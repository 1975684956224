import React from 'react';

import MessagesContact from '../MessagesContact';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

export default function ChatHeader ({
  handleOpenInfo,
  profile,
  isModal,
  returnToList
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={classes.container}>
      {isModal && (
        <button
          type="button"
          onClick={returnToList}
          className={classes.backButton}
        >
          <ArrowBackIcon />
        </button>
      )}
      <Box className={classes.contactContainer}>
        <MessagesContact profile={profile} />
      </Box>
      <button
        type="button"
        onClick={handleOpenInfo}
        className={classes.infoButton}
      >
        <InfoIcon />
      </button>
    </Box>
  );
}

ChatHeader.propTypes = {
  handleOpenInfo: PropTypes.func,
  profile: PropTypes.func,
  isModal: PropTypes.bool,
  returnToList: PropTypes.func
};

ChatHeader.defaultProps = {
  handleOpenInfo: null,
  profile: {},
  isModal: false,
  returnToList: null
};
