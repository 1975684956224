import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  InputBase,
  Paper,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
export default function SearchMenu({
  loadingSearch,
  totalFoundInSearch,
  currentSearchResult,
  resultsFoundInTheSearch,
  setCurrentSearchResult,
  setSearchString,
  setTotalFoundInSearch,
  scrollToElement,
  searchString
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const inputSearchRef = useRef(null);
  const [isOpenSearchMenu, setIsOpenSearchMenu] = useState(false);

  const debounceSearchInput = debounce((currentSearchString) => {
    if (searchString != currentSearchString) {
      resultsFoundInTheSearch.current = [];
      setCurrentSearchResult(0);
      setTotalFoundInSearch(0);
      setSearchString(currentSearchString);
    }
  }, 1500);

  const handleSearchInputChange = (event) => {
    if (debounceSearchInput) debounceSearchInput.clear();
    debounceSearchInput(event.target.value);
  };

  const handleCloseSearchInputMenu = () => {
    setSearchString('');
    setTotalFoundInSearch(0);
    setIsOpenSearchMenu(false);
    setCurrentSearchResult(0);
    resultsFoundInTheSearch.current = [];
  };

  const handleNextSearchResult = () => {
    if (currentSearchResult < totalFoundInSearch) {
      scrollToElement(currentSearchResult + 1, resultsFoundInTheSearch);
      resultsFoundInTheSearch.current[
        currentSearchResult - 1
      ].style.backgroundColor = '';
      resultsFoundInTheSearch.current[
        currentSearchResult
      ].style.backgroundColor = 'orange';
      setCurrentSearchResult((prev) => prev + 1);
    }
  };

  const handlePreviousSearchResult = () => {
    if (currentSearchResult > 1) {
      scrollToElement(currentSearchResult - 1, resultsFoundInTheSearch);
      resultsFoundInTheSearch.current[
        currentSearchResult - 1
      ].style.backgroundColor = '';
      resultsFoundInTheSearch.current[
        currentSearchResult - 2
      ].style.backgroundColor = 'orange';
      setCurrentSearchResult((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (isOpenSearchMenu && !loadingSearch) {
      if (inputSearchRef.current) {
        inputSearchRef.current.focus();
      }
    }
  }, [isOpenSearchMenu, loadingSearch]);

  return (
    <ClickAwayListener onClickAway={handleCloseSearchInputMenu}>
      <span>
        <ButtonBase
          style={{ borderRadius: '4px' }}
          onClick={() => setIsOpenSearchMenu((prev) => !prev)}
        >
          <SearchIcon className={classes.personIconsStyle} />
        </ButtonBase>
        {isOpenSearchMenu && (
          <Paper className={classes.searchMenuPaper}>
            <Box className={classes.searchMenuContent}>
              <InputBase
                disabled={loadingSearch}
                onChange={handleSearchInputChange}
                type="text"
                className={classes.inputSearch}
                inputRef={inputSearchRef}
                placeholder="O que você procura?"
                endAdornment={
                  <Box
                    className={classes.searchInputEndAdornment}
                    style={{
                      color: totalFoundInSearch > 0 ? 'inherit' : '#676D82'
                    }}
                  >
                    <span>{currentSearchResult}</span>
                    <span>/</span>
                    <span>{totalFoundInSearch}</span>
                  </Box>
                }
              />
              <ButtonBase
                style={{ borderRadius: '4px', padding: '4px' }}
                disabled={totalFoundInSearch <= 0}
                onClick={handlePreviousSearchResult}
              >
                <ExpandLessIcon
                  className={classes.personIconsStyle}
                  style={{
                    color:
                      totalFoundInSearch > 0
                        ? theme.palette.textAndBackground.main
                        : '#676D82'
                  }}
                />
              </ButtonBase>
              <ButtonBase
                disabled={totalFoundInSearch <= 0}
                style={{ borderRadius: '4px', padding: '4px' }}
                onClick={handleNextSearchResult}
              >
                <ExpandMoreIcon
                  className={classes.personIconsStyle}
                  style={{
                    color:
                      totalFoundInSearch > 0
                        ? theme.palette.textAndBackground.main
                        : '#676D82'
                  }}
                />
              </ButtonBase>
              <ButtonBase
                style={{
                  borderRadius: '4px',
                  marginLeft: '8px',
                  padding: '4px'
                }}
                onClick={handleCloseSearchInputMenu}
              >
                <CloseIcon
                  style={{
                    height: '18px',
                    width: '18px',
                    color: theme.palette.textAndBackground.main
                  }}
                />
              </ButtonBase>
            </Box>
          </Paper>
        )}
      </span>
    </ClickAwayListener>
  );
}

SearchMenu.propTypes = {
  loadingSearch: PropTypes.bool.isRequired,
  totalFoundInSearch: PropTypes.number.isRequired,
  currentSearchResult: PropTypes.number.isRequired,
  resultsFoundInTheSearch: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  setCurrentSearchResult: PropTypes.func.isRequired,
  setSearchString: PropTypes.func.isRequired,
  setTotalFoundInSearch: PropTypes.func.isRequired,
  scrollToElement: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired
};
