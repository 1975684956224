import React, { useMemo, useState } from 'react';
import { FaUserEdit } from 'react-icons/fa';
import { IoPersonSharp } from 'react-icons/io5';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import ButtonHorizontalIcon from '~/components/atoms/ButtonHorizontalIcon';

import * as S from './styles';
import {
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
  Menu,
  MenuItem
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonIcon from '@material-ui/icons/Person';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const ContextualBar = ({
  structureID,
  selectedMembersList,
  setSelectedMembersList,
  members,
  totalMembers,
  loadingStructureMembersDetails,
  roleOfSelectedMembers,
  openRoleChangeModal,
  openDeleteConfirmationModal,
  getReport
}) => {
  const theme = useTheme();
  const classes = S.useStyles({ theme });
  let history = useHistory();
  let { url } = useRouteMatch();
  let location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [fetchingReport, setFetchingReport] = useState(false);

  const isEditMember =
    window.location.hostname === 'avamecinterativo.mec.gov.br' ||
    window.location.hostname === 'beta.ava.viitra.in' ||
    window.location.hostname === 'localhost';

  const handleSelectedAllMembers = async () => {
    setSelectedMembersList(
      selectedMembersList.length === 0 ? [...members] : []
    );
  };

  const isAllSelected = useMemo(
    () => selectedMembersList.length === members.length,
    [selectedMembersList, members]
  );
  const isIndeterminate = useMemo(
    () =>
      selectedMembersList.length > 0 &&
      selectedMembersList.length < members.length,
    [selectedMembersList, members]
  );

  const editMemberNavigate = () => {
    if (selectedMembersList.length === 1 && isEditMember) {
      history.push({
        pathname: `${url}/members/${selectedMembersList[0]?.user?.id}?structureID=${structureID}&memberID=${selectedMembersList[0]?.user?.id}`,
        state: { background: location }
      });
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const downloadReport = () => {
    setFetchingReport(true);
    const usersID = selectedMembersList.map((item) => item.user.id);
    getReport(structureID, usersID)
      .then((file) =>
        fileDownload(
          file,
          `relatorio_de_papeis_por_estrutura_usuario_${usersID}.csv`
        )
      )
      .catch(() => {
        enqueueSnackbar('Não foi possível Gerar o Relatório', {
          variant: 'error'
        });
      })
      .finally(() => setFetchingReport(false));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box style={{ width: '100%' }}>
      {isMediumScreen && (
        <Box className={classes.contextualButtonsOnSmallScreen}>
          <ButtonHorizontalIcon
            disabled={
              selectedMembersList.length > 1 ||
              selectedMembersList.length === 0 ||
              fetchingReport
            }
            disableRipple
            onClick={downloadReport}
            Icon={fetchingReport ? CircularProgress : GetAppIcon}
            title={fetchingReport ? 'Aguarde...' : 'Baixar relatório'}
            iconProps={
              fetchingReport
                ? {
                    size: 12,
                    style: { color: theme.palette.activeText.main }
                  }
                : {}
            }
          />
          <ButtonHorizontalIcon
            disabled={selectedMembersList.length === 0}
            disableRipple
            onClick={openDeleteConfirmationModal}
            Icon={DeleteIcon}
            title="Remover"
          />
          <ButtonHorizontalIcon
            disabled={
              selectedMembersList.length === 0 || !roleOfSelectedMembers
            }
            disableRipple
            onClick={openRoleChangeModal}
            Icon={IoPersonSharp}
            title="Alterar Papel"
          />
          {isEditMember &&
            !(url.includes('spaces') || url.includes('courses')) && (
              <ButtonHorizontalIcon
                disabled={
                  selectedMembersList.length > 1 ||
                  selectedMembersList.length === 0
                }
                disableRipple
                onClick={editMemberNavigate}
                Icon={FaUserEdit}
                title="Editar membro"
              />
            )}
        </Box>
      )}
      <Box className={classes.container}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Checkbox
            size="medium"
            className={classes.contextualBarCheckbox}
            color="default"
            checked={isAllSelected}
            indeterminate={isIndeterminate}
            onClick={handleSelectedAllMembers}
          />
          <S.Labels marginBottom={-2}>Selecionar todos</S.Labels>
        </Box>
        {isLargeScreen && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '32px'
            }}
          >
            <ButtonHorizontalIcon
              disabled={
                selectedMembersList.length > 1 ||
                selectedMembersList.length === 0 ||
                fetchingReport
              }
              disableRipple
              onClick={downloadReport}
              Icon={fetchingReport ? CircularProgress : GetAppIcon}
              title={fetchingReport ? 'Aguarde...' : 'Baixar relatório'}
              iconProps={
                fetchingReport
                  ? {
                      size: 12,
                      style: { color: theme.palette.activeText.main }
                    }
                  : {}
              }
            />
            <ButtonHorizontalIcon
              disabled={selectedMembersList.length === 0}
              disableRipple
              onClick={openDeleteConfirmationModal}
              Icon={DeleteIcon}
              title="Remover"
            />
            <ButtonHorizontalIcon
              disabled={
                selectedMembersList.length === 0 || !roleOfSelectedMembers
              }
              disableRipple
              onClick={openRoleChangeModal}
              Icon={IoPersonSharp}
              title="Alterar Papel"
            />
            {isEditMember &&
              !(url.includes('spaces') || url.includes('courses')) && (
                <ButtonHorizontalIcon
                  disabled={
                    selectedMembersList.length > 1 ||
                    selectedMembersList.length === 0
                  }
                  disableRipple
                  onClick={editMemberNavigate}
                  Icon={FaUserEdit}
                  title="Editar membro"
                />
              )}
          </Box>
        )}
        <Box style={{ display: 'flex', gap: '8px' }}>
          {loadingStructureMembersDetails ? (
            <S.Loading />
          ) : (
            <>
              <S.SelctedMembers>{`${selectedMembersList.length} - ${members.length} de ${totalMembers}`}</S.SelctedMembers>
              {isLargeScreen && <S.Labels>Membros</S.Labels>}
            </>
          )}
        </Box>
      </Box>
      {isSmallScreen && (
        <Box className={classes.smallContextualActionsBox}>
          <Button
            style={{ textTransform: 'none' }}
            id="actions-button"
            aria-controls={open ? 'actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={classes.menuButton}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Ações
          </Button>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{ style: { width: '80%', marginTop: '8px' } }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                downloadReport();
              }}
              className={classes.menuItem}
              disabled={
                selectedMembersList.length > 1 ||
                selectedMembersList.length === 0 ||
                fetchingReport
              }
            >
              {fetchingReport ? (
                <CircularProgress size={16} className={classes.loadingIcon} />
              ) : (
                <GetAppIcon className={classes.loadingIcon} />
              )}
              Baixar Relatório
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                openDeleteConfirmationModal();
              }}
              className={classes.menuItem}
              disabled={selectedMembersList.length === 0}
            >
              <DeleteIcon className={classes.loadingIcon} />
              Remover
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                openRoleChangeModal();
              }}
              className={classes.menuItem}
              disabled={
                selectedMembersList.length === 0 || !roleOfSelectedMembers
              }
            >
              <IoPersonSharp className={classes.loadingIcon} />
              Alterar Papel
            </MenuItem>
            {isEditMember &&
              !(url.includes('spaces') || url.includes('courses')) && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    editMemberNavigate();
                  }}
                  className={classes.menuItem}
                  disabled={
                    selectedMembersList.length > 1 ||
                    selectedMembersList.length === 0
                  }
                >
                  <FaUserEdit className={classes.loadingIcon} />
                  Editar Membro
                </MenuItem>
              )}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

ContextualBar.propTypes = {
  selectedMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number
      })
    })
  ),
  setSelectedMembersList: PropTypes.func,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string
    })
  ),
  structureID: PropTypes.number,
  totalMembers: PropTypes.number,
  loadingStructureMembersDetails: PropTypes.bool,
  roleOfSelectedMembers: PropTypes.oneOfType([PropTypes.string, null]),
  openRoleChangeModal: PropTypes.func,
  openDeleteConfirmationModal: PropTypes.func,
  getReport: PropTypes.func
};

export default ContextualBar;
