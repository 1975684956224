import React from 'react';

import MainInfo from './MainInfo';
import Options from './Options';
import { useStyles } from './styles';

export default function EnvironmentForm() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <MainInfo />
      <Options />
    </div>
  );
}
