import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  useTheme
} from '@material-ui/core';
import { Mail, Notifications } from '@material-ui/icons';
import Text from '~/components/atoms/Text';
import Section from '~/components/templates/Section';
import api from '~/services/api';

import { useStyles } from './styles';

let Account = ({ fields, loggedInUser }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { profile } = useSelector((state) => state.user);
  const [value, setValue] = useState([]);

  // function handleDelete() {
  //   api
  //     .delete(`/api/users/${profile?.id}`)
  //     .then((res) => {})
  //     .catch((err) => {});
  // }

  const options = [
    { text: 'Notificações por email' },
    { text: 'Notificações push no celular' }
  ];
  const handleChange = (event) => {
    setValue([...value, event.target.value]);
  };

  return (
    <Section title="Configuração da Conta">
      {loggedInUser && (
        <Box
          className={classes.row}
          style={{ alignItems: 'center', marginBottom: 10 }}
        >
          <Mail className={classes.icon} />
          <Text variant="largeText" color="gray">
            {loggedInUser?.email}
          </Text>
        </Box>
      )}
      <Box
        className={classes.row}
        style={{ alignItems: 'center', marginBottom: 10 }}
      >
        {(!!loggedInUser?.settings?.mail_notifications ||
          !!loggedInUser?.settings?.push_notifications) && (
          <Notifications className={classes.icon} />
        )}
        <div>
          {loggedInUser?.settings?.mail_notifications && (
            <Text variant="largeText" color="gray">
              {options[0].text}
            </Text>
          )}
          {loggedInUser?.settings?.push_notifications && (
            <Text variant="largeText" color="gray">
              {options[1].text}
            </Text>
          )}
        </div>
      </Box>
    </Section>
    // <Box className={classes.column}>
    //   <Box className={`${classes.row} ${classes.wrap} ${classes.notification}`}>
    //     <Text variant="largeText" color="gray">
    //       Meu mural é visível a
    //     </Text>

    //     <Field
    //       name="Teste"
    //       component={RadioGroup}
    //       items={[
    //         { value: 1, label: 'Todos' },
    //         { value: 2, label: 'Apenas amigos' }
    //       ]}
    //     />
    //   </Box>

    //   <Box className={`${classes.row} ${classes.wrap} ${classes.notification}`}>
    //     <div>
    //       <Text variant="largeText" color="gray">
    //         Recebimento de
    //       </Text>
    //       <Text variant="largeText" color="gray">
    //         notificações
    //       </Text>
    //     </div>
    //     <FormGroup
    //       ref={checkboxGroupRef}
    //       aria-label="ringtone"
    //       name="ringtone"
    //       value={value}
    //       onChange={handleChange}
    //     >
    //       {options.map((option) => (
    //         <FormControlLabel
    //           value={option.text}
    //           key={option.text}
    //           control={
    //             <Checkbox
    //               color="primary"
    //               icon={
    //                 <CheckBoxOutlineBlankIcon
    //                   style={{ fontSize: 18, color: '#EBEBEB' }}
    //                 />
    //               }
    //               checkedIcon={<CheckBoxIcon style={{ fontSize: 18 }} />}
    //             />
    //           }
    //           label={
    //             <Text color="gray" style={{ fontSize: 14 }}>
    //               {option.text}
    //             </Text>
    //           }
    //         />
    //       ))}
    //     </FormGroup>
    //   </Box>
    //   <Text variant="largeText" color="gray">
    //     Redefinir email
    //   </Text>
    //   {/* <Box className={classes.row}>
    //     {fields.map((account, index) =>
    //       index === 0 ? (
    //         <Field
    //           name={`${account}.email`}
    //           component={TextInput}
    //           label="Email"
    //           // showErrors={showErrors}
    //         />
    //       ) : (
    //         <Field
    //           name={`${account}.confirmationEmail`}
    //           component={TextInput}
    //           label="Confirmar Email"
    //           // showErrors={showErrors}
    //         />
    //       )
    //     )}
    //   </Box> */}

    //   <Text variant="largeText" color="gray" style={{ marginTop: 20 }}>
    //     Redefinir senha
    //   </Text>
    //   <Box className={`${classes.row} ${classes.wrap}`}>
    //     <Field
    //       name="Senha Atual"
    //       component={TextInput}
    //       label="Senha Atual"
    //       type="password"
    //       // showErrors={showErrors}
    //     />
    //     <Field
    //       name="Senha Nova"
    //       component={TextInput}
    //       label="Senha Nova"
    //       type="password"
    //       // showErrors={showErrors}
    //     />
    //     <Field
    //       name="Confirmar Senha"
    //       component={TextInput}
    //       label="Confirmar Senha"
    //       type="password"
    //       // showErrors={showErrors}
    //     />
    //   </Box>
    //   <Box className={classes.buttonArea}>
    //     <ButtonSquare
    //       type="button"
    //       onClick={handleDelete}
    //       backgroundColor={theme.palette.warn.main}
    //       fontSize="14px"
    //       color={theme.palette.textAndBackground.main}
    //       letterSpacing="0px"
    //       width="134px"
    //       height="37px"
    //     >
    //       <Text variant="text" color="textAndBackground" font>
    //         Remover Conta
    //       </Text>
    //     </ButtonSquare>
    //   </Box>
    // </Box>
  );
};

export default Account;
