import React from 'react';
import Swap from './Swap'

export const FormAdminReport = ({ lecture, result }) => {

  return (
    <Swap lecture={lecture} result={result} />
  );
};

export default FormAdminReport;
