import React, { useEffect, useState } from 'react';

import Avatar from '~/components/atoms/Avatar';
import {
  unreadMessageForContactEmit,
  unreadMessageForContactOn
} from '~/socket/message-events';

import * as S from './styles';
import { Badge, useTheme } from '@material-ui/core';

export const AvatarBadge = ({ profile, contact }) => {
  const theme = useTheme();
  const classes = S.useStyles({ theme });
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    if (profile) {
      unreadMessageForContactEmit(profile?.id, contact?.id);
      unreadMessageForContactOn(profile?.id, contact?.id, (obj) => {
        setUnreadMessages(obj.unread);
      });
    }
  }, []);

  return (
    <Badge
      overlap="circle"
      color="error"
      badgeContent={unreadMessages}
      variant="dot"
      max={99}
      classes={{ badge: classes.badge }}
      invisible={unreadMessages <= 0}
    >
      <Avatar size={40} profile={contact} />
    </Badge>
  );
};
