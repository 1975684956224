import React from 'react';
import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper/index';
import { hasReadAccessToStatusFile } from '~/services/AccessControl/statusFileAccessControl';
import settings from '~settings';

export default function ModalImagesDimensions({ item }) {
  const postedImageDimensions = (status_file) => {
    if (status_file.dimensions.height > 360) {
      return (
        <div
          style={{
            display: 'flex',
            alignItens: 'center',
            justifyContent: 'center',
            marginTop: '5%'
          }}
        >
          <img src={`${settings.baseUrl}${status_file.thumbnails[0].href}`} />
        </div>
      );
    } else if (status_file.dimensions.width > 640) {
      return (
        <div
          style={{
            display: 'flex',
            alignItens: 'center',
            justifyContent: 'center',
            marginTop: '5%'
          }}
        >
          <img src={`${settings.baseUrl}${status_file.thumbnails[1].href}`} />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            alignItens: 'center',
            justifyContent: 'center',
            marginTop: '50%'
          }}
        >
          <img src={`${settings.baseUrl}${status_file.thumbnails[2].href}`} />
        </div>
      );
    }
  };

  const renderStatusFile = (status_file) => {
    if (status_file.type === 'image') return postedImageDimensions(status_file);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItens: 'center',
        justifyContent: 'center',
        marginTop: '8%'
      }}
    >
      {item.status_files?.length > 0 && (
        <div style={{ width: 10 }}>
          <ComponentAccessControlWrapper
            accessFunction={hasReadAccessToStatusFile}
            modelID={item.status_files[0].id}
            Component={() => renderStatusFile(item.status_files[0])}
          />
        </div>
      )}
    </div>
  );
}
