import React from 'react';
import { useFormContext } from 'react-hook-form';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';
import settings from '~settings';

import { useStyles, warningStyle } from './styles';
import { Box, CircularProgress, Grid, useTheme } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const client = settings.name;

export default function SubmitSection() {
  const theme = useTheme();
  const classes = useStyles();
  const {
    watch,
    formState: { isSubmitting }
  } = useFormContext();
  const edit = !!watch('id');
  const text = `Por favor não publique conteúdo que esteja protegido por direitos autorais, \
                conteúdo obsceno ou que viole os termos e condições da ${client}. \
                Ao clicar no botão '${
                  edit ? 'Editar' : 'Adicionar'
                }', você estará concordando com esses termos.`;

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item container alignItems="center" spacing={1} xs={12} md={9}>
        <Grid item>
          <IconWrapper
            customColor="#f3ae1b"
            Icon={PriorityHighIcon}
            fontSize="30px"
            {...warningStyle}
          />
        </Grid>
        <Grid item xs>
          <Text color="gray" variant="mediumText">
            {text}
          </Text>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" xs={12} md={3}>
        <Box mt={{ xs: '20px', md: 0 }}>
          <ButtonSquare
            type="submit"
            width={125}
            height={35}
            disabled={isSubmitting}
            backgroundColor={theme.palette.secondary.main}
          >
            {isSubmitting ? (
              <CircularProgress size={28} />
            ) : (
              <Text className={classes.text}>
                {edit ? 'Editar' : 'Adicionar'}
              </Text>
            )}
          </ButtonSquare>
        </Box>
      </Grid>
    </Grid>
  );
}
