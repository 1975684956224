import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ReplicationSteps from '~/components-refactor/molecules/ReplicatedSteps';
import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import Page from '~/components/templates/Page';
import i18n from '~/I18n';

import { CreationSuccessFeedback } from './CreationSuccessFeedback';
import { FootButtonsWrapper } from './FootButtonsWrapper';
import { useReplicateContext } from './ReplicateStructureContext';
import { StepsDisplay } from './StepsDisplay';
import { useStyles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export function CreateReplicatedStructure({
  structureName,
  stepsCount,
  resolver
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const methods = useForm({
    resolver: yupResolver(resolver)
  });

  const {
    mirrorType,
    replicationSteps,
    setReplicationSteps,
    activeStep,
    setActiveStep,
    parentID,
    mirrorService
  } = useReplicateContext();

  const isLastStep = useMemo(() => activeStep === replicationSteps.length - 1, [
    activeStep,
    replicationSteps
  ]);
  const isStartStep = useMemo(() => activeStep === 0, [activeStep]);

  useEffect(() => {
    setReplicationSteps((prev) => {
      let temp = prev.slice(0, stepsCount - 1);
      temp.push({ name: 'Espelhamento', key: true });
      return [...temp];
    });
  }, []);

  const breadcrumbs = [
    {
      name: `Espelhar ${structureName}`,
      path: '#'
    }
  ];

  const generateErrorMessage = (error) => {
    if (mirrorType === 'environment' && error?.name)
      return `Para ${i18n.t(
        `StructuresName.Environment.SingularLower`
      )} o campo "nome": ${error?.name}`;
    else if (error?.contained_in_invalid_mirror)
      return 'Não é possível criar estruturas espelhadas dentro de outras estruturas que já são espelhos.';
    else
      return 'Infelizmente, não conseguimos realizar a operação solicitada no momento. Por favor, tente novamente mais tarde.';
  };

  const submitReplication = async (data) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await mirrorService.create(
        replicationSteps[activeStep - 1].key,
        data,
        parentID
      );
      enqueueSnackbar(<CreationSuccessFeedback />, { variant: 'success' });
      history.goBack();
    } catch (error) {
      enqueueSnackbar(generateErrorMessage(error.response.data.error), {
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async (e) => {
    if (activeStep === replicationSteps.length - 1) return;
    e.preventDefault();
    if (activeStep === replicationSteps.length - 2) {
      const formIsValid = await methods.trigger();
      if (formIsValid) {
        methods.clearErrors();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <>
      <Page>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <DashboardBreadcrumbs links={breadcrumbs} />
            <Text
              variant="xl"
              style={{
                letterSpacing: 1,
                fontWeight: 700,
                color: theme.palette.activeText.main
              }}
            >
              {`Espelhar ${structureName}`}
            </Text>
          </Box>
          <Box className={classes.content}>
            <StepsDisplay steps={replicationSteps} activeStep={activeStep} />
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(submitReplication)}
                className={classes.form}
              >
                <ReplicationSteps
                  currentStep={activeStep}
                  isLastStep={isLastStep}
                />
                <FootButtonsWrapper
                  isStartStep={isStartStep}
                  isLastStep={isLastStep}
                  actionNextButton={handleNext}
                  actionBackButton={handleBack}
                  isLoading={isLoading}
                  disableNextButton={
                    !replicationSteps[activeStep]?.key || isLoading
                  }
                />
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Page>
    </>
  );
}

CreateReplicatedStructure.propTypes = {
  structureName: PropTypes.string.isRequired,
  stepsCount: PropTypes.number.isRequired,
  resolver: PropTypes.object.isRequired
};
