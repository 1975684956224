import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 15
    },
    company: {
      display: 'flex'
    },
    textCompany: {},
    workIcon: {
      width: 53,
      height: 53,
      marginRight: 20,
      color: theme.palette.textActiveElement.main
    },
    noExperiencesDesktopTablet: {
      '@media (max-width: 450px)': {
          display: 'none'
      }
    },
    noExperiencesMobile: {
      display: 'none',
      '@media (max-width: 450px)': {
          display: 'block'
      }
    }
}));
