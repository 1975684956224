import React, { createContext, useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

const SnackbarContext = createContext();

export const useCustomSnackbar = () => useContext(SnackbarContext);

export const CustomSnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    buttonDetails: {
      buttonText: '',
      buttonAction: null
    }
  });

  const showSnackbar = (message, buttonDetails) => {
    setSnackbarData({ message, buttonDetails });
    setSnackbarOpen(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ showSnackbar, closeSnackbar, snackbarOpen, snackbarData }}
    >
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'green', fontSize: '1.5rem' }}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon
                fontSize="medium"
                style={{ marginRight: '8px', verticalAlign: 'center' }}
              />
              {snackbarData.message}
            </div>
          }
          action={
            <>
              <Button
                style={{ fontSize: '1.3rem', color: 'white' }}
                onClick={snackbarData.buttonDetails.buttonAction}
              >
                {snackbarData.buttonDetails.buttonText}
              </Button>
              <IconButton
                size="large"
                aria-label="close"
                color="inherit"
                onClick={closeSnackbar}
              >
                <CloseIcon
                  fontSize="medium"
                  style={{ verticalAlign: 'middle' }}
                />
              </IconButton>
            </>
          }
        />
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
