import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    paddingLeft: 120,
    '@media (max-width: 1300px)': {
      paddingLeft: 27
    },
    '@media (max-width: 1023px)': {
      justifyContent: 'flex-start',
      marginBottom: 10,
      paddingLeft: 0,
      width: '100%',
      overflow: 'auto'
    }
  },
  content: {
    paddingTop: 150,
    '& p': {
      cursor: (props) => (props.unclickable ? 'default' : 'pointer')
    },
    '@media (max-width: 1023px)': {
      width: '100%',
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: (props) => props.tabSpacing,
      overflow: 'auto',
      height: 30,
      '& p:first-of-type': {
        paddingLeft: 25
      },
      '& p': {
        paddingRight: 25,
        textOverflow: 'initial',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase'
      }
    }
  }
});
