import React from 'react'
import Main from '../Main'

export default function YoutubeMiniCard({ size, ...rest }){
  return <Main  color="white" 
                backgroundColor="#ed2402"
                size={size}
                text="YouTube"
                { ...rest }
                />
}