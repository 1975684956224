import React from 'react';

import { CustomAvatar } from './styles';
import PropTypes from 'prop-types';

export default function AvatarProfile({ size, profile, ...otherProps }) {
  return <CustomAvatar profile={profile} size={size} {...otherProps} />;
}

AvatarProfile.propTypes = {
  size: PropTypes.number,
  profile: PropTypes.shape()
};

AvatarProfile.defaultProps = {
  size: 90,
  profile: null
};
