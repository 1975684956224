import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import { parseISO, getYear } from 'date-fns';
import PropTypes from 'prop-types';

function AcademicEducation({ educationable }) {
  const {
    id,
    institution,
    kind,
    course,
    description,
    year,
    workload,
    start_year,
    end_year,
    research_area,
    name,
    role
  } = educationable;
  const classes = useStyles();

  const [educationKind, setEducationKind] = useState('');

  const translateEducationKind = (kind) => {
    switch (kind) {
      case 'degree':
        return setEducationKind('Licenciatura');
      case 'bachelorship':
        return setEducationKind('Bacharelado');
      case 'technical':
        return setEducationKind('Técnico');
      case 'pos_lato_sensu':
        return setEducationKind('Pós "Lato Sensu / MBA"');
      case 'doctorate':
        return setEducationKind('Doutorado');
      case 'phd':
        return setEducationKind('PHD');
      default:
        return setEducationKind('Pós "Stricto Sensu"');
    }
  };

  const translationRole = (role) => {
    switch (role) {
      case 'participant':
        return setEducationKind('Participante');
      case 'speaker':
        return setEducationKind('Palestrante');
      default:
        return setEducationKind('Organizador');
    }
  };

  function formatDate(date) {
    return getYear(parseISO(date));
  }

  useEffect(() => {
    if (kind) translateEducationKind(kind);
  }, [kind]);

  useEffect(() => {
    if (role) translationRole(role);
  }, [role]);

  return (
    <Box key={id} className={classes.educationContainer}>
      <SchoolIcon className={classes.schoolIcon} />
      <Box className={classes.educationInfoContainer}>
        {institution && (
          <Text variant="text" color="gray">
            {institution}
          </Text>
        )}
        {name && ( // Aparentemente o objeto não possui o atributo name, investigar se pode tirar esse "if"
          <Text variant="text" color="gray">
            {name}
          </Text>
        )}
        {role && ( // Aparentemente o objeto não possui o atributo role, investigar se pode tirar esse "if"
          <Text variant="text" color="gray">
            {`${educationKind}`}
          </Text>
        )}
        {!research_area && kind && course && (
          <Text variant="text" color="gray">
            {`${educationKind} em ${course}`}
          </Text>
        )}
        {kind && research_area && course && (
          <Text variant="text" color="gray">
            {`${educationKind} em ${course}, ${research_area}`}
          </Text>
        )}
        {!start_year && end_year && (
          <Text variant="text" color="gray">
            {formatDate(end_year)}
          </Text>
        )}
        {start_year && end_year && (
          <Text variant="text" color="gray">
            {`${formatDate(start_year)} - ${formatDate(end_year)}`}
          </Text>
        )}
        {year && (
          <Text variant="text" color="gray">
            {formatDate(year)}
          </Text>
        )}
        {workload && (
          <Text variant="text" color="gray">
            {`${workload} horas`}
          </Text>
        )}
        {description && (
          <Text variant="text" color="gray">
            {description}
          </Text>
        )}
      </Box>
    </Box>
  );
}

AcademicEducation.propTypes = {
  educationable: PropTypes.shape()
};

AcademicEducation.defaultProps = {
  educationable: null
};

export default AcademicEducation;
