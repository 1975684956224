import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

const fileTypes = [
  'image/bmp',
  'image/x-windows-bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png'
];

const ImageCertificateLogoClient = () => {
  const { watch } = useFormContext();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (watch('certificateLogoClientFile')) {
      setValue({ name: watch('certificateLogoClientFile') });
    }
  }, [watch('certificateLogoClientFile')]);

  return (
    <Field
      width="100%"
      label={TextPrimary('Imagem')}
      name={'certificate_logo_client'}
      fullWidth={true}
      Component={DragDropFileInput}
      permittedFileTypes={fileTypes}
      fileTypesDescription={'Apenas arquivos de imagem serão permitidos.'}
      value={value}
      maxSize={10}
    />
  );
};

export default ImageCertificateLogoClient;
