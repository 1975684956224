import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    '@media (max-width: 600px)': {
      padding: 15
    }
  },
  addIcon: {
    alignSelf: 'center',
    fontSize: 25,
    marginBottom: 15,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  dropDownIcon: {
    position: 'absolute',
    right: 0,
    fontSize: 30,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    maxHeight: '72vh',
    height: '72vh',
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  footer: {
    flex: '0 0 auto'
  },
  loadingMessage: {
    fontSize: 16,
    color: '#707070'
  },

  paginationButtonWrepper: {
    display: 'flex',
    position: 'relative',
    padding: 5,
    top: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  pressContentBody: {
    minHeight: '12px',
    flex: '1 1 auto'
  },
  contentBody: {
    flex: '0 0 auto',
    overflow: 'auto',
    maxWidth: '100%'
  }
}));
