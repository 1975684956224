import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '240px',
    maxWidth: '240px',
    height: '130px',
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    justifyContent: 'space-between'
  },
  iconContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '-16px 0px -8px 0px'
  },
  lectureName: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));
