import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  iconCustom: {
    padding: 0,
    margin: 0,
    width: 12,
    height: 12,
    color: 'white'
  },
  iconCustomWraper: {
    padding: 0,
    margin: 0,
    width: 18,
    height: 18,
    background: '#1B60F3',
    '&:hover': { background: '#083491' },
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: 30,
      width: 30,
      marginTop: 0,
      left: 0
    }
  },
  toggle: {
    cursor: 'pointer',
  },
  field: {
    marginBottom: '10px',
  }
});