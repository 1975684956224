import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    margin: '24px 0 12px 0',
    maxWidth: '100%',
    paddingBottom: '8px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '4px'
    }
  }
});
