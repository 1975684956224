import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: (props) => props.maxWidth,
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'start',
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  closeButton: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  headerText: {
    color: 'gray',
    fontSize: '2.5rem',
    fontWeight: 900,
  },
  descriptionText: {
    color: 'gray',
    fontSize: '1.7rem',
    marginTop: theme.spacing(2),    
  },
  closeIcon: {
    fontSize: '2rem',  
  },
  warningIcon: {
    fontSize: '2.5rem', 
    color: '#CB410B',
    marginRight: theme.spacing(1) 
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontSize: '1.5rem',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray',
      },
      '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray !important',
      },
      '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'gray',
      },
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

