import initialPagesImageBgLogin from '../assets/images/redu-bg.png';
import initialPagesImageBgRecover from '../assets/images/redu-bg.png';
import logoColorful from '../assets/logos/colorful/redu.svg';
import logo from '../assets/logos/logo-redu.png';
import { muiCreateTheme } from './mui-create-theme';

const title = 'Redu';

const loginLogo = {
  width: 300
};
const appBarLogoWidth = {
  width: 100
};

const imageBackgroundLogin = {
  height: 'auto',
  width: '45%'
};

let light = muiCreateTheme({
  size: {
    loginLogo: loginLogo,
    appBarLogo: appBarLogoWidth,
    imageBackgroundLogin: imageBackgroundLogin
  },
  palette: {
    type: 'light',
    primary: {
      main: '#0056B4'
    },
    secondary: {
      main: '#0994DD'
    },
    tertiary: {
      main: '#1D3A85'
    },
    accent: {
      main: '#1A72F9'
    },
    activeText: {
      main: '#676D82'
    },
    pendent: {
      main: '#75aafb'
    },
    hiperlinkActiveText: {
      main: '#5CC4F8'
    },
    textInactiveElement: {
      main: '#676D82'
    },
    textActiveElement: {
      main: '#676D82'
    },
    text: {
      main: '#000000'
    },
    textAndBackground: {
      main: '#ffffff'
    },
    warn: {
      main: '#FFA935'
    },
    error: {
      main: '#D62222'
    },
    shimmer: {
      main: 'rgba(87, 154, 255, 0.43)'
    },
    success: {
      main: '#35998F'
    }
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#4DADD6'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#4DADD6',
          borderWidth: 2
        },
        '&$focused $notchedOutline': {
          borderColor: '#4DADD6'
        }
      },
      notchedOutline: {}
    },
    MuiSelect: {
      icon: {
        fill: '#4DADD6'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 1024,
      lg: 1190,
      xl: 1920
    }
  }
});

export default {
  logo,
  logoColorful,
  title,
  initialPagesImageBgLogin,
  initialPagesImageBgRecover,
  ...light
};
