import React from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';

import { useStyles, TimeDistance, CustomAvatar } from '../styles';
import i18n from '~/I18n';

function Answer({ item, dashboard, lecture }) {
  const { id, text, created_at: createdAt } = item;
  const { first_name, last_name, id: userId } = item.user || {
    first_name: i18n.t('DeletedUser'),
    last_name: '',
    id: null
  };
  const isMobile = useMediaQuery('@media  (max-width: 767px)');
  const userProfile = item.user || {};
  const classes = useStyles({ dashboard });
  return (
    <div className={classes.answerContainer}>
      <div className={classes.answerContainer__userInfo}>
        <CustomAvatar profile={userProfile} lecture={lecture} size={35} />
        <div className={classes.answerContainer__name_time}>
          <Link to={userId ? `/profile/${userId}` : '#'}>
            <Text color="accent" variant="actionText">
              {` ${first_name} ${last_name}`}
            </Text>
          </Link>
          <TimeDistance date={createdAt} />
        </div>
      </div>
      <div className={classes.answerContainer__content}>
        <Text color="gray" variant="actionText">
          {text}
        </Text>
      </div>
    </div>
  );
}

Answer.propTypes = {
  item: PropTypes.shape(),
  dashboard: PropTypes.bool,
  lecture: PropTypes.bool
};

Answer.defaultProps = {
  item: {},
  dashboard: undefined,
  lecture: undefined
};

export default Answer;
