import React, { useCallback } from 'react';

import i18n from '~/I18n';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: '100%',
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    border: 0,
    borderRadius: '0.5rem',
    color: 'white',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main
    }
  }
}));

export default function SubmitButton({ isSubmitting }) {
  const showButtonContent = useCallback(() => {
    return isSubmitting ? <CircularProgress /> : i18n.t('Login.EnterButton');
  }, [isSubmitting]);

  return (
    <Button className={useStyles().submitButton} type="submit" disableElevation>
      {' '}
      {showButtonContent()}{' '}
    </Button>
  );
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool
};

SubmitButton.defaultProps = {
  isSubmitting: false
};
