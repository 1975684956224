import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    padding: '4px 0px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  buttonText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    textWrap: 'nowrap'
  },
  checked: {
    border: '1px solid #008A00',
    '&:hover': {
      backgroundColor: 'white',
      '& *': {
        color: '#008A00 !important'
      }
    }
  },
  checkboxInput: {
    width: '16px',
    height: '16px'
  },
  checkboxIcon: {
    fontSize: '16px'
  }
}));
