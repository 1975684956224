import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

const Loading = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={classes.root}>
      {Array(5)
        .fill(null)
        .map((shimmer, index) => (
          <Skeleton
            key={index}
            className={classes.cardSkeleton}
            variant="rect"
          />
        ))}
    </Box>
  );
};

export default Loading;
