import { Box, Button, IconButton, Modal } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: white;
  max-width: 60rem;
  align-self: center;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.8rem;
`;

export const EditUserForm = styled.form`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const ModalEditMember = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled(Box)`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled(IconButton)`
  height: 3rem;
  width: 3rem;
`;

export const TouchableDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  z-index: -1;
`;

export const SendButton = styled(Button)`
  ${({ colorPrimary, colorSecondary }) => css`
    align-self: center;
    margin-top: 2rem;
    align-self: center;
    min-width: 25rem;
    color: white;
    background-color: ${colorPrimary};
    font-weight: bold;
    &:hover {
      background-color: ${colorSecondary};
      cursor: pointer;
    }
  `}
`;
