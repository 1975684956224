import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  modalContent: {
    borderRadius: '5px',
    backgroundColor: 'white',
    '@media (min-width: 0px)': {
      width: '290px',
      maxHeight: '490px'
    },
    '@media (min-width: 600px)': {
      width: '500px',
      maxHeight: '800px'
    },
    '@media (min-width: 960px)': {
      width: '550px'
    },
    '@media (min-width: 1280px)': {
      width: '800px'
    },
    '@media (min-width: 1920px)': {
      width: '1200px'
    },
    overflowY: 'scroll',
    '& ::-webkit-scrollbar': {
      width: '26px',
      background: 'red'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      bordeRadius: '5px',
      width: '10px'
    },
  },
}));
