import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  textHeader: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  exitButton: {
    fontSize: 28,
    cursor: 'pointer',
    color: '#676d82'
  },
  ContainerOptions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: 0
  },
  optionButton: {
    display: 'flex',
    width: '100%',
    padding: '12px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #A8B1CE',
    color: theme.palette.primary.main
  },
  optionSelected: {
    backgroundColor: '#EBEBEB',
    borderColor: theme.palette.primary.main
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '24px'
  },
  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#F5F5F5',
      filter: 'saturate(180%)'
    }
  },
  addButton: {
    width: '100px',
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: '#fff'
    }
  }
}));
