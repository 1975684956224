import React, { useState } from 'react';

// Material-UI Components
import { 
    Checkbox, 
    FormControlLabel, 
    FormGroup, 
    Box 
} from '@material-ui/core';

// Icons
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// Redux
import { useDispatch } from 'react-redux';
import { Creators as UserActions } from '~/store/ducks/user';

// Other Imports
import { useSnackbar } from 'notistack';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

/**
 * NotificationSettings component allows the user to set their notification preferences.
 */
function NotificationSettings({ profile, styleSaveButton, styleCancelButton, resetActiveSection }) {
  
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Local state for handling notification settings
  const [value, setValue] = useState({
    mail_notifications: profile?.settings?.mail_notifications,
    push_notifications: profile?.settings?.push_notifications
  });

  // Options for notifications
  const options = [
    { text: 'Notificações por email', value: 'mail_notifications' },
    { text: 'Notificações push no celular', value: 'push_notifications' }
  ];

  /**
   * Handle local state changes for checkboxes.
   */
  const handleLocalChange = (event) => {
    const updatedValue = { ...value, [event.target.name]: event.target.checked };
    setValue(updatedValue);
  };

  /**
   * Save the notification settings.
   */
  const handleSave = async () => {
    try {
      await api.put(`/api/users/${profile?.id}`, {
        user:{
          settings: {
            mail_notifications: value.mail_notifications,
            push_notifications: value.push_notifications
          }
        }
      });

      // Update the user profile in the redux store
      const updatedProfile = {
        ...profile, 
        settings: {
          mail_notifications: value.mail_notifications, 
          push_notifications: value.push_notifications
        }
      };
      dispatch(UserActions.putProfileUpdate(updatedProfile));

      // Notify the user about the successful update
      enqueueSnackbar('Notificações foram atualizadas com sucesso!', { variant: 'success' });

    } catch (error) {
      // Handle errors and notify the user
      enqueueSnackbar('Erro ao atualizar notificações', { variant: 'error' });
    }
  };

  return (
    <Box>
      <FormGroup onChange={handleLocalChange}>
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            key={option.text}
            control={
              <Checkbox
                color="primary"
                checked={value?.[option.value] ?? false}
                onChange={handleLocalChange}
                name={option.value}
                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 18, color: '#EBEBEB' }} />}
                checkedIcon={<CheckBoxIcon style={{ fontSize: 18 }} />}
              />
            }
            label={<Text color="gray" style={{ fontSize: 14 }}>{option.text}</Text>}
          />
        ))}
      </FormGroup>
      <button style={styleCancelButton} onClick={resetActiveSection}>{i18n.t('Cancel')}</button>
      <button style={styleSaveButton} onClick={handleSave}>{i18n.t('Save')}</button>
    </Box>
  );
}

export default NotificationSettings;
