/* eslint-disable react/display-name */
import React, { useState } from 'react';

import Avatar from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from '../styles';
import * as S from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const MemberItem = ({ member }) => {
  const { first_name, last_name } = member?.user;
  const theme = useTheme();
  const classes = useStyles({ theme: theme });
  const [role, _] = useState(member.role);

  const roleDict = {
    admin: i18n.t('Admin'),
    moderator: i18n.t('Moderator'),
    member: i18n.t('Student'),
    environment_admin: i18n.t('Environment_admin'),
    course_admin: i18n.t('Course_admin'),
    teacher: i18n.t('Teacher'),
    tutor: i18n.t('Tutor'),
    student: i18n.t('Student')
  };

  return (
    <S.MemberWrapper bgColor={theme.palette.primary.main}>
      <S.AvatarWrapper>
        <Avatar profile={member?.user} size={50} />
      </S.AvatarWrapper>
      <S.LinkProfile to={`/profile/${member?.user.id}`}>
        <S.WrapperUserData className={classes.memberInfo}>
          <Text
            color="accent"
            variant="text"
            style={{
              textTransform: 'capitalize',
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            {`${first_name} ${last_name}`}
          </Text>
          <Text
            variant="mediumText"
            color="gray"
          >{`${roleDict[role]}(a)`}</Text>
        </S.WrapperUserData>
      </S.LinkProfile>
    </S.MemberWrapper>
  );
};

export default MemberItem;

MemberItem.propTypes = {
  member: PropTypes.shape(),
  courseId: PropTypes.shape(),
  setError: PropTypes.shape(),
  environmentId: PropTypes.shape(),
  spacesId: PropTypes.shape(),
  userRole: PropTypes.string,
  updateRole: PropTypes.func,
  setMembers: PropTypes.func
};

MemberItem.defaultProps = {
  member: {},
  courseId: null,
  setError: null,
  environmentId: null,
  spacesId: null,
  userRole: 'member',
  updateRole: async () => {},
  setMembers: async () => {}
};
