import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import * as S from './styles';

const CircularProgressWithLabel = (props) => {
  return (
    <S.Wrapper>
      <CircularProgress variant="determinate" {...props} />
      <S.WrapperLabel>
        <S.Label>{`${Math.round(props.value)}%`}</S.Label>
      </S.WrapperLabel>
    </S.Wrapper>
  );
};

export default CircularProgressWithLabel;

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};
