import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  iconCustomWraper: {
    padding: 0,
    margin: 0,
    width: 25,
    height: 25,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      marginTop: 0,
      left: 0
    }
  },

  NoBackground: {
    background: 'none',
    '&:hover': { background: '#eee' }
  },

  NoBackgroundIconColor: {
    color: theme.palette.activeText.main,
    width: 30,
    height: 30
  },
  iconCustom: {
    padding: 0,
    margin: 0,
    width: 20,
    height: 20,
    color: theme.palette.activeText.main
  },
  iconCustomLecture: {
    padding: 0,
    margin: 0,
    width: 20,
    height: 20,
    color: theme.palette.activeText.main
  },
  wrapMenuItem: {
    minWidth: 150,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    justifyContent: 'flex-start',
    color: theme.palette.activeText.main
  },
  popUpMenuWraper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 5,
    cursor: 'pointer',
    alignSelf: 'flex-start'
  },
  linkOneWraper: { background: 'red' },
  iconOneLinkText: {
    padding: 0,
    margin: 0,
    width: 25,
    height: 25,

    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: 30,
      maxWidth: 200,

      marginTop: 0,
      borderRadius: 6,
      left: 0
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
