import React from 'react'
import Main from '../Main'

export default function PresentationMiniCard({ size, ...rest }){
  return <Main  color="#f3aa5c" 
                backgroundColor="#fbe5cc"
                size={size}
                text="Apresentação"
                { ...rest }
                />
}