import React from 'react';
import { Link } from 'react-router-dom';

import AvatarProfile from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import { muralItemAvatarSize } from '~/constants/styles';
import i18n from '~/I18n';

import BreadcrumbMuralItem from '../../BreadcrumbMuralItem';
import {
  useStyles,
  TimeDistance,
  BreadcrumbsCustom,
  CustomAvatar
} from '../styles';
import { Box, Avatar, Link as MaterialLink } from '@material-ui/core';
import PropTypes from 'prop-types';

function Breadcrumbs({ logeableType, links }) {
  switch (logeableType) {
    case 'Course':
      return <BreadcrumbsCustom crumbs={links.slice(3, 4)} />;
    case 'Lecture':
      return <BreadcrumbsCustom crumbs={links.slice(3, 7)} />;
    case 'Subject':
      return <BreadcrumbsCustom crumbs={links.slice(3, 6)} />;
    case 'Space':
      return <BreadcrumbsCustom crumbs={links.slice(2, 4)} />;
    case 'Enrollment':
      return <BreadcrumbsCustom crumbs={links.slice(2, 3)} />;
    default:
      return null;
  }
}

function Log({ item, dashboard }) {
  const {
    created_at: createdAt,
    text,
    friend,
    logeable_name: logeableName,
    logeable_type: logeableType,
    breadcrumbs
  } = item;
  const { first_name, last_name, id: userId } = item.user || {
    first_name: i18n.t('DeletedUser'),
    last_name: '',
    id: null
  };
  const userProfile = item.user || {};

  function LogLink({ children }) {
    if (!breadcrumbs) return children;

    const { environment, space, subject, lecture } = breadcrumbs;
    const { environment_id, environment_name } = environment || {};
    const { space_id, space_name } = space || {};
    const { subject_id } = subject || {};
    const { lecture_id } = lecture || {};

    // set paths
    const environmentPath = `/dashboard/environments/${environment_id}`;
    const spacePath = space_id ? `/dashboard/spaces/${space_id}` : null;
    const lecturePath = lecture_id ? `/dashboard/lecture/${lecture_id}` : null;
    const path = lecturePath || spacePath || environmentPath;

    // set state
    const state = {
      environmentPath,
      environmentName: environment_name,
      spacePath,
      spaceName: space_name,
      spaceId: space_id,
      subjectId: subject_id
    };

    return <Link to={{ pathname: path, state }}>{children}</Link>;
  }

  const classes = useStyles({ dashboard });

  return (
    <Box className={classes.outerContainer}>
      {logeableType !== 'User' &&
        logeableType !== 'Friendship' &&
        logeableType !== 'Experience' &&
        logeableType !== 'Education' && (
          <Box style={{ marginBottom: '16px' }}>
            <BreadcrumbMuralItem breadcrumbs={breadcrumbs} />
          </Box>
        )}
      <Box className={classes.itemContainer}>
        <CustomAvatar profile={userProfile} />
        <Box className={classes.infoContainer}>
          <Box className={classes.actionContainer}>
            <Box className={classes.action}>
              <Link to={userId ? `/profile/${userId}` : '#'}>
                <Text color="accent" variant="actionText">
                  {`${first_name} ${last_name}`}
                </Text>
              </Link>
              <Text color="gray" variant="actionText">
                {text === 'criou o Curso' &&
                  `criou o ${i18n.t('StructuresName.Course.SingularUpper')}`}
                {text === 'criou a Disciplina' &&
                  `criou a ${i18n.t('StructuresName.Space.SingularUpper')}`}
                {text !== 'criou o Curso' &&
                  text !== 'criou a Disciplina' &&
                  `${text}`}
              </Text>
              {logeableType !== 'User' && logeableType !== 'Friendship' ? (
                <LogLink>
                  <Text color="accent" variant="actionText">
                    {logeableName}
                  </Text>
                </LogLink>
              ) : (
                false
              )}
            </Box>
            <TimeDistance date={createdAt} />
          </Box>
          {item.badge && (
            <Box className={classes.badgeContainer}>
              <Avatar
                className={classes.badgeAvatar}
                src="https://image.flaticon.com/icons/svg/1910/1910476.svg"
                alt="BadgeAvatar"
              />
              <MaterialLink href="/#" underline="none">
                <Text color="accent" variant="actionText">
                  {i18n.t('ViewAchievements')}
                </Text>
              </MaterialLink>
            </Box>
          )}
          {logeableType === 'Friendship' && friend && (
            <Box style={{ marginTop: 10 }}>
              <AvatarProfile
                size={muralItemAvatarSize.tabletMobile}
                profile={friend}
                disabled
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

Log.propTypes = {
  item: PropTypes.shape(),
  dashboard: PropTypes.bool
};

Log.defaultProps = {
  item: {},
  dashboard: false
};

Breadcrumbs.propTypes = {
  logeableType: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape())
};

Breadcrumbs.defaultProps = {
  logeableType: null,
  links: null
};

export default Log;
