import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import { Box, Button } from '@material-ui/core';

export const CreationSuccessFeedback = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box className={classes.message}>
      <strong>Sucesso!</strong> O espelhamento está sendo processado.
      <Button
        onClick={() => history.push('/replicable_processes')}
        className={classes.button}
      >
        Ver andamento
      </Button>
    </Box>
  );
};
