import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contextualBarButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#676D82',
    cursor: 'pointer',
    '&:disabled': {
      color: '#B4BDD3',
      cursor: 'auto'
    }
  }
}));
