import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
    paddingRight: 20,
    '@media (max-width: 600px)': {
      padding: '0 15px',
      alignItems: 'center',
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '60px 0 30px 0px',
    '@media (max-width: 600px)': {
      padding: '20px 0px',
    },
  },
  backIcon: {
    fontSize: 30,
    position: 'relative',
    right: 200
  },
  subtitle: {
    padding: '10px 0px 5px 0px',
  },
  content: {
    maxWidth: 1000,
    overflowY: 'auto',
    flex: 1,
    borderRadius: '10px',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '30px 60px',
    marginBottom: '20px',
    '@media (max-width: 1050px)': {
      maxWidth: 800,
    },
    '@media (max-width: 830px)': {
      width: '100%',
      padding: '30px 30px'
    },
  },
  link: {
    color: (props) => props.theme.palette.primary.main
  },
  numberedList: {
    marginLeft: 20,
    '& li': {
      listStyle: 'decimal',
      fontSize: 14,
      color: (props) => props.theme.palette.textActiveElement.main,
      paddingRight: 5
    }
  },
  bulletList: {
    marginLeft: 20,
    '& li': {
      listStyle: 'disc',
      color: (props) => props.theme.palette.textActiveElement.main
    },
  },
}));
