import React from 'react';

import { Box } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
// import { formatDistanceToNow } from 'date-fns';
// import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import i18n from '~/I18n';

export default function ProfessionalExperiences({ experiences }) {
  const classes = useStyles();

  // function getTimeDistanceToNow(date) {
  //   return formatDistanceToNow(new Date(date), {
  //     locale: ptBR
  //   });
  // }

  return (
    <Box className={classes.root}>
      { experiences && experiences.length ? (
        experiences.map((experience, index) => (
          <Box key={index}>
            <Box className={classes.company}>
              <WorkIcon className={classes.workIcon} />
              <Box className={classes.textCompany}>
                <Text variant="text" color="gray">{experience.company}</Text>
                <Text variant="text" color="gray">{experience.title}</Text>
                { experience.current ? (
                  <Text variant="text" color="gray">{`${experience.start_date.slice(0, 4)} - Presente`}</Text>) : (
                  <Text variant="text" color="gray">{`${experience.start_date} - ${experience.end_date}`}</Text>)}
                <Text variant="text" color="gray">{experience.description}</Text>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <>
          <Box className={classes.noExperiencesDesktopTablet}>
            <Text variant="largeText" color="gray">{i18n.t('noExperiences')}</Text>
          </Box>
          <Box className={classes.noExperiencesMobile}>
            <Text variant="text" color="gray">{i18n.t('noExperiences')}</Text>
          </Box>
        </>
        )}
    </Box>
  );
}

ProfessionalExperiences.propTypes = {
  experiences: PropTypes.oneOf(
      PropTypes.shape(),
      PropTypes.array
  )
};

ProfessionalExperiences.defaultProps = {
  experiences: null
};
