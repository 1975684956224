import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  max-height: 9rem;
  max-width: 36rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Image = styled.img`
  height: 5rem;
  width: auto;
`;

export const Link = styled.a`
  width: 45%;
  height: auto;
`;
