import StepIcon from '@material-ui/core/StepIcon';
import { withStyles } from '@material-ui/core/styles';

const CustomStepIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.activeText.main,
    '&$active': {
      color: theme.palette.primary.main
    },
    '&$completed': {
      color: theme.palette.primary.main
    },
    fontSize: '40px'
  },
  text: {
    fill: '#fff',
    fontSize: '1rem',
    fontWeight: '800'
  }
}))(StepIcon);

export default CustomStepIcon;
