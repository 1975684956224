import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import i18n from '~/I18n';

import { useSpacePageContext } from '../contexts/SpacePageContext';
import PaperSpaceInformation from '../PaperSpaceInformation';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { space } = useSpacePageContext();

  const [openDetails, setOpenDetails] = useState();
  return (
    <Box className={classes.infosHeader}>
      <DashboardBreadcrumbs
        links={[
          {
            name: i18n.t('MyEnvironments'),
            path: '/dashboard/environments'
          },
          {
            name: space.environment.name,
            path: `/dashboard/environments/${space.environment.id}`
          },
          {
            name: space.course.name,
            path: `/dashboard/environments/${space.environment.id}/courses/${space.course_id}`
          },
          {
            name: space.name,
            path: '#',
            last: true
          }
        ]}
      />

      <Text
        variant="xl"
        style={{
          letterSpacing: 1,
          fontWeight: 500,
          color: theme.palette.activeText.main
        }}
      >
        {space?.name}
      </Text>

      <Box className={classes.ambientInformationText}>
        <InfoIcon style={{ fontSize: 20 }} className={classes.infoIcon} />
        <Text
          color="accent"
          variant="largeTextHover"
          onClick={() => setOpenDetails(true)}
        >
          {i18n.t('InfoSpace')}
        </Text>
      </Box>
      {openDetails && (
        <PaperSpaceInformation space={space} setOpenDetails={setOpenDetails} />
      )}
    </Box>
  );
};

export default Header;
