import React from 'react'
import { Typography } from '@material-ui/core'

export default function LoginFail({ error }) {
  const style = { color:"rgb(250, 175, 25)", fontSize: 12, fontWeight: 'bold' }
  
  return error === 401 ?
          <Typography style={style}> 
            Usuário e/ou Senha inválido(s)
          </Typography>

            :

          <Typography style={style}>
            Não foi possível atender a solicitação, tente novamente mais tarde.
          </Typography>
}