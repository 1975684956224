function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// Função para obter um valor do localStorage
function getLocalStorage(key) {
  const storedValue = localStorage.getItem(key);
  if (storedValue !== null) {
    return JSON.parse(storedValue);
  }
  throw new Error('Can not find localStorage key: ' + key);
}

export { setLocalStorage, getLocalStorage };
