import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F2F7F7',
    width: '100%',
    maxWidth: '100%',
    padding: '12px 16px',
    gap: '16px',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  fileInfos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden'
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    overflow: 'hidden'
  },
  fileName: {
    fontSize: '14px',
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '33px',
    color: theme.palette.activeText.main
  },
  fileSize: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#A8B1CE',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  actionBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  actionButton: {
    borderRadius: '50%',
    padding: '4px'
  },
  actionIcons: {
    fontSize: '24px',
    color: theme.palette.activeText.main
  }
}));
