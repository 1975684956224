// React imports
import React, { useState } from 'react';

// Material-UI imports and icons
import { Box } from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

// Component imports
import Text from '~/components/atoms/Text';

// Utility and validation imports
import i18n from '~/I18n';
import { PasswordValidation } from '~/utils/EditProfileValidation/yup';
import * as Yup from 'yup';

// Service imports
import api from '~/services/api';

// Third-party hooks and libraries
import { useSnackbar } from 'notistack';

/**
 * A component to allow users to update their password.
 */
function PasswordSettings({
  profile,
  styleInput,
  styleSaveButton,
  styleCancelButton,
  resetActiveSection
}) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  // State for password visibility toggling
  const [showPassword, setShowPassword] = useState(false);

  // State for password data
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  // Define styles
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  };

  const innerInputStyle = {
    ...styleInput,
    paddingRight: '30px' // Prevents text overflow under the icon
  };

  const visibilityButtonStyle = {
    position: 'absolute',
    right: '5px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none'
  };

  const visibilityButtonIconStyle = {
    color: 'gray',
    fontSize: 20
  };

  /**
   * Toggle the password visibility.
   */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Get the type of the password input based on its visibility state.
   */
  const getPasswordInputType = () => (showPassword ? 'text' : 'password');

  // State to hold validation errors
  const [errors, setErrors] = useState([]);

  /**
   * Handle the action to cancel password changes.
   */
  const handleCancel = () => {
    resetActiveSection();
  };

  /**
   * Handle the action to save the new password.
   */
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      // Validate password data using Yup
      await PasswordValidation.validate(passwordData, { abortEarly: false });
      setErrors([]);

      // Proceed to submit the data to the API
      await api.put(`/api/users/${profile?.id}/update_password`, {
        current_password: passwordData.currentPassword,
        new_password: passwordData.newPassword,
        new_password_confirmation: passwordData.confirmNewPassword
      });
      handleCancel();
      enqueueSnackbar('Senha foi atualizada com sucesso!', {
        variant: 'success'
      });
    } catch (error) {
      // Handle validation and API errors
      if (error instanceof Yup.ValidationError) {
        setErrors(error.errors);
      } else if (error.response) {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      } else {
        enqueueSnackbar('Erro ao atualizar a senha', { variant: 'error' });
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleSave}>
        <Text variant="label" color={'gray'}>
          Senha
        </Text>
        {/* Input for the current password */}
        <Box style={containerStyle}>
          <input
            style={innerInputStyle}
            placeholder="Senha Atual"
            type={getPasswordInputType()}
            onChange={(e) =>
              setPasswordData({
                ...passwordData,
                currentPassword: e.target.value
              })
            }
          />
          <button
            style={visibilityButtonStyle}
            onClick={toggleShowPassword}
            type="button"
          >
            {showPassword ? (
              <VisibilityOff style={visibilityButtonIconStyle} />
            ) : (
              <Visibility style={visibilityButtonIconStyle} />
            )}
          </button>
        </Box>

        {/* Input for the new password */}
        <Box style={containerStyle}>
          <input
            style={styleInput}
            placeholder="Nova senha"
            type={getPasswordInputType()}
            onChange={(e) =>
              setPasswordData({ ...passwordData, newPassword: e.target.value })
            }
          />
          <button
            style={visibilityButtonStyle}
            onClick={toggleShowPassword}
            type="button"
          >
            {showPassword ? (
              <VisibilityOff style={visibilityButtonIconStyle} />
            ) : (
              <Visibility style={visibilityButtonIconStyle} />
            )}
          </button>
        </Box>

        {/* Input to confirm the new password */}
        <Box style={containerStyle}>
          <input
            style={styleInput}
            placeholder="Confirmar Nova Senha"
            type={getPasswordInputType()}
            onChange={(e) =>
              setPasswordData({
                ...passwordData,
                confirmNewPassword: e.target.value
              })
            }
          />
          <button
            style={visibilityButtonStyle}
            onClick={toggleShowPassword}
            type="button"
          >
            {showPassword ? (
              <VisibilityOff style={visibilityButtonIconStyle} />
            ) : (
              <Visibility style={visibilityButtonIconStyle} />
            )}
          </button>
        </Box>

        {/* Display any validation errors */}
        {errors &&
          errors.map((error, index) => (
            <p key={index} style={{ color: 'red', fontSize: 12 }}>
              {error}
            </p>
          ))}

        {/* Cancel and Save buttons */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            style={styleCancelButton}
            onClick={handleCancel}
            type="button"
          >
            {i18n.t('Cancel')}
          </button>
          <button style={styleSaveButton} type="submit">
            Alterar Senha
          </button>
        </div>
      </form>
    </Box>
  );
}

export default PasswordSettings;
