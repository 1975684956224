import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import i18n from '~/I18n';
import { getTypeUsersReport } from '~/services/reports/courses';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function TypeUsersReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getTypeUsersReport}
        baseName={`relatório_de_papeis_dos_usuarios_no_${i18n.t(
          'StructuresName.Course.SingularLower'
        )}_`}
        id={id}
      />
    </div>
  );
}

TypeUsersReport.propTypes = {
  id: PropTypes.string
};

TypeUsersReport.defaultProps = {
  id: ''
};
