import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // Existing styles
    textTransform: (props) => (props.noCapitalize ? 'none' : 'capitalize'),
    marginLeft: (props) => props.marginLeft,
    marginTop: (props) => props.marginTop,
    fontSize: (props) => props.fontSize,
    width: (props) => props.width,
    height: (props) => props.height,
    letterSpacing: (props) => props.letterSpacing,
    border: (props) => props.border,
    padding: (props) => props.padding,
    backgroundColor: (props) =>
      props.disabled ? theme.palette.activeText.main : props.backgroundColor,
    '&:hover': {
      filter: 'saturate(180%)',
      backgroundColor: (props) =>
        props.hoverBackgroundColor || props.backgroundColor
    },
    '@media (max-width: 1460px)': {
      width: (props) => (props.isFriendshipRequest ? 80 : props.width),
      height: (props) => (props.isFriendshipRequest ? 40 : props.height)
    }
  }
}));
