import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Creating action types & creators
 */

export const { Types, Creators } = createActions({
  // handleOpen: ['message', 'alertType', 'item'],
  handleOffsetDecrement: [],
  handleOffsetIncrement: ['quantity'],
  handleOffsetReset: ['quantity'],
  handleDeletedItemIncrement: ['id'],
  handleDeletedReset: ['id'],
  handleCurrentLectureSet: ['id']
});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE = {
  // message: '',
  // type: 'success',
  // open: false,
  // item: null,
  deletedCount: [],
  offset: 0,
  currentId: null
};

const handleOffsetDecrement = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.offset -= action.quantity;
  });
};

const handleOffsetIncrement = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.offset += action.quantity;
  });
};

const handleOffsetReset = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.offset = action.quantity;
  });
};

const handleDeletedItemIncrement = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    //   ...person,
    // address: {
    //   ...person.address
    // },
    const old = state.deletedCount;
    draft.deletedCount = [...old, action.id];
  });
};

const handleDeletedReset = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.deletedCount = [];
  });
};

const handleCurrentLectureSet = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.currentId = action.id;
  });
};

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.HANDLE_OFFSET_INCREMENT]: handleOffsetIncrement,
  [Types.HANDLE_OFFSET_DECREMENT]: handleOffsetDecrement,
  [Types.HANDLE_OFFSET_RESET]: handleOffsetReset,
  [Types.HANDLE_DELETED_ITEM_INCREMENT]: handleDeletedItemIncrement,
  [Types.HANDLE_DELETED_RESET]: handleDeletedReset,
  [Types.HANDLE_DELETED_RESET]: handleDeletedReset,
  [Types.HANDLE_CURRENT_LECTURE_SET]: handleCurrentLectureSet
  // [Types.HANDLE_PROCEED]: handleProceed
  // [Types.HANDLE_OPEN]: handleOpen,
  // [Types.HANDLE_CLOSE]: handleClose,
  // [Types.HANDLE_PROCEED]: handleProceed
});
