/* eslint-disable react/prop-types */
import React from 'react';

import InputMasked from '~/components/atoms/InputMasked';
import InputMaskNumbers from '~/components/atoms/InputMasked/InputMaskNumbers';
import Input from '~/components/atoms/TextInput';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import Degrees from './Degrees';
import Keywords from './Keywords';
import PerfilImage from './PerfilImage';
import { useStyles } from './styles';
import ThumbImage from './ThumbImage';
import { Grid, useTheme } from '@material-ui/core';

export default function MainInfo() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Nome *')}
              name="name"
              Component={Input}
              placeholder={`Insira o nome do ${i18n.t(
                'StructuresName.Course.SingularLower'
              )}`}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Abreviação')}
              name="initials"
              Component={Input}
              placeholder={`Insira uma sigla para o ${i18n.t(
                'StructuresName.Course.SingularLower'
              )}`}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} className={classes.field}>
          <div>
            <Field
              label={TextPrimary('Carga horária *')}
              name="workload"
              Component={(props) => (
                <InputMasked Mask={InputMaskNumbers} {...props} />
              )}
              variant={null}
              placeholder="Ex: 50"
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.field}>
        <Field
          label={TextPrimary(
            `Programa do ${i18n.t('StructuresName.Course.SingularUpper')} *`
          )}
          name="description"
          Component={Input}
          placeholder={`Insira um resumo do ${i18n.t(
            'StructuresName.Course.SingularLower'
          )}`}
          multiline
        />
      </div>

      <div className={classes.field}>
        <PerfilImage />
      </div>

      <div className={classes.field}>
        <ThumbImage />
      </div>

      <div className={classes.field}>
        <Keywords />
      </div>

      <div className={classes.field}>
        <Degrees />
      </div>
    </div>
  );
}
