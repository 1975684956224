import React from 'react';

import WelcomeToChatMessage from '~/components/molecules/WelcomeToChatMessage';

import * as S from './styles';
import { Box } from '@material-ui/core';

export const WelcomeChatPageTemplate = () => {
  const classes = S.useStyles();
  return (
    <Box className={classes.container}>
      <WelcomeToChatMessage width="40%" />
    </Box>
  );
};
