import { socket } from '~/socket';

export const subscriber = (channel, func) => {
  socket.emit('subscribe', channel);
  socket.on(`publishesOn/${channel}`, func);
};
export const publisher = (channel, data) => {
  socket.emit('publish', channel, data);
};

export default { subscriber, publisher };
