import React from 'react';

import PropTypes from 'prop-types';

import { useStyles } from './styles';

export default function TextArea({ onChange, placeholder, ...props }) {
  const classes = useStyles(props)();
  return (
    <textarea
      className={classes.textArea}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

TextArea.defaultProps = {
  onChange: null,
  placeholder: null
};
