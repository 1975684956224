import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import MuralTemplate from '~/components/templates/Mural';
import api from '~/services/api';
import { getStatuses as getSpaceStatuses } from '~/services/Space';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export default function Discussion({ spaceID, canPublish }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [statuses, setStatuses] = useState([]);

  const getSpaceStatus = () => {
    setLoading(true);
    getSpaceStatuses(spaceID, page)
      .then((statusesArray) => {
        setStatuses([...statuses, ...statusesArray]);
        setPage(page + 1);
        setHasMore(statusesArray.length >= 25);
        setLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setLoading(false);
      });
  };

  function addNewStatus(status) {
    setLoading(true);
    api
      .post(`/api/spaces/${spaceID}/statuses`, status)
      .then((response) => {
        const newStatus = [response.data, ...statuses];
        if (newStatus.length < 25) {
          setHasMore(false);
        }
        setStatuses(newStatus);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getSpaceStatus();
  }, []);

  const renderNotAllowedFeedback = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#0056B4',
          maxWidth: 480,
          borderRadius: 4,
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
          marginBottom: 32
        }}
      >
        <InfoIcon
          style={{ color: 'white', marginRight: 8, fontSize: 'large' }}
        />
        <Text variant="lg" color="white">
          Somente perfis permitidos podem publicar neste mural.
        </Text>
      </div>
    );
  };

  return (
    <Box className={classes.content} id="scrollableDiv">
      {!loading && !canPublish && renderNotAllowedFeedback()}

      <MuralTemplate
        space
        showTextarea={canPublish}
        loading={loading}
        statuses={statuses}
        hasMore={hasMore}
        target="scrollableDiv"
        getStatuses={getSpaceStatus}
        setStatuses={setStatuses}
        addNewStatusMural={addNewStatus}
      />
    </Box>
  );
}
