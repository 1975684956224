import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';

import IframeFormButtons from './iframeFormButton';
import { useStyles } from './styles';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

export default function IframeModal({
  data,
  editor,
  iframes,
  setIFrames,
  open,
  setOpen,
  currentIframe,
  setCurrentIframe
}) {
  const classes = useStyles();
  const { control } = useFormContext();

  const [url, setUrl] = useState('');
  const [width, setWidth] = useState('1280');
  const [height, setHeight] = useState('720');
  const [edit, setEdit] = useState(false);

  const clearIframeDatas = () => {
    setUrl('');
    setWidth('1280');
    setHeight('720');
  };

  useEffect(() => {
    if (!currentIframe) return;
    setEdit(true);
    const elem = document.createElement('body');
    elem.innerHTML = currentIframe.body;
    const iframeElement = elem.querySelector('iframe');

    setUrl(iframeElement.getAttribute('src'));
    setWidth(iframeElement.getAttribute('width'));
    setHeight(iframeElement.getAttribute('height'));
  }, [currentIframe]);

  const handleSubmit = () => {
    if (url && width && height) {
      currentIframe ? editIframe() : createIframe();
    } else {
      alert('preencher campos');
    }
    setEdit(false);
    setOpen(false);
  };
  const editIframe = () => {
    const iframeString = getIframe(url, width, height);

    setIFrames((prev) => {
      const iframe = prev.find(
        (elem) => elem.position === currentIframe.position
      );
      iframe.body = iframeString;
      const index = prev.indexOf(iframe);

      prev[index] = iframe;

      return [...prev];
    });
    clearIframeDatas();
    setCurrentIframe(null);
  };

  const createIframe = () => {
    const iframeString = getIframe(url, width, height);
    const position = (iframes.at(-1)?.position ?? 0) + 1;
    setIFrames([
      ...iframes,
      {
        body: iframeString,
        position: position
      }
    ]);
    editor.setData(`${data} <h2>IFRAME${position}</h2>`);
    clearIframeDatas();
  };

  const getIframe = (url, width, height) => {
    return `<p><iframe frameborder="0" height=${height} scrolling=true src=${url} width=${width}></iframe></p>`;
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    // clearIframeDatas();
  };

  return (
    <div>
      <FontAwesomeIcon icon={faLaptopCode} onClick={handleOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Text className={classes.title}>Propriedades do Iframe</Text>
            <TextField
              id="outlined-basic"
              label="Url da página"
              variant="outlined"
              className={classes.inputUrl}
              value={url}
              onChange={(event) => setUrl(event.target.value)}
            />
            <div>
              <div className={classes.inputContainer}>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Largura do iframe"
                    variant="outlined"
                    style={{
                      width: '250px'
                    }}
                    value={width}
                    onChange={(event) => setWidth(event.target.value)}
                    type="number"
                  />
                  <p style={{ marginTop: '10px', color: 'gray' }}>
                    Largura recomendável: "1280"
                  </p>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Altura do iframe"
                    variant="outlined"
                    style={{
                      width: '250px',
                      marginRight: '50px'
                    }}
                    value={height}
                    onChange={(event) => setHeight(event.target.value)}
                    type="number"
                  />
                  <p style={{ marginTop: '10px', color: 'gray' }}>
                    Altura recomendável: "720"
                  </p>
                </div>
              </div>
              <div className={classes.buttonsContainer}>
                <IframeFormButtons Text="Cancelar" handleClick={handleClose} />
                <IframeFormButtons
                  Text={edit ? 'Editar' : 'Criar'}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

// const func = () => {
//   // console.log(data.split())
//   // const dataFormat = data.split(",");
//   // console.log(dataFormat.length)
//   const newObj = iframeArrays.map((obj) => ({
//     // initialData: dataFormat[dataFormat.length -1],
//     initialData: data,
//     novaString: `<p><iframe frameborder="0" height=${obj.height} scrolling="yes" src=${obj.url} width=${obj.width}></iframe></p>`,
//   }));
//   console.log(newObj)
//   // setObjeto(objeto)
// }
