import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '15px 30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '15px'
    }
  },
  attachButton: {
    width: 25,
    height: 25,
    '& svg': {
      width: 25,
      height: 25,
      color: (props) => props.theme.palette.textActiveElement.main
    },
    cursor: 'pointer'
  },
  fileInput: {
    display: 'none'
  },
  sendButton: {
    width: 30,
    height: 30,
    marginLeft: 15,
    '& svg': {
      width: 30,
      height: 30,
      color: (props) => props.theme.palette.primary.main
    },
    '@media (max-width: 600px)': {
      marginLeft: 10
    }
  },
  AttachmentContainer: {
    marginRight: 15,
    '@media (max-width: 600px)': {
      marginRight: 10
    }
  },
  iconButton: {
    marginLeft: '10px'
  },
  sendIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main
  },
  input: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#eaeaea',
    height: '40px',
    borderRadius: '20px',
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  inputProps: {
    display: 'flex',
    width: '100%',
    flex: 1,
    fontSize: '16px'
  }
}));
