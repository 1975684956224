import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  memberCard: {
    boxShadow: '0px 4px 16px 0px rgba(103, 109, 130, 0.16)',
    height: 'max-content',
    padding: '16px',
    borderRadius: '4px'
  },
  item1: {},
  item2: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  item3: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));
