import React from 'react';

import { Toolbar, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerToolbar = styled(({ color, ...otherProps }) => (
  <Toolbar
    {...otherProps}
    style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
      marginTop: 10
    }}
  />
))``;

export const useStyles = makeStyles(() => ({
  ToolBarLogo: {
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  toolbar: {
    padding: 0
  }
}));

export const MyToolbar = styled(Toolbar)`
  display: flex;
  direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
`;

export const Logo = styled.img`
  width: ${(props) => props.width};
  padding: 0;
  height: auto;
`;
