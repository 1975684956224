import React from 'react';
import { useLocation } from 'react-router-dom';

import settings from '~settings';

import { Button, makeStyles, useTheme } from '@material-ui/core';
import base32 from 'base32';

const useStyles = makeStyles((theme) => ({
  sebraeButton: {
    width: '100%',
    height: 35,
    backgroundColor: 'white',
    border: 0,
    borderRadius: 20,
    color: theme.palette.tertiary.main,
    fontSize: '1.6rem',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main
    }
  }
}));

const link = settings.sebraeLoginURL;

export default function SebraeButton() {
  const theme = useTheme();
  const token = new URLSearchParams(useLocation().search).get('t');

  return (
    <Button className={useStyles().sebraeButton} type="button" disableElevation>
      <a
        href={token ? link + `?t=${base32.encode(token)}` : link}
        style={{ textDecoration: 'none', color: theme.palette.primary.main }}
      >
        Entrar com{' '}
        <span style={{ fontStyle: 'italic ' }}> &nbsp;Sebrae&nbsp;</span>{' '}
        <span style={{ fontWeight: '350 ' }}>&nbsp;ID&nbsp;</span>
      </a>
    </Button>
  );
}
