import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import helpIconFilled from '~/assets/icons/help-icon-filled.png';
import helpIcon from '~/assets/icons/help-icon.png';
import InputFile from '~/components/atoms/InputFile';
import TextArea from '~/components/Form/TextArea';
import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper';
import PostMuralCloneSelect from '~/components/molecules/PostMuralCloneSelect';
import i18n from '~/I18n';
import { hasManageAccessToSpace } from '~/services/AccessControl/spaceAccessControl';
import { Creators as UsersActions } from '~/store/ducks/user';

import * as S from './styles';
import { useTheme, CircularProgress, Checkbox } from '@material-ui/core';
import { Form } from '@unform/web';
import PropTypes from 'prop-types';

export default function TextForm({
  lecture,
  addNewStatusMural,
  space,
  loading,
  postStatuses
}) {
  const maxCaracters = 1600;
  const { id: spaceID } = useParams();
  const formRef = useRef(null);
  const { postStatusesRequest } = UsersActions;
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [caractersLeft, setCaractersLeft] = useState(maxCaracters);
  const [isTyping, setIsTyping] = useState(false);
  const [typeComment, setTypeComment] = useState('Activity');
  const [selectFileContent, setSelectFileContent] = useState();
  const [preview, setPreview] = useState('nenhum arquivo selecionado');
  const [selectedSpacesList, setSelectedSpacesList] = useState([]);

  const theme = useTheme();

  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectFileContent(file);
    }
  };

  const handleChange = (event) => {
    setCaractersLeft(maxCaracters - event.target.value.length);
  };

  const handleSubmit = async (data, { reset }) => {
    var status = {
      status: {
        text: data.status,
        type: typeComment
      },
      replicate: JSON.stringify(selectedSpacesList)
    };
    if (selectFileContent) {
      var formData = new FormData();
      formData.append('status[files][]', selectFileContent);
      formData.append('status[text]', data.status);
      formData.append('status[type]', typeComment);
      formData.append('replicate', JSON.stringify(selectedSpacesList));
      status = formData;
    }
    if (lecture || space) {
      addNewStatusMural(status);
    } else if (postStatuses) {
      postStatuses(status, profile.id);
    } else {
      dispatch(postStatusesRequest(status, profile.id));
    }
    reset();
    setSelectFileContent(null);
    toggleIsTyping();
    setCaractersLeft(maxCaracters);
    setPreview('nenhum arquivo selecionado');
  };

  function toggleIsTyping() {
    if (!isTyping) {
      setIsTyping(!isTyping);
    }
  }

  function isDisabled() {
    return caractersLeft == maxCaracters;
  }

  function handleCancel() {
    formRef.current.reset();
    setSelectFileContent(null);
    setIsTyping(!isTyping);
    setCaractersLeft(maxCaracters);
    setPreview('nenhum arquivo selecionado');
  }

  const onChangeHelp = (event) => {
    setTypeComment(event.target.checked === true ? 'Help' : 'Activity');
  };

  useEffect(() => {
    if (selectFileContent) {
      setPreview('1 arquivo selecionado');
    }
  }, [selectFileContent]);

  return (
    <S.Container lecture={lecture} space={space}>
      <Form ref={formRef} onSubmit={handleSubmit} style={{ width: '100%' }}>
        {space && (
          <ComponentAccessControlWrapper
            modelID={spaceID}
            accessFunction={hasManageAccessToSpace}
            Component={PostMuralCloneSelect}
            selectedSpacesList={selectedSpacesList}
            setSelectedSpacesList={setSelectedSpacesList}
          />
        )}
        <TextArea
          name="status"
          onChange={handleChange}
          maxLength={maxCaracters}
          onFocus={toggleIsTyping}
          lecture={lecture}
        />
        <InputFile
          name="InputFile"
          selectFile={selectFile}
          preview={preview}
          caractersLeft={caractersLeft}
        />
        <S.Content hide={isTyping} lecture={lecture}>
          <S.ContainerCheckbox>
            {lecture && (
              <>
                <Checkbox
                  onChange={onChangeHelp}
                  icon={
                    <img
                      src={helpIcon}
                      height="auto"
                      width={22}
                      alt="pessoa com mão levantada"
                    />
                  }
                  checkedIcon={
                    <img
                      src={helpIconFilled}
                      height="auto"
                      width={22}
                      alt="pessoa com mão levantada"
                    />
                  }
                />
                <S.CheckboxLabel>Pedir ajuda</S.CheckboxLabel>
              </>
            )}
          </S.ContainerCheckbox>
          <S.ButtonContainer>
            <S.CancelButton type="button" onClick={handleCancel} theme={theme}>
              {i18n.t('Cancel')}
            </S.CancelButton>
            <S.SendButton
              type="submit"
              disabled={isDisabled() || loading}
              theme={theme}
            >
              {loading ? <CircularProgress size={25} /> : i18n.t('Send')}
            </S.SendButton>
          </S.ButtonContainer>
        </S.Content>
      </Form>
    </S.Container>
  );
}

TextForm.propTypes = {
  lecture: PropTypes.bool,
  addNewStatusMural: PropTypes.func,
  postStatuses: PropTypes.func,
  space: PropTypes.bool,
  loading: PropTypes.bool
};

TextForm.defaultProps = {
  lecture: false,
  addNewStatusMural: null,
  postStatuses: null,
  space: false,
  loading: false
};
