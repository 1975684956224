import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getExerciseGradesCountReport } from '~/services/reports/spaces';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function ExerciseGradesCountReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getExerciseGradesCountReport}
        baseName="relatório_de_notas_em_todos_os_exercícios_"
        id={id}
      />
    </div>
  );
}

ExerciseGradesCountReport.propTypes = {
  id: PropTypes.string
};

ExerciseGradesCountReport.defaultProps = {
  id: ''
};
