import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabContainer = styled.div`
  display: flex;
  margin-top: 2.9rem;
  width: 100%;
  @media (max-width: 495px) {
    justify-content: space-evenly;
  }
`;

export const TabButton = styled.button`
  margin-right: 42px;
  padding-bottom: 3rem;
  height: 25px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: ${(props) =>
    props.selected === props.buttonId ? '2px solid' : '0px'};
  color: ${(props) => props.theme.palette.primary.main};
  @media (max-width: 1460px) {
    font-size: 16px;
  }
  @media (max-width: 495px) {
    margin-right: 0;
  }
`;
