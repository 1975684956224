import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '25%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%'
  },
  modalTitle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    fontSize: '18px',
    color: 'rgba(0,0,0, 0.6)'
  },
  modalTermDescription: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAF0CE',
    fontSize: '17px',
    marginTop: '20px',
    padding: '5%',
    minWidth: '150px',
    borderRadius: '5px',
    color: '#654609'
  },
  modalTermAuthDescription: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
    marginTop: '5px',
    padding: '30px',
    borderRadius: '5px',
    color: 'rgba(0,0,0, 0.6)'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    background: '#F2BC47',
    color: 'white',
    fontSize: '15px',
    padding: '15px 30px 15px 30px',
    '&:hover': {
      color: '#F2BC47'
    }
  }
}));
