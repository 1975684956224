import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Box } from '@material-ui/core';

import settings from '~settings';

import { useStyles } from './styles';

export default function RecaptchaComponent({
  input,
  meta: { touched, error }
}) {
  const classes = useStyles();

  return (
    <Box className={classes.recaptchaContainer}>
      <ReCAPTCHA
        sitekey={settings.recaptchaKey}
        className={classes.recaptcha}
        onChange={(response) => {
          input.onChange(response);
        }}
      />
      {touched && !!error && (
        <span style={{ color: '#FAAF19', fontSize: 12, fontWeight: 'bold' }}>
          {error}
        </span>
      )}
    </Box>
  );
}
