import React from 'react';

import { Box, useTheme } from '@material-ui/core';
import styled from 'styled-components';

export default function BackgroundImage() {
  const theme = useTheme();

  const LeftImageBackground = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
  `;

  return (
    <Box
      style={{
        flex: 1,
        height: theme.size.imageBackgroundLogin.height,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignSelf: 'center',
        width: theme.size.imageBackgroundLogin.width,
        zIndex: -1
      }}
    >
      <LeftImageBackground
        src={theme.initialPagesImageBgLogin}
        alt="imagem de fundo"
      />
    </Box>
  );
}
