import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    background: '#FAFAFA',
    border: '1px dashed #489AF8',
    borderRadius: '12px',
    padding: '5px'
  },
});
