import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  usernameArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 20
  },
  nameEditorBox: {
    display: 'flex'
  },
  editButton: {
    minWidth: 0,
    padding: 0,
    marginLeft: 5
  },
  editIcon: {
    width: 16,
    height: 16,
    color: props => props.theme.palette.primary.main
  }
})