import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MessagesTextInput from '~/components/atoms/MessagesTextInput';
import SearchList from '~/components/Badges/Drawer/LeftDrawer/SearchList';
import MessagesConversationItem from '~/components/molecules/MessagesConversationItem';
import WelcomeToChatMessage from '~/components/molecules/WelcomeToChatMessage';
import i18n from '~/I18n';
import api from '~/services/api';
import {
  userConversationsEmit,
  userConversationsOn
} from '~/socket/conversation-events';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';

export default function ConversationList({
  openDrawer,
  setOpenDrawer,
  setCurChat,
  curChat,
  isFromMessagePage
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [listSearch, setListSearch] = useState([]);
  const [wordSearched, setWordSearched] = useState('');

  const [conversations, setConversations] = useState([]);

  const curUser = useSelector((state) => state.user).profile;

  async function handleSearchQueryChange(newValue) {
    setWordSearched(newValue);
    if (newValue == '') {
      setListSearch([]);
      return;
    }

    const requestBody = { params: { q: newValue, page: 0 } };
    const response = await api
      .get(`/api/search/profiles`, requestBody)
      .catch(() => {
        setListSearch([]);
      });
    if (response.data && response.data.length > 0) setListSearch(response.data);
  }

  async function goToConversation(contact) {
    const curContact = {
      id: contact.id,
      contact_id: contact.id,
      name: contact.display_name,
      display_name: contact.display_name,
      key: '',
      last_message: { body: '' },
      login: contact.login,
      thumbnails: [
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        },
        {
          filename: contact.filename,
          size: '32x32',
          href: contact.thumbnail
        }
      ],
      recipient: {
        id: contact.id,
        name: contact.display_name,
        login: contact.login,
        thumbnails: {
          size: '32x32',
          href: contact.thumbnails
        }
      },
      seen: 0,
      sender_id: curUser.id
    };
    setCurChat(curContact);
  }

  useEffect(() => {
    if (curUser) {
      userConversationsEmit(curUser.id);
      userConversationsOn(curUser.id, (list) => {
        setConversations(list);
      });
    }
  }, [curUser]);

  const welcome =
    conversations?.length === 0 &&
    listSearch?.length === 0 &&
    !isFromMessagePage;

  return (
    <Box className={classes.container}>
      <Box className={classes.inputContainer}>
        <MessagesTextInput
          height={40}
          placeholder={`${i18n.t('SearchPlaceholder')}...`}
          showSearchIcon
          showClearInputButton
          handleValueChange={handleSearchQueryChange}
        />
        <Box className={classes.buttonContainer}>
          <button onClick={() => setOpenDrawer(!openDrawer)}>
            {openDrawer ? (
              <ArrowBackIcon className={classes.arrowIcon} />
            ) : (
              <ArrowForwardIcon className={classes.arrowIcon} />
            )}
          </button>
        </Box>
      </Box>
      <Box style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <SearchList
          listSearch={listSearch}
          setListSearch={setListSearch}
          wordSearched={wordSearched}
          goTo={goToConversation}
        />
      </Box>
      {welcome ? (
        <WelcomeToChatMessage />
      ) : (
        <List component="nav" aria-label="main mailbox folders">
          {conversations?.map((conversation, index) => (
            <MessagesConversationItem
              conversation={conversation}
              key={index}
              index={index}
              setCurChat={setCurChat}
              curChat={curChat}
            />
          ))}
        </List>
      )}
    </Box>
  );
}

ConversationList.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.shape(),
  setCurChat: PropTypes.shape(),
  curChat: PropTypes.number,
  isFromMessagePage: PropTypes.bool
};

ConversationList.defaultProps = {
  openDrawer: true,
  setOpenDrawer: null,
  setCurChat: null,
  curChat: -1,
  isFromMessagePage: false
};
