import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Creating action types & creators
 */

export const { Types, Creators } = createActions({
  postUserRequest: ['user', 'admin'],
  postUserSuccess: ['user'],
  postUserFailure: [],
  getProfileRequest: [],
  getProfileSuccess: ['profile', 'badges'],
  getProfileFailure: [],
  postStatusesRequest: ['status', 'login'],
  postStatusesSuccess: ['status'],
  postStatusesFailure: [],
  putProfileRequest: ['user', 'login'],
  putProfileSuccess: [],
  putProfileFailure: [],
  putEditUserRequest: ['user', 'id', 'successMessage', 'failedMessage'],
  putEditUserSuccess: [],
  putEditUserFailure: [],
  putUserReset: [],
  getMyContactsRequest: ['login'],
  getMyContactsSuccess: ['contacts'],
  getMyContactsFailure: [],
  getMyConnectionsRequest: ['login', 'status'],
  getMyConnectionsSuccess: ['connections'],
  getMyConnectionsFailure: [],
  getNextLecturesRequest: ['id'],
  getNextLecturesSuccess: ['next_lectures'],
  getNextLecturesFailure: [],
  setUsernameAndLogin: ['first_name', 'last_name', 'login'],
  getContactKindsRequest: [],
  getContactKindsSuccess: ['kinds'],
  getContactKindsFailure: [],
  getMemberRequest: ['member_id', 'failedMessage'],
  getMemberSuccess: ['member'],
  getMemberFailure: [],
  clearMemberData: [],
  putProfileUpdate: ['profile'],
  setRecentSearches: ['searchResults'],
  deleteItemRecentSearches: ['id'],
  clearRecentSearches: [],
  setSeenTour: []
});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE = {
  profile: null,
  loading: false,
  badges: null,
  statuses: [],
  mycontacts: null,
  myconnections: [],
  nextLectures: null,
  page: 1,
  noMoreStatuses: false,
  contactKinds: [],
  loadingKinds: false,
  recentSearches: [],
  seenTour: true,
  member: null,
  isLoadingRequestMember: true,
  isLoadingEditUser: false
};

// user
const postUserRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const postUserSuccess = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

const postUserFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getProfileRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });
const getProfileSuccess = (state = INITIAL_STATE, { profile, badges }) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.profile = profile;
    draft.badges = badges;

    const localStorageSeenTour = localStorage.getItem('@openredu_web/seenTour');
    const loginCount = profile && profile.login_count ? profile.login_count : 2;

    if (loginCount === 1 && !localStorageSeenTour) {
      draft.seenTour = false;
    } else if (loginCount > 1 && localStorageSeenTour !== null) {
      localStorage.removeItem('@openredu_web/seenTour');
    }
  });

const getProfileFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

const getNextLecturesRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getNextLecturesSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.nextLectures = action.next_lectures;
  });

const getNextLecturesFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

const postStatusesRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const postStatusesSuccess = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.loading = false;
    draft.statuses = [action.status, ...draft.statuses];
  });
};

const postStatusesFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

// profile
const putProfileRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const putProfileSuccess = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

const putProfileFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

// user
const putEditUserRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.isLoadingEditUser = true;
  });

const putEditUserSuccess = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.isLoadingEditUser = false;
  });
};

const putEditUserFailure = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.isLoadingEditUser = false;
  });
};

const putUserReset = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.profile = null;
    draft.loading = false;
    draft.badges = null;
    draft.statuses = [];
    draft.mycontacts = null;
    draft.myconnections = [];
    draft.nextLectures = null;
    draft.page = 1;
    draft.noMoreStatuses = false;
    draft.recentSearches = [];
    draft.seenTour = true;
  });

// myContacts
const getMyContactsRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getMyContactsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.mycontacts = action.contacts;
  });

const getMyContactsFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

// connections
const getMyConnectionsRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getMyConnectionsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.myconnections = action.connections;
  });

const getMyConnectionsFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

const setUsernameAndLogin = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.profile.first_name = action.first_name;
    draft.profile.last_name = action.last_name;
    draft.profile.login = action.login;
  });

const getContactKindsRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loadingKinds = true;
  });

const getContactKindsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.contactKinds = action.kinds;
    draft.loadingKinds = false;
  });

const getContactKindsFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loadingKinds = false;
  });

const getMemberRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.isLoadingRequestMember = true;
  });

const getMemberSuccess = (state = INITIAL_STATE, { member }) => {
  return produce(state, (draft) => {
    draft.member = member;
    draft.isLoadingRequestMember = false;
  });
};

const getMemberFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.isLoadingRequestMember = false;
  });

const clearMemberData = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.member = null;
    draft.isLoadingRequestMember = false;
    draft.isLoadingEditUser = false;
  });

const putProfileUpdate = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    const { profile } = action;

    Object.keys(profile).map((key) => {
      draft.profile[key] = profile[key];
    });
  });

const setRecentSearches = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    const { searchResults } = action;
    draft.recentSearches = searchResults;
  });

const deleteItemRecentSearches = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    const { id } = action;
    draft.recentSearches = state.recentSearches.filter(
      (item) => item.id !== id
    );
  });

const clearRecentSearches = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.recentSearches = [];
  });

const setSeenTour = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.seenTour = true;
    localStorage.setItem('@openredu_web/seenTour', true);
  });

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.POST_USER_REQUEST]: postUserRequest,
  [Types.POST_USER_REQUEST]: postUserSuccess,
  [Types.POST_USER_FAILURE]: postUserFailure,
  [Types.GET_PROFILE_REQUEST]: getProfileRequest,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_FAILURE]: getProfileFailure,
  [Types.POST_STATUSES_REQUEST]: postStatusesRequest,
  [Types.POST_STATUSES_SUCCESS]: postStatusesSuccess,
  [Types.POST_STATUSES_FAILURE]: postStatusesFailure,
  [Types.PUT_PROFILE_REQUEST]: putProfileRequest,
  [Types.PUT_PROFILE_SUCCESS]: putProfileSuccess,
  [Types.PUT_PROFILE_FAILURE]: putProfileFailure,
  [Types.PUT_EDIT_USER_REQUEST]: putEditUserRequest,
  [Types.PUT_EDIT_USER_SUCCESS]: putEditUserSuccess,
  [Types.PUT_EDIT_USER_FAILURE]: putEditUserFailure,
  [Types.PUT_USER_RESET]: putUserReset,
  [Types.GET_MY_CONTACTS_REQUEST]: getMyContactsRequest,
  [Types.GET_MY_CONTACTS_SUCCESS]: getMyContactsSuccess,
  [Types.GET_MY_CONTACTS_FAILURE]: getMyContactsFailure,
  [Types.GET_MY_CONNECTIONS_REQUEST]: getMyConnectionsRequest,
  [Types.GET_MY_CONNECTIONS_SUCCESS]: getMyConnectionsSuccess,
  [Types.GET_MY_CONNECTIONS_FAILURE]: getMyConnectionsFailure,
  [Types.GET_NEXT_LECTURES_REQUEST]: getNextLecturesRequest,
  [Types.GET_NEXT_LECTURES_SUCCESS]: getNextLecturesSuccess,
  [Types.GET_NEXT_LECTURES_FAILURE]: getNextLecturesFailure,
  [Types.SET_USERNAME_AND_LOGIN]: setUsernameAndLogin,
  [Types.GET_CONTACT_KINDS_REQUEST]: getContactKindsRequest,
  [Types.GET_CONTACT_KINDS_SUCCESS]: getContactKindsSuccess,
  [Types.GET_CONTACT_KINDS_FAILURE]: getContactKindsFailure,
  [Types.PUT_PROFILE_UPDATE]: putProfileUpdate,
  [Types.GET_MEMBER_REQUEST]: getMemberRequest,
  [Types.GET_MEMBER_SUCCESS]: getMemberSuccess,
  [Types.GET_MEMBER_FAILURE]: getMemberFailure,
  [Types.CLEAR_MEMBER_DATA]: clearMemberData,
  [Types.SET_RECENT_SEARCHES]: setRecentSearches,
  [Types.DELETE_ITEM_RECENT_SEARCHES]: deleteItemRecentSearches,
  [Types.CLEAR_RECENT_SEARCHES]: clearRecentSearches,
  [Types.SET_SEEN_TOUR]: setSeenTour
});
