import React from 'react';

import { useStyles } from './styles';
import { ButtonBase, Typography, useTheme } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';

export const ClearFiltersButton = ({ clearFilters, disableClearFilters }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ButtonBase
      disabled={disableClearFilters}
      className={classes.button}
      onClick={clearFilters}
    >
      <FilterListIcon
        style={{
          fontSize: '24px',
          color: disableClearFilters ? theme.palette.activeText.main : '#fff'
        }}
      />
      <Typography className={classes.buttonLabel}>Limpar Filtros</Typography>
    </ButtonBase>
  );
};

ClearFiltersButton.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  disableClearFilters: PropTypes.bool
};
