import api from '~/services/api';

// Establishes the base path for API requests related to associations between users and spaces.
const baseURL = '/api/user_space_associations';

// Modifies the role of an existing association between a user and a space.
export const update = async (UserSpaceAssociationID, role) => {
  // Performs a PUT request to update the role for a specified user-space association.
  const { data } = await api.put(`${baseURL}/${UserSpaceAssociationID}`, {
    user_space_association: { role }
  });
  // Returns the data received from the server, typically including details of the updated association.
  return data;
};

// Establishes a new link between a user and a space, assigning a specific role to the user within that space.
export const create = async (spaceID, userID, role) => {
  // Initiates a POST request to form a new user-space association, specifying the space ID, user ID, and the user's role in the space.
  const { data } = await api.post(baseURL, {
    user_space_association: {
      space_id: spaceID,
      user_id: userID,
      role
    }
  });
  // Returns the details of the newly created association as provided by the server's response.
  return data;
};

// Makes the 'update' and 'create' functions available for import and use in other parts of the application.
export default {
  update,
  create
};
