import React from 'react';

import { ReactComponent as AppleStoreIcon } from '~/assets/icons/apple-store.svg';
import { ReactComponent as PlayStoreIcon } from '~/assets/icons/play-store.svg';
import settings from '~/settings';

import { useStyles } from './styles';
import { Box, Typography, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export default function Footer() {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.boxLogo}>
          <img src={theme.logoColorful} className={classes.logo} />
          <Box className={classes.boxCustomLinks}>
            <Link
              href="/help"
              className={classes.customLink}
              target="_blank"
              rel="noreferrer"
            >
              <HeadsetMicIcon
                style={{ fontSize: '24px', marginRight: '8px' }}
              />
              <Typography className={classes.customLinkText}>
                Fale Conosco
              </Typography>
            </Link>
            <Link
              href="/privacy-policy-and-usage-terms"
              className={classes.customLink}
              target="_blank"
              rel="noreferrer"
            >
              <LibraryBooksIcon
                style={{
                  height: '25.5px',
                  width: '25.5px',
                  marginRight: '8px'
                }}
              />
              <Typography className={classes.customLinkText}>
                Política de privacidade e termos de uso
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box>
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: '500',
              color: theme.palette.textActiveElement.main,
              marginBottom: '8px'
            }}
          >
            Baixe nosso Aplicativo
          </Typography>
          <Box
            display="flex"
            style={{
              gap: '4px'
            }}
          >
            {settings.appstore && (
              <Link
                target="_blank"
                rel="noreferrer"
                href={settings.appstore}
                className={classes.customMobileLink}
              >
                <Box>
                  <Typography
                    style={{
                      color: theme.palette.textActiveElement.main,
                      fontSize: '10px',
                      fontWeight: '500'
                    }}
                  >
                    Baixar na
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.textActiveElement.main,
                      fontSize: '18px',
                      fontWeight: '500',
                      lineHeight: '24px'
                    }}
                  >
                    App Store
                  </Typography>
                </Box>
                <AppleStoreIcon
                  style={{ fill: theme.palette.textActiveElement.main }}
                />
              </Link>
            )}
            {settings.playstore && (
              <Link
                target="_blank"
                rel="noreferrer"
                href={settings.playstore}
                className={classes.customMobileLink}
              >
                <Box>
                  <Typography
                    style={{
                      color: theme.palette.textActiveElement.main,
                      fontSize: '10px',
                      fontWeight: '500'
                    }}
                  >
                    DISPONÍVEL NO
                  </Typography>
                  <Typography
                    style={{
                      color: theme.palette.textActiveElement.main,
                      fontSize: '18px',
                      fontWeight: '500',
                      lineHeight: '24px'
                    }}
                  >
                    Google Play
                  </Typography>
                </Box>
                <PlayStoreIcon
                  style={{ fill: theme.palette.textActiveElement.main }}
                />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      <Box paddingY="16px">
        <Typography
          style={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '500',
            color: theme.palette.textActiveElement.main
          }}
        >
          Copyright © 2024 | Todos os direitos reservados
        </Typography>
      </Box>
    </Box>
  );
}
