import React from 'react'
import Main from '../Main'

export default function ImageMiniCard({ size, ...rest }){
  return <Main  color="#f053c5" 
                backgroundColor="#f9ccee"
                size={size}
                text="Imagem"
                { ...rest }
                />
}