import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const CountLeftCaracters = styled.span`
  color: #707070;
  font-size: 12px;
  padding-bottom: 5px;
`;

export const useStyles = makeStyles((theme) => ({
  attachButton: {
    width: 25,
    height: 25,
    marginRight: 15,
    '& svg': {
      width: 25,
      height: 25,
      color: (props) => props.theme.palette.textActiveElement.main
    }
  },
  AttachmentContainer: {
    marginBottom: 15,
    marginRight: 15,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginRight: 10
    }
  }
}));