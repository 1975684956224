import React from 'react';

import { Toolbar, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Container = styled(({ ...otherProps }) => (
  <Box {...otherProps} display="flex" flexDirection="row wrap" />
))``;

export const MyToolbar = styled(Toolbar)`
  display: flex;
  direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
`;

export const Logo = styled.img`
  width: 67px;
  height: 37px;
`;
