import React from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import Field from '../../GenericFormWrapper/Field';
import { Collapse, Switch } from '@material-ui/core';

export default function PagseguroInfo() {
  const { watch } = useFormContext();

  return (
    <div>
      <Field
        name="monetized_through_pagseguro"
        label={TextPrimary(`Monetizar ${i18n.t('StructuresName.Course.SingularLower')}`)}
        labelPlacement={'end'}
        checked={watch('monetized_through_pagseguro')}
        Component={Switch}
      />

      <Collapse in={watch('monetized_through_pagseguro')}>
        <div>
          <Field
            name="pagseguro_amount"
            label={<Text color={'gray'}>{`Preço do ${i18n.t('StructuresName.Course.SingularLower')}`}</Text>}
            Component={Input}
            placeholder="Ex: 30.00"
            type="number"
          />
        </div>
      </Collapse>
    </div>
  );
}
