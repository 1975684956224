import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Show from '~/components/atoms/Show';
import Text from '~/components/atoms/Text';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import LaplaceSelectExerciseListModal from '~/components/organisms/LectureForm/Lectureable/Forms/Exercise/LaplaceSelectExerciseListModal';
import { TextPrimary } from '~/utils/forms';

import InovaulaImportExerciseButton from './InovaulaImportExerciseButton';
import InovaulaSelectExerciseListModal from './InovaulaSelectExerciseListModal';
import LaplaceImportExerciseButton from './LaplaceImportExerciseButton';
import QuestionFields from './QuestionFields';
import Questions from './QuestionFields/Questions';
import { useStyles } from './styles';
import { Box, Switch } from '@material-ui/core';

export default function Exercise() {
  const classes = useStyles();
  const [SelectExerciseListOpen, setSelectExerciseListOpen] = useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [editing, setEditing] = useState(false);
  const [questions, setQuestions] = useState(
    watch('lectureable.exercise.questions')
  );

  const [currentQuestion, setCurrentQuestion] = useState({
    id: null,
    weight: 1.0,
    alternatives: [],
    statement: '',
    explanation: '',
    position:
      watch('lectureable.exercise.questions')?.length > 0
        ? watch('lectureable.exercise.questions').at(-1).position + 1
        : 1,
    _destroy: false
  });

  const {
    profile: {
      laplace_access: hasLaplaceAccess,
      inovaula_access: hasInovaulaAccess
    }
  } = useSelector((state) => state.user);

  const deleteQuestion = (question) => {
    let position = 1;
    setQuestions((prev) => {
      question._destroy = true;

      const destroyedQuestions = prev.filter((elem) => elem._destroy);
      const validQuestions = prev.filter((elem) => !elem._destroy);

      for (var item of validQuestions) {
        item.position = position;
        position++;
      }

      setCurrentQuestion({
        weight: 1.0,
        alternatives: [],
        statement: '',
        explanation: '',
        position: position
      });

      return [...validQuestions, ...destroyedQuestions];
    });

    setEditing(false);
  };

  const setQuestionEdition = (question) => {
    setCurrentQuestion(question);
    setEditing(true);
  };

  const resetFields = () => {
    setCurrentQuestion({
      weight: 1.0,
      alternatives: [],
      statement: '',
      explanation: '',
      position: questions.at(-1).position + 1
    });
    setEditing(false);
  };

  const addQuestion = (question) => {
    if (
      !question.statement ||
      question.alternatives.length <= 1 ||
      question.alternatives.filter((elem) => elem.correct).length !== 1
    ) {
      return false;
    }

    var existingQuestion = questions.find(
      (elem) => elem.position === question.position && !elem._destroy
    );
    var index = questions.findIndex(
      (elem) => elem.position === question.position && !elem._destroy
    );

    if (existingQuestion) {
      questions[index] = question;
      var newQuestions = questions;

      setQuestions(newQuestions);
      setCurrentQuestion({
        weight: 1.0,
        alternatives: [],
        statement: '',
        explanation: '',
        position: questions.at(-1).position + 1
      });
    } else {
      setQuestions((prev) => [...prev, question]);
      setCurrentQuestion({
        weight: 1.0,
        alternatives: [],
        statement: '',
        explanation: '',
        position: question.position + 1
      });
    }

    setEditing(false);
    return true;
  };

  const NumberInput = ({ ...rest }) => {
    return (
      <input
        className={classes.numberField}
        type="number"
        min={1}
        max={1000}
        step={0.25}
        {...rest}
      />
    );
  };

  function handleClickImportExercise() {
    setSelectExerciseListOpen(true);
  }

  function handleCloseImportExercise() {
    setSelectExerciseListOpen(false);
  }

  useEffect(() => {
    setValue('lectureable.exercise.questions', questions);
  }, [questions]);

  return (
    <Box>
      {errors.lectureable?.exercise?.questions ? (
        <div className={classes.field}>
          <Text color="error" variant={'mediumText'}>
            {' '}
            {errors.lectureable.exercise.questions.message}{' '}
          </Text>
        </div>
      ) : null}

      <div className={classes.field}>
        <Field
          label={TextPrimary('Sequência aleatória?')}
          name="lectureable.exercise.shuffle"
          labelPlacement={'end'}
          control={control}
          checked={watch('lectureable.exercise.shuffle')}
          Component={Switch}
          size="small"
        />
        <Text className={classes.tip} variant={'smallText'}>
          Ative essa opção para embaralhar a ordem das questões
        </Text>
      </div>

      <div className={classes.field}>
        <Field
          label={TextPrimary('Pontuação Máxima')}
          labelPlacement="start"
          name="lectureable.exercise.maximum_grade"
          control={control}
          Component={NumberInput}
        />
      </div>

      <Show condition={hasLaplaceAccess && !hasInovaulaAccess}>
        <LaplaceImportExerciseButton onClick={handleClickImportExercise} />
        <LaplaceSelectExerciseListModal
          open={SelectExerciseListOpen}
          handleClose={handleCloseImportExercise}
        />
      </Show>

      <Show condition={hasInovaulaAccess}>
        <InovaulaImportExerciseButton onClick={handleClickImportExercise} />
        {SelectExerciseListOpen && (
          <InovaulaSelectExerciseListModal
            open={SelectExerciseListOpen}
            handleClose={handleCloseImportExercise}
          />
        )}
      </Show>

      <div className={classes.field}>
        {questions?.length > 0 ? (
          <Questions
            questions={questions}
            deleteFunc={deleteQuestion}
            editFunc={setQuestionEdition}
          />
        ) : null}
      </div>

      <div className={classes.field}>
        <QuestionFields
          addQuestion={addQuestion}
          currentQuestion={currentQuestion}
          resetQuestionFields={resetFields}
          editingQuestion={editing}
        />
      </div>
    </Box>
  );
}
