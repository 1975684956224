/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import CourseForm from '~/components/organisms/CourseForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { courseValidations } from '~/utils/CourseValidation/course';
import { formatDateToDateZeroHour } from '~/utils/dates';

import { createCourse, deleteLimit } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CreateCourse() {
  const theme = useTheme();
  const { id: environmentID } = useParams();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setSubmissionStatus] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    name: '',
    initials: null,
    description: null,
    generate_certificate: null,
    responsible_for_signing: null,
    signature: null,
    signature_owner_organization: null,
    signature_owner_position: null,
    minimum_rate_for_completion: 0,
    certificate_logo_client: null,
    is_course_period: false,
    workload: 0,
    keywords: [],
    key: null,
    degree_ids: [],
    course_start_date: formatDateToDateZeroHour(new Date()),
    course_end_date: formatDateToDateZeroHour(new Date(), 1),
    id_limit: null,
    user_limit_enabled: null,
    user_limit: null
  };

  const navOptions = [i18n.t('Information')];

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    {
      name: i18n.t('StructuresName.Environment.SingularUpper'),
      path: `/dashboard/environments/${environmentID}`
    },
    {
      name: i18n.t('CreateCourse'),
      path: `/dashboard/environments/${environmentID}/courses/new`
    }
  ];

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <>
          <GenericFormWrapper
            Form={CourseForm}
            defaultValues={defaultValues}
            resolver={courseValidations}
            isSubmitting={isSubmitting}
            {...props}
          />
        </>
      );
    }

    return false;
  }

  function handleCancel() {
    history.push(`/dashboard/environments/${environmentID}`);
  }

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    handleSuccess(
      `O ${i18n.t('StructuresName.Course.SingularLower')} foi atualizado!`,
      enqueueSnackbar,
      `/dashboard/environments/${environmentID}`,
      history
    );
  };

  const finalizeFailure = () => {
    window.alert(
      `Não foi possível criar o ${i18n.t(
        'StructuresName.Course.SingularLower'
      )}. Verifique novamente os campos do formulário!`
    );
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formData = new FormData();

    let formattedTagList = '';
    let signatureFileBlob;
    let userlimit;
    let formattedDegrees = [];

    if (fields.keywords && fields.keywords.length) {
      formattedTagList = fields.keywords
        .map((keyword) => keyword.name)
        .join(',');
    }

    delete fields.keywords;

    if (fields.user_limit_enabled === false && fields.id_limit) {
      deleteLimit(fields.id_limit);
      delete fields.id_limit;
      delete fields.user_limit;
    }

    if (fields.user_limit) {
      userlimit = {
        user_limit: fields.user_limit
      };
    }

    if (fields.signature && typeof fields.signature === 'object') {
      signatureFileBlob = fields.signature;
    } else {
      if (!fields.generate_certificate) {
        delete fields.responsible_for_signing;
        delete fields.signature_owner_organization;
        delete fields.signature_owner_position;
        delete fields.minimum_rate_for_completion;
        delete fields.signature;
      }
    }

    if (fields.profile_attributes) {
      formData.append(
        'course[profile_attributes][image]',
        fields.profile_attributes
      );
    }

    if (fields.cape_attributes) {
      formData.append('course[cape_attributes][image]', fields.cape_attributes);
    }

    delete fields.profile_attributes;
    delete fields.cape_attributes;

    if (fields.degree_ids) {
      formattedDegrees = fields.degree_ids
        .map((degreeID, index) => (degreeID ? index.toString() : null))
        .filter((elt) => elt != null);
    }

    delete fields.degree_ids;

    let newDegree = '[';
    formattedDegrees.forEach((degreeId, index) => {
      if (index === formattedDegrees.length - 1) {
        newDegree += `${degreeId}`;
      } else {
        newDegree += `${degreeId},`;
      }
    });
    newDegree += ']';
    formData.append(`course[degree_ids]`, newDegree);

    // Append fields to the FormData object
    for (const key in fields) {
      // eslint-disable-next-line no-prototype-builtins
      if (fields.hasOwnProperty(key) && Boolean(fields[key])) {
        formData.append(`course[${key}]`, fields[key]);
      }
    }

    if (fields.generate_certificate) {
      formData.append('course[signature]', signatureFileBlob);
    }
    formData.append('course[tag_list]', formattedTagList);
    formData.append('course[subscription_type]', fields.subscription_type * 1);

    if (fields.user_limit_enabled) {
      formData.append(
        'course[enrollment_limit_attributes][user_limit]',
        userlimit ? userlimit.user_limit : ''
      );
    }

    formData.append('course[published]', 1);

    createCourse(environmentID, formData)
      .then(finalizeSuccess)
      .catch(finalizeFailure)
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    document.title = `${theme.title} | Criar ${i18n.t(
      'StructuresName.Course.SingularUpper'
    )}`;
  }, []);

  return (
    <StepperForm
      unclickable={false}
      breadcrumbs={breadcrumbs}
      onSubmit={submitForm}
      formTitle={i18n.t('CreateCourse')}
      submitButtonTitle={i18n.t('Create')}
      handleCancel={handleCancel}
      navOptions={navOptions}
      renderContent={renderContent}
    />
  );
}
