import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: ({ conquests, borderBottomColor, keepBorderBottom }) => ({
    borderBottom: `${conquests ? '0px' : '1px'} solid ${borderBottomColor}`,
    boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: keepBorderBottom
        ? `${conquests ? '0px' : '1px'} solid ${borderBottomColor}`
        : 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  }),
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  expandIcon: ({ conquests }) => ({
    minHeight: 15,
    minWidth: 15,
    height: 26,
    width: 26,
    borderRadius: 13,
    opacity: 1,
    backgroundColor: conquests ? '' : theme.palette.primary.main,
    color: 'white',
    // marginRight: 30,
  }),
  content: {
    flexGrow: 0,
  },
  expansionPanelSummaryRoot: ({ lecture }) => ({
    justifyContent: lecture ? 'space-between' : 'flex-start',
    '&.Mui-expanded': {
      minHeight: '30px',
    },
    '@media (max-width: 600px)': {
      justifyContent: 'space-between',
    },
  }),
  expanded: {
    minHeight: '30px',
    marginBottom: '0 !important',
  },
}));
