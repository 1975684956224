import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: '0.5rem',
    backgroundColor: 'white',
    height: 40,
    fontSize: 14,
    padding: '4px 16px'
  },

  inputField: {
    '&::placeholder': {
      color: '#a8b1ce',
      opacity: 1
    }
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  iconbutton: {
    marginRight: 5,
    color: theme.palette.primary.main,
    fontSize: 20
  }
}));

export const Error = styled.span`
  color: #faaf19;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Label = styled.label`
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
`;

export const Gap = styled.div`
  height: 1rem;
`;
