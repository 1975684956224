import { makeStyles } from '@material-ui/core/styles';
// import Skeleton from '@material-ui/lab/Skeleton';
// import styled from 'styled-components';

export const useStyles = makeStyles({
  wrapHelper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight:'15px',
    '@media (max-width: 900px)': {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    '@media (max-width: 600px)': {
      //   paddingRight: 25,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: '0',
    },
  },
  item:{
      justifyContent:'flex-start !important',
      alignItems:'flex-start',
  },
  courseItem: {
    justifyContent:'space-between',
      alignItems:'space-between',
      background:'red',
      width:'100%,'
      
  }
});
