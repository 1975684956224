import { all, put, call, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { Types, Creators as OverviewActions } from './index';

export function* getOverview(action) {
  const { getOverviewSuccess, getOverviewFailure } = OverviewActions;
  const { page, id } = action;

  try {
    const response = yield call(api.get, `api/users/${id}/overview`, {
      params: { page }
    });
    yield put(getOverviewSuccess(response.data));
  } catch (error) {
    yield put(getOverviewFailure());
  }
}

export default all([takeLatest(Types.GET_OVERVIEW_REQUEST, getOverview)]);
