import React from 'react';

import { Box, useTheme }                             from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TextPrimary }                               from '~/utils/forms';
import { faTimes }                                   from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon }                           from '@fortawesome/react-fontawesome';

import { useStyles } from './styles';

export default function Keywords() {
  const theme   = useTheme()
  const classes = useStyles();

  const { control }                = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'keywords', keyName: 'keywordID'});

  const renderKeyword = (name, index) => { 
    return(
      <>
        <label className={classes.tagName}>{name ? name : ''}</label>
        <button type="button" className={classes.tagDelete} onClick={() => remove(index)}>
          <FontAwesomeIcon icon={faTimes} color={theme.palette.textAndBackground.main} style={{ fontSize: 12 }}/>
        </button>
      </>
    )
  }

  const renderKeywordField = (keyword, index) => {
    return (
      <Box>
        <Controller name={`keywords.${index}.name`} control={control} render={({ field }) => (
                                                                                                <Box className={`${classes.tag} ${classes.editTag}`} { ...field }>
                                                                                                  {renderKeyword(keyword?.name, index)}
                                                                                                </Box>
                                                                                              )}/>
      </Box>)
  }

  return (
    <>
      <div className={classes.label}>
        {TextPrimary("Palavras-chave")}
      </div>
      
      <Box className={classes.mainArea}>
        <Box className={classes.interestsList}>
          {fields?.map(renderKeywordField)}
          
          <input
            type="text"
            placeholder='Insira a palavra-chave e pressione enter'
            onKeyPress={(event) => {
              if (event.key == 'Enter' && event.target.value) {
                event.preventDefault();
                append({ name: event.target.value });
                event.target.value = '';
              }
            }}
            className={classes.input}
            maxLength={50}
          />
        
        </Box>
      </Box>
    </>
  );
}