import React from 'react';

import { useStyles } from './styles';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const InputFieldAdornment = ({ setValues, values }) => {
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values?.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <InputAdornment position="end" className={classes.adornment}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {values?.showPassword ? (
          <Visibility className={classes.iconbutton} />
        ) : (
          <VisibilityOff className={classes.iconbutton} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

InputFieldAdornment.propTypes = {
  setValues: PropTypes.func,
  values: PropTypes.shape({
    showPassword: PropTypes.bool
  })
};
InputFieldAdornment.defaultProps = {
  setValue: () => { },
  values: {
    showPassword: false
  }
};

export default InputFieldAdornment;
