import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Hidden } from '@material-ui/core';
import PreviewBar from './PreviewBar';
import Text from '~/components/atoms/Text';

import Desktop from './Desktop';
import FileInput from './FileInput';
import Mobile from './Mobile';

export default function DragDropFileInput({
  value,
  permittedFileTypes,
  fileTypesDescription,
  maxSize,
  onChange,
  reset,
  showPreviewBar,
  aspect
}) {
  const [selectedFile, setSelectedFile] = useState(value);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const canUploadType = (type) => {
    return permittedFileTypes.includes(type);
  };

  const checkMaxSize = (size) => {
    if (maxSize) {
      return size <= maxSize;
    }

    return true;
  };

  useEffect(() => {
    if (reset) {
      setSelectedFile(null);
    }
  }, [reset]);

  useEffect(() => {
    return () => {
      setFileTypeError(false);
      setSelectedFile(null);
    };
  }, []);

  useEffect(() => {
    onChange(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setSelectedFile(value);
  }, [value]);
  let dragDropId = Math.floor(Math.random() * (1000 - 100) + 100);

  const handleDelete = () => {
    setSelectedFile(null);
    setEditImage(false);
  };

  const handleEdit = () => {
    setEditImage(true);
  };

  const formatFileSize = (sizeInBytes) => {
    const kilobytes = sizeInBytes / 1024;
    if (kilobytes < 1024) {
      return kilobytes.toFixed(2) + ' KB';
    } else {
      const megabytes = kilobytes / 1024;
      return megabytes.toFixed(2) + ' MB';
    }
  };

  const InputWrapped = () => (
    <FileInput
      accept={permittedFileTypes}
      setSelectedFile={setSelectedFile}
      setFileTypeError={setFileTypeError}
      canUploadType={canUploadType}
      checkMaxSize={checkMaxSize}
      setSizeError={setSizeError}
      dragDropId={dragDropId}
    />
  );

  return (
    <>
      <Box mb={0.3}>
        <Hidden xsDown>
          <DndProvider backend={HTML5Backend}>
            <Desktop
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              fileTypeError={fileTypeError}
              setFileTypeError={setFileTypeError}
              sizeError={sizeError}
              setSizeError={setSizeError}
              canUploadType={canUploadType}
              checkMaxSize={checkMaxSize}
              Input={InputWrapped}
            />
          </DndProvider>
        </Hidden>

        <Hidden smUp>
          <Mobile
            fileTypeError={fileTypeError}
            sizeError={sizeError}
            selectedFile={selectedFile}
            Input={InputWrapped}
          />
        </Hidden>
      </Box>
      <Text color="gray" variant="mediumText">
        {fileTypesDescription}
      </Text>
      {showPreviewBar && selectedFile?.type && (
        <PreviewBar
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileUrl={URL.createObjectURL(selectedFile)}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          formatFileSize={formatFileSize}
          editImage={editImage}
          setEditImage={setEditImage}
          aspect={aspect}
        />
      )}
    </>
  );
}
