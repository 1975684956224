import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function TableLoadingFeedback() {
  return (
    <>
      {Array.from(new Array(10)).map((_, index) => (
        <TableRow key={index} height={'57px'}>
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>{' '}
          <TableCell
            style={{
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <Skeleton height="32px" variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
