import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 5,
    padding: '10px 0px',
    marginTop: 12
  },
  course__title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  courses__wraper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '10px 15px',

    '@media (min-width: 320px) and (max-width: 767px)': {
      gap: '15px 15px',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  certificate__wraper: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    paddingBottom: '10px',
    paddingTop: '10px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    textAlign: 'left',
    '@media (max-width: 600px)': {
      width: '50%'
    }
  }
}));
