import React from 'react';

import { Box } from '@material-ui/core';

import { useStyles } from './styles';

export default function TitleWrapHelper({ children, type }) {
  const classes = useStyles();
  switch (type) {
    case "item":
      return <Box className={`${classes.wrapHelper} ${classes.item}`}>{children}</Box>;
   case "courseItem":
      return <Box className={`${classes.wrapHelper} ${classes.courseItem}`}>{children}</Box>;
  
    default:
      return <Box className={classes.wrapHelper}>{children}</Box>;
  }
}
