import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '865px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginBottom: '50px',
    '& > *': {
      marginBottom: '8px'
    }
  }
}));
