import React from 'react';

import { ShimmerText } from './styles';

const Loading = () => {

  return (
    <ShimmerText height={60} width="100%" />
  );
};

export default Loading;
