import React from 'react';

import { Typography, Paper, Box, useTheme } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export default function TooltipHTML({ message }) {
  const theme = useTheme();
  return (
    <Box
      px={2}
      py={1}
      style={{
        backgroundColor: '#FCFCFC',
        color: theme.palette.secondary.main
      }}
      component={Paper}
    >
      <Typography style={{ fontWeight: 600 }} variant="h4">
        Chave de Acesso
      </Typography>
      <Typography align="justify" style={{ fontSize: 14, marginTop: 4 }}>
        {message}
      </Typography>
    </Box>
  );
}

TooltipHTML.propTypes = {
  message: PropTypes.string
};

TooltipHTML.defaultProps = {
  message: ''
};
