import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as EmptyEnvironmentsDashboardFeedback } from '~/assets/images/empty-environments-dashboard-feedback.svg';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { EmptyFeedback } from '~/pages/dashboard/EmptyFeedback';
import { getAll } from '~/services/environment';
import settings from '~settings';

import Shimmer from './Loading/shimmer';
import { Container, EnvironmentList, useStyles } from './styles';
import { useTheme, Box, ButtonBase } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export default function Environments() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const targetRoute = '/dashboard/environments';

  const [isFetching, setIsFetching] = useState(false);
  const [environments, setEnvironments] = useState([]);
  useEffect(() => {
    setIsFetching(true);
    getAll(1, { ord: '', direction: '' })
      .then((fetchedEnvironments) => {
        setEnvironments(fetchedEnvironments);
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  return (
    <Container style={{ height: 300 }}>
      <Link to={targetRoute}>
        <Text variant="sideBarTitle" color="white" fontWeight={500}>
          {i18n.t('MyEnvironments')}
        </Text>
      </Link>
      {isFetching && <Shimmer />}
      <EnvironmentList theme={theme}>
        {environments.map((environment) => (
          <Link to={`${targetRoute}/${environment.id}`} key={environment.id}>
            <div>
              <img
                src={
                  environment &&
                  environment.thumbnails &&
                  environment.thumbnails.length > 1
                    ? `${settings.baseUrl}${environment.thumbnails[1].href}`
                    : ``
                }
                alt={environment.name}
              />
            </div>
            <aside>
              <Text variant="sideBarTitle" color="white" fontWeight={500}>
                {environment.name}
              </Text>
            </aside>
          </Link>
        ))}

        {!isFetching && !environments.length && (
          <EmptyFeedback
            icon={EmptyEnvironmentsDashboardFeedback}
            title="Aqui você pode ver seus ambientes"
            description="Inicie seu percurso"
          />
        )}
      </EnvironmentList>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        style={{ minHeight: '25px' }}
      >
        <ButtonBase
          onClick={() => history.push(targetRoute)}
          disableRipple
          className={classes.showAllButton}
        >
          {environments?.length >= 10 && (
            <>
              <Text variant="sideBarTitle" color="white" fontWeight={400}>
                Ver mais
              </Text>
              <ArrowForwardIosRoundedIcon
                style={{
                  color: 'white',
                  fontSize: 14,
                  marginLeft: 2,
                  marginTop: '6px'
                }}
              />
            </>
          )}
        </ButtonBase>
      </Box>
    </Container>
  );
}
