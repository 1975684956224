import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { eventEducationRole } from '~/constants/education';
import { Years } from '~/constants/years';
import i18n from '~/I18n';

import ReactHookFormSelect from '../../ReactHookFormSelect';
import ReactHookFormTextField from '../../ReactHookFormTextField';
import { useStyles } from '../styles';

const EventEducation = ({ control, education, index }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const roleNames = eventEducationRole.map((r) => r.name);

  return (
    <Box className={`${classes.column} ${classes.mainForm}`}>
      <Box className={classes.row}>
        <Controller
          name={`educations.${index}.educationable.name`}
          control={control}
          defaultValue={education.educationable.name}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`${education}.educationable.name`}
              label={i18n.t('EventForm.Name')}
              defaultValue={education.educationable.name}
              {...field}
            />
          )}
        />

        <ReactHookFormSelect
          name={`educations.${index}.educationable.role`}
          label={i18n.t('EventEducationRole')}
          values={roleNames}
          id={`educations.${index}.educationable.role`}
          control={control}
          defaultValue={education.educationable.role}
          variant="outlined"
          margin="normal"
        />
      </Box>

      <Box>
        <ReactHookFormSelect
          id={`educations.${index}.educationable.year`}
          name={`educations.${index}.educationable.year`}
          label={i18n.t('Year')}
          control={control}
          values={Years}
          defaultValue={education.educationable.year}
          variant="outlined"
          margin="normal"
        />
      </Box>
    </Box>
  );
};

export default EventEducation;

EventEducation.propTypes = {
  control: PropTypes.shape(),
  education: PropTypes.shape(),
  index: PropTypes.shape()
};

EventEducation.defaultProps = {
  control: null,
  education: null,
  index: null
};
