import React, { useState } from 'react';

import { StatusInput } from '~/components/molecules/StatusInput';
import { useLectureDiscussionMuralContext } from '~/contexts/LectureDiscussionMuralContext';
import { handleAddNewAnswerMural, sendToMuralListener } from '~/services/mural';

import { Status } from '..';
import { useStyles } from './styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const FootButtons = ({ answers, statusID }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showInputAnswer, setShowInputAnswer] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { channel, setStatusArrayFuncs } = useLectureDiscussionMuralContext();

  const createAnswer = async (data) => {
    if (isSending) return;
    try {
      setIsSending(true);
      const response = await handleAddNewAnswerMural(statusID, data);
      sendToMuralListener(channel, {
        content: response.data,
        type: 'TYPE_COMMENT_ANSWERED'
      });
      setStatusArrayFuncs.addNewAnswer(response.data);
    } catch (e) {
      if (e.response.status === 404) {
        enqueueSnackbar(
          'Ops! Este comentário foi excluído, não é possível enviar uma resposta.',
          { variant: 'error' }
        );
        setStatusArrayFuncs.removeStatus({ id: statusID });
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box>
          {answers && answers.length > 0 && (
            <ButtonBase
              disableRipple
              className={classes.showAnswersButton}
              onClick={() => setShowAnswers((prev) => !prev)}
            >
              {showAnswers
                ? 'Ocultar respostas'
                : `Ver ${answers.length} resposta${
                    answers.length > 1 ? 's' : ''
                  }`}
            </ButtonBase>
          )}
        </Box>
        <ButtonBase
          disableRipple
          onClick={() => {
            setShowAnswers((prev) => (!showInputAnswer ? true : prev));
            setShowInputAnswer((prev) => !prev);
          }}
        >
          <ReplyIcon className={classes.replayIcon} />
          <Typography className={classes.replayText}>Responder</Typography>
        </ButtonBase>
      </Box>
      {showInputAnswer && (
        <StatusInput
          submit={createAnswer}
          cancelButtonCallback={() => setShowInputAnswer(false)}
          isAnswer={true}
        />
      )}
      {showAnswers && (
        <>
          {answers.map((answer, index) => (
            <Box
              key={answer.id}
              style={{
                marginBottom: index === answers.length - 1 ? 0 : '8px'
              }}
            >
              <Status
                status={answer}
                isAnswer={true}
                avatarSize={32}
                statusID={statusID}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

FootButtons.propTypes = {
  statusID: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired
};
