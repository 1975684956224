import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getLecturesReport } from '~/services/reports/subjects';

import RowText from './RowText';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Row({ subject, id }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const oddColor = '#FFF';
  const evenColor = '#F5F5F5';

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: id % 2 === 0 ? evenColor : oddColor }}
    >
      <RowText>{subject.id}</RowText>
      <RowText>{subject.name}</RowText>

      <DownloadReportButton
        getReport={getLecturesReport}
        baseName={`relatorio_de_midias_por_modulo_`}
        id={subject.id}
      />
    </div>
  );
}

Row.propTypes = {
  subject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  id: PropTypes.number.isRequired
};

Row.defaultProps = {
  subject: {
    id: '',
    name: ''
  }
};
