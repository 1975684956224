import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  environmentsList: {
    marginTop: '1rem',
    overflowX: 'scroll',
    '@media (max-width: 450px)': {
      display: 'flex'
    },
    '&::-webkit-scrollbar': {
          height: '0'
      },
      '&::-webkit-scrollbar-track': {
          background: 'none'
      },
      '&::-webkit-scrollbar-thumb': {
          background: 'none'
      }
  },
  container: {
    padding: '2rem'
  },
  outerShimmer: {
    overflowX: 'scroll',
    '@media (max-width: 450px)': {
      display: 'flex'
    },
    '&::-webkit-scrollbar': {
      height: '0'
    },
    '&::-webkit-scrollbar-track': {
        background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'none'
    }
  },
  shimmerContainer: {
    display: 'flex', 
    marginBottom:'2rem', 
    width: '100%',
    '@media (max-width: 450px)': {
      flexDirection: 'column',
      marginRight: '1.5rem',
      marginBottom: 0
    },
  },
  shimmerText: {
    width: '60%', 
    padding: '0 2rem', 
    marginTop: '1rem',
    '@media (max-width: 450px)': {
      padding: 0,
      marginTop: '1rem'
    }
  },
  lgShimmer: {
    borderRadius: '0.5rem',
    '@media (max-width: 450px)': {
      display: 'none'
    }
  },
  smShimmer: {
    display: 'none',
    borderRadius: '0.5rem',
    '@media (max-width: 450px)': {
      display: 'flex'
    }
  },
  noEnvironmentsLarge: {
    fontSize: 16,
    color: theme.palette.activeText.main,
    '@media (max-width: 450px)': {
      display: 'none'
    }
  },
  noEnvironmentsSmall: {
    fontSize: 14,
    color: theme.palette.activeText.main,
    display: 'none',
    '@media (max-width: 450px)': {
      display: 'block'
    }
  },
}));