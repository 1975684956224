import React from 'react';
import { useFormContext } from 'react-hook-form';

import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';
import { Grid, Switch } from '@material-ui/core';
import i18n from '~/I18n';

function LectureDependency() {
  const classes = useStyles();
  const { control, watch } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.switch}>
        <Field
          label={TextPrimary(`Ativar dependência de ${i18n.t('StructuresName.Lectures.PluralLower')}`)}
          labelPlacement={'end'}
          name="lecture_dependency"
          control={control}
          checked={watch('lecture_dependency')}
          Component={Switch}
          size="small"
        />
      </Grid>
    </Grid>
  );
}

export default LectureDependency;
