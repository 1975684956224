import rootReducer from '~/store/ducks/rootReducer';
import rootSaga from '~/store/ducks/rootSaga';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

const whitelist = ['auth', 'user'];
const key = 'redu';
const persistConfig = { key, storage, whitelist };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const enhancerMiddlewares = applyMiddleware(sagaMiddleware);

const enhancercomposeWithDevTools = composeWithDevTools(enhancerMiddlewares);

const store = createStore(persistedReducer, enhancercomposeWithDevTools);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
