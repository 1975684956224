import React from 'react';

import { ForumDescription } from './ForumDescription';
import { Grid } from '@material-ui/core';

export default function Content({ MiniCard, Form, type }) {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <div style={{ display: 'flex' }}>
          <MiniCard size="medium" minWidth="150px" />
        </div>
      </Grid>
      {(type === 'forum' || type === 'multimedia') && (
        <Grid item xs={12}>
          <ForumDescription />
        </Grid>
      )}
      <Grid item xs={12}>
        <Form />
      </Grid>
    </Grid>
  );
}
