import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import EditorField from '../EditorField';
import ActionButtons from './ActionButtons';
import AddAlternativeButton from './AddAlternativeButton';
import { useStyles } from './styles';
import { Collapse, Grid, Switch, useTheme } from '@material-ui/core';

export default function AlternativeFields({
  addAlternative,
  currentAlternative,
  resetFields,
  editing,
  errorAlternative,
  errorRightAlternative
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [alternativeText, setAlternativeText] = useState('');
  const [startText, setStartText] = useState('');
  const [correct, setCorrect] = useState(false);
  const [showAlternativesFields, setShowAlternativesFields] = useState(false);

  const handleClick = () => {
    setCorrect((prev) => !prev);
  };

  const handleAddAlternative = () => {
    var alternative = {
      id: currentAlternative.id,
      correct,
      text: alternativeText,
      letter: currentAlternative.letter
    };
    if (addAlternative(alternative)) {
      setShowAlternativesFields(false);
      setCorrect(false);
      setAlternativeText('');
    }
  };

  const handleCancel = () => {
    setShowAlternativesFields(false);
    resetFields();
  };

  useEffect(() => {
    if (editing) {
      setStartText(currentAlternative.text);
      setCorrect(currentAlternative.correct);
    }
  }, [editing]);

  return (
    <div className={classes.field}>
      <Collapse in={!showAlternativesFields && !editing}>
        <AddAlternativeButton setState={setShowAlternativesFields} />
      </Collapse>

      {errorAlternative && (
        <Text color={'error'}>
          A questão precisa ter mais de uma alternativa.
        </Text>
      )}

      {errorRightAlternative && (
        <Text color={'error'}>
          A questão precisa ter uma alternativa correta!
        </Text>
      )}

      <Collapse in={showAlternativesFields || editing}>
        <div className={classes.alternativeField}>
          <Text color={'primary'} variant={'text'}>
            {' '}
            Alternativa{' '}
            <span style={{ fontWeight: 'bold' }}>
              {currentAlternative.letter}
            </span>{' '}
          </Text>
        </div>

        <div className={classes.alternativeField}>
          <EditorField
            onChange={setAlternativeText}
            value={alternativeText}
            startText={startText}
          />
        </div>

        <div className={classes.alternativeField}>
          <Grid container alignItems="center">
            <Grid item>
              {' '}
              <Switch
                checked={correct}
                onClick={handleClick}
                size={'small'}
              />{' '}
            </Grid>
            <Grid item xs>
              {' '}
              <Text color="primary" variant={'mediumText'}>
                {' '}
                Alternativa Correta?{' '}
              </Text>{' '}
            </Grid>
          </Grid>
        </div>

        <div className={classes.alternativeField}>
          <ActionButtons
            handleCancel={handleCancel}
            handleAddAlternative={handleAddAlternative}
            editing={editing}
          />
        </div>
      </Collapse>
    </div>
  );
}
