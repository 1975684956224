import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    textArea: {
        margin: '0 20px',
        overflow: 'hidden',
        flex: 3,
        width: '100%',
        overflow: 'hidden',
        '& p': {
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    }
});