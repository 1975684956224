import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));