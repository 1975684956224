import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import api from '~/services/api';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { Creators as UserActions } from '~/store/ducks/user';

export const ConectaMaisRedirect = () => {
  const token = new URLSearchParams(useLocation().search).get('tk');
  const history = useHistory();

  const dispatch = useDispatch();
  const { getProfileRequest } = UserActions;
  const { signInSuccess } = AuthActions;

  const setLoginAndRedirect = (token) => {
    api.defaults.headers.Authorization = token;
    dispatch(signInSuccess(token, true));
    dispatch(getProfileRequest());
    history.push('/dashboard');
  };

  const handleToken = async () => {
    try {
      const { data } = await api.post('/api/conecta_mais/redirection', {
        token
      });
      setLoginAndRedirect(data);
    } catch (error) {
      history.push('/');
    }
  };

  useEffect(() => {
    if (token) {
      handleToken();
    } else {
      history.push('/');
    }
  }, [token, history]);

  return null;
};
