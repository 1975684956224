import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { formatLocalTime } from '~/utils/dates';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import { ReactComponent as LectureExpiredIcon } from 'src/assets/icons/lecture-expired-icon.svg';

export default function LectureExpiredAlert({ expirationDate }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (expirationDate)
    var expirationDateFormatted = formatLocalTime(expirationDate);

  return (
    <Box className={classes.root}>
      <LectureExpiredIcon fill={theme.palette.primary.main} />

      <Text
        variant="largeText"
        className={classes.largeText}
        style={{ fontWeight: 500 }}
      >
        Ops! Parece que a {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
        expirou...
      </Text>

      <Text variant="text" className={classes.text}>
        Entre em contato com os organizadores da{' '}
        {i18n.t('StructuresName.Space.SingularLower')} para entender um pouco
        mais.
      </Text>

      <Text variant="text" className={classes.descriptionText}>
        {expirationDateFormatted ? (
          <>
            Esta {i18n.t('StructuresName.Lectures.SingularLower')} ficou
            disponível até o dia {expirationDateFormatted[0]} às{' '}
            {expirationDateFormatted[1]}h.
          </>
        ) : (
          <>
            Esta {i18n.t('StructuresName.Lectures.SingularLower')} não esta mais
            disponível.
          </>
        )}
      </Text>
    </Box>
  );
}
