import api from '~/services/api';
import { readFile } from '~/utils/imageUtils';

const handleAvatar = async (file) => {
  if (file) {
    var dataUrl = await readFile(file);
    return { image: dataUrl, image_file_name: file.name };
  }

  return null;
};

export const createSpace = async (courseID, body) => {
  if (body.space.new_avatar_attributes) {
    body.space.new_avatar_attributes = await handleAvatar(
      body.space.new_avatar_attributes
    );
  }

  return api.post(`/api/courses/${courseID}/spaces`, body);
};
