import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-self: center;
`;

export const WrapperLabel = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(Typography).attrs({
  variant: 'h6',
  component: 'div',
  color: 'textSecondary'
})``;
