import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  lastLectureContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '240px'
  },
  otherLecturesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flex: 1,
    maxWidth: '1280px'
  }
});
