import base from './base';
const baseUrl = 'http://localhost:3000';
// const baseUrl = 'https://ava.viitra.in/';
// const baseUrl = 'https://dev.viitra.in/';
const apiUrl = `${baseUrl}/api`;

// SEBRAE
const sebraeRedirectURI = 'http://localhost:3000/'; // Domínio e porta que está rodando o front do redu localmente
const sebraeIDBaseURL = 'https://amei.homolog.kubernetes.sebrae.com.br'; // Domínio de teste do sebrae ID
const sebraeKey = '!t0907Hu@7l@IAUYYFohGuw9'; // chave do backend para o namespace do sebrae

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  websocketUrl: 'ws://localhost:4443',
  AppKey: 'dcwhxkJtXp0IifeLHuYf3E7UujXFZKazABVB6dqDAPM',
  recaptchaKey: '6LdwTNMaAAAAAHuMkPuqnGmXik7kEh4ocokHJG76',
  sebraeIDBaseURL,
  sebraeRedirectURI,
  sebraeKey,
  sebraeLoginURL: `${sebraeIDBaseURL}/auth/realms/externo/protocol/openid-connect/auth?client_id=sebrae-edu-frontend&response_mode=query&response_type=code&scope=openid&redirect_uri=${sebraeRedirectURI}`
};
