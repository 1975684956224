import React, { useEffect } from 'react';

import LeftContent from './LeftContent';
import RightContent from './RightContent';
import { Grid, Hidden, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

export default function Login() {
  const theme = useTheme();
  const { light } = theme;

  useEffect(() => {
    document.title = `${theme.title} | Login`;
  }, []);

  return (
    <ThemeProvider theme={light}>
      <Grid container style={{ minWidth: 350, height: '100vh' }}>
        <Hidden smDown>
          <Grid item xs={false} sm={6} md={7} lg={7} xl={6}>
            <LeftContent />
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={12} md={5} lg={5} xl={6}>
          <RightContent />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
