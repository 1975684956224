import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AvatarBadge } from '~/components/atoms/AvatarBadge';
import Text from '~/components/atoms/Text';
import OptionsModal from '~/components/templates/OptionsModal';
import i18n from '~/I18n';
import { getLastMessageEmit, getLastMessageOn } from '~/socket/contact-events';
import {
  unreadMessageForContactEmit,
  unreadMessageForContactOn
} from '~/socket/message-events';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

export default function MessagesConversationItem ({
  conversation,
  index,
  setCurChat,
  curChat
}) {
  const [showOptions, setShowOptions] = useState(false);
  const optionsContainer = useRef();
  const [unreadMessages, setUnredMessage] = useState(0);
  const { profile } = useSelector((state) => state.user);
  const [lastMessage, setLastMessage] = useState('');

  const theme = useTheme();
  const classes = useStyles({
    theme,
    showOptions,
    selected: curChat === index
  });

  const { name, contact_id, sender_id, contact_attributes } = conversation;

  const { thumbnails } = contact_attributes;

  function toggleShowOptions (event) {
    event.stopPropagation();
    setShowOptions(!showOptions);
  }

  useEffect(() => {
    unreadMessageForContactEmit(sender_id, contact_id);
    unreadMessageForContactOn(sender_id, contact_id, (unread) => {
      setUnredMessage(unread);
    });
  }, []);
  useEffect(() => {
    if (profile) {
      getLastMessageEmit(profile?.id, contact_id);
      getLastMessageOn(profile?.id, contact_id, (message) => {
        setLastMessage(message);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className={classes.container}
      onClick={() => {
        setCurChat(conversation);
      }}
    >
      <AvatarBadge
        profile={profile}
        contact={{
          id: contact_id,
          first_name: name,
          thumbnails: thumbnails
        }}
      />
      <Box className={classes.middleArea}>
        <Text fontWeight="bold" variant="largeText" color="gray">
          {name}
        </Text>
        <Text varitant="text" color="gray" opacity={0.45}>
          {lastMessage}
        </Text>
      </Box>
      <Box className={classes.notificationArea}>
        {unreadMessages > 0 && (
          <Box className={classes.notificationCircle}>{unreadMessages}</Box>
        )}
      </Box>
      <Box
        className={`${classes.optionsContainer} optionsContainer`}
        ref={optionsContainer}
      >
        <button onClick={toggleShowOptions} className={classes.optionButton}>
          <ExpandMoreIcon />
        </button>
        {showOptions && (
          <OptionsModal
            setShow={setShowOptions}
            items={[
              {
                label: i18n.t('toDelete'),
                action: () =>
                  console.log(i18n.t('ContactDeleteMessage', { index }))
              }
            ]}
          />
        )}
      </Box>
    </Box>
  );
}

MessagesConversationItem.propTypes = {
  conversation: PropTypes.shape(),
  index: PropTypes.number,
  setCurChat: PropTypes.func,
  curChat: PropTypes.number
};

MessagesConversationItem.defaultProps = {
  conversation: {},
  index: 0,
  setCurChat: null,
  curChat: -1
};
