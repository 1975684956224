import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    height: '100%',
    flex: '1 1 auto',
    marginTop: 15
  }
});
