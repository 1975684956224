
import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const ButtonsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding:'30px',
  marginTop: '10px',
  border: '1px solid rgba(0,0,0, 0.2)',
  borderRadius: '5px'
});
