import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function DisplayOptions({
  optionNames,
  selectedOption,
  setSelectedOption,
  tabSpacing,
  unclickable
}) {
  const classes = useStyles({ tabSpacing, unclickable });

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {optionNames.map((name, index) => (
          <Text
            key={index}
            color={selectedOption === index ? 'primary' : 'gray'}
            variant="environmentSidebarText"
            style={{ marginBottom: 15 }}
            onClick={() => {
              if (!unclickable) setSelectedOption(index);
            }}
          >
            {name}
          </Text>
        ))}
        <a
          href={settings.helpCenterUrl}
          target={'_blank'}
          style={{ fontSize: 16, color: 'gray' }}
          rel="noreferrer"
        >
          {i18n.t('Manual')}
        </a>
      </Box>
    </Box>
  );
}

DisplayOptions.propTypes = {
  optionNames: PropTypes.array,
  selectedOption: PropTypes.number,
  setSelectedOption: PropTypes.func,
  tabSpacing: PropTypes.string,
  unclickable: PropTypes.bool
};

DisplayOptions.defaultProps = {
  optionNames: [],
  selectedOption: 0,
  setSelectedOption: null,
  tabSpacing: 'flex-start',
  unclickable: false
};
