import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import Text from '~/components/atoms/Text';

export const useStyles = makeStyles(() => ({
  root: {

  },
  subsection: {
    marginTop: 40,
    marginBottom: 40,
    width: '100%'
  },
  subtitleContainer: {
    marginTop: 5,
    marginBottom: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: 20,
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  submitArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    width: '220px',
    justifyContent: 'space-between'
  },
  educationsSkeletonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 10
  },
  noEducationsDesktopTablet: {
    '@media (max-width: 450px)': {
      display: 'none'
    }
  },
  noEducationsMobile: {
    display: 'none',
    '@media (max-width: 450px)': {
      display: 'block'
    }
  },
  experiencesSkeletonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 10
  }
}));

export const TextNoExistData = styled(({ text }) => (
  <>
    <Box className={useStyles().noEducationsDesktopTablet}>
        <Text variant="largeText" color="gray">{text}</Text>
    </Box>
    <Box className={useStyles().noEducationsMobile}>
        <Text variant="text" color="gray">{text}</Text>
    </Box>
  </>
  ))``;
