import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { GenericFieldText } from '~/components-refactor/atoms/GenericFieldText';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import PropTypes from 'prop-types';

export default function EditSpaceForm({ mirrorPayload }) {
  const classes = useStyles();
  const methods = useFormContext();

  useEffect(() => {
    methods.reset(mirrorPayload);
  }, [mirrorPayload]);

  return (
    <Box width="100%" marginBottom="16px">
      <Box className={classes.titleBox}>
        <Typography className={classes.titleText}>
          {`Dados da ${i18n.t(`StructuresName.Space.SingularUpper`)}`}
        </Typography>
        <CreateIcon className={classes.titleIcon} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenericFieldText
            label={'Nome *'}
            inputProps={{ ...methods.register('name') }}
            error={methods.formState.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericFieldText
            label={'Sigla'}
            inputProps={{ ...methods.register('initials') }}
            error={methods.formState.errors.initials}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericFieldText
            label={'Turno'}
            inputProps={{ ...methods.register('turn') }}
            error={methods.formState.errors.turn}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericFieldText
            label={'Descrição'}
            error={methods.formState.errors.description}
            inputProps={{
              ...methods.register('description'),
              multiline: true,
              rows: 3
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

EditSpaceForm.propTypes = {
  mirrorPayload: PropTypes.object.isRequired
};
