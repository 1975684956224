import React from 'react';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function LoadingMore() {
  return (
    <>
      {Array(5)
        .fill(null)
        .map((shimmer, index) => (
          <Grid item key={index}>
            <Skeleton
              width={260}
              height={265}
              style={{
                borderRadius: '8px'
              }}
              variant="rect"
            />
          </Grid>
        ))}
    </>
  );
}
