import React from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export const FootButtonsWrapper = ({
  isStartStep,
  isLastStep,
  actionNextButton,
  actionBackButton,
  isLoading,
  disableNextButton
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.buttonsContainer}>
      <ButtonSquare width={150} height={50} onClick={actionBackButton}>
        <Text color="secondary" variant="largeText">
          {isStartStep ? 'Cancelar' : 'Retornar'}
        </Text>
      </ButtonSquare>
      <Button
        type={isLastStep ? 'submit' : 'button'}
        variant="contained"
        className={classes.submitButton}
        onClick={actionNextButton}
        disabled={disableNextButton || isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} color="grey" />
        ) : (
          <Text color="white" variant="largeText">
            {isLastStep ? 'Espelhar' : 'Prosseguir'}
          </Text>
        )}
      </Button>
    </Box>
  );
};

FootButtonsWrapper.propTypes = {
  isStartStep: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  actionNextButton: PropTypes.func.isRequired,
  actionBackButton: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disableNextButton: PropTypes.bool
};

FootButtonsWrapper.defaultProps = {
  isLoading: false,
  disableNextButton: false,
  actionNextButton: () => {},
  actionBackButton: () => {}
};
