import { useEffect, useState } from 'react';

import api from '~/services/api';
import settings from '~settings';

export const usePdf = (lecture) => {
  const [pdfURL, setPdfURL] = useState('');
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState(false);
  const fetch = async () => {
    await api
      .get(`${settings.baseUrl}/api/getpdf?document_id=${lecture.id}`, {
        headers: {
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8',
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob',
        data: {}
      })
      .then((response) => {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function() {
          var base64data = reader.result;
          setPdfData(
            base64data.replace('data:application/octet-stream;base64,', '')
          );
        };
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
    return () => {
      setPdfURL(`${settings.baseUrl}${lecture.links[0].href}`);
    };
  };

  useEffect(() => {
    fetch();
  }, [lecture]);

  return { pdfURL, pdfData, error };
};
