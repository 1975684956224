import React from 'react';
import Text from '~/components/atoms/Text';
import UploadVideo from './UploadVideo';
import VideoID from './VIdeoID';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { TextPrimary } from '~/utils/forms';
import { useStyles } from './styles';
import Field from '~/components/organisms/GenericFormWrapper/Field';

export default function Video() {
  const classes = useStyles();

  const { setValue, watch, control } = useFormContext();

  const handleChange = (event) => {
    setValue('lectureable.video.vimeoOption', event.target.value);
  };

  return (
    <Box>
      <div className={classes.field}>
        {TextPrimary('Adicionar Vídeo através:')}
      </div>

      <div className={classes.field}>
        <FormControl variant="outlined">
          <InputLabel>
            <Text color="primary" variant="smallText">
              Opções
            </Text>
          </InputLabel>

          <Select
            value={watch('lectureable.video.vimeoOption')}
            onChange={handleChange}
            label="vimeoOption"
          >
            <MenuItem value={true}>
              <Text color="primary" variant="mediumText">
                De Upload de Arquivo de Vídeo
              </Text>
            </MenuItem>
            <MenuItem value={false}>
              <Text color="primary" variant="mediumText">
                Do ID do vídeo no Vimeo
              </Text>
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      {watch('lectureable.video.vimeoOption') && <UploadVideo />}
      {!watch('lectureable.video.vimeoOption') && <VideoID />}

      <Box style={{ marginTop: '20px' }}>
        <Field
          label={TextPrimary('Permitir download da mídia')}
          labelPlacement={'end'}
          control={control}
          name="lectureable.video.downloadable"
          size="small"
          Component={Switch}
        />
      </Box>
    </Box>
  );
}
