/* eslint-disable react/prop-types */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MenuItem, useTheme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  values,
  isRequired = false
}) => {
  const labelId = `${name}-label`;

  const theme = useTheme();
  const classes = useStyles({
    theme
  });

  const { register } = useFormContext();

  return (
    <FormControl style={{ width: '100%' }}>
      <Controller
        defaultValue={defaultValue}
        render={({ field }) => (
          <div {...field}>
            <Text variant="label" color="gray">
              {label}
            </Text>
            <Select
              required={isRequired}
              labelId={labelId}
              variant="outlined"
              defaultValue={defaultValue}
              placeholder={defaultValue}
              className={classes.root}
              MenuProps={{
                classes: { paper: classes.selectOptions }
              }}
              style={{
                fontSize: 12
              }}
              {...register(name)}
            >
              {values.map((item, idx) => {
                const typeOfItem = Array.isArray(item);
                const itemValue = (typeOfItem && item[1] && item[1]) || item;
                const itemLabel = (typeOfItem && item[1] && item[0]) || item;
                return (
                  <MenuItem
                    className={classes.menuItem}
                    key={idx}
                    value={itemValue}
                  >
                    {itemLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;

ReactHookFormSelect.prototype = {
  name: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  control: PropTypes.shape(),
  defaultValue: PropTypes.shape(),
  values: PropTypes.array
};

ReactHookFormSelect.defaultValue = {
  name: '',
  label: '',
  isRequired: null,
  control: null,
  defaultValue: null,
  values: []
};
