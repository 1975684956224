import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import history from '~/services/history';

import { useStyles } from './styles';
import i18n from '~/I18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export default function EditProfileLink({ profile, userId }) {
  const { profile: currentUser } = useSelector((state) => state.user);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const theme = useTheme();
  const { root } = useStyles();

  useEffect(() => {
    setLoggedInUser(currentUser?.id == userId);
  }, [currentUser, userId]);

  return (
    <>
      {loggedInUser && (
        <Box className={root}>
          <ButtonSquare
            color={profile ? theme.palette.activeText.main : 'white'}
            onClick={() =>
              history.push({ pathname: `/profile/${userId}/edit` })
            }
          >
            <Text variant="text" color={profile ? 'gray' : 'white'}>
              {i18n.t('EditProfile')}{' '}
              <FontAwesomeIcon
                icon={faPen}
                style={{ fontSize: '12px', color: 'gray' }}
              />
            </Text>
          </ButtonSquare>
        </Box>
      )}
    </>
  );
}

EditProfileLink.propTypes = {
  // userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.string,
  profile: PropTypes.bool
};

EditProfileLink.defaultProps = {
  userId: null,
  profile: null
};
