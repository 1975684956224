import aedutec from './aedutec/production';
import avamec from './avamec/production';
import avaviitra from './avaviitra/production';
import conectamaisedu from './conectamaisedu/production';
import devavaviitra from './devavaviitra/production';
import localhost from './development';
import espiral from './espiral/production';
import estartec from './estartec/production';
import invar from './invar/production';
import masteradam from './masteradam/production';
import microkids from './microkids/production';
import movimenta from './movimenta/production';
import munera from './munera/production';
import prefacio from './prefacio/production';
import redudigital from './redudigital/production';
import saberes from './saberes/production';
import sebraeedu from './sebraeedu/production';
import siao from './siao/production';

const settings = {
  'aedutec.redu.digital': aedutec,
  'www.aedutec.redu.digital': aedutec,
  'avamecinterativo.mec.gov.br': avamec,
  'www.avamecinterativo.mec.gov.br': avamec,
  'beta.ava.viitra.in': avaviitra,
  'www.beta.ava.viitra.in': avaviitra,
  'ava.conectamaisedu.com.br': conectamaisedu,
  'www.ava.conectamaisedu.com.br': conectamaisedu,
  'digital.editoraespiral.com.br': espiral,
  'www.digital.editoraespiral.com.br': espiral,
  'sava.estartec.net': estartec,
  'www.sava.estartec.net': estartec,
  'adams.mastersolucoesedu.com.br': masteradam,
  'www.adams.mastersolucoesedu.com.br': masteradam,
  'digital.microkids.com.br': microkids,
  'www.digital.microkids.com.br': microkids,
  'comunidade.movimentaeducacao.com.br': movimenta,
  'www.comunidade.movimentaeducacao.com.br': movimenta,
  'digital.prefacioeditora.com.br': prefacio,
  'www.digital.prefacioeditora.com.br': prefacio,
  'redu.digital': redudigital,
  'www.redu.digital': redudigital,
  'saberes.edicoesipdh.com.br': saberes,
  'www.saberes.edicoesipdh.com.br': saberes,
  'sebraeedu.com.br': sebraeedu,
  'www.sebraeedu.com.br': sebraeedu,
  'educa.siao.seg.br': siao,
  'www.educa.siao.seg.br': siao,
  'ava.editoramunera.com.br': munera,
  'dev.viitra.in': devavaviitra,
  'www.ava.editoramunera.com.br': munera,
  'invar.redu.digital': invar,
  'www.invar.redu.digital': invar,
  localhost
};

export default settings[window.location.hostname];
