import { selectEnglishWordsByClient } from '~/I18n/modules/es-ES';
// import { clientName } from '~/utils/auxFunctions';
import settings from '~settings';

const wordsByClient = selectEnglishWordsByClient();
const client = settings.name;

export default {
  translations: {
    ...wordsByClient,
    NotDoneYet: 'aún no has realizado esta actividad',
    MemberTime: 'Tu tiempo',
    MemberGrade: 'Tu nota',
    MemberName: 'nombre del alumno',
    Seconds: 'Segundos',
    Minutes: 'Minutos',
    Hours: 'Horas',
    QuestionsReport: 'Informe de preguntas',
    Review: 'Revisión',
    QuestionsMultipleChoices: 'Preguntas (elección multiplica)',
    ExplainedQuestions: 'Preguntas explicadas',
    BestGrades: 'Mejores notas',
    Name: 'Nombre',
    Grade: 'Nota',
    Time: 'Tiempo',
    Hits: 'Golpes',
    Misses: 'Errores',
    Null: 'Nulo',
    Recently: 'Recientemente',
    HowManyDidAlready: 'Cuantos ya han hecho',
    People: 'Gente',
    OverallTime: 'Tiempo médio',
    OverallGrade: 'Promedio general',
    Abbreviation: 'Abreviatura',
    Keywords: 'Palabras clave',
    Content: 'Contenido',
    Discussion: 'Discusión',
    Members: 'Miembros',
    Send: 'ENVIAR',
    Explanations: 'Comentarios del Maestro',
    Question: 'Pregunta',
    Submitted: 'Ejercicio ya Respondido',
    Time: 'Duración',
    Rating: 'Valoración',
    Exercise: 'Ejercicio',
    BioCurriculum: 'Bio y Currículum',
    Certificates: 'Certificados',
    Back: 'Regressar',
    Education: 'Formación',
    Experience: 'Experiencia Profesional',
    Languages: 'Idiomas',
    InterestAreas: 'Áreas de Interés',
    Contacts: 'Contactos',
    Present: 'Presente',
    bachelorship: 'licenciatura en',
    Quit: 'Sal',
    EmptyListEnvironments: 'Aún no tienes ningún entorno.',
    EmptyListCourses: 'Este entorno aún no tiene ningún curso.',
    EmptyListSpaces: 'Este curso aún no tiene asignaturas.',
    EmptyListLectures:
      'No fue posible obtener la lista de clases para este módulo. Inténtalo de nuevo.',
    EmptyListModules: 'Este curso aún no tiene módulos.',
    ExceptionHandlerMessage: 'Algo salió mal. Inténtalo de nuevo.',
    ErrorMessage: 'No se ha podido completar la solicitud. Inténtalo de nuevo.',
    ErrorUnsuportedFileMessage: 'No admitimos este tipo de archivo.',
    Profile: 'Perfil',
    PasswordReset: 'Restablecimiento de contraseña',
    FieldRequiredErrorMessage: 'Hay un campo que necesita ser llenado',
    ComplementaryCourseCourse: 'Curso',
    ComplementaryCoursePlaceholder: 'ej: experiencia del usuario',
    ComplementaryCourseInstitution: 'Institución',
    ComplementaryCourseInstitutionPlaceholder: 'ej: Udemy',
    ComplementaryCourseYear: 'Año',
    ComplementaryCourseYearPlaceholder: 'ej: 2020',
    ComplementaryCourseWorkload: 'Carga Horaria',
    ComplementaryCourseWorkloadPlaceholder: 'Tiempo total del curso',
    ComplementaryCourseDescription: 'Descripción',
    ComplementaryCourseDescriptionPlaceholder: 'Opcional',
    EventEducationName: 'Evento',
    EventEducationNamePlaceholder: 'ej: Campus party',
    EventEducationRole: 'Papel',
    EventEducationRolePlaceholder: 'seleccionar un rol',
    EventEducationYear: 'Año',
    EventEducationYearPlaceholder: 'Seleccione un año',
    HigherEducationInstitution: 'Institución',
    HigherEducationInstitutionPlaceholder: 'ej: Universidade de São Paulo',
    HigherEducationKind: 'Nivel',
    HigherEducationKindPlaceholder: 'Seleccione un grado',
    HigherEducationCourse: 'Curso',
    HigherEducationCoursePlaceholder: 'ej: Administración',
    HigherEducationArea: 'Campo de estudio',
    HigherEducationAreaPlaceholder: 'ej: Software educativo',
    HigherEducationStartYear: 'Año comenzó',
    HigherEducationStartYearPlaceholder: 'ej:2020',
    HigherEducationEndYear: 'Año final',
    HigherEducationEndYearPlaceholder: 'ej:2025',
    HigherEducationDescription: 'Descripción',
    HigherEducationDescriptionPlaceholder: 'Opcional',
    HighSchoolInstitution: 'Institución',
    HighSchoolInstitutionPlaceholder: 'ej: Escola Tecnica Estadual',
    HighSchoolEndYear: 'Año final',
    HighSchoolEndYearPlaceholder: 'Seleccione un año',
    HighSchoolDescription: 'Descripción',
    HighSchoolDescriptionPlaceholder: 'Opcional',
    AddItem: 'Agregar artículo',
    AddEducation: 'Agregar educación',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    Save: 'Ahorrar',
    Technical: 'Técnico',
    Degree: 'Licenciatura',
    Bachelorship: 'Graduación',
    PosLatoSensu: 'Pós "Lato Sensu / MBA"',
    PosStrictoSensu: 'Pós "Stricto Sensu"',
    Doctorate: 'Doctorado',
    Phd: 'PhD',
    Participant: 'Partícipe',
    Speaker: 'Altavoz',
    Organizer: 'Organizador',
    HigherEducation: 'Enseñanza superior',
    HighSchool: 'Escuela secundaria',
    EventEducation: 'Evento',
    ComplementaryCourse: 'Curso complementario',
    HomePage: 'Página de inicio',
    overview: 'Visión general',
    myMural: 'Mi pared',
    following: 'Siguiendo',
    shareSomething: 'Comparte algo en tu muro',
    friendshipRequestText: 'quiere conectarse contigo',
    noDescription: 'No hay una descripción',
    noEducations: 'No hay una formación',
    noExperiences: 'Sin experiencia profesional',
    noLanguages: 'Sin idiomas',
    NoInterestAreas: 'sin áreas de interés',
    noEnvironments: 'Sin ambientes',
    noContacts: 'Sin contactos',
    ViewAchievements: 'Ver Logros',
    AddReply: 'Agrega una respuesta',
    Reply: 'Respuesta',
    ShowReplies: 'Mostrar respuestas',
    HideReplies: 'Ocultar respuestas',
    commentedOn: 'comentó en',
    of: 'de',
    Student: 'Estudiante',
    EditProfile: 'Editar perfil',
    Achievements: 'Logros',
    Mural: 'Pared',
    AddedBy: 'Añadido por',
    EmptyWall: 'Pared Vacía',
    MaximumImageSize: 'El tamaño máximo de la imagen debe ser de 10MB',
    LoadingImage: 'Cargando imagen',
    DescriptionPlaceholder: 'Escribe un poco sobre ti',
    InterestPlaceholder: 'Agregue un área de interés ...',
    AddLanguage: 'Agregue un idioma',
    AddExperience: 'Agregar experiencia',
    Language: 'Idioma',
    LanguagePlaceholder: 'ej: portugués, inglés',
    Role: 'Papel',
    Company: 'Empresa',
    StartDate: 'Fecha de entrada',
    EndDate: 'Fecha de salida',
    CurrentlyWorking: 'Actualmente trabajo para esta empresa',
    Description: 'descripción',
    RolePlaceholder: 'ej: Asistente administrativo',
    CompanyPlaceholder: 'ej: Sebrae',
    Optional: 'Opcional',
    EducationType: 'Tipo educativo',
    EducationTypePlaceholder: 'Seleccione un tipo',
    Month: 'Mes',
    Year: 'Año',
    UpdateProfileError: 'Hubo un problema, verifique los campos.',
    UpdateProfileSuccess: 'Datos actualizados con éxito!',
    DeleteEducation: 'Está seguro de que desea eliminar esta formación?',
    DeleteExperience: 'Está seguro de que deseas eliminar esta experiencia?',
    DeleteErrorMessage:
      'Hubo un problema con la solicitud. Vuelve a intentarlo más tarde.',
    Delete: 'Borrar',
    HelpCenter: 'Centro de ayuda',
    BasicGuide: 'Guia basica',
    StudentGuide: 'Guía de estudiante',
    TeacherGuide: 'Guía del profesor',
    ManagerGuide: 'Guía del gerente',
    ContactUs: 'Hable con nosotros',
    ContactUsNamePlaceholder: 'Introduzca su nombre',
    ContactUsEmailPlaceholder: 'Ingrese un correo electrónico de contacto',
    ContactUsSubjectPlaceholder: 'Escriba el asunto del mensaje aquí',
    ContactUsBody: 'Mensaje',
    ContactUsBodyPlaceholder: 'Escriba su mensaje aquí',
    Name: 'Nombre',
    Email: 'Correo electrónico',
    Category: 'Categoría',
    Subject: 'Sujeto',
    SendMessage: 'Enviar mensaje',
    EmptyFieldError: 'Este campo debe completarse.',
    InvalidEmail: 'Este correo electrónico no es válido.',
    SendMessageSuccess: 'Mensaje enviado correctamente!',
    SendMessageError: 'El mensaje no se pudo enviar.',
    OpenZoom: 'Asistir a clase en Zoom',
    OpenJitsi: 'Asistir a clase en Jitsi Meet',
    StreamKey: 'Tu clave de transmisión',
    ObsExplanation:
      'Utilice esta tecla para iniciar la transmisión en su programa de transmisión. Recomendamos el software gratuito ',
    StreamGuide:
      'Haga clic aquí para obtener instrucciones sobre cómo realizar su primera transmisión.',
    DeletedUser: 'Usuario eliminado',
    GenerateCertificate: 'Generar Certificado',
    GenerateCertificateSuccess:
      'La descarga fue exitosa! Puede encontrarlo en la carpeta Descargas.',
    GenerateCertificateRequestError:
      'Hubo un problema con la solicitud. Por favor, inténtelo de nuevo más tarde.',
    GenerateCertificateRuleError:
      'Necesitas completar más clases para obtener el certificado!',
    GetCertificate: 'Generar certificado',
    NoStoragePermission: 'No se otorgó permiso de almacenamiento',
    SearchSebraeEdu: `Buscar ${client}`,
    RecentSearches: 'Búsquedas recientes',
    Clean: 'Limpia',
    ContactListInputPlaceholder: 'Buscar',

    mySubscriptions: 'Mis Suscripciónes',
    profileSubscriptions: 'Suscripciones',
    showMore: 'Mostrar más',
    noStatus: 'Sin estado para mostrar',
    menuItem: { configuration: 'Configuração', messages: 'Mensagens' },
    close: 'Cerca',
    continue: 'Seguir',
    alert: 'Alerta',
    success: 'Éxito',
    error: 'ERROR',
    name: 'Nombre',
    path: 'Dirección'
  }
};
