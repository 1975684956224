/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import FetchMoreButton from './FetchMoreButton';
import Loading from './Loading';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@material-ui/core';
import { Label } from '@material-ui/icons';

// eslint-disable-next-line react/prop-types
export default function PaginatableSelect({
  value,
  onChange,
  label,
  paginatableFunction,
  objectList,
  setObjectList,
  labelAttribute,
  valueAttribute,
  params,
  instanceName
}) {
  const MenuProps = {
    PaperProps: {
      style: {
        height: 300
      }
    }
  };

  const [fetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchMore = () => {
    if (hasMore) {
      setFetching(true);
      const request = params
        ? paginatableFunction(params, page + 1)
        : paginatableFunction(page + 1);
      request
        .then((data) => {
          if (!data) {
            setHasMore(false);
          } else {
            setHasMore(data.length >= 10);
            if (instanceName) {
              setObjectList((prevObjectList) => [
                ...prevObjectList,
                ...data.map((item) => item[instanceName])
              ]);
            } else {
              setObjectList([...objectList, ...data]);
            }
            setPage((page) => page + 1);
          }

          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  };

  const options = () => {
    return (
      // eslint-disable-next-line react/prop-types
      objectList?.map((object) => (
        <MenuItem
          key={object[valueAttribute]}
          value={object[valueAttribute]}
          style={{ fontSize: '16px' }}
        >
          {object[labelAttribute]}
        </MenuItem>
      ))
    );
  };

  return (
    <>
      <Text color="primary" variant={'actionText'}>
        {label}
      </Text>
      <FormControl variant="outlined" fullWidth>
        <Select
          value={value}
          onChange={onChange}
          MenuProps={MenuProps}
          fullWidth
          style={{ fontSize: '16px' }}
        >
          {fetching ? <Loading /> : options()}
          {!fetching && hasMore && objectList?.length >= 10 && (
            <FetchMoreButton fetchMore={fetchMore} />
          )}
        </Select>
      </FormControl>
    </>
  );
}
