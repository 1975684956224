import React from 'react';

import DatePickerInput from '~/components/atoms/DatePickerInput';
import Text from '~/components/atoms/Text';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';

import PropTypes from 'prop-types';

export const DatePickerInputForm = ({ dataType, name, placeholder }) => {
  return (
    <Field
      name={name}
      label={
        <Text color={'gray'}>{`Data de ${dataType} do ${i18n.t(
          'StructuresName.Course.SingularLower'
        )}`}</Text>
      }
      Component={DatePickerInput}
      placeholder={placeholder}
    />
  );
};

DatePickerInputForm.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  dataType: PropTypes.string
};

DatePickerInputForm.defaultProps = {
  input: {},
  placeholder: '',
  name: '',
  dataType: ''
};
