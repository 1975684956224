import React from 'react';

import Input from '~/components/atoms/TextInput';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import Keywords from '../Keywords';
import Image from './Image';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';

export default function MainInfo() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Nome *')}
              name="name"
              Component={Input}
              placeholder={`Digite o nome do ${i18n.t(
                'StructuresName.Environment.SingularLower'
              )}`}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Abreviação *')}
              name="initials"
              Component={Input}
              placeholder={`Digite uma sigla para o ${i18n.t(
                'StructuresName.Environment.SingularLower'
              )}`}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className={classes.field}>
          <Keywords />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className={classes.field}>
          <Field
            label={TextPrimary('Descrição')}
            name="description"
            Component={Input}
            placeholder={`Informações do ${i18n.t(
              'StructuresName.Environment.SingularUpper'
            )}...`}
            multiline
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Estado (opcional)')}
              name="state"
              Component={Input}
              placeholder="Digite seu estado"
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Grupo Regional (opcional)')}
              name="regional_group"
              Component={Input}
              placeholder="Digite seu grupo regional"
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <div className={classes.field}>
            <Field
              label={TextPrimary(i18n.t('CityOptional'))}
              name="city"
              Component={Input}
              placeholder={`Digite sua ${i18n.t('City')}`}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className={classes.field}>
          <Image />
        </Grid>
      </Grid>
    </>
  );
}
