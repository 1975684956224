import React from 'react'
import Main from '../Main'

export default function VideoMiniCard({ size, ...rest }){
  return <Main  color="#f07071" 
                backgroundColor="#fadddd"
                size={size}
                text="Video"
                { ...rest }
                />
}