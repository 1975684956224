import React from 'react';

import AdminReport from './AdminReport';
import MemberReport from './MemberReport';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function FormLecture({ lecture }) {
  return (
    <Box>
      {!lecture ? (
        <Skeleton variant="rectangular" width={'95%'} height={213} />
      ) : lecture.can_manage ? (
        <AdminReport lecture={lecture} />
      ) : (
        <MemberReport lecture={lecture} />
      )}
    </Box>
  );
}
