import React from 'react';
import { Controller } from 'react-hook-form';

import { ErrorValidation } from '~/components/atoms/ErrorValidation/index';

import * as S from './styles';
import PropTypes from 'prop-types';

export const ReactHookFormController = ({
  control,
  name,
  Component,
  error
}) => {
  return (
    <S.Wrapper>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return <Component onChange={onChange} value={value} />;
        }}
      />
      {!!error && <ErrorValidation message={error} />}
    </S.Wrapper>
  );
};

ReactHookFormController.propTypes = {
  control: PropTypes.any,
  name: PropTypes.any,
  Component: PropTypes.any,
  error: PropTypes.any
};

ReactHookFormController.defaultProps = {
  control: null,
  name: null,
  Component: null,
  error: null
};
