import React from 'react';

import { useStyles } from './styles';
import { ButtonBase, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

export const TipButton = ({ tipMessage }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.popperArrow
      }}
      onClose={() => setOpen(false)}
      open={open}
      disableHoverListener
      arrow
      placement="top"
      title={
        <Typography className={classes.tooltipText}>{tipMessage}</Typography>
      }
    >
      <ButtonBase onClick={() => setOpen(!open)} disableRipple>
        <InfoIcon
          style={{
            fontSize: 18,
            color: '#fff'
          }}
        />
      </ButtonBase>
    </Tooltip>
  );
};

TipButton.propTypes = {
  tipMessage: PropTypes.string.isRequired
};
