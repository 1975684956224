import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    descriptionContainer: {
        marginBottom: 50
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        "& button": {
            "&:hover": {
                backgroundColor: props => `${props.theme.palette.secondary.main}E6`
            }
        }
    },
    alert: {
        backgroundColor: props => props.theme.palette.warn.main,
        fontSize: 16,
        marginBottom: 40
    },
    ReportContainer: {
        background: '#ffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        height:'550px',
        borderRadius: '3px',
        boxShadow: '1px 1px 3px 2px rgba(0,0,0, 0.2)'
    },
    ReportTitle: {
        fontSize: '30px',
        marginBottom: '20px',
        color: 'grey'
    },
    cardsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px',
        // margin: '20px'
    },
    cards: {
        marginTop:'20px'
    },
    upBoxReport: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '610px',
        height: '90px',
        marginTop:'10px',
        border: '1px solid rgba(0,0,0, 0.2)',
        borderRadius:'5px',
    }
});