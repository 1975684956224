import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  downloadButton: {
    padding: '8px',
    borderRadius: '50%',
    display: 'flex',
    width: 'min-content',
    minWidth: 0,
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },

  downloadButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: '16px'
  },

  customTooltip: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    maxWidth: '700px !important',
    backgroundColor: '#676D82 !important'
  }
}));
