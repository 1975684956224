import React, { createContext, useContext, useState } from 'react';

import { useLectureMuralContext } from '../LectureMuralContext';
import { useStatusArrayManager } from './useStatusArrayManager';
import PropTypes from 'prop-types';

const lectureDiscussionMuralContext = createContext();

export const LectureDiscussionMuralContextProvider = ({ children }) => {
  const { lecture } = useLectureMuralContext();
  const [totalAttachments, setTotalAttachments] = useState(0);
  const {
    offset,
    setOffset,
    statusArray,
    setStatusArrayFuncs,
    statusInChannel,
    channel,
    setStatusInChannel
  } = useStatusArrayManager(lecture);

  return (
    <lectureDiscussionMuralContext.Provider
      value={{
        statusInChannel,
        channel,
        offset,
        setOffset,
        statusArray,
        setStatusArrayFuncs,
        setStatusInChannel,
        totalAttachments,
        setTotalAttachments
      }}
    >
      {children}
    </lectureDiscussionMuralContext.Provider>
  );
};

export const useLectureDiscussionMuralContext = () =>
  useContext(lectureDiscussionMuralContext);

LectureDiscussionMuralContextProvider.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  children: PropTypes.node.isRequired
};
