import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getUsersListReport } from '~/services/reports/spaces';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UsersListReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUsersListReport}
        baseName="relatório_de_usuários_que_acessaram_a_plataforma_"
        id={id}
      />
    </div>
  );
}

UsersListReport.propTypes = {
  id: PropTypes.string
};

UsersListReport.defaultProps = {
  id: ''
};
