import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        padding: 50,
        maxWidth: props => props.maxWidth ? props.maxWidth : '100%',
        textAlign: 'center'
    },
    buttonArea: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30
    }
});