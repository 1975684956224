import React, { createContext, useContext, useEffect, useState } from 'react';

import i18n from '~/I18n';

import PropTypes from 'prop-types';

export const ReplicatedStructureContext = createContext();

export const ReplicatedStructureProvider = ({
  parentID,
  mirrorType,
  listService,
  mirrorService,
  children
}) => {
  const [replicationSteps, setReplicationSteps] = useState([
    { name: 'Informação', key: true },
    { name: i18n.t(`StructuresName.Environment.SingularUpper`), key: null },
    { name: i18n.t(`StructuresName.Course.SingularUpper`), key: null },
    { name: i18n.t(`StructuresName.Space.SingularUpper`), key: null },
    { name: i18n.t(`StructuresName.Subjects.SingularUpper`), key: null }
  ]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setReplicationSteps((prev) => {
      for (let i = activeStep + 1; i < prev.length - 1; i++) {
        prev[i].key = null;
      }
      return prev;
    });
  }, [replicationSteps]);

  return (
    <ReplicatedStructureContext.Provider
      value={{
        listService,
        replicationSteps,
        setReplicationSteps,
        activeStep,
        setActiveStep,
        parentID,
        mirrorType,
        mirrorService
      }}
    >
      {children}
    </ReplicatedStructureContext.Provider>
  );
};

export const useReplicateContext = () => useContext(ReplicatedStructureContext);

ReplicatedStructureProvider.propTypes = {
  parentID: PropTypes.string.isRequired,
  mirrorType: PropTypes.string.isRequired,
  listService: PropTypes.func.isRequired,
  mirrorService: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
