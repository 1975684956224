import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '~/I18n';
import api from '~/services/api';
import { Container } from './styles';

export default function MuralUsers() {
  const { login } = useParams();
  const [page, setPage] = useState(1);
  const [muralUser, setMuralUser] = useState([]);

  useEffect(() => {
    async function getMuralUser() {
      await fetchMuralUser();
    }

    getMuralUser();
  }, []);

  async function fetchMuralUser() {
    try {
      const response = await api.get(`api/users/${login}/statuses`, {
        params: { page }
      });
      setMuralUser([...muralUser, ...response.data]);
      setPage(page + 1);
    } catch (error) {
      console.tron.log(error.message);
    }
  }

  return (
    <Container>
      <ul>
        {muralUser.map((post) => (
          <li key={post.id}>{post.text}</li>
        ))}
      </ul>
      <button onClick={fetchMuralUser}>{i18n.t('SeeMore')}</button>
    </Container>
  );
}
