import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory, Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import SubjectForm from '~/components/organisms/SubjectForm';
import StepperForm from '~/components/templates/StepperForm';
import { useCustomSnackbar } from '~/contexts/customSnackBar';
import i18n from '~/I18n';
import { handleErrors } from '~/utils/auxiliaryFunctions';
import { subjectValidations } from '~/utils/SubjectValidation/yup';

import { createSubject, updateSubject } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CreateSubject() {
  const theme = useTheme();
  const { spaceID, courseID, environmentID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { showSnackbar } = useCustomSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mirroringPath = `/dashboard/environments/${environmentID}/courses/${courseID}/spaces/${spaceID}/subjects/new_mirror`;
  const navOptions = [i18n.t('Information')];
  const successMessage = `${i18n.t(
    'StructuresName.Subjects.SingularUpper'
  )} criado com sucesso! Deseja adicionar mídias?`;
  const callbackSuccessPath = `/dashboard/spaces/${spaceID}/`;

  const defaultValues = {
    id: null,
    spaceID: spaceID,
    scheduleable: false,
    subject_opening: new Date(),
    subject_closure: new Date(Date.now() + 3600000),
    visible: true,
    lecture_dependency: false,
    lectures: []
  };

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    {
      name:
        location?.state?.environmentName ||
        i18n.t('StructuresName.Environment.SingularUpper'),
      path: `/dashboard/environments/${environmentID}`
    }
  ];

  const renderContent = (props) => {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <>
          <GenericFormWrapper
            Form={SubjectForm}
            defaultValues={defaultValues}
            resolver={subjectValidations}
            isSubmitting={isSubmitting}
            {...props}
          />
          ;
        </>
      );
    }

    return false;
  };

  const handleCancel = () => {
    history.push(`/dashboard/spaces/${spaceID}/`);
  };

  const finalizeSuccess = (createdSubjectID) => {
    setSubmissionStatus('success');

    showSnackbar(successMessage, {
      buttonText: 'Adicionar mídia',
      buttonAction: () =>
        history.push(
          `/dashboard/environments/${environmentID}/courses/${courseID}/spaces/${spaceID}/subjects/${createdSubjectID}/edit`
        )
    });
    history.push(callbackSuccessPath);
  };

  const finalizeFailure = (error) => {
    handleErrors(error, enqueueSnackbar);
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const { id, spaceID, lectures, ...subject } = fields;
    // trata novas posições das aulas
    for (let index in lectures) {
      lectures[index].position = parseInt(index) + 1;
    }

    var body = { subject: { finalized: true, lectures, ...subject } };

    if (id)
      updateSubject(id, body)
        .then(finalizeSuccess)
        .catch(finalizeFailure)
        .finally(() => setIsSubmitting(false));
    else {
      delete body.subject.lectures;
      createSubject(spaceID, body)
        .then((createdSubject) => {
          finalizeSuccess(createdSubject.data.id);
        })
        .catch(finalizeFailure)
        .finally(() => setIsSubmitting(false));
    }
  }

  useEffect(() => {
    document.title = `${theme.title} | Criar ${i18n.t(
      'StructuresName.Subjects.SingularLower'
    )}`;
  }, []);

  return (
    <StepperForm
      navOptions={navOptions}
      breadcrumbs={breadcrumbs}
      onSubmit={submitForm}
      formTitle={i18n.t('CreateSubject.title')}
      submitButtonTitle={i18n.t('CreateSubject.saveButton')}
      handleCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}
