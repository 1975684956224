import React from 'react';
import Text  from '~/components/atoms/Text'
import i18n  from '~/I18n';

export default function SizeError () {
  return(
    <Text textAlign="center"
          variant="mediumText"
          color="error"
          >
      {i18n.t('MaximumSize')}
    </Text>
  );
};