import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdManageAccounts } from 'react-icons/md';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import MuralAccessManagementModal from '~/components/organisms/SpaceForm/MuralAccessManagement/Modal';

import { useTheme } from '@material-ui/core';

const MuralAccessManagement = () => {
  const theme = useTheme();

  const [show, setShow] = useState(false);
  const { setValue } = useFormContext();

  function handleClose() {
    setShow(false);
  }

  function handleConfirm(rule) {
    setValue('mural_access', rule);
    setShow(false);
  }

  return (
    <>
      <MuralAccessManagementModal
        maxWidth={500}
        show={show}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />

      <ButtonSquare
        type="button"
        width={248}
        height={56}
        border={`1px solid ${theme.palette.primary.main}`}
        backgroundColor="white"
        onClick={() => {
          setShow((value) => !value);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MdManageAccounts fontSize={20} color={theme.palette.primary.main} />
          <Text color="primary" variant="lg">
            &nbsp;Gerenciar acesso à Discussão
          </Text>
        </div>
      </ButtonSquare>
    </>
  );
};
export default MuralAccessManagement;
