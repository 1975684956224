import React from 'react';

import UploadIcon from '../Icons/UploadIcon';
import { useStyles } from './styles';

export default function FileInput ({
  accept,
  setSelectedFile,
  setFileTypeError,
  setSizeError,
  checkMaxSize,
  canUploadType,
  dragDropId
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    const file = event.target.files[0];

    if (canUploadType(file.type)) {
      if (checkMaxSize(file.size / 1000000)) {
        setSelectedFile(file);
        setFileTypeError(false);
        setSizeError(false);
      } else {
        setSelectedFile(null);
        setSizeError(true);
      }
    } else {
      setFileTypeError(true);
      setSelectedFile(null);
    }
  };

  return (
    <div>
      <label
        htmlFor={`dragDrop${dragDropId}`}
        className={classes.dragDropInput__input}
      >
        <UploadIcon />
      </label>

      <input
        id={`dragDrop${dragDropId}`}
        style={{ display: 'none' }}
        type="file"
        name="excel"
        accept={accept}
        onChange={handleChange}
      />
    </div>
  );
}
