import { selectEnglishWordsByClient } from '~/I18n/modules/en-US';
// import { clientName } from '~/utils/auxFunctions';
import settings from '~settings';

const wordsByClient = selectEnglishWordsByClient();
const client = settings.name;

export default {
  translations: {
    ...wordsByClient,
    Analytics: 'Analytics',
    Manual: 'Manual',
    ExerciseNotDoneYet: 'Exercise Not Done Yet',
    DoExercise: 'Do Exercise',
    NotDoneYet: 'You have not done the exercise yet',
    MemberTime: 'Your time',
    MemberGrade: 'Your grade',
    MemberName: 'Student name',
    Seconds: 'Seconds',
    Minutes: 'Minutes',
    Hours: 'Hours',
    QuestionsReport: 'Question report',
    Review: 'Review',
    QuestionsMultipleChoices: 'Questions(multiple choices)',
    ExplainedQuestions: 'Explained questions',
    BestGrades: 'Best grades',
    Recently: 'Recently',
    Name: 'Name',
    Grade: 'Grade',
    Time: 'Time',
    Hits: 'Hits',
    Misses: 'Misses',
    Null: 'Null',
    OverallTime: 'OverallTime',
    People: 'People',
    HowManyDidAlready: 'How many did already',
    OverallGrade: 'OverallGrade',
    Abbreviation: 'Abbreviation',
    Keywords: 'Keywords',
    Content: 'Content',
    Discussion: 'Discussion',
    Members: 'Members',
    Send: 'SEND',
    Explanations: 'Teacher Comments',
    Question: 'Question',
    Submitted: 'Exercise Already Answered',
    Rating: 'Rating',
    Exercise: 'Exercise',
    BioCurriculum: 'Description and Curriculum',
    Certificates: 'Certificates',
    Back: 'Back',
    Education: 'Education',
    Experience: 'Professional Experience',
    Languages: 'Languages',
    InterestAreas: 'Interest Areas',
    Contacts: 'Contacts',
    Present: 'Present',
    bachelorship: 'Bachelorship in',
    Quit: 'Log Out',
    EmptyListEnvironments: "You don't have any environments yet.",
    EmptyListCourses: 'This environment does not have any courses yet.',
    EmptyListSpaces: 'This course has no subjects yet.',
    EmptyListLectures:
      'It was not possible to get the list of classes for this module. Please try again.',
    EmptyListModules: 'This course does not have modules yet.',
    ExceptionHandlerMessage: 'Something went wrong. Try again.',
    ErrorMessage: 'The request could not be completed. Please try again.',
    ErrorUnsuportedFileMessage: 'We do not support this type of file.',
    Profile: 'Profile',
    FieldRequiredErrorMessage: 'There is a field that needs to be filled',
    ComplementaryCourseCourse: 'Course',
    ComplementaryCoursePlaceholder: 'eg: User Experience',
    ComplementaryCourseInstitution: 'Institution',
    ComplementaryCourseInstitutionPlaceholder: 'eg: Udemy',
    ComplementaryCourseYear: 'Year',
    ComplementaryCourseYearPlaceholder: 'eg: 2020',
    ComplementaryCourseWorkload: 'Workload',
    ComplementaryCourseWorkloadPlaceholder: 'Course total workload',
    ComplementaryCourseDescription: 'Description',
    ComplementaryCourseDescriptionPlaceholder: 'Optional',
    EventEducationName: 'Event',
    EventEducationNamePlaceholder: 'eg: Campus party',
    EventEducationRole: 'Role',
    EventEducationRolePlaceholder: 'Select a role',
    EventEducationYear: 'Year',
    EventEducationYearPlaceholder: 'Select a year',
    HigherEducationInstitution: 'Institution',
    HigherEducationInstitutionPlaceholder: 'eg: Universidade de São Paulo',
    HigherEducationKind: 'Degree',
    HigherEducationKindPlaceholder: 'Select a degree',
    HigherEducationCourse: 'Major',
    HigherEducationCoursePlaceholder: 'eg: Administration',
    HigherEducationArea: 'Field of study',
    HigherEducationAreaPlaceholder: 'eg: Educations Software',
    HigherEducationStartYear: 'Start year',
    HigherEducationStartYearPlaceholder: 'eg:2020',
    HigherEducationEndYear: 'End year',
    HigherEducationEndYearPlaceholder: 'eg:2025',
    HigherEducationDescription: 'Description',
    HigherEducationDescriptionPlaceholder: 'Optional',
    HighSchoolInstitution: 'Institution',
    HighSchoolInstitutionPlaceholder: 'eg: Escola Tecnica Estadual',
    HighSchoolEndYear: 'End year',
    HighSchoolEndYearPlaceholder: 'Select a year',
    HighSchoolDescription: 'Description',
    HighSchoolDescriptionPlaceholder: 'Optional',
    AddItem: 'Add item',
    AddEducation: 'Add education',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    Save: 'Save',
    Technical: 'Technical',
    Degree: 'Degree',
    Bachelorship: 'Bachelorship',
    PosLatoSensu: 'Pós "Lato Sensu / MBA"',
    PosStrictoSensu: 'Pós "Stricto Sensu"',
    Doctorate: 'Doctorate',
    Phd: 'PhD',
    Participant: 'Participant',
    Speaker: 'Speaker',
    Organizer: 'Organizer',
    HigherEducation: 'Higher Education',
    HighSchool: 'High School',
    EventEducation: 'Event',
    ComplementaryCourse: 'Complementary Course',
    HomePage: 'Home Page',
    overview: 'Overview',
    myMural: 'My wall',
    following: 'Following',
    shareSomething: 'Share something to your wall',
    friendshipRequestText: 'wants to connect with you',
    noDescription: 'No description to show',
    noEducations: 'No education to show',
    noExperiences: 'No professional experience to show',
    noLanguages: 'No languages to show',
    NoInterestAreas: 'No interests to show',
    noEnvironments: 'No subscriptions to show',
    noContacts: 'No contacts to show',
    ViewAchievements: 'View Achievements',
    AddReply: 'Add a reply',
    Reply: 'Reply',
    ShowReplies: 'Show replies',
    HideReplies: 'Hide replies',
    commentedOn: 'commented on',
    of: 'of',
    Student: 'Student',
    EditProfile: 'Edit Account',
    Achievements: 'Achievements',
    Mural: 'Wall',
    AddedBy: 'Added by',
    EmptyWall: 'Empty Wall',
    MaximumImageSize: 'The maximum image size must be 10MB',
    LoadingImage: 'Loading image',
    DescriptionPlaceholder: 'Write a little about yourself',
    InterestPlaceholder: 'Add an area of ​​interest ...',
    AddLanguage: 'Add language',
    AddExperience: 'Add experience',
    Language: 'Language',
    LanguagePlaceholder: 'eg: Portuguese, English',
    Role: 'Role',
    Company: 'Company',
    StartDate: 'Entry date',
    EndDate: 'Departure date',
    CurrentlyWorking: 'I currently work for this company',
    Description: 'Description',
    RolePlaceholder: 'eg.: Administrative Assistant',
    CompanyPlaceholder: 'eg: Sebrae',
    Optional: 'Optional',
    EducationType: 'Education type',
    EducationTypePlaceholder: 'Select a type',
    Month: 'Month',
    Year: 'Year',
    UpdateProfileError: 'There was a problem, check the fields.',
    UpdateProfileSuccess: 'Data updated successfully!',
    DeleteEducation: 'Are you sure you want to delete this education?',
    DeleteExperience: 'Are you sure you want to delete this experience?',
    DeleteErrorMessage:
      'There was a problem with the request, please try again later.',
    HelpCenter: 'Help Center',
    BasicGuide: 'Basic guide',
    StudentGuide: 'Student guide',
    TeacherGuide: 'Teacher guide',
    ManagerGuide: 'Manager guide',
    ContactUs: 'Contact us',
    ContactUsNamePlaceholder: 'Enter your name',
    ContactUsEmailPlaceholder: 'Enter a contact email',
    ContactUsSubjectPlaceholder: 'Type the message subject here',
    ContactUsBody: 'Message',
    ContactUsBodyPlaceholder: 'Write your message here',
    Email: 'Email',
    Category: 'Category',
    Subject: 'Subject',
    SendMessage: 'Send message',
    EmptyFieldError: 'This field needs to be filled.',
    InvalidEmail: 'This email is not valid.',
    SendMessageSuccess: 'Message sent successfully!',
    SendMessageError: 'This message could not be sent.',
    OpenZoom: 'Open Zoom class',
    OpenJitsi: 'Open Jitsi Meet class',
    StreamKey: 'Your stream key',
    ObsExplanation:
      'Use this key to start the transmission in your streaming program. We recommend the free software ',
    StreamGuide: 'Click here for instructions on how to do your first stream.',
    DeletedUser: 'Deleted user',
    GenerateCertificateSuccess:
      'Download was successful! You can find it in the Downloads folder.',
    GenerateCertificateRequestError:
      'There was a problem with the request. Please try again later.',
    GenerateCertificateRuleError:
      'You need to finish more lectures to get the certificate!',
    GetCertificate: 'Get certificate',
    NoStoragePermission: 'Storage permission was not granted.',
    SearchSebraeEdu: `Search on ${client}`,
    RecentSearches: 'Recent searches',
    Clean: 'Clean',
    ContactListInputPlaceholder: 'Search',
    ShowMore: 'Show More',
    NoStatus: 'No status',
    MenuItem: {
      Settings: 'Settings',
      Messages: 'Messages',
      MyProfile: 'My profile',
      HelpCenter: 'Help center',
      ContactUs: 'Contact us',
      PrivacyPolicy: 'Privacy policy and terms of use',
      LogOut: 'Logout'
    },

    MyContacts: 'My contacts',

    ShowAll: 'Show all',
    Friendship: {
      title: 'Friendship suggestion',
      pending: 'pending',
      connect: 'connect',
      Requested: 'Requested'
    },
    Home: 'Home Page',
    OldVersion: 'Change to old version',
    SearchNotFound: 'We could not found results for this search',
    SearchPlaceholder: 'Search',
    Welcome: 'Welcome',
    Tour: {
      Welcome: `Welcome to ${client}`,
      About: `${client} is an educational platform that adopts a social network structure to facilitate learning and promote engagement. Want to Know more about ? Follow the tour!`
    },
    WelcomeMessage: `${client} is an educational platform that adopts a social network structure to facilitate learning and promote engagement.!`,

    // duvida aqui
    SharedOnItsWall: 'compartilhou uma nova mensagem em seu mural',
    Refuse: 'Refuse',
    Remove: 'Remove',
    Accept: 'Accept',
    ReportComment: 'Report comment',
    Report: 'Report',
    RecentAchievements: 'Recent Achievements',
    // ShareOnWallLecture: 'Compartilhe algo no mural',
    ShareOnWallLecture: 'Share something',
    // ShareOnYourWall: 'Compartilhe algo ao seu mural',
    ShareOnYourWall: 'Share something on your mural',
    Account: 'Account',
    toEdit: 'Edit',
    toList: 'List',
    toCreate: 'Create',
    OtherWall: 'Another wall',
    ShowAnotherProfile: 'Show another profile',
    Users: 'Users',
    CreateUser: 'Create user',
    toFollow: 'Follow',
    commonContacts: 'contacts in common',
    searchMembroPlaceholder: 'Search member',
    Admin: 'Administrator',
    Moderator: 'Moderator',
    Environment_admin: 'Administrator',
    Course_admin: 'Administrator',
    Teacher: 'Professor',
    Tutor: 'Tutor',
    removeFromContactList:
      'Want to remove {{firstName}} {{lastName}} from your contact list?',
    removeFromContactListSuccess:
      'Done, {{firstName}} {{lastName}} was removed from your contact list',
    CertificateInfo: {
      StartDate: 'Start date of the course',
      EndDate: 'End date of the course',
      DateExample: 'e.g.: 30/04/2021',
      // EnableGenerator: 'Habilitar geração de certificado',
      EnableGenerator: 'Enable certificate generator',
      ResponsibleName: 'Responsible Name',
      ResponsibleNamePlaceholder:
        // 'Insira o nome do responsável pela assinatura',
        "Enter signature's reponsible name",
      ResponsibleRole: 'Responsible role',
      ResponsibleRolePlaceholder:
        // 'Insira o cargo do responsável pela assinatura',
        "Enter signature's responsible role",
      OrganizationName: 'Organization name',
      OrganizationNamePlaceholder: 'Enter organization name',
      // MinPercent: 'Percentual mínimo para certificado',
      MinPercent: 'Minimum percent to get certificate',
      MinPercentPlaceholder:
        // 'Insira a porcentagem mínima de aulas assistidas para a geração do certificado',
        'Enter the minimum percent of classes watched to generate certificate',
      ResponsibleSignature: "Responsible's signature"
    },

    CourseInfo: {
      // CourseNamePlaceholder: 'Insira o nome do curso',
      CourseNamePlaceholder: 'Enter the name of the course',
      AbbreviationPlaceholder: 'Enter course initials',
      WorkloadPlaceholder: 'e.g.: 50',
      AverageRating: 'Evaluation average',
      AverageRatingPlaceholder: 'e.g.: 9.5',
      AddressPlaceholder: 'Enter a web address',
      CourseProgram: 'Course program',
      CourseProgramPlaceholder: 'Enter a course summary',
      KeywordPlaceholder: 'Enter a keyword',
      ModerateEntry: 'Moderate Entry'
    },
    MonetizeInfo: {
      MonetizeCourse: 'Monetize Course',
      CoursePrice: "Course's price",
      CoursePricePlaceholder: 'e.g: 30.00'
    },
    Address: 'Address',
    Subscription: 'Subscription',
    all: 'all',
    all_male: 'all',
    all_female: 'all',
    NotificationInfo: {
      SendReminderEmail: 'Send a reminder email',
      SendEmailWhenPercentIsOver:
        // 'Enviar emails quando a porcentagem de conclusão do curso estiver abaixo de',
        'Send email when the course completion percentage is below',
      SendEmailWhenPercentIsOverPlaceholder: 'e.g: 30'
    },
    NewCourse: 'New course',
    SaveCourse: 'Save course',
    EditBioCurriculum: {
      errorMessage: 'Oops! Something went wrong...',
      successMessage: 'Saved successfully!',
      AddAnInterestAreas: 'Add an interest...',
      LanguagesPlaceholder: 'e.g: Portuguese, English'
    },
    course: 'Course',
    course_plural: 'Courses',
    courseWithCount: '{{count}} course',
    courseWithCount_plural: '{{count}} courses',
    EnvironmentInfoForm: {
      EnvironmentNamePlaceholder: "Enter environment's name",
      AbbreviationPlaceholder: "Enter environment's initials",
      AddressPlaceholder: 'Enter a web address',
      MinPercent: 'Percent to certificate',
      MinPercentPlaceholder:
        // 'Insira um percentual mínimo para a geração do certíficado',
        'Enter a minimum percent to generate certificate',
      KeywordPlaceholder: 'Enter a keyword...',
      AddDescription: 'Add a description'
    },
    Image: 'Image',
    Continue: 'Continue',
    End: 'End',
    GoBack: 'Back',
    NoImageSet: 'You did not select any image',
    SelectAnEmoji: 'Select an emoji ',
    EventForm: {
      Name: 'Name of the event'
    },
    Proceed: 'Proceed',
    CreatedAt: 'Created at',
    SupportMaterials: 'Support materials',
    toDelete: 'Delete',
    Delete: 'Delete',
    ContactDeleteMessage: 'deleted {{index}} from contact list',
    Login: {
      Title: 'Learning has never been easier.',
      Subtitle: ` Hello! ${client} welcomes you, which is now part of
      our knowledge building network.`,
      AccessYourAccount: 'Access your account',
      Error: 'Login or password invalid',
      EnterButton: 'Enter',
      RememberMeCheck: 'Remember me'
    },
    RecoverPassword: {
      Title: 'We are the missing social network',
      Subtitle: `${client} is a learning environment that was designed to provide intense forms of collaboration around media displayed on its pages.`,
      SucessMessage: 'A message was sent to registered email',
      ErrorMessage:
        'We had a problem with your registered email, please contact our support.'
    },
    ForgotPassword: 'Forgot your password?',
    PasswordReset: 'Password Reset',
    Information: 'Informations',
    Previous: 'Previous',
    Next: 'Next',
    FullScreen: 'Full Screen',
    TeachersComment: "Teacher's comment",
    AllQuestionsMustBeAnswered: 'All questions must be answered!!!',
    SubmittedExercise: 'SUBMITTED',
    ClassesWatched: 'Classes watched',
    FinishedExercizes: 'Finished exercizes',
    FinishedDisciplines: 'Finished disciplines',
    FinishedWithMastery: 'FInished with mastery',
    NumberOfQuestionsAnswered: 'Number of questions answered',
    TimeDistance: '{{time}} ago',
    CreateModule: 'Create Module',
    CreateClass: 'Create Class',
    ManageModule: 'Manage Module',
    noAbbreviation: 'No abbreviation',
    link: 'Link',
    noLink: 'No link',
    noKeywords: 'No keywords',
    noSupportMaterials: 'No support materials',
    noRating: 'No ratings',
    noTime: 'There is no duration',
    AddDiscipline: 'Add discipline',
    GenerateCertificate: 'Generate Certificate',
    SeeMore: 'See more',
    NoClassesForThisCourse: 'There are not classes for this course',
    TermsOfUse: 'Terms of use',
    PrivacyPolicy: 'Privacy policy',
    Accessibility: 'Accessibility',
    ContentNotAvailableYet: 'Content not available yet.',
    CaractersLeft: '{{caracterLeft}} caracters left',
    Member: 'Member',
    RemoveMember: 'Remove',
    ManageSpace: 'Manage space',
    BackToHomePage: 'Back to home',
    or: 'or',
    ReportInfo: {
      ComercialContent: 'Unwanted commercial content or spam',
      HateSpeech: 'Hate speech or explicit violence.',
      HarassmentOrBullying: 'Harassment or bullying',
      Other: 'Other'
    },
    close: 'Close',
    continue: 'Continue',
    alert: 'Alert',
    success: 'Success',
    error: 'ERROR',
    name: 'Name',
    path: 'Path',
    Attachment: 'Attachment',
    City: 'City',
    CityOptional: 'City (optional)'
  }
};
