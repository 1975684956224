import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import { StatusInput } from '~/components/molecules/StatusInput';
import {
  LectureDiscussionMuralContextProvider,
  useLectureDiscussionMuralContext
} from '~/contexts/LectureDiscussionMuralContext';
import { useLectureMuralContext } from '~/contexts/LectureMuralContext';
import i18n from '~/I18n';
import { handleAddNewStatusMural, sendToMuralListener } from '~/services/mural';

import { AttachmentsManagerButton } from './AttachmentsManagerButton';
import { InfiniteScrollStatus } from './InfiniteScrollStatus';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export function DiscussionBoardContent() {
  const { lecture, extendedWall } = useLectureMuralContext();
  const { id: lectureId } = lecture;
  const classes = useStyles();
  const [newStatusCreated, setNewStatusCreated] = useState(false);
  const [attachmentRequired, setAttachmentRequired] = useState(
    !lecture.can_publish
  );
  const {
    channel,
    setStatusArrayFuncs,
    setTotalAttachments
  } = useLectureDiscussionMuralContext();

  const { enqueueSnackbar } = useSnackbar();

  async function addNewStatusMural(formData) {
    try {
      const { data } = await handleAddNewStatusMural(lectureId, formData);
      if (!lecture.private_mural || lecture.can_manage) {
        sendToMuralListener(channel, {
          content: data,
          type: 'TYPE_COMMENT_POSTED'
        });
      }
      setStatusArrayFuncs.addNewStatus(data);
      if (formData.has('status[files][]')) {
        setTotalAttachments((prev) => prev + 1);
        setAttachmentRequired(false);
      }
      if (!extendedWall) setNewStatusCreated(true);
    } catch (error) {
      if (error.response.status === 401) {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(
          'Não foi possível processar sua solicitação, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
          {
            variant: 'error'
          }
        );
      }
    }
  }

  return (
    <>
      <Box className={classes.titleAndForm}>
        <Text color="primary" variant="lg">
          {i18n.t('Wall')}
        </Text>
        <StatusInput
          request_attachment={attachmentRequired && !lecture.can_manage}
          submit={addNewStatusMural}
        />
      </Box>
      <AttachmentsManagerButton />
      <InfiniteScrollStatus
        newStatusCreated={newStatusCreated}
        setNewStatusCreated={setNewStatusCreated}
      />
    </>
  );
}

export const DiscussionBoard = () => (
  <LectureDiscussionMuralContextProvider>
    <DiscussionBoardContent />
  </LectureDiscussionMuralContextProvider>
);
