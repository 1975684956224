import React from 'react';
import { Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import ContactUsForm from '~/components/organisms/ContactUsForm';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Paper, Box, Button, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

export default function ContactUsModal ({ open, setOpen }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  function handleClose () {
    setOpen(false);
  }

  if (!open) return null;

  return (
    <Paper elevation={3} className={classes.rootContactUs}>
      <Box className={classes.header}>
        <Box className={classes.buttonArea}>
          <Button className={classes.closeButton} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </Button>
        </Box>
        <Box className={classes.info}>
          <Text variant="text" color="gray">
            {i18n.t('HelpCenterMessage.prefix')}
            <span className={classes.link}>
              <Link exact to="/help">
                {i18n.t('HelpCenter')}
              </Link>
            </span>
            .
            <br />
            {i18n.t('HelpCenterMessage.sufix')}
          </Text>
        </Box>
      </Box>
      <Box className={classes.form}>
        <ContactUsForm isModal />
      </Box>
    </Paper>
  );
}

ContactUsForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

ContactUsForm.defaultProps = {
  open: false,
  setOpen: null
};
