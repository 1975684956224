import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  helpText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#A8B1CE',
    width: '100%'
  },
  fieldBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));
