import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { sendMessageEmit } from '~/socket/message-events';

import { useStyles } from './styles';
import { useTheme, Input } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

export default function ChatFooter({ contact }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { register, handleSubmit, resetField } = useForm();
  const { profile } = useSelector((state) => state.user);

  const onSubmit = ({ message }) => {
    contact['id'] = contact.contact_id;
    sendMessageEmit(profile?.id, contact, message);
    resetField('message');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <footer className={classes.container}>
        <Input
          placeholder="Aa"
          className={classes.input}
          {...register('message', { required: true })}
          disableUnderline
          inputProps={{
            className: classes.inputProps
          }}
        />
        <button type="submit" className={classes.sendButton}>
          <SendIcon />
        </button>
      </footer>
    </form>
  );
}
