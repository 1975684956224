import React from 'react';

import * as S from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ErrorValidation = ({ message }) => {
  const theme = useTheme();
  return (
    <S.Wrapper>
      <S.Message color={theme.palette.error.main}>{message}</S.Message>
    </S.Wrapper>
  );
};

ErrorValidation.propTypes = {
  message: PropTypes.string
};

ErrorValidation.defaultProps = {
  message: ''
};
