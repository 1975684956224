import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  modal: {
    marginTop: '76px',
    zIndex: '9999 !important'
  },
  modalBackdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(15, 15, 15, 0.9)',
    zIndex: 9999,
    display: 'flex',
    maxWidth: '100vw',
    overflow: 'hidden'
  },
  modalContent: {
    display: 'flex',
    padding: '16px 32px',
    flexDirection: 'column',
    minHeight: 'min-content',
    minWidth: '100%',
    gap: '16px',
    position: 'relative',
    overflowX: 'hidden',
    zIndex: 9999
  },
  modalContentHeader: {
    display: 'flex',
    position: 'fixed',
    overflowX: 'hidden',
    left: 0,
    width: '100%',
    padding: '0px 32px',
    zIndex: 9999
  },
  modalContentHeaderInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flex: 1,
    overflowX: 'hidden'
  },
  attachmentName: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    color: '#fff',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis'
  },
  attachmentSize: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#fff'
  },
  modalContentHeaderAction: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  modalContentHeaderActionIcon: {
    fontSize: '32px',
    color: '#fff'
  },
  imageContainer: {
    overflow: 'hidden',
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '80vw',
    minWidth: '80vw',
    width: 'auto',
    objectFit: 'contain'
  },
  scaleButtonsContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    position: 'fixed',
    bottom: '10px'
  },
  scaleButton: {
    backgroundColor: '#32394E',
    padding: '4px 8px',
    paddingRight: '8px'
  }
});
