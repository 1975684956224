import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '~/services/api';

import {
  LinearProgress,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

export default function Spaces() {
  const { id } = useParams();
  const [spaces, setSpaces] = useState([]);
  const [page, setPage] = useState(1);

  const fetchSpaces = useCallback(async () => {
    try {
      const response = await api.get(`api/courses/${id}/spaces`);
      setSpaces([...spaces, ...response.data]);
      setPage(page + 1);
    } catch (error) {}
  }, [id, page, spaces]);

  // const fetchSpaces = async () => {
  //   await api
  //     .get(`api/courses/${id}/spaces`)
  //     .then((res) => setSpaces(res.data))
  //     .catch(console.log());
  // };

  // useEffect(() => {
  //   fetchSpaces();
  // }, []);

  useEffect(() => {
    if (spaces.length <= 0) fetchSpaces();
  }, [fetchSpaces, spaces]);

  return (
    <div>
      {spaces.length <= 0 && <LinearProgress />}
      {spaces.length > 0 && (
        <List>
          {spaces.map((space, idx) => (
            <ListItem
              key={idx}
              button
              component={Link}
              to={`/dashboard/spaces/${space.id}`}
            >
              <ListItemText>{space.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
