import React from 'react';

import LecturesReport from '~/components/molecules/LecturesReport';
import ReportRow from '~/components/molecules/ReportRow';
import { ReportTableAccordion } from '~/components/molecules/ReportTableAccordion';
import i18n from '~/I18n';
import { getSubjects } from '~/services/course';

import CourseInformationReport from './CourseInformationReport';
import SimplifiedReport from './SimplifiedReport';
import { useStyles } from './styles';
import TypeUsersReport from './TypeUsersReport';
import UserPerformanceReport from './UserPerformanceReport';
import UserReport from './UserReport';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function CourseReports({ id }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <ReportTableAccordion
        title="Relatórios de conclusão de mídias"
        report={LecturesReport}
        id={id}
        fetchFunction={getSubjects}
      />
      <ReportRow
        title={`Relatório simplificado de conclusão de ${i18n.t(
          'StructuresName.Course.SingularUpper'
        )}`}
        report={SimplifiedReport}
        id={id}
      />
      <ReportRow
        title={`Relatório de estruturas do ${i18n.t(
          'StructuresName.Course.SingularUpper'
        )}`}
        report={CourseInformationReport}
        id={id}
      />
      <ReportRow
        title="Relatório de usuários que acessaram a Plataforma"
        report={UserReport}
        id={id}
      />
      <ReportRow
        title={`Relatório de papéis do usuário no ${i18n.t(
          'StructuresName.Course.SingularUpper'
        )}`}
        report={TypeUsersReport}
        id={id}
      />
      <ReportRow
        title="Relatório de desempenho do aluno nos exercícios"
        report={UserPerformanceReport}
        id={id}
      />
    </div>
  );
}
CourseReports.propTypes = {
  id: PropTypes.string
};

CourseReports.defaultProps = {
  id: ''
};
