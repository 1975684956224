// Imports necessary for API requests and URL manipulation
import api from '~/services/api';
import { buildUrlWithParams } from '~/utils/buildUrlWithParams';

// Base URL for course-related API endpoints
const baseURL = '/api/courses';

// Removes a specific course by its ID
export const remove = async (courseID) => {
  const { data } = await api.delete(`${baseURL}/${courseID}`);
  return data;
};

// Retrieves list of enrollments for a given course, with pagination options
export const getEnrollments = async (courseID, page, perPage = 18) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${courseID}/user_course_associations`, {
      page,
      per_page: perPage
    })
  );
  return data;
};

// Fetches the enrollment link for a specific course
export const getEnrollmentLink = async (courseID) => {
  const { data } = await api.get(`${baseURL}/${courseID}/enrollment_links`);
  return data;
};

// Creates a new enrollment link for a course
export const createEnrollmentLink = async (courseID, body) => {
  const { data } = await api.post(`${baseURL}/${courseID}/enrollment_links`, {
    enrollment_link: { ...body }
  });
  return data;
};

// Retrieves details for a specific course by its ID
export const findByID = async (courseID) => {
  const { data } = await api.get(`${baseURL}/${courseID}`);
  return data;
};

// Searches for members within a course based on a search string, with pagination
export const searchMembers = async (
  courseID,
  searchString,
  page,
  per_page = 18
) => {
  const { data } = await api.get(
    buildUrlWithParams(
      `${baseURL}/${courseID}/user_course_associations/search`,
      {
        q: searchString,
        page,
        per_page
      }
    )
  );
  return data;
};

// Deletes specified members from a course
export const deleteMembers = async (courseID, membersID) => {
  await api.delete(`${baseURL}/${courseID}/user_course_associations`, {
    data: { collection_ids: membersID }
  });
};

// Imports members to a course from a provided file
export const importMembersFromFile = async (courseID, fileData) => {
  await api.post(
    `${baseURL}/${courseID}/import_data/import_members`,
    fileData,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
      }
    }
  );
};

// Removes members from a course based on data from a provided file
export const removeMembersFromFile = async (courseID, fileData) => {
  await api.post(
    `${baseURL}/${courseID}/import_data/remove_members`,
    fileData,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
      }
    }
  );
};

// Checks if a user is associated with a given course
export const checkAssociation = async (courseID, userID) => {
  const { data } = await api.get(
    `/api/users/${userID}/courses/${courseID}/user_course_association`
  );
  return data;
};

// Updates the roles of specific members within a course
export const updateRoles = async (courseID, membersID, role) => {
  await api.put(`${baseURL}/${courseID}/user_course_associations`, {
    collection_ids: membersID,
    role
  });
};

export const getSubjects = async (courseID, page = 1, perPage = 10) => {
  const { data } = await api.get(
    `${baseURL}/${courseID}/subjects?page=${page}&per_page=${perPage}`
  );
  return data;
};

export const createUser = async (courseID, userData) => {
  const { data } = await api.post(`${baseURL}/${courseID}/users`, userData);
  return data;
};

// Aggregates all functions for exporting
export default {
  remove,
  getEnrollments,
  getEnrollmentLink,
  createEnrollmentLink,
  findByID,
  searchMembers,
  deleteMembers,
  updateRoles,
  importMembersFromFile,
  removeMembersFromFile,
  checkAssociation,
  createUser
};
