import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root:{
    borderRadius: '3px',
    textTransform: 'uppercase',
  },
  small: {
    padding: '3px 6px 2px 6px',
    fontSize: '9px',
  },
  medium:{
    padding: '5px 9px 4px 9px',
    fontSize: '12px',
  },
  large: {
    padding: '8px 14px 7px 14px',
    fontSize: '15px',
  }
});
