import React from 'react';
import PropTypes from 'prop-types';

import { Box, List, ListItem, useTheme } from '@material-ui/core';

import Text from '~/components/atoms/Text';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { useStyles } from './styles';
import i18n from '~/I18n';

export default function Languages({ languages }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const langArray =
    languages && languages.length
      ? languages.split(',').filter((lang) => lang.trim() !== '')
      : [];

  return (
    <Box className={classes.root}>
      {langArray && langArray.length ? (
        <>
          <List className={classes.largeList}>
            {langArray.map((lang, index) => (
              <ListItem key={index} className={classes.item}>
                <FiberManualRecordIcon className={classes.icon} />
                <Text variant="largeText" color="gray">
                  {lang}
                </Text>
              </ListItem>
            ))}
          </List>
          <div className={classes.smallList}>
            {langArray.map((lang, index) => (
              <Box key={index} className={classes.item}>
                <FiberManualRecordIcon className={classes.icon} />
                <Text variant="text" color="gray">
                  {lang}
                </Text>
              </Box>
            ))}
          </div>
        </>
      ) : (
        <>
          <Box className={classes.noLanguagesLg}>
            <Text variant="largeText" color="gray">
              {i18n.t('noLanguages')}
            </Text>
          </Box>
          <Box className={classes.noLanguagesSm}>
            <Text variant="text" color="gray">
              {i18n.t('noLanguages')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
}

Languages.propTypes = {
  languages: PropTypes.string
};

Languages.defaultProps = {
  languages: ''
};
