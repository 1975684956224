import React from 'react';

import { useStyles } from './styles';
import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const Loading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Skeleton variant="text" width={230} height={24} />
        <Skeleton
          style={{ borderRadius: '8px' }}
          variant="rect"
          width={240}
          height={130}
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Box className={classes.content}>
          <Skeleton variant="text" height={24} width={230} />
          <Box style={{ display: 'flex', gap: '16px' }}>
            {[0, 1, 2].map((_, index) => (
              <Skeleton
                style={{ borderRadius: '8px' }}
                key={index}
                variant="rect"
                width={240}
                height={130}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
