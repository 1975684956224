import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    height: '76px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      padding: '0 40px'
    }
  },
  goToPlatformLink: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    color: '#fff',
    padding: '8px 16px',
    borderRadius: '4px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));
