import { useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Container, BreadText, TextWraper, BreadArrow } from './styles';

const BreadcrumbMuralItem = ({ breadcrumbs }) => {
  const theme = useTheme();
  const { environment_name, environment_id } =
    (breadcrumbs && breadcrumbs.environment) || {};
  const { course_name, course_id } = (breadcrumbs && breadcrumbs.course) || {};
  const { space_name, space_id } = (breadcrumbs && breadcrumbs.space) || {};
  const { lecture_name, lecture_id } =
    (breadcrumbs && breadcrumbs.lecture) || {};
  const { subject_name, subject_id } =
    (breadcrumbs && breadcrumbs.subject) || {};

  const contexts = {
    environment: {
      id: environment_id,
      name: environment_name,
      next: course_id,
      url: `/dashboard/environments/${environment_id}`
    },
    course: {
      id: course_id,
      name: course_name,
      next: space_id,
      url: `/dashboard/environments/${environment_id}/courses/${course_id}`
    },
    space: {
      id: space_id,
      name: space_name,
      next: lecture_id,
      url: `/dashboard/spaces/${space_id}`
    },
    subject: {
      // subject is a select and doesnt have a specific page.
      id: space_id, // that's why we use space_id
      name: subject_name,
      next: lecture_id,
      url: `/dashboard/spaces/${space_id}`
    },
    lecture: {
      id: lecture_id,
      name: lecture_name,
      next: null,
      url: {
        pathname: `/dashboard/lecture/${lecture_id}`,
        state: {
          environmentName: environment_name,
          environmentPath: `/dashboard/environments/${environment_id}`,
          spaceName: space_name,
          spacePath: `/dashboard/spaces/${space_id}`
        }
      }
    }
  };

  const BreadcrumbText = ({ type }) => {
    const { name, id, next, url } = contexts[type];
    return (
      <TextWraper>
        <BreadText
          theme={theme}
          to={url}
          numberOfLines={1}
          last={!next}
          title={name}
        >
          {name}
        </BreadText>
        {next && <BreadArrow> > </BreadArrow>}
      </TextWraper>
    );
  };

  return (
    <Container>
      <BreadcrumbText type="environment" />
      <BreadcrumbText type="course" />
      <BreadcrumbText type="space" />
      <BreadcrumbText type="subject" />
      <BreadcrumbText type="lecture" />
    </Container>
  );
};

export default BreadcrumbMuralItem;
