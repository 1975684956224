import * as yup from 'yup';

export const AddMemberFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Digite um e-mail válido')
    .required('Este campo é obrigatório'),
  confirm_email: yup
    .string()
    .trim()
    .when(['$userExist', '$alreadyRegisteredUser'], {
      is: (userExist, alreadyRegisteredUser) =>
        !userExist && !alreadyRegisteredUser,
      then: yup
        .string()
        .oneOf([yup.ref('email'), null], 'Os e-mails devem ser iguais')
        .required('Este campo é obrigatório'),
      otherwise: yup.string().notRequired()
    }),
  first_name: yup
    .string()
    .trim()
    .when(['$userExist', '$alreadyRegisteredUser'], {
      is: (userExist, alreadyRegisteredUser) =>
        !userExist && !alreadyRegisteredUser,
      then: yup.string().required('Este campo é obrigatório'),
      otherwise: yup.string().notRequired()
    }),
  last_name: yup
    .string()
    .trim()
    .when(['$userExist', '$alreadyRegisteredUser'], {
      is: (userExist, alreadyRegisteredUser) =>
        !userExist && !alreadyRegisteredUser,
      then: yup.string().required('Este campo é obrigatório'),
      otherwise: yup.string().notRequired()
    }),
  password: yup
    .string()
    .nullable()
    .test(
      'is-valid-password',
      'A senha deverá conter no mínimo 6 caracteres.',
      (value) => {
        if (!value) return true;
        return value.length >= 6;
      }
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas não coincidem.')
    .when('password', {
      is: (val) => val && val.length > 0,
      then: yup.string().required('A confirmação de senha é obrigatória.'),
      otherwise: yup.string().nullable()
    }),
  role: yup.string().when('$alreadyRegisteredUser', {
    is: false,
    then: yup.string().notOneOf(['default'], 'Defina um papel para o usuário'),
    otherwise: yup.string().notRequired()
  }),
  mobile: yup
    .string()
    .matches(/^.{0}$|^.{18,19}$/, 'Digite um numero de telefone válido')
});

export default {
  AddMemberFormSchema
};
