import api from '~/services/api';
import { update } from '~/services/Lecture';
import { create as createAudio } from '~/services/Lecture/audio';
import { create as createMirrorLecture } from '~/services/mirroring/lecture';
import { uploadVideo } from '~/services/vimeo';

// eslint-disable-next-line no-unused-vars
import { instanceOf } from 'prop-types';

export const handleLectureCreation = async (
  fields,
  defaultValues,
  setProgressVideoUpload,
  setVideoUploadProgressBarShow
) => {
  if (fields.imageTrack) delete fields.imageTrack;

  if (!fields.subjectID && defaultValues.subjectID)
    fields.subjectID = defaultValues.subjectID;

  const { subjectID, ...lecture } = fields;

  let body = {};
  let type = '';

  switch (lecture.lectureable.type) {
    case 'audio':
      type = 'seminars';
      return handleAudio(lecture, subjectID);
    case 'multimedia':
      type = 'pages';
      body = await handleMultimedia(lecture);
      break;
    case 'presentation':
      type = 'documents';
      body = await handlePresentation(lecture);
      break;
    case 'image':
      type = 'pages';
      body = await handleImage(lecture, subjectID);
      break;
    case 'video':
      type = 'seminars';
      body = await handleVideo(
        lecture,
        setProgressVideoUpload,
        setVideoUploadProgressBarShow
      );
      break;
    case 'videocall':
      type = 'seminars';
      body = handleVideoCall({ ...lecture, subjectID });
      break;
    case 'youtube':
      type = 'seminars';
      body = handleYoutube(lecture);
      break;
    case 'youtube_livestream':
      type = 'seminars';
      body = handleYoutubeLiveStream({ ...lecture, subject_id: subjectID });
      break;
    case 'clone':
      type = 'clones';
      body = handleClone({ ...lecture, subject_id: subjectID });
      break;
    case 'exercise':
      type = 'exercises';
      body = await handleExercise(lecture);
      break;
    case 'form':
      type = 'forms';
      body = handleQuestionary(lecture);
      break;
    case 'forum':
      type = 'pages';
      body = await handleForum(lecture);
      break;
  }
  if (fields.id) {
    return updateLecture(fields);
  }

  //Verify if body is formadata to submit pdf on handledocument
  if (body instanceof FormData) {
    body.append('subject_id', subjectID);
    return createLecture(type, body);
  }

  body.subject_id = subjectID;
  if (type === 'clones' && body.lecture.is_mirror) {
    // Esta passando um obj vazio para o payload pois ainda não foi implementado no front uma logica onde o user possa ,modifica-lo.
    return createMirrorLecture(
      body.lecture.lecture_id,
      { name: body.lecture.name },
      subjectID
    );
  }

  return createLecture(type, body);
};

const handleForum = async (fields) => {
  const temp = { ...fields };
  temp.lectureable.multimedia.forum = true;
  return await handleMultimedia(temp);
};

const handleMultimedia = async (fields) => {
  let page = fields.lectureable.multimedia;

  page.lecture_attributes = fields;

  delete page.lecture_attributes.lectureable;

  const content = document.createElement('body');

  content.innerHTML = page.content;

  let iframes = page.iframes;

  delete page.iframes;
  delete page.content;

  const iframeElements = content.querySelectorAll('h2');

  iframeElements.forEach((target) => {
    if (target.textContent.includes('IFRAME')) {
      const position = parseInt(target.textContent.replace('IFRAME', ''));
      const iframe = iframes.find((iframe) => iframe.position === position);
      target.outerHTML = iframe.body + '&nbsp;';
    }
  });

  page.body = content.innerHTML;

  if (page.id) {
    try {
      await api.put('/api/lectureables/pages/' + page.id, { page });
    } catch (e) {
      if (e.response.status !== 401) throw e;
    }
  }

  return { page };
};

const handleAudio = (fields, subjectID) => {
  if (fields.id) return updateLecture(fields);

  let formData = new FormData();
  formData.append('subject_id', subjectID);
  formData.append('seminar[media]', fields.lectureable.audio.file);
  formData.append(
    'seminar[downloadable]',
    fields.lectureable.audio.downloadable
  );

  delete fields.lectureable;

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(`seminar[lecture_attributes][${key}]`, value);
  });

  formData.append('seminar[kind]', 'audio');

  return createAudio(formData);
};

const handlePresentation = (fields) => {
  if (fields.id) return handleEditPresentation(fields);
  var downloadable = fields.lectureable.presentation.downloadable;

  var formData = new FormData();
  formData.append('file', fields.lectureable.presentation.file);
  formData.append('downloadable', downloadable);

  delete fields.lectureable;

  Object.entries(fields).forEach(([key, value]) => {
    formData.append(`lecture_attributes[${key}]`, value);
  });

  return formData;
};

const handleEditPresentation = async (fields) => {
  const body = {
    downloadable: fields.lectureable.downloadable,
    lecture_id: fields.id
  };

  try {
    await api.put(`/api/lectureables/documents/${fields.lectureable.id}`, body);
  } catch (e) {
    if (e.response.status !== 401) throw e;
  }
};

function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

const handleImage = async (fields, subjectID) => {
  if (fields.id) return {};
  try {
    const image = fields.lectureable.image.file;
    // Prepare and send the image to the API.
    var formData = new FormData();
    const base64 = await convertBase64(image);

    formData.append('avatar', base64);
    formData.append('avatar_name', image.name);

    const { data } = await api.post(
      `api/subjects/${subjectID}/avatars/multimedia`,
      formData
    );

    // Update the content with the returned image URL.
    fields.lectureable.multimedia.content = `<p><img alt="" src="${data.url}"/></p>`;
    return handleMultimedia(fields);
  } catch (error) {}
};

const handleVideo = async (
  fields,
  setProgressVideoUpload,
  setVideoUploadProgressBarShow
) => {
  let seminar = {};
  let downloadable = fields.lectureable.video.downloadable;

  if (fields.lectureable.video.vimeoOption) {
    setVideoUploadProgressBarShow(true);
    var video = fields.lectureable.video.file;
    const response = await uploadVideo(video, setProgressVideoUpload);

    seminar = {
      kind: 'upload',
      vimeo_option: true,
      external_resource: response.uri,
      link: response.link,
      media_content_type: video.type
    };
  } else {
    seminar = {
      kind: 'upload',
      vimeo_option: false,
      video_id: fields.lectureable.video.videoID,
      media_content_type: 'video/mp4'
    };
  }

  seminar = {
    ...seminar,
    downloadable
  };

  delete fields.lectureable;
  seminar.lecture_attributes = fields;

  setVideoUploadProgressBarShow(false);
  return { seminar: seminar, lecture_id: fields.id };
};

const handleVideoCall = (fields) => {
  var kind = fields.lectureable.videocall.kind;

  let seminar = {};

  if (kind == 'call') {
    seminar = { kind };
  } else if (kind == 'zoom') {
    seminar = {
      subject_id: fields.subjectID,
      name: fields.name,
      kind
    };
  } else if (kind == 'google_meet') {
    var { google_meet } = fields.lectureable.videocall;
    seminar = {
      ...google_meet,
      subject_id: fields.subjectID,
      name: fields.name,
      kind
    };
  }

  delete fields.lectureable;
  seminar.lecture_attributes = fields;

  return { seminar: seminar, lecture_id: fields.id };
};

const handleYoutube = (fields) => {
  let seminar = {
    ...fields.lectureable.youtube,
    kind: fields.lectureable.type
  };

  delete fields.lectureable;
  seminar.lecture_attributes = fields;

  return { seminar: seminar, lecture_id: fields.id };
};

const handleYoutubeLiveStream = (fields) => {
  let seminar = {
    ...fields.lectureable.livestream,
    kind: fields.lectureable.type
  };
  seminar.name = fields.name;
  seminar.subject_id = fields.subject_id;

  delete fields.lectureable;
  seminar.lecture_attributes = fields;

  return { seminar: seminar, lecture_id: fields.id };
};

const handleClone = (fields) => {
  fields.lecture_id = fields.lectureable.clone.lecture_id;
  fields.is_mirror = fields.lectureable.clone.is_mirror;
  delete fields.lectureable;

  return { lecture: fields, lecture_id: fields.id };
};

const handleExercise = async (fields) => {
  if (fields.id) return await handleEditExercise(fields);

  fields.lectureable = fields.lectureable.exercise;

  let exercise = fields.lectureable;
  delete fields.lectureable;
  exercise.lecture_attributes = fields;

  return { exercise: exercise };
};

const handleQuestionary = (fields) => {
  if (fields.id) {
    return handleEditQuestionary(fields);
  }
  const exercise = fields.lectureable.exercise;
  delete fields.lectureable;

  exercise.lecture_attributes = fields;
  exercise.any_choice_questions_attributes = exercise.questions.map(
    (question) => {
      const sanitizedOptions = question.options_attributes.map((option) => {
        const { id, correct, letter, ...rest } = option;
        return rest;
      });

      return {
        statement: question.statement,
        options_attributes: sanitizedOptions
      };
    }
  );

  const form = {
    lectures_attributes: [exercise.lecture_attributes],
    any_choice_questions_attributes: exercise.any_choice_questions_attributes
  };

  return { form: form };
};

const handleEditExercise = async (fields) => {
  const buildItem = (item) => {
    delete item.letter;
    return {
      [item.id ?? Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)]: item
    };
  };
  const arr2obj = (arr) =>
    arr.reduce((o, item) => Object.assign(o, buildItem({ ...item })), {});

  const lec = fields.lectureable.exercise;
  let questionArr = [];
  for (let question of lec.questions) {
    const alternatives_attributes = arr2obj(question.alternatives);
    const { alternatives, ...sanitizedQuestion } = question;
    questionArr.push({ ...sanitizedQuestion, alternatives_attributes });
  }

  const questions_attributes = arr2obj(questionArr);
  const { questions, tags, thumbnails, ...sanitizedLec } = lec;
  const exercise = { ...sanitizedLec, questions_attributes };

  delete fields.lectureable;
  exercise.lecture_attributes = fields;

  try {
    await api.put(`/api/lectureables/exercises/${exercise.id}`, {
      exercise: exercise
    });
  } catch (e) {
    if (e.response.status !== 401) throw e;
  }
};

const handleEditQuestionary = (fields) => {
  delete fields.lectureable;
  return fields;
};

const createLecture = async (type, body) => {
  let url = `/api/lectureables/${type}/`;

  if (type === 'seminars') url += `${body.seminar.kind}`;

  return await api.post(url, body);
};

const updateLecture = async (fields) => {
  // eslint-disable-next-line no-unused-vars
  const { lectureable, id, ...body } = fields;
  return await update(id, { lecture: body });
};

export const deleteAvatar = async (url, subjectID) => {
  const regex = /\/multimedias\/(\d+)\//;
  const match = url.match(regex);

  if (match && match[1]) {
    const avatarId = match[1];
    await api.delete(
      `/api/subjects/${subjectID}/avatars/multimedia/${avatarId}`
    );
  }
};
