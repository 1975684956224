import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { LinearProgress, List, ListItem, ListItemText } from '@material-ui/core';
import api from '~/services/api';

export default function UsersContacts() {
	const { login } = useParams();
	const [ mycontacts, setContacts ] = useState([]);

	useEffect(
		() => {
			async function getUsersContacts() {
				try {
					const response = await api.get(`/api/users/${login}/contacts`);
					setContacts(response.data);
				} catch (error) {}
			}

			getUsersContacts();
		},
		[ login ]
	);

	return (
		<div>
			{mycontacts.length <= 0 && <LinearProgress />}
			{mycontacts.length > 0 && (
				<ul>
					{mycontacts.map((contact, idx) => (
						<ListItem key={idx} button component={Link} to={`/dashboard/userContacts/${contact.login}`}>
							<ListItemText>{contact.first_name}</ListItemText>
						</ListItem>
					))}
				</ul>
			)}
		</div>
	);
}
