import React, { useState, memo } from 'react';

import Mural from '~/components/organisms/Mural';
import Overview from '~/components/organisms/Overview';
import i18n from '~/I18n';

import Following from '../organisms/Following';
import { Container, TabButton, TabContainer } from './styles';
import { Divider, Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

function TabBar({ handleLink, target }) {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={1}>{children}</Box>}
      </Typography>
    );
  }

  return (
    <Container>
      <TabContainer className="murals-tour">
        <TabButton
          onClick={() => setValue(0)}
          selected={value}
          buttonId={0}
          theme={theme}
        >
          {i18n.t('overview')}
        </TabButton>
        <TabButton
          onClick={() => setValue(1)}
          selected={value}
          buttonId={1}
          theme={theme}
        >
          {i18n.t('myMural')}
        </TabButton>
        <TabButton
          onClick={() => setValue(2)}
          selected={value}
          buttonId={2}
          theme={theme}
        >
          {i18n.t('following')}
        </TabButton>
      </TabContainer>
      <Divider />
      <TabPanel value={value} index={0}>
        <Overview target={target} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Mural target={target} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Following target={target} />
      </TabPanel>
    </Container>
  );
}

TabBar.propTypes = {
  handleLink: PropTypes.func
};

TabBar.defaultProps = {
  handleLink: null
};

export default memo(TabBar);
