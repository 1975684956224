/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import Courses from './Courses';
import Loading from './Loading';
import NoContent from './NoContent';
import i18n from '~/I18n';

const Content = ({ courses, environmentID, environmentName }) => {
  const [coursesItem, setCoursesItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setCoursesItem(courses);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [courses]);

  useEffect(() => {
    if (coursesItem.length === 0 && isLoading !== true) {
      setIsLoading(false);
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [isLoading]);

  return (
    <>

      {noData === true ? <NoContent /> : null}

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Courses
            courses={coursesItem}
            environmentID={environmentID}
            environmentName={environmentName}
          />
        </>
      )}
    </>
  );
};

export default Content;
