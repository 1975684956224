import React from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const Shimmer = () => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20
        }}
      >
        <Skeleton
          variant="rect"
          width={60}
          height={60}
          style={{
            borderRadius: '7%'
          }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px'
          }}
        >
          <Skeleton
            variant="rect"
            width="100px"
            height="15px"
            style={{
              marginLeft: '1.7rem',
              marginBottom: '10px'
            }}
          />
          <Skeleton
            variant="rect"
            width="70px"
            height="12px"
            style={{
              marginLeft: '1.7rem'
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20
        }}
      >
        <Skeleton
          variant="rect"
          width={60}
          height={60}
          style={{
            borderRadius: '7%'
          }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px'
          }}
        >
          <Skeleton
            variant="rect"
            width="100px"
            height="15px"
            style={{
              marginLeft: '1.7rem',
              marginBottom: '10px'
            }}
          />
          <Skeleton
            variant="rect"
            width="70px"
            height="12px"
            style={{
              marginLeft: '1.7rem'
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Shimmer;
