import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ChatFooter from '~/components/molecules/ChatFooter';
import ChatHeader from '~/components/molecules/ChatHeader';
import ChatMessagesView from '~/components/molecules/ChatMessagesView';
import ChatContactInfo from '~/components/organisms/ChatContactInfo';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Chat ({
  isModal,
  returnToList,
  modalHandleOpenInfo,
  contact,
  curChat,
  setCurChat
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [openInfo, setOpenInfo] = useState(false);
  const { profile } = useSelector((state) => state.user);

  function handleCloseInfo () {
    setOpenInfo(false);
  }

  function handleOpenInfo () {
    if (isModal) modalHandleOpenInfo();
    else setOpenInfo(true);
  }

  useEffect(() => { }, [curChat, profile]);

  return (
    <Box className={classes.container}>
      <ChatHeader
        handleOpenInfo={handleOpenInfo}
        profile={{
          ...curChat,
          thumbnails: curChat?.contact_attributes?.thumbnails
        }}
        returnToList={returnToList}
        isModal={isModal}
      />
      <ChatMessagesView contact={curChat} />
      <ChatFooter setCurChat={setCurChat} contact={curChat} />
      {!isModal && (
        <ChatContactInfo
          open={openInfo}
          handleClose={handleCloseInfo}
          contact={curChat}
          profile={profile}
        />
      )}
    </Box>
  );
}

Chat.propTypes = {
  isModal: PropTypes.bool,
  returnToList: PropTypes.func,
  modalHandleOpenInfo: PropTypes.func
};

Chat.defaultProps = {
  isModal: false,
  returnToList: null,
  modalHandleOpenInfo: null
};
