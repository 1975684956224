import React, { useEffect } from 'react';

import settings from '~settings';

import { useStyles } from './styles';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, useTheme } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

export default function AuthTermsModal() {
  const classes = useStyles(useTheme());
  const [open, setOpen] = React.useState(false);
  const client = settings.name;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <div>
      <Grid container spacing={12}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Grid item xs={12} sm={12} lg={12}>
              <div className={classes.paper}>
                <div className={classes.modalTitle}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{
                        fontSize: 25,
                        marginRight: '10px',
                        color: '#F2BC47'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <h2 id="authTerms-modal-title">Atenção!</h2>
                  </Grid>
                </div>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  lg={12}
                  justifyContent="center"
                >
                  <p className={classes.modalTermDescription}>
                    O compartilhamento de conteúdos da plataforma {`${client}`}{' '}
                    com terceiros é proibido por Lei (art. 184 do código penal).
                  </p>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  lg={12}
                  justifyContent="center"
                >
                  <p className={classes.modalTermAuthDescription}>
                    Eu estou ciente do uso da minha imagem para fins
                    educacionais e de comunicação dentro da plataforma
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Button className={classes.button} onClick={handleClose}>
                    Ok
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
}
