import settings from '~settings';

import jwt from 'jsonwebtoken';
import { io } from 'socket.io-client';

const token = jwt.sign(settings.websocketSecret, settings.websocketKey);

export const socket = io(settings.websocketUrl, {
  query: { token },
  autoConnect: false
});
