import React, { useEffect, useState } from 'react';

import { getAllSubjects } from '~/services/Space';

import { useStyles } from './styles';
import { SubjectItem } from './SubjectItem';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const NextLectures = ({ lecture }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [loadingSubjects, setLoadingSubjects] = useState(true);
  const [subjects, setSubjects] = useState([]);

  const fetchSubjects = async () => {
    try {
      const response = await getAllSubjects(lecture.space_id);
      setSubjects(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSubjects(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <>
      {loadingSubjects ? (
        <Box className={classes.root}>
          <CircularProgress
            size={40}
            style={{ color: theme.palette.activeText.main }}
          />
        </Box>
      ) : (
        subjects.map((subject) => (
          <SubjectItem
            key={subject.id}
            subject={subject}
            currentLectureID={lecture.id}
            currentSubjectID={lecture.subject_id}
          />
        ))
      )}
    </>
  );
};

NextLectures.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.number.isRequired,
    space_id: PropTypes.number.isRequired,
    subject_id: PropTypes.number.isRequired
  }).isRequired
};
