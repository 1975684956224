import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { useStyles } from './styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormInterests = ({ fieldsInterests }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const edit = true;

  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'interested_areas',
    keyName: 'interested_areaId'
  });

  useEffect(() => {
    if (fieldsInterests) {
      const a = fieldsInterests.filter(
        (id, index) => fieldsInterests.indexOf(id) === index
      );

      append([...a]);
    }
  }, []);

  return (
    <Box className={classes.mainArea}>
      <Box className={classes.interestsList}>
        {fields?.map((interest, index) => (
          <>
            <Box>
              <Controller
                defaultValue={interest?.name}
                name={`interested_areas.${index}.name`}
                control={control}
                render={({ field }) => (
                  <Box
                    className={`${classes.tag} ${
                      edit ? classes.editTag : classes.viewTag
                    }`}
                    {...field}
                  >
                    <label className={classes.tagName}>{interest?.name}</label>
                    {edit && (
                      <button
                        type="button"
                        className={classes.tagDelete}
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          color={theme.palette.textAndBackground.main}
                          style={{ fontSize: 12 }}
                        />
                      </button>
                    )}
                  </Box>
                )}
              />
            </Box>
          </>
        ))}

        <input
          type="text"
          onKeyPress={(event) => {
            if (event.key == 'Enter' && event.target.value) {
              event.preventDefault();
              append({ name: event.target.value });
              event.target.value = '';
            }
          }}
          className={classes.input}
          maxLength={50}
        />
      </Box>
    </Box>
  );
};

export default FormInterests;

FormInterests.propTypes = {
  fieldsInterests: PropTypes.array
};

FormInterests.defaultProps = {
  fieldsInterests: []
};
