import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '14px'
    },
    success: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: 'white'
    },
    icon: {
        color: 'white'
    }
}));