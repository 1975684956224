import React from 'react';
import { useFormContext } from 'react-hook-form';

import EnrollmentLinkToggle from '~/components/molecules/EnrollmentLinkToggle';
import i18n from '~/I18n';
import {
  createEnrollmentLink,
  getEnrollmentLink
} from '~/services/environment';

import EmailReminder from './EmailReminder';
import PagseguroIntegration from './PagseguroIntegration';
import { useStyles } from './styles';
import YoutubeIntegration from './YoutubeIntegration';
import ZoomIntegration from './ZoomIntegration';

export default function Options() {
  const classes = useStyles();
  const { watch } = useFormContext();

  const tooltipEnrollmentLinkMessage = `Se este campo for preenchido, apenas membros de posse
  dessa chave conseguirão se inscrever no
  ${i18n.t(`StructuresName.Environment.SingularUpper`)}
  através do link.`;

  return (
    <>
      {watch('id') && (
        <div className={classes.field}>
          <EnrollmentLinkToggle
            structureID={watch('id')}
            tooltipMessage={tooltipEnrollmentLinkMessage}
            getEnrollmentLink={getEnrollmentLink}
            createEnrollmentLink={createEnrollmentLink}
          />
        </div>
      )}
      {/* <div className={classes.field}>
        <EmailReminder />
      </div>
      <div className={classes.field}>
        <ZoomIntegration />
      </div>
      <div className={classes.field}>
        <YoutubeIntegration />
      </div> */}
      {watch('id') && (
        <div className={classes.field}>
          <PagseguroIntegration />
        </div>
      )}
    </>
  );
}
