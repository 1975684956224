import React from 'react';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Title = ({ Icon, text, color }) => {
  const classes = useStyles();
  return (
    <Box className={classes.titleBox}>
      <Icon
        className={classes.icon}
        style={{
          color: color
        }}
      />
      <Typography
        className={classes.text}
        style={{
          color: color
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

Title.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};
