import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import { useStyles } from 'src/components/organisms/LectureForm/Lectureable/Forms/Multimedia/IframeList/IframeItem/styles.js';

import { Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';

export const IframeItem = ({ iframe, setOpen, setCurrentIframe, handleDeleteIframe }) => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const { watch } = useFormContext();
  
  useEffect(() => {
    const elem = document.createElement('body');
    elem.innerHTML = iframe.body;
    const iframeElement = elem.querySelector('iframe');

    setUrl(iframeElement.getAttribute('src'));
  }, [watch('lectureable.multimedia.iframes')]);

  const linkList = [
    {
      action: (e) => {
        e.preventDefault();
        handleDeleteIframe(iframe);
      },
      Icon: DeleteIcon,
      text: 'Excluir',
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setCurrentIframe(iframe);
        setOpen(true);
      },
      Icon: CreateIcon,
      text: 'Editar',
      disabled: false
    }
  ];

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Grid container item spacing={2} alignItems="center">
          <Grid item>
            <Text color="black" className={classes.textField}>
              {`IFRAME${iframe.position}.`}
            </Text>
          </Grid>

          <Grid item>
            <Text color="black" className={classes.textField}>
              {url}
            </Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <PopUpMenu Icon={MoreHorizIcon} linkList={linkList} />
      </Grid>
    </Grid>
  );
};

IframeItem.propTypes = {
  iframe: PropTypes.object,
  setOpen: PropTypes.func,
  setCurrentIframe: PropTypes.func
};

IframeItem.defaultProps = {
  iframes: { body: '', position: -1 },
  setOpen: null,
  setCurrentIframe: null
};
