import React, { useState } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Dialog, CircularProgress, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function DeleteConfirmModal({
  show,
  handleConfirm,
  handleClose,
  maxWidth,
  loading,
  showButtons,
  descriptionMessage,
  title
}) {
  const classes = useStyles({ maxWidth });
  const [inputValue, setInputValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <Box className={classes.root}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <WarningIcon className={classes.warningIcon} color="error" />
            <Text
              className={classes.headerText}
              variant="lg"
              color="gray"
              marginLeft={10}
            >
              {title}
            </Text>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>

        <Text
          className={classes.descriptionText}
          variant="md"
          color="textSecondary"
          marginTop={15}
        >
          Esta ação não poderá ser desfeita. {descriptionMessage}
        </Text>

        <Text
          className={classes.descriptionText}
          variant="md"
          color="textSecondary"
          marginTop={10}
        >
          Digite a palavra{' '}
          <span className={classes.boldText}>
            {i18n.t('Delete').toUpperCase()}
          </span>{' '}
          para confirmar
        </Text>
        <TextField
          variant="outlined"
          placeholder={i18n.t('Delete').toUpperCase()}
          margin="normal"
          fullWidth="100%"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={classes.textField}
        />
        {loading ? (
          <Box className={classes.buttonArea}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          showButtons && (
            <Box className={classes.buttonArea} marginTop={10}>
              <ButtonSquare
                width={100}
                height={50}
                onClick={handleClose}
                type="button"
              >
                <Text variant="largeText" color="gray">
                  {i18n.t('Cancel')}
                </Text>
              </ButtonSquare>
              <ButtonSquare
                backgroundColor="#CB410B"
                width={100}
                height={50}
                onClick={() => {
                  if (inputValue === i18n.t('Delete').toUpperCase()) {
                    handleConfirm();
                  } else {
                    enqueueSnackbar(
                      `Digite a palavra ${i18n.t('Delete').toUpperCase()}`,
                      {
                        variant: 'error'
                      }
                    );
                  }
                }}
                marginLeft={25}
                type="button"
              >
                <Text variant="largeText" color="white">
                  {i18n.t('Delete')}
                </Text>
              </ButtonSquare>
            </Box>
          )
        )}
      </Box>
    </Dialog>
  );
}

DeleteConfirmModal.propTypes = {
  show: PropTypes.bool,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  showButtons: PropTypes.bool,
  descriptionMessage: PropTypes.string,
  title: PropTypes.string
};

DeleteConfirmModal.defaultProps = {
  show: false,
  handleConfirm: null,
  handleClose: null,
  maxWidth: '100%',
  loading: false,
  showButtons: true,
  descriptionMessage: '',
  title: ''
};
