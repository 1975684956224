import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

import { Box, Slider, IconButton } from '@material-ui/core';
import {
  IoIosRemoveCircleOutline,
  IoIosAddCircleOutline
} from 'react-icons/io';
import { MdOutlineRotateRight, MdOutlineRotateLeft } from 'react-icons/md';
import PropTypes from 'prop-types';

import { cropperStyles } from './styles';

const ImageCropper = ({
  imageSrc,
  setCroppedAreaPixels,
  setRotation,
  cropShape,
  aspect = 1
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotationDegree] = useState(0);
  const classes = cropperStyles();

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setRotation(rotation);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const decreaseZoom = () => {
    setZoom((prevZoom) => Math.max(1, prevZoom - 0.1));
  };

  const increaseZoom = () => {
    setZoom((prevZoom) => Math.min(3, prevZoom + 0.1));
  };

  const rotateLeft = () => {
    setRotationDegree((prevRotation) => prevRotation - 90);
  };

  const rotateRight = () => {
    setRotationDegree((prevRotation) => prevRotation + 90);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.screen}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropShape={cropShape}
          showGrid={true}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          rotation={rotation}
        />
      </Box>
      <Box className={classes.controls}>
        <Box className={classes.iconContainer}>
          <IconButton onClick={rotateLeft} style={{ fontSize: '24px' }}>
            <MdOutlineRotateLeft />
          </IconButton>
          <IconButton onClick={rotateRight} style={{ fontSize: '24px' }}>
            <MdOutlineRotateRight />
          </IconButton>
        </Box>

        <Box className={classes.iconContainer}>
          <IconButton onClick={decreaseZoom} style={{ fontSize: '24px' }}>
            <IoIosRemoveCircleOutline />
          </IconButton>

          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, newZoom) => onZoomChange(newZoom)}
            className={classes.slider}
          />

          <IconButton onClick={increaseZoom} style={{ fontSize: '24px' }}>
            <IoIosAddCircleOutline />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ImageCropper.propTypes = {
  imageSrc: PropTypes.string,
  setCroppedAreaPixels: PropTypes.func
};

ImageCropper.defaultProps = {
  imageSrc: '',
  setCroppedAreaPixels: null
};

export default ImageCropper;
