import React from 'react';

import Text from '~/components/atoms/Text';
import MuralProfile from '~/components/organisms/MuralProfile';
import i18n from '~/I18n';

import { Content } from '../styles';
import { Container } from './styles';
import PropTypes from 'prop-types';

export default function Mural({ height, userId }) {
  return (
    <Container height={height}>
      <Content>
        <Text variant="header" color="primary">
          {i18n.t('Mural')}
        </Text>
        <MuralProfile userId={userId} />
      </Content>
    </Container>
  );
}

Mural.propTypes = {
  height: PropTypes.number,
  userId: PropTypes.string
};
Mural.defaultProps = {
  height: null,
  userId: null
};
