import React    from 'react'
import settings from '~settings';

import LogoLabel from '../LogoLabel';

export default function EnvironmentLogo({ logoPath, label }) {
  return <>
            <img src={`${settings.baseUrl}${logoPath}`} alt={label} />
            <LogoLabel label={label}/>
          </>
}