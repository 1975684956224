export const lectureItemWidth = {
    lg: 235,
    md: 195,
    sm: 305
};
export const lectureItemHeight = {
    lg: 135,
    md: 105,
    sm: 171
};

export const muralItemAvatarSize = {
    desktop: 35,
    tabletMobile: 38
};

export const lectureItemMinWidth = '181px';
export const lectureItemMinHeight = '102px';
export const sideBarAvatarSize = 41;
