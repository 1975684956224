import React, { useEffect, useState } from 'react';

import Avatar from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import api from '~/services/api';

import { useStyles } from './styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@material-ui/core';
import styled from 'styled-components';

const Classification = ({ courseId, environmentId, isEnvironment }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [classifications, setClassifications] = useState([]);

  async function handleGetClassification() {
    try {
      const response = await api.get(
        `/api/environments/${environmentId}/courses/${courseId}/leaderboards?page=1`
      );

      setClassifications(response.data);
    } catch (error) {}
  }

  async function handleGetClassificationEnvironment() {
    try {
      const response = await api.get(
        `/api/environments/${environmentId}/leaderboards?page=1`
      );

      setClassifications(response.data);
    } catch (error) {}
  }

  useEffect(() => {
    if (courseId && environmentId) {
      handleGetClassification();
    }

    if (isEnvironment && environmentId) {
      handleGetClassificationEnvironment();
    }
  }, [courseId, environmentId]);

  const Styles = styled.div`
    width: 100%;

    .MuiTableCell-body {
      font-size: 19;
    }

    .contentAluno {
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
      }
    }
  `;

  return (
    <>
      <Text variant="mdTitle" color="primary">
        Classificação
      </Text>
      <Box className={classes.root}>
        <Styles>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16 }}>Posição</TableCell>
                  <TableCell style={{ fontSize: 16 }}>Aluno</TableCell>
                  <TableCell style={{ fontSize: 16 }}>Pontuação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classifications.map((user, index) => (
                  <TableRow
                    key={user.position}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{
                      backgroundColor:
                        index === 0
                          ? 'rgba(178,222,205,0.4)'
                          : index === 1
                          ? 'rgba(153,153,153, 0.2)'
                          : index === 2
                          ? 'rgba(241,198,164,0.4)'
                          : 'white'
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontSize: 14 }}
                    >
                      {user.position}º
                    </TableCell>
                    <TableCell style={{ fontSize: 14 }}>
                      <div className="contentAluno">
                        <Avatar profile={user.user} size={26} />
                        <p>{user.user.name}</p>
                      </div>
                    </TableCell>

                    <TableCell style={{ fontSize: 14 }}>
                      {user.sum_points}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Styles>
      </Box>
    </>
  );
};

export default Classification;
