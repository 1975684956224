import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '74px',
    paddingTop: '78px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'rgba(0,0,0,0.2)',
      width: '8px !important'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px'
    }
  }
}));
