import React from 'react';
import { Link } from 'react-scroll';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },

    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },

    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      bordeRadius: '2px'
    }
  },
  menuItem: {
    width: 'auto',
    paddingLeft: '5px',
    marginBottom: '10px'
  },
  menu: {
    backgroundColor: 'white',
    paddingLeft: '50px',
    paddingTop: '30px'
  },
  content: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    paddingTop: 90
  },
  rightContent: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100vw'
  },
  contentContainer: {
    marginLeft: '5%'
  },
  active: {
    color: theme.palette.primary.main
  },
  link: {
    fontSize: 14,
    cursor: 'pointer'
  },
  sidenavContainer: {
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  MessageFollowButtons: {
    display: 'flex',
    width: '250px',
    '@media (min-width: 1024px)': {
      display: 'none'
    }
  },
  avatarContainer: {
    width: '100%',
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px 0 0 0',
    '@media (max-width: 1024px)': {
      display: 'flex'
    }
  },
  buttonSendMessage: {
    width: '112px',
    height: '31px',
    background: '#489AF8',
    borderRadius: '12px',
    margin: '8px auto 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  textSendMessage: {
    color: '#fff',
    fontSize: 14,
    marginLeft: 4
  }
}));

export const Logo = styled.img`
  width: 67px;
  height: 37px;
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
`;

export const LinkItem = styled(({ ...otherProps }) => {
  const classes = useStyles();

  return (
    <Link
      {...otherProps}
      activeClass={classes.active}
      className={classes.link}
      spy
      smooth
      duration={500}
    />
  );
})``;
