import React from 'react';

import { List, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Menu = styled(({ backgroundColor, color, ...otherProps }) => (
  <List {...otherProps} style={{ backgroundColor }} />
))``;

export const useStyles = makeStyles(() => ({
  menuMainContainer: {
    // gabi
    // padding: '15px',
    // marginTop: '22px',
    '@media (max-width: 1024px)': {
      marginTop: '50px'
    }
  },
  icon: {
    fontSize: 24,
    color: 'white'
  }
}));
