import React, { useEffect, useState } from 'react';
import Question from './Question';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import FormButton from '../FormButton';
import i18n from '~/I18n';

export default function ListOfAnswers({ forms, openRoot }) {

    const theme = useTheme();
    const classes = useStyles({ theme });

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
      if (forms) {
        setQuestions(forms.any_choice_questions);
      }
    }, [forms])

    return (
        <form className={classes.root} style={{ paddingBottom: '50px' }}>
            <Box className={classes.questionsContainer}>
                {questions.map((question, index) => (
                    <Question
                        key={index}
                        question={question}
                        questionIndex={index}
                    />
                ))}
            </Box>
            <FormButton handleClick={openRoot} text={i18n.t('GoBack')} style={{ marginBottom: '50px' }} />
        </form>
    )
}
