import React, { useState } from 'react';
import { BsPersonCheckFill } from 'react-icons/bs';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { useSelector } from 'react-redux';

import api from '~/services/api';

import { UserCard } from '../UserCard';
import { FeedbackLoading, useStyles } from './styles';
import { Box, ButtonBase } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export default function FriendshipSuggestion({ item, marginTop }) {
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const { profile } = useSelector((state) => state.user);
  const theme = useTheme();

  function requestConnection() {
    api
      .post(`/api/users/${profile.id}/connections`, {
        connection: {
          contact_id: item.user.id
        }
      })
      .catch(() => {
        setPending(false);
      });
  }

  function deleteConnection() {
    api
      .delete(`/api/connections/${item.user.id}`, {
        connection: item.user.id
      })
      .then(() => {})
      .catch(() => {
        setPending(false);
      });
  }

  function onHandleConect() {
    if (pending) {
      setPending(false);
      deleteConnection();
    } else {
      setPending(true);
      requestConnection();
    }
  }

  return (
    <Box
      className={classes.root}
      style={{
        marginTop: marginTop ? '8px' : '0'
      }}
    >
      {item ? (
        <>
          <UserCard user={item.user} />
          <ButtonBase disableRipple onClick={onHandleConect}>
            <Box className={classes.iconBox}>
              {pending ? (
                <BsPersonCheckFill
                  style={{
                    color: theme.palette.activeText.main,
                    fontSize: '18px'
                  }}
                />
              ) : (
                <MdPersonAddAlt1
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: '18px'
                  }}
                />
              )}
            </Box>
          </ButtonBase>
        </>
      ) : (
        <FeedbackLoading />
      )}
    </Box>
  );
}

FriendshipSuggestion.propTypes = {
  item: PropTypes.shape(),
  marginTop: PropTypes.bool
};

FriendshipSuggestion.defaultProps = {
  item: null,
  marginTop: false
};
