import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

export default function SideNav({ children, color }) {
    const { root } = useStyles({ color });
    return <Box className={root}>{children}</Box>;
}

SideNav.propTypes = {
    color: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape()),
};

SideNav.defaultProps = {
    color: 'white',
    children: null,
};
