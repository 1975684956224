import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '94%',
    padding: 4,
    borderRadius: 8,
    marginBottom: 14,
    marginLeft: 10,
    backgroundColor: '#f7f7f7'
  },
  infosWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  avatar: {
    width: 48,
    height: 48,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  listItemText: {
    flex: 1,
    marginLeft: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  itemText: {
    color: '#707070',
    fontSize: 14
  },
  iconButton: {
    width: 30,
    height: 30,
    padding: 0
  },
  closeIcon: {
    fontSize: 20,
    color: theme.palette.activeText.main
  }
}));
