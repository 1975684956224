import React from 'react';

import Text from '~/components/atoms/Text';
import { useStyles } from '~/components/organisms/LectureForm/Lectureable/Forms/Multimedia/IframeList/style';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { IframeItem } from 'src/components/organisms/LectureForm/Lectureable/Forms/Multimedia/IframeList/IframeItem';

export const IframeList = ({
  iframes,
  setOpenModal,
  setCurrentIframe,
  handleDeleteIframe
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Text className={classes.title}>IFrames</Text>

      <Grid container item className={classes.root}>
        {iframes.map((iframe) => {
          return (
            <IframeItem
              iframe={iframe}
              setOpen={setOpenModal}
              setCurrentIframe={setCurrentIframe}
              handleDeleteIframe={handleDeleteIframe}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

IframeList.propTypes = {
  iframes: PropTypes.array,
  setOpenModal: PropTypes.func,
  setCurrentIframe: PropTypes.func
};

IframeList.defaultProps = {
  iframes: [],
  setOpenModal: null,
  setCurrentIframe: null
};
