import { all, put, call, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { Types, Creators as CourseActions } from './index';

export function* getCoursesByEnvironmentId({ page, environmentId }) {
  const {
    getCoursesByEnvironmentIdSuccess,
    getCoursesByEnvironmentIdFailure
  } = CourseActions;
  try {
    const response = yield call(
      api.get,
      `api/environments/${environmentId}/courses`
    );
    yield put(getCoursesByEnvironmentIdSuccess(response.data));
  } catch (error) {
    yield put(getCoursesByEnvironmentIdFailure());
  }
}
export function* transferUsers({
  courseSourceId,
  courseDestinationId,
  usersToTransfer
}) {
  const { postTransferUsersSuccess, postTransferUsersFailure } = CourseActions;
  try {
    const { data } = yield call(
      api.post,
      `api/courses/${courseSourceId}/transfer_course/${courseDestinationId}`,
      { users_to_transfer: usersToTransfer }
    );
    yield put(postTransferUsersSuccess(data.result));
  } catch (error) {
    yield put(postTransferUsersFailure());
  }
}

export default all([
  takeLatest(Types.GET_COURSES_BY_ENVIRONMENT_ID, getCoursesByEnvironmentId),
  takeLatest(Types.POST_TRANSFER_USERS, transferUsers)
]);
