import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  containerLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      paddingTop:'5px'
    }
  },
});