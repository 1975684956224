import React from 'react';

import i18n from '~/I18n';
import { setStructurePath } from '~/utils/linkPathConstructors';

import { StatusIndicator } from './StatusIndicator';
import { useStyles } from './styles';
import {
  Box,
  Button,
  Link,
  TableCell,
  TableRow,
  useTheme
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';

const structureNameMap = {
  Environment: i18n.t(`StructuresName.Environment.SingularUpper`),
  Course: i18n.t(`StructuresName.Course.SingularUpper`),
  Space: i18n.t(`StructuresName.Space.SingularUpper`),
  Subject: i18n.t(`StructuresName.Subjects.SingularUpper`),
  Lecture: i18n.t(`StructuresName.Lectures.SingularUpper`)
};

const formatDataOptions = {
  month: 'short',
  day: 'numeric'
};

export default function TableRowWrapper({
  rowData,
  handleDeletion,
  tryAgain,
  isFetchDeleteProcess
}) {
  const classes = useStyles();
  const theme = useTheme();

  const replicaPathExists = Boolean(rowData.replica_hierarchy_associations);
  const replicablePathExists = Boolean(
    rowData.replicable_hierarchy_associations
  );

  return (
    <TableRow key={rowData.replica_id} className={classes.styleTableRow}>
      <TableCell
        align="center"
        className={classes.bodySmallCell}
        style={{ opacity: replicaPathExists ? 1 : 0.5 }}
      >
        {rowData.replica_id ? rowData.replica_id : '-'}
      </TableCell>

      <TableCell
        align="left"
        className={classes.bodyMediumCell}
        style={{ opacity: replicaPathExists ? 1 : 0.5 }}
      >
        <Box display="flex" alignItems="center" style={{ gap: '4px' }}>
          <Box
            style={{
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
            title={rowData.replica_name}
          >
            {rowData.replica_name}
          </Box>
          {replicaPathExists && (
            <Link
              href={setStructurePath({
                id: rowData.replica_id,
                type: rowData.type,
                ...rowData.replica_hierarchy_associations
              })}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <LaunchIcon
                style={{
                  fontSize: '20px',
                  color: theme.palette.primary.main
                }}
              />
            </Link>
          )}
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={classes.bodySmallCell}
        style={{ opacity: replicablePathExists ? 1 : 0.5 }}
      >
        {rowData.replicable_id}
      </TableCell>
      <TableCell
        align="left"
        className={classes.bodyMediumCell}
        style={{ opacity: replicablePathExists ? 1 : 0.5 }}
      >
        <Box display="flex" alignItems="center" style={{ gap: '4px' }}>
          <Box
            style={{
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
            title={rowData.replicable_name}
          >
            {rowData.replicable_name}
          </Box>
          {replicablePathExists && (
            <Link
              href={setStructurePath({
                id: rowData.replicable_id,
                type: rowData.type,
                ...rowData.replicable_hierarchy_associations
              })}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <LaunchIcon
                style={{
                  fontSize: '20px',
                  color: theme.palette.primary.main
                }}
              />
            </Link>
          )}
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={classes.bodySmallCell}
        style={{ opacity: replicaPathExists ? 1 : 0.5 }}
      >
        {structureNameMap[rowData.type]}
      </TableCell>
      <TableCell
        align="center"
        className={classes.bodySmallCell}
        style={{ opacity: replicaPathExists ? 1 : 0.5 }}
      >
        {new Date(rowData.created_at).toLocaleDateString(
          'pt-BR',
          formatDataOptions
        )}
      </TableCell>
      <TableCell
        align="center"
        className={classes.bodySmallCell}
        style={{
          opacity: replicaPathExists || rowData.status !== 'done' ? 1 : 0.5
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <StatusIndicator
            status={rowData.status}
            deleted={!replicaPathExists && rowData.status === 'done'}
            detached={rowData.unlinked}
          />
        </Box>
      </TableCell>
      <TableCell align="center" style={{ padding: 0 }}>
        {rowData.status !== 'processing' && (
          <Box className={classes.actionButtonsBox}>
            {replicablePathExists && rowData.status === 'error' && (
              <Button
                onClick={() => tryAgain(rowData)}
                className={classes.actionButton}
                disabled={isFetchDeleteProcess}
              >
                <ReplayIcon style={{ fontSize: '20px' }} />
              </Button>
            )}
            <Button
              className={classes.actionButton}
              onClick={() => handleDeletion(rowData)}
            >
              <DeleteIcon style={{ fontSize: '20px' }} />
            </Button>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
TableRowWrapper.propTypes = {
  rowData: PropTypes.shape({
    replica_id: PropTypes.number,
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
    replica_hierarchy_associations: PropTypes.object,
    replica_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    replicable_id: PropTypes.number.isRequired,
    replicable_hierarchy_associations: PropTypes.object,
    replicable_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    unlinked: PropTypes.bool
  }).isRequired,
  handleDeletion: PropTypes.func,
  tryAgain: PropTypes.func,
  isFetchDeleteProcess: PropTypes.bool
};
