import React, { useState, useEffect } from 'react';
import Text                from '~/components/atoms/Text';
import EditorField         from '../EditorField';

import { IconWrapper }                       from '~/utils/forms';
import { ExpandLess, ExpandMore }            from '@material-ui/icons';
import { Collapse, Divider, Grid, useTheme } from '@material-ui/core';

import { useStyles } from './styles'

export default function Statements({ 
  statement, 
  setStatement, 
  startStatement,
  errorStatement  
}){

  const theme   = useTheme()
  const classes = useStyles()

  const [showStatement, setShowStatement]     = useState(false)

  const renderIcon = bool => {
    return bool ? 
    <IconWrapper customColor={theme.palette.primary.main} fontSize={30} Icon={ExpandLess}/> 
    : 
    <IconWrapper customColor={theme.palette.primary.main} fontSize={30} Icon={ExpandMore}/>
  }

  const renderField = (label, setToggleState, tip, bool, state, setState, startText ) => {
    return(
      <div className={classes.field}>
        
        <Grid className={classes.toggle} container alignItems='center' justifyContent='space-between' onClick={() => setToggleState( prev => !prev )}>
          <Text color={'primary'} variant={"text"}> { label } </Text>
          { renderIcon(bool) }
        </Grid>
        <Divider className={classes.divider}/>
        
        <Collapse in={ bool }>
          <EditorField onChange={setState} value={state} startText={startText} />
          <Text className={classes.tip} variant={"smallText"}> { tip } </Text>
        </Collapse>
      
      </div>
    )
  }

  useEffect(() => {
    if (errorStatement) {
      setShowStatement(true);
    }
  }, [errorStatement]);

  return (
    <>
      <div className={classes.field}>
        { renderField(  "Enunciado", 
                        setShowStatement, 
                        "Descrição da questão e sua problemática.",
                        showStatement,
                        statement,
                        setStatement,
                        startStatement
                        ) 
                        }
      </div>
      
      {errorStatement && (
        <Text color={'error'}>
          Você precisa descrever o enunciado da questão.
        </Text>
      )}

    </>
  )
}