import React from 'react';

import { useStyles } from './styles';
import { Box, FormHelperText, InputBase, InputLabel } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

export const GenericFieldText = ({
  label,
  labelProps,
  error,
  inputProps,
  onChange
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <InputLabel {...labelProps} className={classes.label}>
        {label}
      </InputLabel>
      <InputBase
        {...inputProps}
        className={classes.outlinedInput}
        inputProps={{
          className: classes.scrollbarInput,
          onChange: (event) => onChange(event.target.value)
        }}
      />
      {error?.message && (
        <FormHelperText className={classes.helperText}>
          <ErrorIcon className={classes.errorIcon} />
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

GenericFieldText.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  inputProps: PropTypes.object,
  onChange: PropTypes.func
};

GenericFieldText.defaultProps = {
  onChange: () => null
};
