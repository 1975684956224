import React from 'react';
import { Page } from 'react-pdf';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const SinglePageVisualization = React.memo(
  ({
    documentTotalPages,
    singlePageRefsArray,
    scale,
    rotateValue,
    setPageDimensions,
    customTextRenderer
  }) => {
    const onPageLoadSuccess = ({ width, height }) => {
      setPageDimensions({ width, height });
    };

    return Array.from(new Array(documentTotalPages), (el, index) => {
      return (
        <Box
          key={`default_${index + 1}`}
          ref={(el) => (singlePageRefsArray.current[index] = el)}
          style={{
            marginBottom: '8px',
            scrollMarginTop: '8px'
          }}
        >
          <>
            {index === 0 ? (
              <Page
                pageNumber={index + 1}
                scale={scale}
                rotate={rotateValue}
                onLoadSuccess={onPageLoadSuccess}
                customTextRenderer={(obj) => customTextRenderer({ obj })}
              />
            ) : (
              <Page
                pageNumber={index + 1}
                scale={scale}
                rotate={rotateValue}
                customTextRenderer={(obj) => customTextRenderer({ obj })}
              />
            )}
          </>
        </Box>
      );
    });
  }
);

SinglePageVisualization.displayName = 'SinglePageVisualization';
export default SinglePageVisualization;

SinglePageVisualization.propTypes = {
  documentTotalPages: PropTypes.number.isRequired,
  singlePageRefsArray: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  scale: PropTypes.number,
  rotateValue: PropTypes.number,
  setPageDimensions: PropTypes.func.isRequired,
  customTextRenderer: PropTypes.func
};
