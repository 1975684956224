import { Typography, withStyles } from '@material-ui/core';

export const LabelText = withStyles((theme) => ({
  root: {
    fontSize: 14,
    color: theme.palette.activeText.main,
    fontWeight: '500',
    whiteSpace: 'nowrap'
  }
}))(Typography);
