import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getPerformanceReport } from '~/services/reports/environments';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function PerformanceReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getPerformanceReport}
        baseName="relatório_de_desempenho_do_aluno_nos_exercícios_"
        id={id}
      />
    </div>
  );
}

PerformanceReport.propTypes = {
  id: PropTypes.string
};

PerformanceReport.defaultProps = {
  id: ''
};
