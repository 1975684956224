import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    backgroundColor: (props) =>
      props.isMine ? props.theme.palette.primary.main : '#EAEAEA',
    padding: '10px 15px',
    borderRadius: 10,
    maxWidth: '100%',
    overflow: 'auto',
    borderBottomLeftRadius: (props) => (!props.isMine ? 0 : 10),
    borderBottomRightRadius: (props) => (props.isMine ? 0 : 10)
  }
});
