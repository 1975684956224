import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const ReportContainer = styled(Box)({
  background: '#ffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding:' 30px',
  borderRadius: '3px',
  boxShadow: '1px 1px 3px 2px rgba(0,0,0, 0.2)'
});
