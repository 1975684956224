import React from 'react';

import { useStyles } from './style';
import { useTheme } from '@material-ui/core/styles';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';

export default function PlayButton({ onClick, playing }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <button onClick={onClick}>
      {playing ? (
        <PauseCircleFilledRoundedIcon className={classes.icon} />
      ) : (
        <PlayCircleFilledRoundedIcon className={classes.icon} />
      )}
    </button>
  );
}
