import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        marginTop: 15
    },
    interestsList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    noInterestsLg: {
        '@media (max-width: 450px)': {
            display: 'none'
        }
    },
    noInterestsSm: {
        display: 'none',
        '@media (max-width: 450px)': {
            display: 'block'
        }
    }
}));