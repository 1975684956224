import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '20px 25px',
    borderBottom: (props) =>
      `1px solid ${props.theme.palette.textActiveElement.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactContainer: {
    width: 'calc(100% - 30px)',
  },
  infoButton: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    '& svg': {
      color: (props) => props.theme.palette.accent.main,
      width: 30,
      height: 30,
    },
  },
  backButton: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    marginRight: 20,
    '& svg': {
      color: (props) => props.theme.palette.primary.main,
      width: 25,
      height: 25,
    },
  },
});
