import { makeStyles } from '@material-ui/core';

const drawerWidth = 340;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100vw',
    marginTop: '62px',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: 'calc(100vh - 76px)',
    '@media (max-width: 1024px)': {
      width: '65%'
    },
    '@media (max-width: 900px)': {
      width: '55%'
    },
    '@media (max-width: 600px)': {
      width: '100vw',
      paddingLeft: 30
    }
  },
  header: {
    flex: '0 0 auto',
    height: '100px',
    maxHeight: '100px'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '72vh',
    height: '72vh',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  footer: {
    flex: '0 0 auto'
  },
  loadingMessage: {
    fontSize: 16,
    color: '#707070'
  },
  addIcon: {
    fontSize: 25,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  paginationButtonWrepper: {
    display: 'flex',
    position: 'relative',
    padding: 5,
    top: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  pressContentBody: {
    minHeight: '12px',
    flex: '1 1 auto'
  },
  contentBody: {
    flex: '0 0 auto'
  },
  drawerPaper: {
    width: drawerWidth
  }
}));
