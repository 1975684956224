import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    minHeight: '50px !important',
    maxHeight: '50px !important'
  },
  icon: {
    fontSize: '36px'
  }
}));
