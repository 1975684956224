import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dragDropInput__input: {
    background: (props) => props.done ? props.theme.palette.success.main : '#C7DAEF',
    '&:hover': {
      background: (props) => props.done ? props.theme.palette.success.main : '#BFD1E5'
    },
    '&:active': {
      background: (props) => props.done ? props.theme.palette.success.main : '#AABACC',
      
    },
  },
});
