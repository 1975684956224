import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '3px 3px 6px #00000029',
    border: '1px solid #1A72F9',
    borderRadius: 5,
    padding: '10px 40px',
    marginTop: 12,
  },
  conquest: {
    width: 55,
    height: 55,
    borderRadius:'100%',
    background: 'white',
    boxShadow:'0px 3px 6px #00000029',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img':{
      width:30
    }
  },
  badges__wraper:{
    width:'100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '15px 35px',
    '@media (min-width: 320px) and (max-width: 767px)': {
      gap: '15px 15px',
      justifyContent: 'center',
      alignItems: 'flex-start',
    }
  },
  conquest__wraper:{
    width:70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
