import base from './base';
const baseUrl = 'https://beta.digital.prefacioeditora.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjyqgoz6r';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: '9TshuNwpHGYDLnhCsyDhVnT2S7UYTXSh0Ek7Yn7g',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://beta.digital.prefacioeditora.com.br:44443',
  clarityKey
};
