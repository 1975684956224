import React from 'react';

import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';

const dividerStyle = makeStyles({
  root: {
    height: '0.5px',
    backgroundColor: 'white'
  }
});

export default function LoginDivider() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={4}>
        <Divider classes={{ root: dividerStyle().root }} />
      </Grid>

      <Grid container item xs={4} justifyContent="center">
        <Grid item>
          <Typography style={{ color: 'white', fontSize: 14 }}> ou </Typography>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Divider classes={{ root: dividerStyle().root }} />
      </Grid>
    </Grid>
  );
}
