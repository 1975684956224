import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  sidebar: {
    width: '20%',
    height: '100%',
    '@media (max-width: 1023px)': {
      display: 'none'
    }
  },
  content: {
    width: '80%',
    padding: '34px 70px 0 70px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    '@media (max-width: 1023px)': {
      width: '100%',
      height: 'auto',
      padding: '34px 25px 0 25px'
    }
  },
  tabbar: {
    display: 'none',
    '@media (max-width: 1023px)': {
      width: '100%',
      display: 'flex',
      marginTop: 35
    },
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  header: {
    paddingBottom: 50,
    '@media (max-width: 1023px)': {
      paddingBottom: 0
    }
  },
  formContainer: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileTitle: {
    '& p': { fontSize: 20 }
  }
});
