import React, { useEffect, useState } from 'react';

import PaginatableSelect from '~/components-refactor/atoms/PaginatableSelect';
import { useReplicateContext } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext.js';
import { useReplication } from '~/hooks/useReplication';
import i18n from '~/I18n';

import EditEnvironmentForm from './EditEnvironmentForm';
import { useSnackbar } from 'notistack';

export default function EnvironmentStep() {
  const { enqueueSnackbar } = useSnackbar();
  const [environmentsListData, setEnvironmentsListData] = useState([]);
  const {
    mirrorType,
    replicationSteps,
    setReplicationSteps,
    listService
  } = useReplicateContext();

  const { fetchStructureList, isFetching } = useReplication();
  const [mirrorPayload, setMirrorPayload] = useState({});

  const handleSuccessFetchList = (data) => {
    const environmentData = data.map((item) => ({
      ...item.environment,
      value: item.environment.id
    }));
    setEnvironmentsListData((prev) => [...prev, ...environmentData]);
  };

  const handleFailureFetchList = () => {
    enqueueSnackbar(
      `Ocorreu um erro ao carregar os dados dos ${i18n.t(
        `StructuresName.Environment.PluralLower`
      )}`,
      {
        variant: 'error'
      }
    );
  };

  useEffect(() => {
    fetchStructureList(
      null,
      mirrorType === 'environment'
        ? listService.getSelf
        : listService.getEnvironments,
      handleSuccessFetchList,
      handleFailureFetchList
    );
  }, []);

  const renderContent = () => {
    return (
      <>
        <PaginatableSelect
          label={
            mirrorType === 'environment'
              ? `Qual ${i18n.t(
                  `StructuresName.Environment.SingularLower`
                )} deseja espelhar?`
              : `Selecione de qual ${i18n.t(
                  `StructuresName.Environment.SingularLower`
                )} você deseja espelhar`
          }
          placeholder={`Selecionar um ${i18n.t(
            `StructuresName.Environment.SingularUpper`
          )}`}
          curValue={replicationSteps[1].key}
          data={environmentsListData}
          fetchMoreItems={() =>
            fetchStructureList(
              null,
              mirrorType === 'environment'
                ? listService.getSelf
                : listService.getEnvironments,
              handleSuccessFetchList,
              handleFailureFetchList
            )
          }
          isFetching={isFetching}
          handleChange={(e) => {
            const environment = environmentsListData.find(
              (el) => e.target.value === el.value
            );
            setReplicationSteps((prev) => {
              let temp = prev;
              prev[1].key = environment.value;
              return [...temp];
            });
            setMirrorPayload(environment);
          }}
        />
        {replicationSteps[1].key && mirrorType === 'environment' && (
          <EditEnvironmentForm mirrorPayload={mirrorPayload} />
        )}
      </>
    );
  };

  return renderContent();
}
