import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdManageAccounts } from 'react-icons/md';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Dialog, Grid, Switch, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const MuralManagementItem = ({ role, value, handleChange }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.item}>
      <Grid item>
        <Text
          variant="lg"
          color="primary"
          textAlign="left"
          style={{ fontWeight: 500 }}
        >
          {role}
        </Text>
        <Text variant="lg" color="gray" textAlign="left">
          Qualquer membro com esse perfil pode publicar
        </Text>
      </Grid>
      <Switch checked={value} onChange={handleChange} />
    </Grid>
  );
};

const MuralAccessManagementModal = ({
  show,
  handleConfirm,
  handleClose,
  maxWidth
}) => {
  const classes = useStyles({ maxWidth });
  const theme = useTheme();

  const { watch } = useFormContext();
  const [teacher, setTeacher] = useState(false);
  const [tutor, setTutor] = useState(false);
  const [member, setMember] = useState(false);

  useEffect(() => {
    ruleToState(watch('mural_access'));
  }, [watch('mural_access')]);

  function onChangeRole(setRole) {
    setRole((value) => !value);
  }

  function stateToRule() {
    return (
      Number(teacher).toString() +
      Number(tutor).toString() +
      Number(member).toString()
    );
  }

  function ruleToState(initialState) {
    const ruleArray = [...initialState];
    if (ruleArray[0] === '1') {
      setTeacher(true);
    }
    if (ruleArray[1] === '1') {
      setTutor(true);
    }
    if (ruleArray[2] === '1') {
      setMember(true);
    }
  }

  return (
    <Dialog open={show}>
      <Box className={classes.root}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
        >
          <MdManageAccounts fontSize={30} color={theme.palette.primary.main} />
          <Text variant="lgTitle" color="primary" textAlign="left">
            &nbsp;Gerenciar acesso à Discussão
          </Text>
        </div>
        <Text
          variant="lg"
          color="gray"
          textAlign="left"
          style={{ marginBottom: 16 }}
        >
          Selecione qual perfil que tem acesso para publicar na discussão da{' '}
          {`${i18n.t('StructuresName.Space.SingularUpper')}`}:
        </Text>
        <MuralManagementItem
          role="Professor"
          value={teacher}
          handleChange={() => onChangeRole(setTeacher)}
        />
        <MuralManagementItem
          role="Tutor"
          value={tutor}
          handleChange={() => onChangeRole(setTutor)}
        />
        <MuralManagementItem
          role="Aluno"
          value={member}
          handleChange={() => onChangeRole(setMember)}
        />
        <Box className={classes.buttonArea}>
          <ButtonSquare
            width={100}
            height={50}
            onClick={handleClose}
            type="button"
          >
            <Text variant="largeText" color="primary">
              {i18n.t('Cancel')}
            </Text>
          </ButtonSquare>
          <ButtonSquare
            backgroundColor={theme.palette.secondary.main}
            width={100}
            height={50}
            onClick={() => {
              handleConfirm(stateToRule());
            }}
            marginLeft={25}
            type="button"
          >
            <Text variant="largeText" color="white">
              {i18n.t('Confirm')}
            </Text>
          </ButtonSquare>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MuralAccessManagementModal;

MuralAccessManagementModal.propTypes = {
  show: PropTypes.bool,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

MuralAccessManagementModal.defaultProps = {
  show: false,
  handleConfirm: null,
  handleClose: null,
  maxWidth: '100%'
};

MuralManagementItem.propTypes = {
  role: PropTypes.string,
  value: PropTypes.bool,
  handleChange: PropTypes.func
};

MuralManagementItem.defaultProps = {
  role: '',
  value: false,
  handleChange: null
};
