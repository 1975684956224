import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: '1px solid #EBEBEB',
    padding: 24
  },
  submitButton: {
    padding: '8px 16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    whiteSpace: 'nowrap',
    width: '95px',
    height: '50px',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    },
    '&:disabled': {
      backgroundColor: theme.palette.activeText.main
    }
  }
}));
