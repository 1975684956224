import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Creating action types & creators
 */

export const { Types, Creators } = createActions({
  getMyStatusesRequest: ['userId', 'page'],
  getMyStatusesSuccess: ['statuses'],
  getMyStatusesFailure: [],
  postMyStatusesRequest: ['status', 'login'],
  postMyStatusesSuccess: ['status'],
  postMyStatusesFailure: []
});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE = {
  statusesMyWall: [],
  pageMyWall: 1,
  hasMoreStatuses: true,
  loading: true
};

// statuses
const getMyStatusesRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getMyStatusesSuccess = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.loading = false;
    if (!action?.statuses || !action?.statuses?.length)
      draft.hasMoreStatuses = false;
    else {
      const newStatuses = action.statuses.filter(
        (item) => item.type !== 'CompoundLog'
      );
      draft.statusesMyWall = [...draft.statusesMyWall, ...newStatuses];
      draft.pageMyWall += 1;
    }
  });
};

const getMyStatusesFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.hasMoreStatuses = false;
  });

const postMyStatusesRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const postMyStatusesSuccess = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    draft.loading = false;
    draft.statusesMyWall = [action.status, ...draft.statusesMyWall];
  });
};

const postMyStatusesFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_MY_STATUSES_REQUEST]: getMyStatusesRequest,
  [Types.GET_MY_STATUSES_SUCCESS]: getMyStatusesSuccess,
  [Types.GET_MY_STATUSES_FAILURE]: getMyStatusesFailure,
  [Types.POST_MY_STATUSES_REQUEST]: postMyStatusesRequest,
  [Types.POST_MY_STATUSES_SUCCESS]: postMyStatusesSuccess,
  [Types.POST_MY_STATUSES_FAILURE]: postMyStatusesFailure
});
