import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const ENVIRONMENT_URL = 'environments/';

export const hasManageAccessToEnvironment = async (environmentID) =>
  await hasManageAccessTo(`${ENVIRONMENT_URL}${environmentID}`);
export const hasReadAccessToEnvironment = async (environmentID) =>
  await hasReadAccessTo(`${ENVIRONMENT_URL}${environmentID}`);
export const hasUpdateAccessToEnvironment = async (environmentID) =>
  await hasCustomAccessTo(`${ENVIRONMENT_URL}${environmentID}`, 'update');
