import React from 'react';

import { Box, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';

export const ActionButton = styled(({ color, ...otherProps }) => (
  <Button
    size="small"
    {...otherProps}
    style={{
      height: 40,
      minWidth: 40,
      borderRadius: 20,
      padding: 0
    }}
  />
))``;

export const ContactName = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 16,
      color: color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContactSocialNetwork = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 12,
      color: color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContainerInfoContact = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    justifyContent="flex-start"
    flexDirection="column"
    alignItems="flex-start"
    style={{
      marginRight: 10,
      flex: 3,
      width: '100%',
      overflow: 'hidden'
    }}
  />
))``;

export const Container = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    style={{
      gap: '8px',
      margin: 5,
      height: 70
    }}
  />
))``;
