import axios from 'axios';

const UPLOAD_ENDPOINT = 'https://api.vimeo.com/me/videos';

export const uploadVideo = async (video, setProgress) => {
  const { data } = await axios.post(
    UPLOAD_ENDPOINT,
    { upload: { approach: 'tus', size: video.size } },
    {
      headers: {
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
        'Content-Type': 'application/json',
        Authorization: 'Bearer 1ee7e58b20298ce6533b42ec7b1b671c'
      }
    }
  );

  const {
    upload: { upload_link }
  } = data;
  await axios.patch(upload_link, video, {
    headers: {
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
      'Content-Type': 'application/offset+octet-stream',
      'Upload-Offset': '0',
      'Tus-Resumable': '1.0.0'
    },
    onUploadProgress: (event) => {
      let progress = Math.round((event.loaded * 100) / event.total);
      setProgress(progress);
    }
  });

  return data;
};
