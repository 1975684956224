import React from 'react';

import { Typography, Box, Button, makeStyles, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { PropTypes } from 'prop-types';

export default function Error({ init }) {
  const classes = makeStyles((theme) => ({
    button: {
      display: 'flex',
      float: 'right',
      padding: '8px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      color: 'white',
      fontSize: 12,
      fontWeight: 500,
      textWrap: 'nowrap',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
      '&:disabled': {
        backgroundColor: '#B4BDD3',
        color: 'white'
      }
    }
  }))();

  return (
    <Box px={2} py={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item container xs={12} sm={6}>
          <Box display={'flex'} alignItems={'center'}>
            <WarningIcon
              style={{ fontSize: 24, color: '#CB410B', marginRight: 8 }}
            />
            <Typography
              style={{ fontWeight: 600, fontSize: 14, textWrap: 'nowrap' }}
            >
              Algo de inesperado aconteceu.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button onClick={init} className={classes.button}>
            Tente novamente
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

Error.propTypes = {
  init: PropTypes.func
};

Error.defaultProps = {
  init: async () => {}
};
