import React from 'react';
import { Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import { Page401 } from '~/components/Icons/Page401';
import PageTemplate from '~/components/templates/Page';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

export default function PageUnauthorized ({ lectureDependency }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isTablet = useMediaQuery('@media (max-width: 780px)');

  return (
    <PageTemplate noAppbar noLeftDrawer>
      <div className={classes.container}>
        <div className={classes.leftContent}>
          <div className={classes.imageTablet}>
            <Page401 width={300} height="auto" />
          </div>
          <div className={classes.title}>
            <div className={classes.titleDecoration}>
              <div className={`${classes.stroke} ${classes.strokeTop}`} />
              <div className={`${classes.stroke} ${classes.strokeMid}`} />
              <div className={`${classes.stroke} ${classes.strokeBot}`} />
            </div>
            <Text color="black" variant={isTablet ? 'smBanner' : 'mdBanner'}>
              Ops!
            </Text>
          </div>

          {!lectureDependency ? (
            <>
              <div className={classes.subtitle}>
                <Text variant={isTablet ? 'mdTitle' : 'xl'} color="black">
                  Página não autorizada
                </Text>
              </div>
              <div className={classes.body}>
                <div className={classes.bodyTitle}>
                  <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                    Por quê?
                  </Text>
                </div>
                <div className={classes.bodyText}>
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="black">
                    Isso significa que você está tentando acessar uma página na
                    qual é necessário antes fazer login com um nome de usuário e
                    senha válidos para que você possa visualiza-la.
                  </Text>
                </div>
                <div className={classes.bodyTitle}>
                  <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                    Sugerimos:
                  </Text>
                </div>
                <div className={classes.bodyText}>
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="black">
                    Certifique-se de que o URL que você está tentando acessar
                    está correto, depois adicione-o novamente em sua barra de
                    endereço e aperte Enter. Se foi de algum link nosso, pedimos
                    desculpas pelo inconveniente e que você{' '}
                    <Link to="/help" className={classes.link}>
                      nos ajude
                    </Link>{' '}
                    a resolver esse problema
                  </Text>
                </div>
                <div className={classes.bodyTitle}>
                  <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                    Estes links podem te ajudar.
                  </Text>
                </div>
                <ul className={classes.linkList}>
                  <li>
                    <Link to="/dashboard">
                      <Text
                        variant={isTablet ? 'largeText' : 'lg'}
                        color="primary"
                      >
                        Página inicial
                      </Text>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy-and-usage-terms">
                      <Text
                        variant={isTablet ? 'largeText' : 'lg'}
                        color="primary"
                      >
                        Termos de uso e política de privacidade
                      </Text>
                    </Link>
                  </li>
                  <li>
                    <Link to="/help">
                      <Text
                        variant={isTablet ? 'largeText' : 'lg'}
                        color="primary"
                      >
                        Entre em contato conosco
                      </Text>
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className={classes.subtitle}>
                <Text variant={isTablet ? 'mdTitle' : 'xl'} color="black">
                  Você não tem acesso a essa{' '}
                  {`${i18n.t('StructuresName.Lectures.SingularLower')}`}.
                </Text>
              </div>
              <div className={classes.body}>
                <div className={classes.bodyTitle}>
                  <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                    O que fazer?
                  </Text>
                </div>
                <div className={classes.bodyText}>
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="black">
                    Para ter acesso a esta{' '}
                    {`${i18n.t('StructuresName.Lectures.SingularLower')}`} é
                    necessário concluir as anteriores.
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={classes.rightContent}>
          <Page401 width={500} height="auto" />
        </div>
      </div>
    </PageTemplate>
  );
}
