import React, { useEffect, useState } from 'react';

import Card from '~/components/atoms/Card';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';

import FormButton from '../ExerciseForm/FormButton';
import LectureMemberReportCard from './LectureMemberReportCard';
import MembersCard from './MembersReportCard';
import QuestionsCard from './QuestionsCard';
import ReportMemberUpBox from './ReportMemberUpBox';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const ExerciseMemberReportRoot = ({
  lecture,
  openExerciseForm,
  result
}) => {
  const [questionsData, setQuestionsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const requestApiRelatorio = async () => {
    await api
      .get(`/api/forms/${lecture.lectureable.id}/lectures/${lecture.id}/info`)
      .then((response) => {
        setQuestionsData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const handleRedirect = () => {
    history.push(`/dashboard/spaces/${lecture.space_id}`);
  };

  useEffect(() => {
    if (lecture) requestApiRelatorio();
  }, [lecture]);

  return (
    <div style={{ marginBottom: '40px' }}>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItens="center"
          spacing={5}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Text
              style={{
                fontSize: '30px',
                color: '#a1a1a1',
                width: '100%',
                textAlign: 'center',
                marginBottom: '-20px'
              }}
            >
              {i18n.t('Questionário')}
            </Text>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {isLoading ? (
              <Skeleton variant="rectangular" width={'100%'} height={70} />
            ) : (
              <ReportMemberUpBox
                result={result ? true : false}
                openExerciseForm={openExerciseForm}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {isLoading ? (
              <Skeleton variant="rectangular" width={'95%'} height={313} />
            ) : (
              <QuestionsCard questionsData={questionsData} />
            )}
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            {isLoading ? (
              <Skeleton variant="rectangular" width={'95%'} height={313} />
            ) : (
              <LectureMemberReportCard result={result} />
            )}
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            {isLoading ? (
              <Skeleton variant="rectangular" width={'95%'} height={313} />
            ) : (
              <MembersCard reportMembersCardData={questionsData} />
            )}
          </Grid>
        </Grid>
      </Card>
      <FormButton handleClick={handleRedirect} text={i18n.t('Back')} />
    </div>
  );
};

export default ExerciseMemberReportRoot;
