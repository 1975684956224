import React, { useEffect, useState } from 'react';

import { useLectureDiscussionMuralContext } from '~/contexts/LectureDiscussionMuralContext';
import { useLectureMuralContext } from '~/contexts/LectureMuralContext';
import { getAttachmentsByLecture } from '~/services/Lecture';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
  useTheme
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

export const AttachmentsManagerButton = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { setContentDisplay, lecture } = useLectureMuralContext();
  const [loading, setLoading] = useState(false);

  const {
    totalAttachments,
    setTotalAttachments
  } = useLectureDiscussionMuralContext();

  async function getTotalAttachments() {
    if (loading) return;
    try {
      setLoading(true);
      const response = await getAttachmentsByLecture(lecture.id);
      setTotalAttachments(response.count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTotalAttachments();
  }, []);

  return (
    <ButtonBase
      disableRipple
      onClick={() => setContentDisplay('attachments')}
      className={classes.buttonGoToAttachments}
    >
      <Box className={classes.flexContainerWithGap}>
        <PhotoLibraryIcon
          className={classes.buttonGoToAttachmentsLibraryIcon}
        />
        <Typography className={classes.buttonGoToAttachmentsText}>
          Anexos
        </Typography>
      </Box>
      <Box className={classes.flexContainerWithGap}>
        {loading ? (
          <CircularProgress
            size={'16px'}
            style={{
              color: theme.palette.activeText.main
            }}
          />
        ) : (
          <Typography className={classes.buttonGoToAttachmentsText}>
            {totalAttachments}
          </Typography>
        )}
        <ArrowForwardIosIcon
          className={classes.buttonGoToAttachmentsArrowIcon}
        />
      </Box>
    </ButtonBase>
  );
};
