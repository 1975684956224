import api from '~/services/api';

// Sets the base path for API requests related to user-course associations.
const baseURL = '/api/user_course_associations';

// Function to change a user's role within a specific course.
export const update = async (UserCourseAssociationID, role) => {
  // Sends a request to modify the role attribute of a user-course association.
  const { data } = await api.put(`${baseURL}/${UserCourseAssociationID}`, {
    user_course_association: { role }
  });
  // Returns the result of the update operation, typically the modified record.
  return data;
};

// Function to link a user to a course, assigning them a role.
export const create = async (courseID, userID, role) => {
  // Initiates a request to form a new user-course association with defined roles.
  const { data } = await api.post(baseURL, {
    user_course_association: { course_id: courseID, user_id: userID, role }
  });
  // Outputs the newly created association's details.
  return data;
};

// Makes the update and create functions available for import in other parts of the application.
export default {
  update,
  create
};
