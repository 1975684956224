import React from 'react';
import Text from '~/components/atoms/Text';
import { MdLock } from 'react-icons/md';
import { Box, Button, useTheme } from '@material-ui/core';
import { IoIosArrowBack } from 'react-icons/io';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import i18n from '~/I18n';

export default function LectureDependencyAlert({ lastLecture }) {
  const { name, id } = lastLecture;
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <MdLock className={classes.lockIcon} color="#32394E" />

        <Text variant="largeText" className={classes.largeText}>
          Parece que você está animado para avançar, o que é ótimo! Mas antes
          disso, você precisa{' '}
          <strong className={classes.spanText}>
            finalizar última {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
            acessada
          </strong>
          .
        </Text>

        <Button
          className={classes.addButton}
          onClick={() => history.push(`/dashboard/lecture/${id}`)}
        >
          <IoIosArrowBack className={classes.arrowIcon} />
          {name}
        </Button>
      </Box>
    </Box>
  );
}
