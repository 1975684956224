import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '465px',
    maxWidth: '100vw',
    minWidth: 'min-content',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    padding: '16px 24px'
  },
  customList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0',
    margin: '16px 0'
  },
  customLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    color: 'white',
    gap: '8px',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));
