import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import api from '~/services/api';

import MyProfileContacts from '../contactsItems/MyProfileContactsItem';
import UsersProfileContacts from '../contactsItems/ProfileContactsItem';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { Skeleton } from '@material-ui/lab';

export default function TransitionsModal({
  userId,
  otherUsers,
  loggedInUserId,
  goToContactsSection
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [profileContacts, setProfileContacts] = useState([]);
  const [usersContacts, setUsersContacts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  async function getMyContacts() {
    setHasMore(true);
    setPage(page + 1);
    api
      .get(`api/connections/mine`, {
        params: {
          page: page,
          status: 'accepted'
        }
      })
      .then((response) => {
        setProfileContacts((prevState) => [...prevState, ...response.data]);
        setHasMore(false);
        goToContactsSection();
      })
      .catch(() => {
        goToContactsSection();
      });
  }
  async function getUsersContacts() {
    setHasMore(true);
    setPage(page + 1);
    api
      .get(`api/users/${userId}/friends_of_another_user`, {
        params: {
          page: page
        }
      })
      .then((response) => {
        setUsersContacts((prevState) => [...prevState, ...response.data]);
        setHasMore(false);

        goToContactsSection();
      })
      .catch(() => {
        goToContactsSection();
      });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getMoreItems = (e) => {
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;

    if (bottom) {
      setHasMore(true);
      if (!otherUsers) {
        getUsersContacts();
      } else {
        getMyContacts();
      }
    }
  };

  useEffect(() => {
    if (profileContacts.length < 10) setHasMore(false);
    if (usersContacts.length < 10) setHasMore(false);
  }, [profileContacts, usersContacts]);

  useEffect(() => {
    if (!otherUsers) getUsersContacts();

    if (userId && loggedInUserId) getMyContacts();

    return () => (
      setHasMore(true), setProfileContacts([]), setUsersContacts([])
    );
  }, [userId, loggedInUserId, otherUsers]);

  return (
    <div>
      <button
        type="button"
        onClick={handleOpen}
        className={classes.openModalButton}
      >
        Ver Todas
      </button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <>
            <div className={classes.paper}>
              <button className={classes.closeModal} onClick={handleClose}>
                X
              </button>
              <div className={classes.header}>
                <Text
                  style={{
                    fontSize: 25,
                    color: 'rgba(0,0,0, 0.3)'
                  }}
                >
                  Todas as conexões
                </Text>
              </div>
              <div className={classes.scrollableArea} onScroll={getMoreItems}>
                <Grid container spacing={2}>
                  {!otherUsers
                    ? usersContacts.map((item) => {
                        return (
                          <UsersProfileContacts key={item.id} item={item} />
                        );
                      })
                    : profileContacts.map((item) => {
                        return <MyProfileContacts key={item.id} item={item} />;
                      })}

                  {hasMore ? (
                    <Grid item xs={12} sm={12}>
                      <div style={{ display: 'flex' }}>
                        <Skeleton
                          variant="circle"
                          width={50}
                          height={50}
                          style={{
                            margin: '5px 15px 5px 5px'
                          }}
                        />
                        <Skeleton
                          variant="rect"
                          width="75%"
                          height={50}
                          style={{ overflow: 'hidden' }}
                        />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </div>
  );
}
