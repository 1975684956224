import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '8px'
  },
  inputField: {
    whiteSpace: 'wrap',
    padding: '16px',
    fontSize: '14px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.activeText.main}`,
    color: theme.palette.activeText.main
  },
  input: {
    '&::placeholder': {
      color: theme.palette.activeText.main, // Altere a cor aqui
      opacity: 1 // Para garantir que a cor seja aplicada corretamente
    }
  },
  inputFileIcon: {
    fontSize: '24px',
    color: theme.palette.activeText.main,
    cursor: 'pointer',
    marginLeft: '5px' // Compensa a diferença entre os ícones do react icon e MUI para manter os elementos alinhados.
  },
  cancelButton: {
    boxShadow: 'none',
    fontSize: '14px',
    padding: '8px 24px',
    borderRadius: '4px',
    textTransform: 'none',
    color: '#454954',
    '&:disabled': {
      color: theme.palette.activeText.main
    }
  },
  confirmButton: {
    boxShadow: 'none',
    fontSize: '14px',
    padding: '8px 24px',
    borderRadius: '4px',
    textTransform: 'none'
  },
  helperText: {
    display: 'flex',
    alignItens: 'center',
    gap: '4px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    color: theme.palette.error.main
  },
  errorIcon: {
    fill: theme.palette.error.main,
    fontSize: '16px'
  }
}));
