import api from '~/services/api';
import { readFile } from '~/utils/imageUtils';

const handleAvatar = async (file) => {
  if (file) {
    var dataUrl = await readFile(file);
    return { image: dataUrl, image_file_name: file.name };
  }

  return null;
};

export const updateSpace = async (spaceID, body) => {
  if (body.space.new_avatar_attributes?.size) {
    body.space.new_avatar_attributes = await handleAvatar(
      body.space.new_avatar_attributes
    );
  } else {
    delete body.space.new_avatar_attributes;
  }
  return await api.put(`/api/spaces/${spaceID}`, body);
};

export const getSpace = async (spaceID) => {
  return await api.get(`api/spaces/${spaceID}`);
};

export const deleteSpace = async (spaceID) => {
  return await api.delete(`/api/spaces/${spaceID}`);
};
