import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '~/components/atoms/Avatar';
import { useDebounce } from '~/hooks/useDebounce';
import { searchContactOn, searchContactEmit } from '~/socket/contact-events';

import { useStyles } from './styles';
import { Box, TextField, useTheme } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Alert, Autocomplete } from '@material-ui/lab';

export const ConversationContactSearch = ({ profile }) => {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const classes = useStyles({ theme });
  const debouncedSearch = useDebounce(searchTerm, 800);
  const history = useHistory();

  useEffect(() => {
    if (profile) {
      searchContactOn(profile?.id, (contacts) => {
        setContacts(contacts);
      });
    }
  }, [profile]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchTermTeste = (q) => {
    searchContactEmit(profile.id, q);
  };

  useEffect(() => {
    const searchHN = async () => {
      if (debouncedSearch) {
        await searchTermTeste(debouncedSearch);
      } else if (debouncedSearch === '') {
        setContacts([]);
      }
    };

    searchHN();
  }, [debouncedSearch]);

  return (
    <Box>
      <Autocomplete
        options={contacts}
        classes={{
          option: classes.option
        }}
        autoHighlight
        getOptionLabel={(option) => `${option.display_name}`}
        noOptionsText="Sem contatos"
        onChange={(event, newValue) => {
          if (newValue?.id) {
            history.push(`/messages/${newValue.id}`);
          }
        }}
        onInputChange={handleChange}
        renderOption={(option) => {
          return (
            <React.Fragment>
              <Box
                style={{ marginRight: '10px' }}
                onClick={() => Alert(option.first_name)}
              >
                <Avatar
                  size={30}
                  profile={{
                    id: option?.id,
                    first_name: option?.first_name,
                    thumbnails: option?.thumbnails
                  }}
                />
              </Box>
              {option.display_name}
            </React.Fragment>
          );
        }}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            ref={params.InputProps.ref}
            className=""
            {...params}
            variant="outlined"
            placeholder="Buscar contato"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ fontSize: '18' }}>
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
              style: {
                fontSize: '16px'
              }
            }}
          />
        )}
      />
    </Box>
  );
};
