import api from '~/services/api';

export const sendEnrollmentRequest = async (userID, courseID) => {
  try {
    const payload = {
      user_course_association: {
        user_id: userID,
        course_id: courseID,
        role: 'member'
      }
    };
    const { data } = await api.post(
      `/api/sebrae/user_course_associations`,
      payload
    );
    return data;
  } catch (error) {
    throw 'Error';
  }
};
