import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PopUpMenu from '~/components-refactor/molecules/PopUpMenu';
import Item from '~/components-refactor/molecules/PopUpMenu/Item';
import UnlinkDialog from '~/components-refactor/molecules/UnlinkDialog';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import i18n from '~/I18n';
import { unlink } from '~/services/mirroring/space';

import { useSpacePageContext } from '../contexts/SpacePageContext';
import SharingModal from '../SharingModal';
import { Grid, useTheme, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';

export default function ManagerPopUp() {
  const theme = useTheme();
  const { space } = useSpacePageContext();
  const history = useHistory();

  const [openSharing, setOpenSharing] = useState(false);
  const [openUnlinkModal, setOpenUnlinkModal] = useState(false);

  const ReplicationItem = () => (
    <StructureCreationPopUp
      text={`Novo ${i18n.t(`StructuresName.Subjects.SingularUpper`)}`}
      structure={{
        name: 'subject',
        parentStructure: {
          name: 'Space',
          id: space.id
        }
      }}
      structureName={i18n.t('StructuresName.Subjects.SingularUpper')}
      redirectLink={`/dashboard/environments/${space.environment.id}/courses/${space.course_id}/spaces/${space.id}/subjects/new`}
    />
  );

  const PopUpButton = ({ ...props }) => (
    <Grid container alignItems="center" spacing={1} {...props}>
      <Grid item>
        <MoreVertIcon
          fontSize="large"
          style={{ color: theme.palette.activeText.main }}
        />
      </Grid>
      <Grid item>
        <Typography
          style={{ fontSize: 14, color: theme.palette.activeText.main }}
        >
          {`Gerenciar ${i18n.t(`StructuresName.Space.SingularUpper`)}`}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <PopUpMenu component={PopUpButton}>
        <Item icon={AddIcon}>
          <ReplicationItem />
        </Item>

        <Item
          icon={EditIcon}
          text={i18n.t('EditSpace.title')}
          action={() =>
            history.push(
              `/dashboard/environments/${space.environment.id}/courses/${space.course_id}/spaces/${space.id}/edit`
            )
          }
        />
        {space.can_unlink && (
          <Item
            icon={LinkOffIcon}
            text={'Desvincular espelho'}
            action={() => setOpenUnlinkModal(true)}
          />
        )}
        <Item
          icon={ShareIcon}
          action={() => setOpenSharing(true)}
          text={`Compartilhar ${i18n.t('StructuresName.Space.SingularLower')}`}
        />
      </PopUpMenu>
      <SharingModal open={openSharing} setOpen={setOpenSharing} />
      <UnlinkDialog
        open={openUnlinkModal}
        handleClose={() => setOpenUnlinkModal(false)}
        unlink={unlink}
        structureID={space.id}
        callback={() => window.location.reload()}
      />
    </>
  );
}
