
import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CardsContainer = styled(Box)({
  display: 'flex',
  flexDirection:'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '200px',
  height: '313px',
  border: '1px solid #449cfd',
  borderRadius: '5px',
});
