export function formatTimeDistanceToNow(curDate) {
  const date = new Date(curDate);
  const now = new Date();
  const diffInSeconds = (now - date) / 1000;

  if (diffInSeconds < 60) {
    return 'Agora';
  }

  const diffInMinutes = diffInSeconds / 60;
  if (diffInMinutes < 60) {
    const minutes = Math.floor(diffInMinutes);
    return `${minutes} min`;
  }

  const diffInHours = diffInMinutes / 60;
  if (diffInHours < 24) {
    const hours = Math.floor(diffInHours);
    return `${hours} h`;
  }

  const diffInDays = diffInHours / 24;
  if (diffInDays < 30) {
    const days = Math.floor(diffInDays);
    return `${days} d`;
  }

  const diffInMonths = diffInDays / 30;
  if (diffInMonths < 12) {
    const months = Math.floor(diffInMonths);
    return `${months} m`;
  }

  const diffInYears = diffInMonths / 12;
  const years = Math.floor(diffInYears);
  return `${years} a`;
}

export const formatFileSize = (currentSize) => {
  if (currentSize === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(currentSize) / Math.log(k));
  return parseFloat((currentSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const scrollToElement = (elementID) => {
  const element = document.getElementById(elementID);
  if (element) {
    element.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
};

export const scrollToElementIntoView = (elementID) => {
  const element = document.getElementById(elementID);
  if (element) {
    // setTimeout is used to guarantee the completion of any rendering and prevent Reflow, which would stop scrolling
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }, 500);
  }
};
