import React from 'react';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import Proptypes from 'prop-types';

const TextBox = ({ title, children, isListItem }) => {
  const theme = useTheme();
  const classes = useStyles({ isListItem });
  return (
    <Box className={classes.root}>
      <Text
        variant="text"
        style={{ maxWidth: '100%', color: theme.palette.activeText.main }}
      >
        <Text
          variant="text"
          style={{
            fontWeight: 500,
            maxWidth: '100%',
            color: theme.palette.activeText.main
          }}
        >
          {title}
        </Text>
        {children}
      </Text>
    </Box>
  );
};

TextBox.propTypes = {
  title: Proptypes.string,
  children: Proptypes.string,
  isListItem: Proptypes.bool
};

TextBox.defaultProps = {
  title: '',
  children: '',
  isListItem: false
};

export default TextBox;
