import base from './base';
const baseUrl = 'https://api.redu.digital';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'k0qb224jup';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'bmerE6W7teWF09nHOhEBh7ozabiR2c0X4embxXiBASU',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://api.redu.digital:44443',
  clarityKey
};
