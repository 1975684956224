import { socket } from '~/socket';

export const userConversationsOn = (userId, callback) => {
  socket.on(`user-conversations/${userId}`, (conversationsList) => {
    callback(conversationsList);
  });
};
export const userConversationsEmit = (userId) => {
  socket.emit(`user-conversations`, userId);
};
export const userConversationsOff = (userId) => {
  socket.on(`user-conversations/${userId}`);
};
