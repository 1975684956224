import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getEnvironmentsRequest: ['page'],
  getEnvironmentsSuccess: ['environments', 'currentEnvData'],
  getEnvironmentsFailure: [],
  clearEnvironments: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  environments: [],
  currentEnvData: [],
  page: 1,
  environmentsRequestLoading: false,
  loading: false,
  noMoreStatuses: false
};

const getEnvironmentsRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.environmentsRequestLoading = true;
  });

const getEnvironmentsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.page += 1;
    if (draft.currentEnvData.length < 10) draft.loading = false;
    if (!action.environments || !action.environments.length)
      draft.noMoreStatuses = true;
    else {
      draft.environments = action.environments;
      draft.currentEnvData = action.environments;
    }
  });

const getEnvironmentsFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.environmentsRequestLoading = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_ENVIRONMENTS_REQUEST]: getEnvironmentsRequest,
  [Types.GET_ENVIRONMENTS_SUCCESS]: getEnvironmentsSuccess,
  [Types.GET_ENVIRONMENTS_FAILURE]: getEnvironmentsFailure
});
