import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  button: {
    width: '100%',
    textAlign: 'left'
  },

  buttonContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  buttonTitle: {
    display: 'flex',
    alignItems: 'end'
  },

  buttonIcon: {
    fontSize: '36px'
  }
}));
