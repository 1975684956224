import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  subjectTitleHelper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 7,
    width: '100% ',
    textOverflow: 'ellipsis'
  },
  selectContainer: {
    width: '80% ',
    display: 'flex',
    columnGap: 20,
    alignItems: 'space-between'
  },

  formControl: {
    width: 315,
    rowGap: 5,
    '& fieldset': {
      borderColor: (props) => `${props.theme.palette.primary.main} !important`
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: (props) => props.theme.palette.primary.main
    }
  },
  select: {
    width: 315,
    height: 40,
    padding: '0 0 0 30px',
    display: 'flex',
    alignItems: 'center'
  },
  selectIcon: {
    fontSize: 20,
    fontWeight: 'bold',
    color: (props) => props.theme.palette.primary.main
  },
  selectMenu: {
    maxHeight: 230,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.2rem'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  selectOption: {
    height: 40,
    fontSize: 14,
    color: (props) => props.theme.palette.primary.main
  },

  progressWraper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 5
  },
  NoLectures: {
    marginTop: 30,
    padding: 0,
    paddingTop: 0,
    flexGrow: 1,
    '@media (max-width: 600px)': {
      marginTop: 30
    }
  }
}));
