import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    recaptcha: {
      marginTop: '2rem',
      transform: 'scale(1)',

      '@media (max-width: 1050px)': {
        transform: 'scale(0.9)'
      },

      '@media (max-width: 600px)': {
        transform: 'scale(1)'
      },

      '@media (max-width: 450px)': {
        transform: 'scale(0.9)'
      },

      '@media (max-width: 390px)': {
        transform: 'scale(0.8)'
      },

      '@media (max-width: 350px)': {
        transform: 'scale(0.7)'
      }
    },
    containerForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: '360px',
      gap: 16
    },
    submitButton: {
      width: '100%',
      height: 40,
      backgroundColor: theme.palette.secondary.main,
      border: 0,
      borderRadius: '0.5rem',
      color: 'white',
      fontSize: '1.6rem',
      '&:hover': {
        backgroundColor: theme.palette.tertiary.main,
        borderColor: theme.palette.tertiary.main,
        boxShadow: 'none'
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.tertiary.main,
        borderColor: theme.palette.tertiary.main
      }
    },
    containerLoginError: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      fontSize: 12,
      margin: 5,
      padding: 5,
      color: theme.palette.warn.main
    }
  };
});
