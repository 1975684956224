import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '24px',
    padding: '16px 16px 0px 16px'
  },
  titleBox: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backButton: {
    padding: '4px',
    borderRadius: '50%',
    color: theme.palette.activeText.main
  },
  titleText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.activeText.main
  },
  tabIcon: { fontSize: '24px', marginRight: '8px' },
  tabText: { fontSize: '14px', lineHeight: '16px', textOverflow: 'ellipsis' },
  attachments: {
    overflow: 'scroll',
    maxHeight: '100%',
    padding: '0px 8px',
    margin: '0px 8px'
  },
  attachmentCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingBottom: '16px',
    marginBottom: '16px',
    borderBottom: '1px solid rgba(112,112,112,0.2)'
  },
  attachmentCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  attachmentCardHeaderText: {
    fontSize: '10px',
    lineHeight: '14px',
    color: theme.palette.activeText.main
  },
  feedbackContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px'
  }
}));
