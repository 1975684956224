import React from 'react';

import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutline';
import MaterialAlert from '@material-ui/lab/Alert';

import { useStyles } from './styles';

export default function Alert({ handleClose, message, alertType }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <MaterialAlert
      icon={
        alertType === 'success' ? (
          <CheckIcon className={classes.icon} />
        ) : (
          <ErrorOutlinedIcon className={classes.icon} />
        )
      }
      classes={{
        root: classes.root,
        filledSuccess: classes.success,
        filledError: classes.error,
      }}
      severity={alertType}
      onClose={handleClose}
      variant="filled"
    >
      {message}
    </MaterialAlert>
  );
}
