import React, { useEffect, useRef } from 'react';
import { Box, useTheme } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import i18n from '~/I18n';

import * as S from './styles';
import { useStyles } from './styles';

export default function InputFile({
  caractersLeft,
  name,
  label,
  selectFile,
  preview,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);
  const theme = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, []);

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box className={classes.AttachmentContainer}>
      <button
        type="button"
        className={classes.attachButton}
        onClick={handleButtonClick}
      >
        <AttachFileIcon />
      </button>
      <p style={{ fontSize: '10px', color: 'grey' }}>{preview}</p>

      <S.CountLeftCaracters>
        {i18n.t('CaractersLeft', { caracterLeft: caractersLeft })}
      </S.CountLeftCaracters>

      <input
        id="fileInputs"
        type="file"
        accept={'images/*'}
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={selectFile}
      />
    </Box>
  );
}

InputFile.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rest: PropTypes.shape(),
  caractersLeft: PropTypes.number
};

InputFile.defaultProps = {
  name: '',
  label: '',
  rest: {},
  caractersLeft: 1600
};