import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const STATUS_FILE = 'status_files/';

export const hasManageAccessToStatusFile = async (statusFileID) =>
  await hasManageAccessTo(`${STATUS_FILE}${statusFileID}`);
export const hasReadAccessToStatusFile = async (statusFileID) =>
  await hasReadAccessTo(`${STATUS_FILE}${statusFileID}`);
export const hasUpdateAccessToStatusFile = async (statusFileID) =>
  await hasCustomAccessTo(`${STATUS_FILE}${statusFileID}`, 'update');
