import React from 'react';

import {
  FormControl,
  MenuItem,
  FormHelperText,
  useTheme
} from '@material-ui/core';
import MaterialSelect from '@material-ui/core/Select';
import PropTypes from 'prop-types';

import Text from '../Text';
import { useStyles } from './styles';

export default function Select({
  input,
  label,
  values,
  labels,
  editProfileInput,
  educationsSelect,
  disabled,
  placeholder,
  onChange,
  fullWidth,
  meta,
  showErrors,
  applyPlaceholderStyles,
  ...custom
}) {
  // se o user nao tiver selecionado nada, o valor do input eh alterado para placeholder para que
  // o texto placeholder seja renderizado (por alguma razao quando o valor era == "" nao funcionava)
  if (input && input.value === '') input.value = 'placeholder';

  const theme = useTheme();
  const classes = useStyles({
    theme,
    placeholderSelected:
      (input && input.value === 'placeholder') || applyPlaceholderStyles
  });
  const displayError = !!(meta && (meta.touched || showErrors) && meta.error);

  return (
    <FormControl
      style={{ width: fullWidth ? '100%' : '' }}
      error={displayError}
    >
      {label && (
        <Text variant="label" color="gray">
          {label}
        </Text>
      )}
      <MaterialSelect
        defaultValue="placeholder"
        style={{
          fontSize: editProfileInput ? 12 : ''
        }}
        onChange={onChange}
        className={educationsSelect ? classes.educationsSelect : classes.root}
        variant="outlined"
        disabled={disabled}
        MenuProps={{
          classes: { paper: classes.selectOptions }
        }}
        classes={{
          icon: displayError && classes.iconError
        }}
        {...input}
        {...custom}
      >
        <MenuItem className={classes.menuItem} value="placeholder" disabled>
          {placeholder}
        </MenuItem>
        {values.map((item, idx) => {
          const typeOfItem = Array.isArray(item);

          const itemValue = (typeOfItem && item[1] && item[1]) || item;
          const itemLabel = (typeOfItem && item[1] && item[0]) || item;

          return (
            <MenuItem className={classes.menuItem} key={idx} value={itemValue}>
              {(labels && labels[idx]) || itemValue}
            </MenuItem>
          );
        })}
      </MaterialSelect>
      {displayError && (
        <FormHelperText classes={{ root: classes.helperText }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Select.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string,
  values: PropTypes.array,
  editProfileInput: PropTypes.bool,
  educationsSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  custom: PropTypes.shape(),
  fullWidth: PropTypes.bool,
  meta: PropTypes.shape(),
  showErrors: PropTypes.bool,
  applyPlaceholderStyles: PropTypes.bool
};

Select.defaultProps = {
  input: null,
  label: '',
  values: [],
  editProfileInput: false,
  educationsSelect: false,
  disabled: false,
  placeholder: '',
  onChange: null,
  custom: null,
  fullWidth: false,
  meta: {},
  showErrors: false,
  applyPlaceholderStyles: false
};
