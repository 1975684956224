import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import i18n from '~/I18n';
import { getSimplifiedReport } from '~/services/reports/courses';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function SimplifiedReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getSimplifiedReport}
        baseName={`relatório_simplificado_de_conclusão_de_${i18n.t(
          'StructuresName.Course.SingularLower'
        )}_`}
        id={id}
      />
    </div>
  );
}

SimplifiedReport.propTypes = {
  id: PropTypes.string
};

SimplifiedReport.defaultProps = {
  id: ''
};
