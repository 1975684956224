import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ContactListMessagesNav } from '~/components/molecules/ContactListMessagesNav';
import { ConversationContactSearch } from '~/components/molecules/ConversationContactSearch';
import PageTemplate from '~/components/templates/Page';
import MessageRoutes from '~/routes/message-routes';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { Box } from '@material-ui/core';

export default function Messages() {
  const { profile } = useSelector((state) => state.user);

  const theme = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    document.title = `${theme.title} | Mensagens`;
  }, []);

  return (
    <PageTemplate>
      <Box className={classes.container}>
        <Box className={classes.contactsDrawer}>
          <Box className={classes.contactsContent}>
            <ConversationContactSearch profile={profile} />
            <ContactListMessagesNav />
          </Box>
        </Box>
        <MessageRoutes />
      </Box>
    </PageTemplate>
  );
}
