import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingRight: 25,
    paddingBottom: 50,
    marginTop: 30,
    overflow: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 865px)': {
      // maxHeight: 'calc(100vh - 300px)',
      rowGap: 15
    },
    '@media (max-width: 300px)': {
      paddingBottom: 100
    },
    gap: 30
  },

  bold: { fontWeight: 'bold' },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    },
    overflow: 'auto',
    '@media (max-width: 768px)': {
      height: '100%',
      width: '100%',
      padding: 0
    }
  },
  modal__form: {
    overflow: 'auto',
    background: 'white',
    width: '90%',
    '@media (max-width: 768px)': {
      width: '100%',
      height: '100%',
      padding: '15px 15px'
    },

    '@media (max-width: 481px)': {
      padding: '30px 15px'
    },
    height: '100%',
    borderRadius: '0 5px 5px 0',
    padding: '20px 70px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  modal__content: {
    flex: 1,
    paddingRight: 5
  },
  modal__buttonWraper: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  modal__exitButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    '& svg': {
      fontSize: 30,
      color: (props) => props.theme.palette.secondary.main
    },
    cursor: 'pointer'
  },
  modal__content_text: {
    marginBottom: '15px'
  },
  wrapModal: {
    width: '80%',
    '@media (max-width: 768px)': {
      width: '95%'
    },

    '@media (max-width: 481px)': {
      width: '95%',
      height: '100%'
    },
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  modal__sideBar: {
    minWidth: '250px',
    boxSizing: 'border-box',
    marginRight: '-1px',
    flex: 1,
    background: '#FAFAFA',
    borderRadius: '12px 0px 0px 12px',
    display: 'flex',
    flexDirection: 'column'
  },
  sideBar_title: { padding: '30px 30px 0 30px' },

  modal__sideBar_menuWraper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    '@media (max-width: 768px)': {
      marginTop: '0',
      marginBottom: '15px',

      flexDirection: 'row'
    }
  },
  modal__sideBar_menuWraper_itemSelected: {
    width: '100%',
    height: '70px',
    borderLeft: '7px solid #2066CC',
    '@media (max-width: 768px)': {
      borderLeft: '0px solid #2066CC',
      borderBottom: '7px solid #2066CC',
      justifyContent: 'center',
      alignItems: 'center'
    },

    background: '#8080801A',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer'
  },
  modal__sideBar_menuWraper_item: {
    width: '100%',
    height: '70px',
    display: 'flex',
    borderLeft: '7px solid #FAFAFA',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      borderLeft: '0px solid #FAFAFA',

      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  members__buttons: {
    width: 176,
    height: 50,
    fontSize: 20,
    fontWeight: 'normal',
    boxSizing: 'border-box',
    marginRight: '15px',
    '&:last-child': '0px',
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      width: 32,
      height: 32,
      '&:last-child': {
        margin: 0
      }
    }
  },

  modal__sideBarIcon: {
    color: (props) => props.theme.palette.primary.main,
    fontSize: 30,
    marginLeft: 15,
    '@media (max-width: 768px)': { margin: 0 }
  }
});
