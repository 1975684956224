import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactLoading from 'react-loading';

import Text from '~/components/atoms/Text';
import PaginatableSelect from '~/components/molecules/PaginatableSelect';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import mirrorListService from '~/services/mirroring/mirroring_list/lectures';
import { IconWrapper, TextPrimary } from '~/utils/forms';

import { useStyles, warningStyle } from './styles';
import { Box, Collapse, Grid, Switch } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

export default function Clone() {
  const theme = useTheme();
  const classes = useStyles();

  const {
    control,
    setValue,
    watch,
    formState: {
      errors: { lectureable: lectureableErrors }
    }
  } = useFormContext();

  const [isLoading, setIsLoading] = useState(true);
  const [environments, setEnvironments] = useState(null);
  const [courses, setCourses] = useState(null);
  const [spaces, setSpaces] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [lectures, setLectures] = useState(null);

  const [selectedLecture, setSelectedLecture] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSpace, setSelectedSpace] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [lectureID, setLectureID] = useState('');

  const handleSuccess = (values, setValues) => {
    setValues(values);
    setIsLoading(false);
  };

  const handleFailure = () => {
    setIsLoading(false);
  };

  const handleEnvironments = (event) => {
    setIsLoading(true);
    setValue('lectureable.clone.lecture_id', '');
    setSpaces(null);

    const { target } = event;
    setSelectedEnvironment(
      environments.find((item) => item.id == target.value)
    );
    mirrorListService
      .getCourses(target.value)
      .then((courses) =>
        handleSuccess(
          courses.map((item) => item.course),
          setCourses
        )
      )
      .catch(handleFailure);
  };

  const handleCourses = (event) => {
    setIsLoading(true);
    setValue('lectureable.clone.lecture_id', '');
    setSubjects(null);

    const { target } = event;
    setSelectedCourse(courses.find((item) => item.id == target.value));
    mirrorListService
      .getSpaces(target.value)
      .then((spaces) =>
        handleSuccess(
          spaces.map((item) => item.space),
          setSpaces
        )
      )
      .catch(handleFailure);
  };

  const handleSpaces = (event) => {
    setIsLoading(true);
    setValue('lectureable.clone.lecture_id', '');
    setLectures(null);

    const { target } = event;
    setSelectedSpace(spaces.find((item) => item.id == target.value));
    mirrorListService
      .getSubjects(target.value)
      .then((subjects) =>
        handleSuccess(
          subjects.map((item) => item.subject),
          setSubjects
        )
      )
      .catch(handleFailure);
  };

  const handleSubjects = (event) => {
    setIsLoading(true);
    setValue('lectureable.clone.lecture_id', '');

    const { target } = event;
    setSelectedSubject(subjects.find((item) => item.id == target.value));
    mirrorListService
      .getSelf(target.value)
      .then((lectures) =>
        handleSuccess(
          lectures.map((item) => item.lecture),
          setLectures
        )
      )
      .catch(handleFailure);
  };

  const handleLectures = (event) => {
    const { target } = event;
    setLectureID(target.value);
    setSelectedLecture(lectures.find((item) => item.id == target.value));
    setValue('lectureable.clone.lecture_id', target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    mirrorListService
      .getEnvironments()
      .then((environments) =>
        handleSuccess(
          environments.map((item) => item.environment),
          setEnvironments
        )
      )
      .catch(handleFailure);
  }, []);

  useEffect(() => {
    if (selectedLecture) setValue('name', selectedLecture.name);
  }, [selectedLecture]);

  return (
    <Box>
      <div>
        <Grid
          className={classes.warning}
          item
          container
          alignItems="center"
          spacing={1}
          xs={12}
          md={9}
        >
          <Grid item>
            <IconWrapper
              customColor="#f3ae1b"
              Icon={PriorityHighIcon}
              fontSize="30px"
              {...warningStyle}
            />
          </Grid>
          <Grid item xs>
            <Text color="gray" variant="mediumText">
              {`Somente ${i18n.t(
                'StructuresName.Lectures.PluralLower'
              )} que pertencem a você podem ser copiadas.`}
            </Text>
          </Grid>
        </Grid>
      </div>

      {isLoading && !environments ? (
        <Grid container justifyContent="center">
          <ReactLoading type="bars" color={`${theme.palette.primary.main}`} />
        </Grid>
      ) : (
        <>
          {lectureableErrors?.clone?.lecture_id ? (
            <Text color="error">
              Você ainda não selecionou uma{' '}
              {`${i18n.t('StructuresName.Lectures.SingularLower')}`}.
            </Text>
          ) : null}

          {environments ? (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Text color="primary">
                  {`${i18n.t('StructuresName.Environment.PluralUpper')}`}
                </Text>
                <PaginatableSelect
                  value={selectedEnvironment.id}
                  onChange={handleEnvironments}
                  label={
                    selectedEnvironment.name
                      ? ''
                      : `Selecione um ${i18n.t(
                          'StructuresName.Environment.SingularUpper'
                        )}`
                  }
                  paginatableFunction={mirrorListService.getEnvironments}
                  objectList={environments}
                  setObjectList={setEnvironments}
                  labelAttribute={'name'}
                  valueAttribute={'id'}
                  instanceName={'environment'}
                  params={null}
                />
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && environments && !courses}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses}>
                  <Text color="primary">
                    {`${i18n.t('StructuresName.Course.PluralUpper')}`}
                  </Text>
                  {courses && (
                    <PaginatableSelect
                      value={selectedCourse.id}
                      onChange={handleCourses}
                      label={
                        selectedCourse.name
                          ? ''
                          : `Selecione um ${i18n.t(
                              'StructuresName.Course.SingularUpper'
                            )}`
                      }
                      paginatableFunction={mirrorListService.getCourses}
                      objectList={courses}
                      setObjectList={setCourses}
                      labelAttribute={'name'}
                      valueAttribute={'id'}
                      instanceName={'course'}
                      params={selectedEnvironment?.id}
                    />
                  )}
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && courses && !spaces}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={courses && spaces}>
                  <Text color="primary">
                    {`${i18n.t('StructuresName.Space.PluralUpper')}`}
                  </Text>
                  {spaces && (
                    <PaginatableSelect
                      value={selectedSpace.id}
                      onChange={handleSpaces}
                      label={
                        selectedSpace.name
                          ? ''
                          : `Selecione uma ${i18n.t(
                              'StructuresName.Space.SingularUpper'
                            )}`
                      }
                      paginatableFunction={mirrorListService.getSpaces}
                      objectList={spaces}
                      setObjectList={setSpaces}
                      labelAttribute={'name'}
                      valueAttribute={'id'}
                      instanceName={'space'}
                      params={selectedCourse?.id}
                    />
                  )}
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && spaces && !subjects}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={spaces && subjects}>
                  <Text color="primary">
                    {`${i18n.t('StructuresName.Subjects.PluralUpper')}`}
                  </Text>
                  {subjects && (
                    <PaginatableSelect
                      value={selectedSubject.id}
                      onChange={handleSubjects}
                      label={
                        selectedSubject.name
                          ? ''
                          : `Selecione um ${i18n.t(
                              'StructuresName.Subjects.SingularUpper'
                            )}`
                      }
                      paginatableFunction={mirrorListService.getSubjects}
                      objectList={subjects}
                      setObjectList={setSubjects}
                      labelAttribute={'name'}
                      valueAttribute={'id'}
                      instanceName={'subject'}
                      params={selectedSpace?.id}
                    />
                  )}
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={isLoading && subjects && !lectures}>
                  <ReactLoading
                    width={30}
                    height={30}
                    type="bars"
                    color={`${theme.palette.primary.main}`}
                  />
                </Collapse>

                <Collapse in={subjects && lectures}>
                  <Text color="primary">
                    {`${i18n.t('StructuresName.Lectures.PluralUpper')}`}
                  </Text>
                  {lectures && (
                    <PaginatableSelect
                      name="lectureable.clone.lecture_id"
                      value={lectureID}
                      onChange={handleLectures}
                      label={
                        lectureID
                          ? ''
                          : `Selecione uma ${i18n.t(
                              'StructuresName.Lectures.SingularUpper'
                            )}`
                      }
                      paginatableFunction={mirrorListService.getSelf}
                      objectList={lectures}
                      setObjectList={setLectures}
                      labelAttribute={'name'}
                      valueAttribute={'id'}
                      instanceName={'lecture'}
                      params={selectedSubject?.id}
                    />
                  )}
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={watch('lectureable.clone.lecture_id')}>
                  <div className={classes.field}>
                    <Field
                      label={TextPrimary(
                        `Deseja espelhar a ${i18n.t(
                          'StructuresName.Lectures.SingularLower'
                        )}`
                      )}
                      name="lectureable.clone.is_mirror"
                      labelPlacement={'end'}
                      control={control}
                      Component={Switch}
                      size="small"
                      checked={watch('lectureable.clone.is_mirror')}
                    />
                  </div>

                  <div className={classes.field}>
                    <Text color="black" variant={'actionText'}>{`
                                                                  ${selectedEnvironment?.name} >
                                                                  ${selectedCourse?.name} >
                                                                  ${selectedSpace?.name} >
                                                                  ${selectedSubject?.name} >
                                                                  ${selectedLecture?.name}`}</Text>
                    <Text
                      color="primary"
                      variant={'title'}
                      style={{ marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {selectedLecture?.name}
                      </span>{' '}
                      selecionada
                    </Text>
                  </div>
                </Collapse>
              </Grid>
            </Grid>
          ) : (
            <div>
              Você ainda não criou nenhuma{' '}
              {`${i18n.t('StructuresName.Lectures.SingularLower')}`} para que
              possa clonar.
            </div>
          )}
        </>
      )}
    </Box>
  );
}
