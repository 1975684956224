import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '15px 30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '15px'
    },
    height: '100%'
  },
  iconButton: {
    marginLeft: '10px'
  },
  sendIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main
  },
  input: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#eaeaea',
    height: '40px',
    borderRadius: '20px',
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  inputProps: {
    display: 'flex',
    width: '100%',
    flex: 1,
    fontSize: '16px'
  }
}));
