import React, { useState, useEffect } from 'react';
import './styles.css';
import { useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router';

export default function VLibras({ isntHome }) {
    const [isPopup, setIsPopup] = useState(false);
  const isTablet = useMediaQuery('@media (max-width: 1190px)');
  const location = useLocation();
  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    const widgetSrc = 'https://vlibras.gov.br/app';
    const scriptSrc = 'https://vlibras.gov.br/app/vlibras-plugin.js';
    let script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    script.onload = (load) => {
      new window.VLibras.Widget(widgetSrc);
    };
    document.head.appendChild(script);

    return () => {
      setIsHome(false);
    };
  }, []);

  useEffect(() => {
    const isHomeCheck = location.pathname === '/dashboard';
    const modifyCheck = isTablet || !isHomeCheck;
    setIsHome(modifyCheck);
    return () => {
      setIsHome(false);
    };
  }, [location, isTablet]);

  const onButtonClick = () => {
    setIsPopup((prev) => !prev);
  };

  return isHome ? (
    <div vw="true" className="enabled" id="isTablet">
      <div vw-access-button="true" className="active"></div>
      <div vw-plugin-wrapper="true">
        <div className="vw-plugin-top-wrapper"></div>
      </div>
    </div>
  ) : (
    <div
      vw="true"
      className="enabled"
      id={isPopup ? 'isWeb' : ''}
      onClick={onButtonClick}
    >
      <div vw-access-button="true" className="active"></div>
      <div vw-plugin-wrapper="true">
        <div className="vw-plugin-top-wrapper"></div>
      </div>
    </div>
  );
}
