import React from 'react';

import { useStyles } from './styles';
import { Button } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import PropTypes from 'prop-types';

export const CloseSnackbarButton = ({ snackbarProviderRef, snackbarKey }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.buttonStyle}
      onClick={() => snackbarProviderRef.current.closeSnackbar(snackbarKey)}
    >
      <ClearRoundedIcon className={classes.iconStyle} />
    </Button>
  );
};

CloseSnackbarButton.propTypes = {
  snackbarProviderRef: PropTypes.shape({
    current: PropTypes.shape({
      closeSnackbar: PropTypes.func.isRequired
    }).isRequired
  }).isRequired,
  snackbarKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};
