/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { getDegrees } from './fetch';
import { useStyles } from './styles';
import { Checkbox, useTheme } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckboxIcon from '@material-ui/icons/CheckBox';

export default function Degrees() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [degrees, setDegrees] = useState([]);
  const { watch } = useFormContext();

  useEffect(() => {
    getDegrees()
      .then((degrees) => setDegrees(degrees))
      .catch(console.log);
  }, []);

  const renderDegrees = useCallback(() => {
    return degrees.map((degree) => (
      <Field
        label={
          <Text variant="mediumText" color="black">
            {degree.title}
          </Text>
        }
        labelPlacement={'end'}
        name={`degree_ids.${degree.id}`}
        key={degree.id}
        Component={Checkbox}
        checked={watch('degree_ids')[degree.id]}
        size="medium"
        color="primary"
        checkedIcon={<CheckboxIcon style={{ fontSize: 21 }} />}
        icon={<CheckBoxOutlineBlank style={{ fontSize: 21 }} />}
      />
    ));
  }, [watch('degree_ids'), degrees]);

  return (
    <div className={classes.field}>
      {TextPrimary('Grau')}
      <div className={classes.degreeList}>{renderDegrees()}</div>
    </div>
  );
}
