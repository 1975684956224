import React from 'react';
import { useFormContext } from 'react-hook-form';

import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { Box, Switch } from '@material-ui/core';

const fileTypes = ['audio/mp3', 'audio/mp4', 'audio/ogg', 'audio/mpeg'];
export default function Audio() {
  const { control } = useFormContext();

  return (
    <>
      <Field
        name="lectureable.audio.file"
        control={control}
        Component={DragDropFileInput}
        permittedFileTypes={fileTypes}
        maxSize={50}
        fileTypesDescription={
          <div>
            Arquivos apenas em formato .mp3, .mp4, .ogg, .mpeg <br />
            Tamanho máximo de arquivos: <strong>50 MB</strong>.
          </div>
        }
      />
      {/*<Box style={{ marginTop: '20px' }}>*/}
      {/*  <Field*/}
      {/*    label={TextPrimary('Permitir download da mídia')}*/}
      {/*    labelPlacement={'end'}*/}
      {/*    control={control}*/}
      {/*    name="lectureable.audio.downloadable"*/}
      {/*    size="small"*/}
      {/*    Component={Switch}*/}
      {/*  />*/}
      {/*</Box>*/}
    </>
  );
}
