import api from '~/services/api';

import {
  getEnvironments as baseGetEnvironments,
  getCourses as baseGetCourses
} from '..';

const baseURL = '/api/mirroring_list/spaces';

export const getEnvironments = async (page) => {
  const environmentsList = await baseGetEnvironments(baseURL, page);

  return environmentsList;
};

export const getCourses = async (environmentID, page) => {
  const coursesList = await baseGetCourses(baseURL, environmentID, page);

  return coursesList;
};

export const getSelf = async (courseID, page) => {
  const { data } = await api.get(
    `${baseURL}?course_id=${courseID}&page=${page}`
  );

  return data;
};

export default {
  getEnvironments,
  getCourses,
  getSelf
};
