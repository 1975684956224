import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ContactListMessageNavItem } from '~/components/molecules/ContactListMessageNavItem';
import { socket } from '~/socket';
import {
  userConversationsEmit,
  userConversationsOn
} from '~/socket/conversation-events';

import List from '@material-ui/core/List';

export const ContactListMessagesNav = () => {
  let { url } = useRouteMatch();
  const { profile } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);

  const listUserConversations = () => {
    if (profile && conversations.length <= 0) {
      userConversationsEmit(profile.id);
      userConversationsOn(profile.id, (list) => {
        setLoading(false);
        setConversations(list);
      });
    }
  };

  useEffect(() => {
    socket.io.on('error', () => {
      if (loading) {
        setLoading(false);
      }
    });
    socket.io.on('reconnect', () => {
      if (loading) {
        setLoading(true);
      }
    });
    listUserConversations();
  }, [profile]);

  return (
    <List component="nav" aria-label="main mailbox folders">
      {loading && (
        <div style={{ marginTop: 180 }}>
          <p style={{ fontSize: 20, color: 'black' }}>
            Carregando lista de contatos...
          </p>
        </div>
      )}
      {!loading && conversations?.length <= 0 && (
        <div style={{ marginTop: 180 }}>
          <p style={{ fontSize: 18, color: 'black' }}>Nenhuma conversa</p>
          <p style={{ fontSize: 18, color: 'black' }}>
            Busque um contato e inicie um chat
          </p>
        </div>
      )}
      {!loading &&
        conversations?.length > 0 &&
        conversations?.map((conversation, index) => (
          <ContactListMessageNavItem
            key={index}
            url={url}
            profile={profile}
            conversation={conversation}
          />
        ))}
    </List>
  );
};
