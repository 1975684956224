import { formatDateToDateZeroHour } from '../dates';
import * as yup from 'yup';

let startDate = formatDateToDateZeroHour(new Date());
let endDate = formatDateToDateZeroHour(new Date(), 1);

const validations = {
  name: yup.string().required('Este campo é obrigatório.'),
  workload: yup.string().required('Este campo é obrigatório.'),
  signature: yup
    .mixed()
    .when('generate_certificate', {
      is: true,
      then: yup.mixed().required('Campo obrigatório')
    })
    .nullable(),
  signature_owner_organization: yup
    .string()
    .when('generate_certificate', {
      is: true,
      then: yup.string().required('Campo obrigatório')
    })
    .nullable(),
  responsible_for_signing: yup
    .string()
    .when('generate_certificate', {
      is: true,
      then: yup.string().required('Campo obrigatório')
    })
    .nullable(),
  minimum_rate_for_completion: yup
    .string()
    .when('generate_certificate', {
      is: true,
      then: yup.string().required('Campo obrigatório')
    })
    .nullable(),
  signature_owner_position: yup
    .string()
    .when('generate_certificate', {
      is: true,
      then: yup.string().required('Campo obrigatório')
    })
    .nullable(),
  course_start_date: yup
    .date()
    .when('is_course_period', {
      is: true,
      then: yup.date().required('Campo obrigatório')
    })
    .nullable(),
  course_end_date: yup
    .date()
    .when('is_course_period', {
      is: true,
      then: yup.date().required('Campo obrigatório')
    })
    .nullable()
};

export const courseValidations = yup.object().shape(validations);
