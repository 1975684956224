import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  editableStyles: {
    padding: '30px 20px',
    border: '1px solid #c4c4c4',
    borderTop: 'transparent',
    '& h2': {
      // backgroundColor: theme.palette.secondary.main,
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      padding: '5px',
      width: '300px',
      height: '400px',
      display: 'flex',
      alignItens: 'center',
      justifyContent: 'center'
    }
  }
}));
