import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const useStyles = makeStyles((theme) => ({
  justifyContentBetween: { justifyContent: 'space-between' },
  root: {
    maxWidth: '95vw',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    '& > *': {
      margin: '1px'
    }
  },
  subjectTitleHelper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 7,
    width: '100% ',
    textOverflow: 'ellipsis'
  },
  loadingMore: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '25%',
    paddingTop: '3%'
  },
  formControl: {
    width: 315,
    rowGap: 5,
    '& fieldset': {
      borderColor: (props) => `${props.theme.palette.primary.main} !important`
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: (props) => props.theme.palette.primary.main
    }
  },
  select: {
    width: 315,
    height: 40,
    padding: '0 0 0 30px',
    display: 'flex',
    alignItems: 'center'
  },
  selectIcon: {
    fontSize: 20,
    fontWeight: 'bold',
    color: (props) => props.theme.palette.primary.main
  },
  selectMenu: {
    maxHeight: 230,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0.2rem'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  selectOption: {
    height: 40,
    fontSize: 14,
    color: (props) => props.theme.palette.primary.main
  },
  lectureContainer: {
    marginTop: 30,
    padding: 0,
    paddingTop: 0,
    flexGrow: 1,
    overflowY: 'scroll'
  },
  lectureGrid: {
    width: '100%',
    display: 'grid',
    columnGap: 30,
    rowGap: 15,
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '70%',
    maxHeight: '400px',
    borderRadius: 5,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2,45%)',
      justifyContent: 'center',
      rowGap: 0
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3,30%)',
      justifyContent: 'center',
      rowGap: 0
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4,22%)',
      justifyContent: 'center',
      rowGap: 0
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1,auto)',
      rowGap: 0,
      justifyContent: 'center',
      paddingRight: 20
    }
  },
  shimmerItem: {
    marginBottom: 24,
    borderRadius: 5,
    width: 230,
    height: 130,
    '@media (max-width: 600px)': {
      width: 315,
      height: 176
    }
  },
  wrapIconButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px',
    '@media (max-width: 700px)': {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginLeft: '-7px'
    },
    '@media (max-width: 600px)': {
      //   paddingRight: 25,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '0'
    }
  },
  customMarginRigth: {
    marginRigth: '20px',
    '&:last-child': {
      marginRigth: '0px'
    }
  },

  resetMargin: {
    marginRigth: '5px',
    '& p': { margin: '0', marginLeft: '3px' }
  },
  progressWraper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 5
  },
  NoLectures: {
    marginTop: 30,
    padding: 0,
    paddingTop: 0,
    flexGrow: 1,
    '@media (max-width: 600px)': {
      marginTop: 30
    }
  },
  bottomLeftSnackbar: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    fontSize: '1.5rem'
  }
}));

export const ProgressLoading = ({ size = 50 }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: 5
      }}
    >
      <Skeleton variant="text" width={70} height={24} />

      <Skeleton variant="circle" width={size} height={size} />
    </div>
  );
};
