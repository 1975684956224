import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%'
  },
  label: {
    fontSize: '23px',
    fontWeight: '600',
    lineHeight: '32px',
    color: theme.palette.activeText.main
  },
  selectItem: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  outlinedSelect: {
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px'
  }
}));
