import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    margin: '7px 0',
    display: 'flex',
    padding: '2px 0',
    borderRadius: 5,
  },
  imgData: {
    fontSize: '12px',
    alignItems: 'center',
    '& img': {
      width: '350px',
      height: 'auto'
    }
  },
  radio: {
    width: 14,
    height: 14,
    marginTop: 2,
    marginRight: 15
  },
  alternatives: {
    maxWidth: 500,
    maxHeight: 400
  }
});
