import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  font: {
    font: 'normal normal normal 14px/17px Univers LT Std'
  },
  header: {
    fontSize: 40,
    '@media (max-width: 450px)': {
      fontSize: 30
    }
  },
  xxl: {
    fontSize: 34
  },
  xl: {
    fontSize: 30
  },
  lgTitle: {
    fontSize: 24
  },
  mdTitle: {
    fontSize: 20
  },
  lg: {
    fontSize: 16
  },
  title: {
    fontSize: 22
  },
  lectureTitle: {
    fontSize: 20,
    '@media (max-width: 1460px)': {
      fontSize: 16
    }
  },
  classItemTitle: {
    fontSize: 16,
    '@media (max-width: 1460px)': {
      fontSize: 16
    },
    '@media (max-width: 450px)': {
      fontSize: 20
    }
  },
  lgCourseCard: {
    fontSize: 16,
    lineHeight: '23.44px',
    fontWeight: '500 !important'
  },
  subtitle: {
    fontSize: 18
  },
  sideBarTitle: {
    fontSize: 16
  },
  username: {
    fontSize: 15
  },
  friendshipRequest: {
    fontSize: 15,
    '@media (max-width: 1460px)': {
      fontSize: 14
    },
    '@media (max-width: 450px)': {
      fontSize: 12
    }
  },
  friendshipRequestDate: {
    fontSize: 14,
    '@media (max-width: 1460px)': {
      fontSize: 12
    },
    '@media (max-width: 450px)': {
      fontSize: 10
    }
  },
  friendshipRequestButton: {
    fontSize: 16,
    '@media (max-width: 1460px)': {
      fontSize: 13
    }
  },
  welcomeTitle: {
    fontSize: 30,
    fontWeight: '500',
    '@media (max-width: 1024px)': {
      fontSize: 25
    },
    '@media (max-width: 450px)': {
      fontSize: 20
    }
  },
  welcomeText: {
    fontSize: 20
  },
  button: {
    fontSize: 14
  },
  text: {
    fontSize: 14
  },
  textBold: {
    fontSize: 14,
    fontWeight: '500'
  },
  largeTextHover: {
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  environmentSidebarText: {
    fontSize: 16,
    '@media (max-width: 600px)': {
      textTransform: 'uppercase',
      fontWeight: '500'
    }
  },
  accordionTitle: {
    fontSize: 24,
    '@media (max-width: 600px)': {
      fontSize: 20
    }
  },
  largeText: {
    fontSize: 16
  },
  mediumText: {
    fontSize: 12
  },
  smallText: {
    fontSize: 10
  },
  /* hashtag */
  hashtag: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#B4B4B5'
  },
  actionText: {
    fontSize: 14,
    marginRight: 4
  },
  appBarName: {
    fontSize: 20,
    marginLeft: 13
  },
  mdBanner: {
    fontSize: 70
  },
  smBanner: {
    fontSize: 50
  },
  white: {
    color: 'white'
  },
  gray: {
    color: '#707070'
  },
  black: {
    color: '#000'
  },
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  accent: {
    color: theme.palette.accent.main
  },
  textAndBackground: {
    color: theme.palette.textAndBackground.main
  },
  error: {
    color: theme.palette.error.main
  },
  hashtagColor: {
    color: '#B4B4B5'
  },
  label: {
    marginBottom: 5,
    marginTop: 10,
    fontSize: 14
  },
  largeTitle: {
    fontSize: 96,
    fontWeight: 'bold'
  },
  largeSubtitle: {
    fontSize: 36,
    fontWeight: 'bold'
  },

  /* ------------- Privacy Policy ------------- */
  ppTitle: {
    fontSize: 32,
    '@media (max-width: 500px)': {
      fontSize: 25
    },
    '@media (max-width: 300px)': {
      fontSize: 20
    }
  },

  /* ------------- additional styles ------------- */
  underlineCursorPoinerHover: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
}));
