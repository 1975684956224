import base from './base';
const baseUrl = 'https://v1.educa.siao.seg.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfk1zv8mqr';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: '2D1yiKLW6kRVRrzcU69icpNUuekF1ETX2gGh4sf4TfPW',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://v1.educa.siao.seg.br:44443',
  clarityKey
};
