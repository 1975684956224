import React from 'react';
import Field from '~/components/organisms/GenericFormWrapper/Field'

import { Collapse, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Switch } from '@material-ui/core';
import { TextPrimary }    from '~/utils/forms';
import { useFormContext } from 'react-hook-form';
import i18n from '~/I18n';

import { useStyles } from './styles'

function SubjectDependency() {
  const classes = useStyles()
  const { control, watch, setValue } = useFormContext()

  const handleOrder = event => {
    setValue("order_subjects_by", event.target.value)
  }

  return (
      <Grid container>
        <Grid item xs={12} className={classes.switch}>
          <Field  label={TextPrimary(`Ativar dependência entre ${i18n.t('StructuresName.Subjects.PluralLower')}`)} 
                  labelPlacement={'end'} 
                  name="dependent_subjects" 
                  control={control} 
                  checked={watch("dependent_subjects")}
                  Component={Switch}
                  size="small" 
                  />
        </Grid>

        <Collapse in={watch("dependent_subjects")}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
                
                <Grid item xs={{xs: 12, sm: null}}>
                  <FormControl component="fieldset" style={{padding: '10px'}}>
                    {TextPrimary(`Ordernar ${i18n.t('StructuresName.Subjects.PluralUpper')} por:`)}
                    <RadioGroup aria-label="order_subjects_by" name="order_subjects_by" value={watch('order_subjects_by')} onChange={handleOrder}>
                      <FormControlLabel value="created_at" control={<Radio />} label={TextPrimary("Data de Criação")} />
                      <FormControlLabel value="name" control={<Radio />} label={TextPrimary("Ordem lexicográfica")} />
                    </RadioGroup>
                  </FormControl>
                </Grid>

            </Grid>
          </Grid>
        </Collapse>
      
      </Grid>
    );
}

export default SubjectDependency;