import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import ConfirmModal from '~/components/molecules/ConfirmModal';
import { IconWrapper } from '~/utils/forms';

import { Grid, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export default function Alternative({
  alternative,
  deleteFunc,
  editFunc,
  showQuestion
}) {
  const theme = useTheme();
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Text
              style={{
                color: alternative.correct
                  ? `${theme.palette.success.main}`
                  : 'black'
              }}
              variant="mediumText"
            >
              {`${alternative.letter}: ${alternative.text.replace(
                /<[^>]+>/g,
                ''
              )}`}
            </Text>
          </Grid>

          {alternative.correct ? (
            <Grid item style={{ marginLeft: '3px' }}>
              {' '}
              <IconWrapper
                customColor={theme.palette.success.main}
                fontSize={13}
                Icon={CheckIcon}
              />{' '}
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {showQuestion ? null : (
        <Grid item>
          <Grid container spacing={1}>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() => editFunc(alternative)}
            >
              <IconWrapper
                customColor={theme.palette.primary.main}
                fontSize={20}
                Icon={EditIcon}
              />
            </Grid>

            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={() => setConfirmDelete(true)}
            >
              <IconWrapper
                customColor={theme.palette.primary.main}
                fontSize={20}
                Icon={DeleteIcon}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <ConfirmModal
        show={confirmDelete}
        text={`Tem certeza que deseja remover a alternativa?`}
        handleConfirm={() => deleteFunc(alternative)}
        handleClose={() => setConfirmDelete(!confirmDelete)}
      />
    </Grid>
  );
}
