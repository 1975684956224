import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: props => props.isModal ? '100%' : '50%',
    flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    '@media (max-width: 1400px)': {
      width: props => props.isModal ? '100%' : '60%'
    },
    '@media (max-width: 1024px)': {
      width: props => props.isModal ? '100%' : '90%'
    },
    '@media (max-width: 600px)': {
      width: props => props.isModal ? '100%' : '100%'
    }
  },
  field: {
    marginBottom: 15,
    '& p': {
      marginBottom: 5
    }
  },
  textArea: {
    border: props => `1px solid ${props.theme.palette.primary.main}`,
    borderRadius: 5,
    padding: 10,
    width: '100%',
    fontSize: 14,
    color: props => props.theme.palette.textActiveElement.main,
    '&::placeholder': {
      color: props => props.theme.palette.textActiveElement.main,
      opacity: .75
    }
  },
  input: {
    border: props => `1px solid ${props.theme.palette.primary.main}`,
    height: 40,
    borderRadius: 5,
    padding: '0 10px',
    width: '100%',
    fontSize: 14,
    color: props => props.theme.palette.textActiveElement.main,
    '&::placeholder': {
      color: props => props.theme.palette.textActiveElement.main,
      opacity: .75
    }
  },
  select: {
    outline: 'none',
    height: 40,
    borderRadius: 5,
    width: '100%',
    fontSize: 14,
    color: props => props.theme.palette.textActiveElement.main,
    padding: 0,
    paddingRight: 10,
    "& fieldset, .MuiOutlinedInput-root, .Mui-focused, .MuiOutlinedInput-notchedOutline": {
      borderWidth: '1px !important'
    }
  },
  selectRoot: {
    width: '100%',
    height: 40,
    padding: '0 0 0 10px',
    display: 'flex',
    alignItems: 'center'
  },
  selectOptions: {
    maxHeight: 160,
    zIndex: 1000000
  },
  selectPopUpRoot: {
    zIndex: '1000000 !important'
  },
  menuItem: {
    fontSize: '12px'
  },
  multiline: {
    height: 150,
    padding: 10,
    overflowY: 'scroll'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textButton: {
    "& button:hover": {
      backgroundColor: props => props.theme.palette.tertiary.main
    }
  },
  iconButton: {
    minWidth: 0,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5
  },
  sendIcon: {
    width: 30,
    height: 30,
    color: props => props.theme.palette.primary.main
  },
  paperClip: {
    color: props => props.theme.palette.textActiveElement.main,
    width: 25,
    height: 25,
    cursor: 'pointer',
    marginRight: '10px',
  },
  fileInput: {
    display: 'none'
  },
  inputError: {
    border: props => `1px solid ${props.theme.palette.error.main}`,
  },
  helperText: {
    fontSize: 12,
    color: props => props.theme.palette.error.main,
    padding: '0 5px'
  },
  feedback: {
    marginTop: 30
  },
  shimmerText: {
    marginBottom: 5
  },
  shimmerInput: {
    marginBottom: 15
  }
});