import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Logo = styled.img`
  width: ${(props) => props.width};
  height: auto;
`;

export const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.primary.main
  },
  ToolbarWraper: {
    display: 'flex',
    alignItems: 'center',
    height: '76px',
    '@media (min-width: 768px)': { padding: '0 40px' }
  },
  searchMenuIconContainer: {
    padding: 0,
    '@media (max-width: 450px)': {
      width: '33.33%'
    }
  },
  avatarLogoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '20px',

    '@media (max-width: 450px)': {
      flexDirection: 'row',
      width: '66.66%',
      marginLeft: '0px'
    }
  },
  avatarContainer: {
    '@media (max-width: 450px)': {
      width: '50%',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  nameTextContainer: {
    '@media (max-width: 450px)': {
      display: 'none'
    }
  },
  resetPadding: { padding: 0, '& span': { padding: 0, width: 'auto' } }
}));
