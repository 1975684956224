import React from 'react';

import PropTypes from 'prop-types';
import settings from '~settings';
import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/core';
import { EnvironmentIcon } from '~/components/atoms/Icons';

import { useStyles } from './styles';
import i18n from '~/I18n';

function EnvironmentItem({ environment, profileId, loggedInId }) {
  // environment image not implemented in api
  const { name, description, id, thumbnails } = environment;
  const theme = useTheme();
  const classes = useStyles(theme);

  const image = thumbnails && thumbnails.length > 4 && thumbnails[4].href
    ? thumbnails[4].href
    : null;

  function EnvLink({ children }) {
    const showLink = profileId && loggedInId && profileId == loggedInId
    if(showLink) return <Link to={`/dashboard/environments/${id}`}>{ children }</Link>
    
    return <>{ children }</>
  }

  return (
    <div className={classes.container} key={id}>
      <EnvLink>
        <div className={image ? classes.imageContainer : classes.iconContainer}>
          { image
            ? <img src={`${settings.baseUrl}${image}`} alt={name} />
            : <EnvironmentIcon width={'8rem'} height={'8rem'} color='white' />
          }
        </div>
      </EnvLink>

      <div className={classes.textContainer}>
        <EnvLink>
          <h3>{name}</h3>
        </EnvLink>
        <p>{description || i18n.t('noDescription')}</p>
      </div>
    </div>
  );
}

EnvironmentItem.propTypes = {
  environment: PropTypes.shape(),
  profileId: PropTypes.number,
  loggedInId: PropTypes.number
}

EnvironmentItem.defaultProps = {
  environment: {},
  profileId: 0,
  loggedInId: 0
}

export default EnvironmentItem;
