import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'debounce';
import PropTypes from 'prop-types';

const SubHeaderSection = React.memo(
  ({
    setSearchString,
    searchString,
    loadMembersList,
    openAddMemberModal,
    openImportMemberModal
  }) => {
    const theme = useTheme();
    const classes = useStyles({ theme });

    const [desableInputSearch, setDisableInputSearch] = useState(false);

    const isMounted = useRef(false);
    const inputRef = useRef(null);

    const handleSearchInput = (event) => {
      const email = event.target.value;
      setSearchString(email);
    };

    const resetMembersList = true;
    const debouncedSearchMembers = debounce(async () => {
      if (isMounted.current) {
        setDisableInputSearch(true);
        await loadMembersList(resetMembersList);
        setDisableInputSearch(false);
        if (inputRef.current) inputRef.current.focus();
      } else {
        isMounted.current = true;
      }
    }, 1000);

    useEffect(() => {
      if (debouncedSearchMembers) debouncedSearchMembers.clear();
      debouncedSearchMembers();
      return () => {
        if (debouncedSearchMembers) debouncedSearchMembers.clear();
      };
    }, [searchString]);

    return (
      <>
        <Box className={classes.container}>
          <OutlinedInput
            id="search-input"
            placeholder="Pesquise por membro..."
            aria-label="Barra de pesquisa"
            className={classes.searchBar}
            inputRef={inputRef}
            onChange={handleSearchInput}
            disabled={desableInputSearch}
            endAdornment={
              <IconButton
                style={{ marginRight: -12 }}
                aria-label="Botão pesquisar"
              >
                <SearchIcon
                  style={{
                    fontSize: '24px',
                    color: theme.palette.primary.main
                  }}
                />
              </IconButton>
            }
          />
          <Box className={classes.subheaderSectionButtons}>
            <Button
              className={classes.importButton}
              disableRipple
              onClick={openImportMemberModal}
            >
              <ImportExportIcon
                style={{
                  fontSize: '24px'
                }}
              />
              Importar
            </Button>

            <Button
              className={classes.addButton}
              disableRipple
              onClick={openAddMemberModal}
            >
              <AddIcon
                style={{
                  fontSize: '24px'
                }}
              />
              Adicionar
            </Button>
          </Box>
        </Box>
      </>
    );
  }
);

SubHeaderSection.displayName = 'SubHeaderSection';
export default SubHeaderSection;

SubHeaderSection.propTypes = {
  setSearchString: PropTypes.func,
  searchString: PropTypes.string,
  loadMembersList: PropTypes.func,
  openAddMemberModal: PropTypes.func,
  openImportMemberModal: PropTypes.func
};
