import React, { useState } from 'react';

import Text         from '~/components/atoms/Text';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import i18n from '~/I18n';

import { Button, Grid, Modal, useTheme } from '@material-ui/core';

import SelectOptions from './SelectOptions';

export default function IntegrationOptions({ authorizationLink, authorizations }) {

  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={openModal}>
        <Text style={{ padding: 0, margin: 0}} color="primary"> Gerar autorização para o Redu </Text>
      </Button>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container 
              alignItems='center' 
              justifyContent='center' 
              style={{ transform: 'translate(0%, 100%)' }}
              >
          
          <Grid item>
            <Grid style={{ backgroundColor: 'white', borderRadius: '5px', padding: '20px 40px' }} 
                  container 
                  direction='column' spacing={2}>
              
              <Grid item>
                <Text color="primary" variant='subtitle'> Notamos que você já integrou outros {`${i18n.t('StructuresName.Environment.PluralLower')}`} com o <span style={{ color: 'green', fontWeight: 'bold' }}>Pagseguro</span>.</Text>
              </Grid>

              <Grid item>
                <SelectOptions authorizations={authorizations}/>
              </Grid>

              <Grid item>
                <a href={authorizationLink}>
                  <ButtonSquare
                    height={40}
                    backgroundColor={theme.palette.error.main}
                  >
                    <Text color="white" variant="mediumText">
                      Não, prosseguir para o Pagseguro.
                    </Text>
                  </ButtonSquare>
                </a>
              </Grid>

            </Grid>
          </Grid>

        </Grid>
      </Modal>
    </>
  );
}