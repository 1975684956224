import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';

import Error from './Error';
import useStyles from './styles.js';
import TooltipHTML from './TooltipHTML';
import {
  Grid,
  Button,
  Typography,
  Paper,
  Box,
  Tooltip,
  useTheme
} from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function EnrollmentLinkForm({
  structureID,
  get,
  create,
  enrollmentLinkService,
  tooltipMessage
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { enqueueSnackbar } = useSnackbar();
  const [enrollmentLink, setEnrollmentLink] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    link: '',
    key: ''
  });

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: defaultValues
  });

  const handleCopyLink = () => {
    navigator.clipboard.writeText(watch('link'));
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(watch('key'));
  };

  const messages = {
    update: {
      success: 'atualizado',
      failure: 'atualizar'
    },
    create: {
      success: 'criado',
      failure: 'criar'
    },
    remove: {
      success: 'excluido',
      failure: 'excluir'
    }
  };

  const handleSuccess = (key) => {
    const message = messages[key];
    enqueueSnackbar(`Link de Inscrição ${message.success} com sucesso`, {
      variant: 'success'
    });
    setIsFetching(false);
  };

  const handleFailure = (key) => {
    const message = messages[key];
    enqueueSnackbar(`Não foi possível ${message.failure} o link de inscrição`, {
      variant: 'error'
    });
    setIsFetching(false);
  };

  const onSubmit = async (data) => {
    if (isFetching) return;
    setIsFetching(true);
    if (enrollmentLink) {
      enrollmentLinkService
        .update(enrollmentLink.id, data)
        .then(() => {
          handleSuccess('update');
        })
        .catch(() => {
          handleFailure('update');
        });
    } else {
      create(structureID, data)
        .then((response) => {
          handleSuccess('create');
          setEnrollmentLink(response);
          setDefaultValues(response);
        })
        .catch(() => {
          handleFailure('create');
        });
    }
  };

  const handleRemoveLink = () => {
    if (isFetching || !enrollmentLink) return;
    setIsFetching(true);
    enrollmentLinkService
      .remove(enrollmentLink.id)
      .then(() => {
        handleSuccess('remove');
        setEnrollmentLink(null);
        setDefaultValues({ key: '', link: '' });
      })
      .catch(() => {
        handleFailure('remove');
      });
  };

  const init = () => {
    if (isFetching) return;
    setIsFetching(true);
    setError(false);
    get(structureID)
      .then((enrollmentLink) => {
        setEnrollmentLink(enrollmentLink);
        setDefaultValues(enrollmentLink);
        setIsFetching(false);
      })
      .catch((error) => {
        const {
          response: { status }
        } = error;
        if (status !== 404) {
          setError(true);
        }
        setIsFetching(false);
      });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    init();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Box
        px={2}
        pt={3}
        pb={1}
        component={Paper}
        display={'flex'}
        flexDirection={'column'}
        className={classes.container}
      >
        {error ? (
          <Error init={init} />
        ) : (
          <>
            <Grid container alignItems="center" spacing={2}>
              {enrollmentLink && (
                <Grid item container sm={12} md={6}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: 14, color: '#707070' }}>
                      Link de Inscrição
                    </Typography>
                  </Grid>
                  <Grid item xs={true}>
                    <Input input={{ ...register('link') }} disabled />
                  </Grid>
                  <Grid item xs={false}>
                    <Button
                      className={`${classes.button} ${classes.confirmButton}`}
                      onClick={handleCopyLink}
                    >
                      Copiar link
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid item container sm={12} md={enrollmentLink ? 6 : 12}>
                <Grid item xs={12} container alignItems="center">
                  <Typography style={{ fontSize: 14, color: '#707070' }}>
                    Definir chave de Acesso
                  </Typography>
                  <Tooltip
                    title={<TooltipHTML message={tooltipMessage} />}
                    placement="right-start"
                    PopperProps={{ style: { backgroundColor: 'none' } }}
                    TransitionProps={{
                      style: { backgroundColor: 'transparent' }
                    }}
                  >
                    <InfoRounded color="secondary" className={classes.icon} />
                  </Tooltip>
                </Grid>
                <Grid item xs={true}>
                  <Input input={{ ...register('key') }} />
                </Grid>
                <Grid item xs={false}>
                  <Button
                    className={`${classes.button} ${classes.confirmButton}`}
                    onClick={handleCopyCode}
                    disabled={Boolean(!enrollmentLink)}
                  >
                    Copiar Chave
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container justifyContent="flex-end">
                {enrollmentLink && (
                  <Grid item>
                    <Button
                      onClick={handleRemoveLink}
                      className={`${classes.button} ${classes.removeButton}`}
                      disabled={isFetching}
                    >
                      Excluir Link de Inscrição
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    className={`${classes.button} ${classes.confirmButton}`}
                    type="submit"
                    disabled={isFetching}
                  >
                    {enrollmentLink ? 'Salvar alterações' : 'Criar link'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
}

EnrollmentLinkForm.propTypes = {
  structureID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  get: PropTypes.func,
  create: PropTypes.func,
  enrollmentLinkService: PropTypes.shape({
    update: PropTypes.func,
    remove: PropTypes.func
  }),
  tooltipMessage: PropTypes.string
};

EnrollmentLinkForm.defaultProps = {
  structureID: undefined,
  get: async () => {},
  create: async () => {},
  enrollmentLinkService: PropTypes.shape({
    update: async () => {},
    remove: async () => {}
  }),
  tooltipMessage: ''
};
