import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Years } from '~/constants/years';
import i18n from '~/I18n';

import ReactHookFormSelect from '../../ReactHookFormSelect';
import ReactHookFormTextField from '../../ReactHookFormTextField';
import { useStyles } from '../styles';

const HighSchool = ({ control, education, index }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={`${classes.column} ${classes.mainForm}`}>
      <Box className={classes.row}>
        <Controller
          name={`educations.${index}.educationable.institution`}
          control={control}
          defaultValue={education.educationable.institution}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.institution`}
              label={i18n.t('HighSchoolInstitution')}
              defaultValue={education.educationable.institution}
              {...field}
            />
          )}
        />
        <ReactHookFormSelect
          id={`educations.${index}.educationable.end_year`}
          name={`educations.${index}.educationable.end_year`}
          label={i18n.t('HighSchoolEndYear')}
          control={control}
          values={Years}
          defaultValue={education.educationable.end_year}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box className={classes.row}>
        <Controller
          defaultValue={education.educationable.description}
          name={`educations.${index}.educationable.description`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.description`}
              label={i18n.t('Description')}
              defaultValue={education.educationable.description}
              {...field}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default HighSchool;

HighSchool.propTypes = {
  control: PropTypes.shape(),
  education: PropTypes.shape(),
  index: PropTypes.shape()
};

HighSchool.defaultProps = {
  control: null,
  education: null,
  index: null
};
