import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  field: {
    marginBottom: '25px',
  },
  tip: {
    margin: 0,
    color: '#888',
  },
  toggle: {
    cursor: 'pointer',
  },
  divider: { 
    backgroundColor: `${theme.palette.primary.main}`, 
    marginBottom: '15px' ,
  },
}));