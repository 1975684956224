import React from 'react';

import { EnvironmentIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import { useStyles } from './styles';
import { Paper, Box, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Proptypes from 'prop-types';

const PaperEnvironmentInformation = ({
  environment,
  setShowEnvironmentInformation
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { name, thumbnails, description, initials, path, tags } = environment;
  const image =
    thumbnails && thumbnails.length === 1
      ? thumbnails[0][1][0].href
      : thumbnails && thumbnails.length > 1 && thumbnails[1][0] !== 'missing'
      ? thumbnails[1][1][0].href
      : thumbnails[0][1][0].href;

  return (
    <Paper
      elevation={3}
      className={classes.root}
      classes={{ rounded: classes.paperRouded }}
    >
      <Box className={classes.closeIconContainer}>
        <CloseIcon
          style={{ fontSize: 20 }}
          className={classes.closeIcon}
          onClick={() => setShowEnvironmentInformation(false)}
        />
      </Box>
      <Box className={classes.name}>
        <Text color="gray" variant="largeText" style={{ fontWeight: 500 }}>
          {name}
        </Text>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.image}>
          {image ? (
            <img
              src={`${settings.baseUrl}${image}`}
              alt={name}
              className={classes.srcImg}
            />
          ) : (
            <EnvironmentIcon width="8rem" height="8rem" color="white" />
          )}
        </Box>
        <Box className={classes.details}>
          <Box className={classes.description}>
            <Text color="gray" variant="mediumText">
              {description || i18n.t('noDescription')}
            </Text>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text color="gray" variant="mediumText">
                {i18n.t('Abbreviation')}
              </Text>
            </Box>
            <Box className={classes.itemContent}>
              <Text color="gray" variant="mediumText">
                {initials || i18n.t('noAbbreviation')}
              </Text>
            </Box>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text color="gray" variant="mediumText">
                {i18n.t('link')}:
              </Text>
            </Box>
            <Box className={classes.itemContent}>
              <Text color="accent" variant="mediumText">
                {path || i18n.t('noLink')}
              </Text>
            </Box>
          </Box>
          <Box className={classes.detailsItem}>
            <Box className={classes.label}>
              <Text
                color="gray"
                variant="mediumText"
                style={{ marginTop: tags && tags.length > 0 ? 8 : 0 }}
              >
                {i18n.t('Keywords')}
              </Text>
            </Box>
            <Box className={classes.keyWords}>
              {tags && tags.length > 0 ? (
                <>
                  {tags.map((tag, index) => (
                    <Box className={classes.keyWordsItem} key={index}>
                      <Text color="gray" variant="mediumText">
                        {tag.name}
                      </Text>
                    </Box>
                  ))}
                </>
              ) : (
                <Text color="gray" variant="mediumText">
                  {i18n.t('noKeywords')}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

PaperEnvironmentInformation.propTypes = {
  environment: Proptypes.shape(),
  setShowEnvironmentInformation: Proptypes.func
};

PaperEnvironmentInformation.defaultProps = {
  environment: {},
  setShowEnvironmentInformation: null
};

export default PaperEnvironmentInformation;
