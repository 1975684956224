
import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CardsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '200px',
  maxHeight:'313px',
  minHeight:'315px',
  border: '1px solid rgba(0,0,0,0.2)',
  borderRadius: '5px',
});
