import React, { useEffect } from 'react';

import Avatar from '~/components/atoms/Avatar';
import MessageBubble from '~/components/atoms/MessageBubble';
// import { emitReadMessage, offReadMessage } from '~/services/websocket';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function MessageRow({
  text,
  sender,
  message,
  contactId,
  userId
}) {
  const classes = useStyles({ isMine: sender });

  useEffect(() => {
    const chatParams = {
      messageId: message.id,
      contactId: contactId,
      userId: userId
    };
    // emitReadMessage(chatParams);
    // return () => {
    //   offReadMessage(chatParams);
    // };
  }, []);

  return (
    <Box className={classes.container}>
      <MessageBubble text={text} sender={sender} />
    </Box>
  );
}

MessageRow.propTypes = {
  text: PropTypes.string,
  sender: PropTypes.string,
  showAvatar: PropTypes.bool
};

MessageRow.defaultProps = {
  text: '',
  sender: 'me',
  showAvatar: false
};
