import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { Container } from './styles';

export default function ProfileUsers() {
	const { login } = useParams();
	const [ profileUser, setProfileUser ] = useState(null);

	useEffect(
		() => {
			async function getProfileUser() {
				try {
					const response = await api.get(`/api/users/${login}`);
					setProfileUser(response.data);
				} catch (error) {}
			}

			getProfileUser();
		},
		[ login ]
	);

	return (
		<Container>
			<h1>{profileUser && profileUser.first_name}</h1>
		</Container>
	);
}
