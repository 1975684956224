import React from 'react';

import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export default function TextContent() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.containerTitleLabel}>
        <Typography className={classes.titleLabel} style={{ fontSize: 40 }}>
          {i18n.t('Login.Title')}
        </Typography>
      </Box>

      <Box className={classes.descriptionContainer}>
        <Typography className={classes.descriptionText}>
          {i18n.t('Login.Subtitle')}
        </Typography>
      </Box>
    </>
  );
}
