import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 350,
    textAlign: 'center',
    margin: 8
  },
  containerIcon: {
    display: 'flex',
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100
  },
  icon: {
    color: 'white',
    fontSize: 60
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    marginTop: 15,
    fontSize: 20,
    fontWeight: 'bold'
  },
  message: {
    fontSize: 16,
    color: theme.palette.textActiveElement.main
  },
  containerActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginBottom: 15
  },
  closeButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 12
  },
  confirmButton: {
    color: theme.palette.primary.main,
    fontSize: 12
  }
}));
