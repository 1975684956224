import React, { useEffect, useState } from 'react';

import DisplayOptions from '~/components/templates/DisplayOptions';
import Page from '~/components/templates/Page';
import i18n from '~/I18n';

import Accessibility from './Accessibility';
import MinimumRequirements from './MinimumRequirements';
import PrivacyPolicy from './PrivacyPolicy';
import { useStyles } from './styles';
import UsageTerms from './UsageTerms';
import { useTheme } from '@material-ui/core';

export default function PrivacyPolicyAndUsageTerms() {
  const theme = useTheme();
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(0);

  const optionNames = [
    i18n.t('PrivacyPolicy'),
    i18n.t('TermsOfUse'),
    i18n.t('Accessibility'),
    i18n.t('MinimumRequirements')
  ];

  useEffect(() => {
    document.title = `${theme.title} | Política e privacidade`;
  }, []);

  return (
    <Page>
      <div className={classes.root}>
        <div className={classes.sideBar}>
          <DisplayOptions
            optionNames={optionNames}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            tabSpacing="center"
          />
        </div>
        <div className={classes.content}>
          {selectedOption === 0 ? (
            <PrivacyPolicy />
          ) : selectedOption === 1 ? (
            <UsageTerms />
          ) : selectedOption === 2 ? (
            <Accessibility />
          ) : (
            <MinimumRequirements />
          )}
        </div>
      </div>
    </Page>
  );
}
