import React from 'react';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Paper, Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function OptionsModal({ items, setShow }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  function handleClickOption(event, option) {
    event.stopPropagation();
    event.preventDefault();
    option.action();
    setShow(false);
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Box className={classes.optionList}>
        {items.map((option, index) => (
          <button
            key={index}
            onClick={(event) => handleClickOption(event, option)}
            className={classes.option}
          >
            <Text variant="text" color="gray">
              {option.label}
            </Text>
          </button>
        ))}
      </Box>
    </Paper>
  );
}

OptionsModal.propTypes = {
  items: PropTypes.array,
  setShow: PropTypes.func
};

OptionsModal.defaultProps = {
  items: [],
  setShow: null
};
