import React from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import Alternative from '../Alternative';
import { useStyles } from './styles';
import { Divider, Grid, Hidden, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export default function Question({ question, editFunc, deleteFunc }) {
  const theme = useTheme();
  const classes = useStyles();

  const getLetter = (index) => {
    const code = 'A'.charCodeAt(0) + index;
    return String.fromCharCode(code);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={9} md={10}>
        <Grid container>
          <Grid item xs={12}>
            <Text style={{ textAlign: 'justify' }} color="primary">
              {' '}
              {`${question.position}. ${question.statement.replace(
                /<[^>]+>/g,
                ''
              )}`}{' '}
            </Text>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: '15px' }}>
            <Text
              color="primary"
              variant="smallText"
            >{`(Peso: ${question.weight})`}</Text>
          </Grid>

          <Grid item xs={12}>
            {question.alternatives?.map((item, index) => {
              if (!item._destroy) item.letter = getLetter(index);
              return !item._destroy ? (
                <Alternative alternative={item} showQuestion />
              ) : null;
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={3} md={2} className={classes.actions}>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={() => editFunc(question)}
          >
            <IconWrapper
              customColor={theme.palette.primary.main}
              fontSize={20}
              Icon={EditIcon}
            />
          </Grid>

          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={() => deleteFunc(question)}
          >
            <IconWrapper
              customColor={theme.palette.primary.main}
              fontSize={20}
              Icon={DeleteIcon}
            />
          </Grid>
        </Grid>
      </Grid>

      <Hidden smUp>
        <Grid xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Hidden>
    </Grid>
  );
}
