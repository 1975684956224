import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress } from '@material-ui/core';

const useStylesFacebook = makeStyles(theme => ({
  top: {
    color: 'gray',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
    color: 'white',
  },
}));

export const Container = styled(({ height, ...otherProps }) => (
  <Box {...otherProps} style={{ minHeight: height }} md={7} />
))``;

export const PlusButton = styled(({ textColor, color, ...otherProps }) => (
  <Button
    {...otherProps}
    style={{
      color: textColor,
      fontSize: 11,
      fontWeight: 'bold',
      height: 40,
      minWidth: 40,
      width: 40,
      borderRadius: 5,
      backgroundColor: color,
      borderColor: textColor,
      padding: 0,
      textTransform: 'capitalize',
    }}
  />
))``;

export const ContaienrButtonPlus = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    width={1 / 2}
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 30,
    }}
  />
))``;

export const ContainerMuralItem = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    style={{
      marginTop: 3,
      marginBottom: 3,
    }}
  />
))``;

export const ActivityIndicator = styled(({ ...otherProps }) => {
  const classes = useStylesFacebook();

  return (
    <CircularProgress
      classes={{
        circle: classes.circle,
      }}
      size={20}
      {...otherProps}
    />
  );
})``;
