import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  cardSkeleton: {
    marginTop: 40,
    borderRadius: 5,
    width: 230,
    height: 190
  }
});
