import React, { useEffect, useState } from 'react';

import { Skeleton } from '@material-ui/lab';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import { Content } from '../styles';
import CertificatesBox from './CertificatesBox';

export default function Certificates({ profile }) {
  const shimmerList = Array(4).fill(null);
  const [certificatesList, setCertificatesList] = useState([]);

  useEffect(() => {
    if (profile) {
      api
        .get(
          `api/enrollments/users/${profile.id}/get_user_courses_certificates`
        )
        .then((response) => {
          setCertificatesList(response.data);
        })
        .catch(console.log);
    }
  }, [profile?.id]);

  return (
    <Content>
      <Text variant="header" color="primary">
        {i18n.t('Certificates')}
      </Text>

      {certificatesList
        ? Object.keys(certificatesList).map((keyName) => (
            <CertificatesBox
              key={keyName}
              title={certificatesList[keyName].environment}
              courses={certificatesList[keyName].courses}
            />
          ))
        : shimmerList.map((keyName) => (
            <Skeleton
              variant="pulse"
              width="100%"
              height={50}
              style={{ margin: '5px 15px 5px 5px' }}
            />
          ))}
    </Content>
  );
}
