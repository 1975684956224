import api from '~/services/api';

import {
  getEnvironments as baseGetEnvironments,
  getCourses as baseGetCourses,
  getSpaces as baseGetSpaces
} from '..';

const baseURL = '/api/mirroring_list/subjects';

export const getEnvironments = async (page) => {
  const environmentsList = await baseGetEnvironments(baseURL, page);

  return environmentsList;
};

export const getCourses = async (environmentID, page) => {
  const coursesList = await baseGetCourses(baseURL, environmentID, page);

  return coursesList;
};

export const getSpaces = async (courseID, page) => {
  const spaceList = await baseGetSpaces(baseURL, courseID, page);

  return spaceList;
};

export const getSelf = async (spaceID, page) => {
  const { data } = await api.get(`${baseURL}?space_id=${spaceID}&page=${page}`);
  return data;
};

export default {
  getEnvironments,
  getCourses,
  getSpaces,
  getSelf
};
