import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    padding: '8px',
    gap: '12px',
    backgroundColor: '#F2F2F7'
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  titleText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    fontWeight: '500'
  },
  titleIcon: {
    fontSize: '24px',
    color: theme.palette.activeText.main
  },
  description: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
