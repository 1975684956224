import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';

import { useMediaQuery, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TableChartIcon from '@material-ui/icons/TableChart';

import i18n from '~/I18n';

import Text from '../Text';
import { useStyles } from './styles';

const FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const DragDropInput = (props) => {
  const {
    onDrop,
    handleFile,
    selectedFile,
    setSelectedFile,
    done,
    set
  } = props;

  const theme = useTheme();
  const classes = useStyles({ theme, done: done?.done });
  const [fileTypeError, setFileTypeError] = useState(false);
  const [show, setShow] = useState(false);
  const isTablet = useMediaQuery('@media (max-width: 768px)');
  const handleSelectedFile = (e) => {
    const file = e.target.files[0];
    if (file.type === FILE_TYPE) {
      handleFile(e);
      setFileTypeError(false);
    } else {
      setFileTypeError(true);
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
      set({ done: false, sent: false });
      setFileTypeError(false);
      setSelectedFile(null);
    };
  }, []);

  const Message = () => (
    <div className={classes.dragDropInput__input_successMessage}>
      {done?.done ? (
        <Text
          textAlign="center"
          fontWeight="bold"
          variant="text"
          color="secondary"
        >
          Você receberá mais informações sobre a importação em seu e-mail.{' '}
        </Text>
      ) : (
        <Text
          textAlign="center"
          fontWeight="bold"
          variant="error"
          style={{ fontSize: 18 }}
          color="secondary"
        >
          Ocorreu um erro. <br />
          Por favor tente novamente.
        </Text>
      )}
    </div>
  );
  const MobileSelectInput = () => {
    return (
      <>
        {selectedFile && (
          <div className={classes.dragDropInput__fileName}>
            <TableChartIcon
              style={{ fontSize: 40, color: theme.palette.secondary.main }}
            />
            <Text variant="largeText" color="gray">
              {selectedFile.name}
            </Text>
          </div>
        )}
        <SelectInput />
        {done?.sent && <Message />}
      </>
    );
  };

  const SelectInput = ({ selectOtherFile }) => (
    <div className={classes.dragDropInput__wraper}>
      <label htmlFor="files" className={classes.dragDropInput__input}>
        {done?.done ? (
          <CheckCircleIcon style={{ color: 'white', fontSize: 35 }} />
        ) : (
          ''
        )}
        <Text
          textAlign="center"
          fontWeight="bold"
          variant="text"
          color={done?.done ? 'white' : 'secondary'}
        >
          {done?.done
            ? 'Enviado'
            : selectOtherFile
              ? 'Trocar arquivo'
              : 'Selecionar arquivo'}
        </Text>
      </label>
      <input
        disabled={done?.done ? true : false}
        id="files"
        style={{ visibility: 'hidden' }}
        type="file"
        name="excel"
        accept=".xlsx"
        onChange={handleSelectedFile}
      />
    </div>
  );
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          if (item.files[0].type === FILE_TYPE) {
            onDrop(item);
            setFileTypeError(false);
          } else {
            setSelectedFile(null);
            setFileTypeError(true);
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [props]
  );
  const isActive = canDrop && isOver;
  return !isTablet
    ? show && (
      <div ref={drop} className={classes.dragDropInput}>
        {isActive ? (
          <div className={classes.dragDropInput__alert}>
            <Text variant="lg" color="gray">
              {i18n.t('DropFileHere')}
            </Text>
          </div>
        ) : (
          <>
            {fileTypeError && (
              <Text
                style={{ marginBottom: 20 }}
                textAlign="center"
                variant="largeText"
                color="error"
              >
                {i18n.t('PleaseSelectAValidFile')}
              </Text>
            )}
            {selectedFile ? (
              <div className={classes.dragDropInput__fileSelected}>
                <div className={classes.dragDropInput__fileName}>
                  <TableChartIcon
                    style={{
                      fontSize: 40,
                      color: theme.palette.secondary.main
                    }}
                  />
                  <Text variant="largeText" color="gray">
                    {selectedFile.name}
                  </Text>
                </div>
                <SelectInput selectOtherFile />
                {done?.sent && <Message />}
              </div>
            ) : (
              <>
                <div className={classes.dragDropInput__alert}>
                  <Text textAlign="center" variant="lg" color="gray">
                    {i18n.t('DragFileToThisField')}
                  </Text>
                </div>
                <SelectInput />
                {done?.sent && <Message />}
              </>
            )}
          </>
        )}
      </div>
    )
    : show && (
      <div className={classes.wrapSelectMobile}>
        {fileTypeError && (
          <Text
            style={{ marginBottom: 20 }}
            textAlign="center"
            variant="largeText"
            color="error"
          >
            {i18n.t('PleaseSelectAValidFile')}
          </Text>
        )}
        <MobileSelectInput />
      </div>
    );
};

export default DragDropInput;
