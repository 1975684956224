/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import { Content } from '../styles';
import MyProfileContacts from './contactsItems/MyProfileContactsItem';
import UsersProfileContacts from './contactsItems/ProfileContactsItem/index';
import ContactsModal from './ContactsModal';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';

export default function ContactsProfile({
  userId,
  otherUsers,
  loggedInUserId,
  goToContactsSection
}) {
  const [loading, setLoading] = useState(true);
  const [profileContacts, setProfileContacts] = useState([]);
  const [usersContacts, setUsersContacts] = useState([]);

  const page = 1;

  async function getMyContacts() {
    api
      .get(`api/connections/mine`, {
        params: {
          page: page,
          status: 'accepted'
        }
      })
      .then((response) => {
        setProfileContacts(response.data);
        setLoading(false);
        goToContactsSection();
      })
      .catch(() => {
        setLoading(false);
        goToContactsSection();
      });
  }
  async function getUsersContacts() {
    api
      .get(`api/users/${userId}/friends_of_another_user`, {
        params: {
          page: page
        }
      })
      .then((response) => {
        setUsersContacts(response.data);
        setLoading(false);
        goToContactsSection();
      })
      .catch(() => {
        setLoading(false);
        goToContactsSection();
      });
  }

  useEffect(() => {
    if (!otherUsers) getUsersContacts();
    if (userId && loggedInUserId) getMyContacts();
    return () => (
      setLoading(true), setProfileContacts([]), setUsersContacts([])
    );
  }, [otherUsers, loggedInUserId, userId]);

  return (
    <Content>
      <Text variant="header" color="primary">
        {i18n.t('Contacts')}
      </Text>
      <Grid container spacing={2}>
        {!otherUsers
          ? usersContacts.slice(0, 6).map((item) => {
              return <UsersProfileContacts key={item.id} item={item} />;
            })
          : profileContacts.map((item) => {
              return <MyProfileContacts key={item.id} item={item} />;
            })}
        {loading ? (
          <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex' }}>
              <Skeleton
                variant="circle"
                width={50}
                height={50}
                style={{ margin: '5px 15px 5px 5px' }}
              />
              <Skeleton
                variant="rect"
                width="75%"
                height={50}
                style={{ overflow: 'hidden' }}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
      {profileContacts.length > 6 || usersContacts.length > 6 ? (
        <ContactsModal
          loggedInUserId={loggedInUserId}
          otherUsers={otherUsers}
          userId={userId}
          goToContactsSection={goToContactsSection}
        />
      ) : null}
    </Content>
  );
}
