import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import settings from '~/settings';

export default function AboutText() {
  const theme = useTheme();
  const client = settings.name;

  const CustomTextElement = ({ children, bold, marginBottom }) => (
    <Typography
      style={{
        fontSize: '16px',
        lineHeight: '24px',
        color: theme.palette.textActiveElement.main,
        fontWeight: bold ? 'bold' : 'normal',
        marginBottom: marginBottom ? '16px' : '0'
      }}
    >
      {children}
    </Typography>
  );

  CustomTextElement.propTypes = {
    children: PropTypes.node,
    bold: PropTypes.bool,
    marginBottom: PropTypes.bool
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: '48px' }}
      paddingX="32px"
    >
      <Typography
        style={{
          color: theme.palette.primary.main,
          fontSize: '40px',
          fontWeight: '700',
          lineHeight: '48px'
        }}
      >
        Conhecendo um pouco mais sobre a {client}
      </Typography>
      <Box>
        <CustomTextElement marginBottom>
          Na nossa plataforma, nossa missão é clara: facilitar o acesso a
          informações e serviços que enriqueçam a vida das pessoas. Desde o
          início, temos nos dedicado a criar uma experiência que seja não apenas
          útil, mas também acessível para todos.
        </CustomTextElement>
        <CustomTextElement bold>Quem somos?</CustomTextElement>
        <CustomTextElement marginBottom>
          Somos uma equipe apaixonada por tecnologia e comprometida em
          proporcionar soluções inovadoras. Nosso objetivo é criar um ambiente
          onde todos se sintam bem-vindos e capacitados a explorar, aprender e
          crescer.
        </CustomTextElement>
        <CustomTextElement marginBottom>
          Nossa plataforma oferece uma variedade de recursos e funcionalidades,
          desde ferramentas de busca até serviços de comunicação e
          entretenimento. Trabalhamos incansavelmente para garantir que cada
          aspecto da nossa plataforma seja projetado com a máxima atenção aos
          detalhes, visando proporcionar uma experiência excepcional aos nossos
          usuários.
        </CustomTextElement>
        <CustomTextElement marginBottom>
          Valorizamos a privacidade e a segurança dos nossos usuários acima de
          tudo. Nunca compartilhamos informações pessoais sem consentimento e
          estamos constantemente aprimorando nossos sistemas para garantir a
          proteção dos dados de todos os que confiam em nós.
        </CustomTextElement>
        <CustomTextElement bold>Somos uma comunidade</CustomTextElement>
        <CustomTextElement marginBottom>
          Na nossa plataforma, você encontrará uma comunidade diversificada e
          inclusiva, onde todas as vozes são ouvidas e respeitadas. Estamos
          comprometidos em promover a igualdade e a diversidade em tudo o que
          fazemos, criando um ambiente onde todos possam se sentir representados
          e valorizados.
        </CustomTextElement>
        <CustomTextElement>
          Em resumo, somos mais do que uma plataforma. Somos uma comunidade
          dedicada a tornar o mundo um lugar melhor, um clique de cada vez.
          Junte-se a nós enquanto continuamos a criar e a inovar, moldando o
          futuro da tecnologia e da conectividade para todos.
        </CustomTextElement>
      </Box>
    </Box>
  );
}
