import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import EnvironmentForm from '~/components/organisms/EnvironmentForm';
import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { environmentValidations } from '~/utils/EnvironmentValidation/yup';

import { createEnvironment } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CreateEnvironment() {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues = {
    new_avatar_attributes: null,
    description: '',
    keywords: [],
    initials: '',
    name: '',
    state: '',
    regional_group: '',
    city: ''
  };

  const navOptions = [i18n.t('Information')];
  const successMessage = `O ${i18n.t(
    'StructuresName.Environment.SingularLower'
  )} foi criado!`;

  const breadcrumbs = [
    { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
    { name: i18n.t('CreateEnvironment'), path: '/create-environment' }
  ];

  useEffect(() => {
    document.title = `${theme.title} | Criar ${i18n.t(
      'StructuresName.Environment.SingularLower'
    )}`;
  }, []);

  function renderContent(props) {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <>
          <GenericFormWrapper
            Form={EnvironmentForm}
            defaultValues={defaultValues}
            resolver={environmentValidations}
            isSubmitting={isSubmitting}
            {...props}
          />
          ;
        </>
      );
    }

    return false;
  }

  function handleCancel() {
    history.push('/dashboard/environments');
  }

  const finalizeSuccess = (response) => {
    const callbackSuccessPath = `/dashboard/environments/${response.data.id}`;
    setSubmissionStatus('success');
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = () => {
    alert(
      `Não foi possível criar o ${i18n.t(
        'StructuresName.Environment.SingularLower'
      )}!`
    );
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    createEnvironment(fields)
      .then(finalizeSuccess)
      .catch(finalizeFailure)
      .finally(() => setIsSubmitting(false));
  }

  return (
    <StepperForm
      navOptions={navOptions}
      breadcrumbs={breadcrumbs}
      onSubmit={submitForm}
      formTitle={i18n.t('CreateEnvironment')}
      submitButtonTitle={i18n.t('CreateEnvironment')}
      handleCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}
