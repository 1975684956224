import React, { useEffect, useState } from 'react';

import { getAllLectures } from '~/services/Subject';

import LectureItem from '../LectureItem';
import { useStyles } from './styles';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
  useTheme,
  Box,
  CircularProgress,
  List
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

export const SubjectItem = ({
  subject,
  currentLectureID,
  currentSubjectID
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { name, id } = subject;
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedLectures, setLoadedLectures] = useState(false);

  const fetchLectures = async () => {
    try {
      const response = await getAllLectures(id);
      setLectures(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setLoadedLectures(true);
    }
  };

  useEffect(() => {
    if (currentSubjectID === id) fetchLectures();
  }, [currentSubjectID]);

  return (
    <Accordion
      style={{ boxShadow: 'none' }}
      defaultExpanded={id === currentSubjectID}
      classes={{ root: classes.accordionRoot }}
      onChange={(_, expanded) => {
        if (expanded && !loadedLectures) {
          setLoading(true);
          fetchLectures();
        }
      }}
    >
      <AccordionSummary
        style={{
          padding: '0px'
        }}
        expandIcon={<ExpandMoreIcon className={classes.accordionSummaryIcon} />}
      >
        <Typography className={classes.accordionSummaryText}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px' }}>
        {loading ? (
          <Box className={classes.accordionDetailsLoadingBox}>
            <CircularProgress
              size={24}
              style={{ color: theme.palette.activeText.main }}
            />
          </Box>
        ) : (
          <List className={classes.listStyle}>
            {lectures.map((item) => (
              <LectureItem
                key={item.id}
                lecture={item}
                current={item.id === currentLectureID}
              />
            ))}

            {loading && (
              <CircularProgress
                size={32}
                style={{ color: theme.palette.activeText.main }}
              />
            )}

            {!loading && lectures.length === 0 && (
              <Typography className={classes.emptyLecturesListText}>
                Nenhuma mídia disponível
              </Typography>
            )}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

SubjectItem.propTypes = {
  subject: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  currentLectureID: PropTypes.number.isRequired,
  currentSubjectID: PropTypes.number.isRequired
};
