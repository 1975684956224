import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  showAnswersButton: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#A8B1CE',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  replayIcon: {
    fontSize: '20px',
    color: '#454954',
    marginRight: '4px'
  },
  replayText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#454954',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }
});
