import React, { useState } from 'react';

import { useStyles } from './styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import PropTypes from 'prop-types';

export const DisplayDescription = ({ description }) => {
  const classes = useStyles();
  const [displayFullDescription, setDisplayFullDescription] = useState(false);

  if (!description)
    return <Typography className={classes.text}>Sem descrição</Typography>;
  else if (description.length < 400) {
    return <Typography className={classes.text}>{`${description}`}</Typography>;
  } else {
    return (
      <Box style={{ overflowY: 'scroll' }}>
        {!displayFullDescription ? (
          <Box>
            <Typography className={classes.text}>
              {`${description.substring(0, 400)}...`}
            </Typography>
            <ButtonBase
              onClick={() => setDisplayFullDescription(true)}
              className={classes.buttonContainer}
            >
              <Typography className={classes.buttonText}>Ver mais</Typography>
              <ExpandMoreRoundedIcon className={classes.buttonIcon} />
            </ButtonBase>
          </Box>
        ) : (
          <Box>
            <Typography className={classes.text}>{description}</Typography>
            <ButtonBase
              disableRipple
              onClick={() => setDisplayFullDescription(false)}
              className={classes.buttonContainer}
            >
              <Typography className={classes.buttonText}>Ver menos</Typography>
              <ExpandLessRoundedIcon className={classes.buttonIcon} />
            </ButtonBase>
          </Box>
        )}
      </Box>
    );
  }
};

DisplayDescription.propTypes = {
  description: PropTypes.string.isRequired
};
