import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const SUBJECT_URL = 'subjects/';

export const hasManageAccessToSubject = async (subjectID) =>
  await hasManageAccessTo(`${SUBJECT_URL}${subjectID}`);
export const hasReadAccessToSubject = async (subjectID) =>
  await hasReadAccessTo(`${SUBJECT_URL}${subjectID}`);
export const hasUpdateAccessToSubject = async (subjectID) =>
  await hasCustomAccessTo(`${SUBJECT_URL}${subjectID}`, 'update');
