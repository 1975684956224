import React from 'react';

import girlImage from '~/assets/images/girl-sitting-on-books-with-laptop-by-oblik-studio.png';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { useMediaQuery } from '@material-ui/core';

export default function TourModal1() {
  const classes = useStyles();
  const isMobile = useMediaQuery('@media (max-width: 600px)');

  return (
    <div className={classes.content}>
      <div className={classes.imageContainer}>
        <img src={girlImage} alt="girl sitting on books with laptop" />
      </div>
      <div className={classes.textContainer}>
        <Text color="primary" variant={isMobile ? 'lg' : 'xl'}>
          {i18n.t('Tour.Welcome')}
        </Text>
        <Text color="gray" variant={isMobile ? 'text' : 'lg'}>
          {i18n.t('Tour.About')}
        </Text>
        {/* <Text color="gray" variant={isMobile ? 'text' : 'lg'}>
          {i18n.t('Tour.KnowMore')}
        </Text> */}
      </div>
    </div>
  );
}
