import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import ContactUsForm from '~/components/organisms/ContactUsForm';
import DisplayOptions from '~/components/templates/DisplayOptions';
import Page from '~/components/templates/Page';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export default function Help() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [selectedOption, setSelectedOption] = useState(0);

  const optionNames = [i18n.t('ContactUs')];

  const RenderSelectedContent = () => {
    if (selectedOption === 0) return <ContactUsForm />;
    return (
      <Text variant="largeText" color="gray">
        {i18n.t('ContentNotAvailableYet')}
      </Text>
    );
  };

  useEffect(() => {
    document.title = `${theme.title} | Ajuda`;
  }, []);

  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.sidebar}>
          <DisplayOptions
            optionNames={optionNames}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
        <Box className={classes.main}>
          <Box className={classes.header}>
            <DashboardBreadcrumbs
              links={[{ name: i18n.t('ContactUs'), path: '#' }]}
            />
            <Box className={classes.title}>
              <InfoIcon className={classes.infoIcon} />
              <Text
                color="primary"
                variant="xl"
                style={{ letterSpacing: 1, fontWeight: 500 }}
              >
                {i18n.t('ContactUs')}
              </Text>
            </Box>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.tabBar}>
              <DisplayOptions
                optionNames={optionNames}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </Box>
            <Box className={classes.scrollBox}>
              <Box className={classes.selectedContent}>
                <RenderSelectedContent />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}
