import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: '-80px',
    height: '100vh'
  },
  contactsDrawer: {
    display: 'flex',
    boxShadow: '0px 3px 6px #00000029',
    width: '30%',
    maxWidth: 360,
    height: 'calc(100vh - 76px)',
    marginTop: '62px',
    backgroundColor: 'white',
    transition: 'transform .2s',
    zIndex: 1000,
    overflowY: 'auto',
    '@media (max-width: 1024px)': {
      width: '35%'
    },
    '@media (max-width: 900px)': {
      width: '45%'
    },
    '@media (max-width: 600px)': {
      width: '90vw',
      position: 'absolute',
      transform: (props) =>
        !props.openDrawer ? 'translateX(calc(30px - 90vw))' : 'translateX(0px)'
    }
  },
  contactsContent: {
    flex: '0 0 auto',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    padding: 20
  }
});
