import React, { useState } from 'react';

import ChatContactInfo from '~/components/organisms/ChatContactInfo';
import Chat from '~/components/organisms/MessagesChat';
import ConversationList from '~/components/organisms/MessagesConversationList';

import { useStyles } from './styles';
import { Paper, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function MessagesModal ({ open, setProfilePageContact }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [curChat, setCurChat] = useState(-1);
  const [openInfo, setOpenInfo] = useState(false);

  function returnToList () {
    if (setProfilePageContact) setProfilePageContact(false);
    setCurChat(-1);
  }

  const setContact = (contact) => {
    setCurChat(contact);
  };

  function handleOpenInfo () {
    setOpenInfo(true);
  }

  if (!open) return false;

  return (
    <Paper elevation={3} className={classes.root}>
      {curChat === -1 ? (
        <ConversationList setCurChat={setContact} curChat={curChat} />
      ) : openInfo ? (
        <ChatContactInfo
          open={openInfo}
          handleClose={() => setOpenInfo(false)}
          contact={curChat}
          isModal
        />
      ) : (
        <Chat
          isModal
          returnToList={returnToList}
          modalHandleOpenInfo={handleOpenInfo}
          curChat={curChat}
          setCurChat={setContact}
        />
      )}
    </Paper>
  );
}

MessagesModal.propTypes = {
  open: PropTypes.bool,
  openMessages: PropTypes.bool,
  profilePageContact: PropTypes.object,
  setProfilePageContact: PropTypes.func
};

MessagesModal.defaultProps = {
  open: false,
  openMessages: false,
  profilePageContact: null,
  setProfilePageContact: null
};
