import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    width: '100%',
    flex: '1 1 auto',
    flexDirection: 'column',
    paddingTop: '90px',
    paddingRight: '24px'
  },
  title: {
    marginBottom: '24px',
    fontSize: '32px',
    fontWeight: '500',
    color: theme.palette.primary.main
  },
  tableContent: {
    maxWidth: 'min-content',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'scroll'
  },
  paginationCaption: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  paginationButtonSvg: {
    '& button': {
      '&:disabled': {
        '& span': {
          '& svg': {
            color: '#B4BDD3'
          }
        }
      },

      '& span': {
        '& svg': {
          fontSize: '24px',
          color: theme.palette.activeText.main
        }
      }
    }
  }
}));
