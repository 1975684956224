import React from 'react';
import i18n  from '~/I18n';

import { useTheme }   from '@material-ui/core';
import { Alert }      from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { CloseIcon }  from '~/components/Welcome/styles';

import { useStyles } from './styles';

export default function FormAlert({ setShowAlert }) {
  const theme   = useTheme()
  const classes = useStyles({ theme });

  return (
    <Alert
      severity="warning"
      variant="filled"
      classes={{ filledWarning: classes.alert }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="medium"
          onClick={() => {
            setShowAlert(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {i18n.t('AllQuestionsMustBeAnswered')}
    </Alert>
  );
}
