import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  sidebar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1300px)': {
      paddingLeft: 24
    },
    '@media (max-width: 600px)': {
      justifyContent: 'flex-start',
      marginBottom: 10,
      paddingLeft: 0
    }
  },
  sidebarContent: {
    cursor: 'pointer',
    marginTop: '50%',
    '@media (max-width: 600px)': {
      width: '95%',
      paddingTop: 0,
      marginTop:'10px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  textContent: {
    marginBottom: 8
  }
});
