import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  directionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 5,
    width: '100%',
    justifyContent: 'space-between'
  },
  directionLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  directionLinkText: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
    color: theme.palette.primary.main
  },
  icon: {
    fontSize: '24px',
    color: theme.palette.primary.main
  }
}));
