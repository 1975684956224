import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingRight: 25,
    paddingBottom: 50,
    marginTop: 30,
    overflow: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 865px)': {
      // maxHeight: 'calc(100vh - 300px)',
      rowGap: 15
    },
    '@media (max-width: 300px)': {
      paddingBottom: 100
    },
    gap: 30
  }
});
