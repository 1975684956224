import React, { useEffect } from 'react';

import RecoveryPasswordForm from '~/components/organisms/RecoveryPasswordForm';
import GridInitialPages from '~/components/templates/GridInitialPages';
import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';
import { handleSuccess } from '~/utils/auxiliaryFunctions';

import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { SubmissionError } from 'redux-form';

export default function RecoverPassword() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  function handleSubmit(values) {
    return api
      .get(`api/recover_password?email=${values.email}`)
      .then(() => {
        handleSuccess(
          'Redefinição de senha enviada',
          enqueueSnackbar,
          '/',
          history,
          5000
        );
      })
      .catch(() => {
        throw new SubmissionError({ _error: 'Email não encontrado.' });
      });
  }

  useEffect(() => {
    document.title = `${theme.title} | Recuperar senha`;
  }, []);

  return (
    <GridInitialPages
      fontSizeTitle={22}
      title={i18n.t('RecoverPassword.Subtitle')}
      subtitle={i18n.t('RecoverPassword.Title')}
      imageBg={theme.initialPagesImageBgRecover}
      widthLeftImage="58%"
      logoLabel={i18n.t('ForgotPassword')}
      form={() => {
        return <RecoveryPasswordForm onSubmit={handleSubmit} />;
      }}
    />
  );
}
