const validation = (values) => {
  const shortPassword =
    'Senha muito curta, na senha deve conter no mínimo 6 caracteres';
  const longPassword =
    'Senha muito longa, a senha deve ter no máximo 50 caracteres';
  const errors = {};

  if (!values['newPassword']) {
    errors['newPassword'] = 'Campo obrigatório';
  } else if (values['newPassword']) {
    if (values['newPassword'].length < 6) {
      errors['newPassword'] = shortPassword;
    }
    if (values['newPassword'].length > 50) {
      errors['newPassword'] = longPassword;
    }
  }

  if (!values['newPasswordConfirmation']) {
    errors['newPasswordConfirmation'] = 'Campo obrigatório';
  } else if (values['newPasswordConfirmation']) {
    if (values['newPasswordConfirmation'].length < 6) {
      errors['newPasswordConfirmation'] = shortPassword;
    }
    if (values['newPasswordConfirmation'].length > 50) {
      errors['newPasswordConfirmation'] = longPassword;
    }
  }

  if (values['newPasswordConfirmation']) {
    if (values['newPassword'] !== values['newPasswordConfirmation']) {
      errors['newPasswordConfirmation'] = 'Senha diferentes';
    }
  }

  if (!values['captcharesponse']) {
    errors['captcharesponse'] = 'Recaptcha inválido';
  }

  return errors;
};

export default validation;
