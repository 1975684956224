import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import { DatePickerInputForm } from '~/components/molecules/DatePickerInputForm';
import Image from '~/components/molecules/Image';
import ImageCertificateLogoClient from '~/components/molecules/ImageCertificateLogo';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';
import settings from '~settings';

import Field from '../../GenericFormWrapper/Field';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { Box, Collapse, Grid, Switch } from '@material-ui/core';

export default function CertificateInfo() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { watch, getValues, setValue } = useFormContext();

  const [signatureUrl, setSignatureUrl] = useState(null);
  const [certificateLogoClientUrl, setCertificateLogoUrl] = useState(null);

  const isCoursePeriod = getValues('is_course_period');
  const signaturePath = getValues('signature');
  const certificateLogoClientPath = getValues('certificate_logo_client');

  const existSignature =
    signaturePath !== '/assets/missing_courses_original.png' &&
    signaturePath !== null;

  const existCertificateLogoClient =
    certificateLogoClientPath !== '/assets/missing_courses_original.png' &&
    certificateLogoClientPath !== null;

  const [signatureLoadError, setSignatureLoadError] = useState(false);

  const [
    certificateLogoClientLoadError,
    setCertificateLogoClientLoadError
  ] = useState(false);

  useEffect(() => {
    if (signatureUrl == null && signaturePath) {
      setSignatureUrl(`${settings?.baseUrl}${signaturePath}`);
    }
    if (certificateLogoClientUrl == null && certificateLogoClientPath) {
      setCertificateLogoUrl(`${settings?.baseUrl}${certificateLogoClientPath}`);
    }
  }, [signaturePath, certificateLogoClientPath]);

  const createOnchange = (maxLenght, fieldName) => {
    return (e) => {
      const value = e.target.value;
      if (value.length <= maxLenght) {
        setValue(fieldName, value);
      }
    };
  };

  return (
    <div>
      <Field
        label={TextPrimary('Habilitar geração de certificado')}
        name="generate_certificate"
        labelPlacement={'end'}
        checked={watch('generate_certificate')}
        Component={Switch}
      />

      <Collapse in={watch('generate_certificate')}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Field
              label={<Text color={'gray'}>{'Nome do responsável'}</Text>}
              name="responsible_for_signing"
              placeholder="Insira o nome do responsável pela assinatura"
              Component={Input}
              onChange={createOnchange(55, 'responsible_for_signing')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="signature_owner_position"
              label={<Text color={'gray'}>{'Cargo do responsável'}</Text>}
              placeholder="Insira o cargo do responsável pela assinatura"
              Component={Input}
              onChange={createOnchange(85, 'signature_owner_position')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="signature_owner_organization"
              label={<Text color={'gray'}>{'Nome da organização'}</Text>}
              placeholder="Insira o nome da organização"
              Component={Input}
              onChange={createOnchange(85, 'signature_owner_organization')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="minimum_rate_for_completion"
              label={
                <Text color={'gray'}>
                  {'Percentual mínimo para certificado'}
                </Text>
              }
              placeholder={`Insira a porcentagem mínima de ${i18n.t(
                'StructuresName.Lectures.PluralLower'
              )} assistidas para a geração do certificado`}
              Component={Input}
              type="number"
            />
          </Grid>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Field
              label={TextPrimary('Habilitar/desabilitar período do curso')}
              name="is_course_period"
              labelPlacement={'end'}
              checked={watch('is_course_period')}
              Component={Switch}
            />
            <Grid item xs={12} sm={6}>
              {isCoursePeriod && (
                <DatePickerInputForm
                  placeholder="Ex: 30/04/2020"
                  name="course_start_date"
                  dataType="início"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {isCoursePeriod && (
                <DatePickerInputForm
                  placeholder="Ex: 30/04/2021"
                  name="course_end_date"
                  dataType="término"
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.wrapperImages}>
              <Box className={classes.wrapperImageInput}>
                <Field
                  classes={classes}
                  name="signature"
                  label={
                    <Text color="gray">{'Assinatura do responsável'}</Text>
                  }
                  id="signature-image-input"
                  Component={Image}
                />
              </Box>
              {existSignature && !signatureLoadError && (
                <Box className={classes.wapperCertificateLogo}>
                  <Text color="gray">{'Imagem atual de assinatura'}</Text>
                  <img
                    src={signatureUrl}
                    className={classes.image}
                    onError={() => {
                      setValue('signature', null, { shouldValidate: false });
                      setSignatureLoadError(true);
                    }}
                    onLoad={() => {
                      setSignatureLoadError(false);
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.wrapperImages}>
              <Box className={classes.wrapperImageInput}>
                <Field
                  classes={classes}
                  name="certificate_logo_client"
                  label={<Text color="gray">{'Logo para o certificado'}</Text>}
                  id="certificate-logo-client-image-input"
                  Component={() => <ImageCertificateLogoClient />}
                />
              </Box>
              {existCertificateLogoClient && !certificateLogoClientLoadError && (
                <Box className={classes.wapperCertificateLogo}>
                  <Text color="gray">
                    {'Imagem atual de logo para o certificado'}
                  </Text>
                  <img
                    src={certificateLogoClientUrl}
                    className={classes.image}
                    onError={() => {
                      setValue('certificate_logo_client', null);
                      setCertificateLogoClientLoadError(true);
                    }}
                    onLoad={() => {
                      setCertificateLogoClientLoadError(false);
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}
