import React, { useEffect, useRef, useState } from "react";

import GetAppIcon                 from '@material-ui/icons/GetApp';
import Text                       from "~/components/atoms/Text";

import { QRCodeCanvas }           from "qrcode.react";
import { Button, Grid, Hidden } from "@material-ui/core";

import { IconWrapper } from "~/utils/forms";
import { useStyles }   from "./styles";

export default function QRCode({ QRCodeLink }) {
  const qrRef   = useRef();
  const classes = useStyles()
  
  const [size, setSize] = useState(300)

  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    if ( window.innerHeight < 740 ) {
      setSize(320)
    } else {
      setSize(410)
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container justifyContent="center" ref={qrRef} xs={12}>
        <Hidden smUp>
          <QRCodeCanvas
            id="qrCode"
            value={QRCodeLink}
            bgColor={"white"}
            size={210}
          />
        </Hidden>
        <Hidden xsDown>
          <QRCodeCanvas
            id="qrCode"
            value={QRCodeLink}
            bgColor={"white"}
            size={size}
          />
        </Hidden>
        
      </Grid>
      
      <Grid item xs={12}>
        <Button classes={{ root: classes.downloadButton }} color="white" onClick={downloadQRCode}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <Text variant={"mediumText"} style={{ margin: 0 }}> Download QR Code </Text>
            </Grid>
            <Grid item>
              <IconWrapper Icon={GetAppIcon} customColor="white" fontSize={18}/>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};
