import { makeStyles } from '@material-ui/core';

const rowHeight = '55px';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '10% 76% 14%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '10% 73% 17%'
    },
    height: rowHeight,
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: '#F5F5F5'
  }
}));
