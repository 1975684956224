import React from 'react';

import Text from '~/components/atoms/Text';

import { ListItemCustom, useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ListItem({ text, children }) {
  const classes = useStyles();
  return (
    <ListItemCustom>
      {children}
      <Box className={classes.textMenuItem}>
        <Text
          variant="text"
          color="white"
          fontWeight={500}
          style={{ fontSize: '16px' }}
        >
          {text}
        </Text>
      </Box>
    </ListItemCustom>
  );
}

ListItem.propTypes = {
  text: PropTypes.string,
  children: PropTypes.shape()
};

ListItem.defaultProps = {
  text: '',
  children: null
};
