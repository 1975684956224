import React from 'react';

import { useStyles } from './styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export const FeedbackLoading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress size={24} className={classes.circularProgress} />
      <Typography className={classes.text}>Carregando...</Typography>
    </Box>
  );
};
