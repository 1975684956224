import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  volumeIcon: {
    transform: 'scale(1.8)',
    color: theme.palette.activeText.main
  },

  sliderRoot: {
    color: theme.palette.secondary.main,
    height: 4,
    width: '15rem'
  },
  sliderThumb: {
    display: 'none'
  },
  sliderTrack: {
    height: 4,
    borderRadius: 4
  },
  sliderRail: {
    height: 4,
    borderRadius: 4
  }
}));