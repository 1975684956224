import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ReactHookFormController } from '~/components/atoms/ReactHookFormController';
import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import { editMemberScheme } from '~/form-validations/edit-member-validation';
import { editUser } from '~/services/environment';
import { Creators as UserActions } from '~/store/ducks/user';

import * as S from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

export const EditUser = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  let history = useHistory();

  const structureID = new URLSearchParams(window.location.search).get(
    'structureID'
  );
  const memberID = new URLSearchParams(window.location.search).get('memberID');
  let { member_id } = useParams();

  const { getMemberRequest, clearMemberData } = UserActions;
  const { member, isLoadingEditUser } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(editMemberScheme)
  });

  const delayGoBack = () => {
    setTimeout(() => {
      history.goBack();
    }, 2000);
  };

  const successMessage = () => {
    enqueueSnackbar('Os dados foram atualizados!', {
      variant: 'success',
      autoHideDuration: 3000
    });
    delayGoBack();
  };
  const failedMessage = (error, readonly) => {
    let messages = [];

    if (error?.response?.status === 401) {
      if (readonly) {
        messages = ['Você não tem autorização para ler os dados do usuário'];
      } else {
        messages = ['Você não tem autorização para efetuar esta ação'];
      }
      delayGoBack();
    }
    if (!readonly && error?.response?.status !== 401) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, errorMessages] of Object.entries(error?.response?.data)) {
        messages.push(...errorMessages);
      }

      if (messages.length === 0) {
        messages = ['Os dados não foram atualizados!'];
      }
    }

    messages.forEach((message) => {
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 3000
      });
    });
  };
  const onSubmit = async (data) => {
    try {
      await editUser(structureID, memberID, data);
      successMessage();
    } catch (e) {
      failedMessage(e);
    }
  };

  let back = (e) => {
    e.stopPropagation();
    history.goBack();
  };
  useEffect(() => {
    return () => {
      dispatch(clearMemberData(member_id));
    };
  }, []);

  useEffect(() => {
    if (member) {
      setValue('login', member?.login);
      setValue('first_name', member?.first_name);
      setValue('last_name', member?.last_name);
      setValue('email', member?.email);
    }
  }, [member]);

  useEffect(() => {
    dispatch(getMemberRequest(member_id, failedMessage));
  }, [dispatch, getMemberRequest, member_id]);

  return (
    <S.ModalEditMember open={true}>
      <S.Wrapper>
        <S.TouchableDiv onClick={back} />
        <S.Header>
          <Text
            variant="xl"
            color="primary"
            style={{ letterSpacing: 1, fontWeight: 500 }}
          >
            Editar membro do ambiente
          </Text>
          <S.CloseButton onClick={back}>
            <CloseIcon fontSize="large" color="primary" />
          </S.CloseButton>
        </S.Header>
        <S.EditUserForm onSubmit={handleSubmit(onSubmit)}>
          <ReactHookFormController
            name="login"
            error={errors?.login?.message}
            control={control}
            Component={(props) => <Input label="Login" {...props} />}
          />
          <ReactHookFormController
            name="first_name"
            error={errors?.first_name?.message}
            control={control}
            Component={(props) => <Input label="Nome" {...props} />}
          />
          <ReactHookFormController
            name="last_name"
            error={errors?.last_name?.message}
            control={control}
            Component={(props) => <Input label="Sobrenome" {...props} />}
          />
          <ReactHookFormController
            name="email"
            error={errors?.email?.message}
            control={control}
            Component={(props) => <Input label="Email" {...props} />}
          />
          <ReactHookFormController
            name="password"
            error={errors?.password?.message}
            control={control}
            Component={(props) => (
              <Input label="Senha" type={'password'} {...props} />
            )}
          />
          <S.SendButton
            type="submit"
            variant="contained"
            disabled={isLoadingEditUser}
            style={{ fontSize: 18 }}
            colorPrimary={theme.palette.primary.main}
            colorSecondary={theme.palette.secondary.main}
          >
            {isLoadingEditUser ? <CircularProgress /> : 'ENVIAR'}
          </S.SendButton>
        </S.EditUserForm>
      </S.Wrapper>
    </S.ModalEditMember>
  );
};
