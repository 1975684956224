// This function constructs a URL with query parameters based on a base URL and a parameters object
export function buildUrlWithParams(urlBase, params = {}) {
  // Builds the query string from the `params` object
  const queryString = Object.entries(params)
    .map(
      // Each key-value pair is encoded and joined by '='
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&'); // Pairs are joined together with '&' to form the complete query string

  // Checks if the base URL already contains parameters
  const separator = urlBase.includes('?') ? '&' : '?';
  // Constructs and returns the complete URL by appending the query string to the base URL
  return `${urlBase}${separator}${queryString}`;
}
