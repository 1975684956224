import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '100%'
  },
  userName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#fff'
  },
  userLogin: {
    fontSize: '14px',
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff'
  },
  iconBox: {
    borderRadius: '50%',
    backgroundColor: '#F2F2F7',
    padding: '8px',
    width: '35px',
    height: '35px'
  }
});

export const FeedbackLoading = () => {
  const theme = useTheme();
  return (
    <>
      <Skeleton
        variant="circle"
        animation="pulse"
        style={{
          backgroundColor: theme.palette.shimmer.main,
          width: '50px',
          height: '40px'
        }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={40}
        animation="pulse"
        style={{
          backgroundColor: theme.palette.shimmer.main
        }}
      />
    </>
  );
};
