import auth from './auth';
import courses from './courses';
import environments from './environments';
import following from './following';
import lectures from './lectures';
import members from './members';
import mywall from './my-wall';
import overview from './overview';
import spaces from './spaces';
import transferMembers from './transferMembers';
import user from './user';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  auth,
  user,
  overview,
  environments,
  lectures,
  spaces,
  courses,
  transferMembers,
  following,
  members,
  mywall,
  form: formReducer
});
