import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const ContentContext = createContext();

export const ContentProvider = ({
  subject,
  setSubject,
  lectures,
  setLectures,
  children
}) => {
  return (
    <ContentContext.Provider
      value={{ subject, setSubject, lectures, setLectures }}
    >
      {children}
    </ContentContext.Provider>
  );
};

ContentProvider.propTypes = {
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  lectures: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLectures: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export const useContentContext = () => {
  return useContext(ContentContext);
};
