import React from 'react';
import { useLocation } from 'react-router-dom';

import Callback from './Callback';
import SebraeButton from './SebraeButton';

export default function SebraeLogin() {
  const code = new URLSearchParams(useLocation().search).get('code');
  const token = new URLSearchParams(useLocation().search).get('t');

  return (
    <>
      {code && <Callback code={code} courseToken={token} />}
      <SebraeButton />
    </>
  );
}
