import React, { useEffect, useRef } from 'react';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

const TextArea = ({ name, lecture, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);
  const theme = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, []);

  return (
    <Box className={classes.Container}>
      <textarea
        className={classes.TextArea}
        placeholder={
          lecture
            ? 'Compartilhe algo no mural'
            : 'Compartilhe algo ao seu mural'
        }
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Box>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rest: PropTypes.shape(),
  lecture: PropTypes.bool,
  caractersLeft: PropTypes.number
};

TextArea.defaultProps = {
  name: '',
  label: '',
  rest: {},
  lecture: false,
  caractersLeft: 1600
};

export default TextArea;
