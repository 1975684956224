import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { higherEducationKind } from '~/constants/education';
import { Years } from '~/constants/years';
import i18n from '~/I18n';

import ReactHookFormSelect from '../../ReactHookFormSelect';
import ReactHookFormTextField from '../../ReactHookFormTextField';
import { useStyles } from '../styles';

const HigherEducation = ({ control, education, index }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const kindNames = higherEducationKind.map((r) => r.name);

  return (
    <Box className={`${classes.column} ${classes.mainForm}`}>
      <Box className={classes.row}>
        <Controller
          name={`educations.${index}.educationable.institution`}
          control={control}
          defaultValue={education.educationable.institution}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.institution`}
              label={i18n.t('HighSchoolInstitution')}
              defaultValue={education.educationable.institution}
              {...field}
            />
          )}
        />

        <ReactHookFormSelect
          label={i18n.t('HigherEducationKind')}
          placeholder={i18n.t('HigherEducationKindPlaceholder')}
          values={kindNames}
          id={`educations.${index}.educationable.kind`}
          name={`educations.${index}.educationable.kind`}
          control={control}
          defaultValue={education.educationable.kind}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box className={classes.row}>
        <Controller
          defaultValue={education.educationable.research_area}
          name={`educations.${index}.educationable.research_area`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.research_area`}
              label={i18n.t('HigherEducationArea')}
              defaultValue={education.educationable.research_area}
              {...field}
            />
          )}
        />

        <Controller
          defaultValue={education.educationable.course}
          name={`educations.${index}.educationable.course`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.course`}
              label={i18n.t('Course')}
              defaultValue={education.educationable.course}
              {...field}
            />
          )}
        />
      </Box>
      <Box className={classes.row}>
        <ReactHookFormSelect
          id={`educations.${index}.educationable.start_year`}
          name={`educations.${index}.educationable.start_year`}
          label={i18n.t('HigherEducationStartYear')}
          control={control}
          values={Years}
          defaultValue={education.educationable.start_year}
          variant="outlined"
          margin="normal"
        />

        <ReactHookFormSelect
          id={`educations.${index}.educationable.end_year`}
          name={`educations.${index}.educationable.end_year`}
          label={i18n.t('HighSchoolEndYear')}
          control={control}
          values={Years}
          defaultValue={education.educationable.end_year}
          variant="outlined"
          margin="normal"
        />
      </Box>
      <Box className={classes.row}>
        <Controller
          defaultValue={education.educationable.description}
          name={`educations.${index}.educationable.description`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.description`}
              label={i18n.t('Description')}
              defaultValue={education.educationable.description}
              {...field}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default HigherEducation;

HigherEducation.propTypes = {
  control: PropTypes.shape(),
  education: PropTypes.shape(),
  index: PropTypes.shape()
};

HigherEducation.defaultProps = {
  control: null,
  education: null,
  index: null
};
