import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';

const Sidebar = ({
  contentIsSelected,
  setContentIsSelected,
  contentClassificationIsSelected,
  setContentClassificationIsSelected,
  users,
  handleGetUsers
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarContent}>
        <Text
          color={contentIsSelected ? 'primary' : 'gray'}
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setContentIsSelected(true);
            setContentClassificationIsSelected(false);
          }}
        >
          {i18n.t('Content')}
        </Text>
        <Text
          color={
            !contentIsSelected && !contentClassificationIsSelected
              ? 'primary'
              : 'gray'
          }
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setContentIsSelected(false);
            setContentClassificationIsSelected(false);
            if (!users) handleGetUsers();
          }}
        >
          {i18n.t('Members')}
        </Text>
        <Text
          color={
            !contentIsSelected && !!contentClassificationIsSelected
              ? 'primary'
              : 'gray'
          }
          variant="environmentSidebarText"
          style={{ marginBottom: 15 }}
          onClick={() => {
            setContentClassificationIsSelected(true);
            setContentIsSelected(false);
          }}
        >
          Classificação
        </Text>
      </Box>
    </Box>
  );
};

Sidebar.propTypes = {
  contentIsSelected: PropTypes.bool,
  setContentIsSelected: PropTypes.func,
  contentClassificationIsSelected: PropTypes.bool,
  setContentClassificationIsSelected: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape()),
  handleGetUsers: PropTypes.func
};

Sidebar.defaultProps = {
  contentIsSelected: false,
  setContentIsSelected: null,
  contentClassificationIsSelected: false,
  setContentClassificationIsSelected: null,
  users: [],
  handleGetUsers: null
};

export default Sidebar;
