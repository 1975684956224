import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import Alternative from './Alternative';
import { useStyles } from './styles';
import { Box, RadioGroup } from '@material-ui/core';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

export default function Question({ question, questionIndex }) {
  const { statement, options } = question;
  const classes = useStyles();
  const [editorID, setEditorID] = useState(uniqid());

  function getQuestionNumber() {
    return questionIndex + 1 < 10 ? `0${questionIndex + 1}` : questionIndex + 1;
  }

  useEffect(() => {
    Editor.create(
      document.querySelector(`#document-editor__editable-${editorID}`)
    )
      .then((instance) => {
        instance.enableReadOnlyMode('editor');
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!question) return null;
  return (
    <Box className={classes.root}>
      <Text variant="lg" color="gray">{`QUESTÃO ${getQuestionNumber()}`}</Text>
      <Box className={classes.statement}>
        <Box
          style={{
            fontSize: '12px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}
        >
          <div
            id={`document-editor__editable-${editorID}`}
            className={classes.imgData}
            dangerouslySetInnerHTML={{
              __html: `<div>${statement}</div>`
            }}
          />
        </Box>
      </Box>
      <RadioGroup
        className={classes.options}
        aria-label={`question${questionIndex + 1}`}
        name={`question${questionIndex + 1}`}
      >
        {options.map((option, altIndex) => (
          <Alternative key={altIndex} alternative={option} />
        ))}
      </RadioGroup>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.shape(),
  questionIndex: PropTypes.number
};

Question.defaultProps = {
  question: {},
  questionIndex: -1
};
