import React from 'react';

import Skeleton       from '@material-ui/lab/Skeleton';
import styled         from 'styled-components';

export const ShimmerText = styled(({ color, height, width, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    height={height}
    animation="pulse"
    width={width}
    style={{
      backgroundColor: (props) => `${props.theme.palette.shimmer.main}`
    }}
  />
))``;
