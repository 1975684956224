import {useTheme } from '@material-ui/core';
import * as S from './style';
 import React from 'react';
 import ButtonSquare from '~/components/atoms/ButtonSquare';
 import Text from '~/components/atoms/Text';
 import i18n from '~/I18n';

 export const ReportMemberUpBox = ({ result, openExerciseForm }) => {
     const theme = useTheme();

     return(
        <S.ButtonsContainer>
           <ButtonSquare 
               backgroundColor={`${theme.palette.secondary.main}BF`}
               marginRight={20}
               onClick={openExerciseForm}         
               >
               <Text variant="subtitle" color="white" >
                   { result ? i18n.t('Review') : i18n.t('Fazer Questionário') }
               </Text>
           </ButtonSquare>
        </S.ButtonsContainer>
   )
 };

 export default ReportMemberUpBox;