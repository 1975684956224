import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CircularProgressBar from '~/components/atoms/CircularProgressBar';
import Text from '~/components/atoms/Text';
import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import i18n from '~/I18n';
import { unlink } from '~/services/mirroring/subject';
import { getSubjects } from '~/services/Space';
import { findByID } from '~/services/Subject';

import { useContentContext } from '../../contexts/ContentContext';
import { useSpacePageContext } from '../../contexts/SpacePageContext';
import { useStyles } from './styles';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

// É preciso refatorar este componente para usar adequadamente os contextos criados
export default function SubjectsSelect() {
  const theme = useTheme();
  const classes = useStyles({ theme: theme });

  const subjectIDParam = new URLSearchParams(useLocation().search).get(
    'subjectID'
  );

  const { subject, setSubject } = useContentContext();
  const {
    space: { id: spaceID, course_id: courseID, environment_id: environmentID }
  } = useSpacePageContext();

  const { enqueueSnackbar } = useSnackbar();
  const [subjects, setSubjects] = useState([]);
  const [hasMoreSubjects, setHasMoreSubjects] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [
    showConfirmationUnlinkModal,
    setShowConfirmationUnlinkModal
  ] = useState(false);

  const linkList = [
    {
      link: `/dashboard/environments/${environmentID}/courses/${courseID}/spaces/${spaceID}/subjects/${subject?.id}/edit`,
      Icon: EditIcon,
      text: i18n.t('EditSubject.title'),
      disabled: false
    },
    subject?.can_unlink && {
      Icon: LinkOffIcon,
      text: 'Desvincular espelho',
      action: (e) => {
        e.preventDefault();
        setShowConfirmationUnlinkModal(true);
      },
      disabled: false
    }
  ].filter(Boolean);

  const handleSubjectChange = (event) => {
    setSubject(subjects.find((e) => e.id === event.target.value));
  };

  const loadMoreSubjects = (e) => {
    if (isFetching || !hasMoreSubjects) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;

    if (bottom) {
      setIsFetching(true);
      getSubjects(spaceID, page + 1)
        .then((subjects) => {
          setSubjects((prev) => [...prev, ...subjects]);

          setHasMoreSubjects(subjects.length >= 10);
          setPage((page) => page + 1);
          setIsFetching(false);
        })
        .catch((error) => {
          console.log(error);
          setIsFetching(false);
        });
    }
  };

  const unlinkSubject = async () => {
    try {
      await unlink(subject.id);
      enqueueSnackbar('A estrutura foi desvinculada com sucesso.', {
        variant: 'success'
      });
      window.location.reload();
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível concluir a ação solicitada, por favor tente novamente mais tarde.',
        { variant: 'error' }
      );
    }
    setShowConfirmationUnlinkModal(false);
  };

  useEffect(() => {
    getSubjects(spaceID)
      .then((subjects) => {
        if (subjectIDParam) {
          findByID(subjectIDParam)
            .then((subj) => {
              setSubject({
                id: subj.id,
                name: subj.name,
                can_manage: subj.can_manage
              });
              setSubjects([subject, subj, ...subjects]);
            })
            .catch(console.log);
        } else {
          setSubjects([subject, ...subjects]);
        }
      })
      .catch(console.log);
  }, [spaceID]);

  return (
    <>
      <Box className={classes.selectContainer}>
        <FormControl
          variant="outlined"
          classes={{
            root: classes.formControl
          }}
        >
          <div className={classes.subjectTitleHelper}>
            <Text
              title={subject?.name}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
              variant="title"
              color="primary"
            >
              {subject?.name}
            </Text>

            {subject && subject.can_manage && subject.id !== -1 && (
              <>
                <Text variant="hashtag" color="hashtag">
                  |
                </Text>
                <PopUpMenu linkList={linkList} />
              </>
            )}
          </div>
          <Select
            value={subject?.id}
            onChange={(event) => handleSubjectChange(event)}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              PaperProps: {
                onScroll: loadMoreSubjects
              },
              style: {
                maxHeight: 500
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              classes: { paper: classes.selectMenu },
              disableScrollLock: true
            }}
            classes={{
              root: classes.select,
              icon: classes.selectIcon
            }}
          >
            {subjects.map((subject, index) => (
              <MenuItem
                className={classes.selectOption}
                key={index}
                aria-label={subject.name}
                value={subject.id}
              >
                {subject.name}
              </MenuItem>
            ))}
            {isFetching && (
              <Skeleton
                varaint="rect"
                width={300}
                height={50}
                style={{ marginLeft: 6 }}
              />
            )}
          </Select>
        </FormControl>

        {subject && subject.id !== -1 && !subject.can_manage && (
          <div className={classes.progressWraper}>
            <Text variant="text" color="primary">
              Concluído
            </Text>
            <CircularProgressBar size={50} value={subject.user_completion} />
          </div>
        )}
      </Box>
      <ConfirmationDialog
        show={showConfirmationUnlinkModal}
        handleClose={() => setShowConfirmationUnlinkModal(false)}
        message={
          <>
            <p style={{ marginBottom: '16px' }}>
              Ao desvincular um espelho, ele perderá o vínculo com o item
              original e não receberá mais nenhuma atualização. Essa ação é{' '}
              <strong>irreversível</strong>.{' '}
            </p>
            <p>Tem certeza de que deseja desvincular esse espelho?</p>
          </>
        }
        handleConfirm={unlinkSubject}
        confirmButtonText="Desvincular"
        titleText={'Desvincular'}
        titleIcon={
          <LinkOffIcon
            style={{
              alignSelf: 'center',
              fontSize: '24px',
              color: theme.palette.primary.main
            }}
          />
        }
        colorVariant={'primary'}
      />
    </>
  );
}
