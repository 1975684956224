import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F2F2F7',
    gap: '8px',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '32px 16px 24px 24px',
    flex: 1,
    borderRadius: '8px',
    backgroundColor: theme.palette.textAndBackground.main,
    gap: '24px',
    height: '100%',
    overflow: 'scroll',
    minWidth: '300px'
  },
  infomations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflow: 'hidden'
  },
  descriptionContainer: {
    display: 'flex',
    overflow: 'scroll',
    gap: '8px'
  },
  title: {
    fontSize: '19px',
    lineHeight: '24px',
    fontWeight: '700',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    color: theme.palette.activeText.main
  },
  eventDate: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  goToMeetLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 16px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    gap: '8px',
    width: 'fit-content',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  },
  linkText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.textAndBackground.main
  }
}));
