import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (props) => makeStyles((theme) => ({
  textArea: {
    width: props.width,
    height: props.height,
    padding: '1rem',
    fontSize: '14px',
    resize: props.resize,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.textActiveElement.main}66`,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&::placeholder': {
      color: `${theme.palette.activeText.main}66`
    },
    '&:-moz-placeholder': {
      ccolor: `${theme.palette.activeText.main}66`
    },
    '&::-moz-placeholder': {
      color: `${theme.palette.activeText.main}66`
    },
    '&::-webkit-input-placeholder': {
      ccolor: `${theme.palette.activeText.main}66`
    },
    '&:-ms-input-placeholder': {
      color: `${theme.palette.activeText.main}66`
    }
  }
}));
