import React from 'react';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import i18n from '~/I18n';

export default function ModalContent({ handleClose, username }) {
  const classes = useStyles();

  return (
    <>
      <Box px={4} py={5} className={classes.modalContent}>
        <Text
          style={{ textAlign: 'right', cursor: 'pointer' }}
          color="primary"
          variant="subtitle"
          fontWeight={'bold'}
          onClick={handleClose}
        >
          X
        </Text>

        <Text
          style={{ textAlign: 'center' }}
          color="primary"
          variant="subtitle"
          fontWeight={'bold'}
        >
          Como inserir vídeos do Vimeo a partir do ID
        </Text>

        <Text color="primary">
          Olá, <span style={{ fontWeight: 'bold' }}>{username}!</span>
        </Text>

        <Text color="primary">
          Para inserir vídeos da Vimeo na plataforma{' '}
          <span style={{ fontWeight: 'bold' }}>Redu</span> através do ID, basta
          preencher o campo "ID do Vídeo" com a numeração que aparece no link do
          vídeo no Vimeo.
        </Text>
        <Text color="primary">
          Por exemplo: para inserir o vídeo{' '}
          <a
            href="https://vimeo.com/288344114"
            style={{
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#65bbe0'
            }}
          >
            https://vimeo.com/288344114
          </a>{' '}
          , basta preencher o campo "ID do Vídeo" com o número{' '}
          <span style={{ fontWeight: 'bold' }}>288344114</span>.
        </Text>
        <Text color="primary">
          Pronto! Depois basta clicar em "Criar {`${i18n.t('StructuresName.Lectures.SingularUpper')}`}" e a {`${i18n.t('StructuresName.Lectures.SingularLower')}`} será criada com
          o seu vídeo. Fácil, né!?
        </Text>

        <Text style={{ color: 'red' }} fontWeight="bold">
          Importante!
        </Text>
        <Text color="primary">
          Para que o vídeo seja exibido em nossa plataforma é necessário que ele
          seja <span style={{ fontWeight: 'bold' }}>público</span>.
        </Text>
        <Text color="primary" variant="smallText">
          Se o vídeo faz parte da sua conta no Vimeo e não é público, navegue
          até a página onde seus vídeos são exibidos e altere a configuração de
          privacidade do vídeo que você deseja inserir na nossa plataforma.
        </Text>
      </Box>
    </>
  );
}
