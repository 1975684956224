import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  textHeader: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  exitButton: {
    fontSize: 28,
    cursor: 'pointer',
    color: '#676d82'
  },

  formBox: {
    overflow: 'auto',
    paddingRight: '4px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '4px'
    }
  },

  userLimitReachedWarning: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    borderRadius: 4,
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 32
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  label: {
    color: '#676D82',
    fontSize: '14px',
    fontWeight: 400
  },
  input: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '8px'
  },

  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#F5F5F5',
      filter: 'saturate(180%)'
    }
  },

  addButton: {
    width: '190px',
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: '#fff'
    }
  }
}));
