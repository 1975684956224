import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import Alternative from './Alternative';
import { useStyles } from './styles';
import { Box, RadioGroup } from '@material-ui/core';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

export default function Question({
  question,
  questionIndex,
  answerQuestion,
  curAnswer,
  submitted,
  result,
  showCorrectAnswer
}) {
  const { statement, id } = question;

  const alternatives = question.alternatives || question.options;
  const classes = useStyles();
  const [editorID, setEditorID] = useState(uniqid());

  function getQuestionNumber() {
    return questionIndex + 1 < 10 ? `0${questionIndex + 1}` : questionIndex + 1;
  }

  function checkAlternative(alternative) {
    answerQuestion(id, alternative);
  }

  useEffect(() => {
    Editor.create(
      document.querySelector(`#document-editor__editable-${editorID}`)
    )
      .then((instance) => {
        instance.enableReadOnlyMode('editor');
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!question) return null;
  return (
    <Box className={classes.root}>
      <Text variant="lg" color="gray">{`QUESTÃO ${getQuestionNumber()}`}</Text>
      <Box className={classes.statement}>
        <Box
          style={{
            fontSize: '12px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}
        >
          <div
            id={`document-editor__editable-${editorID}`}
            className={classes.imgData}
            dangerouslySetInnerHTML={{
              __html: `<div>
              ${statement}
            </div>`
            }}
          />
        </Box>
      </Box>
      <RadioGroup
        className={classes.alternatives}
        aria-label={`question${questionIndex + 1}`}
        name={`question${questionIndex + 1}`}
      >
        {alternatives.map((alternative, altIndex) => (
          <Alternative
            key={altIndex}
            alternative={alternative}
            checkAlternative={checkAlternative}
            checked={curAnswer === alternative.id}
            showCorrectAnswer={showCorrectAnswer ? alternative.correct : false}
            submitted={submitted}
            correctId={submitted ? result?.correct_alternative : -1}
            chosenId={submitted ? result?.option.id : -1}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.shape(),
  questionIndex: PropTypes.number,
  answerQuestion: PropTypes.func,
  curAnswer: PropTypes.number,
  submitted: PropTypes.bool,
  result: PropTypes.shape()
};

Question.defaultProps = {
  question: {},
  questionIndex: -1,
  answerQuestion: null,
  curAnswer: -1,
  submitted: false,
  result: null
};
