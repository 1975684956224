import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  textHeader: {
    fontSize: 24,
    color: theme.palette.activeText.main,
    fontWeight: 500
  },
  exitButton: {
    fontSize: 28,
    cursor: 'pointer',
    color: '#676d82'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '8px'
  },
  cancelButton: {
    display: 'flex',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.activeText.main,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: '#1A72F9',
      backgroundColor: '#F5F5F5'
    },
    '&:active': {
      color: '#0056B4',
      backgroundColor: '#E1E1E5'
    }
  },
  confirmButton: {
    width: '88px',
    display: 'flex',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    minWidth: 'min-content'
  },

  primaryColor: {
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main
    }
  },

  dangerColor: {
    background: '#CB410B',
    '&:hover': {
      backgroundColor: '#F34D0C'
    },
    '&:active': {
      backgroundColor: '#AB3000'
    }
  }
}));