import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.textActiveElement.main
  },
  active: {
    color: theme.palette.hiperlinkActiveText.main,
    fontWeight: 'bold',
    fontSize: 18,
    backgroundColor: 'cyan'
  },
  textWrapper: {
    margin: '10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  avatarWrapper: {
    marginRight: '10px'
  },
  contactInfo: {
    fontSize: '16px',
    marginTop: '2px',
    flex: 1
  },
  lastMessage: {
    fontSize: '12px',
    marginTop: '2px',
    flex: 1,
    color: theme.palette.textActiveElement.main
  },
  optionButton: {
    minWidth: '10px'
  },
  badge: {
    fontSize: 10,
    color: 'white'
  }
}));
