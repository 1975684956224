import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        position: "absolute",
        right: 0
    },
    option: {
        padding: "5px 10px",
        "& p:hover": {
            color: props => props.theme.palette.primary.main
        }
    }
});