import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    marginBottom: '40px'
  },
  visible: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-8px' 
  },
  name: {
    fontSize: '35px',
    color: '#c9c9cc'
  },
});
