import React from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';
import { TextPrimary } from '~/utils/forms';

import Field from '../../GenericFormWrapper/Field';
import { Grid } from '@material-ui/core';
import i18n from '~/I18n';

export default function Informations() {
  const { control } = useFormContext();
  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={8} sm={5} md={4}>
        <Field
          label={TextPrimary('Sigla')}
          name="initials"
          control={control}
          placeholder="Digite a sigla..."
          Component={Input}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={7}>
        <Field
          label={TextPrimary('Objetivos e Programa')}
          name="description"
          control={control}
          multiline
          placeholder={`Informações acerca do ${i18n.t('StructuresName.Subjects.SingularLower')} e suas ${i18n.t('StructuresName.Lectures.PluralLower')}...`}
          Component={Input}
        />
      </Grid>
    </Grid>
  );
}
