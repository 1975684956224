import React from 'react';
import NumberFormat from 'react-number-format';

export default function InputMask({ format = 'workload', ...otherProps }) {
  // predefined formats
  const formats = { workload: '###', mobileNumber: '+55 (##) #####-####' };
  // select current format based on props passed
  const currentFormat = formats[format];
  return <NumberFormat {...otherProps} format={currentFormat} />;
}
