import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '10px'
  }
}));
