import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '~/components/atoms/Avatar';
import ButtonCircle from '~/components/atoms/ButtonCircle';
import { SearchMenuIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import { socket } from '~/socket';
import { store } from '~/store';

import { Logo, useStyles } from './styles';
import { AppBar, Toolbar, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export default function AppBarCustom({ setOpen }) {
  const { profile } = useSelector((state) => state.user);
  const classes = useStyles();
  const theme = useTheme();
  const { isLogged } = store.getState().auth;

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <AppBar className={classes.appbar} position="fixed">
      <Toolbar variant="dense" className={classes.ToolbarWraper}>
        {isLogged && (
          <Box className={classes.searchMenuIconContainer}>
            <ButtonCircle
              className={classes.resetPadding}
              size={44}
              onClick={setOpen}
            >
              <SearchMenuIcon className={classes.resetPadding} color="white" />
            </ButtonCircle>
          </Box>
        )}
        <Box className={classes.avatarLogoContainer}>
          <Box className={classes.avatarContainer}>
            <Link to="/dashboard">
              <Logo src={theme.logo} width={theme.size.appBarLogo.width} />
            </Link>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row">
            {profile && isLogged && <Avatar profile={profile} size={36} />}
            <Box className={classes.nameTextContainer}>
              <Text
                variant="appBarName"
                color="white"
                style={{ textTransform: 'capitalize' }}
              >
                {profile ? profile.first_name : ''}
              </Text>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

AppBarCustom.propTypes = {
  setOpen: PropTypes.func
};

AppBarCustom.defaultProps = {
  setOpen: PropTypes.func
};
