import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    marginBottom: '8px'
  },
  accordion: {
    margin: '0px',
    borderBottom: '1px solid #D9D9D9',
    '&.Mui-expanded': {
      borderBottom: '0px'
    },
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  accordionSummary: {
    padding: '0px',
    margin: '0px',
    minHeight: '0px',
    '&.Mui-expanded': {
      minHeight: '0px'
    }
  },
  accordionDetails: {
    padding: '0px',
    margin: '0px',
    marginBottom: '8px'
  },
  summaryContent: {
    margin: '0px',
    '&.Mui-expanded': {
      margin: '16px 0px'
    }
  },
  expandIcon: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    color: theme.palette.primary.main, // Cor do ícone
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px'
  }
}));
