import React from 'react';
import { Switch } from 'react-router-dom';

import Mural from '~/components/organisms/Mural';
import Contacts from '~/pages/dashboard/Contacts';
import Environments from '~/pages/dashboard/Environments';
import Main from '~/pages/dashboard/Main';
import Spaces from '~/pages/dashboard/Spaces';
import Subject from '~/pages/dashboard/Subject';
import Subjects from '~/pages/dashboard/Subjects';
import UsersContacts from '~/pages/dashboard/UsersContacts';
import Environment from '~/pages/Environment';
import Lecture from '~/pages/Lecture';
import MuralUsers from '~/pages/MuralUsers';
import ProfileUsers from '~/pages/ProfileUsers';
import Space from '~/pages/Space';

import Route from './Route';

export default function DashboardRoutes() {
  return (
    <Switch>
      <Switch>
        <Route
          exact
          path="/dashboard/contacts"
          component={Contacts}
          isPrivate
        />
        <Route exact path="/dashboard/mural" component={Mural} isPrivate />
        <Route exact path="/dashboard" component={Main} isPrivate />
        <Route
          path="/dashboard/profileUsers/:login"
          component={ProfileUsers}
          isPrivate
          exact
        />
        <Route
          path="/dashboard/muralUsers/:login"
          component={MuralUsers}
          isPrivate
          exact
        />
        <Route
          exact
          path="/dashboard/environments/:id"
          component={Environment}
          isPrivate
        />
        <Route exact path="/dashboard/spaces/:id" component={Space} isPrivate />
        <Route
          path="/dashboard/userContacts/:login"
          component={UsersContacts}
          isPrivate
          exact
        />
        <Route
          exact
          path="/dashboard/courses/:id/spaces"
          component={Spaces}
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments"
          component={Environments}
          isPrivate
        />
        <Route
          exact
          path="/dashboard/spaces/:id/subjects"
          component={Subjects}
          isPrivate
        />
        <Route
          exact
          path="/dashboard/subject/:id"
          component={Subject}
          isPrivate
        />
        <Route path="/dashboard/lecture/:id" component={Lecture} isPrivate />
      </Switch>
    </Switch>
  );
}