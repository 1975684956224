import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  muralDashboard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 0',
    overflow: 'scroll',
    position: 'relative',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `#B4BDD3 !important`
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `#A8B1CE !important`
    }
  },
  goToTopButton: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    padding: '12px',
    display: 'flex',
    gap: '8px',
    transition: 'width 0.4s ease-in-out, border-radius 1s ease-in-out',
    width: '48px',
    overflow: 'hidden',
    '&:hover': {
      borderRadius: '40px',
      width: '160px',
      transition: 'width 0.4s ease-in-out, border-radius 0.4s ease-in-out'
    },
    '&:hover > $goToTopButtonText': {
      display: 'block'
    }
  },
  goToTopButtonText: {
    display: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#fff',
    fontWeight: '500',
    textWrap: 'nowrap'
  },
  badge: {
    position: 'fixed',
    bottom: '16px',
    alignSelf: 'flex-end',
    '& .MuiBadge-badge': {
      fontSize: '14px'
    }
  }
}));
