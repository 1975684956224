import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    '& svg': {
      width: '50px',
      height: '50px'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textContainer: {
    textAlign: 'center',
    cursor: 'pointer'
  },
  top: {
    paddingTop: (props) => props.pageprofile && theme.spacing(9)
  },
  avatarArea: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  avatarAreaTablet: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    }
  },
  white: {
    fontSize: 12,
    color: 'white'
  },
  gray: {
    fontSize: 12,
    color: '#707070'
  },
  primary: {
    fontSize: 12,
    color: theme.palette.primary.main
  },
  accent: {
    fontSize: 12,
    color: theme.palette.accent.main
  },
  iconPerson: {
    fontSize: 22,
    marginRight: 10,
    color: (props) =>
      props.pageprofile ? theme.palette.activeText.main : 'white'
  },
  divider: {
    fontSize: 22,
    backgroundColor: (props) =>
      props.pageprofile ? theme.palette.activeText.main : 'white'
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 5,
    width: '100%'
  },
  userNames: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  }
}));

const backgroundSkeleton = 'rgba(87, 154, 255, 0.43)';

export const ShimmerCircle = styled(({ color, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    variant="circle"
    width={otherProps.width}
    height={otherProps.height}
    animation="pulse"
    style={{ backgroundColor: backgroundSkeleton }}
  />
))``;

export const ShimmerText = styled(({ color, ...otherProps }) => (
  <Skeleton
    {...otherProps}
    height={25}
    animation="pulse"
    width="80%"
    style={{ backgroundColor: backgroundSkeleton }}
  />
))``;
