import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { editCourseFormSchema } from '~/components-refactor/molecules/ReplicatedSteps/CourseStep/EditCourseForm/validation';
import { CreateReplicatedStructure } from '~/components-refactor/organisms/CreateReplicatedStructure';
import { ReplicatedStructureProvider } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext';
import i18n from '~/I18n';
import courseMirrorService from '~/services/mirroring/course';
import coursesListService from '~/services/mirroring/mirroring_list/courses';

export default function ReplicateCourse() {
  const { environmentID } = useParams();

  return (
    <ReplicatedStructureProvider
      parentID={environmentID}
      mirrorType="course"
      listService={coursesListService}
      mirrorService={courseMirrorService}
    >
      <CreateReplicatedStructure
        structureName={i18n.t(`StructuresName.Course.SingularUpper`)}
        stepsCount={4}
        resolver={editCourseFormSchema}
      />
    </ReplicatedStructureProvider>
  );
}
