import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '16px',
    width: '100%',
    borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
    paddingBottom: '16px'
  },
  avatarSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
  },
  helpIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px'
  },
  postSection: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1
  },
  postSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    overflow: 'hidden'
  },
  userName: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#454954',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden'
  },
  timeDistance: {
    fontSize: '14px',
    color: '#A8B1CE',
    textWrap: 'nowrap',
    minWidth: 'min-content'
  },
  postBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  postBodyText: {
    fontSize: '14px',
    lineHeight: '24px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    color: theme.palette.activeText.main
  },
  footButtonsContainer: {
    marginTop: '16px'
  },
  popUpMenuSection: {
    minWidth: '24px',
    maxWidth: '24px'
  },
  popUpMenuIcon: {
    fontSize: '24px',
    color: theme.palette.activeText.main
  },
  borderRadiusPopUpMenu: {
    borderRadius: '8px'
  }
}));
