import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    paddingLeft: '32px',
    paddingRight: '32px',
    alignItems: 'flex-start',
    gap: 60,
    paddingTop: 24,
    flexDirection: 'column',
    flex: '1 1 auto',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '166px',
      paddingRight: '166px'
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex0start',
    gap: 16,
    alignSelf: 'stretch'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));
