import React, { useRef, useState } from 'react';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const HorizontalScrollerWrapper = ({ children }) => {
  const classes = useStyles();

  const rootRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickThreshold] = useState(5);
  const [isClick, setIsClick] = useState(true);

  const handleMouseDown = (e) => {
    const div = rootRef.current;
    setIsDragging(true);
    setStartX(e.pageX - div.offsetLeft);
    setScrollLeft(div.scrollLeft);
    setIsClick(true);
    div.style.cursor = 'grabbing';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const div = rootRef.current;
    const x = e.pageX - div.offsetLeft;
    const walkX = (x - startX) * 2;

    if (Math.abs(walkX) > clickThreshold) {
      setIsClick(false);
    }

    div.scrollLeft = scrollLeft - walkX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    rootRef.current.style.cursor = 'default';
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    rootRef.current.style.cursor = 'default';
  };

  const handleClick = (e) => {
    if (!isClick) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Box
      className={classes.root}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      ref={rootRef}
      onClickCapture={handleClick}
    >
      {children}
    </Box>
  );
};

HorizontalScrollerWrapper.propTypes = {
  children: PropTypes.node.isRequired // Define que 'children' deve ser um nó React (qualquer coisa renderizável)
};
