import api from '../api';

export const hasManageAccessTo = async (partialUrl) => {
  const { data } = await api.get(`/api/access_control/${partialUrl}`, {
    params: { accessLevel: 'manage' }
  });
  return data;
};

export const hasReadAccessTo = async (partialUrl) => {
  const { data } = await api.get(`/api/access_control/${partialUrl}`, {
    params: { accessLevel: 'read' }
  });
  return data;
};

export const hasAdminAccess = async () => {
  const { data } = await api.get(`api/access_control/all`);
  return data;
};

export const hasCustomAccessTo = async (partialUrl, customAccessLevel) => {
  const { data } = await api.get(`/api/access_control/${partialUrl}`, {
    params: { accessLevel: customAccessLevel }
  });
  return data;
};
