import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';

import { getQRCodeLink } from './fetch';
import LinkButton from './LinkButton';
import LinkInput from './LinkInput';
import Loading from './Loading';
import QRCode from './QRCode';
import { Grid } from '@material-ui/core';
import i18n from '~/I18n';

export default function LinkField({ subjectID }) {
  const [QRCodeLink, setQRCodeLink] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSuccess = (data) => {
    setQRCodeLink(data.links[0].href);
    setLoading(false);
  };

  const handleFailure = () => setLoading(false);

  useEffect(() => {
    getQRCodeLink(subjectID)
      .then(handleSuccess)
      .catch(handleFailure);
  }, []);

  const Content = () => {
    return (
      <Grid container item spacing={3}>
        <Grid xs={12} item container spacing={1}>
          <Grid item xs={9}>
            <LinkInput QRCodeLink={QRCodeLink} />
          </Grid>
          <Grid item xs={3}>
            <LinkButton QRCodeLink={QRCodeLink} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <QRCode QRCodeLink={QRCodeLink} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {' '}
      {loading ? (
        <Loading />
      ) : QRCodeLink ? (
        <Content />
      ) : (
        <Text variant="mediumText" color="error">
          Não foi possível gerar o link para este {`${i18n.t('StructuresName.Subjects.SingularLower')}`}. Tente novamente mais
          tarde!{' '}
        </Text>
      )}{' '}
    </>
  );
}
