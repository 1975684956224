import { makeStyles } from '@material-ui/core';

const rowHeight = '40px';
const numRows = 10;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    maxHeight: `calc(${rowHeight} * ${numRows})`,
    overflow: 'scroll',
    width: '100%'
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '10% 76% 14%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '10% 73% 17%'
    },
    height: rowHeight,
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: '#F5F5F5'
  },
  titleRow: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  rowText: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#676D82',
    fontSize: '20px',
    textAlign: 'center'
  }
}));
