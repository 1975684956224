import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getOverviewRequest: ['id', 'page'],
  getOverviewSuccess: ['overview'],
  getOverviewFailure: [],
  putOverviewReset: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  overview: [],
  page: 1,
  loading: false,
  noMoreStatuses: false
};

const getOverviewRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getOverviewSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.page += 1;
    if(!action.overview || !action.overview.length) draft.noMoreStatuses = true;
    else draft.overview = draft.overview.concat(action.overview);
  });

const getOverviewFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.noMoreStatuses = true;
    draft.loading = false;
  });

const putOverviewReset = (state = INITIAL_STATE) => 
  produce(state, (draft) => {
    draft.overview = [];
    draft.page = 1;
    draft.loading = false;
    draft.noMoreStatuses = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_OVERVIEW_REQUEST]: getOverviewRequest,
  [Types.GET_OVERVIEW_SUCCESS]: getOverviewSuccess,
  [Types.GET_OVERVIEW_FAILURE]: getOverviewFailure,
  [Types.PUT_OVERVIEW_RESET]: putOverviewReset
});
