import React from 'react';

import Main from '../Main';

export default function ForumMiniCard({ size, ...rest }) {
  return (
    <Main
      color="#7D35DB"
      backgroundColor="#EEE6FA"
      size={size}
      text="tópico de discussão"
      {...rest}
    />
  );
}
