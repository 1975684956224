import React from 'react';
import * as S from './style'
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { formatTime } from '~/utils/dates';

export const LectureReportCard = ({ LectureReportCardData }) => {

  const DynamicTimeLabel = (seconds) => {
    if (seconds < 60) {
      return <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>{i18n.t('Seconds')}</Text>;
    } else if (seconds < 3600) {
      return <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>{i18n.t('Minutes')}</Text>;
    } else {
      return <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>{i18n.t('Hours')}</Text>;
    }
  };

  return (
    <S.Container>
      <S.CardsContainer>
        <Text style={{ color: 'black', fontSize: '15px' }}>
          {i18n.t('HowManyDidAlready')}
        </Text>
        <Text color={'secondary'} style={{ fontSize: '30px' }}>
          {LectureReportCardData?.results_count}
        </Text>
        <Text style={{ color: 'black', fontSize: '15px' }}>
          {i18n.t('OverallTime')}
        </Text>
        <Text color={'secondary'} style={{ fontSize: '30px' }}>
          {formatTime(LectureReportCardData?.average_duration)}
        </Text>
        {DynamicTimeLabel(LectureReportCardData?.average_duration)}
      </S.CardsContainer>
    </S.Container>
  );
};

export default LectureReportCard;
