import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useStyles } from './styles';
import { Box, ListItem, MenuItem, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

export const FilterOption = ({
  handleSelectFilters,
  options,
  filterTitle,
  filterName,
  closeDrawer
}) => {
  const classes = useStyles();
  const { watch } = useFormContext();

  return (
    <Box style={{ width: '100%' }}>
      {options.map((option, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handleSelectFilters(option, filterName);
            closeDrawer();
          }}
          className={classes.item}
        >
          <Box width={'18px'}>
            {watch(filterName) === option && (
              <CheckIcon className={classes.icon} />
            )}
          </Box>
          <Typography className={classes.labelText}>{option}</Typography>
        </MenuItem>
      ))}
      {options.length === 0 && (
        <ListItem className={classes.labelText}>
          {`Nenhum "${filterTitle}" disponível`}
        </ListItem>
      )}
    </Box>
  );
};

FilterOption.propTypes = {
  handleSelectFilters: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterTitle: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired
};
