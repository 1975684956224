import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PopUpMenu from '~/components-refactor/molecules/PopUpMenu';
import Item from '~/components-refactor/molecules/PopUpMenu/Item';
import ShareContent from '~/components/organisms/ShareContents/ShareContentEnvironments';
import i18n from '~/I18n';
import { deleteEnvironment } from '~/services/environment';

import DeleteConfirmModal from '../../DeleteConfirmModal';
import { useStyles } from './styles';
import { ButtonBase, Modal, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const ManagerPopUp = ({ environment, removeEnvironmentById }) => {
  const { name, id, mirrorsCount } = environment || {};
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openSharingModal, setOpenSharingModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDeletion, setLoadingDeletion] = useState(false);

  const deleteMessage = () => {
    if (mirrorsCount) {
      return (
        <>
          O {i18n.t('StructuresName.Environment.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{name} </span>
          possui {mirrorsCount} espelho(s) e será permanentemente excluído,
          incluindo todos os dados e arquivos dos seus espelhos. Todos os
          membros irão perder o acesso.
        </>
      );
    } else {
      return (
        <>
          O {i18n.t('StructuresName.Environment.SingularLower')}{' '}
          <span style={{ fontWeight: 'bold' }}>{name}</span>, será
          permanentemente excluído, incluindo todos os dados e arquivos. Todos
          os membros irão perder o acesso.
        </>
      );
    }
  };

  const handleDeleteConfirm = () => {
    if (loadingDeletion) return;
    setLoadingDeletion(true);
    deleteEnvironment(id)
      .then(() => {
        enqueueSnackbar(`Ambiente excluído com sucesso!`, {
          variant: 'success'
        });

        removeEnvironmentById(id);
      })
      .catch(() => {
        enqueueSnackbar(`Erro ao excluir ambiente.`, { variant: 'error' });
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setLoadingDeletion(false);
      });
  };

  const PopUpButton = ({ ...props }) => (
    <ButtonBase
      {...props}
      disableRipple
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(e);
      }}
      style={{
        minWidth: '0px',
        borderRadius: '50%',
        backgroundColor: 'rgba(103, 109, 130, 0.10)'
      }}
    >
      <MoreVertIcon
        style={{
          fontSize: '24px',
          fill: theme.palette.activeText.main
        }}
      />
    </ButtonBase>
  );

  return (
    <>
      <PopUpMenu component={PopUpButton}>
        <Item
          icon={EditIcon}
          text={`Editar ${i18n.t(`StructuresName.Environment.SingularLower`)}`}
          action={() => history.push(`/dashboard/environments/${id}/edit`)}
        />
        <Item
          icon={ShareIcon}
          text={`Compartilhar ${i18n.t(
            `StructuresName.Environment.SingularLower`
          )}`}
          action={() => setOpenSharingModal(true)}
        />
        <Item
          icon={DeleteIcon}
          text={'Excluir'}
          action={() => setOpenDeleteModal(true)}
        />
      </PopUpMenu>
      <Modal
        open={openSharingModal && id}
        onClose={() => setOpenSharingModal(false)}
        className={classes.modal}
      >
        <ShareContent environmentID={id} />
      </Modal>
      <DeleteConfirmModal
        show={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleConfirm={handleDeleteConfirm}
        maxWidth={440}
        loading={loadingDeletion}
        showButtons={true}
        title={i18n.t('DeleteEnvironment.title')}
        descriptionMessage={deleteMessage()}
      />
    </>
  );
};

ManagerPopUp.propTypes = {
  environment: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mirrorsCount: PropTypes.number
  }),
  removeEnvironmentById: PropTypes.func.isRequired
};
