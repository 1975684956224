import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: 0
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: 0,
    gap: '8px',
    alignItems: 'start',
    justifyContent: 'center',
    marginLeft: '-16px'
  },
  accordionSummaryIcon: {
    fontSize: '24px'
  },
  accordionSummaryText: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: '24px'
  },
  fieldBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }
}));
