import React, { useEffect, useState } from 'react';
import Page from '~/components/templates/Page';

import { useParams } from 'react-router-dom';

import Success            from './Success';
import UnderAnalysis      from './UnderAnalysis';
import { getEnvironment } from './fetch';

export default function PagseguroRedirect() {
  const { id: environmentID } = useParams()
  const [pagseguroAuth, setPagseguroAuth] = useState(false)

  useEffect(() => {
    getEnvironment(environmentID).then(res => setPagseguroAuth(res.data.pagseguro_authorization)).catch(console.log)
  }, [environmentID])
  
  return (
    <Page>
      { pagseguroAuth ? 
        <Success pagseguroAuth={pagseguroAuth} environmentID={environmentID} />
        : 
        <UnderAnalysis/>
      }
    </Page>
  );
}
