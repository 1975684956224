import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

import Field from '../../GenericFormWrapper/Field';
import { Collapse, Grid, Switch, Popover, IconButton } from '@material-ui/core';
import { InfoRounded, Close } from '@material-ui/icons';
import useStyles from './styles.js';

export default function EnrollmentLimit() {
  const classes = useStyles();
  const { watch } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Field
        label={TextPrimary('Habilitar limite de inscrições')}
        name="user_limit_enabled"
        labelPlacement={'end'}
        checked={watch('user_limit_enabled')}
        Component={Switch}
      />

      <Collapse in={watch('user_limit_enabled')}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <div style={{ width: '60%' }}>
              <Field
                label={
                  <Text color={'gray'}>
                    Limite de Inscrições{' '}
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                      <InfoRounded color="secondary" className={classes.icon} />
                    </span>
                  </Text>
                }
                name="user_limit"
                placeholder="Ex: 35"
                Component={Input}
                inputMode="numeric"
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
              />
            </div>
            <Popover
              id="popover"
              classes={{ paper: classes.paper }}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
              transformOrigin={{ vertical: 'center', horizontal: 'left' }}
              style={{ maxHeight: '800px' }}
            >
              <div className={classes.popoverBackground}>
                <Text className={classes.customText}>
                  Limite de inscrições{' '}
                  <IconButton
                    className={classes.closeIcon}
                    size="small"
                    onClick={handleClose}
                  >
                    <Close className={classes.closeButton} />
                  </IconButton>
                </Text>
                <Text className={classes.popoverText}>
                  Se este campo for preenchido, o número informado definirá a
                  quantidade de inscrições permitida neste{' '}
                  {`${i18n.t('StructuresName.Course.SingularLower')}`}.
                </Text>
              </div>
            </Popover>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}