import React from 'react';
import { Link } from 'react-router-dom';

import CustomDrawer from './CustomDrawer';
import { useStyles } from './styles';
import { AppBar, Toolbar, Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export default function HeaderBar() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <AppBar bgcolor={theme.palette.primary.main} position="fixed">
      <Toolbar variant="dense" className={classes.toolBar}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            gap: '20px'
          }}
        >
          <CustomDrawer />
          <Link to="/dashboard">
            <img src={theme.logo} width={theme.size.appBarLogo.width} />
          </Link>
        </Box>
        <Link to="/dashboard" className={classes.goToPlatformLink}>
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap'
            }}
          >
            Acessar Plataforma
          </Typography>
          <ArrowForwardIcon
            style={{
              fontSize: '24px'
            }}
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
}
