import enUsTranslations from './en-US';
import esESTranslations from './es-ES';
import ptBrTranslations from './pt-BR';

export default {
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations,
  'es-ES': esESTranslations
  // Símbolos dos idiomas
  // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
  // http://www.lingoes.net/en/translator/langcode.htm
};
