import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import Accordion from '~/components/templates/Accordion';
import settings from '~settings';
import { useStyles } from './styles';

const ConquestsBox = ({ title, badges }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Accordion title={title} environment conquests>
        <div className={classes.badges__wraper}>
          {badges ? (
            badges.map((item) => (
              <div className={classes.conquest__wraper}>
                <div
                  className={classes.conquest}
                  title={item.badge.description}
                >
                  <img
                    src={`${settings.baseUrl}${item.badge.custom_fields.badge}`}
                    alt={item.badge.custom_fields.description}
                  />
                </div>
                <Text
                  variant="smallText"
                  color="gray"
                  style={{ textTransform: 'capitalize', textAlign:'center', marginTop: 5}}
                >
                  {item.badge.name}
                </Text>
              </div>
            ))
          ) : (
            <Text variant="subtitle" color="gray">
              Não há conquistas nessa categoria.
            </Text>
          )}
        </div>
      </Accordion>
    </Box>
  );
};

ConquestsBox.propTypes = {
  title: PropTypes.string,
};

ConquestsBox.defaultProps = {
  title: '',
};

export default ConquestsBox;
