import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Paper, Box, useTheme, Input, FormHelperText } from '@material-ui/core';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import i18n from '~/I18n';

export default function EditUsernameModal({ setShow, handleSubmit, initialName, initialLogin, errors, clearErrors }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [name, setName] = useState(initialName || "");
  const [login, setLogin] = useState(initialLogin || "");

  function handleNameChange(event) { setName(event.target.value); }
  function handleLoginChange(event) { setLogin(event.target.value); }
  function handleCancel() {
    setName(initialName)
    setLogin(initialLogin);
    setShow(false);
    clearErrors();
  }
  function handleSave() { handleSubmit(name, login); }

  return (
    <Paper elevation={3} className={classes.root}>
      <form>
        <Box className={classes.InputBox}>
          <Input 
            error={!!errors.nameError}
            value={name} 
            placeholder={i18n.t('Name')}
            onChange={handleNameChange}  
            disableUnderline
            classes={{
              root: `${classes.input} ${classes.name}`,
              error: classes.inputError
            }}
          />
          {errors.nameError && 
            <FormHelperText className={classes.helperText}>{errors.nameError}</FormHelperText>
          }
        </Box>
        <Box className={classes.InputBox}>
          <Input 
            error={!!errors.loginError}
            value={login} 
            placeholder='Login' 
            onChange={handleLoginChange}  
            disableUnderline
            startAdornment={(
              <span>
                <Text variant='text' color='gray'>@</Text>
              </span>
            )}
            classes={{
              root: `${classes.input} ${classes.login}`,
              error: classes.inputError
            }}
          />
          {errors.loginError && 
            <FormHelperText className={classes.helperText}>{errors.loginError}</FormHelperText>
          }
        </Box>
        <Box className={classes.buttonArea}>
          <ButtonSquare
            border={`1px solid ${theme.palette.secondary.main}`}
            width={76}
            height={38}
            onClick={handleCancel}
          >
            <Text variant='text' color='secondary'>
              {i18n.t('Cancel')}
            </Text>
          </ButtonSquare>
          <Box className={classes.saveButton}>
            <ButtonSquare
              backgroundColor={theme.palette.secondary.main}
              width={76}
              height={38}
              marginLeft={10}
              onClick={handleSave}
            >
              <Text variant='text' color='white'>
                {i18n.t('Save')}
              </Text>
            </ButtonSquare>
          </Box>
        </Box>
      </form>
    </Paper>
  );
}

EditUsernameModal.propTypes = ({
  setShow: PropTypes.func, 
  handleSubmit: PropTypes.func, 
  initialName: PropTypes.string, 
  initialLogin: PropTypes.string,
  errors: PropTypes.shape(),
  clearErrors: PropTypes.func
});

EditUsernameModal.defaultProps = ({
  setShow: null, 
  handleSubmit: null, 
  initialName: "", 
  initialLogin: "",
  errors: {},
  clearErrors: null
});