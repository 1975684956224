import React, { useState } from 'react';

import {
  faPlus,
  faMinus,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function Accordion({
  children,
  title,
  environment,
  lecture,
  conquests,
  keepBorderBottom,
  borderBottomColor,
}) {
  const classes = useStyles({
    lecture,
    conquests,
    keepBorderBottom,
    borderBottomColor,
  });
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  function handleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <ExpansionPanel className={classes.root} expanded={expanded}>
      <ExpansionPanelSummary
        onClick={handleExpanded}
        className={classes.noPadding}
        // classes={(environment || lecture) ? { root: classes.expansionPanelSummaryRoot, content: classes.content, expanded: classes.expanded } : {}}
        expandIcon={
          <Button className={classes.expandIcon}>
            {expanded ? (
              <FontAwesomeIcon
                icon={conquests ? faChevronDown : faMinus}
                color={
                  conquests
                    ? theme.palette.primary.main
                    : theme.palette.primary.textAndBackground
                }
                style={{ fontSize: conquests ? 20 : 14 }}
              />
            ) : (
              <FontAwesomeIcon
                icon={conquests ? faChevronDown : faPlus}
                color={
                  conquests
                    ? theme.palette.primary.main
                    : theme.palette.primary.textAndBackground
                }
                style={{ fontSize: conquests ? 20 : 14 }}
              />
            )}
          </Button>
        }
      >
        {environment || lecture ? (
          <Text
            color="primary"
            variant="accordionTitle"
            style={{ letterSpacing: 1 }}
            onClick={handleExpanded}
          >
            {title}
          </Text>
        ) : (
          <Text variant="subtitle" color="primary">
            {title}
          </Text>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.noPadding}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.array,
    PropTypes.bool
  ]),
  environment: PropTypes.bool,
  lecture: PropTypes.bool,
  conquests: PropTypes.bool,
  keepBorderBottom: PropTypes.bool,
  borderBottomColor: PropTypes.string,
};

Accordion.defaultProps = {
  title: '',
  children: false,
  environment: false,
  lecture: false,
  conquests: false,
  keepBorderBottom: false,
  borderBottomColor: 'rgba(0, 0, 0, .125)',
};
