import React from 'react';
import { Link } from 'react-router-dom';

import AvatarProfile from '~/components/atoms/Avatar';
import { sideBarAvatarSize } from '~/constants/styles';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export const UserCard = ({ user }) => {
  const classes = useStyles();

  return (
    <Link to={`/profile/${user.id}`} className={classes.link}>
      <AvatarProfile size={sideBarAvatarSize} profile={user} />
      <Box style={{ flex: 1, overflow: 'hidden' }}>
        <Typography className={classes.userName}>
          {`${user.first_name} ${user.last_name}`}
        </Typography>
        <Typography className={classes.userLogin}>@{user.login}</Typography>
      </Box>
    </Link>
  );
};
