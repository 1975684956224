import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import Alternative from '../Alternative';
import { useStyles } from './styles';
import { Collapse, Divider, Grid, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function Alternatives({ alternatives, deleteFunc, editFunc }) {
  const theme = useTheme();
  const classes = useStyles();

  const [show, setShow] = useState(false);

  const renderIcon = (bool) => {
    return bool ? (
      <IconWrapper
        customColor={theme.palette.primary.main}
        fontSize={30}
        Icon={ExpandLess}
      />
    ) : (
      <IconWrapper
        customColor={theme.palette.primary.main}
        fontSize={30}
        Icon={ExpandMore}
      />
    );
  };

  const getLetter = (index) => {
    const code = 'A'.charCodeAt(0) + index;
    return String.fromCharCode(code);
  };

  return (
    <>
      <Grid
        className={classes.toggle}
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setShow((prev) => !prev)}
      >
        <Grid item>
          <Text color="primary" variant={'text'}>
            {' '}
            Exibir alternativas{' '}
          </Text>
        </Grid>

        <Grid item>{renderIcon(show)}</Grid>
      </Grid>
      <Divider className={classes.divider} />

      <Collapse in={show}>
        <div className={classes.alternatives}>
          {alternatives.map((item, index) => {
            if (!item._destroy) item.letter = getLetter(index);

            return !item._destroy ? (
              <Alternative
                alternative={item}
                deleteFunc={deleteFunc}
                editFunc={editFunc}
              />
            ) : null;
          })}
        </div>
      </Collapse>
    </>
  );
}
