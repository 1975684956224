import React from 'react';

import { useStyles } from './styles';
import { Typography, useTheme } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';

const ButtonHorizontalIcon = ({
  Icon,
  title,
  disableRipple,
  onClick,
  disabled,
  iconProps
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <ButtonBase
      className={classes.contextualBarButton}
      disabled={disabled}
      disableRipple={disableRipple}
      onClick={onClick}
    >
      <Icon style={{ fontSize: '16px' }} {...iconProps} />

      <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
        {title}
      </Typography>
    </ButtonBase>
  );
};

ButtonHorizontalIcon.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  disableRipple: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconProps: PropTypes.object
};

ButtonHorizontalIcon.defaultProps = {
  disableRipple: false,
  disabled: false,
  iconProps: {}
};

export default ButtonHorizontalIcon;
