import React, { useEffect, useState } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';
import { formatTime } from '~/utils/dates';
import settings from '~settings';

import FormButton from '../FormButton';
import { Box, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';

const StyledTable = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.primary
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export default function MembersReport({ lecture, openRoot }) {
  const classes = useStyles();

  const [exerciseData, setExerciseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const requestApiRelatorioGeral = async () => {
    setIsLoading(true);
    await api
      .get(
        `/api/forms/${lecture.lectureable.id}/lectures/${lecture.id}/submissions`
      )
      .then((response) => {
        setExerciseData(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (lecture) requestApiRelatorioGeral();
  }, [lecture]);

  const DynamicTimeLabel = (seconds) => {
    if (seconds < 60) {
      return (
        <Text
          style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}
        >
          {i18n.t('Seconds')}
        </Text>
      );
    } else if (seconds < 3600) {
      return (
        <Text
          style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}
        >
          {i18n.t('Minutes')}
        </Text>
      );
    } else {
      return (
        <Text
          style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}
        >
          {i18n.t('Hours')}
        </Text>
      );
    }
  };

  return (
    <Box
      style={{
        paddingBottom: '50px',
        'paddingBottom(max-Width:600px': { paddingBottom: '50px' }
      }}
    >
      {isLoading && (
        <Grid container justifyContent="center">
          <Skeleton variant="rectangular" width={'90%'} height={118} />
        </Grid>
      )}
      {!isLoading && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItens: 'center',
            justifyContent: 'center'
          }}
        >
          <Box style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Text style={{ fontSize: '30px', color: 'rgba(0,0,0, 0.3)' }}>
              {i18n.t('QuestionsReport')}
            </Text>
          </Box>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTable
                    style={{ fontSize: '15px', color: 'rgba(0,0,0,0.4)' }}
                  >
                    {i18n.t('Name')}
                  </StyledTable>
                  <StyledTable
                    style={{ fontSize: '15px', color: 'rgba(0,0,0,0.4)' }}
                    align="center"
                  >
                    {i18n.t('Time')}
                  </StyledTable>
                </TableRow>
              </TableHead>
              <TableBody>
                {exerciseData.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTable
                      style={{ color: '#449cfd' }}
                      component="th"
                      scope="row"
                    >
                      <div style={{ display: 'flex', alignItens: 'center' }}>
                        <img
                          src={`${settings.baseUrl}${row.user.thumbnails[1].href}`}
                          style={{ marginRight: '5px', borderRadius: '15px' }}
                        />
                        <div style={{ paddingTop: '8px' }}>
                          {`${row.user.first_name}` +
                            ' ' +
                            `${row.user.last_name}`}
                        </div>
                      </div>
                    </StyledTable>
                    <StyledTable
                      style={{ color: 'rgba(0,0,0,0.3)' }}
                      align="center"
                    >
                      <Text
                        style={{ marginTop: '20px', color: 'rgba(0,0,0,0.3)' }}
                      >
                        {formatTime(row.duration)}
                      </Text>{' '}
                      <Text style={{ color: 'rgba(0,0,0,0.3)' }}>
                        {DynamicTimeLabel(row.duration)}
                      </Text>
                    </StyledTable>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <FormButton handleClick={openRoot} text={i18n.t('GoBack')} />
    </Box>
  );
}
