import React, { useEffect, useState } from 'react';

import { Input, useTheme, Box, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import PropTypes from 'prop-types';

import i18n from '~/I18n';

import { useStyles } from './styles';

export default function MessagesTextInput({
  height,
  placeholder,
  handleValueChange,
  showEmojiButton,
  showSearchIcon,
  showClearInputButton,
  isMessageSend,
  resetIsMessageSend
}) {
  const [showPicker, setShowPicker] = useState(false);
  const [value, setValue] = useState('');

  const theme = useTheme();
  const classes = useStyles({ height, theme, showPicker });

  useEffect(() => {
    if (isMessageSend) {
      clearInput();
      resetIsMessageSend();
    }
  }, [isMessageSend]);

  function togglePickerState() {
    setShowPicker(!showPicker);
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (handleValueChange) handleValueChange(event.target.value);
  }

  function toUTF16(codePoint) {
    var TEN_BITS = parseInt('1111111111', 2);
    function u(codeUnit) {
      return '\\u' + codeUnit.toString(16).toUpperCase();
    }

    if (codePoint <= 0xffff) {
      return u(codePoint);
    }
    codePoint -= 0x10000;

    // Shift right to get to most significant 10 bits
    var leadSurrogate = 0xd800 + (codePoint >> 10);

    // Mask to get least significant 10 bits
    var tailSurrogate = 0xdc00 + (codePoint & TEN_BITS);

    return u(leadSurrogate) + u(tailSurrogate);
  }

  function handleSelectEmoji(emoji) {
    // const newVal = value + emoji.native;
    const emojiFormatted = toUTF16(emoji.native.codePointAt(0));
    const newVal = value + emojiFormatted;
    setValue(newVal);
    if (handleValueChange) handleValueChange(newVal);
  }

  function clearInput() {
    setValue('');
    if (handleValueChange) handleValueChange('');
  }

  let EmojiPicker;
  if (showPicker) {
    EmojiPicker = (
      <Paper elevation={3} className={classes.pickerModal}>
        <Picker
          title={i18n.t('SelectAnEmoji')}
          emoji="point_up"
          onSelect={handleSelectEmoji}
          sheetSize={32}
          set="apple"
        />
      </Paper>
    );
  }

  return (
    <Box className={classes.content}>
      <Input
        value={value}
        className={classes.input}
        onChange={handleChange}
        placeholder={placeholder}
        multiline
        rows={1}
        rowsMax={7}
        disableUnderline
        startAdornment={
          showSearchIcon && <SearchIcon className={classes.searchIcon} />
        }
        endAdornment={
          showEmojiButton ? (
            <button
              onClick={togglePickerState}
              className={classes.emojiPickerButton}
            >
              <SentimentSatisfiedIcon className={classes.smileyIcon} />
            </button>
          ) : showClearInputButton && value !== '' ? (
            <button onClick={clearInput} className={classes.closeButton}>
              <CloseIcon />
            </button>
          ) : (
            false
          )
        }
        onClick={() => {
          if (showPicker) setShowPicker(false);
        }}
        id="message-input"
      />
      {EmojiPicker}
    </Box>
  );
}

MessagesTextInput.propTypes = {
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  handleValueChange: PropTypes.func,
  showEmojiButton: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  showClearInputButton: PropTypes.bool,
  isMessageSend: PropTypes.bool,
  resetIsMessageSend: PropTypes.func
};

MessagesTextInput.defaultProps = {
  height: 30,
  placeholder: '',
  handleValueChange: null,
  showEmojiButton: false,
  showSearchIcon: false,
  showClearInputButton: false,
  isMessageSend: false,
  resetIsMessageSend: null
};
