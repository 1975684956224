import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    alignSelf: (props) => (props.isMine ? 'flex-end' : 'flex-start'),
    marginBottom: 5,
    display: 'flex',
    maxWidth: '100%',
    flexDirection: (props) => (props.isMine ? 'row-reverse' : 'row'),
    alignItems: 'flex-end'
  }
});
