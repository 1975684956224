import React from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from '@material-ui/lab';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { Content } from '../styles';
import ConquestsBox from './ConquestsBox';

export default function Conquests({ profile }) {
  const { badges } = useSelector((state) => state.user);
  const shimmerList = Array(4).fill(null);

  let badgesStore;
  if (profile) {
    if (profile.category_badges) badgesStore = profile.category_badges;
    else badgesStore = badges && badges;
  }

  const badgesList = badgesStore;

  return (
    <Content>
      <Text variant="header" color="primary">
        {i18n.t('Achievements')}
      </Text>

      {badgesList
        ? Object.keys(badgesList).map((keyName) => (
            <ConquestsBox
              key={keyName}
              title={keyName}
              badges={badgesList[keyName]}
            />
          ))
        : shimmerList.map((keyName) => (
            <Skeleton
              variant="pulse"
              width="100%"
              height={50}
              style={{ margin: '5px 15px 5px 5px' }}
            />
          ))}
    </Content>
  );
}
