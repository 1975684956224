import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '25px'
  },
  warning:{
    marginTop: '-15px',
    marginBottom: '20px',
    backgroundColor: '#faf0ce',
    borderRadius: '5px'
  }
});

export const warningStyle = {
  backgroundColor: "#faf0ce", 
  padding: '2px',
  borderRadius: '50%',
}