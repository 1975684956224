import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdKeyboardDoubleArrowUp } from 'react-icons/md';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import { useLectureDiscussionMuralContext } from '~/contexts/LectureDiscussionMuralContext';
import {
  PER_PAGE,
  WINDOW_SIZE
} from '~/contexts/LectureDiscussionMuralContext/useStatusArrayManager';
import { useLectureMuralContext } from '~/contexts/LectureMuralContext';
import { handleGetStatuses } from '~/services/mural';
import {
  scrollToElement,
  scrollToElementIntoView
} from '~/utils/lectureMuralAuxFunctions';

import { FeedbackLoading } from '../FeedbackLoading';
import { Status } from '../Status';
import { useStyles } from './styles';
import {
  Badge,
  Box,
  ButtonBase,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const InfiniteScrollStatus = ({
  newStatusCreated,
  setNewStatusCreated
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    statusArray,
    setStatusArrayFuncs,
    offset,
    setOffset,
    statusInChannel,
    setStatusInChannel,
    setTotalAttachments
  } = useLectureDiscussionMuralContext();
  const { lecture, extendedWall } = useLectureMuralContext();
  const { id: lectureId } = lecture;

  const [loading, setLoading] = useState(true);
  const [hasMoreDown, setHasMoreDown] = useState(true);
  const [hasMoreTop, setHasMoreTop] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingDown, setLoadingDown] = useState(false);
  const [firstElement, setFirstElement] = useState(null);
  const [scrollOccurred, setScrollOccurred] = useState(false);

  const infinityScrollTopRef = useRef(null);
  const infinityScrollBottomRef = useRef(null);
  const topObserver = useRef(null);
  const downObserver = useRef(null);

  const firstGetStatuses = async () => {
    try {
      setLoading(true);
      const response = await handleGetStatuses(lectureId, PER_PAGE, 0);
      setHasMoreDown(response.data.length >= PER_PAGE);
      setHasMoreTop(false);
      setStatusInChannel([]);
      setStatusArrayFuncs.resetStatusArray(response.data);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível carregar os comentários, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
        { variant: 'error' }
      );
      setHasMoreDown(false);
      setHasMoreTop(false);
    } finally {
      setLoading(false);
      scrollToElement('mural-lecture');
    }
  };

  const getStatusesTop = useCallback(async () => {
    if (!hasMoreTop || loadingTop || firstElement) return;
    try {
      setLoadingTop(true);
      const curOffset = Math.max(offset - (WINDOW_SIZE + PER_PAGE), 0);
      const response = await handleGetStatuses(lectureId, PER_PAGE, curOffset);
      setHasMoreTop(curOffset > 0);
      !extendedWall && setFirstElement(statusArray[0]);
      setStatusArrayFuncs.pushFront(response.data);
      if (curOffset < statusInChannel.length) {
        setStatusInChannel((prev) => [
          ...prev.slice(0, statusInChannel.length - curOffset)
        ]);
      }
      setHasMoreDown(true);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível carregar os comentários, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
        { variant: 'error' }
      );
      setHasMoreTop(false);
    } finally {
      setLoadingTop(false);
    }
  }, [hasMoreTop, loadingTop, offset, firstElement, loading]);

  const getStatusesDown = useCallback(async () => {
    if (!hasMoreDown || loadingDown) return;
    try {
      setLoadingDown(true);
      const response = await handleGetStatuses(lectureId, PER_PAGE, offset);
      setHasMoreDown(response.data.length >= PER_PAGE);
      setStatusArrayFuncs.pushBack(response.data);
      setOffset((prev) => prev + response.data.length);
      setHasMoreTop(offset >= WINDOW_SIZE ? true : false);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível carregar os comentários, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
        { variant: 'error' }
      );
      setHasMoreDown(false);
    } finally {
      setLoadingDown(false);
    }
  }, [hasMoreDown, loadingDown, offset, loading]);

  const addObservers = () => {
    topObserver.current = new IntersectionObserver(async (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !loading) {
          setScrollOccurred(false);
          getStatusesTop();
        } else if (!scrollOccurred && !loading) {
          const rect = entry.boundingClientRect;
          if (rect.top < 0) {
            setScrollOccurred(true);
          }
        }
      });
    });

    downObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !loading) {
          getStatusesDown();
        }
      });
    });

    if (infinityScrollTopRef.current) {
      topObserver.current.observe(infinityScrollTopRef.current);
    }
    if (infinityScrollBottomRef.current) {
      downObserver.current.observe(infinityScrollBottomRef.current);
    }

    return () => {
      if (topObserver.current) {
        topObserver.current.disconnect();
      }
      if (downObserver.current) {
        downObserver.current.disconnect();
      }
    };
  };

  useEffect(addObservers, [getStatusesDown, getStatusesTop]);

  const handleGoToTop = async () => {
    await firstGetStatuses();
    scrollToElement(extendedWall ? 'lecture-main-area' : 'mural-lecture');
  };

  useEffect(() => {
    firstGetStatuses();
  }, [lectureId]);

  useEffect(() => {
    if (newStatusCreated) {
      handleGoToTop();
      setNewStatusCreated(false);
    }
  }, [newStatusCreated]);

  useEffect(() => {
    if (firstElement && !extendedWall) {
      scrollToElementIntoView(firstElement.id);
      // setTimeout are necessary to deal with the delay that exists when executing the scroll
      setTimeout(() => {
        setFirstElement(null);
      }, 1000);
    }
  }, [firstElement]);

  useEffect(() => {
    offset >= WINDOW_SIZE && setHasMoreDown(true);
  }, [offset]);

  useEffect(() => {
    if (
      !scrollOccurred &&
      offset <= WINDOW_SIZE &&
      statusInChannel.length > 0
    ) {
      setStatusArrayFuncs.addNewStatus(
        statusInChannel[statusInChannel.length - 1]
      );
      setTotalAttachments(
        (prev) =>
          prev + statusInChannel[statusInChannel.length - 1].status_files.length
      );
      setStatusInChannel((prev) => {
        const newArray = [...prev];
        newArray.pop();
        return newArray;
      });
    }
  }, [statusInChannel, offset, scrollOccurred]);

  return (
    <Box id="mural-lecture" className={classes.muralDashboard}>
      {(loadingTop || loading) && <FeedbackLoading />}
      <Box ref={infinityScrollTopRef} />
      {statusArray.map((currentStatus, index) => (
        <Box
          key={currentStatus.id}
          id={currentStatus.id}
          style={{
            padding: '0px 8px 0px 16px',
            style: {
              paddingBottom: index === statusArray.length - 1 ? 0 : '24px',
              boxShadow:
                index === statusArray.length - 1
                  ? 'none'
                  : '0 1px 2px rgba(0, 0, 0, 0.1)'
            }
          }}
        >
          <Status key={currentStatus.id} status={currentStatus} />
        </Box>
      ))}
      <Box ref={infinityScrollBottomRef} />
      {loadingDown && <FeedbackLoading />}
      {!loadingDown && !loadingTop && !hasMoreDown && statusArray.length === 0 && (
        <Box padding="0px 64px">
          <GenericFeedbackLayout
            ImageIcon={TropicalIslandImage}
            title={'Nenhum comentário'}
            description={'Não há nada a ser exibido.'}
          />
        </Box>
      )}
      {scrollOccurred && (
        <Badge
          badgeContent={statusInChannel.length}
          color="error"
          overlap="circular"
          className={classes.badge}
        >
          <ButtonBase
            onClick={handleGoToTop}
            className={classes.goToTopButton}
            id="go-to-top-button"
          >
            <Typography className={classes.goToTopButtonText}>
              Ir para o início
            </Typography>
            {loading ? (
              <CircularProgress
                size={24}
                style={{
                  color: '#fff'
                }}
              />
            ) : (
              <MdKeyboardDoubleArrowUp
                style={{
                  fontSize: '24px',
                  color: '#fff'
                }}
              />
            )}
          </ButtonBase>
        </Badge>
      )}
    </Box>
  );
};

InfiniteScrollStatus.propTypes = {
  newStatusCreated: PropTypes.bool.isRequired,
  setNewStatusCreated: PropTypes.func.isRequired
};
