import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  stepLabel: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    fontSize: 16
  }
});
