import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChatHeader from '~/components/molecules/ChatHeader';
import { ConversationMessagesFooter } from '~/components/molecules/ConversationMessagesFooter';
import MessageRow from '~/components/molecules/MessageRow';
import ChatContactInfo from '~/components/organisms/ChatContactInfo';
import { getContactEmit, getContactOn } from '~/socket/contact-events';
import {
  messageFromChatEmit,
  messageFromChatOn,
  messageFromChatOff,
  messageFromChatContactOn,
  readMessageFromChatEmit,
  unreadMessageFromChatCountEmit,
  messageFromChatContactOff,
  unreadMessageForContactEmit
} from '~/socket/message-events';

import { useStyles } from './styles';
import { Box, Typography, useTheme } from '@material-ui/core';

export const ConversationMessages = ({ profile }) => {
  let { contact_id } = useParams();
  const contactId = parseInt(contact_id);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [loading, setLoading] = useState(true);
  const [currentContact, setCurrentContact] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(true);

  const handleScrollChange = () => {
    const chatView = document.getElementById('chat-messages-view');

    if (chatView.scrollTop === 0) {
      setScrollEnd(false);
      handleOldMessages();
    }
  };

  const scrollToEnd = () => {
    const chatView = document.getElementById('chat-messages-view');
    if (chatView) {
      chatView.scrollTo({ top: chatView.scrollHeight });
    }
  };

  useEffect(() => {
    const chatView = document.getElementById('chat-messages-view');
    if (scrollEnd) {
      chatView.scrollTop = chatView.scrollHeight - chatView.clientHeight;
    }
    if (
      chatView.scrollHeight <= chatView.clientHeight &&
      chatMessages.length >= 10
    ) {
      handleOldMessages();
      setScrollEnd(true);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (!!contactId && !!profile) {
      handleOldMessages();
      getContactEmit(profile?.id, contactId);
    }

    return () => {
      if (profile) {
        messageFromChatOff(profile?.id);
      }
    };
  }, [profile]);

  useEffect(() => {
    if (!!contactId && !!profile) {
      getContactOn(profile?.id, (contact) => {
        setCurrentContact(contact);
      });
      const messageFromChatContactOnCallback = (message) => {
        if (message?.id) {
          readMessageFromChatEmit(message.id, contactId);
          unreadMessageFromChatCountEmit(profile.id);
          unreadMessageForContactEmit(profile.id, contactId);
        }

        setChatMessages((prev) => [...prev, message]);
        scrollToEnd();
      };
      messageFromChatContactOn(
        profile?.id,
        contactId,
        messageFromChatContactOnCallback
      );

      const messageFromChatOnCallback = (messages) => {
        messages = messages || [];
        setLoading(false);
        let newMsgs = 0;
        setChatMessages((prev) => {
          const msgs = [...messages.reverse(), ...prev];
          newMsgs = msgs.length - prev.length;
          return msgs;
        });
        const chatView = document.getElementById('chat-messages-view');
        // MessageRow height + margin = 46px
        chatView.scrollTo({ top: newMsgs * 46, behavior: 'instant' });
      };
      messageFromChatOn(profile?.id, messageFromChatOnCallback);
    }

    return () => {
      messageFromChatContactOff(profile?.id, contactId);
      messageFromChatOff(profile?.id);
    };
  }, [profile]);

  const handleOldMessages = () => {
    messageFromChatEmit(
      // eslint-disable-next-line react/prop-types
      profile?.id,
      contactId,
      chatMessages.length,
      setLoading
    );
  };

  return (
    <Box className={classes.wrapper} key={contactId}>
      <Box className={classes.header}>
        <ChatHeader
          key={contactId}
          handleOpenInfo={() => {
            setOpenInfo(!openInfo);
          }}
          profile={{
            name:
              currentContact?.first_name && currentContact?.last_name
                ? `${currentContact?.first_name} ${currentContact?.last_name}`
                : null,
            contact_attributes: {
              login: currentContact?.login
            },
            thumbnails: currentContact?.thumbnails
          }}
          returnToList={() => {}}
          isModal={false}
        />
      </Box>

      <Box
        className={classes.body}
        id="chat-messages-view"
        onScroll={handleScrollChange}
      >
        <Box className={classes.pressContentBody} />
        <Box className={classes.contentBody}>
          {loading && (
            <Typography className={classes.loadingMessage}>
              Carregando mensagens...
            </Typography>
          )}
          {!loading && chatMessages.length <= 0 && (
            <Typography className={classes.loadingMessage}>
              Ainda não há conversa entre vocês...
            </Typography>
          )}
          {chatMessages.length > 0 &&
            chatMessages?.map((message, index) => {
              return (
                <MessageRow
                  key={index}
                  sender={message?.sender_id === profile?.id}
                  text={message.body}
                  message={message}
                  // eslint-disable-next-line react/prop-types
                  contactId={contact_id}
                  userId={profile?.id}
                  showAvatar={
                    // index === chatMessages?.length - 1 ||
                    message.sender_id !== chatMessages.at(index + 1)?.sender_id
                  }
                />
              );
            })}
        </Box>
      </Box>

      <Box className={classes.footer}>
        <ConversationMessagesFooter
          senderId={profile?.id}
          contactId={contact_id}
          contact={currentContact}
        />
      </Box>
      {currentContact && (
        <ChatContactInfo
          open={openInfo}
          handleClose={() => setOpenInfo(false)}
          contact={{
            contact_id: currentContact.id,
            display_name: currentContact.display_name,
            thumbnails: currentContact.thumbnails,
            login: currentContact.login
          }}
          isModal={false}
        />
      )}
    </Box>
  );
};
