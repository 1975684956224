/* eslint-disable react/prop-types */
import React from 'react';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box, TextField, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputMaskDigits = ({
  input,
  label,
  meta: { touched, error },
  disabledBorderColor,
  showErrors,
  colorScheme,
  multiline,
  placeholder,
  custom,
  meta,
  errorOnTheRight,
  Mask,
  variant = 'label',
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles({ colorScheme, theme, disabledBorderColor });
  const displayError = !!((touched || showErrors) && !!error);

  return (
    <Box className={classes.root}>
      <Text
        variant={variant}
        color={colorScheme === 'primaryBg' ? 'white' : 'gray'}
      >
        {label}
      </Text>
      <TextField
        error={displayError}
        className={`${classes.textarea}`}
        placeholder={placeholder}
        variant="outlined"
        multiline={multiline}
        rows={1}
        rowsMax={3}
        disabled={false}
        InputProps={{
          classes: {
            input: classes.input
          },
          inputComponent: Mask
        }}
        {...input}
        {...custom}
        {...rest}
      />
      {displayError && (
        <div
          className={
            errorOnTheRight ? classes.helperTextRight : classes.errorText
          }
        >
          <Text
            color={colorScheme === 'primaryBg' ? 'white' : 'error'}
            variant="mediumText"
          >
            {meta.error}
          </Text>
        </div>
      )}
    </Box>
  );
};

export default InputMaskDigits;

InputMaskDigits.propTypes = {
  input: PropTypes.instanceOf(TextField),
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  multiline: PropTypes.bool,
  custom: PropTypes.shape(),
  placeholder: PropTypes.string,
  showErrors: PropTypes.bool,
  errorOnTheRight: PropTypes.bool,
  onChange: PropTypes.func,
  rest: PropTypes.shape(),
  colorScheme: PropTypes.string,
  disabledBorderColor: PropTypes.string,
  Mask: PropTypes.object
};
InputMaskDigits.defaultProps = {
  input: {},
  label: '',
  meta: {
    touched: false,
    error: ''
  },
  multiline: false,
  custom: null,
  placeholder: '',
  showErrors: false,
  errorOnTheRight: false,
  onChange: null,
  rest: {},
  colorScheme: 'default',
  disabledBorderColor: 'rgba(0, 0, 0, 0.26)',
  Mask: {}
};
