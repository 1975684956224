import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  content: {
    padding: 0,
    width: 800,
    '@media (max-width: 950px)': {
      width: 500
    },
    '@media (max-width: 600px)': {
      width: 250
    }
  },
  imageContainer: {
    marginBottom: 40,
    '@media (max-width: 600px)': {
      marginBottom: 20,
    },
    '& img': {
      maxWidth: 270,
      width: '50%',
      height: 'auto'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    '& p': {
      marginBottom: 25,
      '@media (max-width: 600px)': {
        marginBottom: 15,
      }
    },
  },
});
