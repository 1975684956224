import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import TextBox from '../TextBox';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

const PrivacyPolicy = ({ isModal }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const client = settings.name;
  const platformName =
    client === 'AVAMEC Interativo' ? 'AVAMEC Interativo' : 'Redu';
  const clientMaintainer =
    client === 'AVAMEC Interativo'
      ? 'UFPE - Universidade Federal de Pernambuco'
      : 'Viitra';
  const updatedDate =
    client === 'AVAMEC Interativo' ? '06/06/2024' : '25/05/2022';

  return (
    <Box className={isModal ? classes.rootModal : classes.root}>
      <Box className={isModal ? classes.titleModal : classes.title}>
        <Text
          style={{
            color: theme.palette.activeText.main,
            fontSize: 20,
            fontWeight: '700'
          }}
          variant="ppTitle"
        >
          {i18n.t('PrivacyPolicy')}
        </Text>
      </Box>
      <Box className={isModal ? classes.contentModal : classes.content}>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            Preâmbulo
          </Text>
        </Box>
        <TextBox>
          Esta política estabelece o compromisso da {clientMaintainer}, agente
          de tratamento de dados pessoais, responsável pela{' '}
          <strong>Plataforma {platformName}</strong>, cuja razão social é{' '}
          {client == 'AVAMEC Interativo'
            ? 'UFPE - Universidade Federal de Pernambuco, CNPJ sob o nº 24.134.488/0001-08, doravante mantenedora, com sede à Av. Prof. Moraes Rego, 1235 - Cidade Universitária, Recife - PE, 50670-901'
            : 'VIITRA INOVAÇÕES TECNOLÓGICAS LTDA, CNPJ no 18.217.632.0001-77, com sede à Rua Acadêmico Hélio Ramos, 60, sala 13, bairro da Várzea, Recife/PE, CEP 50.740-533'}
          , com a sua privacidade. É muito importante que qualquer{' '}
          <strong>usuário</strong> da <strong>Plataforma {platformName}</strong>{' '}
          leia e compreenda estas regras, bem como outras regras que possam ser
          aplicáveis, incluindo a Lei nº 12.965/2014 (“Marco Civil da Internet”)
          e a Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).
        </TextBox>
        <TextBox>
          Esta política pode ser alterada a qualquer momento, de tempos em
          tempos, caso em que os <strong>usuários</strong> serão informados da
          mudança por meio da data de atualização na parte inferior deste
          instrumento.
        </TextBox>
        <TextBox>
          Caso os <strong>usuários</strong> não concordem com quaisquer das
          alterações realizadas pela {clientMaintainer} com relação à plataforma{' '}
          {platformName}, recomendamos que a utilização dos serviços do{' '}
          {platformName} sejam interrompidos e/ou que os seus{' '}
          <strong>usuários</strong> encerrem suas contas.
        </TextBox>
        <TextBox>
          Caso os <strong>usuários</strong> não pratiquem as medidas sugeridas e
          previstas acima, considerar-se-á aceita a versão mais atualizada da
          política, cuja data de atualização estará sempre prevista na parte
          inferior deste instrumento.
        </TextBox>
        <TextBox>
          Consideram-se <strong>usuários</strong> os clientes pessoas jurídicas
          da {clientMaintainer} que utilizam a plataforma {platformName} da
          forma disponível em{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>
          , em formato de whitelabel e/ou por implantação personalizada, bem
          como os <strong>usuários</strong> finais da plataforma {platformName},
          os quais são geralmente vinculados diretamente ao{' '}
          <strong>usuários</strong> cliente da {clientMaintainer} (a exemplo de
          Aluno, Professor, Tutor, Administrador de Ambiente e Administrador de
          Plataforma), estando ambos condicionados à anuência e cumprimento do
          presente instrumento, no que lhes couber.
        </TextBox>
        <TextBox>
          A {clientMaintainer} zela pela confidencialidade, privacidade e
          proteção dos dados pessoais dos <strong>usuários</strong> da{' '}
          <strong>Plataforma {platformName}</strong>, sempre buscando empreender
          proteção a respeito de perdas, uso impróprio, acesso não autorizado,
          alteração e destruição, mantendo assim a relação de confiança,
          respeito e transparência construída junto a seu público no seu projeto
          de oferecimento de soluções tecnológicas de ponta.
        </TextBox>
        <TextBox>
          A presente política abrange o tratamento que a {clientMaintainer} dá
          às informações que identificam ou que são capazes de identificar os{' '}
          <strong>usuários</strong> na{' '}
          <strong>Plataforma {platformName}</strong>. A entrada de dados
          pessoais do <strong>usuário</strong> no portal{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>{' '}
          para fins de recebimento da newsletter, bem como a aceitação de
          cookies de navegação na referida plataforma não constituem requisito
          para navegar pelo portal, sendo facultativas, ou seja, o{' '}
          <strong>usuário</strong> pode gerenciar os cookies e pode, por sua
          livre escolha, incluir seus dados no link de “cadastro”
          disponibilizado na <strong>Plataforma {platformName}</strong>.
        </TextBox>
        <TextBox>
          O <strong>usuário</strong> que decidir fornecer seus dados pessoais
          declara conhecer e aceitar os termos desta política.
        </TextBox>
        <TextBox>
          Os dados pessoais tratados pela {clientMaintainer} na{' '}
          <strong>Plataforma {platformName}</strong> e suas respectivas
          justificativas constam abaixo como informações necessárias à
          realização da sua finalidade:
        </TextBox>
        <TextBox
          title={`Dados pessoais necessários para registro na plataforma ${platformName} com finalidade de execução de um contrato`}
        >
          Ao criar ou reconfigurar uma conta na plataforma {platformName}, o{' '}
          <strong>usuário</strong> deve fornecer alguns dados pessoais, a
          exemplo de nome, nome de <strong>usuário</strong>, senha e endereço de
          e-mail, sendo o seu nome e nome de <strong>usuário</strong> publicados
          na sua página de perfil em resultados de pesquisa realizados na
          própria plataforma.
        </TextBox>
        <TextBox
          title={`Dados pessoais facultativos para registro na plataforma ${platformName} com finalidade de execução de um contrato`}
        >
          O <strong>usuário</strong> pode fornecer dados pessoais adicionais
          para serem publicadas, a exemplo de uma breve biografia, sua
          localização, a instituição de ensino a que se vincula e/ou uma imagem,
          podendo ser sua biometria facial ou não.
        </TextBox>
        <TextBox
          title={`Mensagens, vídeo-aulas, exames e outras informações públicas, usuários que são seguidos, ambas facultativas, para fins de execução de  um contrato`}
        >
          Considerando que os serviços da {platformName} servem para
          aprimoramento de aprendizagem mediante compartilhamento com outros{' '}
          <strong>usuários</strong>, a maioria das informações postadas dentro
          da plataforma {platformName} são públicas para o contexto de uso da
          sua instituição, sendo facultativo torná-las privadas, estando
          incluídas as mensagens que o <strong>usuário</strong> posta ou
          disponibiliza, bem como os <strong>usuários</strong> seguidos por ele.
        </TextBox>
        <TextBox title="Informações sobre registro de dados">
          Os servidores da {clientMaintainer} para a{' '}
          <strong>plataforma {platformName}</strong> registram a informação
          criada pelo uso dos serviços. O registro de dados pode incluir
          informações como endereço IP, tipo de navegador, o domínio de
          referência, páginas visitadas e os termos de busca. Outras ações, tais
          como a interação com os anúncios, também podem ser incluídas no
          registro de dados. Nesse processo, a{' '}
          <strong>plataforma {platformName}</strong> usa o Google Analytics para
          compreensão acerca da melhor forma de uso dos serviços, mediante
          coleta de dados enviados pelo seu navegador, como parte de uma
          solicitação de página da web, incluindo cookies e o seu endereço IP,
          sendo tal política acessível em{' '}
          <a
            className={classes.link}
            href="https://policies.google.com/privacy?hl=pt-BR"
            rel="noopener noreferrer"
            target="_blank"
          >
            {`https://policies.google.com/privacy?hl=pt-BR`}
          </a>
          .
        </TextBox>
        <TextBox title="Cookies">
          Os cookies são utilizados para coleta de dados adicionais de uso do
          site{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>{' '}
          e para melhorar os serviços da {clientMaintainer} em sua plataforma{' '}
          {platformName}. Um cookie é um pequeno arquivo de dados, dados esses
          transferidos para o disco rígido do seu computador, sendo possível sua
          utilização para melhor compreensão como o <strong>usuário</strong>{' '}
          interage com os serviços, de modo a monitorar o uso agregado dos
          visitantes e tráfego web de roteamento em nossos serviços.
        </TextBox>

        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            1. Proteção e privacidade dos dados
          </Text>
        </Box>
        <TextBox>
          <strong>1.1.</strong> As informações solicitadas aos{' '}
          <strong>usuários</strong> são as mínimas necessárias para a
          disponibilização dos serviços da{' '}
          <strong>plataforma {platformName}</strong> para {clientMaintainer} e
          são usadas exclusivamente para a finalidade indicada no ponto de
          coleta e/ou descritas nesta política, sempre de acordo aos princípios
          previstos no artigo 6º da Lei nº 13.709/2018 (“Lei Geral de Proteção
          de Dados Pessoais”). Quando necessário, ficam armazenadas em bases de
          dados de terceiros, o que é feito sempre em ambiente seguro e conforme
          preceitos do mesmo dispositivo legal já citado, pois ficam armazenados
          em localidades com o mesmo grau de proteção prevista na LGPD.
        </TextBox>
        <TextBox>
          <strong>1.2.</strong> No âmbito da utilização da{' '}
          <strong>plataforma {platformName}</strong> por seus{' '}
          <strong>usuários</strong>, a {clientMaintainer} se reserva o direito
          de tratar Dados Pessoais, estando todos conforme as definições da Lei
          nº 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”), sendo
          facultado o tratamento de forma anonimizada, sempre que for possível,
          de modo a garantir a confidencialidade dos dados dos{' '}
          <strong>usuários</strong>.
        </TextBox>
        <TextBox>
          <strong>1.3.</strong> Os Dados Pessoais poderão ser tratados com a
          finalidade de evitar falsidade ideológica e fraude de identidade e,
          quando for aplicável, se dará com fundamento no consentimento do{' '}
          <strong>usuário</strong>, conforme definição do artigo 7º da Lei nº
          13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”) e/ou com
          subsídio em outras bases legais previstas na referida lei.
        </TextBox>
        <TextBox>
          <strong>1.4.</strong> O compartilhamento de Dados Pessoais ocorrerá em
          respeito à Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados
          Pessoais) para atingir as finalidades de viabilização de segurança ao
          banco de dados tratados. Além disso, os dados poderão ser
          compartilhados com empresas parceiras a fim de cumprimento dos
          serviços oferecidos pela {clientMaintainer}.
        </TextBox>
        <TextBox>
          <strong>1.5.</strong> Os colaboradores da {clientMaintainer} para
          viabilização da <strong>plataforma {platformName}</strong> e seus
          parceiros comerciais envolvidos nos processos têm conhecimento dessa
          política, apenas o pessoal qualificado e autorizado tem permissão para
          acessar os dados pessoais coletados, sob pena sanção disciplinar em
          caso de violação das normas previstas pela política.
        </TextBox>
        <TextBox>
          <strong>1.6.</strong> A {clientMaintainer} pode compartilhar os dados
          coletados e as atividades registradas na{' '}
          <strong>plataforma {platformName}</strong>:
        </TextBox>
        <TextBox>
          i. Com autoridades judiciais, administrativas ou governamentais
          competentes, sempre que houver requisição legal das autoridades ou
          ordem judicial;
        </TextBox>
        <TextBox>
          ii. De forma automática em caso de movimentações societárias, como
          fusão, aquisição ou incorporação, e;
        </TextBox>
        <TextBox>
          iii. De forma automática, com os provedores dos serviços contratados
          pela {clientMaintainer} para viabilizar o site{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>{' '}
          e a <strong>plataforma {platformName}</strong>, juntamente com todas
          as suas funcionalidades e serviços disponibilizados.
        </TextBox>
        <TextBox>
          <strong>1.6.1.</strong> Todos os terceiros contratados para a
          finalidade descrita no item 1.6, “iii”, previsto acima, obrigam-se por
          previsão contratual a tratar os dados com confidencialidade e somente
          para a finalidade contratada, buscando o cumprimento legal em matéria
          de privacidade e proteção de dados e utilizando, em todo o tempo, as
          práticas adequadas de segurança da informação.
        </TextBox>
        <TextBox>
          <strong>1.7.</strong> Como visto no item 1.4, a{' '}
          <strong>plataforma {platformName}</strong> por meio {clientMaintainer}{' '}
          poderá compartilhar os Dados Pessoais Sensíveis mediante inequívoca e
          expressa autorização, quando for aplicável, bem como Dados Pessoais,
          para as finalidades previstas no item 1.6, sempre consoante a Lei
          Geral de Proteção de Dados.
        </TextBox>
        <TextBox>
          <strong>1.7.1.</strong> O compartilhamento dos dados pessoais servirá
          para fornecer aos <strong>usuários</strong> o produto e/ou serviços
          solicitados.
        </TextBox>
        <TextBox>
          <strong>1.8.</strong> É de responsabilidade do{' '}
          <strong>usuário</strong> a guarda de sua senha e de seu login de
          acesso. Não é adequada a utilização de senhas óbvias, como datas
          especiais, nomes ou sequências numéricas. Caso o{' '}
          <strong>usuário</strong> tome conhecimento ou apenas suspeite que sua
          senha foi descoberta, ele deverá alterá-la em sua página de cadastro
          imediatamente.
        </TextBox>
        <TextBox>
          <strong>1.9.</strong> Os dados e informações tratados nos termos desta
          política são armazenados sob as mais rígidas práticas de segurança de
          informação. Todo o tratamento é rigorosamente supervisionado e
          protegido, de modo que apenas colaboradores habilitados possuem
          acesso, os quais são contratualmente obrigados a preservar o sigilo e
          confidencialidade das informações acessadas, e compõem o banco de
          dados.
        </TextBox>
        <TextBox>
          <strong>1.10.</strong> A {clientMaintainer} se compromete a sempre
          envidar seus melhores esforços para garantir que as informações e
          dados pessoais inseridos na <strong>plataforma {platformName}</strong>{' '}
          sejam sempre tratados conforme o estabelecido nesta política, bem como
          em todas as leis e regulamentações aplicáveis.
        </TextBox>
        <TextBox>
          <strong>1.11.</strong> É possível o compartilhamento e/ou divulgação
          de informações e/ou dados pessoais que não são privadas, ou seja, são
          públicos por estarem divulgadas na{' '}
          <strong>plataforma {platformName}</strong>, a exemplo de dados de
          perfil pessoal, comentários realizados na{' '}
          <strong>plataforma {platformName}</strong> durante a aula ou o número
          de <strong>usuários</strong> que clicaram em um link específico.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            2. Como solicitar a exclusão dos seus dados
          </Text>
        </Box>
        <TextBox>
          <strong>2.1.</strong> No caso de existência de prévio consentimento
          para a {clientMaintainer}
          tratar os seus dados pessoais na{' '}
          <strong>plataforma {platformName}</strong>, você poderá exercer o seu
          direito de revogação do referido consentimento e solicitar a
          eliminação dos seus dados por meio de envio de e-mail para o nosso
          Data Protection Officer (
          <a
            className={classes.link}
            href={
              client === 'AVAMEC Interativo'
                ? 'mailto:v-lab@cin.ufpe.br'
                : 'mailto:dpo@viitra.com'
            }
            title="dpo@viitra.com"
          >
            {client === 'AVAMEC Interativo'
              ? 'v-lab@cin.ufpe.br'
              : 'dpo@viitra.com'}
          </a>
          ). No entanto, independentemente do consentimento, nosso{' '}
          <strong>usuário</strong> poderá exercer os seus direitos de titular de
          dados pelo mesmo canal de relacionamento citado anteriormente, o qual
          tratará exclusivamente de questões referentes ao exercício de direitos
          de titular de dados previstos na Lei Geral de Proteção de Dados
          Pessoais. A {clientMaintainer} estará sujeita aos requisitos legais
          para retenção de certas informações e/ou dados pessoais, a exemplo de
          uma obrigação legal, cumprimento de contrato, legítimo interesse,
          dentre outras hipóteses previstas na Lei 13.709/2018 (Lei Geral de
          Proteção de Dados).
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            3. Dados do encarregado de dados pessoais da {clientMaintainer} para
            a plataforma {platformName} para fins de exercício de direito de
            titular de dados pessoais
          </Text>
        </Box>
        <TextBox>
          Aqui, você encontrará algumas informações gerais, como, por exemplo, o
          contato do Data Protection Officer (DPO) do {clientMaintainer}.
        </TextBox>
        <TextBox>
          <strong>3.1.</strong> A {clientMaintainer} não utiliza nenhum tipo de
          decisão automatizada que impacte o <strong>usuário</strong> da{' '}
          <strong>plataforma {platformName}</strong>.
        </TextBox>
        <TextBox>
          <strong>3.2.</strong> Em caso de qualquer dúvida com relação às
          disposições constantes nesta política de Privacidade ou necessidade de
          contato com o Encarregado pelo Tratamento de Dados Pessoais (Data
          Protection Officer - DPO da {clientMaintainer}) para fins de exercício
          dos direitos de titulares de dados previstos no Capítulo III da Lei
          Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018), o{' '}
          <strong>usuário</strong> poderá entrar em contato com pelo e-mail{' '}
          <a
            className={classes.link}
            href={
              client === 'AVAMEC Interativo'
                ? 'mailto:v-lab@cin.ufpe.br'
                : 'mailto:dpo@viitra.com'
            }
            title="dpo@viitra.com"
          >
            {client === 'AVAMEC Interativo'
              ? 'v-lab@cin.ufpe.br'
              : 'dpo@viitra.com'}
          </a>
          , sendo que as respostas ao contato serão enviadas entre segunda e
          sexta-feira, no horário comercial, exceto em feriados.
        </TextBox>
        <TextBox>
          <strong>3.3.</strong> Caso empresas terceirizadas realizem o
          tratamento de quaisquer dados coletados pela {clientMaintainer},
          deverão respeitar as condições aqui estipuladas e as práticas
          adequadas de segurança da informação, obrigatoriamente.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            4. Alterações da nossa política de Privacidade
          </Text>
        </Box>
        <TextBox>
          <strong>4.1.</strong> Esta política de Privacidade está sujeita a
          constante melhoria e aprimoramento. Assim, recomendamos sua leitura
          periódica.
        </TextBox>
        <TextBox>
          <strong>4.2.</strong> A {clientMaintainer} se reserva ao direito de
          modificar a qualquer momento, visando uma melhoria contínua, a
          presente política de Privacidade, observando a comunicação ampla e
          prévia desta alteração aos <strong>usuários</strong> do serviço.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            5. Canal para dúvidas e sugestões do usuário que não sejam
            referentes ao tratamento dos seus dados pessoais
          </Text>
        </Box>
        <TextBox>
          <strong>5.1.</strong> Em caso de perguntas ou sugestões que não sejam
          referentes a dados pessoais, por favor, envie-nos uma mensagem através
          do link{' '}
          <a
            className={classes.link}
            href={
              client === 'AVAMEC Interativo'
                ? 'https://avamecinterativo.mec.gov.br/app/contato'
                : 'https://redu.digital/app/contato'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {client === 'AVAMEC Interativo'
              ? 'https://avamecinterativo.mec.gov.br/app/contato'
              : 'https://redu.digital/app/contato'}
          </a>
        </TextBox>
        {client !== 'AVAMEC Interativo' && (
          <>
            <Box className={classes.subtitle}>
              <Text
                style={{
                  color: theme.palette.activeText.main,
                  fontWeight: '500'
                }}
                variant="lg"
              >
                6. Da disponibilização da plataforma {platformName} por meio de
                Whitelabel e/ou Implantação Personalizada
              </Text>
            </Box>
            <TextBox>
              <strong>6.1.</strong> Além da disponibilização da plataforma{' '}
              {platformName} por meio de acesso ao{' '}
              <a
                className={classes.link}
                href={settings.baseUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`${settings.baseUrl}`}
              </a>
              , a Viitra também disponibiliza aos seus <strong>usuários</strong>{' '}
              clientes a plataforma {platformName} por meio de whitelabel e/ou
              implantação personalizada, com as seguintes características:
            </TextBox>
            <TextBox>
              Whitelabel: apenas muda a interface do dashboard de acesso à
              plataforma, o qual ficará com elementos identificadores do{' '}
              <strong>usuário</strong> cliente, a exemplo de marca e/ou
              logotipo, dentre outros detalhes.
            </TextBox>
            <TextBox>
              Implantação personalizada: a implantação da plataforma Web
              ocorrerá em infraestrutura nas nuvens. Com a implantação da
              plataforma {platformName} na nuvem, uma cópia da plataforma será
              disponibilizada em endereço requisitado pelo{' '}
              <strong>usuário</strong> cliente da Viitra, geralmente contratante
              da plataforma. Também estará disponível um aplicativo para
              dispositivos móveis com sistemas operacionais Android e iOS para
              download na Google Play e Apple Store.
            </TextBox>
            <TextBox>
              <strong>6.2.</strong> Nos casos previstos no item 6.1, os{' '}
              <strong>usuários</strong> clientes da Viitra são controladores dos
              dados pessoais e Viitra é operadora dos dados pessoais.
            </TextBox>
            <TextBox>
              <strong>6.3.</strong> Os <strong>usuários</strong>, sejam eles{' '}
              <strong>usuários</strong> contratantes da Viitra, sejam meramente{' '}
              <strong>usuários</strong> finais da plataforma, sendo esses
              últimos geralmente vinculados ao contratante da plataforma{' '}
              <strong>{platformName}</strong>, estarão vinculados ao cumprimento
              da presente política de privacidade.
            </TextBox>
            <Box className={classes.subtitle}>
              <Text
                style={{
                  color: theme.palette.activeText.main,
                  fontWeight: '500'
                }}
                variant="lg"
              >
                7. Casos omissos
              </Text>
            </Box>
            <TextBox>
              <strong>7.1.</strong> A política de privacidade do agente de
              tratamento Viitra disponibilizada em{' '}
              <a
                className={classes.link}
                href="https://www.viitra.com/home"
                rel="noopener noreferrer"
                target="_blank"
              >
                {`https://www.viitra.com/home`}
              </a>
              tratará os casos eventualmente omissos nesta política de
              privacidade da <strong>plataforma {platformName}</strong>.
            </TextBox>
          </>
        )}
        <Box className={classes.subtitle} style={{ textAlign: 'center' }}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            Atualizado em {updatedDate}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
