import React from 'react';

import Text from '~/components/atoms/Text';
import { TipButton } from '~/components/molecules/TipButton';
import history from '~/services/history';

import { ContainerButton, useStyles } from './styles';
import { Box, ButtonBase } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PropTypes from 'prop-types';

export default function Grid({
  buttonTitle,
  title,
  children,
  targetRoute,
  marginBottom,
  tipMessage
}) {
  const classes = useStyles();
  return (
    <Box style={{ marginBottom }}>
      <Box className={classes.titleBox}>
        <Text variant="sideBarTitle" color="white" fontWeight={500}>
          {title}
        </Text>
        {Boolean(tipMessage) && <TipButton tipMessage={tipMessage} />}
      </Box>
      {children}
      {buttonTitle && (
        <ContainerButton>
          <ButtonBase
            className={classes.showAllButton}
            disableRipple
            onClick={() => history.push(targetRoute)}
          >
            {buttonTitle && (
              <>
                <Text variant="sideBarTitle" color="white" fontWeight={400}>
                  {buttonTitle}
                </Text>
                <ArrowForwardIosRoundedIcon
                  style={{
                    color: 'white',
                    fontSize: 14,
                    marginLeft: 2,
                    marginTop: '2px'
                  }}
                />
              </>
            )}
          </ButtonBase>
        </ContainerButton>
      )}
    </Box>
  );
}

Grid.propTypes = {
  buttonTitle: PropTypes.string,
  title: PropTypes.string,
  targetRoute: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  marginBottom: PropTypes.string,
  tipMessage: PropTypes.string
};

Grid.defaultProps = {
  buttonTitle: '',
  title: '',
  targetRoute: '#',
  children: null,
  marginBottom: 0
};
