import api from '~/services/api';

const baseURL = '/api/users';

// Creates a new user in the system.
export const create = async (newUserData, environmentId) => {
  const { data } = await api.post(baseURL, {
    user: newUserData,
    environment_id: environmentId
  });
  return data;
};

// Creates a new user in the system.
export const update = async (userID, userAttrs) => {
  const { data } = await api.put(baseURL + `/${userID}`, { user: userAttrs });
  return data;
};

// Retrieves a user's information based on their email address.
export const findByEmail = async (email) => {
  const { data } = await api.get(`${baseURL}/find_by_email/?email=${email}`); // Note: The endpoint might need adjustment for proper email-based search functionality.
  return data;
};

// Verifies if a user is linked to a specific structure, such as a course or an environment.
export const checkAssociation = async (structureID, userID, structureType) => {
  const endpoint = `${baseURL}/${userID}/${structureType}s/${structureID}/user_${structureType}_association`;
  const { data } = await api.get(endpoint);
  return data;
};

// Fetches details about a member's association with a structure using the member's ID.
export const findMemberInStructureByID = async (
  structureID,
  memberID,
  structureType
) => {
  const endpoint = `${baseURL}/${memberID}/${structureType}s/${structureID}/user_${structureType}_association`;
  const { data } = await api.get(endpoint);
  return data;
};

// Refatorar esta rota no backend. Não faz sentido passar o ID do usuário logado, uma vez
// que o back recebe esta informação através do "current_user"
export const getConnectionWithFriend = async (userID, friendID) => {
  const { data } = await api.get(
    `${baseURL}/${userID}/connections/${friendID}/check`
  );
  return data;
};

// Refatorar esta rota no backend. Não faz sentido passar o ID do usuário logado, uma vez
// que o back recebe esta informação através do "current_user"
export const sendConnectionRequestToFriend = async (userID, friendID) => {
  const { data } = await api.post(`${baseURL}/${userID}/connections`, {
    connection: {
      contact_id: friendID
    }
  });
  return data;
};

// Exports the functions for use in other parts of the application.
export default {
  findByEmail,
  create,
  checkAssociation,
  findMemberInStructureByID
};
