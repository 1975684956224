import React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { CancelOutlined }                                                from '@material-ui/icons';

import i18n from '~/I18n';

import { useStyles } from './styles';

export default function ErrorAlert ({ message, open, setOpen }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
     
      <Box className={classes.containerIcon}>
        <CancelOutlined className={classes.icon} />
      </Box>
     
      <Box className={classes.containerTitle}>
        <Typography className={classes.title}>{i18n.t('error')}!</Typography>
      </Box>
      
      <DialogContent className={classes.content}>
        <Typography className={classes.message}> { message } </Typography>
      </DialogContent>
      
      <Box className={classes.containerActions}>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className={classes.closeButton} >
            {i18n.t('close')}
          </Button>
        </DialogActions>
      </Box>
    
    </Dialog>
  );
};