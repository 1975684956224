import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '104px',
    height: '24px',
    padding: '5px 4px',
    borderRadius: '4px',
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0',
    margin: (props) =>
      props.type === 'course' || props.type === 'space' ? '5px' : '0'
  },
  text: {
    fontSize: '12px',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.primary.main
  },
  icon: {
    color: theme.palette.primary.main,
    width: '14px',
    height: '14px'
  }
}));
