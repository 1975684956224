import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  homeIcon: {
    fill: (props) => props.theme.palette.primary.main,
    fontSize: 18
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    maxWidth: (props) => `${props.maxLinkWidth}%`,
    minWidth: 30,
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      '&:hover': {
        color: (props) => props.theme.palette.primary.main,
      }
    }
  },
  arrowIcon: {
    fill: 'grey',
    fontSize: 14,
    margin: '0 10px'
  }
});
