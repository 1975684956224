import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import BottomSection from 'src/components/organisms/AudioPlayer/BottomSection';
import TopSection from '~/components/organisms/AudioPlayer/TopSection';

import { useStyles } from './style';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import OwnerTag from 'src/components/organisms/AudioPlayer/TopSection/OwnerTag';

export default function AudioPlayer({ audioSrc, thumbnail, ownerName }) {
  const classes = useStyles();
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);

  const onDuration = (duration) => setDuration(Math.trunc(duration));
  const seekTo = (sec) => playerRef.current.seekTo(sec);
  const updateProgress = ({ playedSeconds }) =>
    setProgress(Math.trunc(playedSeconds));

  return (
    <Box className={classes.root}>
      <img
        className={classes.thumbnail}
        src={thumbnail}
        alt="environment thumbnail"
      />
      <Box className={classes.rightContainer}>
        <ReactPlayer
          style={{ display: 'none' }}
          ref={playerRef}
          url={audioSrc}
          playing={playing}
          onProgress={updateProgress}
          onDuration={onDuration}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          controls={false}
        />

        <TopSection
          ownerName={ownerName}
          volume={volume}
          setVolume={setVolume}
          muted={muted}
          setMuted={setMuted}
        />

        <BottomSection
          playing={playing}
          setPlaying={setPlaying}
          progress={progress}
          setProgress={setProgress}
          duration={duration}
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
          seekTo={seekTo}
        />
      </Box>
    </Box>
  );
}
