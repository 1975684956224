import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import FormattedText from '~/components/atoms/FormattedText';
import Text from '~/components/atoms/Text';
import TextArea from '~/components/atoms/TextArea';
import MuralItem from '~/components/molecules/MuralItem';
import MuralFilesPost from '~/components/organisms/MuralFilesPost';
import i18n from '~/I18n';
import history from '~/services/history';
import {
  handleAddNewAnswerMural,
  sendToMuralAnswerListener,
  createMuralAnswerListener
} from '~/services/mural';
import { Creators as LecturesCreators } from '~/store/ducks/lectures';

import BreadcrumbMuralItem from '../../BreadcrumbMuralItem';
import { useStyles, TimeDistance, CustomAvatar } from '../styles';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme, Box, TextField, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

function Activity({ item, dashboard, lecture, setDeleted, wasDeleted }) {
  const [answers, setAnswers] = useState([]);
  const [hideAnswers, setHideAnswers] = useState(true);
  const [answerText, setAnswerText] = useState('');
  const [reply, setReply] = useState(false);

  const { deletedComment } = useStyles();
  const {
    id: itemId,
    created_at: createdAt,
    text,
    statusable_name: statusableName,
    statusable_type: statusableType,
    statusable_id: statusableId,
    breadcrumbs
  } = item;

  const currentChannel = `channel-${itemId}`;
  const { handleDeletedItemIncrement } = LecturesCreators;

  const { first_name, last_name, id: userId } = item.user || {
    first_name: i18n.t('DeletedUser'),
    last_name: '',
    id: null
  };

  const userProfile = item.user || {};

  const styleProps = { reply, dashboard, lecture };
  const theme = useTheme();
  const classes = useStyles(styleProps, theme);
  const loggedInUser = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setAnswers(item.answers);

    return () => {
      setAnswers([]);
      setHideAnswers(true);
      setAnswerText('');
      setReply(false);
    };
  }, []);

  async function postAnswer() {
    try {
      const response = await handleAddNewAnswerMural(item.id, {
        status: {
          text: answerText
        }
      });
      sendToMuralAnswerListener(currentChannel, response.data);
      setAnswerText('');
      setReply(false);
      // setAnswers([...answers, response.data]);
    } catch (error) {
      const notFound = error.response.status === 404;
      if (notFound) {
        dispatch(handleDeletedItemIncrement(item.id));

        setDeleted(true);
        setHideAnswers(true);
      }
    }
  }

  function handleClickAnswerButton() {
    if (answerText.trim().length) {
      postAnswer();
    }
  }

  useEffect(() => {
    // getAnswers();
    createMuralAnswerListener(currentChannel, (data) => {
      setAnswers((currAnswers) => [data, ...currAnswers]);
    });
    //callback pra informar q foi deletado
    //passando item q foi deletado e filtrar
    // return () => setAnswers([]);
  }, [lecture]);

  function SpaceLink({ children }) {
    if (item && item.breadcrumbs) {
      const { space_id } = item.breadcrumbs.space || {};
      const { environment_id, environment_name } =
        item.breadcrumbs.environment || {};
      return (
        <Link
          to={{
            pathname: `/dashboard/spaces/${space_id}`,
            state: {
              environmentPath: `/dashboard/environments/${environment_id}`,
              environmentName: `${environment_name}`
            }
          }}
        >
          {children}
        </Link>
      );
    }

    return <>{children}</>;
  }

  function goToLecture() {
    if (item && item.breadcrumbs) {
      const { lecture_id } = item.breadcrumbs.lecture || {};
      const { space_id, space_name } = item.breadcrumbs.space || {};
      const { environment_id, environment_name } =
        item.breadcrumbs.environment || {};

      history.push({
        pathname: `/dashboard/lecture/${lecture_id}`,
        state: {
          environmentPath: `/dashboard/environments/${environment_id}`,
          environmentName: environment_name,
          spacePath: `/dashboard/spaces/${space_id}`,
          spaceName: space_name,
          spaceId: space_id
        }
      });
    }
  }

  function MuralLink({ children }) {
    if (statusableType === 'user') {
      return <Link to={`/profile/${statusableId}`}>{children}</Link>;
    }

    return children;
  }

  return (
    <Box className={classes.outerContainer}>
      {!lecture && (
        <Box style={{ marginBottom: '16px' }}>
          <BreadcrumbMuralItem breadcrumbs={breadcrumbs} />
        </Box>
      )}
      <Box
        className={`${classes.itemContainer} ${wasDeleted && deletedComment}`}
      >
        <CustomAvatar profile={userProfile} lecture={lecture} />
        <Box className={classes.infoContainer}>
          <Box className={classes.actionContainer}>
            <Box className={classes.action}>
              <Link to={userId ? `/profile/${userId}` : '#'}>
                <Text
                  color={wasDeleted ? 'gray' : 'accent'}
                  variant="actionText"
                >
                  {`${first_name} ${last_name}`}
                </Text>
              </Link>
              {!lecture && (
                <>
                  {statusableType === 'lecture' ? (
                    <>
                      <Text color="gray" variant="actionText">
                        {i18n.t('commentedLecture')}
                      </Text>
                      <Box
                        onClick={goToLecture}
                        style={{ display: 'inline', cursor: 'pointer' }}
                      >
                        <Text color="accent" variant="actionText">
                          {statusableName}
                        </Text>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Text color="gray" variant="actionText">
                        {i18n.t('commentedOn')}
                      </Text>
                      <MuralLink>
                        <Text color="accent" variant="actionText">
                          {i18n.t('Mural')}
                        </Text>
                      </MuralLink>
                    </>
                  )}
                </>
              )}
              {statusableType === 'space' ? (
                <>
                  <Text color="gray" variant="actionText">
                    {i18n.t('ofTheSpace')}
                  </Text>
                  <SpaceLink>
                    <Text color="accent" variant="actionText">
                      {statusableName}
                    </Text>
                  </SpaceLink>
                </>
              ) : (
                false
              )}
              {statusableType === 'user' ? (
                <>
                  <Text color="gray" variant="actionText">
                    {i18n.t('of')}
                  </Text>
                  <Link to={`/profile/${statusableId}`}>
                    <Text color="accent" variant="actionText">
                      {statusableName}
                    </Text>
                  </Link>
                </>
              ) : (
                false
              )}
            </Box>
            {!wasDeleted && <TimeDistance date={createdAt} />}
          </Box>
          <Box className={classes.textContainer}>
            <Text color="gray" variant="actionText">
              {wasDeleted && 'Esse comentário foi apagado por seu autor.'}
              {!wasDeleted && <FormattedText text={text} />}
            </Text>
            <MuralFilesPost item={item} lecture={lecture} />
          </Box>
          {!wasDeleted && (
            <ButtonSquare
              onClick={() => {
                setReply(true);
              }}
              padding="4px 0"
            >
              <Text color="primary" variant="textBold">
                {i18n.t('Reply')}
              </Text>
            </ButtonSquare>
          )}
          {reply && !wasDeleted && (
            <Box
              className={classes.replyContainer}
              style={{ filter: wasDeleted ? 'grayscale(100%)' : 'none' }}
            >
              <CustomAvatar profile={loggedInUser.profile} lecture={lecture} />
              <Box className={classes.replyContent}>
                <Box className={classes.textFieldContainer}>
                  <TextField
                    id="standard-basic"
                    placeholder={i18n.t('AddReply')}
                    fullWidth
                    className={classes.answerTextField}
                    onChange={(e) => setAnswerText(e.target.value)}
                  />
                </Box>
                <Box className={classes.textAreaContainer}>
                  <TextArea
                    placeholder={i18n.t('AddReply')}
                    width="100%"
                    height="50px"
                    resize="none"
                    onChange={(e) => setAnswerText(e.target.value)}
                  />
                </Box>
                <Box className={classes.replyButtons}>
                  <ButtonSquare
                    width="100px"
                    height="50px"
                    variant="outlined"
                    border={`0.5px solid ${theme.palette.secondary.main}`}
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    <Text variant="sideBarTitle" color="secondary">
                      {i18n.t('Cancel')}
                    </Text>
                  </ButtonSquare>
                  <ButtonSquare
                    width="100px"
                    height="50px"
                    marginLeft="15px"
                    backgroundColor={theme.palette.secondary.main}
                    onClick={() => handleClickAnswerButton()}
                  >
                    <Text variant="sideBarTitle" color="white">
                      {i18n.t('Reply')}
                    </Text>
                  </ButtonSquare>
                </Box>
                <Box className={classes.smReplyButtons}>
                  <Button
                    className={classes.smButtonCancel}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    <CloseIcon
                      style={{
                        fill: theme.palette.secondary.main,
                        fontSize: 16
                      }}
                    />
                  </Button>
                  <Button
                    className={classes.smButtonCheck}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      handleClickAnswerButton();
                    }}
                  >
                    <CheckIcon style={{ fill: 'white', fontSize: 16 }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {answers && answers.length > 0 && !wasDeleted ? (
            <ButtonSquare
              onClick={() => {
                if (hideAnswers) setHideAnswers(false);
                else setHideAnswers(true);
              }}
              className={classes.hideAnswers}
              padding="4px 0"
            >
              {!hideAnswers ? (
                <FontAwesomeIcon
                  icon={faCaretUp}
                  color={theme.palette.primary.main}
                  style={{
                    fontSize: 22,
                    marginBottom: 2,
                    marginRight: 10
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  color={theme.palette.primary.main}
                  style={{
                    fontSize: 22,
                    marginBottom: 2,
                    marginRight: 10
                  }}
                />
              )}
              <Text color="primary" variant="textBold">
                {hideAnswers ? i18n.t('ShowReplies') : i18n.t('HideReplies')}
              </Text>
            </ButtonSquare>
          ) : (
            false
          )}
          {answers && !hideAnswers
            ? answers.map((answer) => (
                <MuralItem
                  key={answer.id}
                  item={answer}
                  lecture={lecture}
                  setAnswers={setAnswers}
                />
              ))
            : false}
        </Box>
      </Box>
    </Box>
  );
}

Activity.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    text: PropTypes.string,
    statusable_name: PropTypes.string,
    statusable_type: PropTypes.string,
    statusable_id: PropTypes.number,
    breadcrumbs: PropTypes.shape({
      space: PropTypes.shape({
        space_id: PropTypes.number,
        space_name: PropTypes.string
      }),
      environment: PropTypes.shape({
        environment_id: PropTypes.number,
        environment_name: PropTypes.string
      }),
      lecture: PropTypes.shape({
        lecture_id: PropTypes.number
      })
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number
    }),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  }),
  dashboard: PropTypes.bool,
  lecture: PropTypes.bool,
  setDeleted: PropTypes.func,
  wasDeleted: PropTypes.bool
};

Activity.defaultProps = {
  item: {},
  dashboard: undefined,
  lecture: undefined,
  setDeleted: () => {},
  wasDeleted: false
};

export default Activity;
