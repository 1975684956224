/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import FormFilter from '~/components/FormFilter';
import OrderByPopover from '~/components/OrderByPopover';
import i18n from '~/I18n';
import { getFilters } from '~/services/environment';

import { Filters } from './Filters';
import { MobileFilters } from './MobileFilters';
import { useStyles } from './styles';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';

export function HeaderSearchAndFilters({
  fetchEnvironments,
  setEnvironments,
  setHasMore,
  setPage
}) {
  const { watch, setValue, getValues, reset } = useFormContext();
  const classes = useStyles();
  const theme = useTheme();
  const [states, setStates] = useState([]);
  const [regionalGroups, setRegionalGroups] = useState([]);
  const [cities, setCities] = useState([]);

  const disableClearFilters = useMemo(
    () => !watch('state') && !watch('regionalGroup') && !watch('city'),
    [watch('state'), watch('regionalGroup'), watch('city')]
  );

  const [lock, setLock] = useState(true);

  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const clearFilters = () => {
    reset({
      ...getValues(),
      state: '',
      regionalGroup: '',
      city: ''
    });
    updateFilteredData();
  };

  useEffect(() => {
    reset({
      ...getValues(),
      ord: watch('ord'),
      direction: watch('direction'),
      name_like: watch('name_like')
    });
    updateFilteredData();
  }, [watch('ord'), watch('direction'), watch('name_like')]);

  const updateFilteredData = () => {
    getFilters(getValues())
      .then((filtersData) => {
        setStates(filtersData.states);
        setRegionalGroups(filtersData.regional_groups);
        setCities(filtersData.cities);
        setLock(false);
      })
      .catch(() => {
        setLock(false);
      });

    setEnvironments([]);
    setPage(1);
    setHasMore(true);

    fetchEnvironments(1, getValues());
  };

  const handleSelectFilters = (selectedFilter, filterType) => {
    if (lock) return;
    setLock(true);

    switch (filterType) {
      case 'state':
        setValue('state', selectedFilter);
        setValue('regionalGroup', '');
        setValue('city', '');

        updateFilteredData();
        break;
      case 'regionalGroup':
        setValue('regionalGroup', selectedFilter);
        setValue('city', '');

        updateFilteredData();
        break;
      case 'city':
        setValue('city', selectedFilter);

        updateFilteredData();
        break;
    }
  };

  return (
    <Box>
      <Box
        className={classes.content}
        style={{
          gap: isXsScreen ? 8 : 24,
          flexDirection: isXsScreen ? 'column' : 'row'
        }}
      >
        <FormFilter
          placeholder={`Pesquisar ${i18n.t(
            'StructuresName.Environment.SingularUpper'
          )}...`}
        />
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {isXsScreen && (
            <MobileFilters
              handleSelectFilters={handleSelectFilters}
              clearFilters={clearFilters}
              states={states}
              regionalGroups={regionalGroups}
              cities={cities}
              disableClearFilters={disableClearFilters}
            />
          )}
          <OrderByPopover />
        </Box>
      </Box>
      {!isXsScreen && (
        <Filters
          handleSelectFilters={handleSelectFilters}
          clearFilters={clearFilters}
          states={states}
          regionalGroups={regionalGroups}
          cities={cities}
          disableClearFilters={disableClearFilters}
        />
      )}
    </Box>
  );
}
