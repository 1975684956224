import { makeStyles } from '@material-ui/core/styles';
/* import { Input, InputLabel } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';*/

export const useStyles = makeStyles({
  mainForm: {
    marginBottom: '30px',
    paddingBottom: '40px',
    borderBottom: '1px solid rgba(0,0,0,0.15)'
  },
  root: {
    padding: 20
  },
  subsection: {
    marginTop: 40,
    marginBottom: 40,
    width: '100%'
  },
  subtitleContainer: {
    marginTop: 5,
    marginBottom: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: 20,
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  submitArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    width: '220px',
    justifyContent: 'space-between'
  },
  typeBox: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteIcon: {
    color: (props) => props.theme.palette.textActiveElement.main,
    width: 20,
    height: 20,
    transition: '.2s ease-in-out',
    '&:hover': {
      color: (props) => props.theme.palette.error.main
    }
  }
});

/*export const EditAvatar = styled(
  ({ handleProfilePhotoChange }) => (
      <InputLabel  
        htmlFor="upload-photo"
        style={{
          width: '33px',
          height: '33px',
        }}
      >
        <Input
          id="upload-photo"
          type="file"
          accept="image/x-png,image/jpeg"
          style={{ display: 'none' }}
          onChange={(event) => handleProfilePhotoChange(event.target.files)}
        />
        <InsertPhotoIcon
          style={{
            width: '100%',
            height: '100%',
            color: '#449DFC',
          }}
        />
      </InputLabel>
  )
)``; */
