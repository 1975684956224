import initialPagesImageBgRecover from '../assets/images/prefacio-bg.png';
import initialPagesImageBgLogin from '../assets/images/prefacio-bg.png';
import logoColorful from '../assets/logos/colorful/prefacio.svg';
import logo from '../assets/logos/logo-prefacio.png';
import { muiCreateTheme } from './mui-create-theme';

const title = 'Prefácio Digital';

const loginLogo = {
  width: 280
};
const appBarLogo = {
  width: 120
};

const imageBackgroundLogin = {
  height: '100%',
  width: '100%'
};

let light = muiCreateTheme({
  size: {
    loginLogo: loginLogo,
    appBarLogo: appBarLogo,
    imageBackgroundLogin: imageBackgroundLogin
  },
  palette: {
    type: 'light',
    primary: {
      main: '#FF9733'
    },
    secondary: {
      main: '#00ACD3'
    },
    tertiary: {
      main: '#99BD3D'
    },
    quaternary: {
      main: '#DAB6FC'
    },
    accent: {
      main: '#00C2FF'
    },
    activeText: {
      main: '#676D82'
    },
    pendent: {
      main: '#75aafb'
    },
    hiperlinkActiveText: {
      main: '#00C2FF'
    },
    textInactiveElement: {
      main: '#f8f8f6'
    },
    textActiveElement: {
      main: '#707070'
    },
    text: {
      main: '#000000'
    },
    textAndBackground: {
      main: '#ffffff'
    },
    warn: {
      main: '#FD9D4D'
    },
    error: {
      main: '#EE1D23'
    },
    shimmer: {
      main: 'rgba(87, 154, 255, 0.43)'
    },
    success: {
      main: '#35998F'
    }
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#f2b705'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#f2b705',
          borderWidth: 2
        },
        '&$focused $notchedOutline': {
          borderColor: '#f2b705'
        }
      },
      notchedOutline: {}
    },
    MuiSelect: {
      icon: {
        fill: '#f2b705'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 1024,
      lg: 1190,
      xl: 1920
    }
  }
});

export default {
  logo,
  logoColorful,
  ...light,
  title,
  initialPagesImageBgLogin,
  initialPagesImageBgRecover
};
