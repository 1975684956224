import React from 'react';
import * as S from './style';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { dynamicTime, formatTime } from '~/utils/dates';

export const LectureMemberReportCard = ({ result }) => {
  return (
    <S.Container>
      {result ? (
        <S.CardsContainer>
          <Text
            style={{ color: 'black', fontSize: '15px', textAlign: 'center' }}
          >
            {i18n.t('MemberName')}
          </Text>
          <Text
            color={'secondary'}
            style={{
              fontSize: '20px',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            {`${result.user.first_name} ${result.user.last_name}`}
          </Text>
          <Text
            style={{ color: 'black', fontSize: '15px', textAlign: 'center' }}
          >
            {i18n.t('MemberGrade')}
          </Text>
          <Text
            color={'secondary'}
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            {result.grade}
          </Text>
          <Text
            style={{ color: 'black', fontSize: '15px', textAlign: 'center' }}
          >
            {i18n.t('MemberTime')}
          </Text>
          <Text
            color={'secondary'}
            style={{ fontSize: '30px', textAlign: 'center' }}
          >
            {formatTime(result.duration)}
          </Text>
          {dynamicTime(result.duration)}
        </S.CardsContainer>
      ) : (
        <S.CardsContainer>
          <Text
            style={{
              display: 'flex',
              textAlign: 'center',
              color: 'rgba(0,0,0,0.3)'
            }}
          >
            {i18n.t('NotDoneYet')}
          </Text>
        </S.CardsContainer>
      )}
    </S.Container>
  );
};

export default LectureMemberReportCard;
