import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    right: 355,
    bottom: 30,
    zIndex: 9999,
    gap: '8px',
    '@media (max-width: 1630px)': {
      right: 300
    },
    '@media (max-width: 1190px)': {
      right: 30
    },
    '@media (max-width: 600px)': {
      display: 'none'
    },
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderRadius: '2px'
    }
  },
  buttons: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`
    },
    '& svg': {
      fontSize: 20,
      color: theme.palette.textAndBackground.main
    }
  },
  notificationCircle: {
    width: 25,
    height: 25
  },
  badge: {
    fontSize: 10,
    color: 'white'
  }
}));
