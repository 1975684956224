import React from 'react';
import * as S from './style'

export const CardContainer = ({ children }) => {

  return (
    <S.ReportContainer>
      {children}
    </S.ReportContainer>
  );
};

export default CardContainer;
