import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const useStyles = makeStyles({
  sidebar: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  infos: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    '@media (max-width: 1024px)': {
      width: '80%'
    },
    '@media (max-width: 600px)': {
      paddingLeft: 25,
      width: '100vw'
    }
  },
  infosHeader: {
    marginTop: '34px',
    marginBottom: '5px',
    maxWidth: '100%',
    '& p': {
      maxWidth: '100%'
    }
  }
});

export const ShimmerText = styled(
  ({ height, width, marginTop, ...otherProps }) => (
    <Skeleton
      {...otherProps}
      height={height}
      animation="pulse"
      width={width}
      style={{
        backgroundColor: (props) => `${props.theme.palette.shimmer.main}`,
        marginTop: `${marginTop}px`
      }}
    />
  )
)``;
