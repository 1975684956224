import api from '~/services/api';
import history from '~/services/history';

import { Creators as AuthActions, Types } from '.';
import { Creators as FollowingActions } from '../following';
import { Creators as OverviewActions } from '../overview';
import { Creators as UserActions } from '../user';
import { stopSubmit, startSubmit } from 'redux-form';
import { all, put, call, takeLatest } from 'redux-saga/effects';

export function* signIn({ login, password, remember }) {
  const { signInSuccess, signInFailure } = AuthActions;
  const { getProfileRequest } = UserActions;
  yield put(startSubmit('LoginForm'));
  try {
    const response = yield call(api.post, 'api/sessions', {
      user_session: {
        login,
        password
      }
    });

    const { sa_token } = response.data;
    if (remember) {
      localStorage.setItem('@openredu_web/sa_token', sa_token);
    }
    api.defaults.headers.Authorization = sa_token;

    yield put(signInSuccess(response.data.sa_token, remember));
    yield put(getProfileRequest());
    history.push('/dashboard');
  } catch (error) {
    if (error?.response?.status === 422) {
      yield put(
        stopSubmit('LoginForm', { _error: 'Usuário e/ou Senha inválido(s)' })
      );
    }
    if (error?.message === 'Network Error') {
      yield put(
        stopSubmit('LoginForm', {
          _error:
            'Não foi possível atender a solicitação, tente novamente mais tarde.'
        })
      );
    }
    yield put(signInFailure());
  }
}

export function* signOut() {
  const { putUserReset } = UserActions;
  const { putOverviewReset } = OverviewActions;
  const { putFollowingReset } = FollowingActions;

  localStorage.removeItem('@openredu_web/sa_token');
  localStorage.removeItem('persist:redu');
  yield put(putUserReset());
  yield put(putOverviewReset());
  yield put(putFollowingReset());
  history.push('/');
}

export function* recoverPassword(action) {
  const { email } = action;
  const { recoverPasswordSuccess, recoverPasswordFailure } = AuthActions;
  try {
    yield call(api.get, `api/recover_password?email=${email}`);
    yield put(recoverPasswordSuccess());
  } catch (error) {
    yield put(recoverPasswordFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) api.defaults.headers.Authorization = token;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
  takeLatest(Types.SIGN_OUT, signOut),
  takeLatest(Types.RECOVER_PASSWORD_REQUEST, recoverPassword)
]);
