import React from 'react';
import Text  from '~/components/atoms/Text';

import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function UnderAnalysis() {
  
  return (
    <Grid container direction="column" spacing={2} style={{ padding: '40px 90px'}}>
      
      <Grid item>
        <Text variant="ppTitle" color="primary">
          <span style={{fontWeight: 'bold'}}> Oops! </span> Sua solicitação de integração ainda está sob análise.
        </Text>
      </Grid>

      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Text variant="mediumText" color="black">
              Sua solicitação de integração ao Pagseguro está sob análise. Este procedimento leva em média 60 minutos.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="mediumText" color="black">
              Se após 60 minutos a integração não tiver sido concluída, sugerimos que repita o processo.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="mediumText" color="black">
              Em caso de persistência, entre em contato conosco clicando <Link to="/help"> aqui</Link>.
            </Text>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
