import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    fontSize: '12px',
    width: '100%',
    height: 38.25,
    color: (props) =>
      props.placeholderSelected
        ? `${props.theme.palette.textActiveElement.main}BF`
        : 'black',
    '&.Mui-error fieldset': {
      borderColor: (props) => `${props.theme.palette.error.main} !important`
    }
  },
  iconError: {
    fill: (props) => props.theme.palette.error.main
  },
  educationsSelect: {
    color: (props) =>
      props.placeholderSelected
        ? `${props.theme.palette.textActiveElement.main}BF`
        : 'black',
    width: '180px',
    maxHeight: '37px',
    fontSize: '12px'
  },
  menuItem: {
    fontSize: '12px'
  },
  selectOptions: {
    maxHeight: 160,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E1E1E5'
    }
  },
  error: {
    color: 'pink'
  },
  helperText: {
    color: (props) => props.theme.palette.error.main,
    fontSize: 11,
    textAlign: 'center'
  }
});
