import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: '#F6F6F6',
    padding: '1rem',
    borderRadius: 5
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px',
    width: '100%'
  },

  thumbnail: {
    width: '20rem',
    height: '20rem',
    borderRadius: '2rem'
  }
});
