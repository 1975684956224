import React, { useCallback, useState } from 'react';

import Text from '~/components/atoms/Text';

import InputFieldAdornment from '../InputFieldAdornment';
import { useStyles } from './styles';
import { Box, TextField, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const Input = ({
  multiline,
  type,
  label,
  input,
  placeholder,
  custom,
  meta,
  showErrors,
  errorOnTheRight,
  onChange,
  colorScheme,
  disabledBorderColor,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles({ colorScheme, theme, disabledBorderColor });
  const displayError = !!(meta && (meta.touched || showErrors) && meta.error);
  const [values, setValues] = useState({
    showPassword: type === 'password' ? false : true
  });
  const renderError = useCallback(() => {
    return (
      <div
        className={
          errorOnTheRight ? classes.helperTextRight : classes.errorText
        }
      >
        <Text
          color={colorScheme === 'primaryBg' ? 'white' : 'error'}
          variant="mediumText"
        >
          {meta.error}
        </Text>
      </div>
    );
  }, [meta.error]);

  return (
    <Box className={classes.root}>
      {label && (
        <Text
          variant="label"
          color={colorScheme === 'primaryBg' ? 'white' : 'gray'}
        >
          {label}
        </Text>
      )}
      <TextField
        error={displayError}
        className={`${classes.textarea}`}
        placeholder={placeholder}
        variant="outlined"
        multiline={multiline}
        minRows={1}
        maxRows={3}
        disabled={false}
        onChange={(event) => (onChange ? onChange(event) : null)}
        InputProps={{
          type: values?.showPassword ? 'text' : 'password',
          endAdornment: type === 'password' && (
            <InputFieldAdornment setValues={setValues} values={values} />
          ),
          classes: {
            input: classes.input
          }
        }}
        {...input}
        {...custom}
        {...rest}
      />
      {displayError && renderError()}
    </Box>
  );
};

Input.propTypes = {
  multiline: PropTypes.bool,
  label: PropTypes.string,
  input: PropTypes.shape(),
  custom: PropTypes.shape(),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape(),
  showErrors: PropTypes.bool,
  errorOnTheRight: PropTypes.bool,
  onChange: PropTypes.func,
  rest: PropTypes.shape(),
  colorScheme: PropTypes.string,
  disabledBorderColor: PropTypes.string
};

Input.defaultProps = {
  multiline: false,
  label: '',
  input: null,
  custom: null,
  placeholder: '',
  type: 'text',
  meta: {},
  showErrors: false,
  errorOnTheRight: false,
  onChange: null,
  rest: {},
  colorScheme: 'default',
  disabledBorderColor: 'rgba(0, 0, 0, 0.26)'
};

export default Input;
