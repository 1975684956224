import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteIcon, SpreadIcon } from '~/components/atoms/Icons';
import api from '~/services/api';
import { deleteAnswerPublisher, deleteAnswerListener } from '~/services/mural';
import { Creators as SpacesCreators } from '~/store/ducks/spaces';

import PopUpMenu from '../PopUpMenu';
import Activity from './Activity';
// Tipos de status
import Answer from './Answer';
import Help from './Help';
import Log from './Log';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

// O mural é composto pelos quatro tipos de status abaixo
// o status answer é chamado dentro de um Activity ou Help (recursivo)
function StatusType({
  item,
  dashboard,
  lecture,
  setAnswers,
  setDeleted,
  wasDeleted
}) {
  const itemType = item && item.type;
  switch (itemType) {
    case 'Log':
      return <Log item={item} dashboard={dashboard} />;
    case 'Activity':
      return (
        <Activity
          item={item}
          dashboard={dashboard}
          lecture={lecture}
          setDeleted={setDeleted}
          wasDeleted={wasDeleted}
        />
      );
    case 'Help':
      return <Help item={item} dashboard={dashboard} lecture={lecture} />;
    case 'Answer':
      return (
        <Answer
          item={item}
          dashboard={dashboard}
          lecture={lecture}
          setAnswers={setAnswers}
        />
      );
    default:
      return null;
  }
}

const ALERT_MESSAGES = {
  Answer: (
    <div>
      Tem certeza que <b>deseja excluir</b> a resposta?
    </div>
  ),
  Activity: (
    <div>
      Tem certeza que <b>deseja excluir</b> a atualização?
    </div>
  ),
  Help: (
    <div>
      Tem certeza que <b>deseja excluir</b> a ajuda?
    </div>
  ),
  Log: (
    <div>
      Tem certeza que <b>deseja excluir</b> a atualização?
    </div>
  )
};
const MuralItem = React.memo(
  ({ item, dashboard, lecture, setAnswers, setDeleted, deleted }) => {
    const { profile } = useSelector((state) => state.user);
    const { id: profileId } = profile || {};
    const { handleOpen } = SpacesCreators;
    const dispatch = useDispatch();
    const itemUserId = item && item.user && item.user.id;
    const itemType = item && item.type;
    const itemId = item && item.id;
    const isAnswer = itemType === 'Answer';
    const lectureId = lecture && lecture.id;
    const channel = `channel-${lectureId}`;
    const message = ALERT_MESSAGES[itemType];
    const isLoggedUser = profileId === itemUserId;
    const classes = useStyles({ isAnswer, dashboard });
    const deleteAnswer = () => {
      handleDeleteItem();
    };

    const handleDeleteItem = async () => {
      if (item) {
        try {
          // eslint-disable-next-line no-unused-vars
          const response = await api.delete(`/api/statuses/${itemId}`);
          deleteAnswerPublisher(channel, { answerId: itemId });
          // eslint-disable-next-line no-empty
        } catch (e) {}

        setAnswers &&
          setAnswers((answers) =>
            answers.filter((answer) => answer.id !== itemId)
          );
      }
    };

    useEffect(() => {
      if (lecture) {
        deleteAnswerListener(lecture.id, (answerId) => {
          setAnswers &&
            setAnswers((answers) =>
              answers.filter((answer) => answer.id !== answerId)
            );
        });
      }
    }, [lecture]);

    const deletePost = () => {
      dispatch(handleOpen(message, 'alert', item));
    };

    const linkList = [
      {
        link: null,
        action: isAnswer ? deleteAnswer : deletePost,
        Icon: DeleteIcon,
        text: `Excluir`,
        disabled: false
      }
    ];

    return (
      <div className={classes.mainContainer}>
        <StatusType
          setAnswers={setAnswers}
          item={item}
          dashboard={dashboard}
          lecture={lecture}
          setDeleted={setDeleted}
          wasDeleted={deleted}
        />
        <div className={classes.menuHelper}>
          {isLoggedUser && item.type != 'Log' && (
            <PopUpMenu
              Icon={SpreadIcon}
              linkList={linkList}
              hasMore
              wasDeleted={deleted}
            />
          )}
        </div>
      </div>
    );
  }
);

MuralItem.displayName = 'MuralItem';

MuralItem.propTypes = {
  item: PropTypes.shape(),
  dashboard: PropTypes.bool,
  lecture: PropTypes.bool,
  setAnswers: PropTypes.func,
  setDeleted: PropTypes.func,
  deleted: PropTypes.bool,
  muralRef: PropTypes.shape()
};

MuralItem.defaultProps = {
  item: {},
  dashboard: undefined,
  lecture: undefined,
  setAnswers: null,
  setDeleted: null,
  deleted: false,
  muralRef: {}
};

StatusType.propTypes = {
  item: PropTypes.shape(),
  dashboard: PropTypes.bool,
  lecture: PropTypes.bool,
  setAnswers: PropTypes.func,
  setDeleted: PropTypes.func,
  wasDeleted: PropTypes.bool
};

StatusType.defaultProps = {
  item: {},
  dashboard: undefined,
  lecture: undefined,
  setAnswers: null,
  setDeleted: null,
  wasDeleted: false
};

export default MuralItem;
