import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '72px'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1442px',
    gap: '30px',
    padding: '16px 32px 16px 32px'
  },
  header: {
    marginBottom: 20
  },
  environmentContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  environmentItem: {
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  shimmerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  shimmerItem: {
    width: 208,
    height: 208,
    margin: '0px 10px 0px 0',
    '@media (max-width: 1023px)': {
      height: 164,
      width: 164,
      margin: '12px 10px 12px 0'
    }
  },
  loadingContainer: {
    display: 'flex',
    marginLeft: '2%'
  },
  noEnvironmentsContainer: {},
  shimmerText: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    marginTop: 30
  },
  filter: {
    marginTop: 30
  }
}));
