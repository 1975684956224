import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import i18n from '~/I18n';
import { getCourseInformationReport } from '~/services/reports/courses';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function CourseInformationReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getCourseInformationReport}
        baseName={`Relatório_de_estruturas_do_${i18n.t(
          'StructuresName.Course.PluralLower'
        )}__`}
        id={id}
      />
    </div>
  );
}

CourseInformationReport.propTypes = {
  id: PropTypes.string
};

CourseInformationReport.defaultProps = {
  id: ''
};
