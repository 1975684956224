import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import { update as updateQuestion } from '~/services/Lecture/Questionary/AnyChoiceQuestion';
import { remove as removeQuestion } from '~/services/Lecture/Questionary/AnyChoiceQuestion';

import { useStyles } from '../Exercise/styles';
import QuestionFields from './QuestionFields';
import Questions from './QuestionFields/Questions';
import { Box } from '@material-ui/core';

export default function Questionary() {
  const classes = useStyles();

  const {
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [editing, setEditing] = useState(false);
  const [questions, setQuestions] = useState(
    watch('lectureable.exercise.questions')
  );

  const [currentQuestion, setCurrentQuestion] = useState({
    id: null,
    alternatives: [],
    statement: '',
    position:
      watch('lectureable.exercise.questions').length > 0
        ? watch('lectureable.exercise.questions').at(-1).position + 1
        : 1,
    _destroy: false
  });

  const handleDeleteQuestion = (question) => {
    question._destroy = true;
    let position = 1;

    const destroyedQuestions = questions.filter((elem) => elem._destroy);
    const validQuestions = questions.filter((elem) => !elem._destroy);

    for (var item of validQuestions) {
      item.position = position;
      position++;
    }

    setCurrentQuestion({
      alternatives: [],
      statement: '',
      position: position
    });

    setQuestions([...validQuestions, ...destroyedQuestions]);
    setEditing(false);
  };

  const deleteQuestion = (question) => {
    if (question.id) {
      removeQuestion(question.id)
        .then(() => {
          handleDeleteQuestion(question);
        })
        .catch(console.log);
    } else {
      handleDeleteQuestion(question);
    }
  };

  const setQuestionEdition = (question) => {
    setCurrentQuestion(question);
    setEditing(true);
  };

  const resetFields = () => {
    setCurrentQuestion({
      alternatives: [],
      statement: '',
      position: questions.at(-1).position + 1
    });
    setEditing(false);
  };

  const addQuestion = (question) => {
    if (!question.statement || question.options_attributes.length <= 1) {
      return false;
    }

    var existingQuestion = questions.find(
      (elem) => elem.position === question.position && !elem._destroy
    );
    var index = questions.findIndex(
      (elem) => elem.position === question.position && !elem._destroy
    );

    if (existingQuestion) {
      questions[index] = { id: existingQuestion.id, ...question };
      var newQuestions = questions;

      const body = {
        any_choice_question: {
          statement: question.statement
        }
      };

      updateQuestion(existingQuestion.id, body).catch((e) =>
        alert(e.response.data)
      );

      setQuestions(newQuestions);
      setCurrentQuestion({
        alternatives: [],
        statement: '',
        position: questions.at(-1).position + 1
      });
    } else {
      setQuestions((prev) => [...prev, question]);
      setCurrentQuestion({
        alternatives: [],
        statement: '',
        position: question.position + 1
      });
    }

    setEditing(false);
    return true;
  };

  useEffect(() => {
    setValue('lectureable.exercise.questions', questions);
  }, [questions]);

  return (
    <Box>
      {errors.lectureable?.exercise?.questions ? (
        <div className={classes.field}>
          <Text color="error" variant={'mediumText'}>
            {' '}
            {errors.lectureable.exercise.questions.message}{' '}
          </Text>
        </div>
      ) : null}

      <div className={classes.field}>
        {questions.length > 0 ? (
          <Questions
            questions={questions}
            deleteFunc={deleteQuestion}
            editFunc={setQuestionEdition}
          />
        ) : null}
      </div>

      <div className={classes.field}>
        <QuestionFields
          addQuestion={addQuestion}
          currentQuestion={currentQuestion}
          resetQuestionFields={resetFields}
          editingQuestion={editing}
        />
      </div>
    </Box>
  );
}
