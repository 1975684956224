import api from '~/services/api';

export const createSubject = async (spaceID, body) => {
  return await api.post(`/api/spaces/${spaceID}/subjects`, body);
};

export const updateSubject = async (subjectID, body) => {
  return await api.put(`/api/subjects/${subjectID}`, body);
};

