import React, { useEffect, useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';

import i18n from '~/I18n';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import {
  Box,
  Dialog,
  ButtonBase,
  Typography,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const roles = [
  {
    label: 'Student',
    value: 'member'
  },
  {
    label: 'Teacher',
    value: 'teacher'
  },
  {
    label: 'Tutor',
    value: 'tutor'
  },
  {
    label: 'Admin',
    value: 'environment_admin'
  }
];

export default function RoleChangeModal({
  showRoleChangeModal,
  closeRoleChangeModal,
  roleOfSelectedMembers,
  selectedMembersList,
  setSelectedMembersList,
  setMembers,
  structureID,
  updateRolesService
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { enqueueSnackbar } = useSnackbar();

  const [loadingRoleChange, setLoadingRoleChange] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    setSelectedRole(roleOfSelectedMembers || '');
  }, [roleOfSelectedMembers]);

  const closeModal = () => {
    setSelectedRole(roleOfSelectedMembers);
    closeRoleChangeModal();
  };

  const handleSelectRoleChange = async () => {
    if (loadingRoleChange || selectedRole === roleOfSelectedMembers) return;
    setLoadingRoleChange(true);
    try {
      await updateRolesService(
        structureID,
        selectedMembersList.map((member) => member.id),
        selectedRole
      );
      updateLocalMembersRole();
      setSelectedMembersList([]);
      closeModal();
    } catch (e) {
      enqueueSnackbar(
        `Não foi possível alterar o papel de ${selectedMembersList.length} ${
          selectedMembersList.length > 1 ? 'membros' : 'membro'
        }`,
        {
          variant: 'error'
        }
      );
      setSelectedRole(roleOfSelectedMembers);
    }
    setLoadingRoleChange(false);
  };

  const updateLocalMembersRole = () => {
    const mapSelectedMembersList = new Map(
      selectedMembersList.map((member) => [member.id, member])
    );

    setMembers((prev) =>
      prev.map((member) => {
        if (mapSelectedMembersList.has(member.id)) {
          return {
            ...member,
            role: selectedRole
          };
        } else {
          return member;
        }
      })
    );
  };

  return (
    <Dialog
      open={showRoleChangeModal}
      onClose={closeModal}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          padding: '32px 24px'
        }
      }}
    >
      <Box className={classes.header}>
        <Typography className={classes.textHeader}>Alterar Papel</Typography>
        <CloseIcon className={classes.exitButton} onClick={closeModal} />
      </Box>
      <Box className={classes.ContainerOptions}>
        {roles.map((item, index) => (
          <ButtonBase
            key={index}
            className={`${classes.optionButton} ${selectedRole === item.value &&
              classes.optionSelected}`}
            onClick={() => setSelectedRole(item.value)}
          >
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              {i18n.t(item.label)}
            </Typography>
            {selectedRole === item.value ? (
              <IoMdCheckmark style={{ fontSize: '24px' }} />
            ) : null}
          </ButtonBase>
        ))}
      </Box>
      <Box className={classes.buttons}>
        <ButtonBase
          disableRipple
          className={classes.cancelButton}
          onClick={closeModal}
        >
          Cancelar
        </ButtonBase>
        <ButtonBase
          disableRipple
          className={classes.addButton}
          onClick={handleSelectRoleChange}
          disabled={loadingRoleChange}
        >
          {loadingRoleChange ? <CircularProgress size={20} /> : 'Alterar'}
        </ButtonBase>
      </Box>
    </Dialog>
  );
}

RoleChangeModal.propTypes = {
  showRoleChangeModal: PropTypes.bool.isRequired,
  closeRoleChangeModal: PropTypes.func.isRequired,
  roleOfSelectedMembers: PropTypes.string,
  setSelectedMembersList: PropTypes.func.isRequired,
  selectedMembersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  setMembers: PropTypes.func.isRequired,
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  updateRolesService: PropTypes.func.isRequired
};
