import React from 'react';

import { ReactComponent as MirroringImage } from '~/assets/images/mirroring-image.svg';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export default function StartStep() {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      flexDirection={{ xs: 'column', md: 'row' }}
      width={{ xs: '100%', xl: '80%' }}
    >
      <Box>
        <MirroringImage width={361} height={421} />
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.title}>Espelhamento</Typography>
        <Box className={classes.descriptionContent}>
          <Typography className={classes.descriptionText}>
            Ao espelhar um item, você estará criando uma réplica idêntica do
            item original. Caso sejam realizadas alterações, como remoção ou
            inserção de conteúdo, o item espelhado também será alterado. Dados
            alterados na estrutura após o espelhamento, como nome e abreviação
            do original, não serão refletidos para os itens espelhados.
          </Typography>
          <Typography className={classes.descriptionText}>
            Caso você não deseje que seu novo item receba as alterações do item
            original, você poderá desvincular o espelho posteriormente.
          </Typography>
          <Typography className={classes.descriptionText}>
            Se você espelhar estruturas que contenham conteúdos espelhos, esses
            conteúdos não serão replicados.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
