import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import api from '~/services/api';

import ExerciseForm from './ExerciseForm';
import Root from './Root';

export const ExerciseMembersReportSwap = ({ lecture }) => {
  const [showExerciseForm, setShowExerciseForm] = useState(false);

  const { profile } = useSelector((state) => state.user);

  const [result, setResult] = useState(null);

  const openExerciseForm = () => setShowExerciseForm(true);
  const closeExerciseForm = () => setShowExerciseForm(false);

  const handleRequest = ({ data }) => {
    if (data.length > 0) setResult(data[0]);
  };

  const getMemberResult = () => {
    api
      .get(
        `/api/exercises/${lecture.lectureable.id}/users/${profile.id}/results`
      )
      .then(handleRequest)
      .catch(console.log);
  };

  useEffect(() => {
    getMemberResult();
  }, []);

  return (
    <>
      {showExerciseForm ? (
        <ExerciseForm
          result={result}
          setResult={setResult}
          lecture={lecture}
          closeExerciseForm={closeExerciseForm}
        />
      ) : (
        <Root
          openExerciseForm={openExerciseForm}
          lecture={lecture}
          result={result}
          setResult={setResult}
        />
      )}
    </>
  );
};

export default ExerciseMembersReportSwap;
