import React from 'react';

import BackgroundImage from './BackgroundImage';
import { useStyles } from './styles';
import TextContent from './TextContent';
import { Box } from '@material-ui/core';

export default function LeftContent() {
  const classes = useStyles();

  return (
    <Box className={classes.contentLeft}>
      <BackgroundImage />
      <TextContent />
    </Box>
  );
}
