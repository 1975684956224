import api from "~/services/api";

export const getEnvironmentPubInfo = async environmentPath => {
  const { data } = await api.get(`/api/environments/${environmentPath}/public_info`, { params: { path: environmentPath } })

  const { thumbnails } = data 
  const image = thumbnails && thumbnails.length > 2 && thumbnails[2].href
    ? thumbnails[2].href
    : null;

  const { name } = data
    
  return { name: name, image: image }
}