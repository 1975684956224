import React from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';
import i18n from '~/I18n';

export default function Main() {
  const { control } = useFormContext();

  return (
    <div>
      <Field
        label={TextPrimary('Título', '13px')}
        name="name"
        control={control}
        placeholder={`Título da ${i18n.t('StructuresName.Lectures.SingularLower')}...`}
        Component={Input}
      />
    </div>
  );
}
