import React from 'react';

import { useStyles } from './style';
import { Box, Slider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import VolumeDownRoundedIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeMuteRoundedIcon from '@material-ui/icons/VolumeMuteRounded';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';

export default function Volume({ volume, setVolume, muted, setMuted }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleVolumeIconClick = () => setMuted((prev) => !prev);
  const onChange = (_, v) => setVolume(v / 100);

  function VolumeIcon() {
    let Icon;

    if (muted) Icon = VolumeOffRoundedIcon;
    else if (volume === 0) Icon = VolumeMuteRoundedIcon;
    else if (volume < 0.5) Icon = VolumeDownRoundedIcon;
    else Icon = VolumeUpRoundedIcon;

    return <Icon className={classes.volumeIcon} />;
  }

  return (
    <Box style={{ display: 'flex' }}>
      <button
        style={{
          display: 'flex',
          marginRight: '0.75rem',
          alignItems: 'center'
        }}
        onClick={handleVolumeIconClick}
      >
        <VolumeIcon />
      </button>
      <Slider
        classes={{
          root: classes.sliderRoot,
          thumb: classes.sliderThumb,
          track: classes.sliderTrack,
          rail: classes.sliderRail
        }}
        value={volume * 100}
        defaultValue={100}
        aria-label="volume slider"
        onChange={onChange}
      />
    </Box>
  );
}
