import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%'
  },
  label: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.activeText.main
  },
  outlinedInput: {
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.activeText.main}`,
    overflow: 'auto'
  },

  scrollbarInput: {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.secondary.main
    }
  },

  helperText: {
    display: 'flex',
    alignItens: 'center',
    gap: '4px',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    color: theme.palette.error.main
  },
  errorIcon: {
    fill: theme.palette.error.main,
    fontSize: '16px'
  }
}));
