import React from 'react';

import { LecturesSummary } from '~/components/organisms/LecturesSummary';
import TabBar from '~/components/TabBar';

import { Container } from './styles';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function Main() {
  const theme = useTheme();
  return (
    <Container
      id="scrollableOverviewDiv"
      scollbarThumbCOlor={theme.palette.secondary.main}
    >
      <Box>
        <LecturesSummary />
      </Box>
      <TabBar target="scrollableOverviewDiv" />
    </Container>
  );
}
