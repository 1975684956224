/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import UnlinkDialog from '~/components-refactor/molecules/UnlinkDialog';
import CircularProgressBar from '~/components/atoms/CircularProgressBar';
import { SettingsIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import MirroredFlag from '~/components/molecules/MirroredFlag';
import ShareContent from '~/components/organisms/ShareContents/ShareContentCourses';
import ShareContentSpace from '~/components/organisms/ShareContents/ShareContentSpaces';
import i18n from '~/I18n';
import Course from '~/services/course';
import { unlink as unlinkCourse } from '~/services/mirroring/course';
import { unlink as unlinkSpace } from '~/services/mirroring/space';
import Space from '~/services/Space';
import settings from '~settings';

import PopUpMenu from '../PopUpMenu';
import { useStyles } from './styles';
import { Box, Modal, useTheme, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ShareIcon from '@material-ui/icons/Share';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

function CourseCard({
  instance,
  instanceType,
  courseID,
  environmentId,
  spaceID,
  environmentName,
  link,
  editLink
}) {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCourse = async (ID) => {
    Course.remove(ID)
      .then(() => {
        handleClose();
        localStorage.setItem(
          'snackbarMessage',
          `${i18n.t(
            'StructuresName.Course.SingularUpper'
          )} excluído com sucesso!`
        );
        window.location.replace(`/app/dashboard/environments/${environmentId}`);
      })
      .catch(() =>
        enqueueSnackbar(
          `Erro ao excluir ${i18n.t('StructuresName.Course.SingularLower')}.`,
          {
            variant: 'error'
          }
        )
      );
  };

  const deleteSpace = async (ID) => {
    Space.remove(ID)
      .then(() => {
        handleClose();
        localStorage.setItem(
          'snackbarMessage',
          `${i18n.t(
            'StructuresName.Space.SingularUpper'
          )} excluída com sucesso!`
        );
        window.location.replace(
          `/app/dashboard/environments/${environmentId}/courses/${courseID}`
        );
      })
      .catch(() =>
        enqueueSnackbar(
          `Erro ao excluir $${i18n.t('StructuresName.Space.SingularLower')}.`,
          {
            variant: 'error'
          }
        )
      );
  };

  let linkListCourses = [
    {
      link: editLink,
      Icon: EditIcon,
      text: i18n.t('EditCourse'),
      disabled: false
    },
    instance.can_unlink && {
      Icon: LinkOffIcon,
      text: 'Desvincular espelho',
      action: (e) => {
        e.preventDefault();
        setShowUnlinkModal(true);
      },
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t('StructuresName.Course.SingularLower')}`,
      disabled: false
    }
  ].filter(Boolean);

  let linkListSpaces = [
    {
      link: `/dashboard/environments/${environmentId}/courses/${courseID}/spaces/${spaceID}/edit`,
      Icon: EditIcon,
      text: `Editar ${i18n.t('StructuresName.Space.SingularLower')}`,
      disabled: false
    },
    instance.can_unlink && {
      Icon: LinkOffIcon,
      text: 'Desvincular espelho',
      action: (e) => {
        e.preventDefault();
        setShowUnlinkModal(true);
      },
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t('StructuresName.Space.SingularLower')}`,
      disabled: false
    }
  ].filter(Boolean);

  useEffect(() => {
    if (instance) {
      setProgress(instance.user_completion?.toFixed(0));
    }
  }, [instance]);

  const currentEnvironmentName = environmentName || 'Carregando';
  const classes = useStyles({ theme, SettingsIcon });
  const instanceTime = instance.workload;
  const image =
    instance &&
    instance?.thumbnails &&
    instance?.thumbnails.length > 1 &&
    instance?.thumbnails[0][0] !== 'missing'
      ? instanceType === 'course'
        ? instance?.thumbnails[0][1][1].href
        : instance?.thumbnails[1].href
      : instance?.thumbnails[0][1][1].href;

  return (
    <>
      <Link
        to={{
          pathname: link,
          state: {
            environmentPath: `/dashboard/environments/${courseID}`,
            environmentName: currentEnvironmentName,
            environmentId: environmentId
          }
        }}
        className={classes.container}
        key={instance.id}
      >
        <div className={classes.container__header}>
          <img
            className={classes.container__image}
            src={image && `${settings.baseUrl}${image}`}
            alt="Course Image"
          />
          <Box className={classes.container__popup}>
            {instanceType === 'course' && (
              <Box>
                {instance?.can_manage && (
                  <PopUpMenu
                    linkList={linkListCourses}
                    onDelete={() => deleteCourse(instance.id)}
                    instance={instance}
                    instanceType={instanceType}
                    showDeleteButton
                    isCourse
                  />
                )}
                <Modal
                  open={open && courseID}
                  onClose={handleClose}
                  className={classes.modal}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div>
                    <ShareContent courseID={courseID} />
                  </div>
                </Modal>
              </Box>
            )}
            {instanceType === 'space' && (
              <Box>
                {instance?.can_manage && (
                  <PopUpMenu
                    linkList={linkListSpaces}
                    onDelete={() => deleteSpace(instance.id)}
                    instance={instance}
                    instanceType={instanceType}
                    showDeleteButton
                    isCourse
                  />
                )}
                <Modal
                  open={open && spaceID}
                  onClose={handleClose}
                  className={classes.modal}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div>
                    <ShareContentSpace spaceID={spaceID} />
                  </div>
                </Modal>
              </Box>
            )}
          </Box>
        </div>
        <Box className={classes.container__mirrored}>
          <div>
            {instance?.can_manage && instance.is_mirror && (
              <MirroredFlag type={instanceType} />
            )}
          </div>
        </Box>
        <div className={classes.title__wraper}>
          <Box className={classes.container__title}>
            <Tooltip
              title={instance.name.length > 40 ? instance.name : ''}
              arrow
              placement="bottom"
              classes={{ tooltip: classes.customTooltip }}
            >
              <div>
                <Text
                  id="course__title"
                  variant="lgCourseCard"
                  color="primary"
                  style={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                    width: '80%'
                  }}
                >
                  {instance.name}
                </Text>
              </div>
            </Tooltip>
          </Box>
          {(progress || progress == 0) && (
            <Box className={classes.container__hashtags}>
              <div title={`${progress}% concluído`}>
                <CircularProgressBar value={progress} />
              </div>
            </Box>
          )}
        </div>

        <Box className={classes.description__wraper}>
          <div className={classes.container__description}>
            <Text variant="text" color="black">
              {instance.workload &&
                `${instanceTime} ${instanceTime > 1 ? 'horas' : 'hora'}`}
            </Text>
          </div>
        </Box>
      </Link>
      <UnlinkDialog
        open={showUnlinkModal}
        handleClose={() => setShowUnlinkModal(false)}
        unlink={instanceType === 'course' ? unlinkCourse : unlinkSpace}
        structureID={instanceType === 'course' ? courseID : spaceID}
        callback={() => window.location.reload()}
      />
    </>
  );
}

export default CourseCard;

CourseCard.propTypes = {
  instance: PropTypes.object,
  environmentId: PropTypes.string,
  environmentName: PropTypes.string,
  course: PropTypes.bool,
  courseID: PropTypes.string
};

CourseCard.defaultProps = {
  instance: '',
  environmentId: '',
  environmentName: '',
  course: false,
  courseID: ''
};
