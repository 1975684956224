import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { GenericFieldText } from '~/components-refactor/atoms/GenericFieldText';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import debounce from 'debounce';
import PropTypes from 'prop-types';

export default function EditEnvironmentForm({ mirrorPayload }) {
  const classes = useStyles();

  const methods = useFormContext();
  const [isLoading, setIsLoading] = useState(false);

  const debouncedEnvironmentName = useCallback(
    debounce(async () => {
      if (isLoading) return;
      setIsLoading(true);
      if (await methods.trigger()) {
        methods.clearErrors();
      }
      setIsLoading(false);
    }, 400),
    [mirrorPayload]
  );

  const handleEnvironmentInputChange = (value) => {
    methods.setValue('name', value);
    methods.setError('name');
    if (debouncedEnvironmentName) debouncedEnvironmentName.clear();
    debouncedEnvironmentName(value.trim());
  };

  useEffect(() => {
    methods.setError('name');
    const keys = Object.keys(mirrorPayload);
    for (let key of keys) {
      methods.setValue(key, mirrorPayload[key], { shouldValidate: false });
    }
  }, [mirrorPayload]);

  return (
    <Box width="100%" marginBottom="16px">
      <Box className={classes.titleBox}>
        <Typography className={classes.titleText}>{`Dados do ${i18n.t(
          `StructuresName.Environment.SingularUpper`
        )}`}</Typography>
        <CreateIcon className={classes.titleIcon} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenericFieldText
            label={'Nome *'}
            inputProps={{ ...methods.register('name') }}
            error={methods.formState.errors.name}
            onChange={handleEnvironmentInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericFieldText
            label={'Abreviação'}
            inputProps={{ ...methods.register('initials') }}
            error={methods.formState.errors.initials}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericFieldText
            label={'Descrição'}
            error={methods.formState.errors.description}
            inputProps={{
              ...methods.register('description'),
              multiline: true,
              rows: 3
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GenericFieldText
            label={'Estado'}
            inputProps={{ ...methods.register('state') }}
            error={methods.formState.errors.state}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GenericFieldText
            label={'Grupo Regional'}
            inputProps={{ ...methods.register('regional_group') }}
            error={methods.formState.errors.regional_group}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GenericFieldText
            label={'Município'}
            inputProps={{ ...methods.register('city') }}
            error={methods.formState.errors.city}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

EditEnvironmentForm.propTypes = {
  mirrorPayload: PropTypes.object.isRequired
};
