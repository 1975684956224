import React from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

export default function Text({
  variant,
  color,
  font,
  lineHeight,
  textAlign,
  additionalStyle,
  fontWeight,
  letterSpacing,
  opacity,
  style,
  ...otherProps
}) {
  const classes = useStyles();
  return (
    <Typography
      className={`${classes[variant]} ${classes[color]} ${classes[additionalStyle]
        } ${font && classes.font}`}
      style={{
        lineHeight: lineHeight,
        textAlign: textAlign,
        fontWeight: fontWeight,
        letterSpacing: letterSpacing,
        opacity: opacity,
        ...style
      }}
      {...otherProps}
    />
  );
}

Text.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  variant: PropTypes.string,
  textAlign: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  opacity: PropTypes.number
};

Text.defaultProps = {
  color: 'white',
  fontSize: 14,
  fontWeight: 'normal',
  variant: 'label',
  textAlign: '',
  letterSpacing: 'norml',
  lineHeight: '',
  opacity: 1
};
