import React from 'react';

import { useTheme } from '@material-ui/core';

export const Page404 = ({ width, height }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 526.356 492.676"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.811"
          y1="0.646"
          x2="-0.035"
          y2="-0.005"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.004" stopColor="#fff" />
          <stop offset="0.551" stopColor="#fff" stopOpacity="0.302" />
        </linearGradient>
      </defs>
      <g id="Illu404" transform="translate(-76.034 -53.487)">
        <path
          id="Retângulo_4"
          data-name="Retângulo 4"
          d="M23.023,0h129.97a23.023,23.023,0,0,1,23.023,23.023V78.356a0,0,0,0,1,0,0H23.023A23.023,23.023,0,0,1,0,55.333V23.023A23.023,23.023,0,0,1,23.023,0Z"
          transform="translate(113.03 287.051)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_244"
          data-name="Caminho 244"
          d="M404.1,527.371c36.811-12.982,72.425-35.181,95.733-67.533,28.514-39.577,26.073-84.437,31.908-130.674,2.371-18.791,14.952-29.594,22.439-46.258,15.794-35.151,10.214-88.054-23-111.933-20.457-14.707-40.22-16.309-51.233-41.884-6.09-14.14-9.652-29.707-19.108-41.857C447.144,69.639,423.9,62.724,401.88,59.253c-25.611-4.037-50.625-3.141-76.311-3.9-27.382-.812-55.151-.072-81.732,7.2-36.075,9.865-75.719,35.693-82.016,75.018-2.7,16.861.836,34.849-5.568,50.679-9.636,23.817-35.305,31.9-54.422,45.967C81.307,249.318,67.957,272.9,62.1,297.413c-9.983,41.771-2.624,88.106,16.8,126.031,20.8,40.6,54.574,75.137,95.545,95.45,32.092,15.911,76.428,20.6,112.621,22.832C326.619,544.162,366.881,540.5,404.1,527.371Z"
          transform="translate(28.221 -1)"
          fill="#4d38fe"
        />
        <path
          id="Caminho_245"
          data-name="Caminho 245"
          d="M514.218,304.56c-.25-50.282-18.22-98.585-71.076-130.984-65.22-39.978-156.392-39.458-223.06-2.159a212.26,212.26,0,0,0-91.312,100.559c-17.143,39.467-21.566,81.685-19.74,123.3l396.916.96q2.488-21.184,4.975-42.367C512.824,337.657,514.3,321.094,514.218,304.56Z"
          transform="translate(26.151 40.138)"
          fill="#5d8aff"
        />
        <path
          id="Retângulo_5"
          data-name="Retângulo 5"
          d="M23.023,0h129.97a23.023,23.023,0,0,1,23.023,23.023V78.356a0,0,0,0,1,0,0H23.023A23.023,23.023,0,0,1,0,55.333V23.023A23.023,23.023,0,0,1,23.023,0Z"
          transform="translate(134.839 260.781)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1"
          opacity="0.75"
        />
        <path
          id="Retângulo_6"
          data-name="Retângulo 6"
          d="M30.451,0H210.3a30.451,30.451,0,0,1,30.451,30.451v73.184a0,0,0,0,1,0,0H30.451A30.451,30.451,0,0,1,0,73.184V30.451A30.451,30.451,0,0,1,30.451,0Z"
          transform="translate(325.883 115.295)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1"
          opacity="0.75"
        />
        <g
          id="Grupo_50"
          data-name="Grupo 50"
          transform="translate(311.344 97.848)"
        >
          <path
            id="Retângulo_8"
            data-name="Retângulo 8"
            d="M30.451,0H210.3a30.451,30.451,0,0,1,30.451,30.451v73.184a0,0,0,0,1,0,0H30.451A30.451,30.451,0,0,1,0,73.184V30.451A30.451,30.451,0,0,1,30.451,0Z"
            transform="translate(0 0)"
            fill="#1e5cff"
          />
          <g
            id="Grupo_49"
            data-name="Grupo 49"
            transform="translate(56.456 28.528)"
          >
            <text
              x={5}
              y={45}
              fill="#fff"
              fontSize={65}
              letterSpacing={10}
              fontWeight={900}
            >
              404
            </text>
            {/* <path
              id="Caminho_276"
              data-name="Caminho 276"
              d="M223.132,143.9h-4.439v7.8H207.941v-7.8h-16.07v-8L208.63,111.4h10.063v24.369h4.439Zm-15.191-8.133v-4.686q0-1.324.1-3.569a22.83,22.83,0,0,1,.151-2.358h-.3a29.331,29.331,0,0,1-2.123,3.971l-4.466,6.643Z"
              transform="translate(-191.871 -110.817)"
              fill="#fff"
            />
            <path
              id="Caminho_277"
              data-name="Caminho 277"
              d="M252.2,131.783q0,10.7-3.584,15.672t-10.971,4.977q-7.221,0-10.888-5.2t-3.667-15.451q0-10.75,3.584-15.768T237.645,111q7.195,0,10.875,5.224T252.2,131.783Zm-18.277,0q0,6.837.855,9.4t2.867,2.563q2.041,0,2.88-2.646t.842-9.318q0-6.7-.855-9.387t-2.867-2.687q-2.013,0-2.867,2.591T233.923,131.783Z"
              transform="translate(-177.7 -110.998)"
              fill="#fff"
            />
            <path
              id="Caminho_278"
              data-name="Caminho 278"
              d="M284.166,143.9h-4.437v7.8h-10.75v-7.8H252.907v-8L269.668,111.4h10.061v24.369h4.437Zm-15.188-8.133v-4.686q0-1.324.1-3.569a22.844,22.844,0,0,1,.151-2.358h-.3a29.165,29.165,0,0,1-2.123,3.971l-4.465,6.643Z"
              transform="translate(-164.165 -110.817)"
              fill="#fff"
            /> */}
          </g>
        </g>
        <g
          id="Grupo_52"
          data-name="Grupo 52"
          transform="translate(152.286 236.164)"
        >
          <path
            id="Retângulo_9"
            data-name="Retângulo 9"
            d="M23.023,0h129.97a23.023,23.023,0,0,1,23.023,23.023V78.356a0,0,0,0,1,0,0H23.023A23.023,23.023,0,0,1,0,55.333V23.023A23.023,23.023,0,0,1,23.023,0Z"
            fill="#fff"
          />
          <text
            x={45}
            y={50}
            fill={theme.palette.error.main}
            fontSize={24}
            letterSpacing={5}
            fontWeight={600}
          >
            ERRO
          </text>
        </g>
        <path
          id="Caminho_284"
          data-name="Caminho 284"
          d="M394.073,398.085l-37.655-140.5a46.017,46.017,0,0,0-43.448-34.093h0c-38.834-.845-61.177,43.83-37.212,74.4,39.369,50.22,82.9,106.638,82.9,106.638Z"
          transform="translate(60.515 -4.566)"
          opacity="0.49"
          fill="url(#linear-gradient)"
        />
        <g
          id="Grupo_64"
          data-name="Grupo 64"
          transform="translate(331.188 246.469)"
        >
          <rect
            id="Retângulo_12"
            data-name="Retângulo 12"
            width="187.183"
            height="8.508"
            rx="2.5"
            transform="translate(0 174.341)"
            fill="#fff"
          />
          <path
            id="Caminho_296"
            data-name="Caminho 296"
            d="M422.807,395.879H262.851l13.918-91.915a6.492,6.492,0,0,1,6.339-5.571H430.386a6.234,6.234,0,0,1,6.08,7.283Z"
            transform="translate(-235.04 -213.029)"
            fill="#fff"
          />
          <circle
            id="Elipse_4"
            data-name="Elipse 4"
            cx="7.658"
            cy="7.658"
            r="7.658"
            transform="translate(114.722 119.887)"
            fill="none"
            stroke="#4d38fe"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
        <rect
          id="Retângulo_13"
          data-name="Retângulo 13"
          width="473.063"
          height="10.21"
          rx="3"
          transform="translate(103.165 426.163)"
          fill="#c1e7f8"
        />
        <path
          id="Caminho_299"
          data-name="Caminho 299"
          d="M449.917,181.7c3.3,20.933,4.648,43.411-4.941,62.308-5.349,10.54-13.776,19.269-18.556,30.121-5.1,11.579-6.9,23.6-8.168,36.155"
          transform="translate(149.204 41.478)"
          fill="none"
          stroke="#addfff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_300"
          data-name="Caminho 300"
          d="M140.855,155.458c-13.344,16.113-33.163,25.248-49.185,38.7a115.554,115.554,0,0,0-40.614,89.369"
          transform="translate(26 32.674)"
          fill="none"
          stroke="#addfff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_301"
          data-name="Caminho 301"
          d="M337.26,55.352a82.738,82.738,0,0,1,49.706,44.057"
          transform="translate(122.031 -0.912)"
          fill="none"
          stroke="#addfff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_302"
          data-name="Caminho 302"
          d="M109.686,389.788A180.18,180.18,0,0,0,216.322,433.87"
          transform="translate(45.678 111.294)"
          fill="none"
          stroke="#addfff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_303"
          data-name="Caminho 303"
          d="M329.11,398.27a223.839,223.839,0,0,0,20.943-10.721"
          transform="translate(119.296 110.543)"
          fill="none"
          stroke="#69a4c9"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Caminho_304"
          data-name="Caminho 304"
          d="M256.13,413.993c28.4-1.25,53.062-2.6,83.687-14.725"
          transform="translate(94.811 114.475)"
          fill="none"
          stroke="#69a4c9"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
