import * as yup from 'yup';

export const PasswordValidation = yup.object().shape({
  currentPassword: yup.string().required('Senha atual é obrigatória'),
  newPassword: yup.string().min(6, 'A nova senha precisa ter no mínimo seis caracteres').required('Nova senha é obrigatória'),
  confirmNewPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'As senhas devem coincidir')
    .required('Confirmação de senha é obrigatória'),
});

export const EmailValidation = yup.object().shape({
  email: yup.string()
    .notOneOf([''], 'O e-mail é obrigatório')
    .email('O e-mail inserido não é válido')
    .required('O e-mail é obrigatório'),
  confirmEmail: yup.string()
    .notOneOf([''], 'A confirmação de e-mail é obrigatória')
    .oneOf([yup.ref('email'), null], 'Os e-mails devem coincidir')
    .required('A confirmação de e-mail é obrigatória'),
});