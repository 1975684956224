import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    height: '100%',
    overflowY: 'hidden',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  sideBar: {
    width: '20%',
    paddingRight: 10,
    '@media (max-width: 600px)': {
      width: '100%',
      padding: '20px 0'
    }
  },
  content: {
    width: '80%',
    overflowY: 'auto',
    '@media (max-width: 600px)': {
      width: '100%',
      flex: 1,
    }
  }
}));
