import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '20px'
  },
  label: {
    paddingTop: 10,
    marginBottom: 5
  }
});
