import React from 'react';

import { ReactComponent as ProgressIndicatorImage } from '~/assets/images/progress-indicator-pana.svg';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export default function LastStep() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <ProgressIndicatorImage width={348} height={348} />
      </Box>
      <Box className={classes.content} marginLeft={{ xs: 0, md: '52px' }}>
        <Typography className={classes.title}>Vamos espelhar?</Typography>
        <Box>
          <Typography className={classes.descriptionText}>
            A conclusão do espelhamento poderá demorar alguns minutos, para
            verificar o andamento você pode acompanhar o status na seção{' '}
            <strong>Espelhamento</strong> na tela inicial.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
