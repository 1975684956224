import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    marginTop: '40px'
  },
  title: {
    marginBottom: '5px',
    fontSize: '35px'
  },
  content: {
    backgroundColor: '#f6f6fa',
    borderRadius: '5px',
    padding: '20px'
  }
});
