import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: 425,
    right: 65,
    zIndex: 20,
    bottom: 0,
    borderRadius: 10,
    height: 600,
    maxHeight: '70vh',
    display: 'flex',
    flexDirection: 'column',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  },
  header: {
    padding: 25,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '10px 10px 0px 0px'
  },
  contactList: {
    paddingTop: 20
  }
});
