import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    height: '100vh',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      alignItems: 'flex-start'
    }
  },
  leftContent: {
    width: '60%',
    padding: '30px 15px 30px 30px',
    '@media (max-width: 1000px)': {
      width: '100%',
      paddingRight: 50,
      paddingLeft: 10
    }
  },
  rightContent: {
    width: '40%',
    display: 'flex',
    padding: '30px 30px 30px 15px',
    justifyContent: 'center',
    '@media (max-width: 1000px)': {
      display: 'none'
    }
  },
  imageTablet: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'flex',
      padding: 20,
      justifyContent: 'center'
    }
  },
  title: {
    display: 'flex',
    '& p': {
      fontWeight: '500 !important'
    }
  },
  titleDecoration: {
    width: 40,
    height: 40,
    position: 'relative',
    right: -15,
    top: 10,
    '@media (max-width: 780px)': {
      right: -15,
      top: 0
    }
  },
  stroke: {
    width: 5,
    height: 20,
    borderRadius: 5,
    backgroundColor: (props) => props.theme.palette.error.main,
    margin: 0,
    position: 'absolute',
    '@media (max-width: 780px)': {
      height: 15
    }
  },
  strokeTop: {
    transform: 'matrix(0.73, -0.68, 0.68, 0.73, 0, 0)',
    top: 0,
    left: 22
  },
  strokeMid: {
    transform: 'matrix(0.5, -0.87, 0.87, 0.5, 0, 0)',
    top: 8,
    left: 15
  },
  strokeBot: {
    transform: 'matrix(0.22, -0.97, 0.97, 0.22, 0, 0)',
    left: 10,
    top: 18
  },
  subtitle: {
    marginLeft: 40
  },
  body: {
    margin: '25px 0 25px 40px'
  },
  bodyTitle: {
    '& p': {
      fontWeight: 'bold !important'
    }
  },
  bodyText: {
    marginBottom: 40
  },
  link: {
    color: (props) => props.theme.palette.primary.main,
    fontWeight: 500
  },
  linkList: {
    paddingLeft: 20,
    '& li': {
      listStyleType: 'disc !important',
      color: (props) => props.theme.palette.primary.main,
      fontSize: 20
    },
    '& p': {
      fontWeight: '500 !important'
    }
  }
});
