import settings from '~settings';

import axios from 'axios';

const api = axios.create({
  baseURL: settings.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    AppKey: settings.AppKey
  }
});

export default api;
