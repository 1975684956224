import api from '~/services/api';

const baseURL = '/api/mirroring';

export const create = async (id, payload, parentID) => {
  await api.post(`${baseURL}/environments/${parentID}/courses`, {
    replicable_id: id,
    course: {
      ...payload
    }
  });
};

export const unlink = async (id) => {
  await api.put(`${baseURL}/courses/${id}/unlink`);
};

export default { create, unlink };
