import React from 'react';

import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper';
import { hasReadAccessToStatusFile } from '~/services/AccessControl/statusFileAccessControl';
import settings from '~settings';

import { downloadReport } from './fetch';
import ModalImagesDimensions from './modalImageDimensions';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import fileDownload from 'js-file-download';

export default function MuralFilesPost({ item }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const postedImageDimensions = (status_file) => {
    if (status_file.type !== 'image') return;
    else if (status_file?.dimensions?.height > 360) {
      return (
        <div>
          <button type="button" onClick={handleOpen}>
            <img
              src={`${settings.baseUrl}${status_file?.thumbnails[0]?.href}`}
              style={{ width: '100%' }}
            />
          </button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <div>
                <ModalImagesDimensions item={item} />
              </div>
            </Fade>
          </Modal>
        </div>
      );
    } else if (status_file?.dimensions?.width > 640) {
      return (
        <div>
          <button type="button" onClick={handleOpen}>
            <img src={`${settings.baseUrl}${status_file.thumbnails[1].href}`} />
          </button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <div>
                <ModalImagesDimensions item={item} handleClose={handleClose} />
              </div>
            </Fade>
          </Modal>
        </div>
      );
    } else {
      return (
        <div>
          <button type="button" onClick={handleOpen}>
            <img src={`${settings.baseUrl}${status_file.thumbnails[2].href}`} />
          </button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <div>
                <ModalImagesDimensions item={item} />
              </div>
            </Fade>
          </Modal>
        </div>
      );
    }
  };

  const renderStatusFile = (status_file) => {
    if (status_file.type === 'image') return postedImageDimensions(status_file);
    else
      return (
        <button
          style={{ fontSize: '12px', color: 'blue' }}
          onClick={() => filesDownload(status_file)}
        >
          {status_file.name}
        </button>
      );
  };

  const filesDownload = (status_file) => {
    downloadReport(status_file.id)
      .then((file) => fileDownload(file, `${status_file.name}`))
      .catch(console.log);
  };

  return (
    <div>
      {/* {item.status_files?.length > 0 && renderStatusFile(item.status_files[0])} */}
      {item.status_files?.length > 0 && (
        <ComponentAccessControlWrapper
          accessFunction={hasReadAccessToStatusFile}
          modelID={item.status_files[0].id}
          Component={() => renderStatusFile(item.status_files[0])}
        />
      )}
    </div>
  );
}
