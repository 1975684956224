import React from 'react';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import { Description } from '@material-ui/icons/';
import PropTypes from 'prop-types';

export default function ReportTitle({ title }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Description
        color="primary"
        className={classes.icon}
        style={{ marginRight: '8px' }}
      />
      <Typography variant="h6" color="primary" style={{ fontSize: '16px' }}>
        {title}
      </Typography>
    </Box>
  );
}
ReportTitle.propTypes = {
  title: PropTypes.string
};

ReportTitle.defaultProps = {
  title: ''
};
