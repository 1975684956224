import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Creators as UserActions } from '~/store/ducks/user';

import PropTypes from 'prop-types';

import { Box, Input, useTheme, FormHelperText, Button, Select, MenuItem } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import File from './File';

import Text from '~/components/atoms/Text';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Alert from '~/components/atoms/ValidationAlertDialog';

import { useStyles } from './styles';
import api from '~/services/api';
import { Skeleton } from '@material-ui/lab';
import i18n from '~/I18n';

export default function ContactUsForm({ isModal }) {
  const theme = useTheme();
  const classes = useStyles({ theme, isModal });

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getContactKindsRequest } = UserActions;
  const { contactKinds, loadingKinds } = useSelector(state => state.user);
  const [selectedFile, setSelectedFile] = useState(null);
  const [resetFileView, setResetFileView] = useState(false);


  const [ data, setData ] = useState({
    name: '',
    email: '',
    body: '',
    subject: '',
    kind: 0,
    files: [],
  });

  const [ errors, setErrors ] = useState({});
  const [ submitting, setSubmitting ] = useState(false);

  useEffect(() => {
    if(!contactKinds || !contactKinds.length)
      dispatch(getContactKindsRequest());
  }, []);

  useEffect(() => {
    if(contactKinds && contactKinds.length && contactKinds[0].id)
      setData({...data, kind: contactKinds[0].id});
  }, [contactKinds])

  const fields = [
    {name: 'name', placeholder: i18n.t('ContactUsNamePlaceholder'), label: i18n.t('Name')},
    {name: 'email', placeholder: i18n.t('ContactUsEmailPlaceholder'), label: i18n.t('Email')},
    {name: 'kind', label: i18n.t('Category')},
    {name: 'subject', placeholder: i18n.t('ContactUsSubjectPlaceholder'), label: i18n.t('Subject')},
    {name: 'body', placeholder: i18n.t('ContactUsBodyPlaceholder'), label: i18n.t('ContactUsBody')},
    {name: 'files', label: i18n.t('Attachment')},
  ];

  function handleChange(event) {
    setData({...data, [event.target.name]: event.target.value})
  }

  const handleFileChange = (file) => {
    if(file) {
      setData({ ...data, files: [file] });
    } else {
      setData({ ...data, files: [] });
    }
  };

  const resetFields = () => {
    setData({
      name: '',
      email: '',
      body: '',
      subject: '',
      kind: contactKinds && contactKinds.length && contactKinds[0].id || 0,
      files: [],
    });
    setResetFileView(true); 
    setTimeout(() => setResetFileView(false), 500);
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function handleSubmit(event) {
    event.preventDefault();
    
    setSubmitting(true);

    // check for errors
    const newErrors = {};

    Object.keys(data).map((keyName) => {
      if(data[keyName] === "") newErrors[keyName] = i18n.t('EmptyFieldError');
      else if(keyName === "email" && !validateEmail(data[keyName])) {
        newErrors[keyName] = i18n.t('InvalidEmail');
      }
    });

    setErrors(newErrors);

    // if there are no errors submit
    if(Object.keys(newErrors).length === 0) {
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (key !== 'files') {
          formData.append(`contact[${key}]`, data[key]);
        }
      });
    if (data.files) {
      data.files.forEach((file, index) => {
        formData.append(`contact[files][]`, file);
      })  
    }

      api.post('/api/contact', formData)
      .then((res) => {
        setSubmitting(false);
        resetFields();
        enqueueSnackbar(`Mensagem enviada com sucesso!`, {
          variant: 'success'
        });
      })
      .catch((err) => {
        setSubmitting(false);
        enqueueSnackbar(`Algo errado aconteceu. Tente novamente!`, {
          variant: 'error'
        });
      })
    } else {
      setSubmitting(false);
    }
  }

  if(loadingKinds) {
    return (
      <Box className={classes.root}>
          {fields.map((field, index) => (
            <Box key={index}>
              <Skeleton variant='rect' width='100%' height={15} className={classes.shimmerText} />
              <Skeleton variant='rect' width='100%' height={index < 4 ? 40 : 150} className={classes.shimmerInput} />
            </Box>
          ))}
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <Box className={classes.field} key={index}>
            <Text variant='largeText' color='gray'>{field.label}</Text>
              { field.name === 'kind'
              ? (
                <Select 
                  key={index}
                  value={data[field.name]}
                  onChange={handleChange}
                  name={field.name}
                  variant='outlined'
                  className={classes.select}
                  MenuProps={{
                    PopoverClasses: {root: classes.selectPopUpRoot},
                    classes: {paper: classes.selectOptions}
                  }}
                  classes={{root: classes.selectRoot, outlined: classes.selectRoot}}
                >
                  {contactKinds.map((option, index) => (
                    <MenuItem key={index} value={option.id} className={classes.menuItem}>{option.name}</MenuItem>
                  ))}
                </Select>
              ) : field.name === 'files' ? (
                <Box className={classes.fileContainer}>
                  <File onFileChange={handleFileChange} selectedFile={selectedFile} reset={resetFileView}/>
                </Box>
            ): (
                <Box key={index}>
                  <Input 
                    error={!!errors[field.name]}
                    multiline={field.name === 'body'}
                    rows={7}
                    rowsMax={7}
                    value={data[field.name]} 
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={handleChange}  
                    disableUnderline
                    classes={{
                      root: field.name === 'body' ? classes.textArea : classes.input,
                      error: classes.inputError
                    }}
                  />
                  {errors[field.name] && 
                    <FormHelperText className={classes.helperText}>{errors[field.name]}</FormHelperText>
                  }
                </Box>
              )}
            
          </Box>
        ))}
        <Box className={classes.footer}>
          { isModal ? (
            <Button
              className={classes.iconButton}
              type='submit'
              disabled={submitting}
            >
              <SendIcon className={classes.sendIcon} />
            </Button>
          ) : (
            <Box className={classes.textButton}>
                <ButtonSquare
                  backgroundColor={theme.palette.secondary.main}
                  width={140}
                  height={38}
                  marginLeft={10}
                  type='submit'
                  disabled={submitting}
                >
                  <Text variant='text' color='white'>
                    {i18n.t('SendMessage')}
                  </Text>
                </ButtonSquare>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
}

ContactUsForm.propTypes = {
  isModal: PropTypes.bool
}

ContactUsForm.defaultProps = {
  isModal: false
}