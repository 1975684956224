import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getSimplifiedSubjectsReport } from '~/services/reports/environments';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function SimplifiedSubjectsReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getSimplifiedSubjectsReport}
        baseName="relatório_de_conclusão_de_módulos_simplificado_"
        id={id}
      />
    </div>
  );
}

SimplifiedSubjectsReport.propTypes = {
  id: PropTypes.string
};

SimplifiedSubjectsReport.defaultProps = {
  id: ''
};
