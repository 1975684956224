import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100vw',
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    flexGrow: 1,
    paddingTop: 30
  },
  sidebar: {
    width: '20%',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '80%',
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  header: {
    paddingLeft: 25,
    paddingRight: 25
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
    color: props => props.theme.palette.primary.main
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 60,
    width: '100%',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },
    '& ::-webkit-scrollbar-track': {
        background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
        background: (props) => `${props.theme.palette.secondary.main}`,
        bordeRadius: '2px'
    },
    '@media (max-width: 600px)': {
      marginTop: 0
    }
  },
  scrollBox: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  selectedContent: {
    padding: '0 25px 25px 25px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tabBar: {
    display: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    maxWidth: '100%',
    marginTop: 15,
    minHeight: 40,
    '& ::-webkit-scrollbar': {
      height: 0
    },
    '@media (max-width: 600px)': {
      display: 'block'
    }
  }
});