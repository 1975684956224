import React from 'react';

import { editEnvironmentFormSchema } from '~/components-refactor/molecules/ReplicatedSteps/EnvironmentStep/EditEnvironmentForm/validation';
import { CreateReplicatedStructure } from '~/components-refactor/organisms/CreateReplicatedStructure';
import { ReplicatedStructureProvider } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext';
import i18n from '~/I18n';
import environmentMirrorService from '~/services/mirroring/environment';
import environmentsListService from '~/services/mirroring/mirroring_list/environments';

export default function ReplicateEnvironment() {
  return (
    <ReplicatedStructureProvider
      mirrorType="environment"
      listService={environmentsListService}
      mirrorService={environmentMirrorService}
    >
      <CreateReplicatedStructure
        structureName={i18n.t(`StructuresName.Environment.SingularUpper`)}
        stepsCount={3}
        resolver={editEnvironmentFormSchema}
      />
    </ReplicatedStructureProvider>
  );
}
