import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    '& .MuiFormControl-root': {
      borderRadius: 5
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: (props) =>
          props.colorScheme === 'primaryBg'
            ? 'none'
            : `1px solid ${props.theme.palette.primary.main}`,
        borderColor: (props) => props.theme.palette.primary.main
      },
      '&.Mui-error fieldset': {
        borderColor: (props) => props.theme.palette.error.main
      }
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      color: (props) =>
        props.disabledBorderColor === props.theme.palette.primary.main
          ? 'black'
          : 'rgba(0, 0, 0, 0.38)'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => props.disabledBorderColor
    }
  },
  textarea: {
    width: '100%',
    backgroundColor: 'white'
  },
  input: {
    fontSize: 12,
    lineHeight: 1.3,
    padding: 12,
    backgroundColor: 'white',
    borderRadius: 5,
    '&::placeholder': {
      color: (props) => props.theme.palette.textActiveElement.main,
      opacity: 0.75
    }
  },
  helperTextRight: {
    '@media (min-width: 600px)': {
      whiteSpace: 'nowrap',
      position: 'absolute',
      left: '100%',
      top: 36
    }
  },
  errorText: {
    textAlign: 'center'
  }
});
