import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { updateGoogleCredentials } from '~/pages/GoogleOauth2Callback/fetch';

export default function GoogleOauth2Callback() {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    const body = {
      code: queryParameters.get('code')
    };

    updateGoogleCredentials(body)
      .then(() => window.close())
      .catch(() => window.close());
  }, [queryParameters]);

  return <div>{/*<p>Code: {queryParameters.get('code')}</p>*/}</div>;
}
