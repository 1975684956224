import React from 'react';

import Main from '../Main';

export default function AudioMiniCard({ size, ...rest }) {
  return (
    <Main
      color="#096FCE"
      backgroundColor="#CBDCFD"
      size={size}
      text="Áudio"
      {...rest}
    />
  );
}
