import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px'
  },
  circularProgress: {
    color: theme.palette.activeText.main
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
