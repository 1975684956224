import React, { useState } from 'react';

import Text         from '~/components/atoms/Text';
import Select       from '~/components/atoms/Select';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import i18n from '~/I18n';

import { Grid, useTheme } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { replicateAuthorization } from './fetch';

export default function SelectOptions({ authorizations }) {

  const theme = useTheme()
  
  const [selectedAuth, setSelectedAuth] = useState(null)
  const { watch }                       = useFormContext()

  const handleChange = event => {
    setSelectedAuth(event.target.value)
  }

  const handleClick = () => {
    replicateAuthorization(selectedAuth, watch("id")).then(() => {
      alert("Integração feita com sucesso!")
      window.location.reload() 
    }).catch(error => alert(`Não foi possível replicar a autorização do ${i18n.t('StructuresName.Environment.SingularLower')} selecionado.`))
    
  }

  return (
    <>
      <div style={{ marginBottom: '2px'}}>
        <Text color="black" variant="mediumText"> Deseja utilizar o código de autorização de algum desses {`${i18n.t('StructuresName.Environment.PluralLower')}`}? </Text>  
      </div>
      
      <div>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={9}>
            <Select values={ authorizations.map(auth => auth.id) } 
                    labels={ authorizations.map(auth => auth.environment.name)}
                    onChange={handleChange}
                    size="small"
                    fullWidth 
                    />
          </Grid>
          <Grid item>
          <ButtonSquare
            width={80}
            height={40}
            backgroundColor={theme.palette.secondary.main}
            onClick={handleClick}
          >
            <Text color="white" variant="mediumText">
              Selecionar
            </Text>
          </ButtonSquare>
          </Grid>
        </Grid>
      </div>
    </>                
  );
}