import base from './base';
const baseUrl = 'https://beta.digital.editoraespiral.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjuo1mplt';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'Kwykc8ojlQjEXhrtZYPBIdxXsSXsjUIHqU2eN2xbK7c',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://beta.digital.editoraespiral.com.br:44443',
  clarityKey
};
