import initialPagesImageBgRecover from '../assets/images/aedutec-bg.png';
import initialPagesImageBgLogin from '../assets/images/aedutec-bg.png';
import logoColorful from '../assets/logos/colorful/aedutec.svg';
import logo from '../assets/logos/logo-aedutec.png';
import { muiCreateTheme } from './mui-create-theme';

const title = 'AEDUTEC';
const loginLogo = {
  width: 260
};
const appBarLogo = {
  width: 115
};

const imageBackgroundLogin = {
  height: '100%',
  width: '70%'
};

const palette = {
  primary: {
    main: '#642273'
  },
  secondary: {
    main: '#659847'
  },
  tertiary: {
    main: '#606062'
  },
  accent: {
    main: '#d1432b'
  },
  activeText: {
    main: '#676D82'
  },
  pendent: {
    main: '#75aafb'
  },
  hiperlinkActiveText: {
    main: '#00C2FF'
  },
  textInactiveElement: {
    main: '#e7e7e7'
  },
  textActiveElement: {
    main: '#707070'
  },
  text: {
    main: '#000000'
  },
  textAndBackground: {
    main: '#ffffff'
  },
  warn: {
    main: '#FD9D4D'
  },
  error: {
    main: '#EE1D23'
  },
  shimmer: {
    main: 'rgba(87, 154, 255, 0.43)'
  },
  success: {
    main: '#35998F'
  }
};

const overrides = {
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        borderColor: '#990100'
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#990100',
        borderWidth: 2
      },
      '&$focused $notchedOutline': {
        borderColor: '#990100'
      }
    },
    notchedOutline: {}
  },
  MuiSelect: {
    icon: {
      fill: '#990100'
    }
  }
};

let light = muiCreateTheme({
  size: {
    loginLogo: loginLogo,
    appBarLogo: appBarLogo,
    imageBackgroundLogin: imageBackgroundLogin
  },
  palette: {
    type: 'light',
    ...palette
  },
  status: {
    danger: 'orange'
  },
  overrides: overrides
});

export default {
  logo,
  logoColorful,
  ...light,
  title,
  initialPagesImageBgLogin,
  initialPagesImageBgRecover
};
