import React from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/Input';

import Field from '../../GenericFormWrapper/Field';
import Recaptcha from './Recaptcha';
import Remember from './Remember';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export default function LoginForm() {
  const classes = useStyles();
  const style = {
    color: 'rgb(250, 175, 25)',
    fontSize: 12,
    fontWeight: 'bold'
  };

  const { setValue } = useFormContext();

  const loginLabel = (text) => {
    return <div style={{ fontSize: 16, color: 'white' }}> {text} </div>;
  };

  const recaptchaChange = (data) => {
    if (data) setValue('recaptcha', true);
  };

  return (
    <Box>
      <div className={classes.field}>
        <Field
          label={loginLabel('Login')}
          placeholder={'E-mail ou nome de usuário'}
          name="login"
          Component={Input}
          type="text"
          errorComponent={Typography}
          errorComponentProps={{ style: style }}
        />
      </div>
      <Field
        label={loginLabel('Senha')}
        placeholder="Senha"
        name="password"
        Component={Input}
        type="password"
        errorComponent={Typography}
        errorComponentProps={{ style: style }}
      />
      <div className={classes.smallField}>
        <Remember />
      </div>
      {process.env.NODE_ENV === 'production' && (
        <div className={classes.smallField}>
          <Field
            name="recaptcha"
            Component={Recaptcha}
            onChange={recaptchaChange}
            errorComponent={Typography}
            errorComponentProps={{ style: style }}
          />
        </div>
      )}
    </Box>
  );
}
