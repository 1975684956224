import React from 'react';

import AudioPlayer from '~/components/organisms/AudioPlayer';
import settings from '~settings';

import iconeMidiaAudio from 'src/assets/images/icone-midia-audio.svg';

export default function AudioLecture({ lecture }) {
  const audioHref = lecture.lectureable.links[0].href;
  const audioSrc = `${settings.baseUrl}/${audioHref}`;

  const getName = () =>
    `${lecture.owner.first_name} ${lecture.owner.last_name}`;

  return (
    <AudioPlayer
      audioSrc={audioSrc}
      thumbnail={iconeMidiaAudio}
      ownerName={getName()}
    />
  );
}
