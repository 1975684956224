import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 5px 10px 0'
  },
  wapperCertificateLogo: {
    display: 'flex',
    maxWidth: 300,
    minWidth: 280,
    width: 400,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    alignSelf: 'center'
  },
  image: { resize: 'cover', width: '100%', height: 'auto' },
  wrapperImages: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  wrapperImageInput: {
    display: 'flex',
    flex: 1
  },
  root: {
    flex: 1
  },
  gridContainer: {
    marginLeft: 1,
    marginRight: 1,
    marginTop: 20,
    marginBottom: 10
  }
});
