import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width: 0px)': {
      height: '420px'
    },
    '@media (min-width: 600px)': {
      height: '450px'
    },
    '@media (min-width: 960px)': {
      height: '500px'
    },
    '@media (min-width: 1280px)': {
      height: '600px'
    },
    '@media (min-width: 1920px)': {
      height: '800px'
    },
    overflowX: 'hidden',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  container: {
    overflowY: 'scroll',
    '& ::-webkit-scrollbar': {
      width: '7px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  field: {
    marginBottom: '24px'
  }
}));
