import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: '24px',
  },
  smallField: {
    marginTop: '6px',
  },
  submitButton: {
    width: '100%',
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    border: 0,
    borderRadius: '0.5rem',
    color: 'white',
    fontSize: '1.6rem',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main
    }
  },
  sebraeButton: {
    width: '100%',
    height: 40,
    backgroundColor: 'white',
    border: 0,
    borderRadius: 20,
    color: theme.palette.tertiary.main,
    fontSize: '1.6rem',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.tertiary.main,
      borderColor: theme.palette.tertiary.main
    }
  },
}));
