import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '60%',
    maxHeight: '100%',
    borderRadius: '8px',
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    },
    '@media (min-width: 0px) and (max-width: 450px)': {
      width: '100%',
      height: '100%'
    },
    '@media (min-width: 451px)': {
      minWidth: '450px'
    }
  }
}));
