import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Creating action types & creators
 */

export const { Types, Creators } = createActions({
  handleOpen: ['message', 'alertType', 'item'],
  handleClose: [],
  handleProceed: []
});

/**
 * Creating the reducer handlers
 */
const INITIAL_STATE = {
  message: '',
  type: 'success',
  open: false,
  item: null
};

const handleOpen = (state = INITIAL_STATE, actions) => {
  return produce(state, (draft) => {
    draft.open = true;
    draft.type = actions.alertType;
    draft.message = actions.message;
    draft.item = actions.item;
  });
};

const handleClose = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.open = false;
  });
};

const handleProceed = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.type = 'success';
    draft.message = `item: ${state.item.text}, foi excluído!`;
  });
};

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.HANDLE_OPEN]: handleOpen,
  [Types.HANDLE_CLOSE]: handleClose,
  [Types.HANDLE_PROCEED]: handleProceed
});
