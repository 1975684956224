import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import LaPlaceContext from '~/components/contexts/LaPlaceContext';
import i18n from '~/I18n';
import inovaulaService from '~/services/inovaula';

import { useStyles } from './styles';
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

function InovaulaSelectExerciseListModal({ open, handleClose }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { handleSucessImportLecture, subjectID } = useContext(LaPlaceContext);
  const { enqueueSnackbar } = useSnackbar();

  const [exerciseList, setExerciseList] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const infiniteScrollRef = useRef(null);
  const observer = useRef(null);

  const getExerciseLists = useCallback(async () => {
    if (!hasMore || loading) return;
    try {
      setLoading(true);
      const data = await inovaulaService.getExerciseLists(page);
      handleSuccessGetLists(data);
    } catch (error) {
      console.log(error);
    }
  }, [hasMore, loading, page]);

  const handleSuccessGetLists = (data) => {
    setExerciseList((prev) => [...prev, ...data]);
    const fullPage = data.length >= 10;
    setHasMore(fullPage);
    setPage((prev) => prev + fullPage);
    setLoading(false);
  };

  const handleImport = () => {
    inovaulaService
      .importExercise(subjectID, exerciseList[selected].id)
      .then(handleImportSuccess)
      .catch(handleImportError);
  };

  const handleImportSuccess = () => {
    setExerciseList([]);
    setSelected(-1);
    handleSucessImportLecture();
    handleClose();
  };

  const handleImportError = () => {
    enqueueSnackbar('Não foi possível importar a lista.', { variant: 'error' });
  };

  useEffect(() => {
    getExerciseLists();
  }, []);

  useEffect(() => {
    if (!infiniteScrollRef.current) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        getExerciseLists();
      }
    });
    observer.current.observe(infiniteScrollRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [infiniteScrollRef.current, getExerciseLists]);

  const secondaryText = (text) => (
    <Text color="gray" variant="lg" style={{ fontWeight: 400 }}>
      {text}
    </Text>
  );

  function Buttons() {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonSquare onClick={handleClose} className={classes.button}>
          <Typography className={classes.cancelButton}>
            {i18n.t('Cancel')}
          </Typography>
        </ButtonSquare>
        <ButtonSquare
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={handleImport}
        >
          <Typography className={classes.importButton}>
            {i18n.t('SelectExerciseList.Import')}
          </Typography>
        </ButtonSquare>
      </div>
    );
  }

  function ExerciseList() {
    return (
      <FormControl
        component="fieldset"
        style={{
          width: '100%',
          padding: '8px',
          gap: '16px',
          height: '200px',
          overflow: 'scroll'
        }}
      >
        <RadioGroup
          aria-label="list"
          name="list"
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {exerciseList?.map((list, index) => (
            <FormControlLabel
              key={list.id}
              value={index.toString()}
              control={<Radio color="primary" />}
              label={secondaryText(list.title)}
            />
          ))}
          {loading && <Skeleton />}
          <Box style={{ height: '1px' }} ref={infiniteScrollRef} />
        </RadioGroup>
      </FormControl>
    );
  }

  function Skeleton() {
    return <span>{secondaryText('Carregando...')}</span>;
  }

  function Title() {
    return (
      <>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ImportExportIcon style={{ fontSize: '24px' }} />
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 500 }}
            >
              {i18n.t('SelectExerciseList.Title')}
            </Text>
          </div>
          <ButtonSquare onClick={handleClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </ButtonSquare>
        </div>
        {secondaryText(i18n.t('SelectExerciseList.Description'))}
      </>
    );
  }

  return (
    <Modal
      disableEnforceFocus
      className={classes.modal}
      open={open}
      closeAfterTransition
    >
      <Paper className={classes.paper}>
        <Title />
        <ExerciseList />
        <Buttons />
      </Paper>
    </Modal>
  );
}

InovaulaSelectExerciseListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default InovaulaSelectExerciseListModal;
