import React from 'react';

import { Box, useTheme, Divider, Button } from '@material-ui/core';
import { IconWrapper } from '~/utils/forms';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import Accordion from '~/components/templates/Accordion';
import { useStyles } from './styles';
import api from '~/services/api';

const CertificatesBox = ({ title, courses }) => {
  const classes = useStyles();
  const theme = useTheme();

  const downloadCertificate = async (course) => {
    await api
      .get(`api/courses/${course.course_id}/generate_certificate`, {
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `Certificado ${course.course_name}.pdf`;

        link.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box className={classes.container}>
      <Accordion title={title} environment conquests>
        <Box className={classes.courses__wraper}>
          {courses ? (
            courses.map((course) => (
              <>
                <Box className={classes.certificate__wraper}>
                  <Text
                    color={theme.palette.primary.main}
                    style={{ marginTop: 5 }}
                    fontSize="16px"
                  >
                    <Box className={classes.course__title}>
                      {course.course_name}
                      <Box className={classes.download__button}>
                        <Button
                          style={{
                            color: theme.palette.primary.main,
                            width: '5%',
                            padding: 0
                          }}
                          onClick={() => downloadCertificate(course)}
                        >
                          <IconWrapper
                            color={theme.palette.primary.main}
                            fontSize={18}
                            Icon={GetAppIcon}
                          />
                        </Button>
                      </Box>
                    </Box>
                  </Text>
                  <Text
                    variant="mediumText"
                    color="gray"
                    style={{ marginTop: 5 }}
                  >
                    Concluído em {`${course.end_period}`}
                  </Text>
                </Box>
              </>
            ))
          ) : (
            <Text variant="subtitle" color="gray">
              Não há certificados neste ambiente.
            </Text>
          )}
        </Box>
      </Accordion>
      <Divider style={{ height: '2px' }} />
    </Box>
  );
};

CertificatesBox.propTypes = {
  title: PropTypes.string,
  courses: PropTypes.array
};

CertificatesBox.defaultProps = {
  title: '',
  courses: []
};

export default CertificatesBox;
