import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const LectureShimmer = () => {
  const classes = makeStyles({
    shimmerItem: {
      marginBottom: 24,
      borderRadius: 5,
      width: 230,
      height: 130,
      '@media (max-width: 600px)': {
        width: 315,
        height: 176
      }
    }
  })();

  return Array(4)
    .fill(null)
    .map((shimmer, index) => (
      <Skeleton key={index} className={classes.shimmerItem} variant="rect" />
    ));
};

export default LectureShimmer;
