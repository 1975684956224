import * as yup from 'yup';

export const editLectureValidations = yup
  .object()
  .shape({
    name: yup.string().required('Este campo é obrigatório.'),
    lectureable: yup.object({
      type: yup.string(),
      exercise: yup.object().when('type', {
        is: 'exercise',
        then: yup.object().shape({
          questions: yup
            .array()
            .min(1, 'É necessário criar pelo menos uma questão.')
            .required('É necessário criar pelo menos uma questão.')
        })
      })
    })
  })
  .required();
