import settings from '~settings';

const client = settings.name;

export const modalTexts = {
  2: 'Aqui você terá acesso a todas as atualizações referentes às mídias recentemente visualizadas, além de poder explorar as próximas mídias do seu curso. Dessa forma, você estará constantemente atualizado, evitando qualquer possibilidade de se perder no conteúdo.',
  3: 'Neste espaço, você terá uma visão abrangente do que está ocorrendo nos cursos, poderá compartilhar conteúdos interessantes em seu mural e também conferir as postagens de pessoas e amigos que você está seguindo.',
  4: 'Aqui você pode ficar sabendo dos ambientes em que está cadastrado e o seu histórico de disciplinas. Ah! Você também pode conferir as suas conquistas recentes.',
  5: `Você terá a oportunidade de interagir por meio de mensagens privadas com outros estudantes e amigos da mesma turma que a sua. Além disso, se surgir alguma dúvida sobre a plataforma ${client}, você poderá acessar facilmente a central de ajuda para obter assistência.`
};
