import React from 'react';
import * as S from './style';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

export const QuestionsCard = ({questionsData}) => {

  return (
    <S.Container>
        <S.CardsContainer>
          <Text style={{ color: 'black', fontSize: '15px' }}>
            {i18n.t('Questionários')}:
          </Text>
          <Text color={'secondary'} style={{fontSize:'30px'}}>
            {questionsData?.questions_count}
          </Text>
          <Text style={{fontSize:'10px', color:'#a1a1a1', textAlign:'center'}}>Questões</Text>
          {/* [REFACTOR] - analisar se é realmente necessário */}
          {/* <Text color={('secondary')} style={{fontSize:'30px'}}>{questionsData?.explained_count}</Text>
          <Text style={{fontSize:'10px', color:'#a1a1a1'}}>{i18n.t('ExplainedQuestions')}</Text> */}
        </S.CardsContainer>
    </S.Container>
  );
};

export default QuestionsCard;
