import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Box, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

export default function SpacesLoading() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={classes.skeletonContainer}>
      <Skeleton
        variant="rect"
        width={230}
        height={190}
        style={{ marginBottom: 5, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        width={230}
        height={190}
        style={{ marginBottom: 5, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        width={230}
        height={190}
        style={{ marginBottom: 5, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        width={230}
        height={190}
        style={{ marginBottom: 5, borderRadius: 5 }}
      />
      <Skeleton
        variant="rect"
        width={230}
        height={190}
        style={{ marginBottom: 5, borderRadius: 5 }}
      />
    </Box>
  );
}
