import React, { useState, useEffect } from 'react';

import { SettingsIcon } from '~/components/atoms/Icons';

import { Box, Menu } from '@material-ui/core';
import PropTypes from 'prop-types';

const PopUpMenuRefactored = React.forwardRef(function PopUpMenuRefactored({ anchorOrigin, transformOrigin, Component, children, forwardRef }, ref) {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (event) => {
    if (forwardRef) {
      setAnchorElement(forwardRef.current);
    } else {
      setAnchorElement(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Component onClick={handleClick} />

      <Menu
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Box onClick={handleClose}>{children}</Box>
      </Menu>
    </>
  );
});

PopUpMenuRefactored.propTypes = {
  text: PropTypes.string,
  showText: PropTypes.bool,
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
      Icon: PropTypes.any,
      disabled: PropTypes.bool
    })
  ),
  justOneLink: PropTypes.bool,
  Icon: PropTypes.any,
  onDelete: PropTypes.shape(),
  isCourse: PropTypes.bool
};

PopUpMenuRefactored.defaultProps = {
  text: '',
  showText: false,
  linkList: [],
  disabled: false,
  justOneLink: false,
  Icon: SettingsIcon,
  onDelete: null,
  isCourse: false
};

export default PopUpMenuRefactored;
