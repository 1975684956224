import settings from '~/settings';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  containerTitleLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 3
  },
  titleLabel: {
    fontSize: '6rem',
    color: 'white',
    fontWeight: '500',
    marginTop: 400
  },
  descriptionContainer: {
    display: 'flex',
    marginTop: settings.client === 'munera' ? 0 : '3.5rem',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '1.8rem',
    zIndex: 3
  },
  descriptionText: {
    fontSize: '2.2rem',
    color: 'white',
    fontWeight: '500'
  }
}));
