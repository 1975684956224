export default {
  versionName: 'AAA',
  versionNumber: '0.0.1',
  name: 'Redu',
  client: 'redu',
  websocketSecret: 'OUBtCXoayoc2uptN5tvuCCJvej',
  websocketKey: 'DEu3vO#gPJGMYT@US@YxWNJFAzLUy^BUZLAdu8Bxa6DFrLWR',
  zoomServiceSecret: 'PAt6b0p0xYE74fveyYe8GgJZbHQ5Le49',
  zoomRedirectUri: 'https://zoom.ava.viitra.in/oauth/callback',
  appstore: 'https://apps.apple.com/br/app/redu/id1523075541',
  playstore:
    'https://play.google.com/store/apps/details?id=com.viitra.redu&hl=pt_BR&gl=US',
  googleAuthClientId:
    '590034191650-rmrfddb4s0bjpij8nd40p78ljo7midqj.apps.googleusercontent.com',
  googleAuthScopes: [
    'https://www.googleapis.com/auth/calendar.app.created'
  ].join(' '),
  helpCenterUrl: 'https://ajuda.redu.digital'
};
