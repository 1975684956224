/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import AuthTermsModal from '~/components/molecules/AuthTerms';

import { useStyles } from './styles';

export default function VDONinja({ lecture }) {
  const classes = useStyles();
  const [roomURL, setRoomURL] = useState('');

  useEffect(() => {
    if (lecture) {
      setRoomURL(lecture.lectureable.external_resource);
    }
  }, [lecture]);

  return (
    <div className={classes.container}>
      <AuthTermsModal />
      <iframe
        allow="camera *;microphone *; display-capture *"
        width="100%"
        height="400"
        src={roomURL}
        allowFullScreen
      ></iframe>
    </div>
  );
}
