import React, { useEffect, useState } from 'react';

import DragDropFileInput from '~/components/atoms/DragDropFileInput';

import PropTypes from 'prop-types';
// import { useFormContext } from 'react-hook-form';
// import { Box, Input, useTheme, FormHelperText, Button, Select, MenuItem } from '@material-ui/core';
// import Field from '~/components/organisms/GenericFormWrapper/Field';
// import { TextPrimary } from '~/utils/forms';

const fileTypes = [
  //images
  'image/bmp',
  'image/x-windows-bmp',
  'image/gif', 
  'image/jpeg',
  'image/pjpeg', 
  'image/png',
  //document
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/pdf',
  'application/postscript',
  'text/plain',
  'application/msword',
  'application/excel',
  'text/rtf',
  'application/rtf',
  'application/vnd.sun.xml.writer',
  'application/vnd.sun.xml.impress',
  'application/vnd.sun.xml.calc',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.oasis.opendocument.text',
  'text/csv',
  //compressed
  'application/zip',
  'application/x-zip', 
  'application/x-rar-compressed',
  'application/vnd.rar',
  'application/x-rar'
];

export default function File({onFileChange, selectedFile, reset}) {
  const handleFileChange = (file) => {
    if (file) {
      onFileChange(file);
    } else {
      onFileChange(null); 
    }
  };

  return (
    <DragDropFileInput
      name="fileContainer"
      value={selectedFile}
      permittedFileTypes={fileTypes}
      onChange={handleFileChange}
      maxSize={25} // máximo em Mb
      reset={reset}
    />
  );
}

File.propTypes = {
  onFileChange: PropTypes.func.isRequired,
};

