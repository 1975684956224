import React from 'react';
import { useCallback } from 'react';

import ObjectItem from '../ObjectItem';
import update from 'immutability-helper';

export default function ObjectsContainer({
  objects,
  setObjects,
  Container,
  ObjectComponent,
  itemType,
  ...rest
}) {
  const moveObjects = useCallback(
    (dragIndex, hoverIndex) => {
      const dragObject = objects[dragIndex];
      setObjects(
        update(objects, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragObject]
          ]
        })
      );
    },
    [objects]
  );

  const renderObject = (object, index) => (
    <ObjectItem
      key={object.id}
      id={object.id}
      index={index}
      itemType={itemType}
      moveObjects={moveObjects}
      ObjectComponent={
        <ObjectComponent
          index={index}
          setObjects={setObjects}
          {...object}
          {...rest}
        />
      }
    />
  );

  return (
    <Container>
      {' '}
      {objects.map((object, i) => renderObject(object, i))}{' '}
    </Container>
  );
}
