import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { SettingsIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';
import FormFilter from '~/components/FormFilter';
import CourseCard from '~/components/molecules/CourseCard';
import OrderByPopover from '~/components/OrderByPopover';
import i18n from '~/I18n';
import api from '~/services/api';

import SpacesLoading from './loading';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

const Content = ({ courseId, environmentId, environmentName }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: ''
    }
  });
  const { profile } = useSelector((state) => state.user);

  const [spaceItems, setSpaceItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreSpaces, setHasMoreSpaces] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const getSpaces = async (pageNumber) => {
    setIsFetching(true);
    try {
      const { data } = await api.get(`api/courses/${courseId}/spaces`, {
        params: {
          order: filters.getValues().ord,
          sort: filters.getValues().direction,
          name_like: filters.getValues().name_like,
          page: pageNumber
        }
      });
      if (pageNumber === 1) {
        setSpaceItems(data);
      } else {
        setSpaceItems((prevItems) => [...prevItems, ...data]);
      }
      setHasMoreSpaces(data.length >= 10);
      setPage(pageNumber);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setHasMoreSpaces(false);
      }
    } finally {
      setIsFetching(false);
      setIsInitialLoad(false);
    }
  };

  useEffect(() => {
    setIsInitialLoad(true);
    getSpaces(1);
  }, [
    filters.getValues().ord,
    filters.getValues().direction,
    filters.getValues().name_like
  ]);

  const getMoreItems = (e) => {
    if (isFetching || !hasMoreSpaces) return;
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;
    if (bottom) {
      getSpaces(page + 1);
    }
  };

  return (
    <>
      <Text variant="mdTitle" color="primary">
        {`${i18n.t('StructuresName.Space.PluralUpper')}`}
      </Text>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: 32
        }}
      >
        <FormProvider {...filters}>
          <FormFilter
            placeholder={`Pesquisar ${i18n.t(
              'StructuresName.Space.SingularUpper'
            )}...`}
          />
          <OrderByPopover />
        </FormProvider>
      </div>
      <Box className={classes.root}>
        <Box className={classes.content} onScroll={getMoreItems}>
          {isInitialLoad ? (
            <>
              <SpacesLoading />
            </>
          ) : null}
          {!isInitialLoad && spaceItems && (
            <div className={classes.root}>
              {spaceItems.map((space, index) => {
                const linkList = [
                  {
                    link: `/dashboard/environments/${environmentId}/courses/${courseId}/spaces/${space.id}/edit`,
                    Icon: SettingsIcon,
                    text: i18n.t('EditSpace.title'),
                    disabled: false
                  }
                ];
                return (
                  <CourseCard
                    courseID={courseId}
                    spaceID={space.id}
                    profile={profile}
                    instance={space}
                    instanceType="space"
                    key={index}
                    environmentId={environmentId}
                    environmentName={environmentName}
                    linkList={linkList}
                    link={`/dashboard/spaces/${space.id}`}
                    editLink={`/dashboard/spaces/${space.id}/edit`}
                  />
                );
              })}
            </div>
          )}
        </Box>

        {!isFetching && spaceItems.length === 0 ? (
          <Text
            color="primary"
            variant="lg"
            style={{ letterSpacing: 1, fontWeight: 500 }}
          >
            {i18n.t('NoSpacesForThisCourse')}
          </Text>
        ) : null}
      </Box>
    </>
  );
};

export default Content;
