import { Grid, useTheme } from '@material-ui/core';
import * as S from './style';
import React from 'react';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

export const ReportUpBox = ({ openListOfAnswers, openTable }) => {
    const theme = useTheme();

    return (
        <S.ButtonsContainer>
            <Grid container direction='row' alignItens='center' justifyContent='center' spacing={2}>
                <Grid item>
                    <ButtonSquare
                        backgroundColor={`${theme.palette.secondary.main}BF`}
                        onClick={openListOfAnswers}
                    >
                        <Text variant="subtitle" color="white" style={{paddingLeft:'15px', paddingRight:'15px'}} >
                            {/* {i18n.t('ListOfAnswers')} */}
                            Ver Questões
                        </Text>
                    </ButtonSquare>
                </Grid>
                <Grid item>
                    <ButtonSquare
                        backgroundColor={`${theme.palette.secondary.main}BF`}
                        width={150}
                        onClick={openTable}
                    >
                        <Text variant="subtitle" color="white" >
                            {i18n.t('QuestionsReport')}
                        </Text>
                    </ButtonSquare>
                </Grid>
            </Grid>
        </S.ButtonsContainer>
    )
};

export default ReportUpBox;