import api from '~/services/api';

// Defines the endpoint for operations on user-environment associations.
const baseURL = '/api/user_environment_associations';

// Function to modify the role within an environment association for a user.
export const update = async (UserEnvironmentAssociationID, role) => {
  // Sends a PUT request to change the role of an existing association based on its unique ID.
  const { data } = await api.put(`${baseURL}/${UserEnvironmentAssociationID}`, {
    user_environment_association: { role }
  });
  // Returns the updated data from the server, reflecting the role change.
  return data;
};

// Function to link a user to an environment, assigning them a specific role.
export const create = async (environmentID, userID, role) => {
  // Sends a POST request to form a new link between a user and an environment, specifying their role.
  // Note: Environment ID is explicitly converted to an integer to match expected server-side data types.
  const { data } = await api.post(baseURL, {
    user_environment_association: {
      environment_id: parseInt(environmentID, 10), // Ensures environment ID is correctly typed.
      user_id: userID,
      role
    }
  });
  // Returns the newly created association detail from the response.
  return data;
};

// Exports the 'update' and 'create' functionalities to enable their use across the application.
export default {
  update,
  create
};
