import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '200px',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    color: '#ffff',
    borderRadius: '5px'
  }
}));

export default function IframeFormButtons({ Text, handleClick }) {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={handleClick}>
      {Text}
    </button>
  );
}
