import React, { useEffect, useState } from 'react';

import { scaleOptions } from '../constants';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';

export default function ScaleMenu({
  setScale,
  scale,
  contentDocumentRef,
  pageDimensions,
  rotateValue,
  maxScaleX
}) {
  const theme = useTheme();

  const [currentScaleOptions, setCurrentScaleOptions] = useState([]);
  const [isOpenSelectScaleMenu, setIsOpenSelectScaleMenu] = useState(false);

  const handleClickOptionInSelectScaleMenu = (selectedScale) => {
    setScale(selectedScale);
    setIsOpenSelectScaleMenu(false);
  };

  const adjustScaleToContentSize = (scaleMode = null) => {
    if (!contentDocumentRef.current) return;

    const { offsetWidth, offsetHeight } = contentDocumentRef.current;
    const screenWidth = offsetWidth - 16;
    const screenHeight = offsetHeight - 16;

    const documentWidth = pageDimensions.width;
    const documentHeight = pageDimensions.height;

    let scaleX;
    let scaleY;

    if (rotateValue === 90 || rotateValue === 270) {
      scaleX = screenHeight / documentWidth;
      scaleY = screenWidth / documentHeight;
    } else {
      scaleX = screenWidth / documentWidth;
      scaleY = screenHeight / documentHeight;
    }

    let scaleToUse;

    if (scaleMode === 'page_adjustment') {
      scaleToUse = scaleY;
    } else if (scaleMode === 'page_width_adjustment') {
      scaleToUse = scaleX;
    }

    setScale(scaleToUse);
  };

  useEffect(() => {
    const newScaleOptions = scaleOptions.filter(
      (scaleValue) => scaleValue <= maxScaleX
    );
    newScaleOptions.push(maxScaleX);
    setCurrentScaleOptions(newScaleOptions);
  }, [maxScaleX]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpenSelectScaleMenu(false)}>
      <span>
        <ButtonBase
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            borderRadius: '4px'
          }}
          onClick={() => setIsOpenSelectScaleMenu((prev) => !prev)}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '14px',
              paddingLeft: '8px'
            }}
          >
            {Math.trunc(scale * 100)}%
          </Typography>
          <ArrowDropDownIcon
            style={{
              height: '24px',
              width: '24px'
            }}
          />
        </ButtonBase>
        {isOpenSelectScaleMenu && (
          <Paper
            style={{
              position: 'absolute',
              top: '40px',
              left: '47%'
            }}
          >
            <MenuList>
              <MenuItem
                key={'page_adjustment'}
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: theme.palette.textActiveElement.main,
                  display: 'flex',
                  justifyContent: 'center'
                }}
                onClick={() => adjustScaleToContentSize('page_adjustment')}
              >
                <span>Ajuste de página</span>
              </MenuItem>
              <MenuItem
                key={'page_width_adjustment'}
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: theme.palette.textActiveElement.main,
                  display: 'flex',
                  justifyContent: 'center'
                }}
                onClick={() =>
                  adjustScaleToContentSize('page_width_adjustment')
                }
              >
                Largura da página
              </MenuItem>
              <Box
                style={{
                  height: '1px',
                  backgroundColor: '#A8B1CE'
                }}
              ></Box>
              {currentScaleOptions.map((scaleOption, index) => (
                <MenuItem
                  key={index}
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: theme.palette.textActiveElement.main
                  }}
                  onClick={() =>
                    handleClickOptionInSelectScaleMenu(scaleOption)
                  }
                >
                  {`${Math.floor(scaleOption * 100)}%`}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        )}
      </span>
    </ClickAwayListener>
  );
}

ScaleMenu.propTypes = {
  setScale: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  contentDocumentRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }).isRequired,
  pageDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  rotateValue: PropTypes.number.isRequired
};
