import { Typography, withStyles } from '@material-ui/core';

export const DescriptionText = withStyles(() => ({
  root: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#A8B1CE',
    width: '100%'
  }
}))(Typography);
