import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import useStyles from './styles';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';

function FormFilter({ placeholder }) {
  const classes = useStyles();
  const { setValue, watch } = useFormContext();

  const [inputValue, setInputValue] = useState(watch('name_like'));

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
  };

  const debouncedSearchChange = useCallback(
    debounce((value) => setValue('name_like', value), 500),
    [watch('name_like')]
  );

  useEffect(() => {
    // Chama o debouncedSearchChange sempre que o inputValue muda
    debouncedSearchChange(inputValue);
  }, [inputValue]);

  return (
    <form className={classes.form} onSubmit={handleSearchSubmit}>
      <TextField
        className={classes.textField}
        label={placeholder}
        variant="outlined"
        value={inputValue}
        size="small"
        onChange={handleInputChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <SearchIcon
                className={classes.searchIcon}
                onClick={handleSearchSubmit}
              />
            </InputAdornment>
          ),
          style: { fontSize: 14 }
        }}
        InputLabelProps={{ style: { fontSize: 14 } }}
      />
    </form>
  );
}

export default FormFilter;
