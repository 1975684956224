import React, { useState } from 'react';

import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';

import { useTheme } from '@material-ui/core';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function UnlinkDialog({
  open,
  handleClose,
  unlink,
  structureID,
  callback,
  fallback
}) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUnlink = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      await unlink(structureID);
      enqueueSnackbar('A estrutura foi desvinculada com sucesso.', {
        variant: 'success'
      });
      callback();
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível concluir a ação solicitada, por favor tente novamente mais tarde.',
        { variant: 'error' }
      );
      fallback();
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      show={open}
      handleClose={handleClose}
      isLoading={isLoading}
      message={
        <>
          <p style={{ marginBottom: '16px' }}>
            Ao desvincular um espelho, ele <strong>perderá o vínculo</strong>{' '}
            com o item original e não receberá mais nenhuma atualização, com
            exceção das <strong>mídias que permanecerão espelhadas</strong>,
            caso deseje poderá editar as mídias ou poderá fazer uma cópia. Essa
            ação é <strong>irreversível</strong>.{' '}
          </p>
          <p>
            Tem certeza de que deseja <strong>desvincular</strong> esse espelho?
          </p>
        </>
      }
      handleConfirm={handleUnlink}
      confirmButtonText="Desvincular"
      titleText={'Desvincular'}
      titleIcon={
        <LinkOffIcon
          style={{
            alignSelf: 'center',
            fontSize: '24px',
            color: theme.palette.primary.main
          }}
        />
      }
      colorVariant={'primary'}
    />
  );
}
UnlinkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unlink: PropTypes.func.isRequired,
  callback: PropTypes.func,
  fallback: PropTypes.func,
  structureID: PropTypes.number.isRequired
};

UnlinkDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  callback: () => {},
  fallback: () => {}
};
