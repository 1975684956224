import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  showAllButton: {
    height: '23px',
    alignItems: 'stretch',
    '&:hover': {
      borderBottom: '1px solid #fff'
    }
  }
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(330px / 3);
  height: ${(props) => {
    return props.length <= 4 ? 'auto' : '320px';
  }};
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const EnvironmentList = styled.ul`
  overflow-y: overlay;
  margin-top: 10px;
  padding-right: 5px;
  box-sizing: border-box;
  a {
    display: flex;
    color: #fff;
    padding: 10px;
    border-radius: 12px;
    width: 95%;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    & + a {
      margin-top: 0px;
    }

    div {
      display: flex;
      align-items: center;
      img {
        height: 45px;
        width: 45px;
        border-radius: 7%;
        margin-right: 1.5rem;
      }
    }

    aside {
      width: 150px;
      h1 {
        font-size: 1.4rem;
      }

      span {
        font-size: 1.2rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
