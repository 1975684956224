import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '10rem',
    height: '100vh',
    backgroundImage:
      'linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0, 1))'
  }
});
