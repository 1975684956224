import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  Container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: '2rem',
    position: 'relative'
  },
  TextArea: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: '67px',
    maxHeight: '150px',
    padding: '1rem',
    fontSize: '14px',
    marginBottom: '1rem',
    border: '1px solid rgba(112,112,112,0.38)',
    borderRadius: '4px',
    resize: 'vertical',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&::placeholder': {
      color: 'rgba(112,112,112,0.38)'
    },
    '&:-moz-placeholder': {
      color: 'rgba(112,112,112,0.38)'
    },
    '&::-moz-placeholder': {
      color: 'rgba(112,112,112,0.38)'
    },
    '&::-webkit-input-placeholder': {
      color: 'rgba(112,112,112,0.38)'
    },
    '&:-ms-input-placeholder': {
      color: 'rgba(112,112,112,0.38)'
    }
  },
  attachButton: {
    width: 25,
    height: 25,
    marginRight: 15,
    '& svg': {
      width: 25,
      height: 25,
      color: (props) => props.theme.palette.textActiveElement.main
    }
  },
  fileInput: {
    display: 'none'
  },
  AttachmentContainer: {
    marginBottom: 15,
    marginRight: 15,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginRight: 10
    }
  }
}));

export const CountLeftCaracters = styled.span`
  color: #707070;
  font-size: 12px;
`;
