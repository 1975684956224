import React from 'react';

import { Box, useTheme, Typography, Link } from '@material-ui/core';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import HelpIcon from '@material-ui/icons/Help';

export default function ContactUs() {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ gap: '12px' }}
      marginTop="36px"
    >
      <Link
        href="/help"
        target="_blank"
        rel="noreferrer"
        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
      >
        <HeadsetMicIcon
          style={{
            color: theme.palette.textAndBackground.main,
            fontSize: '24px'
          }}
        />
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: '500',
            color: theme.palette.textAndBackground.main
          }}
        >
          Central de Ajuda
        </Typography>
      </Link>
      <Link
        href="/about"
        target="_blank"
        rel="noreferrer"
        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
      >
        <HelpIcon
          style={{
            color: theme.palette.textAndBackground.main,
            fontSize: '24px'
          }}
        />
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: '500',
            color: theme.palette.textAndBackground.main
          }}
        >
          Sobre a plataforma
        </Typography>
      </Link>
    </Box>
  );
}
