import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
    textAlign: 'center',
    color: '#676D82',
    gap: 16
  },
  largeText: {
    fontSize: 32,
    lineHeight: '28px',
    marginTop: 24
  },
  text: {
    fontSize: 18,
    lineHeight: '24px'
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: '28px',
    paddingTop: 24
  }
}));
