import React from 'react';

import { useStyles } from './styles';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

function ButtonSquare({
  children,
  onClick,
  disabled,
  color,
  type,
  style,
  className,
  ...rest
}) {
  const classes = useStyles({ disabled, ...rest });

  return (
    <Button
      className={`${classes.root} ${className}`}
      onClick={onClick}
      size="small"
      disabled={disabled}
      color={color}
      type={type}
      style={style}
    >
      {children}
    </Button>
  );
}

ButtonSquare.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', '']), // Add or remove values based on your theme
  type: PropTypes.oneOf(['button', 'submit', 'reset', '']),
  style: PropTypes.object,
  className: PropTypes.string
};

ButtonSquare.defaultProps = {
  children: null,
  onClick: async () => {},
  disabled: false,
  color: 'default',
  type: 'button',
  style: {},
  className: ''
};

export default ButtonSquare;
