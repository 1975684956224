import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    color: theme.palette.activeText.main
  },
  titleText: {
    fontSize: '19px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  titleIcon: {
    fill: theme.palette.activeText.main,
    fontSize: '20px'
  },
  submitButtonForm: {
    padding: '8px 16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    }
  }
}));
