import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -10px;
  column-gap: 5px;
`;
export const BreadText = styled(Link)`
  max-width: 120px;
  font-size: 12px;
  white-space: nowrap;                  
  overflow: hidden; 
  text-overflow:    ellipsis;
  text-decoration:none;
  &:visited{
    color:gray;
  }
    ${(props) => props.last && 'text-decoration: underline; '}
  

    &:hover{
      color: blue;
      text-decoration: underline;}
    }
  color:gray;

`;

export const BreadArrow = styled.div`
  max-width: 120px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  color: gray;
  text-overflow: ellipsis;
`;

export const TextWraper = styled.div`
  display: flex;
  width: auto;
  padding: 1px;
  flex-direction: row;
`;
