import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { createMuralListener } from '~/services/mural';

export const WINDOW_SIZE = 20;
export const PER_PAGE = 10;

export const useStatusArrayManager = (lecture) => {
  const store = useStore();

  const [offset, setOffset] = useState(0);
  const [statusArray, setStatusArray] = useState([]);

  const [channel, setChannel] = useState('');
  const [statusInChannel, setStatusInChannel] = useState([]);

  useEffect(() => {
    if (channel !== '') {
      createMuralListener(channel, (data) => {
        const currentUserId = store.getState().user.profile.id;
        const statusUserId = data.content.user.id;
        if (currentUserId === statusUserId) return;
        const content = data.content;
        if (data.type === 'TYPE_COMMENT_POSTED') {
          setStatusInChannel((prev) => [content, ...prev]);
        } else if (data.type === 'TYPE_COMMENT_ANSWERED') {
          setStatusArray((prev) => {
            return prev.map((cur) => {
              if (cur.id === content.statusable_id) {
                return {
                  ...cur,
                  answers: [content, ...cur.answers]
                };
              } else return cur;
            });
          });
        }
      });
    }
  }, [channel]);

  useEffect(() => {
    if (lecture && lecture.id) {
      setChannel(`channel-${lecture.id}`);
    }
    return () => setChannel('');
  }, [lecture]);

  const addNewStatus = (newStatus) => {
    if (statusArray.length >= WINDOW_SIZE) {
      setStatusArray((prev) => [newStatus, ...prev.slice(0, 19)]);
    } else {
      setOffset((prev) => prev + 1);
      setStatusArray((prev) => [newStatus, ...prev]);
    }
  };

  const addNewAnswer = (newAnswer) => {
    const newStatusArray = statusArray.map((cur) => {
      if (cur.id === newAnswer.statusable_id) {
        return {
          ...cur,
          answers: [newAnswer, ...cur.answers]
        };
      } else return cur;
    });
    setStatusArray(newStatusArray);
  };

  const removeStatus = (deletedStatus) => {
    setStatusArray((prev) =>
      prev.filter((curStatus) => curStatus.id != deletedStatus.id)
    );
    setOffset((prev) => prev - 1);
  };

  const removeAnswer = (deletedAnswer) => {
    const newStatusArray = statusArray.map((cur) => {
      if (cur.id === deletedAnswer.statusable_id) {
        return {
          ...cur,
          answers: cur.answers.filter(
            (curAnswer) => curAnswer.id !== deletedAnswer.id
          )
        };
      } else {
        return cur;
      }
    });
    setStatusArray(newStatusArray);
  };

  const resetStatusArray = (newStatusArray) => {
    setOffset(newStatusArray.length);
    setStatusArray([...newStatusArray]);
  };

  const pushFront = (newDataStatus) => {
    if (newDataStatus.length + statusArray.length > WINDOW_SIZE) {
      setStatusArray((prev) =>
        [
          ...new Map(
            [...newDataStatus, ...prev].map((item) => [item.id, item])
          ).values()
        ].slice(0, WINDOW_SIZE)
      );
    } else {
      setStatusArray((prev) => [...newDataStatus, ...prev]);
    }
    setOffset((prev) => Math.max(prev - newDataStatus.length, WINDOW_SIZE));
  };

  const pushBack = (newDataStatus) => {
    if (newDataStatus.length + statusArray.length > WINDOW_SIZE) {
      setStatusArray((prev) =>
        [...prev, ...newDataStatus].slice(
          newDataStatus.length + statusArray.length - WINDOW_SIZE
        )
      );
    } else {
      setStatusArray((prev) => [...prev, ...newDataStatus]);
    }
  };

  return {
    offset,
    setOffset,
    statusArray,
    statusInChannel,
    channel,
    setStatusInChannel,
    setStatusArrayFuncs: {
      addNewStatus,
      addNewAnswer,
      resetStatusArray,
      removeStatus,
      removeAnswer,
      pushFront,
      pushBack
    }
  };
};
