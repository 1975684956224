import React, { useEffect, useState } from 'react';

import { getPdfData } from '~/services/Lecture/pdf';

import { useStyles } from './styles';
import { Button, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

export default function DownloadDocumentButton({ lecture }) {
  const classes = useStyles();

  const [pdfData, setPdfData] = useState(null);

  function downloadPdf() {
    if (!pdfData) {
      getPdfData(lecture.id)
        .then((file) => {
          setPdfData(file);
        })
        .catch();
    } else {
      fileDownload(pdfData, `${lecture.name}.pdf`);
    }
  }

  useEffect(() => {
    if (pdfData) fileDownload(pdfData, `${lecture.name}.pdf`);
  }, [pdfData]);

  return (
    <Tooltip
      title={'Baixar anexo'}
      arrow
      placement="top"
      classes={{ tooltip: classes.customTooltip }}
    >
      <Button
        disableRipple
        onClick={downloadPdf}
        className={classes.downloadButton}
      >
        <GetAppIcon className={classes.downloadButtonIcon} />
      </Button>
    </Tooltip>
  );
}

DownloadDocumentButton.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string
      })
    )
  })
};
