import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.activeText.main,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    backgroundColor: '#F2F2F7',
    borderRadius: '24px',
    padding: '8px 16px',
    minWidth: 'max-content'
  },
  buttonLabel: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  item: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 8px'
  },
  labelText: {
    fontSize: '14px',
    color: theme.palette.activeText.main,
    lineHeight: '24px'
  },
  icon: {
    fontSize: '18px',
    color: theme.palette.activeText.main
  }
}));
