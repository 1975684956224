import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '30px'
  },
  description: {
    marginBottom: '15px'
  },
  label: {
    marginRight: '5px'
  }
});