import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import DragDropInput from '~/components/atoms/DragDropInput';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const ImportData = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [menuItem, setMenuItem] = useState('add');
  const [currentPage, setCurrentPage] = useState(true);
  const isTablet = useMediaQuery('@media (max-width: 768px)');
  const isMobile = useMediaQuery('@media (max-width: 481px)');
  const [addDone, setAddDone] = useState({ done: false, sent: false });
  const [removeDone, setRemoveDone] = useState({ done: false, sent: false });
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileToRemove, setSelectedFileToRemove] = useState(null);

  const changeContext = (context) => {
    if (menuItem != context) {
      setCurrentPage(!currentPage);
      setMenuItem(context);
    }
  };

  const handleAddFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleRemoveFile = (e) => {
    setSelectedFileToRemove(e.target.files[0]);
  };

  const importAddFile = async () => {
    setAddDone({ done: false, sent: false });
    if (selectedFile) {
      const formData = new FormData();
      formData.append('uploaded_file', selectedFile);
      try {
        // const response = await api.post(
        //   `/api/courses/${courseId}/import_data/import_members`,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        //     }
        //   }
        // );

        setAddDone({ done: true, sent: true });
      } catch (error) {
        setAddDone({ done: false, sent: true });
      }
    }
  };

  const importRemoveFile = async () => {
    if (selectedFileToRemove) {
      const formData = new FormData();
      formData.append('uploaded_file', selectedFileToRemove);
      try {
        // const response = await api.post(
        //   `/api/courses/${courseId}/import_data/remove_members`,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        //     }
        //   }
        // );
        setRemoveDone({ done: true, sent: true });
      } catch (error) {
        setRemoveDone({ done: false, sent: true });
      }
    }
  };

  const Sidebar = () => (
    <div className={classes.modal__sideBar}>
      <div className={classes.sideBar_title}>
        <Text style={{ paddingBottom: 20 }} color="primary" variant="header">
          {i18n.t('Import')}
        </Text>
      </div>
      <div className={classes.modal__sideBar_menuWraper}>
        <div
          onClick={() => changeContext('add')}
          className={
            currentPage
              ? classes.modal__sideBar_menuWraper_itemSelected
              : classes.modal__sideBar_menuWraper_item
          }
        >
          <AddIcon className={classes.modal__sideBarIcon} />
          <Text
            style={{ width: '100px', marginLeft: 15 }}
            color="primary"
            variant="largeText"
          >
            Adicionar por importação
          </Text>
        </div>
        <div
          onClick={() => changeContext('remove')}
          className={
            !currentPage
              ? classes.modal__sideBar_menuWraper_itemSelected
              : classes.modal__sideBar_menuWraper_item
          }
        >
          <DeleteOutlineIcon className={classes.modal__sideBarIcon} />
          <Text
            style={{ width: '100px', marginLeft: 15 }}
            color="primary"
            variant="largeText"
          >
            Remover por importação
          </Text>
        </div>
      </div>
    </div>
  );

  const MobileSidebar = () => (
    <div className={classes.modal__sideBar_menuWraper}>
      <div
        onClick={() => changeContext('add')}
        className={
          currentPage
            ? classes.modal__sideBar_menuWraper_itemSelected
            : classes.modal__sideBar_menuWraper_item
        }
      >
        <AddIcon className={classes.modal__sideBarIcon} />
        {!isMobile && (
          <Text
            style={{ width: '100px', marginLeft: 15 }}
            color="primary"
            variant="largeText"
          >
            Adicionar por importação
          </Text>
        )}
      </div>
      <div
        onClick={() => changeContext('remove')}
        className={
          !currentPage
            ? classes.modal__sideBar_menuWraper_itemSelected
            : classes.modal__sideBar_menuWraper_item
        }
      >
        <DeleteOutlineIcon className={classes.modal__sideBarIcon} />
        {!isMobile && (
          <Text
            style={{ width: '100px', marginLeft: 15 }}
            color="primary"
            variant="largeText"
          >
            Remover por importação
          </Text>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Text variant="mdTitle" color="primary">
        Importar dados
      </Text>

      <Box className={classes.root}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowModal(true)}
          className={classes.members__buttons}
        >
          <ImportExportIcon fontSize="large" />

          {isTablet ? '' : 'Importar'}
        </Button>

        <DndProvider backend={HTML5Backend}>
          <Modal open={showModal} className={classes.modal}>
            <div className={classes.wrapModal}>
              {!isTablet && <Sidebar />}

              {currentPage ? (
                <form
                  // onSubmit={handleSubmit(submitMember)}
                  className={classes.modal__form}
                >
                  <div
                    className={classes.modal__exitButton}
                    onClick={() => setShowModal(false)}
                  >
                    <CloseIcon />
                  </div>

                  <div className={classes.modal__content}>
                    {isTablet && (
                      <Text
                        style={{ paddingBottom: 20 }}
                        color="primary"
                        variant="header"
                      >
                        {i18n.t('Import')}
                      </Text>
                    )}
                    {isTablet && <MobileSidebar />}
                    <div className={classes.modal__content_text}>
                      <Text variant="lgTitle" color="gray">
                        A importação para{' '}
                        <span className={classes.bold}>adicionar</span> dados
                        ficou mais fácil!
                      </Text>

                      <div>
                        <div>
                          <Text variant="lg" color="gray">
                            1. Baixe o modelo de importação no formato .xlsx,{' '}
                            <a
                              href="https://ava.viitra.in/static/modelo-de-remocao-de-membros.xlsx"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <b>
                                <u>aqui.</u>
                              </b>
                            </a>
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            2. Preencha os dados do(s){' '}
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            3. Anexe abaixo e submeta clicando no botão “
                            {i18n.t('Import')}”
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            4. Pronto!{' '}
                          </Text>
                        </div>
                      </div>
                    </div>

                    <DragDropInput
                      key={0}
                      done={addDone}
                      set={setAddDone}
                      // onDrop={handleFileDrop}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      handleFile={handleAddFile}
                    />
                  </div>

                  <div className={classes.modal__buttonWraper}>
                    <ButtonSquare
                      type="submit"
                      disabled={addDone.done}
                      padding="15px 25px"
                      onClick={importAddFile}
                      backgroundColor={
                        addDone.done ? 'white' : theme.palette.secondary.main
                      }
                    >
                      <Text color="white" variant="lg">
                        {addDone.done ? (
                          <CheckCircleIcon
                            style={{ color: 'white', fontSize: 40 }}
                          />
                        ) : (
                          i18n.t('Import')
                        )}
                      </Text>
                    </ButtonSquare>
                  </div>
                </form>
              ) : (
                <form
                  // onSubmit={handleSubmit(submitMember)}
                  className={classes.modal__form}
                >
                  <div
                    className={classes.modal__exitButton}
                    // onClick={toggleModal}
                  >
                    <CloseIcon />
                  </div>

                  <div className={classes.modal__content}>
                    {isTablet && (
                      <Text
                        style={{ paddingBottom: 20 }}
                        color="primary"
                        variant="header"
                      >
                        {i18n.t('Import')}
                      </Text>
                    )}

                    {isTablet && <MobileSidebar />}

                    <div className={classes.modal__content_text}>
                      <Text variant="lgTitle" color="gray">
                        A importação para{' '}
                        <span className={classes.bold}>remover</span> dados
                        ficou mais fácil!
                      </Text>
                      <div>
                        <div>
                          <Text variant="lg" color="gray">
                            1. Baixe o modelo de importação no formato .xlsx,{' '}
                            <a
                              href="https://ava.viitra.in/static/modelo-de-remocao-de-membros.xlsx"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <b>
                                <u>aqui.</u>
                              </b>
                            </a>
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            2. Preencha os dados do(s){' '}
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            3. Anexe abaixo e submeta clicando no botão “
                            {i18n.t('Import')}”
                          </Text>
                        </div>
                        <div>
                          <Text variant="lg" color="gray">
                            4. Pronto!{' '}
                          </Text>
                        </div>
                      </div>
                    </div>

                    <DragDropInput
                      key={1}
                      done={removeDone}
                      set={setRemoveDone}
                      // onDrop={handleFileToRemoveDrop}
                      selectedFile={selectedFileToRemove}
                      setSelectedFile={setSelectedFileToRemove}
                      handleFile={handleRemoveFile}
                    />
                  </div>

                  <div className={classes.modal__buttonWraper}>
                    <ButtonSquare
                      disabled={removeDone.done}
                      type="submit"
                      padding="15px 25px"
                      onClick={importRemoveFile}
                      backgroundColor={
                        removeDone.done ? 'white' : theme.palette.secondary.main
                      }
                    >
                      <Text color="white" variant="lg">
                        {removeDone.done ? (
                          <CheckCircleIcon
                            style={{ color: 'white', fontSize: 40 }}
                          />
                        ) : (
                          i18n.t('Import')
                        )}
                      </Text>
                    </ButtonSquare>
                  </div>
                </form>
              )}
            </div>
          </Modal>
        </DndProvider>
      </Box>
    </>
  );
};

export default ImportData;
