import React from 'react';
import { Controller, useFormContext, get } from 'react-hook-form';

import Text from '~/components/atoms/Text';

import { Grid } from '@material-ui/core';

export default function Field ({
  label,
  labelPlacement,
  name,
  control,
  rules,
  hideErrors,
  Component,
  classes,
  errorComponent: Error,
  errorComponentProps,
  ...rest
}) {
  const {
    formState: { errors }
  } = useFormContext();
  const error = get(errors, name);

  const showError = (message) =>
    Error ? (
      <Error {...errorComponentProps}> {message} </Error>
    ) : (
      <Text variant="mediumText" color="error">
        {' '}
        {message}{' '}
      </Text>
    );

  const above = () => {
    return (
      <div className={classes?.root}>
        <div className={classes?.label} style={{ marginBottom: '3px' }}>
          {' '}
          {label}{' '}
        </div>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => <Component {...field} {...rest} />}
        />
        {error && !hideErrors && showError(error.message)}
      </div>
    );
  };

  const horizontalStart = () => {
    return (
      <>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {' '}
            <div className={classes?.label}> {label} </div>{' '}
          </Grid>
          <Grid item xs>
            {' '}
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => <Component {...field} {...rest} />}
            />{' '}
          </Grid>
        </Grid>
        {error && !hideErrors && showError(error.message)}
      </>
    );
  };

  const horizontalEnd = () => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            {' '}
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => <Component {...field} {...rest} />}
            />{' '}
          </Grid>
          <Grid item xs>
            {' '}
            <div className={classes?.label}> {label} </div>{' '}
          </Grid>
        </Grid>
        {error && !hideErrors && showError(error.message)}
      </>
    );
  };

  const setLabelPlacement = (labelPlacement) => {
    switch (labelPlacement) {
      case 'start':
        return horizontalStart();

      case 'end':
        return horizontalEnd();

      default:
        return above();
    }
  };

  return setLabelPlacement(labelPlacement);
}
