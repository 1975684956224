import api from '~/services/api';

import { Creators as UsersActions, Types } from '.';
import { all, put, call, takeLatest } from 'redux-saga/effects';

export function* postUser ({ user, admin }) {
  const { postUserSuccess, postUserFailure } = UsersActions;
  try {
    if (admin) {
      yield call(api.post, '/api/users', user);
      yield put(postUserSuccess());
    } else {
      yield put(postUserFailure());
    }
  } catch (error) {
    yield put(postUserFailure());
  }
}

export function* getProfile () {
  const { getProfileSuccess, getProfileFailure } = UsersActions;
  try {
    const { data } = yield call(api.get, 'api/me');
    const { category_badges } = data;
    delete data.category_badges;
    yield put(getProfileSuccess(data, category_badges));
  } catch (error) {
    yield put(getProfileFailure());
  }
}

export function* postStatuses (action) {
  const { status, login } = action;
  const { postStatusesSuccess, postStatusesFailure } = UsersActions;
  try {
    const response = yield call(
      api.post,
      `/api/users/${login}/statuses`,
      status
    );
    yield put(postStatusesSuccess(response.data));
  } catch (error) {
    yield put(postStatusesFailure());
  }
}

export function* editProfileAccount (action) {
  const { user, login } = action;
  const { putProfileSuccess, putProfileFailure } = UsersActions;
  try {
    yield call(api.put, `api/users/${login}`, { user });
    yield put(putProfileSuccess());
  } catch (error) {
    yield put(putProfileFailure());
  }
}
export function* editUser (action) {
  const { user, id, successMessage, failedMessage } = action;
  const { putEditUserSuccess, putEditUserFailure } = UsersActions;
  try {
    yield call(api.put, `api/users/${id}`, { user });
    yield successMessage();
    yield put(putEditUserSuccess());
  } catch (error) {
    yield failedMessage();
    yield put(putEditUserFailure());
  }
}

export function* getContacts (action) {
  const { login } = action;
  const { getMyContactsSuccess, getMyContactsFailure } = UsersActions;
  try {
    const response = yield call(api.get, `/api/users/${login}/contacts`);
    yield put(getMyContactsSuccess(response.data));
  } catch (error) {
    yield put(getMyContactsFailure());
  }
}

export function* getConnections (action) {
  const { login, status } = action;
  let url = `/api/users/${login}/connections`;
  if (status) {
    url = `${url}?status=${status}`;
  }
  const { getMyConnectionsSuccess, getMyConnectionsFailure } = UsersActions;
  try {
    const response = yield call(api.get, url);
    yield put(getMyConnectionsSuccess(response.data));
  } catch (error) {
    yield put(getMyConnectionsFailure());
  }
}

export function* getContactKinds (action) {
  const { getContactKindsSuccess, getContactKindsFailure } = UsersActions;

  try {
    const response = yield call(api.get, '/api/kinds');
    yield put(getContactKindsSuccess(response.data));
  } catch (error) {
    yield put(getContactKindsFailure());
  }
}
export function* getMember ({ member_id, failedMessage }) {
  const { getMemberSuccess, getMemberFailure } = UsersActions;
  try {
    const { data } = yield call(api.get, `/api/users/${member_id}`);
    yield put(getMemberSuccess(data));
  } catch (error) {
    yield failedMessage(error, true);
    yield put(getMemberFailure());
  }
}

export default all([
  takeLatest(Types.GET_PROFILE_REQUEST, getProfile),
  takeLatest(Types.POST_STATUSES_REQUEST, postStatuses),
  takeLatest(Types.PUT_PROFILE_REQUEST, editProfileAccount),
  takeLatest(Types.PUT_EDIT_USER_REQUEST, editUser),
  takeLatest(Types.POST_USER_REQUEST, postUser),
  takeLatest(Types.GET_MY_CONTACTS_REQUEST, getContacts),
  takeLatest(Types.GET_MY_CONNECTIONS_REQUEST, getConnections),
  takeLatest(Types.GET_CONTACT_KINDS_REQUEST, getContactKinds),
  takeLatest(Types.GET_MEMBER_REQUEST, getMember)
]);
