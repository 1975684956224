import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex ',
    flexDirection: 'column',
    maxWidth: '100%',
    backgroundColor: '#E1E1E5',
    alignItems: 'center',
    color: theme.palette.textAndBackground.main
  },
  contextualBar: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: '8px 16px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main
  },
  personIconsStyle: {
    height: '24px',
    width: '24px'
  },
  inputPage: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    borderRadius: '8px',
    fontSize: '14px',
    padding: '0px 4px',
    fontWeight: 600,
    color: theme.palette.textAndBackground.main,
    backgroundColor: 'rgba(168, 177, 206, 0.2)'
  },
  documentContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    overflow: 'auto',
    maxHeight: '100%'
  },
  documentContentSpan: {
    maxWidth: '100%',
    // height: 'max-content',
    position: 'relative'
  }
}));
