import React, { useEffect, useState } from 'react';
import Input                          from '~/components/atoms/TextInput';
import Field                          from '~/components/organisms/GenericFormWrapper/Field';
import Text                           from '~/components/atoms/Text';
import ModalContent                   from './ModalContent';

import { Box, Fade, Grid, Modal }     from '@material-ui/core';
import { useSelector }                from 'react-redux';
import { useFormContext }             from 'react-hook-form';

import { useTheme }    from '@material-ui/core/styles';
import { TextPrimary } from '~/utils/forms';
import { useStyles }   from './styles'


export default function VideoID() {
  const classes = useStyles()
  const theme   = useTheme();
  
  const { profile }             = useSelector( state => state.user )
  const { control }             = useFormContext()
  const [open, setOpen]         = useState(false)
  const [username, setUsername] = useState('')
  
  const handleOpen  = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (profile) setUsername(profile.first_name)
  }, [profile])

  return (
    <>
      <div className={classes.field}>
        <Field  name="lectureable.video.videoID"
                label={TextPrimary("ID do Vídeo")} 
                control={ control } 
                Component={Input}
                placeholder="Digite aqui o ID do vídeo..."
                />

      </div>

      <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={handleOpen}>
        <Text color="primary" variant="smallText"><span style={{ fontWeight: 'bold' }}>NOVO!</span> Confira como adicionar vídeos do Vimeo a partir do ID.</Text>
      </a>

      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Grid container justifyContent='center' alignItems='center' style={{ height: "100%" }}>
            <Grid item>

              <Box px={4} py={5} className={classes.modalContent}>
                <ModalContent handleClose={handleClose} username={username} />
              </Box>

            </Grid>
           </Grid>
        </Fade>
      </Modal>
    </>
  )

}
