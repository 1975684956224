import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import PasswordResetForm from '~/components/organisms/PasswordResetForm';
import GridInitialPages from '~/components/templates/GridInitialPages';
import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';
import { handleSuccess } from '~/utils/auxiliaryFunctions';

import * as S from './styles';
import { CircularProgress, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PasswordReset() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams();
  const query = useQuery();
  const login = query.get('login');
  const [unauthenticated, seUnauthenticated] = useState(true);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    check_link_expiration();
  }, []);

  const returnPath = () => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  const check_link_expiration = () => {
    api
      .get(`api/password_reset/${token}?login=${login}`)
      .then(() => {
        seUnauthenticated(false);
      })
      .catch((error) => {
        setExpired(true);

        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
        returnPath();
      });
  };

  function handleSubmit(values) {
    return api
      .post(`api/password_reset`, {
        login: login,
        user: {
          password: values.newPassword,
          password_confirmation: values.newPasswordConfirmation
        }
      })
      .then(() => {
        handleSuccess('Senha redefinida!', enqueueSnackbar, '/', history, 5000);
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
        returnPath();
      });
  }

  return (
    <GridInitialPages
      fontSizeTitle={22}
      title={i18n.t('RecoverPassword.Subtitle')}
      subtitle={i18n.t('RecoverPassword.Title')}
      imageBg={theme.initialPagesImageBgRecover}
      widthLeftImage="58%"
      logoLabel={i18n.t('PasswordReset')}
      form={() => {
        if (unauthenticated && !expired) {
          return (
            <S.WrapperLoading>
              <CircularProgress />
            </S.WrapperLoading>
          );
        } else {
          return (
            <PasswordResetForm
              onSubmit={handleSubmit}
              disabled={unauthenticated}
            />
          );
        }
      }}
    />
  );
}
