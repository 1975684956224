import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as S from './style';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import history from '~/services/history';
import settings from '~settings';

export const ReportMembersCard = ({ reportMembersCardData }) => {
  const [showMemberReport, setShowMemberReport] = useState(false);
  const [memberReportNull, setMemberReportNull] = useState(false);

  const handleRedirectToProfile = (id) => history.push(`/profile/${id}`);

  const checkIfStateIsNull = (reportMembersCardData) => {
    if (reportMembersCardData?.members.length !== 0) {
      setShowMemberReport(true);
      setMemberReportNull(false);
    } else {
      setShowMemberReport(false);
      setMemberReportNull(true);
    }
  };

  const formatDate = (members) => {
    let date = new Date(members).toLocaleString();
    return date;
  };

  useEffect(() => {
    checkIfStateIsNull(reportMembersCardData);
  }, [reportMembersCardData]);

  return (
    <S.Container>
      {showMemberReport && (
        <S.CardsContainer>
          <Text style={{ color: 'black', fontSize: '15px' }}>
            {i18n.t('Recently')}
          </Text>
          {reportMembersCardData &&
            reportMembersCardData?.members.map((member, index) => (
              <Box
                key={member}
                id={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    maxWidth: '100%',
                    padding: '0px 8px'
                  }}
                >
                  <img
                    src={`${settings.baseUrl}${member.thumbnails[0].href}`}
                    style={{
                      marginRight: '2px',
                      borderRadius: '15px',
                      paddingBottom: '3px'
                    }}
                  />
                  <Text
                    onClick={() => handleRedirectToProfile(member.id)}
                    color={'secondary'}
                    style={{
                      marginTop: '-1px',
                      fontSize: '14px',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textWrap: 'nowrap',
                      maxWidth: '100%'
                    }}
                  >
                    {`${member.first_name}` + ' ' + `${member.last_name}`}
                  </Text>
                </Box>
              </Box>
            ))}
        </S.CardsContainer>
      )}
      {memberReportNull && (
        <S.CardsContainer>
          <Text
            style={{
              display: 'flex',
              marginLeft: '25px',
              color: 'rgba(0,0,0,0.3)'
            }}
          >
            {i18n.t('ExerciseNotDoneYet')}
          </Text>
        </S.CardsContainer>
      )}
    </S.Container>
  );
};

export default ReportMembersCard;
