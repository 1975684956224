import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  content: {
    overflowX: 'hidden',
    height: '100vh',
    marginBottom: 100,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5,
    '@media (max-width: 1024px)': {
      marginBottom: 90
    }
  }
});
