import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';
import i18n from '~/I18n';

import { useStyles } from './style';
import { CircularProgress, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getConnectionWithFriend, sendConnectionRequestToFriend } from '~/services/user';
import { remove } from '~/services/connection';

export default function FriendshipConnection() {
  const profile = useSelector((state) => state.user.profile);
  const { id } = useParams();
  const { ButtonConnection } = useStyles();

  const [friendship, setFriendship] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('noFriend');
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(true);

  const [openConfirmUnFollowModal, setOpenConfirmUnFollowModal] = useState(
    false
  );
  const profileFullName = () => {
    return `${profile.first_name} ${profile.last_name}`;
  };

  const handleRequestFriendship = async () => {
    setLoadingButton(true);
    try {
      const connection = await getConnectionWithFriend(profile.id, id);
      setFriendship(connection);
      setConnectionStatus(connection.status);
    } catch (err) {
      setConnectionStatus('noFriend');
    } finally {
      setLoadingButton(false);
    }
  };

  const requestConnection = async () => {
    setLoadingButton(true);
    try {
      const connection = await sendConnectionRequestToFriend(profile.id, id);
      setFriendship(connection);
      setConnectionStatus(connection.status);
    } catch (err) {
      setConnectionStatus('noFriend');
    } finally {
      setLoadingButton(false);
    }
  };

  const deleteConnection = async () => {
    setLoadingButton(true);
    try {
      await remove(friendship.id);
      setFriendship(null);
      setConnectionStatus('noFriend');
    } finally {
      setLoadingButton(false);
      setOpenConfirmUnFollowModal(false);
    }
  };

  const statusMapping = {
    pending: {
      translation: i18n.t('Pending'),
      request: deleteConnection
    },
    accepted: {
      translation: i18n.t('Accepted'),
      request: () => setOpenConfirmUnFollowModal(true)
    },
    requested: {
      translation: i18n.t('Friendship.Requested'),
      request: deleteConnection
    },
    noFriend: {
      translation: i18n.t('Follow'),
      request: requestConnection
    }
  };

  const onHandleConnect = () => {
    statusMapping[connectionStatus].request();
  };

  useEffect(() => {
    setIsLoading(true);
    if (id && profile) {
      handleRequestFriendship();
      setIsLoading(false);
    }
  }, [id, profile]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      ></div>
      {isLoading ? (
        <Skeleton variant="rect" width={'100px'} height={30} />
      ) : (
        <button
          onClick={onHandleConnect}
          disabled={loadingButton}
          className={ButtonConnection}
          style={{
            padding: '0px 10px',
            textTransform: 'capitalize'
          }}
        >
          <Typography style={{ color: 'white', fontSize: 14 }}>
            {loadingButton ? (
              <CircularProgress size={24} />
            ) : (
              statusMapping[connectionStatus].translation
            )}
          </Typography>
        </button>
      )}
      <ConfirmationDialog
        show={openConfirmUnFollowModal}
        handleClose={() => setOpenConfirmUnFollowModal(false)}
        message={`Deseja mesmo desfazer a conexão com ${profileFullName()}`}
        handleConfirm={deleteConnection}
        isLoading={loadingButton}
        confirmButtonText="Desconectar"
        titleText="Desfazer Conexão"
      />
    </>
  );
}
