import React        from 'react';
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text         from '~/components/atoms/Text';

import { Box }      from '@material-ui/core';
import { useTheme } from '@material-ui/core';

import { useStyles } from './styles';

export default function FormButton({ handleClick, text }) {
  const theme   = useTheme()
  const classes = useStyles({ theme });

  return (
    <Box className={classes.buttonContainer}>
      <ButtonSquare
        backgroundColor={theme.palette.secondary.main}
        onClick={handleClick}
        width={299}
        height={56}
        marginBottom={10}
      >
        <Text variant="subtitle" color="white">
          {text}
        </Text>
      </ButtonSquare>
    </Box>
  );
}
