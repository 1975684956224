import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    padding: '24px 32px',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: 40,
    '@media (max-width: 800px)': {
      boxShadow: 'none',
      padding: 0
    }
  },
  imgData: {
    fontSize: '14px',
    '& img': {
      width: '350px',
      height: '200px'
    }
  },
  statement: {
    '& p': {
      marginTop: 24,
      marginBottom: 10
    }
  },
});
