import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '16px',
    padding: '20px 0px',
    width: '100%'
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center'
  },
  description: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center'
  }
}));
