import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 48px 0 48px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 'min-content',
    boxShadow: '0px 0px 24px 0px rgba(103, 109, 130, 0.12)',
    gap: '64px',
    [theme.breakpoints.down('sm')]: {
      gap: '24px',
      padding: '32px 40px 0 40px'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '64px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '16px'
    }
  },
  boxLogo: {
    display: 'flex',
    alignItems: 'center',
    gap: '64px',
    [theme.breakpoints.down('sm')]: {
      gap: '24px'
    }
  },
  boxCustomLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    [theme.breakpoints.down('sm')]: {
      gap: '8px'
    }
  },
  logo: {
    width: '180px',
    [theme.breakpoints.down('md')]: {
      width: '150px'
    }
  },
  customLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.textActiveElement.main,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  customLinkText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  customMobileLink: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.textActiveElement.main}`,
    padding: '6px 10px',
    gap: '16px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));
