import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: 20,
    width: '100%'
  },
  icon: {
    width: 53,
    height: 53,
    marginRight: 20,
    color: theme.palette.textActiveElement.main
  },
  wrap: {
    '@media (max-width: 600px)': {
      flexWrap: 'wrap'
    }
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  notification: {
    marginBottom: 20
  }
}));
