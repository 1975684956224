import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function TourModalDefault({ text }) {
  const classes = useStyles();
  const isMobile = useMediaQuery('@media (max-width: 600px)');

  return (
    <div className={classes.content}>
      <Text variant={isMobile ? 'text' : 'lg'} color="primary">
        {text}
      </Text>
    </div>
  );
}

TourModalDefault.propTypes = {
  text: PropTypes.string,
};

TourModalDefault.defaultProps = {
  text: '',
};
