import api from '~/services/api';

import { Creators as MyWallActions, Types } from '.';
import { all, put, call, takeLatest } from 'redux-saga/effects';

export function* getStatuses (action) {
  const { userId, page } = action;
  const { getMyStatusesSuccess, getMyStatusesFailure } = MyWallActions;
  try {
    const { data } = yield call(api.get, `/api/users/${userId}/my_wall`, {
      params: {
        page
      }
    });
    yield put(getMyStatusesSuccess(data));
  } catch (error) {
    yield put(getMyStatusesFailure());
  }
}

export function* postStatuses (action) {
  const { status, login } = action;
  const { postMyStatusesSuccess, postMyStatusesFailure } = MyWallActions;
  try {
    const { data } = yield call(
      api.post,
      `/api/users/${login}/statuses`,
      status
    );
    yield put(postMyStatusesSuccess(data));
  } catch (error) {
    yield put(postMyStatusesFailure());
  }
}

export default all([
  takeLatest(Types.GET_MY_STATUSES_REQUEST, getStatuses),
  takeLatest(Types.POST_MY_STATUSES_REQUEST, postStatuses)
]);
