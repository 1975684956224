import { Link } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const EditUserButton = styled(Button)`
  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;
  border-radius: 1.2rem;
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
`;

export const WrapperEditUserButton = styled(Link)`
  background-color: cyan;
`;

export const MemberWrapper = styled(Box)`
  ${({ bgColor }) => css`
    display: flex;
    margin-bottom: 1.6rem;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    margin: 0.1rem;
    border-radius: 0.4rem;
    overflow: hidden;
    min-height: 76px;
    &:hover {
      background: ${bgColor};
      & p {
        color: white !important;
      }
    }
  `}
`;

export const AvatarWrapper = styled(Box)`
  margin-right: 0.4rem;
`;

export const WrapperUserData = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
  max-width: 100%;
`;

export const LinkProfile = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
`;
