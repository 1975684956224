import { makeStyles, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

// Inspired by the former Facebook spinners.
export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: '#D4D4D9'
  },
  top: {
    color: theme?.palette.primary.main,
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));
