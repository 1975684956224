export default {
  versionName: 'AAA',
  versionNumber: '0.0.1',
  name: 'ADAM - Ambiente Digital de Aprendizagem Master',
  client: 'masteradams',
  websocketSecret: 'OUBtCXoayoc2uptN5tvuCCJvej',
  websocketKey: 'DEu3vO#gPJGMYT@US@YxWNJFAzLUy^BUZLAdu8Bxa6DFrLWR',
  appstore: 'https://apps.apple.com/br/app/adams-master/id1644104587',
  playstore:
    'https://play.google.com/store/apps/details?id=com.viitra.masteradam',
  googleAuthClientId:
    '590034191650-rmrfddb4s0bjpij8nd40p78ljo7midqj.apps.googleusercontent.com',
  googleAuthScopes: [
    'https://www.googleapis.com/auth/calendar.app.created'
  ].join(' '),
  helpCenterUrl: 'https://https://ajuda.redu.digital'
};
