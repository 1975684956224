/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import MemberItem from './MemberItem';
import { useStyles } from './styles';
import { Box, Checkbox, useTheme, Button, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Alert, Skeleton } from '@material-ui/lab';
import debounce from 'debounce';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const errorFetchMembersListMessagens = {
  search: {
    initialLoad:
      'Nenhum usuário encontrado. Por favor, ajuste os critérios de busca ou tente novamente mais tarde.',
    loadingMore: 'Não foram encontrados mais resoltados para essa busca.'
  },
  listing: {
    initialLoad:
      'Houve um problema ao tentar exibir a lista de membros. Por favor, recarregue a página ou tente novamente mais tarde.',
    loadingMore:
      'Não foi possível carregar mais membros da lista devido a um erro. Por favor, tente novamente mais tarde para continuar explorando a lista.'
  }
};

const Members = ({
  structureID,
  getEnrollments,
  updateRole,
  searchMembers,
  canManage
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme: theme });

  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [rolesSelected, setRolesSelected] = useState([
    'member',
    'tutor',
    'teacher',
    'environment_admin'
  ]);
  const [searchString, setSearchString] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const membersPerPage = 18;
  const [desableInputSearch, setDisableInputSearch] = useState(false);

  const isMounted = useRef(false);
  const inputRef = useRef(null);
  const resetMembersList = true;

  const shimmerList = Array(8).fill(null);
  const handleGetUsers = async () => {
    if (isFetchingUsers || !hasMoreUsers) return;

    setIsFetchingUsers(true);
    getEnrollments(structureID, page + 1, membersPerPage)
      .then((usersFetched) => {
        setUsers((prev) => [...prev, ...usersFetched]);
        setIsFetchingUsers(false);
        setPage((prev) => prev + 1);
        setHasMoreUsers(usersFetched.length >= 10);
      })
      .catch(() => {
        setIsFetchingUsers(false);
      });
  };

  useEffect(() => {
    setIsFetchingUsers(true);
    getEnrollments(structureID)
      .then((usersFetched) => {
        setUsers(usersFetched);
        setIsFetchingUsers(false);
        setHasMoreUsers(usersFetched.length >= 10);
      })
      .catch(() => {
        setIsFetchingUsers(false);
      });
  }, []);

  const renderCheckbox = (label) => {
    const roles = {
      member: i18n.t('Student'),
      tutor: i18n.t('Tutor'),
      teacher: i18n.t('Teacher'),
      environment_admin: i18n.t('Admin')
    };
    return (
      <Box className={classes.checkboxElement}>
        <Checkbox
          checked={rolesSelected.includes(label)}
          onChange={() => {
            if (rolesSelected.includes(label)) {
              setRolesSelected((prev) => prev.filter((e) => e != label));
            } else {
              setRolesSelected((prev) => [...prev, label]);
            }
          }}
          classes={{ root: classes.checkbox }}
        />
        <Text variant="text" color="gray">
          {roles[label]}
        </Text>
      </Box>
    );
  };

  const handleClose = () => {
    setError(false);
  };

  const getMoreItems = (e) => {
    if (isFetchingUsers || !hasMoreUsers) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 100;

    if (bottom) {
      setIsFetchingUsers(true);
      handleGetUsers();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleSearchInput = (event) => {
    const searchValue = event.target.value;
    setSearchString(searchValue);
    setPage(1);
  };

  useEffect(() => {
    if (debouncedSearchMembers) debouncedSearchMembers.clear();
    debouncedSearchMembers();
    return () => {
      if (debouncedSearchMembers) debouncedSearchMembers.clear();
    };
  }, [searchString]);

  const debouncedSearchMembers = debounce(async () => {
    if (isMounted.current) {
      setDisableInputSearch(true);
      await loadMembersList(resetMembersList);
      setDisableInputSearch(false);
      if (inputRef.current) inputRef.current.focus();
    } else {
      isMounted.current = true;
    }
  }, 1000);

  const loadMembersList = async (resetListMembers = false) => {
    if (!(hasMoreUsers || resetListMembers)) return;

    const requestPage = resetListMembers ? 1 : page;
    setUsers((prev) => (resetListMembers ? [] : prev));
    if (searchString.length > 0) {
      await getListOfSearchedStructureMembers(requestPage, resetListMembers);
    } else {
      setIsFetchingUsers(true);
      getEnrollments(structureID)
        .then((usersFetched) => {
          setUsers(usersFetched);
          setIsFetchingUsers(false);
          setHasMoreUsers(usersFetched.length >= 10);
        })
        .catch(() => {
          setIsFetchingUsers(false);
        });
    }
  };

  const getListOfSearchedStructureMembers = async (
    nextRequestPage,
    resetListMembers
  ) => {
    setIsFetchingUsers(true);
    try {
      const loadedMembers = await searchMembers(
        structureID,
        searchString,
        nextRequestPage,
        membersPerPage
      );
      handleSuccessFetchMembersList(loadedMembers, resetListMembers);
    } catch (e) {
      handleFailFetchMembersList(
        errorFetchMembersListMessagens.search,
        resetListMembers
      );
    }
    setIsFetchingUsers(false);
  };

  const handleSuccessFetchMembersList = (loadedMembers, reset) => {
    const checkHasMoreMembers = loadedMembers.length >= membersPerPage;
    setHasMoreUsers(checkHasMoreMembers);
    if (reset) {
      setUsers([...loadedMembers]);
      setPage(2);
    } else {
      setUsers((prev) => [...prev, ...loadedMembers]);
      setPage((prev) => (checkHasMoreMembers ? prev + 1 : prev));
    }
  };

  const handleFailFetchMembersList = (message, reset) => {
    if (reset) {
      enqueueSnackbar(message.initialLoad, { variant: 'error' });
      setUsers([]);
      setHasMoreUsers(false);
    } else {
      enqueueSnackbar(message.loadingMore, { variant: 'error' });
      setHasMoreUsers(false);
    }
  };

  return (
    <Box className={classes.root}>
      {error && (
        <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
            style={{ fontSize: 16 }}
          >
            Ocorreu um erro! Por favor tente mais tarde ou entre em contato com
            o administrador do sistema!
          </Alert>
        </Snackbar>
      )}

      <form className={classes.searchForm} onSubmit={handleSubmit}>
        <Box className={classes.searchBarContainer}>
          <input
            className={classes.searchBar}
            placeholder={i18n.t('searchMembroPlaceholder')}
            inputRef={inputRef}
            disabled={desableInputSearch}
            onChange={handleSearchInput}
          />
          <Button className={classes.searchIcon}>
            <SearchIcon />
          </Button>
        </Box>
        <Box className={classes.checkboxContainer}>
          {renderCheckbox('member')}
          {renderCheckbox('tutor')}
          {renderCheckbox('teacher')}
          {renderCheckbox('environment_admin')}
        </Box>
      </form>

      <Box className={classes.membersContainer}>
        <Box className={classes.membersGrid} onScroll={getMoreItems}>
          {users
            .filter((e) => rolesSelected.includes(e.role))
            .map((member) => (
              <MemberItem
                key={member.id}
                member={member}
                updateRole={updateRole}
                setMembers={setUsers}
                canManage={canManage}
              />
            ))}

          {isFetchingUsers &&
            shimmerList.map((item, index) => (
              <Box key={index} className={classes.memberContainer}>
                <Box className={classes.memberAvatar}>
                  <Skeleton variant="circle" width={50} height={50} />
                </Box>
                <Box className={classes.memberInfo}>
                  <Skeleton
                    variant="rect"
                    height={14}
                    width="70%"
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton
                    variant="rect"
                    height={12}
                    width="40%"
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton variant="rect" height={12} width="55%" />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Members;

Members.propTypes = {
  members: PropTypes.shape(),
  courseId: PropTypes.shape(),
  environmentId: PropTypes.shape(),
  spacesId: PropTypes.shape()
};

Members.defaultProps = {
  members: null,
  courseId: null,
  environmentId: null,
  spacesId: null
};
