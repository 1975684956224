import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0px',
    padding: '0px'
  },
  accordionRoot: {
    '&:before': {
      display: 'none'
    }
  },
  accordionSummaryText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  accordionSummaryIcon: {
    fontSize: '24px'
  },
  accordionDetailsLoadingBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '64px'
  },
  emptyLecturesListText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    textAlign: 'center'
  }
}));
