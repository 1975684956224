import React from 'react';
import Text  from '~/components/atoms/Text';

import { Button } from '@material-ui/core';

export default function FirstIntegration({ authorizationLink }) {

  return (
    <a href={authorizationLink} target="_blank" disabled>
      <Button variant="outlined" color="primary">
        <Text style={{ padding: 0, margin: 0}} color="primary"> Gerar autorização para o Redu </Text>
      </Button>
    </a>
  );
}