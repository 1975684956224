import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  mainForm: {
    marginBottom: '30px',
    paddingBottom: '40px',
    borderBottom: '1px solid rgba(0,0,0,0.15)'
  },
  subsection: {
    marginTop: 40,
    marginBottom: 40
  },
  subtitleContainer: {
    marginTop: 5,
    marginBottom: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: 20,
    width: '100%'
  },

  deleteBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10
  },
  deleteIcon: {
    color: (props) => props.theme.palette.textActiveElement.main,
    width: 20,
    height: 20,
    transition: '.2s ease-in-out',
    '&:hover': {
      color: (props) => props.theme.palette.error.main
    },
    checkboxIcon: {
      fontSize: 21
    },
    checkboxLabel: {
      fontSize: 12
    }
  }
});
