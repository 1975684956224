import React from 'react';

import Text from '../Text';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';


export default function MessageBubble({ text, sender }) {
  const theme = useTheme();
  const classes = useStyles({ isMine: sender, theme });

  return (
    <Box className={classes.container}>
      <Text
        style={{ maxWidth: '100%', wordWrap: 'break-word' }}
        variant="text"
        color={sender ? 'white' : 'black'}
      >
        {text}
      </Text>
    </Box>
  );
}

MessageBubble.propTypes = {
  text: PropTypes.string,
  sender: PropTypes.bool
};

MessageBubble.defaultProps = {
  text: '',
  sender: true
};
