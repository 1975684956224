import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    width: '100%',
    height: 50,
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 100,
    '& button': {
      margin: '0 15px',
      '@media (max-width: 900px)': {
        margin: '0 5px'
      }
    }
  },
  leftButton: {
    float: 'left',
    marginRight: (props) => props.theme.spacing(2)
  },

  rightButtons: {
    float: 'center',
    '& > button': {
      marginLeft: (props) => props.theme.spacing(2)
    }
  },
  centerRightButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  arrowBack: {
    color: (props) => props.theme.palette.secondary.main,
    width: 16,
    height: 16,
    margin: '0 5px'
  },
  submitIcon: {
    color: 'white',
    width: 16,
    height: 16,
    margin: '0 5px'
  },
  loader: {
    color: (props) => props.theme.palette.secondary.main
  }
});
