import api from "~/services/api"

export const deleteLecture = async lectureID => {
  return await api.delete(`api/lectures/${lectureID}`)
}

export const getSpace = async spaceID => {
  return await api.get(`api/spaces/${spaceID}`)
}

export const getCourse = async courseID => {
  return await api.get(`api/courses/${courseID}`)
};