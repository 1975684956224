import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import Text from '../Text';

import { useStyles } from './styles';

function CircularProgressBar({ value, size = 40, ...props }) {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      title={`${value}% concluído.`}
    >
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={size}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          size={size}
          value={value}
          thickness={4}
          {...props}
        />
      </div>
      {/* <CircularProgress variant="determinate" value={value} {...rest} /> */}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text variant="mediumText" component="div" color="text">{`${Math.round(
          value
        )}%`}</Text>
      </Box>
    </Box>
  );
}

export default CircularProgressBar;
