import React     from 'react';
import Error     from '../Error';
import FileName  from '../FileName';
import SizeError from '../SizeError';

import { useStyles } from './styles';

export default function Mobile({ fileTypeError, sizeError, selectedFile, Input }) {
  const classes = useStyles()
  
  return (
    <div className={classes.root}>
      <Input/>
      { selectedFile  && <FileName name={ selectedFile.name } />}
      { fileTypeError && <Error/> }
      { sizeError     && <SizeError/> }
    </div>
  );
}
