import React from 'react';

import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MoreVert,
  Add,
  NavigateNext,
  NavigateBefore,
  MoreHoriz,
  Delete,
  ArrowUpward
} from '@material-ui/icons';
export const ChatIcon = () => (
  <svg width={20} height={17.56} viewBox="0 0 20 17.56">
    <defs>
      <style>{'.chat{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(0 -6.13)">
      <path
        className="chat"
        d="M17.03,6.13H2.97A2.97,2.97,0,0,0,0,9.1v7.824A2.97,2.97,0,0,0,2.97,19.9H4v2.6c0,1.234.742,1.558,1.648.72L9.236,19.9h7.8A2.97,2.97,0,0,0,20,16.928V9.1A2.97,2.97,0,0,0,17.03,6.13ZM5.944,14.346a1.15,1.15,0,1,1,1.142-1.134,1.15,1.15,0,0,1-1.142,1.134Zm4.044,0a1.15,1.15,0,1,1,1.16-1.144,1.162,1.162,0,0,1-1.16,1.146Zm4.068,0a1.145,1.145,0,1,1,.813-.339,1.15,1.15,0,0,1-.813.339Z"
      />
    </g>
  </svg>
);

export const GearIcon = () => (
  <svg width={19.81} height={19.81} viewBox="0 0 19.81 19.81">
    <defs>
      <style>{'.gear{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(-6.1 -7.1)">
      <g transform="translate(6.1 7.1)">
        <path
          className="gear"
          d="M26.332,15.005l-2.681-.37q-.116-.324-.254-.624l1.618-2.15a.693.693,0,0,0-.069-.9L23.073,9.088a.693.693,0,0,0-.9-.069l-2.15,1.618-.624-.254L19.027,7.7a.693.693,0,0,0-.693-.6H15.7a.693.693,0,0,0-.693.6l-.37,2.681-.624.254-2.15-1.618a.693.693,0,0,0-.9.069L9.088,10.96a.693.693,0,0,0-.069.9l1.618,2.15q-.139.3-.254.624l-2.681.37a.693.693,0,0,0-.6.693v2.635a.693.693,0,0,0,.6.693l2.658.37q.116.324.254.624L9,22.171a.693.693,0,0,0,.069.9l1.872,1.872a.693.693,0,0,0,.9.069l2.15-1.618.624.254.37,2.658a.693.693,0,0,0,.693.6h2.658a.693.693,0,0,0,.693-.6l.37-2.658.624-.254,2.15,1.618a.693.693,0,0,0,.9-.069l1.872-1.872a.693.693,0,0,0,.069-.9L23.4,20.021q.139-.3.254-.624l2.658-.37a.693.693,0,0,0,.6-.693V15.7A.693.693,0,0,0,26.332,15.005ZM17.016,20.9A3.883,3.883,0,1,1,20.9,17.016,3.883,3.883,0,0,1,17.016,20.9Z"
          transform="translate(-7.1 -7.1)"
        />
      </g>
    </g>
  </svg>
);

export const ContrastIcon = ({ color }) => (
  <svg width={25.871} height={25.871} viewBox="0 0 25.871 25.871">
    <defs>
      <style>{`.contrast{fill:${color};fill-rule:evenodd;}`}</style>
    </defs>
    <g transform="translate(-83.818 -88.971)">
      <g transform="translate(83.818 88.971)">
        <path
          className="contrast"
          d="M20.92,31.91V9.961a10.974,10.974,0,0,0,0,21.949ZM20.92,8A12.936,12.936,0,1,1,8,20.92,12.935,12.935,0,0,1,20.92,8Zm0,1.525A11.41,11.41,0,1,1,9.525,20.92,11.407,11.407,0,0,1,20.92,9.525Z"
          transform="translate(-8 -8)"
        />
      </g>
    </g>
  </svg>
);

export const DocsIcon = () => (
  <svg width={20} height={20} viewBox="0 0 16.744 20.926">
    <defs>
      <style>{'.docs{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(5 -147)">
      <g transform="translate(-5 147)">
        <path
          className="docs"
          d="M4.594,162.044a1.234,1.234,0,0,1,1.235-1.235H9.191V147.6a.6.6,0,0,0-.6-.6H-4.4a.606.606,0,0,0-.6.6v17.2a.6.6,0,0,0,.6.6H4.594ZM-2.76,149.273H6.947a.318.318,0,0,1,0,.636H-2.76a.314.314,0,0,1-.314-.318A.314.314,0,0,1-2.76,149.273Zm0,2.206H6.947a.318.318,0,0,1,0,.636H-2.76a.314.314,0,0,1-.314-.318A.312.312,0,0,1-2.76,151.479Zm0,2.2H6.947a.318.318,0,0,1,0,.636H-2.76A.314.314,0,0,1-3.074,154,.314.314,0,0,1-2.76,153.681Zm0,2.206H6.947a.318.318,0,0,1,0,.636H-2.76a.314.314,0,0,1-.314-.318A.314.314,0,0,1-2.76,155.887Zm0,2.206H6.947a.318.318,0,0,1,0,.636H-2.76a.314.314,0,0,1-.314-.318A.314.314,0,0,1-2.76,158.093Zm5.2,5.044h-5.2a.314.314,0,0,1-.314-.318.317.317,0,0,1,.314-.318h5.2a.318.318,0,0,1,0,.636Zm0-2.206h-5.2a.314.314,0,0,1-.314-.318.317.317,0,0,1,.314-.318h5.2a.318.318,0,0,1,0,.636Z"
          transform="translate(5 -147)"
        />
        <path
          className="docs"
          d="M239.3,492.6v2.918l3.516-3.52H239.9A.6.6,0,0,0,239.3,492.6Z"
          transform="translate(-229.074 -477.557)"
        />
        <path
          className="docs"
          d="M69.684,207.2H68.361v11.6c0,.021,0,.042,0,.063h0a.239.239,0,0,1-.017.059h0a.225.225,0,0,1-.029.054h0a.444.444,0,0,1-.038.046l-4.6,4.609a.131.131,0,0,1-.046.038h0a.226.226,0,0,1-.054.029h0a.241.241,0,0,1-.059.017h0a.317.317,0,0,1-.063,0H56.1V225a.6.6,0,0,0,.6.6H69.684a.606.606,0,0,0,.6-.6v-17.2A.607.607,0,0,0,69.684,207.2Z"
          transform="translate(-53.542 -204.68)"
        />
      </g>
    </g>
  </svg>
);

export const FlagIcon = () => (
  <svg width={21.73} height={25.899} viewBox="0 0 21.73 25.899">
    <defs>
      <style>{'.a{fill:#707070;}'}</style>
    </defs>
    <g transform="translate(-12.59 -5.414)">
      <g transform="translate(12.59 5.414)">
        <path
          className="a"
          d="M34.253,8.046a.791.791,0,0,0-.724-.472H25.6a2.945,2.945,0,0,0-2.833-2.159H13.381a.791.791,0,0,0-.791.791V30.522a.791.791,0,0,0,1.582,0V16.739h6.794v.814A2.348,2.348,0,0,0,23.312,19.9H33.529a.791.791,0,0,0,.584-1.325l-4.426-4.837L34.112,8.9A.791.791,0,0,0,34.253,8.046Z"
          transform="translate(-12.59 -5.414)"
        />
      </g>
    </g>
  </svg>
);

export const UserIcon = () => (
  <svg width={20} height={20} viewBox="0 0 15.067 20">
    <defs>
      <style>{'.a{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(-27.4 -20)">
      <path
        className="a"
        d="M30.333,24.6a4.6,4.6,0,1,1,4.6,4.567A4.609,4.609,0,0,1,30.333,24.6ZM42.467,37.9a7.54,7.54,0,0,0-12.733-5.3A7.483,7.483,0,0,0,27.4,38.067,1.963,1.963,0,0,0,29.367,40H40.5a1.965,1.965,0,0,0,1.4-.567,1.945,1.945,0,0,0,.567-1.367h0Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);

export const PhoneIcon = () => (
  <svg width={20.022} height={20.022} viewBox="0 0 20.022 20.022">
    <defs>
      <style>{'.phone{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(0.022)">
      <path
        className="phone"
        d="M13,2a4.951,4.951,0,0,1,5,5,1,1,0,0,0,2,0,6.957,6.957,0,0,0-7-7,.945.945,0,0,0-1,1A.945.945,0,0,0,13,2Z"
      />
      <path
        className="phone"
        d="M13,6a.945.945,0,0,1,1,1,1,1,0,0,0,2,0,2.946,2.946,0,0,0-3-3,.945.945,0,0,0-1,1A.945.945,0,0,0,13,6Z"
      />
      <path
        className="phone"
        d="M19.6,15.9l-4.3-4.3a1.15,1.15,0,0,0-1.7,0L11.2,14c-.6.6-2.2-.1-3.7-1.5S5.4,9.4,6,8.8L8.4,6.4a1.15,1.15,0,0,0,0-1.7L4.1.4A1.15,1.15,0,0,0,2.4.4L.7,2c-2,2,.3,7.4,5.1,12.2S16,21.3,18,19.3l1.6-1.6A1.267,1.267,0,0,0,19.6,15.9Z"
      />
    </g>
  </svg>
);

export const PinIcon = () => (
  <svg width={21.73} height={25.899} viewBox="0 0 21.73 25.899">
    <defs>
      <style>{'.a{fill:#707070;}'}</style>
    </defs>
    <g transform="translate(-12.59 -5.414)">
      <g transform="translate(12.59 5.414)">
        <path
          className="a"
          d="M34.253,8.046a.791.791,0,0,0-.724-.472H25.6a2.945,2.945,0,0,0-2.833-2.159H13.381a.791.791,0,0,0-.791.791V30.522a.791.791,0,0,0,1.582,0V16.739h6.794v.814A2.348,2.348,0,0,0,23.312,19.9H33.529a.791.791,0,0,0,.584-1.325l-4.426-4.837L34.112,8.9A.791.791,0,0,0,34.253,8.046Z"
          transform="translate(-12.59 -5.414)"
        />
      </g>
    </g>
  </svg>
);

export const SearchMenuIcon = ({ color, ...rest }) => (
  <svg width="auto" height={28} viewBox="0 0 31.609 27.631">
    <defs>
      <style>{`.searchmenu{fill:${color};}`}</style>
    </defs>
    <g transform="translate(9957 1008.069)">
      <path
        className="searchmenu"
        d="M30.375,28.562H1.125A1.126,1.126,0,0,1,0,27.437V24.625A1.126,1.126,0,0,1,1.125,23.5H25.219l2.456,2.41a2.536,2.536,0,0,0,1.789.777,2.819,2.819,0,0,0,2.036-.939v1.689A1.126,1.126,0,0,1,30.375,28.562ZM9.3,17.312H1.125A1.126,1.126,0,0,1,0,16.187V13.375A1.126,1.126,0,0,1,1.125,12.25H7.183A10.741,10.741,0,0,0,9.3,17.311ZM8.17,6.062H1.125A1.126,1.126,0,0,1,0,4.937V2.125A1.126,1.126,0,0,1,1.125,1H13.346A10.841,10.841,0,0,0,8.171,6.062Z"
        transform="translate(-9957 -1009)"
      />
      <g transform="translate(-9949 -1008.069)">
        <path
          className="searchmenu"
          d="M21.463,28.442a9.846,9.846,0,1,1,9.844-9.847A9.858,9.858,0,0,1,21.463,28.442Zm0-15.527a5.681,5.681,0,1,0,5.679,5.681A5.688,5.688,0,0,0,21.463,12.914Z"
          transform="translate(-11.616 -8.749)"
        />
        <path
          className="searchmenu"
          d="M53.909,59.54,60.927,66.4c1.921,1.878,4.868-1.065,2.945-2.945l-7.018-6.864c-1.921-1.878-4.868,1.065-2.945,2.945Z"
          transform="translate(-40.888 -41.938)"
        />
      </g>
    </g>
  </svg>
);

export const UserPlusIcon = () => (
  <svg width={35.468} height={27.792} viewBox="0 0 35.468 27.792">
    <defs>
      <style>{'.a{fill:#449dfc;}'}</style>
    </defs>
    <g transform="translate(-875.578 -977.955)">
      <g transform="translate(848.178 958.254)">
        <path
          className="a"
          d="M31.432,26.323A6.323,6.323,0,1,1,37.756,32.6,6.336,6.336,0,0,1,31.432,26.323ZM48.111,44.606a10.364,10.364,0,0,0-17.5-7.286A10.286,10.286,0,0,0,27.4,44.835a2.7,2.7,0,0,0,2.7,2.658h15.3a2.7,2.7,0,0,0,1.924-.779,2.673,2.673,0,0,0,.779-1.879h0Z"
          transform="translate(0)"
        />
      </g>
      <g transform="translate(1075.955 759.955)">
        <g transform="translate(-179 218)">
          <path
            className="a"
            d="M46.091,13.045a1.767,1.767,0,0,1-1.761,1.761H40.807v3.523a1.761,1.761,0,0,1-3.523,0V14.807H33.761a1.761,1.761,0,0,1,0-3.523h3.523V7.761a1.761,1.761,0,0,1,3.523,0v3.523h3.523A1.767,1.767,0,0,1,46.091,13.045Z"
            transform="translate(-32 -6)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UserCheckedIcon = () => (
  <svg width={34.258} height={27.493} viewBox="0 0 34.258 27.493">
    <defs>
      <style>{'.a{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(-860.578 -939.254)">
      <g transform="translate(833.178 919.254)">
        <path
          className="a"
          d="M31.432,26.323A6.323,6.323,0,1,1,37.756,32.6,6.336,6.336,0,0,1,31.432,26.323ZM48.111,44.606a10.364,10.364,0,0,0-17.5-7.286A10.286,10.286,0,0,0,27.4,44.835a2.7,2.7,0,0,0,2.7,2.658h15.3a2.7,2.7,0,0,0,1.924-.779,2.673,2.673,0,0,0,.779-1.879h0Z"
          transform="translate(0)"
        />
      </g>
      <g transform="translate(852 906.524)">
        <g transform="translate(28.97 33.969)">
          <path
            className="a"
            d="M41.138,986.332a1.648,1.648,0,0,0-1.133.495l-6.212,6.212-2.143-1.71a1.649,1.649,0,0,0-2.06,2.576l3.3,2.637a1.649,1.649,0,0,0,2.194-.124l7.253-7.253a1.648,1.648,0,0,0-1.2-2.833Z"
            transform="translate(-28.97 -986.331)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UserClockIcon = () => (
  <svg width={36.108} height={28.747} viewBox="0 0 36.108 28.747">
    <defs>
      <style>{'.a{fill:#fff;}'}</style>
    </defs>
    <g transform="translate(-860.578 -938)">
      <g transform="translate(833.178 919.254)">
        <path
          className="a"
          d="M31.432,26.323A6.323,6.323,0,1,1,37.756,32.6,6.336,6.336,0,0,1,31.432,26.323ZM48.111,44.606a10.364,10.364,0,0,0-17.5-7.286A10.286,10.286,0,0,0,27.4,44.835a2.7,2.7,0,0,0,2.7,2.658h15.3a2.7,2.7,0,0,0,1.924-.779,2.673,2.673,0,0,0,.779-1.879h0Z"
          transform="translate(0)"
        />
      </g>
      <g transform="translate(879.898 853)">
        <g transform="translate(0.937 85)">
          <path
            className="a"
            d="M92.925,85a7.955,7.955,0,1,1-5.6,2.33A7.8,7.8,0,0,1,92.925,85Zm4.526,3.4a6.409,6.409,0,1,0,1.872,4.526A6.347,6.347,0,0,0,97.451,88.4Z"
            transform="translate(-85 -85)"
          />
          <path
            className="a"
            d="M460.038,190.786a.965.965,0,0,1-.038-.248v-4.774a.764.764,0,1,1,1.528,0V189.7l1.337-.764a.745.745,0,0,1,1.031.267.772.772,0,0,1-.267,1.05l-2.483,1.432a.777.777,0,0,1-1.05-.286A.854.854,0,0,1,460.038,190.786Z"
            transform="translate(-452.839 -183.09)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const UserUncheckedIcon = () => (
  <svg width={34.258} height={27.493} viewBox="0 0 34.258 27.493">
    <defs>
      <style>{'.a{fill:#fff;}.b{fill:rgba(255,255,255,0);}'}</style>
    </defs>
    <g transform="translate(-860.578 -939.254)">
      <g transform="translate(833.178 919.254)">
        <path
          className="a"
          d="M31.432,26.323A6.323,6.323,0,1,1,37.756,32.6,6.336,6.336,0,0,1,31.432,26.323ZM48.111,44.606a10.364,10.364,0,0,0-17.5-7.286A10.286,10.286,0,0,0,27.4,44.835a2.7,2.7,0,0,0,2.7,2.658h15.3a2.7,2.7,0,0,0,1.924-.779,2.673,2.673,0,0,0,.779-1.879h0Z"
          transform="translate(0)"
        />
      </g>
      <g transform="translate(852 906.524)">
        <g transform="translate(28.97 33.969)">
          <path
            className="b"
            d="M41.138,986.332a1.648,1.648,0,0,0-1.133.495l-6.212,6.212-2.143-1.71a1.649,1.649,0,0,0-2.06,2.576l3.3,2.637a1.649,1.649,0,0,0,2.194-.124l7.253-7.253a1.648,1.648,0,0,0-1.2-2.833Z"
            transform="translate(-28.97 -986.331)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const EnvironmentIcon = ({ width, height, color, style }) => (
  <svg viewBox="0 0 134.956 127.022" width={width} height={height} fill={color} style={{...style}}>
    <defs />
    <g transform="translate(-142.386 -43.204)">
      <circle
        className="a"
        cx={18.549}
        cy={18.549}
        r={18.549}
        transform="translate(191.315 43.204)"
      />
      <circle
        className="a"
        cx={18.549}
        cy={18.549}
        r={18.549}
        transform="translate(142.386 88.166)"
      />
      <circle
        className="a"
        cx={18.549}
        cy={18.549}
        r={18.549}
        transform="translate(240.244 88.166)"
      />
      <circle
        className="a"
        cx={18.549}
        cy={18.549}
        r={18.549}
        transform="translate(191.315 133.128)"
      />
    </g>
  </svg>
);

// eslint-disable-next-line react/prop-types
export const SettingsIcon = ({ className }) => (
  <MoreVert className={className} />
);

export const AddIcon = ({ className }) => <Add className={className} />;

export const MessageIcon = ({ width }) => (
  <FontAwesomeIcon
    icon={faCommentDots}
    color="white"
    style={{ fontSize: width }}
  />
);

export const NextLectureIcon = ({ className }) => (
  <NavigateNext className={className} />
);
export const PreviousLectureIcon = ({ className }) => (
  <NavigateBefore className={className} />
);

export const SpreadIcon = ({ className }) => (
  <MoreHoriz className={className} />
);

export const DeleteIcon = ({ className }) => <Delete className={className} />;

export const ArrowUpwardIcon = ({ className }) => (
  <ArrowUpward className={className} />
);
