import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import FormFilter from '~/components/FormFilter';
import OrderByPopover from '~/components/OrderByPopover';
import i18n from '~/I18n';
import { getLectures } from '~/services/Space';
import { getLectures as getSubjectLectures } from '~/services/Subject';

import { ContentProvider } from '../contexts/ContentContext';
import { useSpacePageContext } from '../contexts/SpacePageContext';
import Lecture from './Lecture';
import LectureShimmer from './LectureShimmer';
import { useStyles } from './styles';
import SubjectsSelect from './SubjectsSelect';
import { useTheme, Box } from '@material-ui/core';

export default function Content() {
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: ''
    }
  });
  const theme = useTheme();
  const classes = useStyles({ theme: theme });
  const { space } = useSpacePageContext();
  const [subject, setSubject] = useState({
    id: -1,
    name: i18n.t('AllModules')
  });
  const [lectures, setLectures] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMoreLectures, setHasMoreLectures] = useState(true);
  const [page, setPage] = useState(1);

  const handleSetLectures = (lectures) => {
    setLectures((prev) => [...prev, ...lectures]);

    setHasMoreLectures(lectures.length >= 10);
    setPage((page) => page + 1);
    setIsFetching(false);
  };

  const loadMoreLectures = (e) => {
    if (isFetching || !hasMoreLectures) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;

    if (bottom) {
      setIsFetching(true);
      if (subject.id !== -1) {
        getSubjectLectures(subject.id, page + 1, filters.getValues())
          .then(handleSetLectures)
          .catch(() => setIsFetching(false));
      } else {
        getLectures(space.id, page + 1, filters.getValues())
          .then(handleSetLectures)
          .catch(() => setIsFetching(false));
      }
    }
  };

  useEffect(() => {
    if (isFetching) return;
    setIsFetching(true);
    setLectures([]);
    setPage(0);

    if (subject.id !== -1) {
      getSubjectLectures(subject.id, 1, filters.getValues())
        .then(handleSetLectures)
        .catch(() => setIsFetching(false));
    } else {
      getLectures(space.id, 1, filters.getValues())
        .then(handleSetLectures)
        .catch(() => setIsFetching(false));
    }
  }, [
    subject,
    filters.getValues().ord,
    filters.getValues().direction,
    filters.getValues().name_like
  ]);

  return (
    <FormProvider {...filters}>
      <ContentProvider
        subject={subject}
        setSubject={setSubject}
        lectures={lectures}
        setLectures={setLectures}
      >
        <Box className={`${classes.root} ${classes.justifyContentBetween}`}>
          <SubjectsSelect />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: 24,
              marginTop: '2rem'
            }}
          >
            <FormFilter
              placeholder={`Pesquisar ${i18n.t(
                'StructuresName.Lectures.SingularUpper'
              )}...`}
            />
            <OrderByPopover />
          </div>

          <Box className={classes.lectureContainer} onScroll={loadMoreLectures}>
            <Box className={classes.lectureGrid}>
              {lectures.map((lecture) => (
                <Lecture key={lecture.id} lecture={lecture} />
              ))}
              {isFetching && <LectureShimmer />}
            </Box>
          </Box>
        </Box>
      </ContentProvider>
    </FormProvider>
  );
}
