import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingRight: 25,
    paddingBottom: 10,
    marginTop: 10,
    overflow: 'auto',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 865px)': {
      // maxHeight: 'calc(100vh - 300px)',
      rowGap: 15
    },
    '@media (max-width: 300px)': {
      paddingBottom: 100
    },
    gap: 30
  },
  accordionHeader: {
    display: 'flex'
  },
  alertContainer: {
    margin: '20px 0px 30px 0px'
  },
  accordionContent: {
    width: '100%'
  },
  infoIcon: {
    color: (props) => `${props.theme.palette.accent.main}`,
    marginRight: '8px'
  },
  accordionInformationText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 30
  },
  spaces: {
    marginTop: 20
  },
  space: {
    width: '95%',
    height: 112,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 23,
    '& ::-webkit-scrollbar': {
      width: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.primary.main}`,
      bordeRadius: '2px'
    }
  },
  image: {
    width: 112,
    height: '100%',
    backgroundColor: (props) => `${props.theme.palette.primary.main}`,
    borderRadius: 5
  },
  imgTag: {
    width: '100%',
    height: '100%',
    borderRadius: 5
  },
  infos: {
    flex: 1,
    height: '100%',
    paddingLeft: 18,
    overflow: 'auto'
  },
  loadingContainer: {
    width: 120,
    display: 'flex',
    justifyContent: 'center'
  },
  loadingCertificate: {
    color: (props) => props.theme.palette.accent.main
  },
  fetchButton: {
    background: '#F2F2F7',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '3px 0px',
    '&:hover': {
      background: '#E1E1E5',
      fontSize: '40px'
    }
  },
  content: {
    width: '100%',
    paddingRight: 25,
    paddingBottom: 30,
    marginTop: 20,
    overflow: 'auto',
    height: 370,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 12,
    '@media (max-width: 865px)': {
      // maxHeight: 'calc(100vh - 300px)',
      rowGap: 15
    },
    '@media (max-width: 300px)': {
      paddingBottom: 100
    },
    gap: 30
  },
  skeletonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: 30,
    gap: 30
  }
});
