import i18n from '~/I18n';
import { findByName } from '~/services/environment';

import * as yup from 'yup';

const validateEnvironmentName = async (value = '') => {
  const environmentName = value.trim();
  if (environmentName.length === 0) return true;
  try {
    await findByName(environmentName);
    return false;
  } catch (e) {
    return true;
  }
};

export const editEnvironmentFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório')
    .max(100, 'O Nome deve ter no máximo 100 caracteres.')
    .test(
      'unique-name',
      `O nome do ${i18n.t(
        `StructuresName.Environment.SingularLower`
      )} espelhado não pode ser igual ao ${i18n.t(
        `StructuresName.Environment.SingularLower`
      )} original.`,
      async function(value) {
        return validateEnvironmentName(value);
      }
    ),
  initials: yup.string().required('A abreviação é obrigatória.'),
  description: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  regional_group: yup.string().nullable()
});
