import React from 'react';
import Text  from '~/components/atoms/Text';

import { Grid }      from '@material-ui/core';
import { AddIcon }   from '~/components/atoms/Icons';

import { useStyles } from './styles'

export default function AddAlternativeButton({ setState }){
  const classes = useStyles()

  return (
    <Grid className={`${classes.toggle} ${classes.field}`} container alignItems='center' spacing={1} onClick={() => setState( prev => !prev )}>
      <Grid item>
        <div className={classes.iconCustomWraper}>
          <AddIcon className={classes.iconCustom} />
        </div>          
      </Grid>
      <Grid item>
        <Text color={'primary'} variant={"text"}> Adicionar Alternativa </Text>
      </Grid>
    </Grid>
  )
}