import React, { useCallback, useState } from 'react';
import DropTarget                       from './DropTarget';

export default function Desktop({
  selectedFile,
  fileTypeError,
  setSelectedFile,
  setFileTypeError,
  canUploadType,
  sizeError,
  setSizeError,
  checkMaxSize,
  Input
}) {

  const [droppedFiles, setDroppedFiles]   = useState([]);

  const handleFileDrop = useCallback(
    item => {
      if (item) {
        const files = item.files;
        setDroppedFiles(files);
        setSelectedFile(files[0]);
      }
    },
    [setDroppedFiles]
  );

  return(
        <DropTarget onDrop={handleFileDrop} 
                    Input={Input} 
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile} 
                    fileTypeError={fileTypeError}
                    setFileTypeError={setFileTypeError}
                    sizeError={sizeError}
                    setSizeError={setSizeError} 
                    canUploadType={canUploadType}
                    checkMaxSize={checkMaxSize}
                    />
  )
}
