import React from 'react';

import ReportTitle from '~/components/molecules/ReportTitle';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ReportRow({ title, report: Report, id }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ReportTitle title={title} />
      <Report id={id} />
    </Box>
  );
}

ReportRow.propTypes = {
  title: PropTypes.string,
  report: PropTypes.func,
  id: PropTypes.string
};

ReportRow.defaultProps = {
  title: '',
  report: () => {},
  id: ''
};
