import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffff',
    border: '1px solid gray',
    height: '350px',
    width: '620px',
    borderRadius: '5px'
  },
  title: {
    color: theme.palette.primary.main,
    marginLeft: '20px',
    fontSize: '20px',
    marginTop: '20px'
  },
  inputUrl: {
    width: '400px',
    marginRight: '200px',
    marginTop: '30px',
    marginLeft: '20px'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
    marginLeft: '20px'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '20px',
    marginRight: '35px',
    marginTop: '30px'
  },
  button: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    width: '170px',
    height: '25px',
    marginTop: '10px',
    borderRadius: '5px'
  }
}));
