import React from 'react';

import { ReactComponent as ApplicationIcon } from '~/assets/icons/application_icon.svg';
import ButtonCircle from '~/components/atoms/ButtonCircle';
import { SearchMenuIcon } from '~/components/atoms/Icons';
import settings from '~/settings';

import { useStyles } from './styles';
import { Typography, Box, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(isOpen);
  };

  const list = () => (
    <div className={classes.root} onKeyDown={toggleDrawer(false)}>
      <Box
        onClick={toggleDrawer(false)}
        display="flex"
        justifyContent="end"
        marginBottom="16px"
      >
        <CloseIcon
          style={{
            fontSize: '24px',
            fill: '#fff',
            cursor: 'pointer'
          }}
        />
      </Box>
      <List className={classes.customList}>
        <Link href="/dashboard" className={classes.customLink}>
          <ListItem button>
            <ApplicationIcon
              style={{
                height: '25.5px',
                width: '25.5px',
                marginRight: '8px'
              }}
            />
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '16px'
              }}
            >
              Acesse nossa plataforma
            </Typography>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className={classes.customList}>
        <Link
          href={settings.helpCenterUrl}
          target="_blank"
          rel="noreferrer"
          className={classes.customLink}
        >
          <ListItem button>
            <MenuBookIcon style={{ fontSize: '24px', marginRight: '8px' }} />
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '16px'
              }}
            >
              Manual da plataforma
            </Typography>
          </ListItem>
        </Link>
        <Link
          href="/help"
          className={classes.customLink}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <HeadsetMicIcon style={{ fontSize: '24px', marginRight: '8px' }} />
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '16px'
              }}
            >
              Fale Conosco
            </Typography>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className={classes.customList}>
        <Link
          href="/privacy-policy-and-usage-terms"
          className={classes.customLink}
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <LibraryBooksIcon
              style={{
                height: '25.5px',
                width: '25.5px',
                marginRight: '8px'
              }}
            />
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '16px'
              }}
            >
              Política de privacidade e termos de uso
            </Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <ButtonCircle size={44} onClick={toggleDrawer(true)}>
        <SearchMenuIcon color="white" />
      </ButtonCircle>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
