import React from 'react';

import settings from '~settings';

import { useStyles } from './styles';
import { IconButton, Box, ListItem, Avatar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Parse from 'html-react-parser';
import PropTypes from 'prop-types';

const SearchListItem = ({ item, removeItem, wordSearched, goTo }) => {
  const classes = useStyles();

  const ItemHighlighted = () => {
    const substring = new RegExp(wordSearched, 'gi');
    const name = item.type === 'profile' ? item.display_name : item.name;

    const itemHighlighted = name?.replace(substring, (match) => {
      return `<mark>${match}</mark>`;
    });

    const html = `
      <div class=${classes.listItemText} >
        <h1 class=${classes.itemText} >
          ${itemHighlighted}
        </h1>
      </div>
    `;

    return Parse(html);
  };

  return (
    <ListItem className={classes.container}>
      <Box className={classes.infosWrapper}>
        <Avatar
          className={classes.avatar}
          alt="{profile.first_name}"
          src={`${settings.baseUrl}${item.thumbnail}`}
          onClick={() => goTo(item)}
        />
        <Box
          onClick={() => goTo(item)}
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            marginLeft: 14
          }}
        >
          <ItemHighlighted />
        </Box>
        <IconButton
          onClick={() => {
            removeItem(item);
          }}
          className={classes.iconButton}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      </Box>
    </ListItem>
  );
};

SearchListItem.propTypes = {
  item: PropTypes.shape(),
  removeItem: PropTypes.func,
  toggleOpenDrawer: PropTypes.func,
  wordSearched: PropTypes.string
};

SearchListItem.defaultProps = {
  item: {},
  removeItem: null,
  toggleOpenDrawer: null,
  wordSearched: ''
};

export default SearchListItem;
