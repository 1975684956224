import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import LaPlaceContext from '~/components/contexts/LaPlaceContext';
import DragDropList from '~/components/molecules/DragDropList';
import LectureForm from '~/components/organisms/LectureForm';
import i18n from '~/I18n';
import { createSubject } from '~/pages/CreateSubject/fetch';
import { getLectures } from '~/services/Subject';

import Lecture from './Lecture';
import { useStyles } from './styles';
import { Backdrop, Box, Fade, Grid, Modal, useTheme } from '@material-ui/core';

export default function Content() {
  const theme = useTheme();
  const classes = useStyles();

  const profile = useSelector((state) => state.user.profile);

  const [open, setOpen] = useState(false);

  async function handleSucessImportLecture() {
    try {
      const lectures = await getLectures(watch('id'));
      setValue('lectures', lectures);
      setOpen(false);
    } catch (e) {
      setOpen(false);
    }
  }

  const {
    formState: { isValid },
    setValue,
    getValues,
    watch,
    trigger
  } = useFormContext();

  const newLecturesDefaultValues = {
    subjectID: watch('id'),
    expiration_date: null,
    markable: true,
    mural_enabled: true,
    lectureable: {
      type: 'multimedia',
      videocall: {
        google_meet: {
          event_start: null,
          event_end: null,
          user_id: profile?.id
        }
      },
      livestream: {
        start_time: new Date()
      },
      image: {
        user_id: profile?.id
      },
      clone: {
        is_mirror: false,
        lecture_id: ''
      },
      exercise: {
        shuffle: false,
        maximum_grade: 10,
        questions: []
      },
      video: {
        vimeoOption: true,
        videoID: '',
        file: null
      }
    }
  };

  const [currentLecture, setCurrentLecture] = useState(null);
  const setLectures = (lectures) => {
    setValue('lectures', lectures);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = (response) => {
    setValue('id', response.data.id);
    handleOpen();
  };

  const handleAddLecture = async () => {
    const { ...body } = getValues();
    await trigger();
    setCurrentLecture(null);

    if (watch('id')) {
      handleOpen();
    } else if (isValid || body.name) {
      delete body['spaceID'];
      delete body['lectures'];
      createSubject(watch('spaceID'), { subject: body })
        .then(handleSuccess)
        .catch(() =>
          window.alert(
            `Não foi possível abrir o formulário de ${i18n.t(
              'StructuresName.Lectures.PluralLower'
            )}.`
          )
        );
    } else {
      window.alert(
        `Os campos obrigatórios do ${i18n.t(
          'StructuresName.Subjects.SingularLower'
        )} não foram preenchidos corretamente. Por favor, preencha-os e tente novamente!`
      );
    }
  };

  const handleLectureCreationSuccess = (response) => {
    const lecture = response.data;
    let lectures = watch('lectures');
    const index = lectures.findIndex((elem) => elem.id === lecture.id);
    if (index >= 0) {
      lectures[index] = lecture;
      setValue('lectures', lectures);
    } else {
      watch('lectures').push(response.data);
    }
    handleClose();
  };

  const handleLectureCreationFailure = (e) => {
    window.alert(
      e.contained_in_invalid_mirror
        ? 'Não é possível criar estruturas espelhadas dentro de outras estruturas que já são espelhos.'
        : `Não foi possível criar a ${i18n.t(
            'StructuresName.Lectures.SingularLower'
          )}`
    );
    // handleClose();
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <ButtonSquare
          width={125}
          height={35}
          backgroundColor={theme.palette.secondary.main}
          onClick={handleAddLecture}
        >
          <Text className={classes.buttonName}>
            {' '}
            Adicionar {`${i18n.t(
              'StructuresName.Lectures.SingularUpper'
            )}`}{' '}
          </Text>
        </ButtonSquare>
      </Grid>

      <Grid item>
        <DragDropList
          objects={watch('lectures')}
          setObjects={setLectures}
          Container={'div'}
          ObjectComponent={Lecture}
          itemType="lectures"
          setOpenModal={setOpen}
          setCurrentLecture={setCurrentLecture}
        />
      </Grid>

      <Modal
        id="modal-modal"
        disableEnforceFocus
        aria-labelledby="lecture-form"
        aria-describedby="modal-for-lecture-form"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box maxWidth={'900px'} width={'90%'}>
            <LaPlaceContext.Provider
              value={{ handleSucessImportLecture, subjectID: watch('id') }}
            >
              <LectureForm
                defaultValues={currentLecture ?? newLecturesDefaultValues}
                handleSuccess={handleLectureCreationSuccess}
                handleFailure={handleLectureCreationFailure}
                handleClose={handleClose}
              />
            </LaPlaceContext.Provider>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
}
