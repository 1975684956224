import React from 'react';
import { Page } from 'react-pdf';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const TwoPagesVisualization = React.memo(
  ({
    documentTotalPages,
    twoPagesRefArray,
    scale,
    rotateValue,
    customTextRenderer
  }) => {
    const content = Array.from(new Array(documentTotalPages), (el, index) => {
      if (!(index % 2 === 0)) return null;
      return (
        <Box
          key={`twoPages_${index / 2}`}
          ref={(el) => (twoPagesRefArray.current[index / 2] = el)}
          style={{
            marginBottom: '8px',
            display: 'flex',
            gap: '8px',
            scrollMarginTop: '8px'
          }}
        >
          <Page
            pageNumber={index + 1}
            scale={scale}
            rotate={rotateValue}
            customTextRenderer={(obj) => customTextRenderer({ obj })}
          />
          {index + 2 < documentTotalPages && (
            <Page
              pageNumber={index + 2}
              scale={scale}
              rotate={rotateValue}
              customTextRenderer={(obj) => customTextRenderer({ obj })}
            />
          )}
        </Box>
      );
    });

    return content;
  }
);

TwoPagesVisualization.displayName = 'TwoPagesVisualization';
export default TwoPagesVisualization;

TwoPagesVisualization.propTypes = {
  documentTotalPages: PropTypes.number.isRequired,
  twoPagesRefArray: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  scale: PropTypes.number.isRequired,
  rotateValue: PropTypes.number.isRequired,
  customTextRenderer: PropTypes.func.isRequired
};
