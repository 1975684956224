import api from '~/services/api';

const baseURL = '/api/mirroring/environments';

export const create = async (id, payload) => {
  await api.post(`${baseURL}`, {
    replicable_id: id,
    environment: {
      ...payload
    }
  });
};

export const unlink = async (id) => {
  await api.put(`${baseURL}/${id}/unlink`);
};

export default { create, unlink };
