import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import i18n from '~/I18n';
import { getUsersRegisteredReport } from '~/services/reports/spaces';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UsersRegisteredReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUsersRegisteredReport}
        baseName={`relatorio_de_papeis_dos_usuarios_na_${i18n.t(
          'StructuresName.Space.SingularLower'
        )}`}
        id={id}
      />
    </div>
  );
}

UsersRegisteredReport.propTypes = {
  id: PropTypes.string
};

UsersRegisteredReport.defaultProps = {
  id: ''
};
