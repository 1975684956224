import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Years } from '~/constants/years';
import i18n from '~/I18n';

import ReactHookFormSelect from '../../ReactHookFormSelect';
import ReactHookFormTextField from '../../ReactHookFormTextField';
import { useStyles } from '../styles';

const ComplementaryCourse = ({ control, education, index }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={`${classes.column} ${classes.mainForm}`}>
      <Box className={classes.row}>
        <Controller
          defaultValue={education.educationable.course}
          name={`educations.${index}.educationable.course`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.course`}
              label={i18n.t('Course')}
              defaultValue={education.educationable.course}
              {...field}
            />
          )}
        />

        <Controller
          name={`educations.${index}.educationable.institution`}
          control={control}
          defaultValue={education.educationable.institution}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.institution`}
              label={i18n.t('HighSchoolInstitution')}
              defaultValue={education.educationable.institution}
              {...field}
            />
          )}
        />
      </Box>

      <Box className={classes.row}>
        <ReactHookFormSelect
          id={`educations.${index}.educationable.year`}
          name={`educations.${index}.educationable.year`}
          label={i18n.t('Year')}
          control={control}
          values={Years}
          defaultValue={education.educationable.year}
          variant="outlined"
          margin="normal"
        />

        <Controller
          name={`educations.${index}.educationable.workload`}
          control={control}
          defaultValue={education.educationable.workload}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${education}.educationable.workload`}
              label={i18n.t('ComplementaryCourseWorkload')}
              defaultValue={education.educationable.workload}
              {...field}
            />
          )}
        />
      </Box>

      <Box className={classes.row}>
        <Controller
          defaultValue={education.educationable.description}
          name={`educations.${index}.educationable.description`}
          control={control}
          render={({ field }) => (
            <ReactHookFormTextField
              name={`educations.${index}.educationable.description`}
              label={i18n.t('Description')}
              defaultValue={education.educationable.description}
              {...field}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ComplementaryCourse;

ComplementaryCourse.propTypes = {
  control: PropTypes.shape(),
  education: PropTypes.shape(),
  index: PropTypes.shape()
};

ComplementaryCourse.defaultProps = {
  control: null,
  education: null,
  index: null
};
