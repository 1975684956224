import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

export const SpacePageContext = createContext();

export const SpacePageProvider = ({ space, section, setSection, children }) => {
  return (
    <SpacePageContext.Provider
      value={{
        space,
        section,
        setSection
      }}
    >
      {children}
    </SpacePageContext.Provider>
  );
};

export const useSpacePageContext = () => useContext(SpacePageContext);

SpacePageProvider.propTypes = {
  space: PropTypes.shape.isRequired,
  section: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
