import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '16px',
    // justifyContent: 'center',
    gap: '16px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    boxShadow: '0px 4px 16px 0px rgba(103, 109, 130, 0.16)',
    overflow: 'hidden',
    maxHeight: '88px',
    minHeight: '88px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '& $userName, & $userRole, & $customCheckbox': {
        color: '#fff'
      }
    }
  },
  containerActivated: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '& $userName, & $userRole, & $customCheckbox': {
      color: '#fff'
    }
  },
  memberInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  userName: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  userRole: {
    fontSize: '12px',
    color: '#676D82',
    textAlign: 'left'
  },
  customCheckbox: {
    transform: 'scale(1.5)',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
