import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import { update as updateAlternative } from '~/services/Lecture/Questionary/AnyChoiceQuestion/Option';
import { remove as removeAlternative } from '~/services/Lecture/Questionary/AnyChoiceQuestion/Option';

import AddQuestionButton from './AddQuestionButton';
import AlternativeFields from './AlternativeFields';
import Alternatives from './Alternatives';
import Statements from './Statements';
import { useStyles } from './styles';
import { Button, Collapse, Grid, useTheme } from '@material-ui/core';

export default function QuestionFields({
  addQuestion,
  currentQuestion,
  resetQuestionFields,
  editingQuestion
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const { setValue } = useFormContext();

  const [alternatives, setAlternatives] = useState([]);
  const [errorStatement, setErrorStatement] = useState(false);
  const [errorAlternative, setErrorAlternative] = useState(false);
  const [showQuestionFields, setShowQuestionFields] = useState(false);
  const [currentAlternative, setCurrentAlternative] = useState({
    id: null,
    correct: false,
    text: '',
    letter: 'A'
  });

  const [statement, setStatement] = useState('');
  const [weight, setWeight] = useState(1.0);

  const [startStatement, setStartStatement] = useState('');

  const deleteAlternative = (alternative) => {
    const handleDeleteAlternative = (alternative) => {
      let letter = 'A';
      alternative.letter = '';
      alternative.correct = false;

      const destroyedAlternatives = alternatives.filter(
        (elem) => elem._destroy
      );
      const validAlternatives = alternatives.filter(
        (elem) => !elem._destroy && elem.letter !== ''
      );

      for (let item of validAlternatives) {
        item.letter = letter;
        letter = getNextChar(letter);
      }

      setCurrentAlternative({ correct: false, text: '', letter: letter });
      setAlternatives([...validAlternatives, ...destroyedAlternatives]);
      setEditing(false);
    };

    if (alternative.id) {
      removeAlternative(alternative.id)
        .then(() => {
          alternative._destroy = true;
          handleDeleteAlternative(alternative);
        })
        .catch((e) => alert(e.response.data));
    } else {
      handleDeleteAlternative();
    }
  };

  const setAlternativeEdition = (alternative) => {
    setCurrentAlternative(alternative);
    setEditing(true);
  };

  const resetFields = () => {
    const validAlternatives = alternatives.filter((elem) => !elem._destroy);
    const letter = validAlternatives.at(-1)?.letter ?? 'A';
    setCurrentAlternative({
      correct: false,
      text: '',
      letter: getNextChar(letter)
    });
    setEditing(false);
  };

  const resetAlternativeFields = () => {
    setAlternatives([]);
    setCurrentAlternative({ correct: false, text: '', letter: 'A' });
    setWeight(1.0);
    setStatement('');
  };

  const resetErrorFlags = () => {
    setErrorStatement(false);
    setErrorAlternative(false);
  };

  const handleAddQuestion = () => {
    var question = {
      options_attributes: alternatives,
      statement: statement,
      position: currentQuestion.position
    };

    setValue(`lectureable.questionary.questions[${currentQuestion.position - 1}]`, question);

    if (addQuestion(question)) {
      setShowQuestionFields(false);
      resetAlternativeFields();
      resetErrorFlags();
    } else {
      resetErrorFlags();
      if (!question.statement) {
        setErrorStatement(true);
      } else if (question.options_attributes.length <= 1) {
        setErrorAlternative(true);
      }
    }
  };

  const handleCancel = () => {
    setShowQuestionFields(false);
    resetQuestionFields();
  };

  const getNextChar = (char) => {
    return String.fromCharCode(char.charCodeAt(0) + 1);
  };

  const addAlternative = (alternative) => {
    var existingAlternative = alternatives.find(
      (elem) => elem.letter === alternative.letter && !elem._destroy
    );
    var index = alternatives.findIndex(
      (elem) => elem.letter === alternative.letter && !elem._destroy
    );
    if (existingAlternative) {
      setErrorAlternative(false);

      alternatives[index] = alternative;
      var newAlternatives = alternatives;
      setAlternatives(newAlternatives);
      const validAlternatives = alternatives.filter((elem) => !elem._destroy);

      const body = {
        option: {
          text: alternative.text
        }
      };
      updateAlternative(alternative.id, body).catch((e) =>
        alert(e.response.data)
      );

      setCurrentAlternative({
        correct: false,
        text: '',
        letter: getNextChar(validAlternatives.at(-1).letter)
      });
      setEditing(false);

      return true;
    } else {
      if (!alternative.text) {
        window.alert('Você precisa preencher o enunciado da alternativa.');
        return false;
      }

      if (
        alternative.correct &&
        alternatives.filter((elem) => elem.correct && !elem._destroy).length > 0
      ) {
        window.alert('A questão já possui uma alternativa correta.');
        return false;
      }
      setAlternatives((prev) => [...prev, alternative]);
      setCurrentAlternative({
        correct: false,
        text: '',
        letter: getNextChar(alternative.letter)
      });
      setEditing(false);
      return true;
    }
  };

  useEffect(() => {
    if (editingQuestion) {
      setStartStatement(currentQuestion.statement);
      setAlternatives(currentQuestion.options_attributes);
      const validAlternatives = currentQuestion.options_attributes.filter(
        (elem) => !elem._destroy
      );
      setCurrentAlternative({
        id: null,
        correct: false,
        text: '',
        letter: getNextChar(validAlternatives.at(-1)?.letter ?? 'A')
      });
    }
  }, [editingQuestion]);

  return (
    <div>
      <Collapse in={!showQuestionFields && !editingQuestion}>
        <AddQuestionButton setState={setShowQuestionFields} />
      </Collapse>

      <Collapse in={showQuestionFields || editingQuestion}>
        <div className={classes.questionField}>
          {currentQuestion.position ? (
            <Text color={'primary'} variant={'subtitle'}>
              {' '}
              Questão{' '}
              <span style={{ fontWeight: 'bold' }}>
                {currentQuestion.position}
              </span>{' '}
            </Text>
          ) : (
            <Text color={'primary'} variant={'subtitle'}>
              {' '}
              Questão{' '}
            </Text>
          )}
        </div>

        <Statements
          statement={statement}
          setStatement={setStatement}
          startStatement={startStatement}
          errorStatement={errorStatement}
        />

        <div className={classes.field}>
          {alternatives.length > 0 ? (
            <Alternatives
              alternatives={alternatives}
              deleteFunc={deleteAlternative}
              editFunc={setAlternativeEdition}
            />
          ) : null}
        </div>

        <AlternativeFields
          addAlternative={addAlternative}
          currentAlternative={currentAlternative}
          resetFields={resetFields}
          editing={editing}
          errorAlternative={errorAlternative}
        />

        <div className={classes.field}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{
                  textTransform: 'none',
                  border: `1px solid ${theme.palette.primary.main}`,
                  width: 140,
                  height: 45
                }}
                onClick={handleCancel}
              >
                <Text color="primary" variant={'button'}>
                  {' '}
                  {editingQuestion
                    ? 'Cancelar Edição'
                    : 'Cancelar Questão'}{' '}
                </Text>
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  textTransform: 'none',
                  backgroundColor: `${theme.palette.primary.main}`,
                  width: 140,
                  height: 45
                }}
                onClick={handleAddQuestion}
              >
                <Text color="white" variant={'button'}>
                  {' '}
                  {editingQuestion
                    ? 'Alterar Questão'
                    : 'Adicionar Questão'}{' '}
                </Text>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Collapse>
    </div>
  );
}
