import React from 'react';

import i18n from '~/I18n';

import LogoLabel from '../LogoLabel';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';

export default function PlatformLogo() {
  const theme = useTheme();

  const LogoImage = styled.img.attrs({
    src: theme.logo
  })`
    width: ${(props) => props.width};
    height: auto;
  `;

  return (
    <>
      <LogoImage width={theme.size.loginLogo.width} />
      <LogoLabel label={i18n.t('Login.AccessYourAccount')} />
    </>
  );
}
