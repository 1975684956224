import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  displayLecturesButton: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '24px',
    padding: '12px 24px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    bottom: '16px',
    left: '16px',
    zIndex: 9998,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  displayLecturesButtonText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  displayLecturesButtonIcon: {
    fontSize: '24px'
  },
  drawerPaper: {
    borderRadius: '4px 4px 0px 0px',
    padding: '16px 8px 0px 8px',
    maxHeight: '90vh',
    minHeight: '90vh'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px'
  },
  drawerTitleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  drawerTitleIcon: {
    color: theme.palette.primary.main,
    fontSize: '24px'
  },
  drawerTitleText: {
    fontSize: '19px',
    fontWeight: '700',
    lineHeight: '24px',
    color: theme.palette.primary.main
  },
  closeButton: {
    borderRadius: '50%',
    padding: '4px'
  },
  closeButtonIcon: {
    color: theme.palette.activeText.main,
    fontSize: '20px'
  }
}));
