import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import PropTypes from 'prop-types';

export const ListLecturesInMobile = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isMdUp) setOpen(false);
  }, [isMdUp]);

  return (
    <>
      <ButtonBase
        className={classes.displayLecturesButton}
        onClick={() => setOpen(true)}
      >
        <SubscriptionsIcon className={classes.displayLecturesButtonIcon} />
        <Typography className={classes.displayLecturesButtonText}>
          Mídias
        </Typography>
        <ExpandLessIcon className={classes.displayLecturesButtonIcon} />
      </ButtonBase>
      <Drawer
        PaperProps={{ className: classes.drawerPaper }}
        anchor={'bottom'}
        open={open}
        onClose={() => setOpen(false)}
        style={{
          zIndex: 9999
        }}
      >
        <Box className={classes.drawerHeader}>
          <Box className={classes.drawerTitleBox}>
            <SubscriptionsIcon className={classes.drawerTitleIcon} />
            <Typography className={classes.drawerTitleText}>Mídias</Typography>
          </Box>
          <ButtonBase
            disableRipple
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseRoundedIcon className={classes.closeButtonIcon} />
          </ButtonBase>
        </Box>
        {children}
      </Drawer>
    </>
  );
};

ListLecturesInMobile.propTypes = {
  children: PropTypes.node.isRequired
};
