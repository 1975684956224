import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingBottom: 20,
    fontSize: 'initial',
    '& object': {
      width: '100%'
    },
    '& embed': {
      width: '100%'
    },
    '& iframe': {
      maxWidth: '100%'
    },
    '& .ck-widget.html-object-embed': {
      maxWidth: '100%'
    },
    '& img': {
      // Targeting all <img> tags inside elements with the class "root"
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto'
    }
  },

  content: {
    padding: 'revert',
    '& *': {
      padding: 'revert'
    }
  }
});
