import React     from 'react'
import ReCAPTCHA from 'react-google-recaptcha';

import { Box } from '@material-ui/core';

import settings from '~settings';

import { useStyles } from './styles';

export default function Recaptcha({ onChange }) {
  const classes = useStyles()

  return (
    <Box className={classes.recaptchaContainer}>
      <ReCAPTCHA
        sitekey={settings.recaptchaKey}
        className={classes.recaptcha}
        onChange={onChange}
      />
    </Box>
  )
}