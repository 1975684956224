import React, { useEffect, useState } from 'react';

import AppsMobileLinks from '~/components/molecules/AppsMobileLinks';
import ReduLoginForm from '~/components/organisms/ReduLoginForm';

import ContactUs from './ContactUs';
import { getSettings } from './fetch';
import LoginDivider from './LoginDivider';
import Logo from './Logo';
import OthersLogins from './OthersLogins';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';

export default function RightContent() {
  const classes = useStyles();

  const [reduLoginEnabled, setReduLoginEnabled] = useState(false);
  const [sebraeLoginEnabled, setSebraeLoginEnabled] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  const includeReduLogin = (elem) =>
    elem.key === 'feature.authentication_service.redu' && elem.value;
  const includeSebraeLogin = (elem) =>
    elem.key === 'feature.authentication_service.sebrae_id' && elem.value;

  useEffect(() => {
    getSettings()
      .then((settings) => {
        setReduLoginEnabled(settings.find(includeReduLogin));
        setSebraeLoginEnabled(settings.find(includeSebraeLogin));

        setShowOthers(settings.find(includeSebraeLogin));
      })
      .catch(console.log);
  }, []);

  return (
    <Box className={classes.contentRight}>
      <Box style={{ width: '80%', maxWidth: '360px' }}>
        <div style={{ marginBottom: '15px' }}>
          <Logo />
        </div>

        {showOthers && (
          <div>
            <div style={{ marginBottom: '30px' }}>
              <OthersLogins sebraeLoginEnabled={sebraeLoginEnabled} />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <LoginDivider />
            </div>
          </div>
        )}

        {reduLoginEnabled && (
          <div style={{ marginBottom: '20px' }}>
            <ReduLoginForm />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AppsMobileLinks />
        </div>

        <ContactUs />
      </Box>
    </Box>
  );
}
