import React from 'react';
import { Page } from 'react-pdf';

import PropTypes from 'prop-types';
export default function PresentationVisualization({
  currentPage,
  scale,
  rotateValue
}) {
  return <Page pageNumber={currentPage} scale={scale} rotate={rotateValue} />;
}

PresentationVisualization.propTypes = {
  currentPage: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  rotateValue: PropTypes.number.isRequired
};
