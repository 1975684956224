import initialPagesImageBgRecover from '../assets/images/adamsMaster-bg.jpg';
import initialPagesImageBgLogin from '../assets/images/adamsMaster-bg.jpg';
import logoColorful from '../assets/logos/colorful/adams.svg';
import logo from '../assets/logos/logo-masteradams.png';
import { muiCreateTheme } from './mui-create-theme';

const title = 'ADAMS - Ambiente Digital de Aprendizagem Master';
const loginLogo = {
  width: 250
};
const appBarLogo = {
  width: 80
};

const imageBackgroundLogin = {
  height: '100%',
  width: '60%'
};

let light = muiCreateTheme({
  size: {
    loginLogo: loginLogo,
    appBarLogo: appBarLogo,
    imageBackgroundLogin: imageBackgroundLogin
  },
  palette: {
    type: 'light',
    primary: {
      main: '#432D80'
    },
    secondary: {
      main: '#fcd85a'
    },
    tertiary: {
      main: '#EF6D50'
    },
    accent: {
      main: '#26C8CE'
    },
    activeText: {
      main: '#676D82'
    },
    pendent: {
      main: '#75aafb'
    },
    hiperlinkActiveText: {
      main: '#00C2FF'
    },
    textInactiveElement: {
      main: '#e7e7e7'
    },
    textActiveElement: {
      main: '#707070'
    },
    text: {
      main: '#000000'
    },
    textAndBackground: {
      main: '#ffffff'
    },
    warn: {
      main: '#FD9D4D'
    },
    error: {
      main: '#EE1D23'
    },
    shimmer: {
      main: 'rgba(87, 154, 255, 0.43)'
    },
    success: {
      main: '#35998F'
    }
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#B5C057'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#B5C057',
          borderWidth: 2
        },
        '&$focused $notchedOutline': {
          borderColor: '#B5C057'
        }
      },
      notchedOutline: {}
    },
    MuiSelect: {
      icon: {
        fill: '#B5C057'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 1024,
      lg: 1190,
      xl: 1920
    }
  }
});

export default {
  logo,
  logoColorful,
  ...light,
  title,
  initialPagesImageBgLogin,
  initialPagesImageBgRecover
};
