import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { Creators as UserActions } from '~/store/ducks/user';

export default function Contacts() {
  const { profile, mycontacts } = useSelector((state) => state.user);
  const { getMyContactsRequest } = UserActions;
  const dispatch = useDispatch();

  useEffect(() => {
    if (profile) {
      dispatch(getMyContactsRequest(profile.login));
    }
  }, []);

  return (
    <div>
      {!mycontacts && <LinearProgress />}
      {mycontacts && (
        <List>
          {mycontacts.map((contact, idx) => (
            <ListItem
              key={idx}
              button
              component={Link}
              to={`/dashboard/userContacts/${contact.login}`}
            >
              <ListItemText>{contact.first_name}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}
