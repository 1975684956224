import base from './base';
const baseUrl = 'https://v1.sebraeedu.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfkb0dt9p9';

// SEBRAE
const sebraeRedirectURI = 'https://sebraeedu.com.br/app/login'; // Domínio que está rodando o front do redu em produção
const sebraeIDBaseURL = 'https://amei.sebrae.com.br'; // Domínio de produção do sebrae ID
const sebraeKey = '!t0907Hu@7l@IAUYYFohGuw9'; // chave do backend para o namespace do sebrae

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'dvCucar0Ku3q56pJtMdtLvA723UnfvbKTp6z4bA9hN4',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  sebraeIDBaseURL,
  sebraeRedirectURI,
  websocketUrl: 'wss://v1.sebraeedu.com.br:44443',
  sebraeKey,
  clarityKey,
  sebraeLoginURL: `${sebraeIDBaseURL}/auth/realms/externo/protocol/openid-connect/auth?client_id=sebrae-edu-frontend&response_mode=query&response_type=code&scope=openid&redirect_uri=${sebraeRedirectURI}`
};
