/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Select from '~/components/atoms/Select';
import Text from '~/components/atoms/Text';
import ConfirmModal from '~/components/molecules/ConfirmModal';
import { educationableTypes } from '~/constants/education';
import i18n from '~/I18n';
import api from '~/services/api';

import ComplementaryCourse from './ComplementaryCourse';
import EventEducation from './EventEducation';
import HigherEducation from './HigherEducation';
import HighSchool from './HighSchool';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
const questionMessage = i18n.t('DeleteEducation');
const errorMessage = i18n.t('DeleteErrorMessage');

const FormAcademicEducation = ({ fieldsAcademic }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educations',
    keyName: 'educationId'
  });

  const { profile } = useSelector((state) => state.user);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmButtons, setShowConfirmButtons] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [education, setEducation] = useState(null);
  const [index, setIndex] = useState(null);
  const [newEducationSelect, setNewEducationSelect] = useState(false);

  const educationNames = educationableTypes.map((type) => type.name);

  useEffect(() => {
    if (fields.length === 0 && fieldsAcademic && fieldsAcademic.length !== 0) {
      fieldsAcademic.map((e) => {
        append(e);
      });
    }
  }, [fieldsAcademic]);

  function handleOpen(education, index) {
    setEducation(education);
    setIndex(index);
    setDialogMessage(questionMessage);
    setShowConfirmButtons(true);
    setShowConfirmDialog(true);
  }
  function handleClose() {
    setShowConfirmDialog(false);
    setShowConfirmButtons(false);
  }

  function handleConfirm() {
    if (education.id !== undefined) {
      setLoadingDelete(true);
      api
        .delete(`/api/users/${profile?.id}/education/${education.id}`)
        .then(() => {
          setLoadingDelete(false);
          setShowConfirmButtons(!showConfirmButtons);
          remove(index);
        })
        .catch(() => {
          setLoadingDelete(false);
          setDialogMessage(errorMessage);
          setShowConfirmButtons(false);
        });
    } else {
      remove(index);
      setShowConfirmButtons(false);
      setShowConfirmDialog(false);
    }
  }

  useEffect(() => {
    if (showConfirmButtons !== true) {
      setShowConfirmButtons(false);
      setShowConfirmDialog(false);
    }
  }, [showConfirmButtons]);

  return (
    <Box className={classes.column}>
      {fields?.map((education, index) => (
        <Box key={index}>
          <Box className={classes.typeBox}>
            <Text variant="largeText" color="gray">
              {education.educationable_type}
            </Text>
            <button onClick={() => handleOpen(education, index)} type="button">
              <CloseIcon className={classes.deleteIcon} />
            </button>
          </Box>
          {education.educationable_type == 'Ensino Superior' && (
            <HigherEducation
              index={index}
              education={education}
              control={control}
            />
          )}

          {education.educationable_type == 'Ensino Médio' && (
            <HighSchool index={index} education={education} control={control} />
          )}

          {education.educationable_type == 'Evento' && (
            <EventEducation
              index={index}
              education={education}
              control={control}
            />
          )}
          {education.educationable_type == 'Curso Complementar' && (
            <ComplementaryCourse
              index={index}
              education={education}
              control={control}
            />
          )}
        </Box>
      ))}
      <>
        <Box className={classes.buttonArea}>
          {!newEducationSelect && (
            <ButtonSquare
              onClick={() => setNewEducationSelect(true)}
              backgroundColor={theme.palette.secondary.main}
              fontSize="14px"
              color={theme.palette.textAndBackground.main}
              letterSpacing="0px"
              width="134px"
              height="37px"
            >
              <Text variant="text" color="textAndBackground" font>
                {i18n.t('AddEducation')}
              </Text>
            </ButtonSquare>
          )}
        </Box>
        {newEducationSelect && (
          <Box className={classes.educationTypeSelect}>
            <Box>
              <Select
                educationsSelect
                applyPlaceholderStyles
                label={i18n.t('EducationType')}
                values={educationNames}
                label={i18n.t('EducationTypePlaceholder')}
                onChange={(event) => {
                  append({
                    educationable: {},
                    educationable_type: event.target.value
                  });

                  setNewEducationSelect(false);
                }}
              />
            </Box>
          </Box>
        )}
      </>
      <ConfirmModal
        show={showConfirmDialog}
        text={dialogMessage}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        maxWidth={440}
        loading={loadingDelete}
        showButtons={showConfirmButtons}
      />
    </Box>
  );
};

export default FormAcademicEducation;

FormAcademicEducation.propTypes = {
  fieldsAcademic: PropTypes.array
};

FormAcademicEducation.defaultProps = {
  fieldsAcademic: []
};
