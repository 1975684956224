import React from 'react';
import PropTypes from 'prop-types';
import SearchListItem from '../SearchListItem';

export default function SearchList ({ listSearch, setListSearch, wordSearched, goTo }) {    
  async function removeItem(listItem) {
    if (listSearch && listSearch.length > 0) {
      const newList = await listSearch.filter((item) => {
        if (item.id !== listItem.id) {
          return item;
        }
      });
      setListSearch(newList);
    } 
  }
  
  return (
    listSearch?.map(item => (
      <SearchListItem
        key={item.id}
        item={item}
        removeItem={removeItem}
        wordSearched={wordSearched}
        goTo={goTo}
      />)
    )
  )
};

SearchList.propTypes = {
  wordSearched: PropTypes.string,
  listSearch: PropTypes.array,
  setListSearch: PropTypes.shape()
};

SearchList.defaultProps = {
  wordSearched: '',
  listSearch: [],
  setListSearch: null
};