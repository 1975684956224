import api from '~/services/api';

export const updateCourse = async (courseID, body) => {
  const { data } = await api.put(`/api/courses/${courseID}`, body);
  return data;
};

export const getCourse = async (courseID) => {
  try {
    const { data } = await api.get(`api/courses/${courseID}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const deleteLimit = async (limitID) => {
  const { data } = await api.delete(`api/enrollment_limits/${limitID}`);
  return data;
}

export const deleteCourse = async (courseID) => {
  const { data } = await api.delete(`api/courses/${courseID}`);
  return data;
};

export const deleteEnrollmentLink = async (linkID) => {
  const { data } = await api.delete(`api/enrollment_links/${linkID}`);
  return data;
};