import api from '~/services/api';

const baseURL = '/api/lectureables/seminars/audio';

// TODO: return response.data
export const create = async (body) => {
  return await api.post(baseURL, body);
};

export default {
  create
};
