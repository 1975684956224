import React from 'react';

import PropTypes from 'prop-types';

import { Box, Radio, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function Alternative({
  alternative,
  checked,
  checkAlternative,
  submitted,
  chosenId,
  correctId,
  showCorrectAnswer,
  type
}) {
  const { text, id } = alternative;

  const theme = useTheme();
  const classes = useStyles({
    theme,
    submitted,
    greenOutline:
      showCorrectAnswer ||
      (submitted && chosenId === correctId && id === chosenId),
    greenFilled: submitted && chosenId !== correctId && id === correctId
  });

  function check() {
    checkAlternative(id);
  }

  if (!alternative) return null;

  return (
    <Box className={classes.root}>
      <Radio
        disabled={submitted}
        className={classes.radio}
        disableRipple
        color="default"
        checkedIcon={
          <CheckCircleIcon className={`${classes.icon} ${classes.checked}`} />
        }
        icon={<RadioButtonUncheckedIcon className={classes.icon} />}
        checked={
            !submitted
            ? checked
            : id === chosenId
        }
        onChange={check}
      />
      <Box style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
        <div
          className={classes.imgData}
          dangerouslySetInnerHTML={{
            __html: `<div>
              ${text}
            </div>`
          }}
        />
      </Box>
    </Box>
  );
}

Alternative.propTypes = {
  alternative: PropTypes.shape(),
  checked: PropTypes.bool,
  checkAlternative: PropTypes.func,
  submitted: PropTypes.bool,
  chosenId: PropTypes.number,
  correctId: PropTypes.number
};

Alternative.defaultProps = {
  alternative: {},
  checked: false,
  checkAlternative: null,
  submitted: false,
  chosenId: -1,
  correctId: -1
};
