/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Box, Modal, IconButton } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MdOutlineCrop, MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import api from '~/services/api';
import { Creators as UserCreators } from '~/store/ducks/user';
import { dataURLtoFile, readFile, getCroppedImg } from '~/utils/imageUtils';

import { ShimmerCircle } from '../Avatar/styles';
import ImageCropper from './Cropper';
import { useStyles, EditAvatar } from './styles';

export default function AvatarEditor({
  size,
  profile,
  image,
  setImage,
  editImage,
  setEditImage,
  selectedFile,
  setSelectedFile,
  setShowImageSizeWarningText,
  cropShape,
  aspect
}) {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(null);
  const [saving, setSaving] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getProfileRequest } = UserCreators;

  const onFileChange = async (e) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      if (files[0].size <= 10000000) {
        const imageDataUrl = await readFile(files[0]);
        setImage(imageDataUrl);
        setShowImageSizeWarningText(false);
      } else {
        setShowImageSizeWarningText(true);
      }
    }
  };

  const handleClickBackButton = () => {
    profile ? setImage(null) : setEditImage(false);
  };

  async function putAvatar(data) {
    try {
      await api.put(`/api/users/${profile.id}`, data);
      setSaving(false);
      setImage(null);
      dispatch(getProfileRequest());
    } catch (e) {
      alert('Opa, houve algum erro ao carregar a imagem, tente novamente!');
    } finally {
      setSaving(false);
      setImage(null);
    }
  }

  async function handleClickSaveButton() {
    try {
      setSaving(true);
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      const file = dataURLtoFile(
        croppedImage,
        selectedFile ? selectedFile.name : `avatar.jpeg`
      );
      const data = new FormData();
      data.append('user[avatar]', file);
      profile?.id ? putAvatar(data) : setSelectedFile(file);
    } catch (e) {
      setSaving(false);
    }

    profile?.id ? setImage(null) : setEditImage(false);
  }

  return (
    <>
      {profile || editImage ? (
        <>
          {profile && (
            <EditAvatar
              className={classes.editAvatarHover}
              profile={profile}
              size={size}
              handleProfilePhotoChange={onFileChange}
            />
          )}
          {image ? (
            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open
              aria-labelledby="server-modal-title"
              aria-describedby="server-modal-description"
              className={classes.modal}
            >
              <Box className={classes.modalContent}>
                <Box className={classes.modalHeader}>
                  <h2 className={classes.modalTitle}>
                    <MdOutlineCrop className={classes.cropIcon} />
                    Cortar Imagem
                  </h2>
                  <IconButton
                    onClick={handleClickBackButton}
                    className={classes.closeIcon}
                  >
                    <MdClose />
                  </IconButton>
                </Box>
                <Box className={classes.cropperContainer}>
                  <ImageCropper
                    imageSrc={image}
                    setCroppedAreaPixels={setCroppedAreaPixels}
                    setRotation={setRotation}
                    cropShape={cropShape}
                    aspect={aspect}
                  />
                </Box>
                {saving && (
                  <Box className={classes.progressContainer}>
                    <LinearProgress />
                  </Box>
                )}
                <Box className={classes.buttonsContainer}>
                  <Button
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={handleClickBackButton}
                    disabled={saving}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={handleClickSaveButton}
                    disabled={saving}
                  >
                    Cortar imagem
                  </Button>
                </Box>
              </Box>
            </Modal>
          ) : (
            false
          )}
        </>
      ) : (
        <ShimmerCircle size={size} />
      )}
    </>
  );
}

AvatarEditor.propTypes = {
  size: PropTypes.number,
  profile: PropTypes.shape(),
  image: PropTypes.string,
  setImage: PropTypes.func,
  setShowImageSizeWarningText: PropTypes.func
};

AvatarEditor.defaultProps = {
  size: 90,
  profile: null,
  image: null,
  setImage: null,
  setShowImageSizeWarningText: null
};
