import base from './base';
const baseUrl = 'https://api.invar.redu.digital';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfk1zv8mqr';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'ddc6DKpqtxZLHbsAoCYaF6mxVLlj24DEf1EFv5Nirxbz',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://api.invar.redu.digital:44443',
  clarityKey
};
