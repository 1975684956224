import React, { useState, useEffect, useMemo } from 'react';
import ReactLoading from 'react-loading';
import { useParams, useHistory } from 'react-router-dom';

import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import LectureDependencyAlert from '~/components/molecules/LectureDependencyAlert';
import LectureExpiredAlert from '~/components/molecules/LectureExpiredAlert';
import AppbarPages from '~/components/templates/AppbarPages';
import i18n from '~/I18n';
import api from '~/services/api';

import DocumentLecture from './DocumentLecture';
import DownloadDocumentButton from './DownloadDocumentButton';
import ExerciseLecture from './ExerciseLecture';
import FormLecture from './FormLecture';
import { LectureDirectionButton } from './LectureDirectionButton';
import { ListLecturesInMobile } from './ListLecturesInMobile';
import { MarkAsDoneButton } from './MarkAsDoneButton';
import { Mural } from './Mural';
import { NextLectures } from './NextLectures';
import PageLecture from './PageLecture';
import { useStyles } from './styles';
import VideoLecture from './VideoLecture';
import {
  Box,
  Tooltip,
  useTheme,
  Typography,
  useMediaQuery
} from '@material-ui/core';

// import SupportMaterials from '~/components/molecules/LectureSupportMaterials'; -> Comentando enquanto o material de apoio não esta sendo utilizado.

const renderLectureContentMap = {
  Document: DocumentLecture,
  Exercise: ExerciseLecture,
  Form: FormLecture,
  Seminar: VideoLecture,
  Page: PageLecture
};

export default function Lecture() {
  const theme = useTheme();
  const classes = useStyles();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const history = useHistory();
  const { id } = useParams();

  const [lecture, setLecture] = useState(null);
  const [ErrorLectureComponent, setErrorLectureComponent] = useState(null);

  const canFinish = useMemo(() => lecture?.can_read && !lecture?.can_manage, [
    lecture
  ]);
  const canDownload = useMemo(
    () => lecture?.type === 'Document' && lecture?.can_download,
    [lecture]
  );
  const mainAreaIsExtended = useMemo(
    () => lecture?.lectureable?.forum || !isLgUp,
    [lecture, isLgUp]
  );

  const breadcrumb = useMemo(
    () =>
      lecture && [
        { name: i18n.t('MyEnvironments'), path: '/dashboard/environments' },
        {
          name: lecture.environment_name || '...',
          path: `/dashboard/environments/${lecture.environment_id}`
        },
        {
          name: lecture.course_name,
          path: `/dashboard/environments/${lecture.environment_id}/courses/${lecture.course_id}`
        },
        {
          name: lecture.space_name,
          path: `/dashboard/spaces/${lecture.space_id}`,
          state: {
            environmentName: lecture.environment_name || '...',
            environmentPath: `/dashboard/environments/${lecture.environment_id}`
          }
        },
        { name: `${lecture.name}`, path: '#' }
      ],
    [lecture]
  );

  const LectureComponent =
    renderLectureContentMap[lecture?.type] || React.Fragment;

  async function handleGetLecture() {
    try {
      const response = await api.get(`/api/lectures/${id}`);
      setLecture(response.data);
    } catch (e) {
      if (e.response && e.response.status === 404) {
        history.push('/page-not-found');
      } else if (e.response && e.response.status === 401) {
        e.response.data.lecture_dependency &&
          setErrorLectureComponent(
            <LectureDependencyAlert
              lastLecture={e.response.data.last_lecture}
            />
          );
        e.response.data.expired &&
          setErrorLectureComponent(
            <LectureExpiredAlert
              expirationDate={e.response.data.expiration_date}
            />
          );
        setLecture(e.response.data);
      }
    }
  }

  useEffect(() => {
    handleGetLecture();
    return () => {
      setLecture(null);
    };
  }, [id]);

  useEffect(() => {
    if (lecture) {
      document.title = `${theme.title} | ${i18n.t(
        'StructuresName.Lectures.SingularUpper'
      )} "${lecture.name}"`;
    } else {
      document.title = theme.title;
    }
  }, [lecture]);

  function renderLecture() {
    if (ErrorLectureComponent) return ErrorLectureComponent;

    return <LectureComponent lecture={lecture} />;
  }

  return (
    <>
      {!lecture ? (
        <Box className={classes.loadingPageContainer}>
          <ReactLoading type="bars" color={theme.palette.primary.main} />
        </Box>
      ) : (
        <AppbarPages>
          <Box className={classes.root}>
            <Box className={classes.nextLecturesPaper} elevation={3}>
              <NextLectures lecture={lecture} />
            </Box>
            <ListLecturesInMobile>
              <NextLectures lecture={lecture} />
            </ListLecturesInMobile>

            <Box className={classes.mainAreaContainer} id="lecture-main-area">
              <Box className={classes.mainArea}>
                <Box className={classes.infos}>
                  <DashboardBreadcrumbs links={breadcrumb} />
                  <Box
                    className={classes.headerLectureDataContainer}
                    display={{ xs: 'block', md: 'flex' }}
                  >
                    <Box className={classes.nameLectureBox}>
                      <Tooltip
                        title={lecture.name}
                        arrow
                        placement="bottom"
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <Typography className={classes.nameLecture}>
                          {lecture.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      className={classes.headerLectureButtonsContainer}
                      width={{ xs: '100%', md: 'min-content' }}
                      margin={{ xs: '8px 0px 0px 0px', md: '0px' }}
                    >
                      {canFinish && <MarkAsDoneButton lecture={lecture} />}
                      {canDownload && (
                        <DownloadDocumentButton lecture={lecture} />
                      )}
                    </Box>
                  </Box>
                  <LectureDirectionButton navigation={lecture.navigation} />
                </Box>
                <Box className={classes.lectureDataContainer}>
                  {renderLecture()}
                </Box>
                {lecture.mural_enabled && mainAreaIsExtended && (
                  <Mural lecture={lecture} extendedWall={true} />
                )}
              </Box>
            </Box>

            {lecture.mural_enabled && !mainAreaIsExtended && (
              <Box elevation={3} className={classes.muralPaper}>
                <Mural lecture={lecture} />
              </Box>
            )}
          </Box>
        </AppbarPages>
      )}
    </>
  );
}
