import React from 'react';
import Arrow from '@material-ui/icons/ArrowUpward';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';

export default function UploadIcon() {
  const arrowStyle = {
    fontSize: '24px', 
    marginBottom: '-10px', 
    backgroundColor: "#c9c9cc", 
    borderRadius: "50%", 
    zIndex: 1,
    padding: "2px",
    color: 'white'
  }

  const fileStyle = {
    fontSize: '42px', 
    marginLeft: '-22px', 
    zIndex: 0,
    color: '#c9c9cc'
  }

  return(
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Arrow style={arrowStyle}/>
      <FileIcon style={fileStyle}/>
    </div>
  );
}
