import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 4px'
  },

  buttonText: {
    color: 'white',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  buttonIcon: {
    color: 'white',
    fontSize: '24px'
  }
}));
