import React, { useState } from 'react';

import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import AppBarCustom from '~/components/molecules/AppBar';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export default function Page({ children, noAppbar, noLeftDrawer }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const classes = useStyles({ theme, noAppbar });

  function toggleDrawer() {
    setOpen(!open);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root}>
        {!noAppbar && <AppBarCustom setOpen={toggleDrawer} />}
        {!noLeftDrawer && <LeftDrawer open={open} setOpen={toggleDrawer} />}
        <Box className={classes.content}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
}

Page.propTypes = {
  children: PropTypes.shape(),
  noAppbar: PropTypes.bool,
  noLeftDrawer: PropTypes.bool
};

Page.defaultProps = {
  children: null,
  noAppbar: false,
  noLeftDrawer: false
};
