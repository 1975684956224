import React from 'react';
import { Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import { Box, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

export default function FileProcessingAlert() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <ReportOutlinedIcon
          style={{ width: '56px', height: '56px' }}
          color="primary"
        />

        <Text
          variant="largeText"
          className={classes.largeText}
          style={{ fontWeight: 500 }}
        >
          Seu arquivo está sendo processado e dentro de instantes estará pronto.
        </Text>

        <Text variant="text" className={classes.text}>
          Caso demore mais do que 24hrs,{' '}
          <Link to={`/help`} rel="noopener" className={classes.link}>
            entre em contato pelo Fale Conosco
          </Link>{' '}
          para que possamos melhorar a qualidade do serviço.
        </Text>
      </Box>
    </Box>
  );
}
