import React, { useEffect, useState } from 'react';

import MuralTemplate from '~/components/templates/Mural';
import api from '~/services/api';

import PropTypes from 'prop-types';

export default function MuralProfile({ userId }) {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  function getMuralProfile() {
    if (userId) {
      api
        .get(`api/users/${userId}/my_wall`, {
          params: {
            page: page
          }
        })
        .then((response) => {
          if (response.data.length < 10) {
            setHasMore(false);
          }
          if (response.data.length > 0) {
            setStatuses([...statuses, ...response.data]);
            setPage(page + 1);
          }
        })
        .catch(() => {
          setHasMore(false);
        });
    }
  }

  useEffect(() => {
    getMuralProfile();
  }, []);

  return (
    <MuralTemplate
      loading={loading}
      statuses={statuses}
      hasMore={hasMore}
      getStatuses={getMuralProfile}
      isDashboard
      setStatuses={setStatuses}
    />
  );
}

MuralProfile.propTypes = {
  userId: PropTypes.string
};

MuralProfile.defaultProps = {
  userId: ''
};
