import React from 'react';

import { Box, useTheme } from '@material-ui/core';

import Text from '~/components/atoms/Text';

import TextBox from '../TextBox';
import { useStyles } from './styles';
import i18n from '~/I18n';
import settings from '~settings';

const Accessibility = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const client = settings.name;
  const platformName =
    client === 'AVAMEC Interativo' ? 'AVAMEC Interativo' : 'Redu';

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Text color="primary" variant="ppTitle">
          {i18n.t('Accessibility')}
        </Text>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.subtitle}>
          <Text color="primary" variant="lg" style={{ fontWeight: '500' }}>
            Leitor de tela
          </Text>
        </Box>
        <TextBox>
          A Plataforma {platformName} foi desenvolvida e codificada segundo os
          Padrões Web regulamentados pelo W3C para que os leitores de tela ou
          qualquer outra tecnologia assistiva funcione corretamente. Leitores de
          tela são softwares de tecnologia assistiva que através de um
          sintetizador de voz ou de um display Braille lê ou ou dispõe em
          Braille todo o texto que estiver presente na tela do computador.
          Recomendamos o software gratuito NVDA (
          <a
            className={classes.link}
            href="https://www.nvaccess.org/"
            title="Nv Access"
            target="_blank"
          >
            {`Nv Access`}
          </a>
          ).
        </TextBox>
        <Box className={classes.subtitle}>
          <Text color="primary" variant="lg" style={{ fontWeight: '500' }}>
            Libras
          </Text>
        </Box>
        <TextBox>
          Possuímos integração com o V-Libras, uma ferramenta gratuita e de
          código aberto que traduz conteúdos digitais (texto, áudio e vídeo) em
          Português para Libras, tornando computadores, celulares e plataformas
          Web mais acessíveis para as pessoas surdas.
        </TextBox>
      </Box>
    </Box>
  );
};

export default Accessibility;
