import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
    overflow: 'hidden'
  },
  userName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#fff'
  },
  userLogin: {
    fontSize: '14px',
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff'
  }
});
