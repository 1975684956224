import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
    borderRadius: '8px',
    border: '1px solid #E1E1E5',
    height: '265px',
    minHeight: '265px',
    maxHeight: '265px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      height: '160px',
      minHeight: '160px',
      maxHeight: '160px'
    }
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%'
  },
  image: {
    width: '100%',
    minWidth: '100%',
    height: '160px',
    minHeight: '160px',
    maxHeight: '160px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '128px',
      maxWidth: '128px',
      minWidth: '128px',
      height: '100%'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px',
    overflow: 'hidden',
    width: '100%'
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.activeText.main,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: theme.palette.activeText.main,
    fontSize: '16px',
    lineHeight: '24px'
  },
  customTooltip: {
    fontSize: '14px',
    lineHeight: '24px',
    maxWidth: 'fit-content',
    backgroundColor: '#676D82'
  }
}));
