export const setStructurePath = (item) => {
  const pathMap = {
    Environment: `/app/dashboard/environments/${item.id}`,
    Course: `/app/dashboard/environments/${item.environment_id}/courses/${item.id}`,
    Space: `/app/dashboard/spaces/${item.id}`,
    Subject: `/app/dashboard/spaces/${item.space_id}`
  };
  return pathMap[item.type];
};

export const setEditStructurePath = (item) => {
  const pathMap = {
    Environment: `/app/dashboard/environments/${item.id}/edit`,
    Course: `/app/dashboard/environments/${item.environment_id}/courses/${item.id}/edit`,
    Space: `/app/dashboard/environments/${item.environment_id}/courses/${item.course_id}/spaces/${item.id}/edit`,
    Subject: `/app/dashboard/environments/${item.environment_id}/courses/${item.course_id}/spaces/${item.space_id}/subjects/${item.id}/edit`
  };
  return pathMap[item.type];
};

export const setCreateMirrorPath = (item) => {
  const pathMap = {
    Environment: `/app/dashboard/environments/new_mirror_environment`,
    Course: `/app/dashboard/environments/${item.environment_id}/new_mirror_course`,
    Space: `/app/dashboard/courses/${item.course_id}/new_mirror_space`,
    Subject: `/app/dashboard/spaces/${item.space_id}/new_mirror_subject`
  };
  return pathMap[item.type];
};
