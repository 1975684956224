import React from 'react';

import settings from '~settings';

import { useSpacePageContext } from '../../contexts/SpacePageContext';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Image = () => {
  const { space } = useSpacePageContext();

  const image =
    space &&
    space.thumbnails &&
    space.thumbnails.length > 0 &&
    space.thumbnails[0].href
      ? space.thumbnails[0].href
      : null;

  return (
    <Box
      style={{
        marginTop: 32
      }}
    >
      <img src={image && `${settings.baseUrl}${image}`} />
    </Box>
  );
};

Image.propTypes = {
  space: PropTypes.shape({
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired
      })
    )
  })
};
