import api from '~/services/api';

const baseURL = '/api/mirroring_list/environments';

export const getSelf = async (page) => {
  const { data } = await api.get(`${baseURL}?page=${page}`);
  return data;
};

export default {
  getSelf
};
