import React, { useState, useEffect } from 'react';

import { useTheme, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import { CustomAvatar } from '~/components/molecules/MuralItem/styles';
import i18n from '~/I18n';
import api from '~/services/api';

import {
  Container,
  ContainerCard,
  ContainerElements,
  ContentCard,
  PaperContentCard,
  ContainerInfo,
  TimeFormated,
  ActionsCard,
  InfoName,
  useStyles
} from './style';

function FriendshipRequest({ list }) {
  const [requestsList, setRequestsList] = useState([]);
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    setRequestsList(list);
  }, [list]);

  function removeRequestFromList(requestId) {
    const newList = requestsList.filter((request) => request.id !== requestId);
    setRequestsList(newList);
  }

  async function acceptConnection(id) {
    try {
      const response = await api.put(`/api/connections/${id}`);
      removeRequestFromList(id);
    } catch (error) { }
  }

  async function rejectConnection(id) {
    try {
      const response = await api.delete(`/api/connections/${id}`, {
        connection: id
      });
      removeRequestFromList(id);
    } catch (error) { }
  }

  return (
    <Container className={classes.container}>
      {requestsList &&
        requestsList.map((item) => {
          const { contact, requested_at } = item || {};
          const { first_name, last_name } = contact || {};
          return (
            <ContainerCard className={classes.containerCard} key={item.id}>
              <ContainerElements>
                <ContentCard style={{ flex: 1 }}>
                  <PaperContentCard>
                    <div className={classes.lgAvatar}>
                      <CustomAvatar profile={contact} size={50} />
                    </div>
                    <div className={classes.smAvatar}>
                      <CustomAvatar profile={contact} size={40} />
                    </div>
                    <ContainerInfo>
                      <InfoName>
                        <Text color="gray" variant="friendshipRequest">
                          <span
                            style={{
                              color: theme.palette.accent.main,
                              paddingRight: 4
                            }}
                          >
                            {`${first_name} ${last_name}`}
                          </span>
                          {i18n.t('friendshipRequestText')}
                        </Text>
                      </InfoName>
                      <TimeFormated date={requested_at} />
                    </ContainerInfo>
                  </PaperContentCard>
                </ContentCard>
                <ActionsCard className={classes.lgButtonsContainer}>
                  <ButtonSquare
                    isFriendshipRequest
                    width="100px"
                    height="50px"
                    variant="outlined"
                    color={theme.palette.secondary.main}
                    border={`0.5px solid ${theme.palette.secondary.main}`}
                    onClick={() => {
                      rejectConnection(item.id);
                    }}
                  >
                    <Text variant="friendshipRequestButton" color="secondary">
                      {i18n.t('Refuse')}
                    </Text>
                  </ButtonSquare>
                  <ButtonSquare
                    isFriendshipRequest
                    width="100px"
                    height="50px"
                    backgroundColor={theme.palette.secondary.main}
                    onClick={() => {
                      acceptConnection(item.id);
                    }}
                  >
                    <Text variant="friendshipRequestButton" color="white">
                      {i18n.t('Accept')}
                    </Text>
                  </ButtonSquare>
                </ActionsCard>
                <ActionsCard>
                  <Button
                    className={classes.smButtonCancel}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      rejectConnection(item.id);
                    }}
                  >
                    <CloseIcon
                      style={{
                        fill: theme.palette.secondary.main,
                        fontSize: 16
                      }}
                    />
                  </Button>
                  <Button
                    className={classes.smButtonCheck}
                    classes={{ root: classes.smButton }}
                    onClick={() => {
                      acceptConnection(item.id);
                    }}
                  >
                    <CheckIcon style={{ fill: 'white', fontSize: 16 }} />
                  </Button>
                </ActionsCard>
              </ContainerElements>
            </ContainerCard>
          );
        })}
    </Container>
  );
}

FriendshipRequest.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape)
};

FriendshipRequest.defaultProps = {
  list: {}
};

export default FriendshipRequest;
