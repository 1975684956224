import React from 'react';
import { Link } from 'react-scroll';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },

    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },

    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      bordeRadius: '2px'
    }
  },
  menuItem: {
    width: 'auto',
    paddingLeft: '5px',
    marginBottom: '10px'
  },
  menu: {
    backgroundColor: 'white',
    paddingLeft: '50px',
    paddingTop: '30px'
  },
  content: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row',
    paddingTop: '90px'
  },
  rightContent: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100vw'
  },
  contentContainer: {
    marginLeft: '5%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '10%'
    }
  },
  active: {
    color: theme.palette.primary.main,
    opacity: 1
  },
  link: {
    fontSize: 14,
    cursor: 'pointer'
  },
  title: {
    marginBottom: 100
  },
  subtitle: { marginTop: 5, marginBottom: 5 },
  sidenavContainer: {
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  editPhotoDesktop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 72,
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  editPhotoTabletMobile: {
    display: 'none',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
    '@media (max-width: 1024px)': {
      display: 'flex'
    }
  }
}));

export const Logo = styled.img`
  width: 67px;
  height: 37px;
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  p {
    margin-bottom: 2.5rem;
    font-size: 1.3rem;
    color: #707070;
  }

  h1 {
    color: #1a72f9;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: #1a72f9;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }
`;

export const LinkItem = styled(({ ...otherProps }) => {
  const classes = useStyles();
  return (
    <Link
      {...otherProps}
      activeClass={classes.active}
      className={classes.link}
      spy
      smooth
      duration={500}
    />
  );
})``;
