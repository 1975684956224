import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import GenericFormWrapper from '~/components/organisms/GenericFormWrapper';
import SpaceForm from '~/components/organisms/SpaceForm';
import StepperForm from '~/components/templates/StepperForm';
import i18n from '~/I18n';
import { handleSuccess } from '~/utils/auxiliaryFunctions';
import { spaceValidations } from '~/utils/SpaceValidation/yup';

import { createSpace } from './fetch';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function CreateSpace() {
  const theme = useTheme();
  const { courseID, environmentID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSubmissionStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultValues = {
    id: null,
    name: '',
    initials: '',
    turn: '',
    description: '',
    dependent_subjects: false,
    order_subjects_by: 'created_at',
    keywords: [],
    mural_access: '111'
  };

  const navOptions = [i18n.t('Information')];
  const successMessage = `A ${i18n.t(
    'StructuresName.Space.SingularLower'
  )} foi criada!`;
  const callbackSuccessPath = `/dashboard/environments/${environmentID}/courses/${courseID}/`;

  const breadcrumbs = [
    {
      name: i18n.t('MyEnvironments'),
      path: '/dashboard/environments'
    },
    {
      name:
        location?.state?.environmentName ||
        i18n.t('StructuresName.Environment.SingularUpper'),
      path: `/dashboard/environments/${environmentID}`
    }
  ];

  const renderContent = (props) => {
    const { selectedTab } = props;

    if (selectedTab === 0) {
      return (
        <>
          <GenericFormWrapper
            Form={SpaceForm}
            defaultValues={defaultValues}
            resolver={spaceValidations}
            isSubmitting={isSubmitting}
            {...props}
          />
          ;
        </>
      );
    }

    return false;
  };

  const handleCancel = () => {
    history.push(
      `/dashboard/environments/${environmentID}/courses/${courseID}/`
    );
  };

  const finalizeSuccess = () => {
    setSubmissionStatus('success');
    setIsSubmitting(false);
    handleSuccess(
      successMessage,
      enqueueSnackbar,
      callbackSuccessPath,
      history
    );
  };

  const finalizeFailure = () => {
    setIsSubmitting(false);
    enqueueSnackbar('Não foi possível criar esta disciplina', {
      variant: 'error'
    });
    setSubmissionStatus('error');
  };

  function submitForm(fields) {
    var formattedTagList = fields?.keywords
      ?.map((keyword) => keyword.name)
      .join(',');
    delete fields.keywords;

    var body = { space: { ...fields, tag_list: formattedTagList } };
    setIsSubmitting(true);
    createSpace(courseID, body)
      .then(finalizeSuccess)
      .catch(finalizeFailure);
  }

  useEffect(() => {
    document.title = `${theme.title} | Criar ${i18n.t(
      'StructuresName.Space.SingularLower'
    )}`;
  }, []);

  return (
    <StepperForm
      navOptions={navOptions}
      breadcrumbs={breadcrumbs}
      onSubmit={submitForm}
      formTitle={i18n.t('CreateSpace.title')}
      submitButtonTitle={i18n.t('CreateSpace.saveButton')}
      handleCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}
