import base from './base';
const baseUrl = 'https://v1.comunidade.movimentaeducacao.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjxpd4bf9';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'O1aRI6dE626VfHFvc0hoZ0TSCWtnXdFz7pp9qfZAnOqE',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://v1.comunidade.movimentaeducacao.com.br:44443',
  clarityKey
};
