import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import LinkField from './LinkField';
import { useStyles } from './styles';
import { Collapse, Grid, Switch } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
export default function ShareContentSpace({ spaceID }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow((prev) => !prev);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.container}
    >
      <Grid item>
        <Text variant="lgTitle" color="primary">
          {' '}
          Compartilhar {`${i18n.t('StructuresName.Space.SingularUpper')}`}{' '}
        </Text>
      </Grid>

      <Grid item>
        <Text variant={'text'} color={'gray'} textAlign={'justify'}>
          Gere um link de acesso para sua{' '}
          {`${i18n.t('StructuresName.Space.SingularLower')}`} e compartilhe com
          seus alunos. O acesso pode ser via link URL ou QR Code.
        </Text>
      </Grid>

      <Grid container item alignItems="center" spacing={1}>
        <Grid item style={{ marginLeft: '-10px' }}>
          <Switch onChange={toggleShow} />
        </Grid>
        <Grid item>
          <Text variant={'mediumText'} style={{ margin: 0 }} color={'gray'}>
            Exibir link de acesso
          </Text>
        </Grid>
      </Grid>

      <Collapse in={show}>
        <LinkField spaceID={spaceID} />
      </Collapse>
    </Grid>
  );
}
