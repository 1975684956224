import React, { useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme, useMediaQuery } from '@material-ui/core';

import TourModal1 from '~/components/molecules/TourModal1';
import TourModalDefault from '~/components/molecules/TourModalDefault';
import { Creators as UserActions } from '~/store/ducks/user';

import { modalTexts } from './texts';
import i18n from '~/I18n';

export default function Tour() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hiddenDrawer = useMediaQuery('@media (max-width: 1190px)');
  const hiddenButtons = useMediaQuery('@media (max-width: 600px)');

  const { seenTour } = useSelector((state) => state.user);
  const { setSeenTour } = UserActions;

  const [curStep, setCurStep] = useState(0);

  const steps = [
    {
      content: <TourModal1 />,
      placement: 'center',
      target: 'body',
      showSkipButton: true,
      locale: { next: 'Fazer tour', skip: 'Pular' },
      styles: {
        tooltip: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        },
        tooltipFooter: {
          justifyContent: 'center',
          width: '210px'
        }
      },
    },
    {
      content: <TourModalDefault text={modalTexts[2]} />,
      target: '.next-lectures-tour',
      styles: {
        spotlight: {
          paddingBottom: 0,
        },
      },
    },
    {
      content: <TourModalDefault text={modalTexts[3]} />,
      target: '.murals-tour',
      locale: { next: hiddenButtons ? 'Finalizar' : 'Continuar' },
    },
    {
      content: <TourModalDefault text={modalTexts[4]} />,
      target: '.right-drawer-tour'
    },
    {
      content: <TourModalDefault text={modalTexts[5]} />,
      target: '.float-buttons-tour'
    }
  ];

  function stepCallback(CallBackProps) {
    const { status, action, type, index } = CallBackProps;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      let addAmt = 1;

      if (curStep === 2 && hiddenDrawer) addAmt = 2;
      else if (curStep === 3 && hiddenButtons) addAmt = 2;

      setCurStep(index + (action === ACTIONS.PREV ? -addAmt : addAmt));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setSeenTour());
    }
  }

  return (
    <Joyride
      run={!seenTour}
      steps={steps}
      stepIndex={curStep}
      callback={stepCallback}
      continuous
      hideBackButton
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      locale={{ next: i18n.t('Continue'), last: i18n.t('End')}}
      styles={{
        options: {
          width: 'auto',
        },
        tooltip: {
          padding: 30,
        },
        buttonClose: {
          display: 'none',
        },
        buttonNext: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          backgroundColor: theme.palette.secondary.main,
          fontSize: 15,
          height: 50,
          width: 100,
          borderRadius: 5,
        },
        buttonSkip: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          border: `1px solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
          fontSize: 15,
          height: 50,
          width: 100,
          borderRadius: 5,
        },
        tooltipContent: {
          padding: 0,
        },
        overlay: {
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          maxHeight: '100vh'
        },
        spotlight: {
          borderRadius: 10,
        },
      }}
    />
  );
}
