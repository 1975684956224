import React from 'react';
import { useLocation } from 'react-router-dom';

import Callback from '../Callback';

export const SebraeLoginRedirect = () => {
  const code = new URLSearchParams(useLocation().search).get('code');
  const token = new URLSearchParams(useLocation().search).get('t');

  return <>{!!code && <Callback code={code} courseToken={token} />}</>;
};
