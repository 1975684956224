import React from 'react';

import {
  LectureMuralContextProvider,
  useLectureMuralContext
} from '~/contexts/LectureMuralContext';

import { AttachmentsDashboard } from './AttachmentsDashboard';
import { DiscussionBoard } from './DiscussionBoard';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const MuralContent = () => {
  const classes = useStyles();
  const { contentDisplay, extendedWall } = useLectureMuralContext();
  return (
    <Box padding={extendedWall ? '0 0 32px 0' : 0} className={classes.root}>
      {contentDisplay === 'discussion' && <DiscussionBoard />}
      {contentDisplay === 'attachments' && <AttachmentsDashboard />}
    </Box>
  );
};

export const Mural = ({ lecture, extendedWall }) => {
  return (
    <LectureMuralContextProvider lecture={lecture} extendedWall={extendedWall}>
      <MuralContent />
    </LectureMuralContextProvider>
  );
};

Mural.propTypes = {
  lecture: PropTypes.object.isRequired,
  extendedWall: PropTypes.bool
};
