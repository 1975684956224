import React from 'react';
import { Link } from 'react-router-dom';

import { NextLectureIcon, PreviousLectureIcon } from '~/components/atoms/Icons';

import { useStyles } from './styles';
import { Box, ButtonBase, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const LectureDirectionButton = ({ navigation }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Box className={classes.directionButtonContainer}>
      <Box>
        {navigation.previous && (
          <Link
            className={classes.directionLink}
            to={`/dashboard/lecture/${navigation.previous}`}
            style={{ paddingRight: '16px' }}
            component={ButtonBase}
          >
            <PreviousLectureIcon className={classes.icon} />
            <Typography className={classes.directionLinkText}>
              Anterior
            </Typography>
          </Link>
        )}
      </Box>
      <Box>
        {navigation.next && (
          <Link
            className={classes.directionLink}
            to={`/dashboard/lecture/${navigation.next}`}
            style={{ paddingLeft: '16px' }}
            component={ButtonBase}
          >
            <Typography className={classes.directionLinkText}>
              Próxima
            </Typography>
            <NextLectureIcon className={classes.icon} />
          </Link>
        )}
      </Box>
    </Box>
  );
};

LectureDirectionButton.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.number,
    next: PropTypes.number
  }).isRequired
};
