import api from '~/services/api';

const baseURL = '/api/inovaula';

export const getExerciseLists = async (page = 1) => {
  const { data } = await api.get(baseURL + '/lists' + `?page=${page}`);

  return data;
};

export const importExercise = async (subjectID, collectionID) => {
  const { data } = await api.post(baseURL + '/exercises', {
    subject_id: subjectID,
    collection_id: collectionID
  });

  return data;
};

export default {
  getExerciseLists,
  importExercise
};
