import api from '~/services/api';

const baseURL = '/api/mirroring';

export const create = async (id, payload, parentID) => {
  await api.post(`${baseURL}/courses/${parentID}/spaces`, {
    replicable_id: id,
    space: {
      ...payload
    }
  });
};

export const unlink = async (id) => {
  await api.put(`${baseURL}/spaces/${id}/unlink`);
};

export default { create, unlink };
