import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  rootContactUs: {
    position: 'absolute',
    width: 350,
    maxHeight: '70vh',
    paddingBottom: 22,
    zIndex: 20,
    right: 120,
    bottom: 0,
    overflow: 'auto',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.primary.main}`,
      bordeRadius: '2px',
    },
  },
  header: {
    backgroundColor: '#eaeaea',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingBottom: 22,
  },
  buttonArea: {
    padding: 2,
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  info: {
    padding: '0 30px',
    paddingTop: 30,

    '& a': {
      color: (props) => props.theme.palette.accent.main,
    },
  },
  closeButton: {
    minWidth: 0,
    padding: 5,
    position: 'fixed',

    borderRadius: '50%',
  },
  closeIcon: {
    color: (props) => props.theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  form: {
    padding: '22px 22px 0 22px',
  },
});
