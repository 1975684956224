import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getInformationListReport } from '~/services/reports/spaces';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function InformationListReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getInformationListReport}
        baseName="listagem_dos_módulos_da_disciplina_"
        id={id}
      />
    </div>
  );
}

InformationListReport.propTypes = {
  id: PropTypes.string
};

InformationListReport.defaultProps = {
  id: ''
};
