import React from 'react';

import backgroundImage from '~/assets/images/Background-about.png';

import AboutText from './AboutText';
import Footer from './Footer';
import HeaderBar from './HeaderBar';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';

export default function About() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      width="100vw"
      minWidth={'min-content'}
    >
      <HeaderBar />
      <Box
        className={classes.content}
        style={{
          backgroundImage: `url(${backgroundImage}`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box maxWidth={'948px'} marginBottom={'200px'}>
          <AboutText />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
