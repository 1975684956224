import React from 'react';

import { ListItem, makeStyles } from '@material-ui/core';
import styled from 'styled-components';


export const ListItemCustom = styled(({ ...otherProps }) => (
  <ListItem
    {...otherProps}
    button
    disableGutters
  />
))``;

export const useStyles = makeStyles(() => ({
  textMenuItem: {
    marginLeft: '15px',
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  }
}));
