import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf';

import FileProcessingAlert from '~/components/molecules/FileProcessingAlert';
import { usePdf } from '~/pages/Lecture/usePdf';

import { scaleOptions } from './constants';
import MoreOptionsMenu from './MoreOptionsMenu';
import PresentationVisualization from './PresentationVisualization';
import ScaleMenu from './ScaleMenu';
import SearchMenu from './SearchMenu';
import SinglePageVisualization from './SinglePageVisualization';
import { useStyles } from './styles';
import TwoPagesVisualization from './TwoPagesVisualization';
import {
  Box,
  CircularProgress,
  useTheme,
  ButtonBase,
  Typography,
  InputBase
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RemoveIcon from '@material-ui/icons/Remove';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import { debounce } from 'debounce';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function DocumentLecture({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { enqueueSnackbar } = useSnackbar();
  const { pdfData, error } = usePdf(lecture);

  const [currentPage, setCurrentPage] = useState(1);
  const [documentTotalPages, setDocumentTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState(1.0);
  const [rotateValue, setRotateValue] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [visualizationMode, setVisualizationMode] = useState('default');
  const [pageDimensions, setPageDimensions] = useState({
    width: 0,
    height: 0
  });
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [totalFoundInSearch, setTotalFoundInSearch] = useState(0);
  const [currentSearchResult, setCurrentSearchResult] = useState(0);
  const [indexCurrentVisualization, setIndexCurrentVisualization] = useState(1);
  const [maxScaleX, setMaxScaleX] = useState(0);
  const [documentContentHeight, setDocumentContentHeight] = useState(0);

  const nextPageButtonRef = useRef(null);
  const previousPageButtonRef = useRef(null);
  const fullVisualizationComponentRef = useRef(null);
  const singlePageRefsArray = useRef([]);
  const twoPagesRefArray = useRef([]);
  const contentDocumentRef = useRef(null);
  const resultsFoundInTheSearch = useRef([]);
  const [searchString, setSearchString] = useState('');

  const customTextRenderer = useCallback(
    ({ obj }) => {
      if (searchString.length > 0) {
        const parts = obj.str.split(new RegExp(`(${searchString})`, 'gi'));
        const newParts = parts.map((part, index) => {
          return part.toLowerCase() === searchString.toLowerCase() ? (
            <mark
              key={index}
              ref={(el) => {
                if (el) {
                  resultsFoundInTheSearch.current.push(el);
                }
              }}
            >
              {part}
            </mark>
          ) : (
            <span key={index}>{part}</span>
          );
        });
        debounceSetTotalFoundInTheSearch();
        return newParts;
      }
    },
    [searchString]
  );

  const debounceSetTotalFoundInTheSearch = debounce(() => {
    const position = resultsFoundInTheSearch.current.length > 0 ? 1 : 0;
    setCurrentSearchResult(position);
    sortElementsByVerticalPosition();
    scrollToElement(position, resultsFoundInTheSearch);
    if (position)
      resultsFoundInTheSearch.current[
        currentSearchResult
      ].style.backgroundColor = 'orange';
    setTotalFoundInSearch(resultsFoundInTheSearch.current.length);
    setLoadingSearch(false);
  }, 500);

  function sortElementsByVerticalPosition() {
    const elementosOrdenados = resultsFoundInTheSearch.current
      .slice()
      .sort((elementoA, elementoB) => {
        const rectA = elementoA.getBoundingClientRect();
        const rectB = elementoB.getBoundingClientRect();

        return rectA.top - rectB.top;
      });

    resultsFoundInTheSearch.current = elementosOrdenados;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setDocumentTotalPages(numPages);
    const pagePairs = numPages % 2 === 0 ? numPages / 2 : (numPages + 1) / 2;
    twoPagesRefArray.current = Array(pagePairs)
      .fill(null)
      .map((_, i) => twoPagesRefArray.current[i] || React.createRef());
    singlePageRefsArray.current = Array(numPages)
      .fill(null)
      .map((_, i) => singlePageRefsArray.current[i] || React.createRef());
  }

  const handleChangeInputPage = (event) => {
    const newValue = event.target.value;
    if (newValue > documentTotalPages) {
      setCurrentPage(documentTotalPages);
    } else {
      setCurrentPage(newValue);
    }
  };

  const enterPressInInputPageValue = (event) => {
    const newValue = event.target.value <= 0 ? 1 : event.target.value;
    if (event.key === 'Enter') {
      if (visualizationMode === 'default') {
        setCurrentPage(newValue);
        scrollToElement(newValue, singlePageRefsArray);
      } else {
        setCurrentPage(newValue);
        scrollToElement(Math.ceil(newValue / 2), twoPagesRefArray);
      }
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      if (visualizationMode === 'presentation') {
        setCurrentPage((prev) => prev - 1);
      } else if (visualizationMode === 'default') {
        scrollToElement(currentPage - 1, singlePageRefsArray);
      } else {
        scrollToElement(indexCurrentVisualization - 1, twoPagesRefArray);
      }
    }
  };

  const nextPage = () => {
    if (currentPage < documentTotalPages) {
      if (visualizationMode === 'presentation') {
        setCurrentPage((prev) => prev + 1);
      } else if (visualizationMode === 'default') {
        scrollToElement(currentPage + 1, singlePageRefsArray);
      } else {
        scrollToElement(indexCurrentVisualization + 1, twoPagesRefArray);
      }
    }
  };

  const rotateRight = () => {
    if (rotateValue - 90 < 0) {
      setRotateValue(270);
    } else {
      setRotateValue((prev) => prev - 90);
    }
  };

  const upScale = () => {
    const currentScale = scaleOptions.indexOf(scale);
    if (currentScale === -1) {
      setScale(() => getNextValueInScaleOptionsArray());
    } else if (currentScale < scaleOptions.length - 1) {
      setScale(
        scaleOptions[currentScale + 1] > maxScaleX
          ? maxScaleX
          : scaleOptions[currentScale + 1]
      );
    } else {
      setScale(scaleOptions[scaleOptions.length - 1]);
    }
  };

  const downScale = () => {
    const currentScale = scaleOptions.indexOf(scale);
    if (currentScale === -1) {
      setScale(() => getPreviousValueInScaleOptionsArray());
    } else if (currentScale >= 1) {
      setScale(scaleOptions[currentScale - 1]);
    } else {
      setScale(scaleOptions[0]);
    }
  };

  const getNextValueInScaleOptionsArray = () => {
    for (let num of scaleOptions) {
      if (num > scale) {
        return num > maxScaleX ? maxScaleX : num;
      }
    }

    return scaleOptions[scaleOptions.length - 1] > maxScaleX
      ? maxScaleX
      : scaleOptions[scaleOptions.length - 1];
  };

  const getPreviousValueInScaleOptionsArray = () => {
    let previousValue;
    for (let num of scaleOptions) {
      if (num < scale) {
        previousValue = num;
      } else {
        break;
      }
    }
    return previousValue;
  };

  const scrollToElement = (page, listRef) => {
    if (listRef.current.length === 0) return;
    const currentListRef = listRef.current[page - 1];
    if (currentListRef) {
      currentListRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const handleScroll = (event) => {
    if (visualizationMode === 'default') {
      handleScrollDefaultVisualization(event);
    } else if (visualizationMode === 'two-pages') {
      handleScrollTwoPagesVisualization(event);
    }
  };

  const calculateCurrentPosition = (scrollBox, elementesRefArray) => {
    const scrollTop = scrollBox.scrollTop;
    const pageHeights = elementesRefArray.current.map(
      (ref) => ref.offsetTop - scrollBox.offsetTop
    );
    const currentPageIndex = pageHeights.findIndex((start, index) => {
      const end = pageHeights[index + 1] || Infinity;
      return scrollTop >= start && scrollTop < end;
    });

    return currentPageIndex;
  };

  const handleScrollDefaultVisualization = (event) => {
    const currentIndexPosition = calculateCurrentPosition(
      event.target,
      singlePageRefsArray
    );
    if (
      currentIndexPosition !== -1 &&
      currentIndexPosition !== currentPage - 1
    ) {
      setCurrentPage(currentIndexPosition + 1);
      setIndexCurrentVisualization(currentIndexPosition + 1);
    }
  };

  const handleScrollTwoPagesVisualization = (event) => {
    const currentIndexPosition = calculateCurrentPosition(
      event.target,
      twoPagesRefArray
    );
    if (
      currentIndexPosition !== -1 &&
      currentIndexPosition !== indexCurrentVisualization - 1
    ) {
      if (currentIndexPosition * 2 + 1 > documentTotalPages) {
        setCurrentPage(documentTotalPages);
      } else if (currentIndexPosition * 2 + 1 < 1) {
        setCurrentPage(1);
      } else {
        setCurrentPage(currentIndexPosition * 2 + 1);
      }
      setIndexCurrentVisualization(currentIndexPosition + 1);
    }
  };

  const toggleFullScreen = () => {
    if (fullVisualizationComponentRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (isFullScreen) {
        if (fullVisualizationComponentRef.current.requestFullscreen) {
          fullVisualizationComponentRef.current.requestFullscreen();
        } else if (
          fullVisualizationComponentRef.current.webkitRequestFullscreen
        ) {
          fullVisualizationComponentRef.current.webkitRequestFullscreen();
        }
      }
    }
  };

  function handleFullScreenChange() {
    const isFullScreenNow = document.fullscreenElement;
    if (!isFullScreenNow) {
      setScale(1.0);
      setVisualizationMode((prev) =>
        prev === 'presentation' ? 'default' : prev
      );
    }
    if (Boolean(isFullScreenNow) != isFullScreen) {
      setIsFullScreen(Boolean(isFullScreenNow));
    }

    setTimeout(() => {
      calculateContentHeight();
    }, 100);
  }

  const handleKeyDown = (event) => {
    const tagName = document.activeElement.tagName;
    if (tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') {
      return;
    }
    if (event.key === 'ArrowRight') {
      nextPageButtonRef.current.click();
    } else if (event.key === 'ArrowLeft') {
      previousPageButtonRef.current.click();
    }
  };

  const calculateContentHeight = () => {
    if (contentDocumentRef.current) {
      const rect = contentDocumentRef.current.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.top;
      setDocumentContentHeight(distanceToBottom);
    }
  };

  const renderDocumentContent = {
    default: (
      <SinglePageVisualization
        documentTotalPages={documentTotalPages}
        singlePageRefsArray={singlePageRefsArray}
        scale={scale}
        rotateValue={rotateValue}
        setPageDimensions={setPageDimensions}
        customTextRenderer={customTextRenderer}
      />
    ),
    'two-pages': (
      <TwoPagesVisualization
        documentTotalPages={documentTotalPages}
        twoPagesRefArray={twoPagesRefArray}
        scale={scale}
        rotateValue={rotateValue}
        customTextRenderer={customTextRenderer}
      />
    ),
    presentation: (
      <PresentationVisualization
        currentPage={currentPage}
        scale={scale}
        rotateValue={rotateValue}
      />
    )
  };

  useEffect(() => {
    if (visualizationMode === 'presentation') return;
    setCurrentPage(1);
    setIndexCurrentVisualization(1);
    contentDocumentRef.current.scrollTop = 0;
  }, [visualizationMode]);

  useEffect(() => {
    if (pdfData) {
      setIsLoading(false);

      toggleFullScreen();

      window.addEventListener('keydown', handleKeyDown);
      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener(
          'fullscreenchange',
          handleFullScreenChange
        );
      };
    }
  }, [pdfData, isFullScreen]);

  useEffect(() => {
    if (searchString.length > 0) setLoadingSearch(true);
  }, [searchString]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    if (fullVisualizationComponentRef.current)
      fullVisualizationComponentRef.current.addEventListener(
        'contextmenu',
        (event) => event.preventDefault()
      );

    calculateContentHeight();

    window.addEventListener('resize', calculateContentHeight);
    window.addEventListener('scroll', calculateContentHeight);
    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = '';
      window.removeEventListener('resize', calculateContentHeight);
      window.removeEventListener('scroll', calculateContentHeight);
    };
  }, []);

  useEffect(() => {
    if (!contentDocumentRef.current) return;

    const { offsetWidth, offsetHeight } = contentDocumentRef.current;
    const screenWidth = offsetWidth - 16;
    const screenHeight = offsetHeight - 16;

    const documentWidth = pageDimensions.width;

    let scaleX;

    if (rotateValue === 90 || rotateValue === 270) {
      scaleX = screenHeight / documentWidth;
    } else {
      scaleX = screenWidth / documentWidth;
    }

    setMaxScaleX(scaleX);
  }, [pageDimensions, rotateValue]);

  return (
    <>
      {error ? (
        <FileProcessingAlert />
      ) : (
        <Box ref={fullVisualizationComponentRef} className={classes.root}>
          <Box
            className={classes.contextualBar}
            style={{
              display: visualizationMode === 'presentation' ? 'none' : 'flex'
            }}
          >
            <SearchMenu
              loadingSearch={loadingSearch}
              totalFoundInSearch={totalFoundInSearch}
              setTotalFoundInSearch={setTotalFoundInSearch}
              currentSearchResult={currentSearchResult}
              setCurrentSearchResult={setCurrentSearchResult}
              resultsFoundInTheSearch={resultsFoundInTheSearch}
              setSearchString={setSearchString}
              scrollToElement={scrollToElement}
              searchString={searchString}
            />
            <Box style={{ display: 'flex', gap: '32px', alignItems: 'center' }}>
              <Box
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <ButtonBase
                  style={{ borderRadius: '4px' }}
                  onClick={previousPage}
                  ref={previousPageButtonRef}
                >
                  <NavigateBeforeIcon className={classes.personIconsStyle} />
                </ButtonBase>
                <Box
                  style={{ display: 'flex', gap: '12px', alignItems: 'center' }}
                >
                  <InputBase
                    value={currentPage}
                    onKeyDown={enterPressInInputPageValue}
                    onChange={handleChangeInputPage}
                    type="number"
                    className={classes.inputPage}
                    style={{
                      width: `${documentTotalPages.toString().length * 15}px`
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center'
                      }
                    }}
                  />
                  <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                    /
                  </Typography>
                  <Typography style={{ fontSize: '14px', fontWeight: 600 }}>
                    {documentTotalPages}
                  </Typography>
                </Box>
                <ButtonBase
                  style={{ borderRadius: '4px' }}
                  onClick={nextPage}
                  ref={nextPageButtonRef}
                >
                  <NavigateNextIcon className={classes.personIconsStyle} />
                </ButtonBase>
              </Box>
              <Box
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <ButtonBase style={{ borderRadius: '4px' }}>
                  <RemoveIcon
                    className={classes.personIconsStyle}
                    onClick={downScale}
                  />
                </ButtonBase>
                <ScaleMenu
                  setScale={setScale}
                  scale={scale}
                  contentDocumentRef={contentDocumentRef}
                  pageDimensions={pageDimensions}
                  rotateValue={rotateValue}
                  maxScaleX={maxScaleX}
                />
                <ButtonBase style={{ borderRadius: '4px' }}>
                  <AddIcon
                    className={classes.personIconsStyle}
                    onClick={upScale}
                  />
                </ButtonBase>
                <ButtonBase
                  style={{ borderRadius: '4px' }}
                  onClick={rotateRight}
                >
                  <Rotate90DegreesCcwIcon
                    className={classes.personIconsStyle}
                  />
                </ButtonBase>
              </Box>
            </Box>
            <Box style={{ display: 'flex', gap: '8px' }}>
              <ButtonBase
                style={{ borderRadius: '4px' }}
                onClick={() => setIsFullScreen((prev) => !prev)}
              >
                {isFullScreen ? (
                  <FullscreenExitIcon className={classes.personIconsStyle} />
                ) : (
                  <FullscreenIcon className={classes.personIconsStyle} />
                )}
              </ButtonBase>
              <MoreOptionsMenu
                setVisualizationMode={setVisualizationMode}
                rotateValue={rotateValue}
                setScale={setScale}
                pageDimensions={pageDimensions}
                setIsFullScreen={setIsFullScreen}
              />
            </Box>
          </Box>
          <Box
            onScroll={handleScroll}
            ref={contentDocumentRef}
            className={classes.documentContent}
            id="documentContent"
            style={{
              overflow: 'auto',
              maxHeight: documentContentHeight - 8
            }}
          >
            <span className={classes.documentContentSpan}>
              {isLoading ? (
                <CircularProgress size={64} />
              ) : (
                <Document
                  file={`data:application/pdf;base64,${pdfData}`}
                  options={{ workerSrc: '/pdf.worker.js' }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<CircularProgress size={64} />}
                >
                  {renderDocumentContent[`${visualizationMode}`]}
                </Document>
              )}
            </span>
          </Box>
        </Box>
      )}
    </>
  );
}

DocumentLecture.propTypes = {
  lecture: PropTypes.object
};
