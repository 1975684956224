import settings from '~settings';

const simpleUploadBuilder = (subjectID, token) => {
  return {
    uploadUrl: `${settings.apiUrl}/subjects/${subjectID}/avatars/multimedia`,
    headers: {
      'X-CSRF-TOKEN': 'CSRF-Token',
      Authorization: token,
      AppKey: settings.AppKey
    }
  };
};

export default simpleUploadBuilder;
