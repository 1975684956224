import React from 'react'
import { TextPrimary } from '~/utils/forms'

import { useStyles } from './styles'
export default function Section({ title, Content, ...props }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        { TextPrimary(title, '25px') }
      </div>

      <div className={classes.content}>
        <Content {...props}/>
      </div>
    </div>
  )
}