import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: 267,
    padding: 22,
    zIndex: 20
  },
  at: {
    fontSize: 14,
    color: props => props.theme.palette.textActiveElement.main
  },
  InputBox: {
    marginBottom: 10
  },
  input: {
    border: props => `1px solid ${props.theme.palette.primary.main}`,
    height: 40,
    borderRadius: 5,
    padding: '0 10px',
    width: '100%',
    '&::placeholder': {
      color: props => props.theme.palette.textActiveElement.main,
      opacity: .75
    }
  },
  login: {
    color: props => props.theme.palette.textActiveElement.main,
    fontSize: 14
  },
  name: {
    color: props => props.theme.palette.primary.main,
    fontSize: 20
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    "& button:hover": {
      backgroundColor: props => props.theme.palette.tertiary.main
    }
  },
  inputError: {
    border: props => `1px solid ${props.theme.palette.error.main}`,
  },
  helperText: {
    fontSize: 12,
    color: props => props.theme.palette.error.main,
    padding: '0 5px'
  }
})