import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px'
  },
  buttonText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  buttonIcon: {
    fontSize: '24px',
    color: theme.palette.primary.main
  }
}));
