import React from 'react';
import Text from '~/components/atoms/Text';

import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import i18n from '~/I18n';

export default function Success({ pagseguroAuth, environmentID }) {
  
  return (
    <Grid container direction="column" spacing={2} style={{ padding: '40px 90px'}}>
      
      <Grid item>
        <Text variant="ppTitle" color="primary">
          Tudo certo, <span style={{fontWeight: 'bold'}}>{ `${pagseguroAuth.user.first_name} ${pagseguroAuth.user.last_name}` }</span>!
        </Text>
      </Grid>

      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Text variant="mediumText" color="black">
              A integração ao Pagseguro foi concluída com sucesso.
            </Text>
          </Grid>
          <Grid item>
            <Text variant="mediumText" color="black">
              Você pode conferir o status da sua solicitação na página de configuração do {`${i18n.t('StructuresName.Environment.SingularLower')}`} clicando <Link to={`/dashboard/environments/${environmentID}/edit`}> aqui</Link>.
            </Text>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
