import api from '~/services/api';
const baseURL = '/api/any_choice_questions';

export const update = async (ID, body) => {
  const { data } = await api.put(baseURL + `/${ID}`, body);

  return data;
};

export const remove = async (ID) => {
  const { data } = await api.delete(baseURL + `/${ID}`);

  return data;
};

export default {
  remove,
  update
};
