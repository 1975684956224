import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import AppBarPages from '~/components/templates/AppbarPages';
import Members from '~/components/templates/Members';
import i18n from '~/I18n';
import { findByID, getEnrollments, searchMembers } from '~/services/Space';
import { update as updateRole } from '~/services/user-space-association';

import Content from './Content';
import { SpacePageProvider } from './contexts/SpacePageContext';
import Discussion from './Discussion';
import Header from './Header';
import ManagerPopUp from './ManagerPopUp';
import PageLoading from './PageLoading';
import Sidebar from './Sidebar';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

export default function Space() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { id } = useParams();

  const [section, setSection] = useState('content');
  const [space, setSpace] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    findByID(id)
      .then((space) => {
        setSpace(space);
      })
      .catch(console.log)
      .finally(() => setIsFetching(false));
  }, [id]);

  const renderSection = () => {
    if (section === 'content') {
      return <Content />;
    }

    if (section === 'discussion') {
      return <Discussion spaceID={id} canPublish={space.can_publish} />;
    }

    return (
      <Members
        structureID={id}
        getEnrollments={getEnrollments}
        updateRole={updateRole}
        searchMembers={searchMembers}
      />
    );
  };

  useEffect(() => {
    if (space) {
      document.title = `${theme.title} | ${space.name}`;
    } else {
      document.title = theme.title;
    }
  }, [space]);

  return (
    <AppBarPages>
      <Box className={classes.root}>
        <Box className={classes.content}>
          {!isFetching && !space && (
            <GenericFeedbackLayout
              ImageIcon={TropicalIslandImage}
              title={'Não encontrado!'}
              description={`Não foi possível carregar a ${i18n.t(
                `StructuresName.Space.SingularUpper`
              )}`}
            />
          )}
          {isFetching && <PageLoading />}
          {space && (
            <SpacePageProvider
              section={section}
              setSection={setSection}
              space={space}
            >
              <Box display={{ xs: 'none', md: 'flex' }}>
                <Sidebar />
              </Box>
              <Box className={classes.infos}>
                <Header />

                {space.can_manage && <ManagerPopUp />}
                <Box display={{ xs: 'flex', md: 'none' }}>
                  <Sidebar />
                </Box>

                <Box className={classes.infosContent}>
                  <>{renderSection()}</>
                </Box>
              </Box>
            </SpacePageProvider>
          )}
        </Box>
      </Box>
    </AppBarPages>
  );
}
