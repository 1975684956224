import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    color: theme.palette.activeText.main,
    marginLeft: '8px'
  },
  openDrawerButton: {
    minWidth: 0,
    padding: '8px 16px',
    backgroundColor: '#F2F2F7',
    borderRadius: '24px'
  },
  drawerPaper: {
    height: '85vh',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '16px 24px 24px 24px'
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  filtersContent: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    marginBottom: '16px'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 500,
    color: theme.palette.activeText.main
  },
  icon: {
    fontSize: '24px',
    color: theme.palette.activeText.main
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  accordionSummary: {
    margin: '0px !important',
    minHeight: '0px !important'
  },
  AccordionTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  accordion: {
    marginBottom: '0px !important',
    marginTop: '0px !important',
    boxShadow: 'none !important',
    backgroundColor: '#F2F2F7',
    borderRadius: '80px !important',
    transition: 'border-radius 1s ease',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      borderRadius: '8px !important'
    }
  },
  expanded: {},
  labelText: {
    fontSize: '14px',
    color: theme.palette.activeText.main,
    lineHeight: '24px'
  }
}));
