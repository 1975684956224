import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const SPACE_URL = 'spaces/';

export const hasManageAccessToSpace = async (spaceID) =>
  await hasManageAccessTo(`${SPACE_URL}${spaceID}`);
export const hasReadAccessToSpace = async (spaceID) =>
  await hasReadAccessTo(`${SPACE_URL}${spaceID}`);
export const hasUpdateAccessToSpace = async (spaceID) =>
  await hasCustomAccessTo(`${SPACE_URL}${spaceID}`, 'update');
export const hasPublishAccessToSpaceMural = async (spaceID) =>
  await hasCustomAccessTo(`${SPACE_URL}${spaceID}`, 'publish_mural');