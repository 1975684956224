import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    marginBottom: 20,
    '@media (max-width: 600px)': {
      marginBottom: 0
    }
  },
  membersContainer: {
    marginTop: 36,
    width: '68%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  membersGrid: {
    display: 'grid',
    overflowY: 'scroll',
    maxHeight: 300,
    gridTemplateColumns: 'repeat(4,25%)',
    '@media (max-width: 1400px)': {
      gridTemplateColumns: 'repeat(3,33.3333%)'
    },
    '@media (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(2,50%)'
    },
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1,100%)'
    }
  },
  membersList: {
    width: 800,
    overflow: 'scroll',
    height: 400
  },
  memberContainer: {
    display: 'flex',
    marginBottom: 16,
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    '&:hover': {
      background: theme?.palette.primary.main,
      '& p': {
        color: 'white !important'
      },
      '& svg': {
        fill: 'white !important'
      }
    }
  },
  memberAvatar: {
    marginRight: 4
  },
  memberInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  searchForm: {},
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%',
    borderBottom: (props) =>
      `1px solid ${props.theme.palette.textActiveElement.main}`,
    paddingBottom: 5,
    '@media (max-width: 600px)': {
      width: 'calc(100% - 25px)'
    }
  },
  searchBar: {
    width: '90%',
    fontSize: 14,
    padding: 0
  },
  searchIcon: {
    padding: 0,
    '& svg': {
      color: (props) => props.theme.palette.primary.main,
      fontSize: 26
    }
  },
  checkboxContainer: {
    display: 'flex',
    marginTop: 10
  },
  checkboxElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15
  },
  checkbox: {
    width: 16,
    height: 16,
    marginRight: 3,
    color: (props) => `${props.theme.palette.textActiveElement.main}88`,
    '& svg': {
      fontSize: 16
    }
  },
  desktopShimmer: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  mobileShimmer: {
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'flex'
    }
  }
}));
