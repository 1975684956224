import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  rememberContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '360px'
  },
  checkbox: {
    transform: 'scale(1.5)',
    marginLeft: '-8px',
  },
});

export const RecoverPasswordButton = styled.button`
  background-color: Transparent;
  border: 0;
  font-size: 1.2rem;
  color: #fff;
  opacity: 1;
`;

export const RememberRecoverDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

export const RememberDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 1.2rem;
  color: #ffffff;
  margin-left: 1rem;
`;

export const WrapperRemember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
`;
