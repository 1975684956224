import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  field: {
    marginBottom: '25px',
  },
  numberField: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '2px',
    padding: '4px 6px',
    fontSize: '13px',
    width: '90px'
  },
  questionField: {
    marginBottom: '10px'
  },
}));