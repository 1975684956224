import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	input: {
		width: '100%',
		borderRadius: '0.5rem',
		backgroundColor: 'white',
		height: 40,
		fontSize: 14,
		padding: 5
	},
	container: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		position: 'relative',
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'relative',
	},
	iconbutton: {
		marginRight: 5,
		color: theme.palette.primary.main,
		fontSize: 20
	}
}));

