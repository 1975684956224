import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import AppbarPages from '~/components/templates/AppbarPages';
import { getAll, remove } from '~/services/mirroring/replicable_processes';
import { setCreateMirrorPath } from '~/utils/linkPathConstructors';

import PageUnauthorized from '../PageUnauthorized';
import { useStyles } from './styles';
import TableHeaderWrapper from './TableHeaderWrapper';
import TableLoadingFeedback from './TableLoadingFeedback';
import TableRowWrapper from './TableRowWrapper';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  useTheme
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';

function ReplicableProcessesContent() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [
    isOpenModalProcessingErrorHandler,
    setIsOpenModalProcessingErrorHandler
  ] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isFetchDeleteProcess, setIsFetchDeleteProcess] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentProcessData, setCurrentProcessData] = useState(null);

  const getProcessing = async (curPage) => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await getAll(curPage);
      setProcessing(response);
      setPage(curPage);
      setHasMore(response.length >= 10);
    } catch (e) {
      enqueueSnackbar('Não foi possível carregar seus processamentos.', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const removeProcessFromTable = (processID) => {
    setProcessing((prev) => prev.filter((process) => process.id !== processID));
  };

  const handleProcessingError = (processData) => {
    setCurrentProcessData(processData);
    setIsOpenModalProcessingErrorHandler(true);
  };

  const handleProcessDeletion = async (processID) => {
    if (isFetchDeleteProcess) return;
    try {
      setIsFetchDeleteProcess(true);
      await remove(processID);
      setIsOpenModalProcessingErrorHandler(false);
      removeProcessFromTable(processID);
      enqueueSnackbar('Processamento excluído com sucesso.', {
        variant: 'success'
      });
      return true;
    } catch (e) {
      enqueueSnackbar(
        'Não conseguimos concluir sua solicitação. Por favor, tente novamente. Se o problema continuar, entre em contato com nossa equipe de suporte. Estamos aqui para ajudar!',
        {
          variant: 'error'
        }
      );
      return false;
    } finally {
      setIsFetchDeleteProcess(false);
    }
  };

  const processDeletionUseCallback = useCallback(
    () => handleProcessDeletion(currentProcessData.id),
    [currentProcessData]
  );

  const tryProcessAgain = async (currentProcessData) => {
    if (await handleProcessDeletion(currentProcessData.id)) {
      history.push(
        setCreateMirrorPath({
          type: currentProcessData.type,
          ...currentProcessData.replicable_hierarchy_associations
        })
      );
    }
  };

  useEffect(() => {
    getProcessing(page);
  }, []);

  return (
    <>
      <AppbarPages>
        <Box
          className={classes.content}
          paddingLeft={{ xs: '24px', lg: '128px', xl: '184px' }}
        >
          <DashboardBreadcrumbs
            links={[
              {
                name: 'Espelhamento',
                path: '/replicable_processes'
              }
            ]}
          />
          <Typography className={classes.title}>Espelhamento</Typography>
          <TableContainer component={Box} className={classes.tableContent}>
            <Table>
              <TableHeaderWrapper />
              <TableBody>
                {loading ? (
                  <TableLoadingFeedback />
                ) : processing.length === 0 && !hasMore ? (
                  <TableCell colSpan={8} height={'570px'}>
                    <GenericFeedbackLayout
                      ImageIcon={TropicalIslandImage}
                      title={'Página vazia!'}
                      description={'Não há nada a ser exibido.'}
                    />
                  </TableCell>
                ) : (
                  processing.map((row) => (
                    <TableRowWrapper
                      key={row.id}
                      rowData={row}
                      handleDeletion={handleProcessingError}
                      tryAgain={tryProcessAgain}
                      isFetchDeleteProcess={isFetchDeleteProcess}
                    />
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              style={{
                alignSelf: 'end'
              }}
              component="div"
              count={hasMore * -1}
              rowsPerPage={10}
              page={page - 1}
              labelDisplayedRows={() => ''}
              onPageChange={(_, newPage) => getProcessing(newPage + 1)}
              rowsPerPageOptions={[]}
              classes={{
                caption: classes.paginationCaption,
                actions: classes.paginationButtonSvg
              }}
            />
          </TableContainer>
        </Box>
      </AppbarPages>
      <ConfirmationDialog
        show={isOpenModalProcessingErrorHandler}
        handleClose={() => setIsOpenModalProcessingErrorHandler(false)}
        message="Você tem certeza de que deseja excluir este item da lista? "
        handleConfirm={processDeletionUseCallback}
        confirmButtonText="Confirmar"
        titleText="Excluir"
        titleIcon={
          <WarningIcon
            style={{
              alignSelf: 'center',
              fill: theme.palette.error.main,
              fontSize: '24px'
            }}
          />
        }
        colorVariant="primary"
        isLoading={isFetchDeleteProcess}
      />
    </>
  );
}

export default function ReplicableProcesses() {
  const profile = useSelector((state) => state.user.profile);

  return (
    <>
      {profile?.can_mirror ? (
        <ReplicableProcessesContent />
      ) : (
        <PageUnauthorized />
      )}
    </>
  );
}
