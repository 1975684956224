import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

export default function MemberLoading() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        className={`${classes.memberCard} ${classes.item1}`}
      >
        <Box width={80} style={{ marginRight: '16px' }}>
          <Skeleton variant="circle" width={60} height={60} />
        </Box>
        <Box display="flex" flexDirection="column" width={1}>
          <Skeleton
            variant="rect"
            height={16}
            width={'80%'}
            style={{ marginTop: 10 }}
          />
          <Skeleton
            variant="rect"
            height={12}
            width={'30%'}
            style={{ marginTop: 5 }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        className={`${classes.memberCard} ${classes.item2}`}
      >
        <Box width={80} style={{ marginRight: '16px' }}>
          <Skeleton variant="circle" width={60} height={60} />
        </Box>
        <Box display="flex" flexDirection="column" width={1}>
          <Skeleton
            variant="rect"
            height={16}
            width={'80%'}
            style={{ marginTop: 10 }}
          />
          <Skeleton
            variant="rect"
            height={12}
            width={'30%'}
            style={{ marginTop: 5 }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        className={`${classes.memberCard} ${classes.item3}`}
      >
        <Box width={80} style={{ marginRight: '16px' }}>
          <Skeleton variant="circle" width={60} height={60} />
        </Box>
        <Box display="flex" flexDirection="column" width={1}>
          <Skeleton
            variant="rect"
            height={16}
            width={'80%'}
            style={{ marginTop: 10 }}
          />
          <Skeleton
            variant="rect"
            height={12}
            width={'30%'}
            style={{ marginTop: 5 }}
          />
        </Box>
      </Box>
    </>
  );
}
