import base from './base';
const baseUrl = 'https://beta.digital.microkids.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjx13rbd1';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'InExIoXOGrYF6THjZ3rvpYmjxDtV2k7_UrVqE6vaNK8',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://beta.digital.microkids.com.br:44443',
  clarityKey
};
