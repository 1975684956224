import React, { useState } from 'react';

import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import DisplayOptions from '~/components/templates/DisplayOptions';
import PageTemplate from '~/components/templates/Page';

import { useStyles } from './styles';
import { useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

export default function StepperForm({
  navOptions,
  onSubmit,
  renderContent,
  breadcrumbs,
  submissionStatus,
  setSubmissionStatus,
  handleCancel,
  handleGetYoutubeUrl,
  initialValues,
  formTitle,
  submitButtonTitle,
  loading,
  unclickable
}) {
  const isTablet = useMediaQuery('@media (max-width: 600px)');
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  function previousTab() {
    if (selectedTab > 0) setSelectedTab(selectedTab - 1);
  }

  function nextTab() {
    if (selectedTab < navOptions.length - 1) setSelectedTab(selectedTab + 1);
  }

  return (
    <PageTemplate>
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <DisplayOptions
            optionNames={navOptions}
            selectedOption={selectedTab}
            setSelectedOption={setSelectedTab}
            unclickable={unclickable}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.header}>
            {loading ? (
              <>
                <Skeleton height={25} width="40%" animation="pulse" />
                <Skeleton height={50} width="60%" animation="pulse" />
              </>
            ) : (
              <>
                <DashboardBreadcrumbs links={breadcrumbs} />
                <div className={isTablet && classes.mobileTitle}>
                  <Text
                    variant="xl"
                    color="primary"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    {formTitle}
                  </Text>
                </div>
              </>
            )}
            <div className={classes.tabbar}>
              <DisplayOptions
                optionNames={navOptions}
                selectedOption={selectedTab}
                setSelectedOption={setSelectedTab}
                tabSpacing="space-around"
                unclickable={unclickable}
              />
            </div>
          </div>
          <div className={classes.formContainer}>
            {loading ? (
              <div className={classes.shimmerForm}>
                <Skeleton height={20} width="40%" animation="pulse" />
                <Skeleton height={60} width="100%" animation="pulse" />
                <Skeleton height={20} width="40%" animation="pulse" />
                <Skeleton height={60} width="100%" animation="pulse" />
                <Skeleton height={20} width="40%" animation="pulse" />
                <Skeleton height={60} width="100%" animation="pulse" />
              </div>
            ) : (
              renderContent({
                selectedTab,
                nextTab,
                previousTab,
                tabAmount: navOptions.length,
                onSubmit,
                submissionStatus,
                setSubmissionStatus,
                handleCancel,
                handleGetYoutubeUrl,
                initialValues,
                submitButtonTitle,
                loading
              })
            )}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

StepperForm.propTypes = {
  navOptions: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
  renderContent: PropTypes.func,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  submissionStatus: PropTypes.string,
  setSubmissionStatus: PropTypes.func,
  handleCancel: PropTypes.func,
  handleGetYoutubeUrl: PropTypes.func,
  initialValues: PropTypes.shape(),
  formTitle: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  loading: PropTypes.bool,
  unclickable: PropTypes.bool
};

StepperForm.defaultProps = {
  navOptions: [],
  onSubmit: null,
  renderContent: null,
  breadcrumbs: [],
  submissionStatus: '',
  setSubmissionStatus: null,
  handleCancel: null,
  handleGetYoutubeUrl: null,
  initialValues: {},
  formTitle: '',
  submitButtonTitle: '',
  loading: false,
  unclickable: true
};
