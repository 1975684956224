import api from '~/services/api';

export const getEnvironments = async (baseURL, page) => {
  const { data } = await api.get(`${baseURL}/environments?page=${page}`);
  return data;
};

export const getCourses = async (baseURL, environmentID, page) => {
  const { data } = await api.get(
    `${baseURL}/courses?environment_id=${environmentID}&page=${page}`
  );
  return data;
};

export const getSpaces = async (baseURL, courseID, page) => {
  const { data } = await api.get(
    `${baseURL}/spaces?course_id=${courseID}&page=${page}`
  );
  return data;
};
export const getSubjects = async (baseURL, spaceID, page) => {
  const { data } = await api.get(
    `${baseURL}/subjects?space_id=${spaceID}&page=${page}`
  );
  return data;
};

// lectures/environments
// lectures/couses?environment_id
// lectures/space?course_id
// lectures/subjects?space_id
// lectures?subject_id

// subjects/environments
// subjects/couses?environment_id
// subjects/space?course_id
// subjects?space_id

// spaces/environments
// spaces/couses?environment_id
// spaces?course_id

// courses/environments
// courses?environment_id

// environments/
