import React from 'react';
import { Switch } from '@material-ui/core';
import Input from '~/components/atoms/TextInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles';

export default function Youtube() {
  const classes = useStyles()

  return(
    <div>
      <div className={classes.field}>
        <Field label={TextPrimary("URL")} name="lectureable.youtube.external_resource_url" placeholder="link para o vídeo do Youtube..." Component={Input} />
      </div>
      <div className={`${classes.field} ${classes.switch}`}>
        <Field label={TextPrimary("Bloquear compartilhamento do link do youtube?")} name="lectureable.youtube.custom_video_player" labelPlacement={'end'} Component={Switch} size="small" />
      </div>
    </div>
  );
}
