import React from 'react';

import { ReactComponent as WelcomeImage } from '~/assets/images/creative-team-cuate.svg';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export const Welcome = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.textContainer}>
        <Typography className={classes.title}>
          Olá! Seja bem-vindo(a)!
        </Typography>
        <Typography className={classes.message}>
          Somos uma plataforma de ensino que valoriza o{' '}
          <strong>aprendizado real</strong> e transformador. Aqui, promovemos
          interações e conexões que impulsionam o seu crescimento
        </Typography>
      </Box>
      <WelcomeImage className={classes.image} />
    </Box>
  );
};
