import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import ComponentAccessControlWrapper from '~/components/molecules/ComponentAccessControlWrapper';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import { EnvironmentItem } from '~/components/molecules/EnvironmentItem';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import AppbarPages from '~/components/templates/AppbarPages';
import i18n from '~/I18n';
import { hasAdminAccess } from '~/services/AccessControl/accessControl';
import { getAll } from '~/services/environment';
import { Creators as UserActions } from '~/store/ducks/user';

import { HeaderSearchAndFilters } from './HeaderSearchAndFilters';
import LoadingMore from './Loading/loadingMore';
import { useStyles } from './styles';
import { Box, Grid, useTheme } from '@material-ui/core';

export default function Environments() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { getProfileRequest } = UserActions;
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.user);

  function removeEnvironmentById(id) {
    setEnvironments((prev) => prev.filter((element) => element.id !== id));
  }

  // State for pagination
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const filters = useForm({
    defaultValues: {
      ord: '',
      direction: '',
      name_like: '',
      state: '',
      regionalGroup: '',
      city: ''
    }
  });
  const [hasMore, setHasMore] = useState(true);

  // Fetch user profile
  function fetchProfile() {
    dispatch(getProfileRequest());
  }

  // Fetch environments with pagination and filters
  const fetchEnvironments = (pageNumber, filterOptions) => {
    if (isLoading) return;

    setIsLoading(true);
    getAll(pageNumber, filterOptions)
      .then((data) => {
        if (data.length < 20) {
          setHasMore(false);
        }
        setEnvironments((prevEnvironments) => [...prevEnvironments, ...data]);
        setIsLoading(false);
        setPage(pageNumber + 1);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // Load more items when scrolling
  const loadMoreItems = (event) => {
    const bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.offsetHeight + 60;

    if (bottom && hasMore) {
      fetchEnvironments(page, filters.getValues());
    }
  };

  // Fetch user profile on component mount
  useEffect(() => {
    if (!profile) fetchProfile();
  }, []);

  // Fetch environments and set document title on component mount
  useEffect(() => {
    document.title = `${theme.title} | ${i18n.t(
      'StructuresName.Environment.PluralUpper'
    )}`;
  }, []);

  return (
    <AppbarPages>
      <Box className={classes.root} onScroll={loadMoreItems}>
        <Box className={classes.content}>
          <Box className={classes.header}>
            <Grid
              container
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Grid item>
                {/* Breadcrumbs */}
                <DashboardBreadcrumbs
                  links={[
                    {
                      name: i18n.t('MyEnvironments'),
                      path: '/dashboard/environments'
                    }
                  ]}
                />
                {/* Page Title */}
                <Text
                  variant="xl"
                  style={{
                    letterSpacing: 1,
                    fontWeight: 500,
                    color: theme.palette.activeText.main
                  }}
                >
                  {i18n.t('MyEnvironments')}
                </Text>
                {/* Component to create new environment */}
                <Box style={{ marginTop: 15, display: 'inline-flex' }}>
                  <ComponentAccessControlWrapper
                    accessFunction={hasAdminAccess}
                    Component={StructureCreationPopUp}
                    text={`Novo ${i18n.t(
                      'StructuresName.Environment.SingularUpper'
                    )}`}
                    structure={{
                      name: 'environment'
                    }}
                    structureName={i18n.t(
                      'StructuresName.Environment.SingularUpper'
                    )}
                    redirectLink={`/dashboard/environments/new`}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.filter}>
              {/* Filters component */}
              <FormProvider {...filters}>
                <HeaderSearchAndFilters
                  fetchEnvironments={fetchEnvironments}
                  setEnvironments={setEnvironments}
                  setPage={setPage}
                  setHasMore={setHasMore}
                />
              </FormProvider>
            </Box>
          </Box>
          <Box className={classes.environmentContainer}>
            <Grid
              container
              spacing={1}
              style={{
                height: 'min-content'
              }}
            >
              {environments.map((environment, index) => (
                <Grid item key={index} className={classes.environmentItem}>
                  <EnvironmentItem
                    key={index}
                    environment={environment}
                    removeEnvironmentById={removeEnvironmentById}
                  />
                </Grid>
              ))}
              {/* Loading indicator while loading more items */}
              {isLoading ? <LoadingMore /> : null}
            </Grid>
            {environments.length <= 0 && !hasMore && !isLoading && (
              <Text color="primary">
                Não foi possível encontrar nenhum{' '}
                {`${i18n.t('StructuresName.Environment.SingularLower')}`}.
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </AppbarPages>
  );
}
