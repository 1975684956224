import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import api from '~/services/api';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Chip, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function PostMuralCloneSelect({
  selectedSpacesList,
  setSelectedSpacesList
}) {
  const { id } = useParams();
  const [spaceList, setSpaceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handleChange = (event) => {
    if (event.target.value[event.target.value.length - 1]) {
      setSelectedSpacesList(event.target.value);
    }
  };

  const getReplicateOptions = () => {
    if (isLoading) return;
    setIsLoading(true);
    setTimeout(() => {}, 5000);
    api
      .get(`/api/spaces/${id}/load_replicate_options`, { params: { page } })
      .then((res) => {
        setSpaceList([...spaceList, ...res.data]);
        setIsLoading(false);
        setPage((page) => page + 1);
      })
      .catch(setIsLoading(false));
  };

  useEffect(() => {
    getReplicateOptions();
  }, []);

  return (
    <div style={{ marginTop: '3%' }}>
      <FormControl variant="outlined">
        <InputLabel style={{ color: '#1a72f9' }} id="mutiple-checkbox">
          Espelhar para:
        </InputLabel>
        <Select
          MenuProps={{ getContentAnchorEl: () => null }}
          style={{ minWidth: '400px', maxWidth: '700px' }}
          label="Espelhar para:"
          labelId="mutiple-checkbox"
          id="teste"
          multiple
          value={selectedSpacesList}
          onChange={handleChange}
          renderValue={(list) => (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                maxHeight: '180px',
                overflow: 'scroll',
                overflowX: 'hidden',
                marginBottom: '-10px'
              }}
            >
              {list.map((spaceId) => (
                <Chip
                  key={spaceId}
                  label={spaceList.find((e) => e.id === spaceId)?.name}
                  style={{
                    margin: 2,
                    background: 'blue',
                    color: '#fff',
                    fontSize: '10px',
                    cursor: 'pointer'
                  }}
                />
              ))}
            </div>
          )}
        >
          {spaceList.map((space) => (
            <MenuItem key={space.id} value={space.id}>
              <Checkbox
                style={{ color: '#1a72f9' }}
                checked={selectedSpacesList.indexOf(space.id) > -1}
              />
              <ListItemText
                style={{ fontSize: '25px', color: '#1a72f9' }}
                primary={space.name}
              />
            </MenuItem>
          ))}
          <MenuItem
            style={{
              display: 'flex',
              alignItens: 'center',
              justifyContent: 'center'
            }}
            onClick={getReplicateOptions}
          >
            {isLoading ? (
              <CircularProgress size={'20px'} />
            ) : (
              <Typography
                style={{ color: '#1a72f9', fontSize: '12px', height: '20px' }}
              >
                ver mais
              </Typography>
            )}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
