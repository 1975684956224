import React             from 'react';
import Field             from '~/components/organisms/GenericFormWrapper/Field';
import IntegrationDone   from './IntegrationDone';
import IntegrationUnDone from './IntegrationUnDone';

import { TextPrimary }      from '~/utils/forms';
import { Collapse, Switch } from '@material-ui/core';
import { useFormContext }   from 'react-hook-form';

export default function PagseguroIntegration() {
  const { watch } = useFormContext()

  return (
    <>
      <Field  label={TextPrimary("Integrar com o Pagseguro")} 
              labelPlacement={'end'} 
              name="enable_pagseguro" 
              checked={watch("enable_pagseguro")}
              Component={Switch}
              />

      <Collapse in={watch("enable_pagseguro")} style={{ marginTop: '10px' }}>
        { watch("pagseguro_authorization") ? <IntegrationDone/> : <IntegrationUnDone/>}
      </Collapse>
    </>
  );
}