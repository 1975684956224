import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  btnRoot: {
    width: '100%', 
    height: '100%', 
    backgroundColor: theme.palette.primary.main, 
    minWidth: 0, 
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main, 
    },
  },
  floatRoot: {
    position: 'absolute',
  },
  alert: {
    alignItems: 'center',
    maxHeight: '25px',
  },
  typograph: {
    color: 'white', 
    fontSize: 11
  }
}));
