import React from 'react';

import { useStyles } from './styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import PropTypes from 'prop-types';

const statusNameMap = {
  done: 'CONCLUÍDO',
  error: 'ERRO',
  processing: 'ANDAMENTO'
};

export const StatusIndicator = ({ status, deleted, detached }) => {
  const theme = useTheme();
  const classes = useStyles();

  const statusBackgroundColorMap = {
    done: theme.palette.info.main,
    error: theme.palette.error.main,
    processing: theme.palette.warning.main
  };

  if (deleted) {
    return (
      <Box className={classes.statusBox}>
        <DeleteForeverIcon className={classes.icon} />
        <Typography className={classes.statusText}>Excluído</Typography>
      </Box>
    );
  } else if (detached) {
    return (
      <Box className={classes.statusBox}>
        <LinkOffIcon className={classes.icon} />
        <Typography className={classes.statusText}>Desvinculado</Typography>
      </Box>
    );
  }

  return (
    <Box
      className={classes.statusIndicator}
      style={{
        backgroundColor: statusBackgroundColorMap[status]
      }}
    >
      {statusNameMap[status]}
    </Box>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(['done', 'error', 'processing']).isRequired,
  deleted: PropTypes.bool,
  detached: PropTypes.bool
};

StatusIndicator.defaultProps = {
  deleted: false,
  detached: false
};
