import React, { useEffect, useState } from 'react'

import { Box }         from '@material-ui/core'
import { useLocation } from 'react-router-dom';

import EnvironmentLogo           from './EnvironmentLogo';
import PlatformLogo              from './PlatformLogo';
import { getEnvironmentPubInfo } from './fetch';

import { useStyles } from './styles'  

export default function Logo() {
  const classes = useStyles()

  const environmentPath = new URLSearchParams(useLocation().search).get('env');
  const [environmentData, setEnvironmentData] = useState(null);

  useEffect(() => {
    if (environmentPath) getEnvironmentPubInfo(environmentPath).then(setEnvironmentData).catch(console.log);
  }, [])

  return (
    <Box className={classes.containerLogo}>
      { environmentData ? <EnvironmentLogo logoPath={environmentData.image} label={environmentData.name}/> : <PlatformLogo/> }
    </Box>
  )
}