import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '24px',
    padding: '8px 16px',
    height: '40px',
    '&:disabled': {
      backgroundColor: '#F2F2F7',
      color: theme.palette.activeText.main
    }
  },
  buttonLabel: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px'
  }
}));
