import i18n from '~/I18n';
export const Capitaliza = function(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function handleAddItem(errors, fieldName, hasErrorFunc, noErrorFunc) {
  let hasErrors = false;
  if (errors && errors[fieldName]) hasErrors = true;

  // will only allow new item if all previous items are valid
  if (hasErrors) hasErrorFunc();
  else noErrorFunc();
}

export function handleErrors(error, enqueueSnackbar) {
  const errors = error?.response?.data;
  if (errors) {
    for (const property in errors) {
      enqueueSnackbar(
        `Houve um problema na submissão. '${i18n.t(property)}: ${
          errors[property][0]
        }'!`,
        {
          variant: 'error'
        }
      );
    }
  }
}

export function handleSuccess(message, enqueueSnackbar, path, history) {
  enqueueSnackbar(`${message}!`, {
    variant: 'success'
  });

  history.push(path);
}

export function sanitizeLecture(rawLecture) {
  let lec = {};
  rawLecture.subjectID = rawLecture.subject_id;
  if (rawLecture.type === 'Exercise') {
    lec = {
      ...rawLecture,
      lectureable: { ['exercise']: rawLecture.lectureable }
    };
    lec.lectureable.type = 'exercise';
  } else if (rawLecture.type === 'Form') {
    lec = {
      ...rawLecture,
      lectureable: {
        exercise: {
          id: rawLecture.lectureable.id,
          questions: rawLecture.lectureable.any_choice_questions.map((q, i) => {
            const { options, ...rest } = q;
            return {
              ...rest,
              position: i,
              options_attributes: options
            };
          })
        },
        type: 'form'
      }
    };
  } else if (rawLecture.type === 'Page') {
    lec = {
      ...rawLecture
    };

    if (rawLecture.lectureable.image_type) {
      lec.lectureable = { ['image']: rawLecture.lectureable };
      lec.lectureable.type = 'image';
      lec.lectureable.image = { file: null };
    } else {
      lec.lectureable = { ['multimedia']: rawLecture.lectureable };
      lec.lectureable.type = 'multimedia';
      lec.lectureable.multimedia.content = lec.lectureable.multimedia.body;
    }
  } else if (rawLecture.type === 'Seminar') {
    lec = {
      ...rawLecture
    };
    switch (rawLecture.lectureable.resource_type) {
      case 'call':
      case 'zoom':
      case 'google_meet':
        lec.lectureable = { ['videocall']: rawLecture.lectureable };
        lec.lectureable.type = 'videocall';
        break;
      case 'upload':
        lec.lectureable = { ['video']: rawLecture.lectureable };
        lec.lectureable.type = 'video';
        break;
      case 'youtube_livestream':
        lec.lectureable = { ['livestream']: rawLecture.lectureable };
        lec.lectureable.type = 'youtube_livestream';
        break;
      case 'youtube':
        lec.lectureable = { ['youtube']: rawLecture.lectureable };
        lec.lectureable.type = 'youtube';
        break;
    }
  } else {
    lec = {
      ...rawLecture
    };
    lec.lectureable.type = 'presentation';
  }
  return lec;
}
