import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
/**
 * Creating action types & creators
 */

export const { Types, Creators } = createActions({
  signInRequest: ['login', 'password', 'remember'],
  signInSuccess: ['token', 'remember'],
  signInFailure: [],
  signOut: [],
  recoverPasswordRequest: ['email'],
  recoverPasswordSuccess: [],
  recoverPasswordFailure: [],
});
/**
 * Creating the reducer handlers
 */
const INITIAL_STATE = {
  isLogged: false,
  loginError: false,
  token: null,
  loading: false,
  loadingRecoverPassword: false,
  errorRecoverPassword: false,
  remember: false,
  submited: false,
};

const signInRequest = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = true;
    draft.submited = true;
    draft.loginError = false;
  });
const signInSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.isLogged = true;
    draft.loginError = false;
    draft.token = action.token;
    draft.remember = action.remember;
    draft.submited = false;
  });
const signInFailure = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.loginError = true;
    draft.submited = false;
  });

const signOut = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    // draft.loading = true;
    draft.loading = false;
    draft.isLogged = false;
    draft.token = null;
  });

/**
 * Creating reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.SIGN_OUT]: signOut,
});
