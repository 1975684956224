import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '5px',
    maxWidth: '450px',
    '@media (max-width: 425px)': {
      maxWidth: '250px'
    }
  }
});
