import React from 'react';

import CourseStep from './CourseStep';
import EnvironmentStep from './EnvironmentStep';
import LastStep from './LastStep';
import SpaceStep from './SpaceStep';
import StartStep from './StartStep';
import { useStyles } from './styles';
import SubjectStep from './SubjectStep';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function ReplicatedSteps({ currentStep, isLastStep }) {
  const classes = useStyles();

  const steps = {
    0: <StartStep />,
    1: <EnvironmentStep />,
    2: <CourseStep />,
    3: <SpaceStep />,
    4: <SubjectStep />
  };

  return (
    <Box className={classes.content}>
      {isLastStep ? <LastStep /> : steps[currentStep]}
    </Box>
  );
}

ReplicatedSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired
};
