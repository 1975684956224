import settings from '~settings';

import api from './api';
import axios from 'axios';
import * as qs from 'qs';

const baseURL = settings.sebraeIDBaseURL;
var redirectURI = settings.sebraeRedirectURI;
const SebraeKey = settings.sebraeKey;
const clientID = 'sebrae-edu-frontend';
const tokenEndpoint =
  baseURL + '/auth/realms/externo/protocol/openid-connect/token';
const accountEndpoint = baseURL + '/auth/realms/externo/account';

const sebraeID = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
  }
});

sebraeID.interceptors.request.use(async (config) => {
  return config;
});

export const getUserToken = async (code) => {
  const payload = {
    grant_type: 'authorization_code',
    redirect_uri: redirectURI,
    client_id: clientID,
    code: code
  };

  return sebraeID.post(tokenEndpoint, qs.stringify(payload));
};

export const getUserData = async (token) => {
  return sebraeID.get(accountEndpoint, {
    headers: { authorization: `Bearer ${token}` }
  });
};

export const processSebraeAuth = async (code, courseToken) => {
  if (courseToken) {
    redirectURI = redirectURI + `?t=${courseToken}`;
  }

  const {
    data: { access_token }
  } = await getUserToken(code);
  const { data } = await getUserData(access_token);

  const response = await api.post(
    '/api/sebrae/users',
    {
      user: {
        login: data.attributes.cpf[0].replace(/[.-]/g, ''),
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        birthday: new Date(data.attributes.dataNascimento[0]),
        mobile: '+55 ' + data.attributes.telefoneCelular[0]
      }
    },
    { headers: { SebraeKey } }
  );

  return response.data;
};
