import React from 'react';
import { MdLock, MdLockClock } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { LectureCardIcon } from '~/components/atoms/LectureCardIcon';

import { useStyles } from './styles';
import { Box, ListItem, Typography, useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';

export default function LectureItem({ lecture, current }) {
  const { name, user_completion, can_read, expired, id } = lecture;
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const color = can_read ? '#fff' : '#676D82';

  return (
    <ListItem
      className={`${classes.lectureContainer} ${
        current ? classes.current : ''
      } ${!can_read ? classes.inactive : ''}`}
      onClick={() => !current && history.push(`/dashboard/lecture/${id}`)}
    >
      <Box className={classes.itemHeader}>
        <Box>
          {user_completion && (
            <CheckCircleOutlineIcon className={classes.doneIcon} />
          )}
        </Box>
        <Box>
          {!can_read &&
            (!expired ? (
              <MdLock className={classes.notCanReadIcons} />
            ) : (
              <MdLockClock className={classes.notCanReadIcons} />
            ))}
        </Box>
      </Box>
      <LectureCardIcon lecture={lecture} />
      <Typography style={{ color: color }} className={classes.itemName}>
        {name}
      </Typography>
    </ListItem>
  );
}

LectureItem.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    user_completion: PropTypes.bool,
    can_read: PropTypes.bool.isRequired,
    expired: PropTypes.bool.isRequired
  }).isRequired,
  current: PropTypes.bool.isRequired
};
