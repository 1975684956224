import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dragDropInput: {
    background: '#FAFAFA',
    border: '1px dashed #489AF8',
    borderRadius: '12px',
    maxWidth: '100%',
    minWidth: '240px',
    maxHeight: '400px',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },

  dragDropInput__wraper: {
    width: '220px',
    height: '30px',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    outline: 'none',
    'white-space': 'nowrap',
    '-webkit-user-select': 'none',
    cursor: 'pointer',
    '&::-webkit-file-upload-button': {
      visibility: 'hidden'
    }
  },

  dragDropInput__input: {
    boxSizing: 'border-box',
    background: (props) =>
      props.done ? props.theme.palette.success.main : '#C7DAEF',
    borderRadius: '12px',
    width: '220px',
    height: '60px',
    padding: '15px',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: (props) =>
        props.done ? props.theme.palette.success.main : '#BFD1E5'
    },
    '&:active': {
      background: (props) =>
        props.done ? props.theme.palette.success.main : '#AABACC'
    }
  },
  dragDropInput__alert: {
    maxWidth: '600px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dragDropInput__fileName: {
    maxWidth: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& p': {
      maxWidth: '200px',
      marginLeft: '15px'
    }
  },
  dragDropInput__fileSelected: {
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  wrapSelectMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0'
  },
  dragDropInput__input_successMessage: {
    maxWidth: 320,
    heigth: 200,
    marginTop: 30
  }
});
