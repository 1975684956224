import React from 'react';

import appstore from '~/assets/images/apple_branco.png';
import playstore from '~/assets/images/playstore_branco.png';
import settings from '~settings';

import * as S from './styles';

const AppsMobileLinks = () => {
  return (
    <S.Container>
      {!!settings.appstore && (
        <S.Link href={settings.appstore} target="_blank">
          <S.Image src={appstore} />
        </S.Link>
      )}
      {!!settings.playstore && (
        <S.Link href={settings.playstore} target="_blank">
          <S.Image src={playstore} />
        </S.Link>
      )}
    </S.Container>
  );
};

export default AppsMobileLinks;
