import React             from 'react';
import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field             from '~/components/organisms/GenericFormWrapper/Field';

import { useFormContext } from 'react-hook-form';

const fileTypes = [ 
  'application/x-mp4',
  'video/x-flv',
  'application/x-flv',
  'video/mpeg',
  'video/quicktime',
  'video/x-la-asf',
  'video/x-ms-asf',
  'video/x-msvideo',
  'video/x-sgi-movie',
  'video/x-flv',
  'flv-application/octet-stream',
  'video/3gpp',
  'video/3gpp2',
  'video/3gpp-tt',
  'video/BMPEG',
  'video/BT656',
  'video/CelB',
  'video/DV',
  'video/H261',
  'video/H263',
  'video/H263-1998',
  'video/H263-2000',
  'video/H264',
  'video/JPEG',
  'video/MJ2',
  'video/MP1S',
  'video/MP2P',
  'video/MP2T',
  'video/mp4',
  'video/MP4V-ES',
  'video/MPV',
  'video/mpeg4',
  'video/mpeg',
  'video/avi',
  'video/mpeg4-generic',
  'video/nv',
  'video/vnd.objectvideo',
  'video/parityfec',
  'video/pointer',
  'video/raw',
  'video/rtx',
  'application/x-mpegURL',
  'video/x-ms-wmv'
]

export default function UploadVideo() {
  const { control } = useFormContext()

  return (
    <Field  name="lectureable.video.file" 
            control={ control } 
            Component={DragDropFileInput} 
            permittedFileTypes={fileTypes} 
            fileTypesDescription = {<div>Arquivos apenas em vídeo <br />Tamanho máximo de arquivos: <strong>1 GB.</strong></div>}
            />
  )

}
