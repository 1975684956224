import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { editSubjectFormSchema } from '~/components-refactor/molecules/ReplicatedSteps/SubjectStep/EditSubjectForm/validation';
import { CreateReplicatedStructure } from '~/components-refactor/organisms/CreateReplicatedStructure';
import { ReplicatedStructureProvider } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext';
import i18n from '~/I18n';
import subjectsListService from '~/services/mirroring/mirroring_list/subjects';
import subjectMirrorService from '~/services/mirroring/subject';

export default function ReplicateSubject() {
  const { spaceID } = useParams();

  return (
    <ReplicatedStructureProvider
      parentID={spaceID}
      mirrorType="subject"
      listService={subjectsListService}
      mirrorService={subjectMirrorService}
    >
      <CreateReplicatedStructure
        structureName={i18n.t(`StructuresName.Subjects.SingularUpper`)}
        stepsCount={6}
        resolver={editSubjectFormSchema}
      />
    </ReplicatedStructureProvider>
  );
}
