import React, { useEffect, useState } from 'react';

import { getUserSubmission } from '~/services/Lecture/Questionary';

import ExerciseForm from './ExerciseForm';
import Root from './Root';

export const ExerciseMembersReportSwap = ({ lecture }) => {
  const [showExerciseForm, setShowExerciseForm] = useState(false);

  const [result, setResult] = useState(null);

  const openExerciseForm = () => setShowExerciseForm(true);
  const closeExerciseForm = () => setShowExerciseForm(false);

  const getMemberResult = () => {
    getUserSubmission(lecture.lectureable.id, lecture.id)
      .then(setResult)
      .catch(console.log);
  };

  useEffect(() => {
    getMemberResult();
  }, []);

  return (
    <>
      {showExerciseForm ? (
        <ExerciseForm
          result={result}
          setResult={setResult}
          lecture={lecture}
          closeExerciseForm={closeExerciseForm}
        />
      ) : (
        <Root
          openExerciseForm={openExerciseForm}
          lecture={lecture}
          result={result && result.state === 'finalized' ? result : null}
        />
      )}
    </>
  );
};

export default ExerciseMembersReportSwap;
