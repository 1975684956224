import React from 'react'
import Text from '~/components/atoms/Text'

import { useStyles } from './styles'

export default function Main({ color, backgroundColor, size, text, ...rest }) {
  const classes = useStyles()

  const style = {
    color: color,
    backgroundColor: backgroundColor,
    ...rest
  }


  return <Text textAlign="center" fontWeight="bold" className={`${classes.root} ${ size ? classes[size] : classes['small']}`} style={style}> {text} </Text>

}