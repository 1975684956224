import React from 'react';
import { useFormContext } from 'react-hook-form';

import AdvancedOptions from './AdvancedOptions';
import Content from './Content';
import Informations from './Informations';
import MainInfo from './MainInfo';
import Section from './Section';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

export default function SubjectForm() {
  const classes = useStyles();
  const theme = useTheme();

  const { watch } = useFormContext();

  return (
    <div className={classes.content}>
      <MainInfo />

      {/* subject doesnt exist, create */}
      {!watch('id') && (
        <div
          className={classes.messageBar}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <InfoIcon className={classes.infoIcon} />
          <span className={classes.messageText}>
            Para adicionar mídias, finalize a criação do módulo primeiro. A
            adição de mídias está disponível na página de Editar Módulo.
          </span>
        </div>
      )}

      <Section title="Informações" Content={Informations} />

      {/* subject exists, edit */}
      {watch('id') && <Section title="Conteúdo" Content={Content} />}

      <Section title="Opções Avançadas" Content={AdvancedOptions} />
    </div>
  );
}
