import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tag: {
    backgroundColor: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.textAndBackground.main,
    lineHeight: '27px',
    borderRadius: '5px',
    minHeight: '27px'
  },
  editTag: {
    display: 'inline-block',
    paddingLeft: '20px',
    margin: '5px',
    '&:hover button': {
      opacity: 1,
      marginRight: '12px'
    },
    '&:hover': {
      paddingLeft: '13px'
    }
  },
  viewTag: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    marginRight: '5px',
    marginBottom: '5px',
    '@media (max-width: 450px)': {
      padding: '0 10px'
    }
  },
  tagName: {
    fontSize: '14px',
    textTransform: 'capitalize',
    '@media (max-width: 450px)': {
      fontSize: 12
    }
  },
  tagDelete: {
    margin: '0 5px',
    opacity: 0
  }
}));
