import React from 'react';

import { Link } from 'react-router-dom';
import history from '~/services/history';
import PropTypes from 'prop-types';

import { Box, useTheme } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/Home';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function DashboardBreadcrumbs({ links }) {
  const theme = useTheme();
  const classes = useStyles({
    theme: theme,
    maxLinkWidth: 100 / Math.max(1, links.length)
  });

  function goToLink(link) {
    const { path, state } = link;

    history.push({
      pathname: path,
      state: state || {}
    });
  }

  return (
    <Box className={classes.root}>
      <Link to="/dashboard">
        <HomeIcon className={classes.homeIcon} />
      </Link>
      {links.map((link, index) => (
        <Box key={index} className={classes.linkContainer}>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
          {/* Essa operação ternária serve para desabilitar o último link do breadbrumbs, ou seja,
                    o link da rota para ela mesma */}
          {index !== links.length - 1 ? (
            <Text
              noWrap
              style={{
                cursor: 'pointer',
                textDecoration: `${link.last && 'underline'}`,
                color: `${link.last && theme.palette.primary.main}`
              }}
              onClick={() => goToLink(link)}
              variant="mediumText"
              color="gray"
            >
              {!link.name && !link.last ? '...' : link.name}
            </Text>
          ) : (
            <Text
              noWrap
              style={{
                cursor: 'pointer',
                textDecoration: `${link.last && 'underline'}`,
                color: `${link.last && theme.palette.primary.main}`
              }}
              variant="mediumText"
              color="gray"
            >
              {link.name}
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
}

DashboardBreadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape())
};

DashboardBreadcrumbs.defaultProps = {
  links: []
};
