import { makeStyles, Box } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  recaptcha: {
    marginTop: '2rem',
    transform: 'scale(1)',

    '@media (max-width: 1050px)': {
      transform: 'scale(0.9)'
    },

    '@media (max-width: 600px)': {
      transform: 'scale(1)'
    },

    '@media (max-width: 450px)': {
      transform: 'scale(0.9)'
    },

    '@media (max-width: 390px)': {
      transform: 'scale(0.8)'
    },

    '@media (max-width: 350px)': {
      transform: 'scale(0.7)'
    }
  }
});

export const WrapperLoading = styled(Box)`
  margin-top: 20px;
  display: flex;
  background-color: white;
  width: 90%;
  height: 50%;
  justify-content: center;
  align-items: center;
`;
