import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import { DisplayAttachmentFile } from '~/components/molecules/DisplayAttachmentFile';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import { useLectureMuralContext } from '~/contexts/LectureMuralContext';
import { getAttachmentsByLecture } from '~/services/Lecture';

import { useStyles } from './styles';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSnackbar } from 'notistack';

export const AttachmentsDashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setContentDisplay, lecture } = useLectureMuralContext();
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(10);

  const infiniteScrollRef = useRef();
  const observer = useRef(null);

  const handleSuccessFetchAttachments = (data) => {
    const attachmentsArray = [...attachments, ...data.status_files];
    setAttachments([...attachmentsArray]);
    if (data.count <= attachmentsArray.length) {
      setHasMore(false);
    } else {
      setPage((prev) => prev + 1);
    }
  };

  const handleFailFetchAttachments = () => {
    enqueueSnackbar(
      'Não foi possível exibir os anexos deste mural. Recarregue a página e tente novamente. Se o erro persistir, contate o suporte.',
      {
        variant: 'error'
      }
    );
    setHasMore(false);
  };

  const getAttachments = async (curPage = page, perPage = 10) => {
    if (isLoading || !hasMore) return;
    try {
      setIsLoading(true);
      const response = await getAttachmentsByLecture(
        lecture.id,
        curPage,
        perPage
      );
      handleSuccessFetchAttachments(response);
    } catch (e) {
      handleFailFetchAttachments();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!infiniteScrollRef.current) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        getAttachments();
      }
    });

    observer.current.observe(infiniteScrollRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [attachments, hasMore, infiniteScrollRef.current, getAttachments]);

  useEffect(() => {
    if (attachments.length === 0) getAttachments(1, 100);
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box disableRipple className={classes.titleBox}>
          <ButtonBase
            onClick={() => setContentDisplay('discussion')}
            className={classes.backButton}
          >
            <ArrowBackIosRoundedIcon />
          </ButtonBase>
          <Typography className={classes.titleText}>
            Todos os arquivos anexados
          </Typography>
        </Box>
        <Tabs
          value={0}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            disableRipple
            disableFocusRipple
            style={{ cursor: 'auto' }}
            label={
              <Box
                display="flex"
                alignItems="center"
                style={{
                  gap: '8px',
                  textTransform: 'none'
                }}
              >
                <PhotoLibraryIcon />
                <Typography className={classes.tabText}>Anexos</Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>
      <Box className={classes.attachments}>
        {attachments.map((item, index) => (
          <Box key={index} className={classes.attachmentCard}>
            <Box className={classes.attachmentCardHeader}>
              <Typography className={classes.attachmentCardHeaderText}>
                {item.user ? item.user.name : 'Usuário Excluído'}
              </Typography>
              <Typography className={classes.attachmentCardHeaderText}>
                {format(new Date(item.created_at), "d 'de' MMMM 'de' yyyy", {
                  locale: ptBR
                })}
              </Typography>
            </Box>
            <DisplayAttachmentFile
              attachment={item}
              downloadable={lecture.downloadable_status || lecture.can_manage}
            />
          </Box>
        ))}
        <Box className={classes.feedbackContainer}>
          {isLoading && (
            <CircularProgress
              size="24px"
              style={{ color: theme.palette.activeText.main }}
            />
          )}
          {!isLoading && !hasMore && attachments.length === 0 && (
            <GenericFeedbackLayout
              ImageIcon={TropicalIslandImage}
              title={'Nenhum anexo'}
              description={'Não há nada a ser exibido.'}
            />
          )}
          <Box ref={infiniteScrollRef} />
        </Box>
      </Box>
    </Box>
  );
};
