import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import simpleUploadBuilder from '~/utils/ckeditor/plugins/simpleUpload';

import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import uniqid from 'uniqid';

const editableStyles = {
  padding: '30px 20px',
  border: '1px solid #c4c4c4',
  borderTop: 'transparent'
};

export default function EditorField({ startText, value, onChange, ...rest }) {
  const { auth } = useSelector((state) => state);

  const [editorID, setEditorID] = useState(uniqid());
  const [editor, setEditor] = useState(null);

  const { watch } = useFormContext();

  const simpleUpload = simpleUploadBuilder(watch('subjectID'), auth.token);
  const editorConfig = {
    toolbar: {
      shouldNotGroupWhenFull: true
    },
    simpleUpload
  };

  const queryCkEditorClassName = () => {
    var element = document.getElementsByClassName('ck-balloon-panel');
    for (let i = 0; i < element.length; i++) {
      element[i].style.zIndex = 1400;
    }
  };

  const initEditor = (editor) => {
    const toolbarContainer = document.querySelector(
      `#document-editor__toolbar-${editorID}`
    );
    toolbarContainer.appendChild(editor.ui.view.toolbar.element);

    editor.model.document.on('change:data', () => {
      onChange(editor.getData());
    });

    if (value) editor.setData(value);

    setEditor(editor);
  };

  useEffect(() => {
    Editor.create(
      document.querySelector(`#document-editor__editable-${editorID}`),
      editorConfig
    )
      .then(initEditor)
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (editor && value === '') editor.setData(value);
  }, [value]);

  useEffect(() => {
    if (editor && startText) editor.setData(startText);
  }, [startText]);

  return (
    <div {...rest}>
      <div
        onClick={queryCkEditorClassName}
        id={`document-editor__toolbar-${editorID}`}
      ></div>
      <div
        id={`document-editor__editable-${editorID}`}
        style={editableStyles}
      ></div>
    </div>
  );
}
