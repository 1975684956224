import React, { useState } from 'react';

import { Box, Menu } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function PopUpMenu({
  component: Component,
  componentProps,
  children
}) {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(null);
  };

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  return (
    <>
      <Component
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
        {...componentProps}
      />
      <Menu
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
      >
        <Box onClick={handleClose}>{children}</Box>
      </Menu>
    </>
  );
}

PopUpMenu.propTypes = {
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.object,
  children: PropTypes.node
};

PopUpMenu.defaultProps = {
  componentProps: {},
  children: null
};
