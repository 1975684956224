import { makeStyles, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: 'gray'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

export const useStyles = makeStyles({
  container: {
    minWidth: 230,
    maxWidth: 230,
    '@media (max-width: 863px)': {
      minWidth: '100%',
      maxWidth: '100%'
    },
    workloadSpan: { fontSize: '8px !important' },
    minHeight: 185,
    maxHeight: 185,
    background: '#F2F2F7',
    borderRadius: '12px',
    borderBottom: '0',
    boxSizing: 'border-box',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: '#E1E1E5'
      // '& p#course__title': {
      //   textDecoration: 'underline'
      // }
    }
  },
  container__header: {
    position: 'relative',
    width: '100%',
    height: '40%'
  },
  container__title: {
    width: '100%',
    paddingLeft: '10px',
    position: 'absolute'
  },
  container__image: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '12px 12px 0 0',
    objectFit: 'cover'
  },
  container__popup: {
    position: 'absolute',
    top: 10,
    right: 5
  },
  title__wraper: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  iconCustomWraper: {
    padding: 0,
    margin: 0,
    width: 25,
    height: 25,
    background: '#1B60F3',
    '&:hover': { background: '#083491' },
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: 50,
      width: 50,
      marginTop: 0
    }
  },
  iconCustom: {
    padding: 0,
    margin: 0,
    width: 15,
    height: 15,
    color: 'white'
  },
  description__wraper: {
    width: '100%',
    display: 'flex',
    flex: 1,
    minHeight: 30,
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  container__description: { width: '40%' },
  linearProgress: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
    colorPrimary: {
      backgroundColor: 'gray'
    }
  },
  container__hashtags: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
    bottom: 5,
    columnGap: 5,
    padding: '5px 10px',
    '& p': {
      textAlign: 'center',
      marginRight: 10,
      '&:last-child': { marginRight: 0 }
    }
  },
  container__mirrored: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    bottom: 5,
    padding: '0px 10px'
  },

  customTooltip: {
    fontSize: '14px !important',
    width: '100% !important',
    lineHeight: '24px !important',
    maxWidth: '700px !important',
    backgroundColor: '#676D82 !important'
  },

  wrapProgress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottom: {
    color: 'red'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
