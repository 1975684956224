import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getMembersRequest: ['page'],
  getMembersSuccess: ['members', 'currentMembersData'],
  getMembersFailure: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  members: [],
  currentMembersData: [],
  page: 1,
  loading: false,
  noMoreStatuses: false
};

const getMembersRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getMembersSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.page += 1;
    if (draft.currentMembersData.length < 10) draft.loading = false;
    if (!action.members || !action.members.length) draft.noMoreStatuses = true;
    else {
      draft.members = draft.members.concat(action.members);
      draft.currentMembersData = action.members;
    }
  });

const getMembersFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_MEMBERS_REQUEST]: getMembersRequest,
  [Types.GET_MEMBERS_SUCCESS]: getMembersSuccess,
  [Types.GET_MEMBERS_FAILURE]: getMembersFailure
});
