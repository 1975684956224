import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    ButtonConnection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '112px',
        height: '31px',
        background: '#489AF8',
        borderRadius: '12px',
        margin: '8px auto 0',
        cursor: 'pointer'
    },
}));