import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getFormConclusionReport } from '~/services/reports/spaces';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function FormConclusionReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getFormConclusionReport}
        baseName="relatório_de_status_do_questionário_"
        id={id}
      />
    </div>
  );
}

FormConclusionReport.propTypes = {
  id: PropTypes.string
};

FormConclusionReport.defaultProps = {
  id: ''
};
