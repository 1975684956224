import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return {
    recaptcha: {
      transform: 'scale(1)',
      '@media (max-width: 1050px)': {
        transform: 'scale(0.9)',
      },

      '@media (max-width: 600px)': {
        transform: 'scale(0.8)',
      },

      '@media (max-width: 450px)': {
        transform: 'scale(0.7)',
      },

      '@media (max-width: 390px)': {
        transform: 'scale(0.6)',
      },
    },
    recaptchaContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  };
});
