import React from 'react';

import { useStyles } from './styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function InterestTag({ index, fields, input, edit, name }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box
      className={`${classes.tag} ${edit ? classes.editTag : classes.viewTag}`}
    >
      <label className={classes.tagName}>{input ? input.value : name}</label>
      {edit && (
        <button
          type="button"
          className={classes.tagDelete}
          onClick={() => fields.remove(index)}
        >
          <FontAwesomeIcon
            icon={faTimes}
            color={theme.palette.textAndBackground.main}
            style={{ fontSize: 12 }}
          />
        </button>
      )}
    </Box>
  );
}

InterestTag.propTypes = {
  input: PropTypes.shape(),
  fields: PropTypes.shape(),
  index: PropTypes.number,
  edit: PropTypes.bool,
  name: PropTypes.string
};

InterestTag.defaultProps = {
  input: null,
  fields: null,
  index: 0,
  edit: false,
  name: ''
};
