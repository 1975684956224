import React from 'react';

import {
  Box,
  CardActions,
  CardContent,
  Button,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR, enUS, es } from 'date-fns/locale';
import styled from 'styled-components';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

export const useStyles = makeStyles((theme) => ({
  containerCard: {
    '@media (max-width: 1024px)': {
      margin: '0px !important'
    }
  },
  container: {
    padding: 20,
    '@media (max-width: 1024px)': {
      padding: '20px 0px'
    }
  },
  lgAvatar: {
    '@media (max-width: 450px)': {
      display: 'none'
    }
  },
  smAvatar: {
    display: 'none',
    '@media (max-width: 450px)': {
      display: 'block'
    }
  },
  lgButtonsContainer: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  smButton: {
    display: 'none',
    width: 28,
    minWidth: 28,
    height: 28,
    padding: 0,
    borderRadius: '50%',
    '@media (max-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  smButtonCheck: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  smButtonCancel: {
    border: `1px solid ${theme.palette.secondary.main}`
  }
}));

export const Container = styled(({ ...otherProps }) => (
  <Box component="div" {...otherProps} />
))``;

export const ActionsCard = styled(({ ...otherProps }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <CardActions
      {...otherProps}
      style={{ justifyContent: matches ? 'flex-end' : 'space-between', padding: 16 }}
    />
  );
})``;

export const ButtonOutlined = styled(({ ...otherProps }) => (
  <Button
    {...otherProps}
    style={{ textTransform: 'capitalize', fontSize: 14, height: 40 }}
    size="large"
    variant="outlined"
    color="primary"
  />
))``;
export const ButtonContained = styled(({ ...otherProps }) => (
  <Button
    {...otherProps}
    style={{ textTransform: 'capitalize', fontSize: 14, height: 40 }}
    size="large"
    variant="contained"
    color="primary"
    disableElevation
  />
))``;

export const ContainerElements = styled(({ ...otherProps }) => (
    <Box
      {...otherProps}
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    />
  ))``;

export const ContentCard = styled(({ ...otherProps }) => (
  <CardContent {...otherProps} style={{ flex: 1 }} />
))``;

export const ContainerInfo = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="column"
    style={{ marginLeft: 10, flex: 1 }}
  />
))``;

export const InfoName = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    alignItems="center"
  />
))``;

export const PaperContentCard = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="row"
    style={{ flex: 1, alignItems: 'center' }}
  />
))``;

export const TimeFormated = styled(({ ...otherProps }) => {
  const { date } = otherProps;
  const locale = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': es };
  const timeDistance = format(new Date(date), 'dd MMMM, yyyy', { locale: locale[i18n.language] })
  return (
    <Text variant="friendshipRequestDate" color="gray">
      {timeDistance}
    </Text>
  );
})``;

export const ContainerCard = styled(({ ...otherProps }) => (
  <Box {...otherProps} />
))`
  -webkit-box-shadow: 0 0.3rem 0.3rem 0.1rem rgba(211, 216, 224, 0.4);
  -moz-box-shadow: 0 0.3rem 0.3rem 0.1rem rgba(211, 216, 224, 0.4);
  box-shadow: 0 0.3rem 0.3rem 0.1rem rgba(211, 216, 224, 0.4);
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1;
  border-radius: 1rem 1rem 1rem 1rem;
  -moz-border-radius: 1rem 1rem 1rem 1rem;
  -webkit-border-radius: 1rem 1rem 1rem 1rem;
  border: 0.1rem solid #ebebeb;
`;
