import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 5px 10px 0'
  }
});
