import * as yup from 'yup';

export const loginValidations = yup
  .object()
  .shape({
    login: yup.string().required('Este campo é obrigatório.'),
    password: yup
      .string()
      .min(6, 'A senha precisa ter no mínimo seis caracteres.')
      .required('Este campo é obrigatório.'),
    recaptcha:
      process.env.NODE_ENV !== 'production'
        ? undefined
        : yup.bool().oneOf([true], 'Você precisa marcar o reCAPTCHA.')
  })
  .required();
