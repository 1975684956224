import React from 'react';
import { Collapse, Grid, Switch } from '@material-ui/core';
import { TextPrimary } from '~/utils/forms';

import { useStyles } from './styles'
import Field from '~/components/organisms/GenericFormWrapper/Field'
import { useFormContext } from 'react-hook-form';
import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import i18n from '~/I18n';

function Scheduleable() {
  const classes = useStyles()
  const { control, watch } = useFormContext()

  return (
      <Grid container>
        <Grid item xs={12} className={classes.switch}>
          <Field  label={TextPrimary(`Programar abertura e fechamento do ${i18n.t('StructuresName.Subjects.SingularLower')}`)} 
                  labelPlacement={'end'} 
                  name="scheduleable" 
                  control={control} 
                  checked={watch("scheduleable")}
                  Component={Switch}
                  size="small" 
                  />
        </Grid>

        <Collapse in={watch("scheduleable")}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
                
                <Grid item xs={{xs: 12, sm: null}}>
                  <Field label={TextPrimary("Início")} name="subject_opening" control={control}  Component={DateTimePickerInput}/>
                </Grid>

                <Grid item xs={{xs: 12, sm: null}}>
                                                                                  {/* Acrescenta uma hora ao Time atual */}
                  <Field label={TextPrimary("Fim")} name="subject_closure" control={control} defaultValue={new Date(Date.now() + 3600000)} Component={DateTimePickerInput}/>
                </Grid>

            </Grid>
          </Grid>
        </Collapse>
      
      </Grid>
    );
}

export default Scheduleable;