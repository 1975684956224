import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as EmptyFeedbackOverview } from '~/assets/images/casual-work-environment.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import MuralTemplate from '~/components/templates/Mural';
import api from '~/services/api';

import PropTypes from 'prop-types';

export default function Overview({ target, userID }) {
  const { profile } = useSelector((state) => state.user);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const [friendshipRequests, setFriendshipRequest] = useState(null);

  function getMyOverview() {
    if (profile) {
      const curUserID = userID || profile.id;
      api
        .get(`api/users/${curUserID}/overview`, {
          params: {
            page: page
          }
        })
        .then((response) => {
          if (response.data.length < 10) {
            setHasMore(false);
          }
          if (response.data.length > 0) {
            setStatuses([...statuses, ...response.data]);
            setPage(page + 1);
          }
        })
        .catch(() => {
          setHasMore(false);
        });
    }
  }

  function getFriendshipRequest() {
    if (profile && (!userID || userID == profile.id)) {
      setLoading(true);
      api
        .get(`/api/users/${profile.id}/connections`, {
          params: { status: 'pending' }
        })
        .then((response) => {
          setLoading(false);
          setFriendshipRequest(response.data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getMyOverview();
    getFriendshipRequest();
  }, []);

  return (
    <MuralTemplate
      loading={loading}
      statuses={statuses}
      hasMore={hasMore}
      target={target}
      getStatuses={getMyOverview}
      friendshipRequests={friendshipRequests}
      isDashboard
      setStatuses={setStatuses}
      emptyFeedbackComponent={
        <GenericFeedbackLayout
          ImageIcon={EmptyFeedbackOverview}
          title="Aqui é onde você pode acompanhar as novidades"
          description="Você pode ver novas atualizações dos seus ambientes, aulas adicionadas e acompanhar o que suas conexões estão fazendo."
        />
      }
    />
  );
}

Overview.propTypes = {
  target: PropTypes.string,
  userID: PropTypes.string
};

Overview.defaultProps = {
  target: ''
};
