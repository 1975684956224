import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  paper: {
    minWidth: '320px',
    width: '40%',
    borderRadius: '8px',
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main
    },
    '@media (min-width: 0px) and (max-width: 450px)': {
      width: '90%'
    },
    '@media (min-width: 451px) and (max-width: 1190px)': {
      width: '60%'
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '16px',
    width: '100%'
  },
  button: {
    padding: '8px 24px'
  },
  cancelButton: {
    fontSize: '16px',
    fontHeight: '24px',
    color: theme.palette.primary.main
  },
  importButton: {
    fontSize: '16px',
    fontHeight: '24px',
    color: 'white'
  }
}));
