import api from '~/services/api';

const baseURL = '/api/replicable_processes';

export const getAll = async (page) => {
  const { data } = await api.get(`${baseURL}?page=${page}`);
  return data;
};

export const remove = async (processID) => {
  const { data } = await api.delete(`${baseURL}/${processID}`);
  return data;
};

export default { getAll, remove };
