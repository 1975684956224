import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  text: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  icon: {
    fontSize: '24px'
  }
});
