import settings from '~settings';

const redu = {
  MyEnvironments: 'Mis Ambientes',
  InfoEnvironments: 'Información del Ambientes',
  InfoCourses: 'Información del curso',
  InfoSpace: 'Información de disciplina',
  Hours: 'horas',
  Message: 'Mensaje',
  Messages: 'Mensajes',
  CreateEnvironment: 'Crear ambiente',
  CreateMirroredEnvironment: 'Crear Ambiente Espelhado',
  EmptyListEnvironments: 'Aún no tienes ningún ambiente.',
  NoSubscription: 'Sin ambiente',
  AddSubscription: 'Añadir ambiente',
  ManageEnvironment: 'Gestionar Ambiente',
  EditEnvironment: 'Editar Ambiente',
  NoCoursesForThisEnvironment: 'Este ambiente no tiene cursos',
  NoSpacesForThisCourse: 'Este curso no tiene asignaturas.',
  lastClass: 'Última media accedida',
  nextClasses: 'Próximas medias',
  CreateCourse: 'Crear Curso',
  EditCourse: 'Editar Curso',
  ManageCourse: 'Gestionar Curso',
  CourseInformation: 'Información del curso',
  CommonCourses: 'Cursos comunes',
  AddNewMemberToCourse: 'Añadir nuevo miembro al Curso',
  ofTheSpace: 'de la Asignatura',
  myCourses: 'Mis Asignaturas',
  noCourses: 'Sin asignaturas',
  ManageDiscipline: 'Gestionar Asignatura',
  Disciplines: 'Asignaturas',
  AddDisciplineToCourse: 'Añadir asignatura',
  AllModules: 'Todos los módulos',
  NoClassesOnThisModule: 'Este módulo no tiene medias aún',
  Classes: 'Medias',
  commentedLecture: 'comentó en la media',
  helpLecture: 'pidió ayuda en la media',
  EmptyLectureSumaryList: 'No has accedido a ninguna media recientemente',
  noNextClasses: 'No hay próximas medias',
  Environments: 'Ambientes',
  Subjects: 'Módulo',
  Lectures: 'Clase',
  CreateSubject: {
    title: 'Crear Módulo',
    saveButton: 'Crear'
  },
  CreateMirroredSubject: {
    title: 'Crear Módulo Espelhado',
    saveButton: 'Crear'
  },
  EditSubject: {
    title: 'Editar Módulo',
    saveButton: 'Guardar'
  },
  CreateSpace: {
    title: 'Crear Asignatura',
    saveButton: 'Crear'
  },
  CreateMirroredSpace: {
    title: 'Crear Asignatura Espelhada',
    saveButton: 'Crear'
  },
  EditSpace: {
    title: 'Editar Asignatura',
    saveButton: 'Guardar'
  },
  CreateMirroredCourse: {
    title: 'Crear Curso Espelhado',
    saveButton: 'Crear'
  },
  EditMirroredCourse: {
    title: 'Editar Curso',
    saveButton: 'Guardar'
  },
  DeleteEnvironment: {
    title: 'Eliminar Ambiente',
  },
  DeleteMirroredEnvironment: {
      title: 'Eliminar Ambiente Espejo'
  },
  DeleteCourse: {
      title: 'Eliminar Curso'
  },
  DeleteMirroredCourse: {
      title: 'Eliminar Curso Espejo'
  },
  DeleteSpace: {
      title: 'Eliminar Asignatura'
  },
  DeleteMirroredSpace: {
      title: 'Eliminar Asignatura Espejo'
  },
  DeleteSubject: {
      title: 'Eliminar Módulo'
  },
  DeleteMirroredSubject: {
      title: 'Eliminar Módulo Espejo'
  },
  DeleteMedia: {
      title: 'Eliminar Medio'
  },
  DeleteMirroredMedia: {
      title: 'Eliminar Medio Espejo'
  },
  StructuresName: {
    Environment: {
      SingularUpper: 'Ambiente',
      PluralUpper: 'Ambientes',
      SingularLower: 'ambiente',
      PluralLower: 'ambientes',
    },
    Course: {
      SingularUpper: 'Curso',
      SingularLower: 'curso',
      PluralUpper: 'Cursos',
      PluralLower: 'cursos',
    },
    Space: {
      SingularUpper: 'Asignatura',
      PluralUpper: 'Asignaturas',
      SingularLower: 'asignatura',
      PluralLower: 'asignaturas',
    },
    Subjects: {
      SingularUpper: 'Módulo',
      PluralUpper: 'Módulos',
      SingularLower: 'módulo',
      PluralLower: 'módulos',
    },
    Lectures: {
      SingularLower: 'media',
      PluralLower: 'medias',
      PluralUpper: 'Medias',
      SingularUpper: 'Media'
    }
  }
};

export function selectEnglishWordsByClient() {
  switch (settings.name) {
    case 'redu':
      return redu;

    default:
      return redu;
  }
}
