import React from 'react';
import { useFormContext } from 'react-hook-form';
import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';

const fileTypes = [ 
  'image/bmp', 
  'image/x-windows-bmp', 
  'image/gif', 
  'image/jpeg',
  'image/pjpeg', 
  'image/png',
]
export default function Image() {
  const { control } = useFormContext()

  return <Field name="lectureable.image.file" 
                control= { control } 
                Component={DragDropFileInput} 
                permittedFileTypes={fileTypes} 
                fileTypesDescription = {<div>Apenas arquivos de imagem serão permitidos. <br />Tamanho máximo de arquivos: <strong>10 MB</strong>.</div>}
                />
}
