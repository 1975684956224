export default {
  versionName: 'AAA',
  versionNumber: '0.0.1',
  name: 'Conecta+ AVA',
  client: 'conectamais',
  websocketSecret: 'OUBtCXoayoc2uptN5tvuCCJvej',
  websocketKey: 'DEu3vO#gPJGMYT@US@YxWNJFAzLUy^BUZLAdu8Bxa6DFrLWR',
  appstore: null,
  playstore: 'https://play.google.com/store/apps/details?id=com.viitra.admtec',
  googleAuthClientId:
    '590034191650-rmrfddb4s0bjpij8nd40p78ljo7midqj.apps.googleusercontent.com',
  googleAuthScopes: [
    'https://www.googleapis.com/auth/calendar.app.created'
  ].join(' ')
};
