import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0 -48px',
    '@media (max-width: 1100px)': {
      margin: '0 -8px',
    },
  },
  muralItemContainer: {
    marginTop: 3,
    marginBottom: 3,
  },
  shimmerContainer: {
    marginTop: 3,
    marginBottom: 3,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      padding: '15px 0',
    },
  },
  dashboardShimmer: {
    padding: '15px 75px',
    '@media (max-width: 1400px)': {
      padding: '15px 0px 15px 0px',
    },
  },
  profileShimmer: {
    padding: '15px 0 15px 8px'
  },
  lgShimmerAvatar: {
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  smShimmerAvatar: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
    },
  },
  lgShimmerText: {
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  smShimmerText: {
    display: 'none',
    '@media (max-width: 700px)': {
      display: 'block',
    },
    '@media (max-width: 300px)': {
      display: 'none',
    },
  },
  xsShimmerText: {
    display: 'none',
    '@media (max-width: 300px)': {
      display: 'block',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  button: {
    height: 40,
    minWidth: 120,
    width: 120,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  loading: {
    strokeLinecap: 'round',
    color: 'white',
  },
}));
