import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '100%',
    marginBottom: '16px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  title: {
    fontSize: '19px',
    fontWeight: '700',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  },
  descriptionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  descriptionText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.activeText.main
  }
}));
