import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 700
    },
    meet: {
        flex: 1,
        width: '100%',
        border: 'none',
        minHeight: 600
    },
    buttonArea: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        alignItems: 'center',
        marginBottom: 20,
        "& p": {
            marginBottom: 10
        },
        "& button:hover": {
            backgroundColor: props => props.theme.palette.secondary.main
        }
    },
    buttonText: {
        color: 'white',
        fontSize: 12
    }
});