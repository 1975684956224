import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '70%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1300px)': {
      width: '100%',
    },
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 5px 10px 0'
  }
});