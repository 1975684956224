import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { Box, List, ListItem, ListItemText } from '@material-ui/core';

import api from '~/services/api';
import i18n from '~/I18n';

export default function Subjects() {
  const [subjects, setSubjects] = useState(null);
  const [subject, setSubject] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function handleCreateSubjects() {
      const newSubject = {
        subject: {
          name: `Novo ${i18n.t('StructuresName.Subjects.SingularLower')}`,
          description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit'
        }
      };

      try {
        const response = await api.post(
          `/api/spaces/${id}/subjects/enrollments`,
          newSubject
        );
        setSubject(response.data);
      } catch (err) {}
    }

    async function handleGetSubjects() {
      try {
        const response = await api.get(
          `/api/spaces/${id}/subjects/enrollments`
        );
        setSubjects(response.data);
      } catch (err) {}
    }

    handleCreateSubjects();
    handleGetSubjects();
  }, []);

  useEffect(() => {
    async function handleGetSubject() {
      try {
        const response = await api.get(`/api/subjects/${subject.id}`);
      } catch (err) {}
    }
    if (subject) handleGetSubject();
  }, [subject]);

  return (
    <Box component="div">
      <Link
        to={`/dashboard/subject/${subject && subject.id}`}
        style={{ fontSize: 14 }}
      >
        Ir para o modulo criado com o id {subject && subject.id}
      </Link>
      <List>
        {subjects &&
          subjects.map((item) => (
            <ListItem
              key={item.id}
              button
              component={Link}
              to={`/dashboard/subject/${item.id}`}
              style={{ fontSize: 14 }}
            >
              <ListItemText style={{ fontSize: 14 }} primary={item.name} />
            </ListItem>
          ))}
      </List>
    </Box>
  );
}
