import api from '~/services/api';
import { readFile } from '~/utils/imageUtils';

export const updateEnvironment = async (environmentID, fields) => {
  delete fields.enable_zoom;
  delete fields.enable_youtube;
  delete fields.enable_pagseguro;

  fields.tag_list = formatTagList(fields.keywords);
  delete fields.keywords;

  if (fields.new_avatar_attributes?.size) {
    fields.new_avatar_attributes = await handleAvatar(
      fields.new_avatar_attributes
    );
  } else {
    delete fields.new_avatar_attributes;
  }

  const body = { environment: { ...fields, published: true } };
  delete body.environment['enrollment_link_attributes'];

  return await api.put(`/api/environments/${environmentID}`, body);
};

export const getEnvironment = async (environmentID) => {
  const { data } = await api.get(`api/environments/${environmentID}`);
  return data;
};

const formatTagList = (keywords) => {
  if (keywords.length > 0) {
    return keywords.map((key) => key.name).join(',');
  }

  return null;
};

const handleAvatar = async (file) => {
  if (file) {
    var dataUrl = await readFile(file);
    return { image: dataUrl, image_file_name: file.name };
  }

  return null;
};

export const deleteEnvironment = async (environmentID) => {
  const { data } = await api.delete(`api/environments/${environmentID}`);
  return data;
};
