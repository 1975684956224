import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonGoToAttachments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(112,112,112,0.2)',
    padding: '15px'
  },
  buttonGoToAttachmentsText: {
    color: theme.palette.activeText.main,
    fontSize: '14px',
    lineHeight: '16px'
  },
  buttonGoToAttachmentsLibraryIcon: {
    color: theme.palette.activeText.main,
    fontSize: '24px'
  },
  buttonGoToAttachmentsArrowIcon: {
    color: theme.palette.activeText.main,
    fontSize: '16px'
  },
  flexContainerWithGap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));
