import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainArea: {
    height: '88px',
    width: '100%',
    borderRadius: '5px',
    border: (props) =>
      props.colorScheme === 'primaryBg'
        ? 'none'
        : `1px solid ${props.theme.palette.primary.main}`,
    padding: '5px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: 'white'
  },
  interestsList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    height: '27px',
    fontSize: '14px',
    margin: '5px',
    padding: 0,
    flexGrow: 1,
    '&::placeholder': {
      color: (props) => props.theme.palette.textActiveElement.main,
      opacity: 0.75
    },
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  tag: {
    backgroundColor: (props) => props.theme.palette.secondary.main,
    color: (props) => props.theme.palette.textAndBackground.main,
    lineHeight: '27px',
    borderRadius: '5px',
    minHeight: '27px'
  },
  editTag: {
    display: 'inline-block',
    paddingLeft: '20px',
    margin: '5px',
    '&:hover button': {
      opacity: 1,
      marginRight: '12px'
    },
    '&:hover': {
      paddingLeft: '13px'
    }
  },
  viewTag: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    marginRight: '5px',
    marginBottom: '5px',
    '@media (max-width: 450px)': {
      padding: '0 10px'
    }
  },
  tagName: {
    fontSize: '14px',
    textTransform: 'capitalize',
    '@media (max-width: 450px)': {
      fontSize: 12
    }
  },
  tagDelete: {
    margin: '0 5px',
    opacity: 0
  }
});
