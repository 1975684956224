import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '16px'
  },
  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.activeText.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F5F5F5'
    },
    '&:active': {
      color: theme.palette.activeText.main,
      backgroundColor: '#E1E1E5'
    }
  },
  confirmButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  }
}));
