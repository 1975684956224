import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: 644,
    height: 254,
    position: 'absolute',
    padding: '10px 10px 20px 20px',
    marginBottom: 10,
    zIndex: 11,
    transform: 'translateY(-25px) translateX(25px)',
    '@media (max-width: 1024px)': {
      width: 312,
      height: 432,
      padding: '10px 10px 16px 16px'
    },
    '@media (max-width: 370px)': {
      width: '90%',
      transform: 'translateY(-25px) translateX(-10px)'
    }
  },
  paperRouded: {
    borderRadius: '10px'
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeIcon: {
    color: (props) => `${props.theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  // o textOverflow não está funcionando
  name: {
    width: '100%',
    overflow: 'hidden',
    display: 'inline-block',
    direction: 'ltr',
    '& p': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  content: {
    width: '100%',
    height: 170,
    display: 'flex',
    marginTop: 10,
    '& ::-webkit-scrollbar': {
      width: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.primary.main}`,
      bordeRadius: '2px'
    },
    '@media (max-width: 1024px)': {
      display: 'block',
      height: 210
    }
  },
  image: {
    width: 280,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 1em rgba(0,0,0,0.2)',
    '@media (max-width: 1024px)': {
      height: 136
    },
    '@media (max-width: 370px)': {
      width: '100%'
    }
  },
  srcImg: {
    width: '100%',
    height: '100%'
  },
  details: {
    width: 334,
    height: '100%',
    paddingLeft: 16,
    overflow: 'auto',
    '@media (max-width: 1024px)': {
      width: 280,
      paddingLeft: 0
    },
    '@media (max-width: 370px)': {
      width: '100%'
    }
  },
  description: {
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 6,
    '@media (max-width: 1024px)': {
      marginTop: 15
    }
  },
  detailsItem: {
    width: '100%',
    display: 'flex',
    marginBottom: 8
  },
  label: {
    minWidth: 100
  },
  itemContent: {
    width: 218,
    wordWrap: 'break-word',
    '@media (max-width: 1024px)': {
      width: 164
    }
  },
  keyWords: {
    width: 218,
    display: 'flex',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
    '@media (max-width: 1024px)': {
      width: 164
    }
  },
  keyWordsItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 6px',
    margin: '6px 6px 0 0',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: (props) => `${props.theme.palette.textActiveElement.main}`
  }
});
