import base from './base';
const baseUrl = 'https://beta.adams.mastersolucoesedu.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjwm7igy4';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'nosD8VccRTLsXVAqjrQPZWTd5MUdZm8S3b4YuFRerk',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://beta.adams.mastersolucoesedu.com.br:44443',
  clarityKey
};
