import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker as MaterialPicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

export default function DateTimePickerInput(props) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <MaterialPicker
          { ...props }
          autoOk
          className={classes.picker}
          inputVariant="outlined"
          format="dd/MM/yyyy H:mm"
          ampm={false}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

DateTimePickerInput.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string,
};

DateTimePickerInput.defaultProps = {
  input: {},
  label: '',
};
