import React from 'react'

import { TextField, useTheme } from '@material-ui/core'

export default function LinkInput ({ QRCodeLink }){
  const theme = useTheme()

  return (
    <TextField  fullWidth 
                variant="outlined" 
                color="primary"
                value={QRCodeLink}
                size="small" 
                disabled
                inputProps={{ style: { color: theme.palette.primary.main, fontSize: 13 }}}
                />              
  )
}