import React, { useMemo, useState } from 'react';

import { ReactComponent as FileFormatDefault } from '~/assets/images/fileFormatDefault.svg';
import { ReactComponent as FileFormatDOC } from '~/assets/images/fileFormatDOC.svg';
import { ReactComponent as FileFormatGIF } from '~/assets/images/fileFormatGIF.svg';
import { ReactComponent as FileFormatJPEG } from '~/assets/images/fileFormatJPEG.svg';
import { ReactComponent as FileFormatJPG } from '~/assets/images/fileFormatJPG.svg';
// import { ReactComponent as FileFormatMP3 } from '~/assets/images/fileFormatMP3.svg';
// import { ReactComponent as FileFormatMP4 } from '~/assets/images/fileFormatMP4.svg';
import { ReactComponent as FileFormatPDF } from '~/assets/images/fileFormatPDF.svg';
import { ReactComponent as FileFormatPNG } from '~/assets/images/fileFormatPNG.svg';
import { ReactComponent as FileFormatPPT } from '~/assets/images/fileFormatPPT.svg';
import { ReactComponent as FileFormatTXT } from '~/assets/images/fileFormatTXT.svg';
import { ReactComponent as FileFormatXLS } from '~/assets/images/fileFormatXLS.svg';
import { getAttachment } from '~/services/status-file';
import settings from '~/settings';
import { formatFileSize } from '~/utils/lectureMuralAuxFunctions';

import { useStyles } from './styles';
import { Box, ButtonBase, Modal, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import RemoveIcon from '@material-ui/icons/Remove';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const fileTypeIconMap = {
  png: FileFormatPNG,
  jpg: FileFormatJPG,
  jpeg: FileFormatJPEG,
  // mp4: FileFormatMP4,
  gif: FileFormatGIF,
  pdf: FileFormatPDF,
  ppt: FileFormatPPT,
  pptx: FileFormatPPT,
  xls: FileFormatXLS,
  xlsx: FileFormatXLS,
  doc: FileFormatDOC,
  docx: FileFormatDOC,
  txt: FileFormatTXT
  // mp3: FileFormatMP3
};

export const FullScreenImageVisualization = ({
  attachment,
  open,
  closeModal,
  downloadable
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [imagemScale, setImageScale] = useState(1);

  const formattedSize = useMemo(() => formatFileSize(attachment.size), [
    attachment.size
  ]);
  const isDisabledNextScaleButton = useMemo(() => imagemScale === 1.5, [
    imagemScale
  ]);
  const isDisabledBackScaleButton = useMemo(() => imagemScale === 1, [
    imagemScale
  ]);

  const attachmentFormat = useMemo(
    () => attachment.name?.split('.')[attachment.name?.split('.').length - 1],
    [attachment.name]
  );

  const FileIcon = useMemo(
    () => fileTypeIconMap[attachmentFormat] || FileFormatDefault,
    [attachmentFormat]
  );

  const upScale = () => {
    setImageScale((prev) => prev + 0.25);
  };

  const downScale = () => {
    setImageScale((prev) => prev - 0.25);
  };

  const handleClose = () => {
    closeModal();
    setImageScale(1);
  };

  const getFileToDownload = async () => {
    try {
      const data = await getAttachment(attachment.id);
      fileDownload(data, attachment.name);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível realizar o download desse anexo. Recarregue a página e tente novamente. Se o erro persistir, contate o suporte.',
        {
          variant: 'error'
        }
      );
    }
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={() => <></>}
      className={classes.modal}
    >
      <Box className={classes.modalBackdrop}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalContentHeader}>
            <Box className={classes.modalContentHeaderInfo}>
              <FileIcon />
              <Box style={{ overflowX: 'hidden' }}>
                <Typography className={classes.attachmentName}>
                  {attachment.name}
                </Typography>
                <Typography className={classes.attachmentSize}>
                  {`Tamanho ${formattedSize}`}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.modalContentHeaderAction}>
              {downloadable && (
                <ButtonBase disableRipple onClick={getFileToDownload}>
                  <GetAppRoundedIcon
                    className={classes.modalContentHeaderActionIcon}
                  />
                </ButtonBase>
              )}
              <ButtonBase disableRipple onClick={handleClose}>
                <CloseRoundedIcon
                  className={classes.modalContentHeaderActionIcon}
                />
              </ButtonBase>
            </Box>
          </Box>

          <Box className={classes.imageContainer}>
            <img
              src={`${settings.baseUrl}${attachment?.url}`}
              style={{
                height: '600px',
                transform: `scale(${imagemScale})`
              }}
              className={classes.image}
            />
          </Box>

          <Box
            display={{
              xs: 'none',
              md: 'flex'
            }}
            className={classes.scaleButtonsContainer}
          >
            <ButtonBase
              style={{
                borderRadius: '20px 0 0 20px'
              }}
              className={classes.scaleButton}
              onClick={downScale}
              disabled={isDisabledBackScaleButton}
            >
              <RemoveIcon
                style={{
                  fontSize: '24px',
                  color: isDisabledBackScaleButton ? 'grey' : '#fff'
                }}
              />
            </ButtonBase>
            <ButtonBase
              style={{
                borderRadius: '0 20px 20px 0'
              }}
              className={classes.scaleButton}
              onClick={upScale}
              disabled={isDisabledNextScaleButton}
            >
              <AddIcon
                style={{
                  fontSize: '24px',
                  color: isDisabledNextScaleButton ? 'grey' : '#fff'
                }}
              />
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

FullScreenImageVisualization.propTypes = {
  downloadable: PropTypes.bool,
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};
