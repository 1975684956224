import React from 'react'
import Main from '../Main'

export default function VideoCallMiniCard({ size, ...rest }){
  return <Main  color="#369037" 
                backgroundColor="#b5dbbd"
                size={size}
                text="Videochamada"
                { ...rest }
                />
}