import React, { useState, useEffect } from 'react';

import Text from '~/components/atoms/Text';
import { IconWrapper } from '~/utils/forms';

import EditorField from '../EditorField';
import { useStyles } from './styles';
import { Collapse, Divider, Grid, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function Statements({
  statement,
  setStatement,
  explanation,
  setExplanation,
  startStatement,
  startExplanation,
  errorStatement
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [showStatement, setShowStatement] = useState(false);
  const [showExplanation, setshowExplanation] = useState(false);

  const renderIcon = (bool) => {
    return bool ? (
      <IconWrapper
        customColor={theme.palette.primary.main}
        fontSize={30}
        Icon={ExpandLess}
      />
    ) : (
      <IconWrapper
        customColor={theme.palette.primary.main}
        fontSize={30}
        Icon={ExpandMore}
      />
    );
  };

  const renderField = (
    label,
    setToggleState,
    tip,
    bool,
    state,
    setState,
    startText
  ) => {
    return (
      <div className={classes.field}>
        <Grid
          className={classes.toggle}
          container
          alignItems="center"
          justifyContent="space-between"
          onClick={() => setToggleState((prev) => !prev)}
        >
          <Text color={'primary'} variant={'text'}>
            {' '}
            {label}{' '}
          </Text>
          {renderIcon(bool)}
        </Grid>
        <Divider className={classes.divider} />

        <Collapse in={bool}>
          <EditorField
            onChange={setState}
            value={state}
            startText={startText}
          />
          <Text className={classes.tip} variant={'smallText'}>
            {' '}
            {tip}{' '}
          </Text>
        </Collapse>
      </div>
    );
  };

  useEffect(() => {
    if (errorStatement) {
      setShowStatement(true);
    }
  }, [errorStatement]);

  return (
    <>
      <div className={classes.field}>
        {renderField(
          'Enunciado',
          setShowStatement,
          'Descrição da questão e sua problemática.',
          showStatement,
          statement,
          setStatement,
          startStatement
        )}
      </div>

      {errorStatement && (
        <Text color={'error'}>
          Você precisa descrever o enunciado da questão.
        </Text>
      )}

      <div className={classes.field}>
        {renderField(
          'Justificativa',
          setshowExplanation,
          'Explicação da questão. Será exibida após a realização do exame.',
          showExplanation,
          explanation,
          setExplanation,
          startExplanation
        )}
      </div>
    </>
  );
}
