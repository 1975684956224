import React from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';
import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import i18n from '~/I18n';

// eslint-disable-next-line react/prop-types
export default function SebraeErrorAlert({ open, setOpen }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <Box className={classes.containerIcon}>
        <CancelOutlined className={classes.icon} />
      </Box>

      <Box className={classes.containerTitle}>
        <Typography className={classes.title}>Ops!</Typography>
      </Box>

      <DialogContent className={classes.content}>
        <Typography className={classes.message}>
          Houve um problema em realizar sua inscrição no {`${i18n.t('StructuresName.Course.SingularUpper')}`}.
        </Typography>
      </DialogContent>
      <DialogContent className={classes.content}>
        <Typography className={classes.message}>
          <Link to="/help">
            Clique aqui para entrar em contato com o nosso suporte.
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
