import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageUnauthorized from '~/pages/PageUnauthorized';

export default function RouteAccessControlWrapper (
  Component,
  accessFunction,
  queryParam
) {
  const modelID = useParams()[queryParam];

  const [authorized, setAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lectureDependency, setLectureDependency] = useState(false);
  const [expired, setExpired] = useState(false);

  const handleAuthorization = () => {
    setAuthorized(true);
    setIsLoading(false);
  };

  const handleUnauthorization = (error) => {
    const { data } = error.response;
    if (data?.lecture_dependency) setLectureDependency(true);
    if (data?.expired) setExpired(true);
    setIsLoading(false);
  };

  useEffect(() => {
    accessFunction(modelID)
      .then(handleAuthorization)
      .catch(handleUnauthorization);
  }, []);

  if (!isLoading && !(authorized || lectureDependency || expired)) {
    return <PageUnauthorized />;
  }

  return <Component />;
}
