import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

const lectureMuralContext = createContext();

export const useLectureMuralContext = () => useContext(lectureMuralContext);

export const LectureMuralContextProvider = ({
  lecture,
  extendedWall,
  children
}) => {
  const [contentDisplay, setContentDisplay] = useState('discussion');

  return (
    <lectureMuralContext.Provider
      value={{
        lecture,
        contentDisplay,
        setContentDisplay,
        extendedWall
      }}
    >
      {children}
    </lectureMuralContext.Provider>
  );
};

LectureMuralContextProvider.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  children: PropTypes.node.isRequired,
  extendedWall: PropTypes.bool
};
