import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonStyle: {
    borderRadius: '50% !important',
    padding: '4px !important',
    marginRight: '8px !important',
    minWidth: '0px !important',
    color: '#fff !important'
  },
  iconStyle: {
    fontSize: '20px !important',
    color: '#fff !important'
  }
});
