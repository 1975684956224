import React from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';

function LaplaceImportExerciseButton({ onClick }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ButtonSquare className={classes.root} onClick={onClick}>
      <BookIcon className={classes.icon} />
      <div className={classes.textContainer}>
        <Typography className={classes.title}>
          {i18n.t('QuestionDatabase')}
        </Typography>
        <Typography className={classes.description}>
          {i18n.t('ExerciseImportButtonDescription')}
        </Typography>
      </div>
    </ButtonSquare>
  );
}

export default LaplaceImportExerciseButton;
