import React from 'react';
import { TableChart } from '@material-ui/icons';
import { Grid, useTheme } from '@material-ui/core';
import Text from '~/components/atoms/Text';

export default function FileName({ name }) {
  const theme = useTheme()
  return(
      <Grid container alignItems='center' justifyContent='center' spacing={1}>
          
        <Grid item>
          <TableChart style={{ fontSize: 24, color: theme.palette.secondary.main }} />
        </Grid>
        <Grid item>
          <Text variant="actionText" color="gray">
            { name }
          </Text>
        </Grid>
        
      </Grid>
  );
}



