import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'end',
    width: '100%'
  },

  container: {
    width: '100%',
    padding: '0 0 0 2rem'
  },

  bottomControlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  centerControlsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '6rem',
    alignItems: 'end'
  },

  progress: {
    color: theme.palette.activeText.main,
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },

  controlIcons: {
    transform: 'scale(1.8)',
    color: theme.palette.activeText.main
  },

  playBackRate: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    width: '10rem',
    color: theme.palette.activeText.main
  },

  progressBarRoot: {
    color: theme.palette.secondary.main,
    height: 4
  },
  progressBarThumb: {
    height: 12,
    width: 12,
    backgroundColor: theme.palette.secondary.main,
    border: '2px solid currentColor',
    marginTop: -2,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },

  progressBarTrack: {
    height: 8,
    borderRadius: 4
  },
  progressBarRail: {
    height: 8,
    borderRadius: 4
  }
}));
