import React from 'react';

import ReportTitle from '../ReportTitle';
import { useStyles } from './styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

export const ReportTableAccordion = ({
  title,
  report: Report,
  id,
  fetchFunction
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          classes={{ content: classes.summaryContent }}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <ReportTitle title={title} />
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Report id={id} fetchFunction={fetchFunction} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

ReportTableAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  report: PropTypes.elementType.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fetchFunction: PropTypes.func.isRequired
};
