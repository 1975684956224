import React from 'react';
import { Link } from 'react-router-dom';

import Text from '~/components/atoms/Text';
import { Page404 } from '~/components/Icons/Page404';
import PageTemplate from '~/components/templates/Page';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

export default function PageNotFound() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isTablet = useMediaQuery('@media (max-width: 780px)');

  return (
    <PageTemplate noAppbar noLeftDrawer>
      <div className={classes.container}>
        <div className={classes.leftContent}>
          <div className={classes.imageTablet}>
            <Page404 width={300} height="auto" />
          </div>
          <div className={classes.title}>
            <div className={classes.titleDecoration}>
              <div className={`${classes.stroke} ${classes.strokeTop}`} />
              <div className={`${classes.stroke} ${classes.strokeMid}`} />
              <div className={`${classes.stroke} ${classes.strokeBot}`} />
            </div>
            <Text color="black" variant={isTablet ? 'smBanner' : 'mdBanner'}>
              Ops!
            </Text>
          </div>
          <div className={classes.subtitle}>
            <Text variant={isTablet ? 'mdTitle' : 'xl'} color="black">
              Página não encontrada
            </Text>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyTitle}>
              <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                Por quê?
              </Text>
            </div>
            <div className={classes.bodyText}>
              <Text variant={isTablet ? 'largeText' : 'lg'} color="black">
                A página que você procura não foi encontrada. Isso pode ser
                resultado de alguns fatores como: caractere digitado
                incorretamente, página inexistente ou endereço realocado.
              </Text>
            </div>
            <div className={classes.bodyTitle}>
              <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                Sugerimos:
              </Text>
            </div>
            <div className={classes.bodyText}>
              <Text variant={isTablet ? 'largeText' : 'lg'} color="black">
                Caso você tenha digitado o endereço, verifique se ele está
                correto (com algum caractere a mais ou a menos). Caso tenha sido
                redirecionado de nossa plataforma, pedimos desculpas pelo
                inconveniente e que você{' '}
                <Link to="/help" className={classes.link}>
                  nos ajude
                </Link>{' '}
                a resolver esse problema.
              </Text>
            </div>
            <div className={classes.bodyTitle}>
              <Text variant={isTablet ? 'lg' : 'mdTitle'} color="black">
                Estes links podem te ajudar.
              </Text>
            </div>
            <ul className={classes.linkList}>
              <li>
                <Link to="/dashboard">
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="primary">
                    Página inicial
                  </Text>
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy-and-usage-terms">
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="primary">
                    Termos de uso e política de privacidade
                  </Text>
                </Link>
              </li>
              <li>
                <Link to="/help">
                  <Text variant={isTablet ? 'largeText' : 'lg'} color="primary">
                    Entre em contato conosco
                  </Text>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.rightContent}>
          <Page404 width={500} height="auto" />
        </div>
      </div>
    </PageTemplate>
  );
}
