import React from 'react';

import { useStyles } from './styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const EmptyFeedback = ({ icon: Icon, title, description }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Icon style={{ fill: theme.palette.primary.main }} />
      <Box style={{ color: '#fff' }}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

EmptyFeedback.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
