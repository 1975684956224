import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  statusIndicator: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    width: '100%',
    justifyContent: 'center',
    color: '#fff'
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  icon: {
    fontSize: '24px'
  },
  statusText: {
    fontStyle: 'italic',
    fontSize: '14px'
  }
});
