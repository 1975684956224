import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from '~/I18n';

import SebraeErrorAlert from '~/components/molecules/SebraeErrorAlert';

export default function SebraeError() {
  const sebraeCourseEnrollmentError = new URLSearchParams(
    useLocation().search
  ).get('se');

  const [open, setOpen] = useState(sebraeCourseEnrollmentError ? true : false);

  return (
    <SebraeErrorAlert
      message={
        `Ops! Houve um problema em realizar sua inscrição no ${i18n.t('StructuresName.Course.SingularUpper')}. Clique aqui para entrar em contato com o nosso suporte.`
      }
      open={open}
      setOpen={setOpen}
    />
  );
}
