import React from 'react';
import Text from '~/components/atoms/Text';
import TextBox from '../TextBox';
import i18n from '~/I18n';
import settings from '~settings';

import { Box, useTheme } from '@material-ui/core';

import { useStyles } from './styles';

export default function MinimumRequirements() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const client = settings.name;
  const platformName =
    client === 'AVAMEC Interativo' ? 'AVAMEC Interativo' : 'Redu';

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Text color="primary" variant="ppTitle">
          {i18n.t('MinimumRequirements')}
        </Text>
      </Box>
      <Box className={classes.content}>
        <TextBox>
          A Plataforma {platformName} tenta ser ao máximo acessível aos
          usuários. Entretanto, algumas funcionalidades podem não funcionar
          muito bem caso o equipamento (hardware e conexão à internet) não siga
          os seguintes requisitos mínimos:
        </TextBox>
        <ol className={classes.numberedList}>
          <li>
            <TextBox isListItem>
              Processador: Core 2 Duo ou equivalente, acima de 2.5GHz;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>Memória RAM: 4Gb ou superior;</TextBox>
          </li>
          <li>
            <TextBox isListItem>Armazenamento: 128Gb ou superior;</TextBox>
          </li>
          <li>
            <TextBox isListItem>
              Conexão com Internet: acima de 4Mbps dedicada ou superior;
            </TextBox>
          </li>
        </ol>
        <TextBox>
          Navegadores suportados: Google Chrome, Mozilla Firefox, Apple Safari
          ou Microsoft Edge.
        </TextBox>
        <TextBox>
          É possível o acesso às funcionalidades mesmo com configurações abaixo
          do que as mencionadas acima. Entretanto sugerimos que você acesse o
          sistema antes de adquirir um plano e que tenha ciência de que a{' '}
          Plataforma {platformName} não será responsável pela qualidade em tais
          casos.
        </TextBox>
      </Box>
    </Box>
  );
}
