import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  subsection: {
    marginTop: 40,
    marginBottom: 40,
    width: '100%',
  },
  subtitleContainer: {
    marginTop: 5,
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: 20,
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  submitArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    width: '220px',
    justifyContent: 'space-between'
  },
  saveButton: {
    '& button:hover': {
      backgroundColor: theme.palette.tertiary.main
    }
  }
}));
