import { useEffect, useRef, useState } from 'react';

export function useInfiniteScroll({ fetchFunction, id }) {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [elements, setElements] = useState([]);

  const observer = useRef(null);
  const anchorRef = useRef(null);

  function fetchNextPage() {
    if (loading) return;
    setLoading(true);
    fetchFunction(id, page).then((newElements) => {
      if (newElements.length < 10) {
        setHasMore(false);
      } else {
        setPage(page + 1);
      }
      setElements([...elements, ...newElements]);
    });
    setLoading(false);
  }

  useEffect(() => {
    if (!anchorRef.current) return;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchNextPage();
      }
    });

    observer.current.observe(anchorRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [elements, hasMore, anchorRef.current]);

  return {
    elements,
    loading,
    anchorRef
  };
}
