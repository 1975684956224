import settings from '~settings';

const redu = {
  MyEnvironments: 'My Environments',
  InfoEnvironments: 'Environment information',
  InfoCourses: 'Course information',
  InfoSpace: 'Space information',
  Hours: 'hours',
  Message: 'Message',
  Messages: 'Messages',
  CreateEnvironment: 'New Environment',
  CreateMirroredEnvironment: 'Create Mirrored Environment',
  EmptyListEnvironments: "You don't have environments yet",
  NoSubscription: 'No Environment',
  AddSubscription: 'Create Environment',
  ManageEnvironment: 'Manage Environment',
  EditEnvironment: 'Edit Environment',
  NoCoursesForThisEnvironment: 'There are not courses for this environment',
  NoSpacesForThisCourse: 'This course has no spaces.',
  lastClass: 'Last accessed media',
  nextClasses: 'Next media',
  CreateCourse: 'New Course',
  EditCourse: 'Edit Course',
  ManageCourse: 'Manage Course',
  CourseInformation: 'Course Information',
  CommonCourses: 'Common Courses',
  AddNewMemberToCourse: 'Add new member to Course',
  ofTheSpace: 'of the Space',
  myCourses: 'My Courses',
  noCourses: 'No courses',
  ManageDiscipline: 'Manage Discipline',
  Disciplines: 'Disciplines',
  AddDisciplineToCourse: 'Add discipline',
  AllModules: 'All modules',
  NoClassesOnThisModule: 'This module has no media yet',
  Classes: 'Media',
  commentedLecture: 'commented on the media',
  helpLecture: 'asked for help on the media',
  EmptyLectureSumaryList: 'You have not accessed any media recently',
  noNextClasses: 'There are no next media',
  Environments: 'Environments',
  Environment: 'Environment',
  Subjects: 'Subject',
  Lectures: 'Lectures',
  CreateSubject: {
    title: 'New Module',
    saveButton: 'Create'
  },
  CreateMirroredSubject: {
    title: 'Create Mirrored Module',
    saveButton: 'Create'
  },
  EditSubject: {
    title: 'Edit Module',
    saveButton: 'Save'
  },
  CreateSpace: {
    title: 'New Space',
    saveButton: 'Create'
  },
  CreateMirroredSpace: {
    title: 'Create Mirrored Space',
    saveButton: 'Create'
  },
  EditSpace: {
    title: 'Edit Space',
    saveButton: 'Save'
  },
  CreateMirroredCourse: {
    title: 'Create Mirrored Course',
    saveButton: 'Create'
  },
  EditMirroredCourse: {
    title: 'Edit Course',
    saveButton: 'Save'
  },
  DeleteEnvironment: {
    title: 'Delete Environment'
  },
  DeleteMirroredEnvironment: {
    title: 'Delete Mirrored Environment'
  },
  DeleteCourse: {
    title: 'Delete Course'
  },
  DeleteMirroredCourse: {
    title: 'Delete Mirrored Course'
  },
  DeleteSpace: {
    title: 'Delete Space'
  },
  DeleteMirroredSpace: {
    title: 'Delete Mirrored Space'
  },
  DeleteSubject: {
    title: 'Delete Subject'
  },
  DeleteMirroredSubject: {
    title: 'Delete Mirrored Subject'
  },
  DeleteMedia: {
    title: 'Delete Media'
  },
  DeleteMirroredMedia: {
    title: 'Delete Mirrored Media'
  },
  StructuresName: {
    Environment: {
      SingularUpper: 'Environment',
      PluralUpper: 'Environments',
      SingularLower: 'environment',
      PluralLower: 'environments',
    },
    Course: {
      SingularUpper: 'Course',
      SingularLower: 'course',
      PluralUpper: 'Courses',
      PluralLower: 'courses',
    },
    Space: {
      SingularUpper: 'Space',
      PluralUpper: 'Spaces',
      SingularLower: 'space',
      PluralLower: 'spaces',
    },
    Subjects: {
      SingularUpper: 'Module',
      PluralUpper: 'Modules',
      SingularLower: 'module',
      PluralLower: 'modules',
    },
    Lectures: {
      SingularLower: 'media',
      PluralLower: 'media',
      PluralUpper: 'Media',
      SingularUpper: 'Media'
    }
  }
};

export function selectEnglishWordsByClient() {
  switch (settings.name) {
    case 'redu':
      return redu;

    default:
      return redu;
  }
}
