import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  drawer: {
    width: 365,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 76px)',
    position: 'absolute',
    right: 0,
    boxShadow: '0px 5px 6px #00000029',
    zIndex: 980,
    backgroundColor: 'white',
    transition: 'transform .2s',
    overflow: 'hidden',
    '@media (max-width: 600px)': {
      width: 300,
    },
  },
  container: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  closed: {
    transform: 'translateX(365px)',
    '@media (max-width: 600px)': {
      transform: 'translateX(300px)'
    },
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: (props) => (props.isModal ? 'flex-start' : 'flex-end'),
    padding: '10px 15px',
  },
  icon: {
    color: (props) => props.theme.palette.primary.main,
    width: 25,
    height: 25,
  },
  content: {
    padding: '0 25px 25px 25px',
    overflow: 'auto',
  },
  infoHeaderBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameInfo: {
    marginTop: 10,
    textAlign: 'center',
    '& p': {
      wordBreak: 'break-word',
    },
  },
  descriptionContainer: {
    margin: '20px 0',
  },
  courseItem: {
    marginTop: 15,
    display: 'flex',
  },
  courseThumb: {
    marginRight: 20,
    backgroundColor: (props) => props.theme.palette.primary.main,
    width: 100,
    height: 100,
    minWidth: 100,
    minHeight: 100,
    borderRadius: 5,
    '@media (max-width: 600px)': {
      width: 80,
      height: 80,
      minWidth: 80,
      minHeight: 80,
    },
  },
  corseInfo: {
    flexGrow: 1,
  },
});
