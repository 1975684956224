import React from 'react';

import AvatarProfile from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';
import { muralItemAvatarSize } from '~/constants/styles';
import i18n from '~/I18n';

import { Breadcrumbs, Box } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { formatDistanceToNow } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  outerContainer: ({ dashboard }) => ({
    width: '100%',
    padding: '15',
    '@media (max-width: 1400px)': {
      padding: dashboard ? '15px 0px 15px 0px' : '15px 0 15px 8px'
    },
    '@media  (max-width: 1280px)': {
      maxWidth: 600
    }
  }),
  itemContainer: () => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }),
  itemContainerAvatar: () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }),
  imageHelp: ({ lecture }) => ({
    width: 22,
    height: 'auto',
    marginTop: 10,
    marginRight: 25,
    color: theme.palette.primary.main,
    '@media (max-width: 1400px)': {
      marginRight: lecture ? 8 : 25
    },
    '@media (max-width: 1024px)': {
      marginRight: lecture ? 8 : 15
    }
  }),
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%'
  },
  breadCrumbsContainer: {
    marginLeft: muralItemAvatarSize + 4 + 25,
    paddingBottom: 10
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  action: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  textContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  avatarProfileContainer: ({ lecture }) => ({
    marginRight: 25,
    '@media (max-width: 1400px)': {
      marginRight: lecture ? 8 : 25
    },
    '@media (max-width: 1024px)': {
      marginRight: lecture ? 8 : 15
    }
  }),
  badgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  badgeAvatar: {
    width: 48,
    height: 48,
    margin: 10
  },
  answerTextContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  replyContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: 10
  },
  replyContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  smReplyButtons: ({ lecture }) => ({
    display: 'none',
    marginTop: '5px',
    '@media (max-width: 1400px)': {
      display: lecture ? 'flex' : 'none',
      width: lecture ? '100%' : '',
      justifyContent: lecture ? 'flex-end' : ''
    },
    '@media (max-width: 800px)': {
      display: 'none'
    },
    '@media (max-width: 600px)': {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end'
    }
  }),
  replyButtons: ({ lecture }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    '@media (max-width: 1400px)': {
      display: lecture ? 'none' : 'flex'
    },
    '@media (max-width: 800px)': {
      display: 'flex'
    },
    '@media (max-width: 600px)': {
      display: 'none'
    }
  }),
  smButton: {
    display: 'flex',
    width: 28,
    minWidth: 28,
    height: 28,
    padding: 0,
    borderRadius: '50%',
    '@media (max-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  smButtonCheck: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  smButtonCancel: {
    border: `1px solid ${theme.palette.secondary.main}`,
    margin: '0 5px'
  },
  answerTextField: {
    '& .MuiInputBase-root': {
      fontSize: 14
    },
    paddingTop: 14
  },
  avatarProfileDesktop: ({ lecture }) => ({
    '@media (max-width: 1400px)': {
      display: lecture ? 'none' : 'block'
    },
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  }),
  avatarProfileTabletMobile: ({ lecture }) => ({
    display: 'none',
    '@media (max-width: 1400px)': {
      display: lecture ? 'block' : 'none'
    },
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  }),
  breadcrumbsSeparator: {
    display: 'flex',
    marginLeft: '2px',
    userSelect: 'none',
    marginRight: '2px'
  },
  textFieldContainer: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  textAreaContainer: {
    width: '100%',
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'block'
    }
  },
  deletedComment: {
    opacity: 0.7
  },
  deletedComment__name: {
    color: 'gray !important'
  },
  answerContainer: ({ dashboard }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: 10,
    borderBottom: '1px solid #f2f2f6',
    position: 'relative',
    '@media  (max-width: 1440px)': {
      width: '80%'
    },
    '@media  (max-width: 800px)': {
      width: '100%'
    }
  }),
  answerContainer__userInfo: {
    display: 'flex',
    flexDirection: 'row'
  },
  answerContainer__content: ({ dashboard }) => ({
    padding: '15px 0',
    width: '100%',
    '& p': {
      wordWrap: 'break-word'
    }
  }),

  mainContainer: ({ isAnswer, dashboard }) => ({
    padding: '15px 0',
    position: 'relative',
    width: '100%',
    overflowX: 'hidden'
  }),
  menuHelper: {
    position: 'absolute',
    top: 10,
    right: 10,
    '@media  (max-width: 1152px)': {
      right: 60
    }
  }
}));

export const TimeDistance = styled(({ date }) => {
  const locale = { 'en-US': enUS, 'pt-BR': ptBR, 'es-ES': es };
  const timeDistance = formatDistanceToNow(new Date(date), {
    locale: locale[i18n.language],
    includeSeconds: true
  });
  return (
    <Text color="gray" variant="mediumText">
      {i18n.t('TimeDistance', { time: timeDistance })}
    </Text>
  );
})``;

export const LikeIcon = styled(() => {
  const theme = useTheme();
  return (
    <ThumbUp
      style={{
        color: theme.palette.primary.main,
        marginRight: 5,
        fontSize: 14
      }}
    />
  );
})``;

export const BreadcrumbsCustom = styled(({ ...otherProps }) => {
  const { crumbs } = otherProps;
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      classes={{ separator: useStyles().breadcrumbsSeparator }}
    >
      {crumbs.map((crumb, index) => (
        <Text key={index} color="gray" variant="mediumText">
          {crumb.name}
        </Text>
      ))}
    </Breadcrumbs>
  );
})``;

export const CustomAvatar = styled(
  ({ profile, lecture, size = muralItemAvatarSize.desktop }) => (
    <Box className={useStyles({ lecture }).avatarProfileContainer}>
      <Box className={useStyles({ lecture }).avatarProfileDesktop}>
        <AvatarProfile size={size} profile={profile} />
      </Box>
      <Box className={useStyles({ lecture }).avatarProfileTabletMobile}>
        <AvatarProfile
          size={muralItemAvatarSize.tabletMobile}
          profile={profile}
        />
      </Box>
    </Box>
  )
)``;
