import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '99%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  subheaderSectionButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  searchBar: {
    width: '100%',
    fontSize: '16px',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  importButton: {
    display: 'inline-flex',
    padding: '7px 20px 7px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    textTransform: 'none',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    background: '#fff',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    '&:hover': {
      color: theme.palette.primary.main,
      filter: 'saturate(180%)'
    }
  },
  addButton: {
    display: 'inline-flex',
    padding: '8px 20px 8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    textTransform: 'none',
    borderRadius: '4px',
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff',
      filter: 'saturate(180%)'
    }
  }
}));
