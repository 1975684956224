import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  message: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  button: {
    fontWeight: '700',
    marginLeft: '24px',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '24px'
  }
});
