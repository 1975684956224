import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '100%'),
    textAlign: 'center'
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  item: {
    padding: 16,
    marginBottom: 16,
    borderRadius: 4,
    background: '#F2F2F7'
  }
}));
