import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

const fileTypes = [
  'image/bmp',
  'image/x-windows-bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png'
];

export default function Image () {
  const { watch } = useFormContext();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (watch('signatureFile')) {
      setValue({ name: watch('signatureFile') });
    }
  }, [watch('signatureFile')]);

  return (
    <Field
      label={TextPrimary('Imagem')}
      name="signature"
      Component={DragDropFileInput}
      permittedFileTypes={fileTypes}
      fileTypesDescription={'Apenas arquivos de imagem serão permitidos.'}
      value={value}
      // maxSize in Mb
      maxSize={10}
      hideErrors
    />
  );
}
