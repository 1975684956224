import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: '2rem',
    '@media (max-width: 450px)' : {
      flexDirection: 'column',
      marginRight: '1.5rem',
      marginBottom: 0
    }
  },
  imageContainer: {
    width: '25rem',
    boxShadow: '5px 5px 10px rgba(0,0,0,0.2)',
    '@media (max-width: 450px)' : {
      width: '30rem',
    },
    "& img": {
      width: '100%',
      display: 'block',
      height: '15rem',
      borderRadius: '0.5rem',
      '@media (max-width: 450px)' : {
        height: '17rem',
      },
    },
  },
  iconContainer: {
    width: '25rem',
    boxShadow: '5px 5px 10px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    '@media (max-width: 450px)' : {
      width: '30rem',
      height: '17rem'
    }
  },
  textContainer: {
    width: '60%',
    padding: '0 2rem',
    '@media (max-width: 450px)' : {
      padding: 0,
      marginTop: '1rem'
    },
    "& h3": {
      color: theme.palette.primary.main,
      marginBottom: '1rem',
      fontSize: '2.2rem',
      fontWeight: '500',
      '@media (max-width: 450px)' : {
        fontSize: '2rem'
      },
    },
    "& p": {
      fontSize: '14px',
      color: theme.palette.activeText.main
    }
  }
}));
