import api from '~/services/api';

const baseURL = '/api/forms/';

export const getUserSubmission = async (questionaryID, lectureID) => {
  const { data } = await api.get(
    baseURL + `${questionaryID}/lectures/${lectureID}/my_submission`
  );

  return data;
};

export default {
  getUserSubmission
};
