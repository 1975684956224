import React from 'react';

import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Input from '~/components/atoms/Input';
import Recaptcha from '~/components/atoms/Recaptcha';
import i18n from '~/I18n';

import { useStyles } from './styles';
import validate from './validate';

const PasswordResetForm = ({
  handleSubmit,
  submitting,
  pristine,
  error,
  disabled
}) => {
  const classes = useStyles();

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Box className={classes.containerForm}>
        <Field
          name="newPassword"
          component={Input}
          label="Nova senha"
          placeholder=""
          type="password"
          disabled={disabled}
        />
        <Field
          name="newPasswordConfirmation"
          component={Input}
          label="Confirme a nova senha"
          placeholder=""
          type="password"
          disabled={disabled}
        />
        <Field
          name="captcharesponse"
          component={Recaptcha}
          label=""
          placeholder=""
          type=""
        />
        <Button
          className={classes.submitButton}
          disabled={pristine && submitting && disabled}
          type="submit"
          disableElevation
        >
          {submitting ? (
            <CircularProgress />
          ) : (
            i18n.t('RecoverPassword.RecoverPassword')
          )}
        </Button>
        <Box className={classes.containerLoginError}>
          {error && <strong>{error}</strong>}
        </Box>
      </Box>
    </form>
  );
};

export default reduxForm({
  form: 'PasswordResetForm',
  validate
})(PasswordResetForm);

PasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.any
};

PasswordResetForm.defaultProps = {
  handleSubmit: null,
  submitting: false,
  disabled: false,
  pristine: false,
  error: null
};
