import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MessageRow from '~/components/molecules/MessageRow';
import { getContactEmit, getContactOn } from '~/socket/contact-events';
import {
  messageFromChatEmit,
  messageFromChatOn,
  messageFromChatOff,
  messageFromChatContactOn,
  readMessageFromChatEmit,
  unreadMessageFromChatCountEmit,
  messageFromChatContactOff
} from '~/socket/message-events';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { AddCircleOutline, ArrowDropDownCircleSharp } from '@material-ui/icons';

// eslint-disable-next-line react/prop-types
export default function ChatMessagesView({ contact }) {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.user).profile;
  const [loading, setLoading] = useState(true);
  const [showDownBtn, setShowDownBtn] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [currentContact, setCurrentContact] = useState(null);

  const handleScrollChange = () => {
    const chatView = document.getElementById('chat-messages-view');
    const sum = chatView.scrollTop + chatView.offsetHeight;

    if (chatView.scrollHeight - sum > 180) {
      setShowDownBtn(true);
    }
    if (chatView.scrollHeight - sum < 50) {
      setShowDownBtn(false);
    }

    if (chatView.scrollTop === 0) {
      handleOldMessages();
    }
  };

  const scrollToStart = () => {
    const chatView = document.getElementById('chat-messages-view');
    if (chatView) {
      chatView.scrollTo({ top: 0 });
    }
  };

  const scrollToEnd = () => {
    const chatView = document.getElementById('chat-messages-view');
    if (chatView) {
      chatView.scrollTo({ top: chatView.scrollHeight });
    }
  };

  function toChatBottom() {
    scrollToEnd();
    setShowDownBtn(false);
  }

  useEffect(() => {
    if (!!contact && !!currentUser) {
      handleOldMessages();
      getContactEmit(currentUser?.id, contact.contact_id);
    }

    return () => {
      if (currentUser) {
        messageFromChatOff(currentUser?.id);
      }
    };
  }, [currentUser]);
  const handleOldMessages = () => {
    messageFromChatEmit(
      // eslint-disable-next-line react/prop-types
      currentUser?.id,
      contact.contact_id,
      chatMessages.length,
      setLoading
    );
  };

  useEffect(() => {
    if (!!contact && !!currentUser) {
      getContactOn(currentUser?.id, (contact) => {
        setCurrentContact(contact);
      });

      const messageFromChatContactOnCallback = (message) => {
        if (message?.id) {
          readMessageFromChatEmit(message.id, contact.contact_id);
          unreadMessageFromChatCountEmit(currentUser.id);
        }

        setChatMessages((prev) => [...prev, message]);
        scrollToEnd();
      };
      messageFromChatContactOn(
        currentUser?.id,
        contact.contact_id,
        messageFromChatContactOnCallback
      );

      const messageFromChatOnCallback = (messages) => {
        messages = messages || []
        setLoading(false);
        let newMsgs = 0;
        setChatMessages((prev) => {
          const msgs = [...messages.reverse(), ...prev];
          newMsgs = msgs.length - prev.length;
          return msgs;
        });
        const chatView = document.getElementById('chat-messages-view');
        // MessageRow height + margin = 46px
        chatView.scrollTo({ top: newMsgs * 46, behavior: 'instant' });
      };
      messageFromChatOn(currentUser?.id, messageFromChatOnCallback);
    }

    scrollToEnd();

    return () => {
      messageFromChatContactOff(currentUser?.id, contact.contact_id);
      messageFromChatOff(currentUser?.id);
    };
  }, [currentUser]);

  return (
    <Box
      // onScroll={handleScrollChange}
      // id="chat-messages-view"
      className={classes.container}
    >
      <Box
        className={classes.body}
        id="chat-messages-view"
        onScroll={handleScrollChange}
      >
        {/*<Box className={classes.pressContentBody} />*/}
        <Box className={classes.contentBody}>
          {chatMessages?.map((message, index) => {
            return (
              <MessageRow
                key={index}
                sender={message.sender_id === currentUser.id}
                text={message.body}
                message={message}
                // eslint-disable-next-line react/prop-types
                contactId={contact.id}
                userId={currentUser.id}
              />
            );
          })}
        </Box>
      </Box>
      {showDownBtn ? (
        <ArrowDropDownCircleSharp
          className={classes.dropDownIcon}
          style={{
            top:
              document.getElementById('chat-messages-view').offsetHeight +
              document.getElementById('chat-messages-view').offsetTop -
              30
          }}
          onClick={toChatBottom}
        />
      ) : null}
    </Box>
  );
}
