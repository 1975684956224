import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  field: {
    marginBottom: '5px'
  },
  hideText: {
    padding: 0,
    margin:0,
    fontSize: '14px',
    color: '#767677'
  },
  click: {
    cursor: 'pointer'
  }
});
