import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  customTooltip: {
    background: '#f0f0f0',
    padding: '12px 16px',
    borderRadius: '4px'
  },
  popperArrow: {
    color: '#f0f0f0'
  },
  tooltipText: {
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.activeText.main
  }
}));
