import React, { useEffect, useState } from 'react';

import PaginatableSelect from '~/components-refactor/atoms/PaginatableSelect';
import { useReplicateContext } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext.js';
import { useReplication } from '~/hooks/useReplication';
import i18n from '~/I18n';

import EditCourseForm from './EditCourseForm';
import { useSnackbar } from 'notistack';

export default function CourseStep() {
  const {
    replicationSteps,
    setReplicationSteps,
    listService,
    mirrorType
  } = useReplicateContext();
  const { fetchStructureList, isFetching } = useReplication();
  const { enqueueSnackbar } = useSnackbar();
  const [coursesListData, setCoursesListData] = useState([]);
  const [mirrorPayload, setMirrorPayload] = useState({});

  const handleSuccessFetchList = (data) => {
    const courseData = data.map((item) => ({
      ...item.course,
      value: item.course.id
    }));
    setCoursesListData((prev) => [...prev, ...courseData]);
  };

  const handleFailureFetchList = () => {
    enqueueSnackbar(
      `Ocorreu um erro ao carregar os dados dos ${i18n.t(
        `StructuresName.Course.PluralLower`
      )}`,
      {
        variant: 'error'
      }
    );
  };

  useEffect(() => {
    if (replicationSteps[1].key)
      fetchStructureList(
        replicationSteps[1].key,
        mirrorType === 'course' ? listService.getSelf : listService.getCourses,
        handleSuccessFetchList,
        handleFailureFetchList
      );
  }, [replicationSteps[1].key]);

  const renderContent = () => {
    return (
      <>
        <PaginatableSelect
          label={
            mirrorType === 'course'
              ? `Qual ${i18n.t(
                  `StructuresName.Course.SingularLower`
                )} deseja espelhar?`
              : `Selecione de qual ${i18n.t(
                  `StructuresName.Course.SingularLower`
                )} você deseja espelhar`
          }
          placeholder={`Selecionar um ${i18n.t(
            `StructuresName.Course.SingularUpper`
          )}`}
          curValue={replicationSteps[2].key}
          data={coursesListData}
          fetchMoreItems={() =>
            fetchStructureList(
              replicationSteps[1].key,
              mirrorType === 'course'
                ? listService.getSelf
                : listService.getCourses,
              handleSuccessFetchList,
              handleFailureFetchList
            )
          }
          isFetching={isFetching}
          handleChange={(e) => {
            const course = coursesListData.find(
              (el) => e.target.value === el.value
            );
            setReplicationSteps((prev) => {
              let temp = prev;
              prev[2].key = course.value;
              return [...temp];
            });
            setMirrorPayload(course);
          }}
        />
        {replicationSteps[2].key && mirrorType === 'course' && (
          <EditCourseForm mirrorPayload={mirrorPayload} />
        )}
      </>
    );
  };

  return renderContent();
}
