import { all, put, call, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { Types, Creators as FollowingActions } from './index';

export function* getFollowing(action) {
  const { getFollowingSuccess, getFollowingFailure } = FollowingActions;
  const { page, userId } = action;

  try {
    const response = yield call(api.get, `/api/users/${userId}/following`, {
      params: { page }
    });
    yield put(getFollowingSuccess(response.data));
  } catch (error) {
    yield put(getFollowingFailure());
  }
}

export default all([takeLatest(Types.GET_FOLLOWING_REQUEST, getFollowing)]);
