import api from '~/services/api';

const baseURL = '/api/status_files';

export const getAttachment = async (fileID) => {
  const { data } = await api.get(`${baseURL}/${fileID}/download`, {
    responseType: 'blob'
  });

  return data;
};
export default {};
