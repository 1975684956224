import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getUserListReport } from '~/services/reports/courses';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UserListReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUserListReport}
        baseName="relatório_de_usuários_que_acessaram_a_plataforma_"
        id={id}
      />
    </div>
  );
}

UserListReport.propTypes = {
  id: PropTypes.string
};

UserListReport.defaultProps = {
  id: ''
};
