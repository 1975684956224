import React, { useEffect, useMemo, useRef, useState } from 'react';

import MemberItem from '~/components/organisms/MembersManagement/MemberItem';

import AddMemberModal from '../AddMemberModal';
import ContextualBar from './ContextualBar';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ImportMemberModal from './ImportMemberModal';
import MemberLoading from './MemberLoading';
import RoleChangeModal from './RoleChangeModal';
import { FeedbackMessage, useStyles } from './styles';
import SubHeaderSection from './SubHeaderSection';
import { Box, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const errorFetchMembersListMessagens = {
  search: {
    initialLoad:
      'Nenhum usuário encontrado. Por favor, ajuste os critérios de busca ou tente novamente mais tarde.',
    loadingMore: 'Não foram encontrados mais resultados para essa busca.'
  },
  listing: {
    initialLoad:
      'Houve um problema ao tentar exibir a lista de membros. Por favor, recarregue a página ou tente novamente mais tarde.',
    loadingMore:
      'Não foi possível carregar mais membros da lista devido a um erro. Por favor, tente novamente mais tarde para continuar explorando a lista.'
  }
};
const membersPerPage = 18;

export default function MembersManagement({
  structureID,
  structureServices,
  userStructureAssociationServices,
  reportURLs,
  // environmentID é realmente necessário?
  environmentId,
  getUserFilterReport
}) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles({ theme });

  const observer = useRef(null);
  const anchorRef = useRef(null);

  const [totalMembers, setTotalMembers] = useState(0);
  const [membersLimitReached, setMembersLimitReached] = useState(false);
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [selectedMembersList, setSelectedMembersList] = useState([]);

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showImportMemberModal, setShowImportMemberModal] = useState(false);
  const [showRoleChangeModal, setShowRoleChangeModal] = useState(false);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal
  ] = useState(false);

  const [
    loadingStructureMembersDetails,
    setLoadingStructureMembersDetails
  ] = useState(false);

  const loadMembersDetails = async () => {
    await getStructureDetails();
    await loadMembersList(true);
  };

  const loadMembersList = async (resetListMembers = false) => {
    if (loadingStructureMembersDetails || !(hasMore || resetListMembers))
      return;
    const requestPage = resetListMembers ? 1 : page;
    setMembers((prev) => (resetListMembers ? [] : prev));
    if (searchString.length > 0) {
      await getListOfSearchedStructureMembers(requestPage, resetListMembers);
    } else {
      await getStructureMembersList(requestPage, resetListMembers);
    }
  };

  const getStructureDetails = async () => {
    setLoadingStructureMembersDetails(true);
    try {
      const structureData = await structureServices.findByID(structureID);
      setMembersLimitReached(
        structureData.enrollment_limit?.user_limit <=
          structureData.total_members
      );
      setTotalMembers(structureData.total_members);
    } catch (e) {
      enqueueSnackbar(`Algo deu errado ao carregar os dados dessa pagina`, {
        variant: 'error'
      });
    }
    setLoadingStructureMembersDetails(false);
  };

  const handleSuccessFetchMembersList = (loadedMembers, reset) => {
    const checkHasMoreMembers = loadedMembers.length >= membersPerPage;
    setHasMore(checkHasMoreMembers);
    if (reset) {
      setMembers([...loadedMembers]);
      setPage(2);
    } else {
      setMembers((prev) => [...prev, ...loadedMembers]);
      setPage((prev) => (checkHasMoreMembers ? prev + 1 : prev));
    }
  };

  const handleFailFetchMembersList = (message, reset) => {
    if (reset) {
      enqueueSnackbar(message.initialLoad, { variant: 'error' });
      setMembers([]);
      setHasMore(false);
    } else {
      enqueueSnackbar(message.loadingMore, { variant: 'error' });
      setHasMore(false);
    }
  };

  const getStructureMembersList = async (nextRequestPage, resetListMembers) => {
    setLoadingStructureMembersDetails(true);
    try {
      const loadedMembers = await structureServices.getEnrollments(
        structureID,
        nextRequestPage,
        membersPerPage
      );
      handleSuccessFetchMembersList(loadedMembers, resetListMembers);
    } catch (e) {
      handleFailFetchMembersList(
        errorFetchMembersListMessagens.listing,
        resetListMembers
      );
    }
    setLoadingStructureMembersDetails(false);
  };

  const getListOfSearchedStructureMembers = async (
    nextRequestPage,
    resetListMembers
  ) => {
    setLoadingStructureMembersDetails(true);
    try {
      const loadedMembers = await structureServices.searchMembers(
        structureID,
        searchString,
        nextRequestPage,
        membersPerPage
      );
      handleSuccessFetchMembersList(loadedMembers, resetListMembers);
    } catch (e) {
      handleFailFetchMembersList(
        errorFetchMembersListMessagens.search,
        resetListMembers
      );
    }
    setLoadingStructureMembersDetails(false);
  };

  const getRoleOfSelectedMembersIfUnique = () => {
    if (selectedMembersList.length > 0) {
      const roles = selectedMembersList.map((member) => member.role);
      const roleTypes = new Set(roles);
      const roleTypesIsUnique = roleTypes.size === 1;
      return roleTypesIsUnique ? roleTypes.values().next().value : null;
    } else {
      return null;
    }
  };

  const roleOfSelectedMembers = useMemo(
    () => getRoleOfSelectedMembersIfUnique(),
    [selectedMembersList]
  );

  useEffect(() => {
    loadMembersDetails();
  }, []);

  useEffect(() => {
    const init = async () => {
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMembersList();
        }
      });

      if (anchorRef.current) {
        observer.current.observe(anchorRef.current);
      }
    };

    init();

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [members, hasMore, loadingStructureMembersDetails]);

  const callbackSuccessAddMemberModal = () => {
    const resetMembersList = true;
    loadMembersList(resetMembersList);
    setTotalMembers((prev) => prev + 1);
  };

  return (
    <>
      <Box className={classes.container}>
        <SubHeaderSection
          setSearchString={setSearchString}
          searchString={searchString}
          loadMembersList={loadMembersList}
          openAddMemberModal={() => setShowAddMemberModal(true)}
          openImportMemberModal={() => setShowImportMemberModal(true)}
        />
        <ContextualBar
          structureID={structureID}
          selectedMembersList={selectedMembersList}
          setSelectedMembersList={setSelectedMembersList}
          members={members}
          totalMembers={totalMembers}
          roleOfSelectedMembers={roleOfSelectedMembers}
          loadingStructureMembersDetails={loadingStructureMembersDetails}
          getReport={getUserFilterReport}
          openRoleChangeModal={() => setShowRoleChangeModal(true)}
          openDeleteConfirmationModal={() =>
            setShowDeleteConfirmationModal(true)
          }
        />
        <Box className={classes.membersSection}>
          {members.length > 0 &&
            members.map((userData) => (
              <MemberItem
                userData={userData}
                key={userData.id}
                selectedMembersList={selectedMembersList}
                setSelectedMembersList={setSelectedMembersList}
              />
            ))}
          {loadingStructureMembersDetails && <MemberLoading />}
          <Box style={{ height: '8px' }} ref={anchorRef}></Box>
        </Box>
        {!loadingStructureMembersDetails &&
          members.length === 0 &&
          !hasMore &&
          (searchString.length > 0 ? (
            <FeedbackMessage
              color={theme.palette.text.secondary}
              message={`Nenhum membro encontrado para ${searchString}.`}
            />
          ) : (
            <FeedbackMessage
              color={theme.palette.text.secondary}
              message={'Esta estrutura ainda não possui membros cadastrados.'}
            />
          ))}
      </Box>
      <AddMemberModal
        showAddMemberModal={showAddMemberModal}
        closeAddMemberModal={() => setShowAddMemberModal(false)}
        membersLimitReached={membersLimitReached}
        setMembersLimitReached={setMembersLimitReached}
        callbackSuccessAddMemberInStructure={callbackSuccessAddMemberModal}
        structureID={structureID}
        checkAssociationService={structureServices.checkAssociation}
        registerUserInStructureService={userStructureAssociationServices.create}
        environmentId={environmentId}
        structureService={structureServices}
      />
      <ImportMemberModal
        showImportMemberModal={showImportMemberModal}
        closeImportMemberModal={() => setShowImportMemberModal(false)}
        structureID={structureID}
        loadMembersDetails={loadMembersDetails}
        membersLimitReached={membersLimitReached}
        importMembersFromFileService={structureServices.importMembersFromFile}
        removeMembersFromFileService={structureServices.removeMembersFromFile}
        reportURLs={reportURLs}
      />
      <DeleteConfirmationModal
        selectedMembersList={selectedMembersList}
        setSelectedMembersList={setSelectedMembersList}
        structureID={structureID}
        deleteMembersService={structureServices.deleteMembers}
        loadMembersDetails={loadMembersDetails}
        showDeleteConfirmationModal={showDeleteConfirmationModal}
        closeDeleteConfirmationModal={() =>
          setShowDeleteConfirmationModal(false)
        }
      />
      <RoleChangeModal
        showRoleChangeModal={showRoleChangeModal}
        closeRoleChangeModal={() => setShowRoleChangeModal(false)}
        roleOfSelectedMembers={roleOfSelectedMembers}
        selectedMembersList={selectedMembersList}
        setSelectedMembersList={setSelectedMembersList}
        setMembers={setMembers}
        structureID={structureID}
        updateRolesService={structureServices.updateRoles}
      />
    </>
  );
}
MembersManagement.propTypes = {
  structureID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  reportURLs: PropTypes.shape({
    import: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired
  }).isRequired,
  structureServices: PropTypes.shape({
    findByID: PropTypes.func.isRequired,
    getEnrollments: PropTypes.func.isRequired,
    searchMembers: PropTypes.func.isRequired,
    checkAssociation: PropTypes.func.isRequired,
    importMembersFromFile: PropTypes.func.isRequired,
    removeMembersFromFile: PropTypes.func.isRequired,
    deleteMembers: PropTypes.func.isRequired,
    updateRoles: PropTypes.func.isRequired
  }).isRequired,
  userStructureAssociationServices: PropTypes.shape({
    create: PropTypes.func.isRequired
  }).isRequired,
  environmentId: PropTypes.number,
  getUserFilterReport: PropTypes.func.isRequired
};
