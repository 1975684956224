import React, { useEffect, useState } from 'react';

import { ReactComponent as VideoCallIllustration } from '~/assets/images/video_call_illustration.svg';
import LectureExpiredAlert from '~/components/molecules/LectureExpiredAlert';

import { DisplayDescription } from './DisplayDescription';
import { useStyles } from './styles';
import { Box, Typography, Link, useTheme, Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LaunchIcon from '@material-ui/icons/Launch';
import NotesIcon from '@material-ui/icons/Notes';
import PropTypes from 'prop-types';

const VideoCallIllustrationHeight = 468;
const boxContentPadding = 16;

// Criando um formatter com as opções desejadas
const formatterStartDate = new Intl.DateTimeFormat('pt-BR', {
  weekday: 'long', // nome do dia da semana
  day: 'numeric', // dia do mês
  month: 'long', // nome do mês
  hour: 'numeric', // hora
  minute: 'numeric' // minutos
});

const formatterEndDate = new Intl.DateTimeFormat('pt-BR', {
  hour: 'numeric', // hora
  minute: 'numeric' // minutos
});

export default function GoogleMeet({ event, expired }) {
  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const meetBaseUrl = 'https://meet.google.com/';
  const [roomURL, setRoomURL] = useState('');

  useEffect(() => {
    if (event) setRoomURL(meetBaseUrl + event.code);
  }, [event]);

  const formatDate = (startDate, endDate) => {
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();

    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();

    if (startMonth !== endMonth || startDay !== endDay) {
      const formattedStartDate = formatterStartDate.format(startDate);
      const formattedEndDate = formatterStartDate.format(endDate);
      return `${formattedStartDate} | ${formattedEndDate}`;
    } else {
      const formattedStartDate = formatterStartDate.format(startDate);
      const formattedEndDate = formatterEndDate.format(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  };

  return (
    <>
      {expired || !event ? (
        <LectureExpiredAlert />
      ) : (
        <Box
          padding={`${boxContentPadding}px`}
          height={`${VideoCallIllustrationHeight + 2 * boxContentPadding}px`}
          className={classes.root}
        >
          <Box className={classes.content}>
            <Box className={classes.infomations}>
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: '24px' }}
              >
                <Typography className={classes.title}>{event.title}</Typography>
                <Box display="flex" style={{ gap: '8px' }}>
                  <DateRangeIcon
                    style={{
                      fontSize: '24px',
                      color: theme.palette.activeText.main
                    }}
                  />
                  <Typography className={classes.eventDate}>
                    {formatDate(
                      new Date(event.start_date),
                      new Date(event.end_date)
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.descriptionContainer}
                style={{
                  opacity: event.description ? 1 : 0.5
                }}
              >
                <NotesIcon
                  style={{
                    fontSize: '24px',
                    color: theme.palette.activeText.main
                  }}
                />
                {<DisplayDescription description={event.description} />}
              </Box>
            </Box>
            <Divider />
            <Link
              href={roomURL}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.goToMeetLink}
            >
              <Typography className={classes.linkText}>
                Entrar na videochamada
              </Typography>
              <LaunchIcon
                style={{
                  fill: theme.palette.textAndBackground.main,
                  fontSize: '24px'
                }}
              />
            </Link>
          </Box>
          {isUpMD && (
            <VideoCallIllustration
              fill={theme.palette.primary.main}
              style={{
                height: `${VideoCallIllustrationHeight}px`,
                width: 'auto'
              }}
            />
          )}
        </Box>
      )}
    </>
  );
}

GoogleMeet.propTypes = {
  expired: PropTypes.bool,
  event: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    start_date: PropTypes.shape({
      date_time: PropTypes.string.isRequired
    }),
    end_date: PropTypes.shape({
      date_time: PropTypes.string.isRequired
    })
  })
};
