import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#B4BDD3',
    width: '100%',
    height: '90%',
    borderRadius: 4,
    padding: 24,
    gap: 24
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '62%',
    height: '100%',
    textAlign: 'center',
    gap: 24,
    color: '#32394E'
  },
  largeText: {
    fontSize: 20,
    lineHeight: '28px'
  },
  spanText: {
    fontSize: 20,
    lineHeight: '16px'
  },
  lockIcon: {
    width: '40px',
    height: '40px'
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  },
  addButton: {
    display: 'inline-flex',
    fontSize: 14,
    padding: '12px 24px 12px 24px',
    textTransform: 'none',
    borderRadius: 4,
    background: theme.palette.primary.main,
    color: '#fff',
    marginTop: 8,
    '&:hover': {
      background: theme.palette.primary.main,
      filter: 'saturate(180%)'
    }
  }
}));
