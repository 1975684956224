import React from 'react';

import Controls from '~/components/organisms/AudioPlayer/BottomSection/Controls';
import PlayButton from '~/components/organisms/AudioPlayer/BottomSection/PlayButtom';

import { useStyles } from './style';
import { Box, Slider, Typography, useTheme } from '@material-ui/core';
import Forward10RoundedIcon from '@material-ui/icons/Forward10Rounded';
import Replay10RoundedIcon from '@material-ui/icons/Replay10Rounded';

export default function BottomSection({
  playing,
  setPlaying,
  progress,
  setProgress,
  duration,
  playbackRate,
  setPlaybackRate,
  seekTo
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const togglePlayPause = () => setPlaying((prev) => !prev);

  function onChange(_, value) {
    setProgress(value);
    seekTo(value);
  }

  return (
    <Box className={classes.root}>
      <PlayButton playing={playing} onClick={togglePlayPause} />

      <Box className={classes.container}>
        <Slider
          classes={{
            root: classes.progressBarRoot,
            thumb: classes.progressBarThumb,
            track: classes.progressBarTrack,
            rail: classes.progressBarRail
          }}
          min={0}
          max={duration}
          step={1}
          value={progress}
          onChange={onChange}
        />

        <Controls
          setPlaybackRate={setPlaybackRate}
          playbackRate={playbackRate}
          duration={duration}
          progress={progress}
          seekTo={seekTo}
        />
      </Box>
    </Box>
  );
}
