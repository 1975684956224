import React     from 'react';
import Text      from '~/components/atoms/Text';
import DropIcon  from '../../Icons/DropIcon';
import FileName  from '../../FileName';
import Error     from '../../Error';
import SizeError from '../../SizeError';

import { Grid }        from '@material-ui/core';
import { useDrop }     from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { useStyles } from './styles';

export default function DropTarget(props) {

  const { 
    setFileTypeError, 
    onDrop, 
    Input, 
    selectedFile, 
    setSelectedFile, 
    canUploadType, 
    fileTypeError, 
    sizeError,
    setSizeError,
    checkMaxSize 
  } = props
  const classes = useStyles()

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          if (canUploadType(item.files[0].type)) {
            if (checkMaxSize(item.files[0].size/1000000)){
              onDrop(item);
              setFileTypeError(false);
              setSizeError(false);
            } else {
              setSelectedFile(null)
              setSizeError(true)
            }
          } else {
            setSelectedFile(null);
            setFileTypeError(true);
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [props]
  );

  const SelectedFile = () => {
    return(
      <Grid contaner alignItems='center' direction='column' spacing={1}>

        <Grid item>
          <Input />
        </Grid>

        <Grid item>
          <FileName name={ selectedFile.name }/>          
        </Grid>

      </Grid>
    );
  };

  const renderComponent = (Component, text) => {
    return (
      <>
        <Grid item>
          <Component />
        </Grid>
      
        <Grid item>
          <div>
            <Text textAlign="center" style={{ color: "#c9c9cc", fontSize: 14 }}>
              {text}
            </Text>
          </div>
        </Grid>
      </>
    );
  };
  
  
  const isActive = canDrop && isOver;

  return(
    <div ref={drop} className={classes.root}>
      <Grid contaner justifyContent='center' alignItems='center' direction='column' spacing={1}>
        { isActive ? 
          renderComponent(DropIcon, "Solte aqui...")
          :
          selectedFile ?
            <SelectedFile/>
            :
            renderComponent(Input, "Selecione ou arraste o arquivo para este campo...")
        }
        </Grid>

        { fileTypeError && <Error/> }
        
        { sizeError && <SizeError/> }
    </div>
  );
}
