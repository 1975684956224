import React, { useState } from 'react';

import enrollmentLinkService from '~/services/enrollment-link';

import EnrollmentLinkForm from '../EnrollmentLinkForm';
import { Box, Collapse, Switch, Typography, useTheme } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export default function EnrollmentLinkToggle({
  structureID,
  tooltipMessage,
  getEnrollmentLink,
  createEnrollmentLink
}) {
  const theme = useTheme();

  const [show, setShow] = useState(false);

  return (
    <>
      <Box display={'flex'} alignItems={'center'}>
        <Switch checked={show} onChange={() => setShow((prev) => !prev)} />
        <Typography style={{ fontSize: 14, color: theme.palette.primary.main }}>
          Configurar Link de Inscrição
        </Typography>
      </Box>

      <Collapse in={show}>
        {show && structureID && (
          <EnrollmentLinkForm
            structureID={structureID}
            tooltipMessage={tooltipMessage}
            get={getEnrollmentLink}
            create={createEnrollmentLink}
            enrollmentLinkService={enrollmentLinkService}
          />
        )}
      </Collapse>
    </>
  );
}

EnrollmentLinkToggle.propTypes = {
  structureID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getEnrollmentLink: PropTypes.func,
  createEnrollmentLink: PropTypes.func,
  enrollmentLinkService: PropTypes.shape({
    update: PropTypes.func,
    remove: PropTypes.func
  }),
  tooltipMessage: PropTypes.string
};

EnrollmentLinkToggle.defaultProps = {
  structureID: undefined,
  getEnrollmentLink: async () => {},
  createEnrollmentLink: async () => {},
  enrollmentLinkService: PropTypes.shape({
    update: async () => {},
    remove: async () => {}
  }),
  tooltipMessage: ''
};
