import React from 'react';

import PropTypes from 'prop-types';

import { Box, Radio, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

export default function Alternative({
  alternative,
}) {
  const { text } = alternative;

  const theme = useTheme();
  const classes = useStyles({
    theme,
  });

  return (
    <Box className={classes.root}>
      <Radio
        disabled
        className={classes.radio}
        disableRipple
        color="default"
        checked={false}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: '12px'
        }}
      >
        <div
          className={classes.imgData}
          dangerouslySetInnerHTML={{
            __html: `<div>
              ${text}
            </div>`
          }}
        />
      </Box>
    </Box>
  );
}

Alternative.propTypes = {
  alternative: PropTypes.shape(),
};

Alternative.defaultProps = {
  alternative: {},
};
 