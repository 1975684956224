import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ClearFiltersButton } from '../ClearFIltersButton';
import { FilterOption } from './FilterOption';
import { useStyles } from './styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Drawer,
  Typography,
  useTheme
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';

const filters = ['Estado', 'Grupo Regional', 'Cidade'];

export const MobileFilters = ({
  handleSelectFilters,
  clearFilters,
  states,
  regionalGroups,
  cities,
  disableClearFilters
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { watch } = useFormContext();

  const optionsMap = {
    Estado: { values: states, name: 'state' },
    'Grupo Regional': { values: regionalGroups, name: 'regionalGroup' },
    Cidade: { values: cities, name: 'city' }
  };

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={() => setOpen(true)}
        className={classes.openDrawerButton}
      >
        <FilterListIcon
          style={{ fontSize: '20px', color: theme.palette.activeText.main }}
        />
        <Typography className={classes.buttonLabel}>Filtros</Typography>
      </ButtonBase>
      <Drawer
        anchor={'bottom'}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: classes.drawerPaper
        }}
      >
        <Box className={classes.drawerContent}>
          <Box className={classes.filtersContent}>
            <Box className={classes.header}>
              <Typography className={classes.title}>Filtros:</Typography>
              <ButtonBase disableRipple onClick={() => setOpen(false)}>
                <CloseIcon className={classes.icon} />
              </ButtonBase>
            </Box>
            <Box className={classes.filters}>
              {filters.map((filterName, index) => (
                <Accordion
                  key={index}
                  classes={{
                    root: classes.accordion,
                    expanded: classes.expanded
                  }}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ArrowDropDownIcon className={classes.icon} />}
                  >
                    <Typography className={classes.AccordionTitle}>
                      {watch(optionsMap[filterName].name) === ''
                        ? filterName
                        : watch(optionsMap[filterName].name)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FilterOption
                      handleSelectFilters={handleSelectFilters}
                      options={optionsMap[filterName].values}
                      filterTitle={filterName}
                      filterName={optionsMap[filterName].name}
                      closeDrawer={() => setOpen(false)}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
          <ClearFiltersButton
            clearFilters={() => {
              clearFilters();
              setOpen(false);
            }}
            disableClearFilters={disableClearFilters}
          />
        </Box>
      </Drawer>
    </>
  );
};

MobileFilters.propTypes = {
  handleSelectFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  regionalGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  cities: PropTypes.arrayOf(PropTypes.string).isRequired,
  disableClearFilters: PropTypes.bool
};
