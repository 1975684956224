import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '200px',
    marginTop: '24px',
    padding: '24px',
    borderRadius: '16px',
    backgroundColor: '#F2F2F7',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  textContainer: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '800px'
  },
  title: {
    fontWeight: '700',
    lineHeight: '36px',
    color: theme.palette.activeText.main,
    fontSize: '28px'
  },
  message: {
    lineHeight: '30px',
    color: theme.palette.activeText.main,
    fontSize: '16px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px'
    }
  },
  image: {
    fill: theme.palette.primary.main,
    height: 'auto',
    width: '300px',
    [theme.breakpoints.up('xl')]: {
      width: '400px'
    }
  }
}));
