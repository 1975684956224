import React from 'react';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import {
  Dialog,
  Box,
  Typography,
  ButtonBase,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
export default function ConfirmationDialog({
  show,
  handleClose,
  message,
  handleConfirm,
  isLoading,
  confirmButtonText,
  titleText,
  titleIcon,
  colorVariant
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          padding: '24px'
        }
      }}
    >
      <Box className={classes.header}>
        <Box style={{ display: 'flex', gap: '8px' }}>
          {titleIcon}
          <Typography className={classes.textHeader}>{titleText}</Typography>
        </Box>
        <CloseIcon className={classes.exitButton} onClick={handleClose} />
      </Box>
      <Typography
        style={{ color: '#676D82', fontSize: '16px', marginBottom: '24px' }}
      >
        {message}
      </Typography>
      <Box className={classes.buttons}>
        <ButtonBase
          disableRipple
          className={classes.cancelButton}
          onClick={handleClose}
        >
          Cancelar
        </ButtonBase>
        <ButtonBase
          onClick={handleConfirm}
          disableRipple
          className={clsx(
            classes.confirmButton,
            classes[colorVariant ? `${colorVariant}Color` : 'dangerColor']
          )}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              style={{ color: theme.palette.secondary.main }}
            />
          ) : (
            `${confirmButtonText}`
          )}
        </ButtonBase>
      </Box>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  confirmButtonText: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  titleIcon: PropTypes.element,
  colorVariant: PropTypes.string
};
