import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    padding: '10px 25px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: (props) => (props.selected ? '#EAEAEA' : 'white'),
    cursor: 'pointer',
    '&:hover .optionsContainer': {
      display: 'block'
    },
    '&:hover': {
      backgroundColor: (props) => (props.selected ? '#EAEAEA' : '#FAFAFA')
    }
  },
  middleArea: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',
    '& p': {
      display: 'inline-block',
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      margin: 0
    }
  },
  notificationArea: {
    marginLeft: 25
  },
  notificationCircle: {
    backgroundColor: (props) => props.theme.palette.error.main,
    width: 22,
    height: 22,
    borderRadius: 22 / 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: 'white',
    fontWeight: 'bold'
  },
  optionsContainer: {
    marginLeft: 10,
    display: (props) => (props.showOptions ? 'block' : 'none'),
    position: 'relative'
  },
  optionButton: {
    '& svg': {
      width: 30,
      height: 30,
      color: (props) => props.theme.palette.textActiveElement.main
    }
  }
});
