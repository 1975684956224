import React, { useMemo } from 'react';

import { ReactComponent as FileFormatDefault } from '~/assets/images/fileFormatDefault.svg';
import { ReactComponent as FileFormatDOC } from '~/assets/images/fileFormatDOC.svg';
import { ReactComponent as FileFormatGIF } from '~/assets/images/fileFormatGIF.svg';
import { ReactComponent as FileFormatJPEG } from '~/assets/images/fileFormatJPEG.svg';
import { ReactComponent as FileFormatJPG } from '~/assets/images/fileFormatJPG.svg';
// import { ReactComponent as FileFormatMP3 } from '~/assets/images/fileFormatMP3.svg';
// import { ReactComponent as FileFormatMP4 } from '~/assets/images/fileFormatMP4.svg';
import { ReactComponent as FileFormatPDF } from '~/assets/images/fileFormatPDF.svg';
import { ReactComponent as FileFormatPNG } from '~/assets/images/fileFormatPNG.svg';
import { ReactComponent as FileFormatPPT } from '~/assets/images/fileFormatPPT.svg';
import { ReactComponent as FileFormatTXT } from '~/assets/images/fileFormatTXT.svg';
import { ReactComponent as FileFormatXLS } from '~/assets/images/fileFormatXLS.svg';
import { getAttachment } from '~/services/status-file';
import { formatFileSize } from '~/utils/lectureMuralAuxFunctions';

import { useStyles } from './styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

// MP3 e MP4 atualmente não são permitidos
const fileTypeIconMap = {
  png: FileFormatPNG,
  jpg: FileFormatJPG,
  jpeg: FileFormatJPEG,
  // mp4: FileFormatMP4,
  gif: FileFormatGIF,
  pdf: FileFormatPDF,
  ppt: FileFormatPPT,
  pptx: FileFormatPPT,
  xls: FileFormatXLS,
  xlsx: FileFormatXLS,
  doc: FileFormatDOC,
  docx: FileFormatDOC,
  txt: FileFormatTXT
  // mp3: FileFormatMP3
};

export const DisplayAttachmentFile = ({
  onDelete,
  downloadable,
  attachment
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const attachmentData = {
    name: attachment.name,
    type: attachment.name?.split('.')[attachment.name?.split('.').length - 1],
    size: attachment.size
  };
  const FileIcon = fileTypeIconMap[attachmentData.type] || FileFormatDefault;

  const formattedSize = useMemo(() => formatFileSize(attachmentData.size), [
    attachmentData.size
  ]);

  const getFileToDownload = async () => {
    try {
      const data = await getAttachment(attachment.id);
      fileDownload(data, attachment.name);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível realizar o download desse anexo. Recarregue a página e tente novamente. Se o erro persistir, contate o suporte.',
        {
          variant: 'error'
        }
      );
    }
  };

  return (
    <Box className={classes.root}>
      {FileIcon && <FileIcon />}
      <Box className={classes.fileInfos}>
        <Box className={classes.titleBox}>
          <Typography title={attachmentData.name} className={classes.fileName}>
            {attachmentData.name}
          </Typography>
          <Typography className={classes.fileSize}>{formattedSize}</Typography>
        </Box>
        <Box className={classes.actionBox}>
          {onDelete && (
            <ButtonBase className={classes.actionButton} onClick={onDelete}>
              <DeleteRoundedIcon className={classes.actionIcons} />
            </ButtonBase>
          )}
          {downloadable && (
            <ButtonBase
              className={classes.actionButton}
              onClick={getFileToDownload}
            >
              <GetAppRoundedIcon className={classes.actionIcons} />
            </ButtonBase>
          )}
        </Box>
      </Box>
    </Box>
  );
};

DisplayAttachmentFile.propTypes = {
  onDelete: PropTypes.func,
  downloadable: PropTypes.bool,
  attachment: PropTypes.object.isRequired
};
