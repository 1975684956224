/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import Alert from '~/components/atoms/ValidationAlertDialog';
import Accordion from '~/components/templates/Accordion';
import Section from '~/components/templates/Section';
import { Days } from '~/constants/days';
import {
  educationableTypes,
  higherEducationKind,
  eventEducationRole
} from '~/constants/education';
import { Months } from '~/constants/months';
import { Years } from '~/constants/years';
import i18n from '~/I18n';
import history from '~/services/history';
import { handleSuccess } from '~/utils/auxiliaryFunctions';

import FormAcademicEducation from './FormAcademicEducation';
import FormInterests from './FormInterests';
import FormProfessionalExperience from './FormProfessionalExperience';
import ReactHookFormSelect from './ReactHookFormSelect';
import ReactHookFormTextField from './ReactHookFormTextField';
import { useStyles } from './styles';
import { Box, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AsYouType } from 'libphonenumber-js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function EditBioCurriculum(props) {
  const {
    onSubmit,
    profileData,
    submittingBC,
    submitFailed,
    displayFillingError,
    displayError,
    displaySuccess,
    setFillingError,
    setDisplayError,
    setDisplaySuccess,
    birthday,
    phone
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const fillingErrorMessage =
    'Caso tenha adicionado alguma Formação ou Experiência Profissional, você precisa preencher todos os campos.';
  const errorMessage = 'Opa! Houve algum problema na submissão.';
  const successMessage = 'Dados salvos com sucesso!';
  const callbackSuccessPath = `/profile/${profileData?.id}`;

  const [auxDisplayFillingError, setAuxDisplayFillingError] = useState(false);

  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (submitFailed) {
      setDisplayError(true);
      setDisplaySuccess(false);
    }
  }, [submitFailed]);

  /* ------- Funcionalidade para o alerta de erro de preenchimento ------- */
  useEffect(() => {
    setAuxDisplayFillingError(displayFillingError);
  }, [displayFillingError]);

  useEffect(() => {
    setTimeout(() => {
      setFillingError(false);
      setAuxDisplayFillingError(false);
    }, 6000);
  }, [auxDisplayFillingError, setFillingError]);
  /* --------------------------------------------------------------------- */

  useEffect(() => {
    if (profileData) {
      const experiencesArr = profileData.experiences.map((exp) => {
        // mandar no formato dia-mes-ano
        const startDate = exp.start_date
          ? exp.start_date.split('-')
          : [null, null];
        const startMonth = Months[parseInt(startDate[1]) - 1];
        const startYear = parseInt(startDate[0]);

        const endDate = exp.end_date ? exp.end_date.split('-') : [null, null];
        const endMonth = endDate[1] ? Months[parseInt(endDate[1]) - 1] : null;
        const endYear = endDate[0] ? parseInt(endDate[0]) : null;

        return {
          ...exp,
          start_date: { month: startMonth, year: startYear },
          end_date: { month: endMonth, year: endYear }
        };
      });

      const formattedEducations = profileData.educations.map((edu) => {
        const newEdu = { ...edu };
        const newEducationable = { ...edu.educationable };

        const newEduType = educationableTypes.find(
          (type) => type.id === edu.educationable_type
        );
        newEdu.educationable_type = newEduType ? newEduType.name : '';

        if (edu.educationable.kind) {
          const kind = higherEducationKind.find(
            (kind) => kind.id === edu.educationable.kind
          );
          newEducationable.kind = kind ? kind.name : '';
        }

        if (edu.educationable.role) {
          const role = eventEducationRole.find(
            (role) => role.id === edu.educationable.role
          );
          newEducationable.role = role ? role.name : '';
        }

        if (edu.educationable.year)
          newEducationable.year = edu.educationable.year.split('-')[0];
        if (edu.educationable.start_year)
          newEducationable.start_year = edu.educationable.start_year.split(
            '-'
          )[0];
        if (edu.educationable.end_year)
          newEducationable.end_year = edu.educationable.end_year.split('-')[0];

        newEdu.educationable = newEducationable;
        return newEdu;
      });

      setEducations(formattedEducations);
      setExperiences(experiencesArr);

      // props.initialize(initialValues);
    }
  }, [profileData]);

  const methods = useForm();

  function handleCancel() {
    setDisplaySuccess(false);
    setDisplayError(false);

    if (profileData) history.push(`/profile/${profileData.id}`);
  }

  const errorPhone = useMemo(() => {
    const phone = methods.watch('mobile');

    const checkStringHasLetter = /[a-zA-Z]/g;

    return checkStringHasLetter.test(phone);
  }, [methods.watch('mobile')]);

  const generos = ['Feminino', 'Masculino'];

  return (
    <Section className={classes.root} title={i18n.t('BioCurriculum')}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ReactHookFormTextField
            label={i18n.t('Description') + ' *'}
            name="description"
            defaultValue={profileData?.description}
            required
          />

          <ReactHookFormTextField
            isPhone
            label="Telefone"
            name="mobile"
            defaultValue={new AsYouType('BR').input(phone)}
          />

          {errorPhone && (
            <Box style={{ marginTop: 12 }}>
              <span style={{ fontSize: 15, color: 'red' }}>
                Error: Esse campo só aceita numeros!
              </span>
            </Box>
          )}

          <Text variant="label" color="gray">
            Data de Nascimento *
          </Text>
          <Box className={classes.row}>
            <ReactHookFormSelect
              id="day"
              name="day"
              label="Dia"
              control={methods.control}
              values={Days}
              defaultValue={birthday?.day}
              variant="outlined"
              margin="normal"
              isRequired={true}
            />

            <ReactHookFormSelect
              id="month"
              name="month"
              label={i18n.t('Month')}
              control={methods.control}
              values={Months}
              defaultValue={birthday?.month}
              variant="outlined"
              margin="normal"
              isRequired={true}
            />

            <ReactHookFormSelect
              id="year"
              name="year"
              label={i18n.t('Year')}
              control={methods.control}
              values={Years}
              defaultValue={birthday?.year}
              variant="outlined"
              margin="normal"
              isRequired={true}
            />
          </Box>

          <ReactHookFormSelect
            id="gender"
            name="gender"
            label="Gênero *"
            control={methods.control}
            values={generos}
            defaultValue={profileData?.gender}
            variant="outlined"
            margin="normal"
            isRequired={true}
          />

          <Box className={classes.subsection}>
            <Accordion
              className={classes.subtitleContainer}
              title={i18n.t('Education')}
            >
              <FormAcademicEducation fieldsAcademic={educations} />
            </Accordion>
          </Box>
          <Box className={classes.subsection}>
            <Accordion
              className={classes.subtitleContainer}
              title={i18n.t('Experience')}
            >
              <FormProfessionalExperience fieldsExperience={experiences} />
            </Accordion>
          </Box>
          <Box className={classes.subsection}>
            <Accordion
              className={classes.subtitleContainer}
              title={i18n.t('Languages')}
            >
              <ReactHookFormTextField
                label={i18n.t('Languages')}
                name="languages"
                defaultValue={profileData?.languages}
              />
            </Accordion>
          </Box>
          <Box className={classes.subsection}>
            <Box className={classes.subtitleContainer}>
              <Text variant="subtitle" color="primary">
                {i18n.t('InterestAreas')}
              </Text>
              <Text variant="mediumText" color="gray">
                {`(Após digitar um interesse, pressione o botão Enter do teclado para adicionar.)`}
              </Text>
            </Box>
            <FormInterests fieldsInterests={profileData?.interested_areas} />
          </Box>
          <Box className={classes.submitArea}>
            {submittingBC ? (
              <Box>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box className={classes.buttons}>
                <ButtonSquare
                  type="button"
                  onClick={handleCancel}
                  height={50}
                  width={100}
                  backgroundColor={theme.palette.textAndBackground.main}
                  letterSpacing={0}
                  fontSize="16px"
                  border={`1px solid ${theme.palette.secondary.main}`}
                >
                  <Text variant="button" color="secondary">
                    {i18n.t('Cancel')}
                  </Text>
                </ButtonSquare>
                <Box className={classes.saveButton}>
                  <ButtonSquare
                    type="submit"
                    height={50}
                    width={100}
                    backgroundColor={theme.palette.primary.main}
                    letterSpacing={0}
                    fontSize="16px"
                  >
                    <Text variant="button" color="textAndBackground">
                      {i18n.t('Save')}
                    </Text>
                  </ButtonSquare>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.subsection}>
            {auxDisplayFillingError && (
              <Alert
                handleClose={() => {
                  setFillingError(false);
                }}
                alertType="warning"
                message={fillingErrorMessage}
              />
            )}
            {displayError && (
              <Alert
                handleClose={() => {
                  setDisplayError(false);
                }}
                alertType="error"
                message={errorMessage}
              />
            )}
            {displaySuccess &&
              handleSuccess(
                successMessage,
                enqueueSnackbar,
                callbackSuccessPath,
                history
              )}
          </Box>
        </form>
      </FormProvider>
    </Section>
  );
}

EditBioCurriculum.propTypes = {
  props: PropTypes.shape()
};

EditBioCurriculum.defaultProps = {
  props: {}
};
