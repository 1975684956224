import React        from 'react'
import ReactLoading from 'react-loading';

import { Modal } from '@material-ui/core'

export default function LoadingModal({ loading }) {
  return (
    <div> 
      <Modal open={loading} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
        <ReactLoading width={100} height={50} type="bars"></ReactLoading>
      </Modal> 
    </div> 
)
}