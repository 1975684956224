import React, { useCallback, useEffect, useState } from 'react'

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { Button, Collapse, Grid, Hidden, Typography, useTheme } from '@material-ui/core'

import { IconWrapper } from '~/utils/forms'
import { Alert } from '@material-ui/lab';
import { useStyles } from './styles';
export default function LinkButton ({ QRCodeLink }){
  const theme   = useTheme()
  const classes = useStyles()
  
  const [position, setPosition] = useState({})
  const [open, setOpen]         = useState(false)
  
  useEffect(() => {
    if(open) {
      var rect = document.getElementById("clipButton").getBoundingClientRect()
      setPosition({ top: rect.top + 35, left: rect.left + 15 })
  
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
  }, [open])
  
  const handleClipboard = () => { 
    navigator.clipboard.writeText(QRCodeLink)
    setOpen(true)
  }

  const FloatText = useCallback(
    () => {
      return <div className={classes.floatRoot} style={{ ...position }}>
                <Alert className={classes.alert} severity="success"> Copiado! </Alert>
              </div>
    },
    [position],
  )
  
  return (
    <>
      <Button
        id="clipButton"
        classes={{ root: classes.btnRoot }}
        onClick={handleClipboard}
      >
        <Grid container justifyContent='center' alignItems='center' spacing={1}>
          <Hidden xsDown>
            <Grid item>
              <Typography className={classes.typograph} > Copiar Link </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <IconWrapper Icon={FileCopyOutlinedIcon} customColor="white" fontSize={15}/>
          </Grid>
        </Grid>
      </Button>
      
      <Collapse in={open}>
        <FloatText/>
      </Collapse>
    </>
  )
}