import { Typography, Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled(Box)`
  margin-top: 0.4rem;
`;

export const Message = styled(Typography)`
  ${({ color }) => css`
    font-size: 1rem;
    color: ${color};
  `}
`;
