import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  },
  conversationList: {
    flexGrow: 1,
    width: '100%',
    overflowY: 'scroll'
  },
  inputContainer: {
    display: 'flex',
    padding: '30px 30px 30px 30px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '30px 0 30px 30px'
    }
  },
  buttonContainer: {
    display: 'none',
    '& button': {
      width: 30,
      height: 30,
      marginLeft: 15
    },
    '@media (max-width: 600px)': {
      display: 'flex'
    }
  },
  arrowIcon: {
    color: (props) => props.theme.palette.primary.main,
    width: 20,
    height: 20
  },
  chatEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h1': {
      fontSize: '22px',
      fontWeight: '500',

      '& strong': {
        fontWeight: '500',
        color: '#1A73F9'
      }
    },
    '& h2': {
      fontSize: '14px',
      marginTop: '8px',
      fontWeight: '500'
    }
  }
});
