import React, { useMemo, useState } from 'react';
import { MdFrontHand } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PopUpMenu from '~/components-refactor/molecules/PopUpMenu';
import Item from '~/components-refactor/molecules/PopUpMenu/Item';
import AvatarProfile from '~/components/atoms/Avatar';
import ConfirmationDialog from '~/components/molecules/ConfirmationDialog';
import { useLectureDiscussionMuralContext } from '~/contexts/LectureDiscussionMuralContext';
import { handleDeleteStatusMural } from '~/services/mural';
import { formatTimeDistanceToNow } from '~/utils/lectureMuralAuxFunctions';

import { Attachment } from './Attachment';
import { FootButtons } from './FootButtons';
import { useStyles } from './styles';
import { Box, ButtonBase, Typography, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import anchorme from 'anchorme';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const Status = React.memo(({ status, isAnswer, avatarSize }) => {
  const profile = useSelector((state) => state.user.profile);
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user, answers, id, text, created_at, type, status_files } = status;
  const [showDeleteStatusModal, setShowDeleteStatusModal] = useState(false);
  const [deletingStatus, setDeletingStatus] = useState(false);
  const { setStatusArrayFuncs } = useLectureDiscussionMuralContext();

  const handleDeleteStatus = async () => {
    if (deletingStatus) return;
    try {
      setDeletingStatus(true);
      await handleDeleteStatusMural(id);
      if (isAnswer) {
        setStatusArrayFuncs.removeAnswer(status);
      } else {
        setStatusArrayFuncs.removeStatus(status);
      }
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível excluir este comentário, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
        {
          variant: 'error'
        }
      );
    } finally {
      setDeletingStatus(false);
      setShowDeleteStatusModal(false);
    }
  };

  const timeDistanceToNow = useMemo(() => formatTimeDistanceToNow(created_at), [
    created_at
  ]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.avatarSection}>
          <AvatarProfile profile={user} size={avatarSize} />
          {type === 'Help' && <MdFrontHand className={classes.helpIcon} />}
        </Box>
        <Box className={classes.postSection}>
          <Box className={classes.postSectionHeader}>
            {user ? (
              <Link to={`/profile/${user.id}`} className={classes.userName}>
                <Typography className={classes.userName} title={user.name}>
                  {user.name}
                </Typography>
              </Link>
            ) : (
              <Typography
                className={classes.userName}
                title={'Usuário Excluído'}
              >
                Usuário Excluído
              </Typography>
            )}
            <Typography className={classes.timeDistance}>
              {timeDistanceToNow}
            </Typography>
          </Box>
          <Box className={classes.postBody}>
            <Typography className={classes.postBodyText}>
              {parse(
                anchorme({
                  input: text,
                  options: {
                    attributes: {
                      target: '_blank'
                    }
                  }
                })
              )}
            </Typography>
            {status_files.map((attachment) => (
              <Box key={attachment.id}>
                <Attachment attachment={attachment} />
              </Box>
            ))}
          </Box>
          <Box className={classes.footButtonsContainer}>
            {!isAnswer && (
              <FootButtons answers={answers} statusID={status.id} />
            )}
          </Box>
        </Box>
        <Box className={classes.popUpMenuSection}>
          {(profile.role === 'admin' || profile.id === status.user?.id) && (
            <PopUpMenu
              component={({ ...props }) => (
                <ButtonBase
                  {...props}
                  disableRipple
                  className={classes.borderRadiusPopUpMenu}
                >
                  <MoreVertIcon className={classes.popUpMenuIcon} />
                </ButtonBase>
              )}
              componentProps={{
                className: classes.borderRadiusPopUpMenu
              }}
            >
              <Item
                action={() => setShowDeleteStatusModal(true)}
                color={theme.palette.activeText.main}
                text="Excluir"
                icon={DeleteIcon}
              />
            </PopUpMenu>
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        show={showDeleteStatusModal}
        handleClose={() => setShowDeleteStatusModal(false)}
        message={'Tem certeza de que deseja excluir este comentário?'}
        handleConfirm={handleDeleteStatus}
        confirmButtonText="Excluir"
        titleText="Excluir"
        loadingConfirmButton={deletingStatus}
        titleIcon={
          <DeleteIcon style={{ fontSize: '32px', color: '#CB410B' }} />
        }
      />
    </>
  );
});

Status.displayName = 'Status';

Status.propTypes = {
  status: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    text: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    type: PropTypes.string,
    status_files: PropTypes.array.isRequired,
    answers: PropTypes.array.isRequired
  }).isRequired,
  isAnswer: PropTypes.bool,
  avatarSize: PropTypes.number,
  setOffset: PropTypes.func,
  statusID: PropTypes.number
};

Status.defaultProps = {
  isAnswer: false,
  avatarSize: 32,
  setOffset: () => {}
};
