export default {
  versionName: 'AAA',
  versionNumber: '0.0.1',
  name: 'Espiral Digital',
  client: 'espiral',
  websocketSecret: 'OUBtCXoayoc2uptN5tvuCCJvej',
  websocketKey: 'DEu3vO#gPJGMYT@US@YxWNJFAzLUy^BUZLAdu8Bxa6DFrLWR',
  appstore: null,
  playstore: null,
  googleAuthClientId:
    '590034191650-rmrfddb4s0bjpij8nd40p78ljo7midqj.apps.googleusercontent.com',
  googleAuthScopes: [
    'https://www.googleapis.com/auth/calendar.app.created'
  ].join(' '),
  helpCenterUrl: 'https://ajuda.redu.digital'
};
