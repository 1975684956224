import React from 'react';
import ReactLoading from 'react-loading';

import { useTheme } from '@material-ui/core/styles';

export default function Loading() {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5px'
      }}
    >
      <ReactLoading type="cylon" color={`${theme.palette.primary.main}`} />
    </div>
  );
}
