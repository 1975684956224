/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import DeleteConfirmModal from '~/components/molecules/DeleteConfirmModal';
import PopUpMenuRefactored from '~/components/molecules/PopUpMenuRefactored';
import PopUpMenuItem from '~/components/molecules/PopUpMenuRefactored/PopUpMenuItem';
import ShareContentLecture from '~/components/organisms/ShareContents/ShareContentLectures';
import i18n from '~/I18n';
import { remove as deleteLecture } from '~/services/Lecture';

import { useStyles } from './styles';
import { Modal, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import { useSnackbar } from 'notistack';
import EditLectureModal from 'src/pages/Space/Content/Lecture/EditLectureModal';

export default function LecturePopUp({
  lectureID,
  setLectures,
  isMirror,
  mirrorsCount,
  lectureName
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { enqueueSnackbar } = useSnackbar();

  const defaultTitle = i18n.t('DeleteMedia.title');
  const mirrorTitle = i18n.t('DeleteMirroredMedia.title');

  const defaultMessageDescription = () => {
    return (
      <>
        A {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{lectureName}</span>, será
        permanentemente excluída, incluindo todos os dados e arquivos. Todos os
        membros irão perder o acesso.
      </>
    );
  };

  const lectureWithMirrorsMessageDescription = () => {
    return (
      <>
        A {i18n.t('StructuresName.Lectures.SingularLower')}{' '}
        <span style={{ fontWeight: 'bold' }}>{lectureName} </span>
        possui {mirrorsCount} espelho(s) e será permanentemente excluída,
        incluindo todos os dados e arquivos dos seus espelhos. Todos os membros
        irão perder o acesso.
      </>
    );
  };

  const [openLectureSharing, setOpenLectureSharing] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateLectures = (updatedLecture) => {
    setLectures((prevLectures) => {
      const oldLecture = prevLectures.find((l) => l.id === updatedLecture.id);
      oldLecture.name = updatedLecture.name;
      return [...prevLectures];
    });
  };

  const renderComponent = ({ onClick }) => (
    <MoreVertIcon
      fontSize={'large'}
      style={{ color: 'white' }}
      onClick={onClick}
    />
  );

  const handleDeleteLecture = () => {
    if (isDeleting) return;

    setIsDeleting(true);
    deleteLecture(lectureID)
      .then(() => {
        setOpenDeleteModal(false);

        setLectures((lectures) =>
          lectures.filter((lecture) => lecture.id !== lectureID)
        );

        setIsDeleting(false);

        enqueueSnackbar(`Mídia excluída com sucesso!`, {
          variant: 'success'
        });
      })
      .catch(() => {
        setIsDeleting(false);

        enqueueSnackbar(`Erro ao excluir mídia.`, {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <PopUpMenuRefactored Component={renderComponent}>
        <PopUpMenuItem
          text={`Editar ${i18n.t('StructuresName.Lectures.SingularLower')}`}
          icon={EditIcon}
          action={() => setOpenEdit(true)}
        />

        <PopUpMenuItem
          text={`Compartilhar ${i18n.t(
            'StructuresName.Lectures.SingularLower'
          )}`}
          icon={ShareIcon}
          action={() => setOpenLectureSharing(true)}
        />

        <PopUpMenuItem
          text={i18n.t('Delete')}
          icon={DeleteIcon}
          action={() => setOpenDeleteModal(true)}
        />
      </PopUpMenuRefactored>

      {openLectureSharing && (
        <Modal
          open={openLectureSharing}
          onClose={() => setOpenLectureSharing(false)}
          className={classes.modal}
        >
          <ShareContentLecture lectureID={lectureID} />
        </Modal>
      )}

      {openEdit && (
        <EditLectureModal
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          lectureID={lectureID}
          updateLectures={updateLectures}
        />
      )}

      {openDeleteModal && (
        <DeleteConfirmModal
          show={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          handleConfirm={handleDeleteLecture}
          maxWidth={440}
          title={isMirror ? mirrorTitle : defaultTitle}
          loading={isDeleting}
          showButtons
          descriptionMessage={
            mirrorsCount > 0
              ? lectureWithMirrorsMessageDescription()
              : defaultMessageDescription()
          }
        />
      )}
    </>
  );
}
