import React from 'react'
import styledComponents from 'styled-components';

export default function LogoLabel({ label }) {
  const Label = styledComponents.label`
  margin-top: 35px;
  font-size: 2rem;
  color: #ffffff;
`;
  return <Label>{label}</Label>
}