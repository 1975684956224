import React, { useState } from 'react';

import ListOfAnswers from './ListOfAnswers';
import MembersReport from './MembersReport';
import Root from './Root';

export const ExerciseAdminReportSwap = ({ lecture }) => {
  const [showRoot, setShowRoot] = useState(true);
  const [showListOfAnswers, setShowListOfAnswers] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const openListOfAnswers = () => {
    setShowRoot(false);
    setShowTable(false);
    setShowListOfAnswers(true);
  };

  const openRoot = () => {
    setShowTable(false);
    setShowListOfAnswers(false);
    setShowRoot(true);
  };

  const openTable = () => {
    setShowTable(true);
    setShowRoot(false);
    setShowListOfAnswers(false);
  };

  return (
    <>
      {lecture && (
        <>
          {showRoot && (
            <Root
              lecture={lecture}
              openTable={openTable}
              openListOfAnswers={openListOfAnswers}
            />
          )}
          {showListOfAnswers && (
            <ListOfAnswers
              exerciseID={lecture.lectureable.id}
              openRoot={openRoot}
            />
          )}
          {showTable && <MembersReport lecture={lecture} openRoot={openRoot} />}
        </>
      )}
    </>
  );
};

export default ExerciseAdminReportSwap;
