import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    content: {
        flex: 1,
        display: 'flex',
        position: 'relative'
    },
    input: {
        backgroundColor: '#eaeaea',
        minHeight: props => props.height,
        borderRadius: props => props.height / 2,
        flex: 1,
        fontSize: 16,
        padding: '5px 15px'
    },
    emojiPickerButton: {
        alignSelf: 'flex-start', 
        paddingLeft: 10,
        paddingTop: 2
    },
    smileyIcon: {
        color: props => props.showPicker ? props.theme.palette.primary.main : props.theme.palette.textActiveElement.main,
        width: 25,
        height: 25,
        transition: "color .1s"
    },
    pickerModal: {
        position: 'absolute',
        right: -50,
        top: -440
    },
    searchIcon: {
        width: 25,
        height: 25,
        marginRight: 15,
        color: props => props.theme.palette.textActiveElement.main
    },
    closeButton: {
        width: 27,
        height: 27,
        "& svg": {
            width: 27,
            height: 27,
            color: props => props.theme.palette.textActiveElement.main
        }
    }
});