import React, { useEffect, useState } from 'react';

export default function ComponentAccessControlWrapper({ Component, accessFunction, modelID, ...rest }){

  const [authorized, setAuthorized] = useState(false)  
  const handleAuthorization         = () => setAuthorized(true)
  
  useEffect(() => {
    setAuthorized(false)
    accessFunction(modelID).then(handleAuthorization).catch(console.log)
  }, [modelID])

  return authorized && <Component { ...rest } />
}