import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box, useTheme, Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import ConfirmModal from '~/components/molecules/ConfirmModal';
import { Months } from '~/constants/months';
import { Years } from '~/constants/years';
import i18n from '~/I18n';
import api from '~/services/api';

import ReactHookFormSelect from '../ReactHookFormSelect';
import ReactHookFormTextField from '../ReactHookFormTextField';
import { useStyles } from './styles';

const questionMessage = 'Você tem certeza que deseja excluir esta experiência?';
const errorMessage =
  'Houve um problema na requisição, por favor tente novamente mais tarde.';

const FormProfessionalExperience = ({ fieldsExperience }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { profile } = useSelector((state) => state.user);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmButtons, setShowConfirmButtons] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [experience, setExperience] = useState(null);
  const [index, setIndex] = useState(null);
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'experiences',
    keyName: 'experienceId'
  });

  useEffect(() => {
    if (
      fields.length === 0 &&
      fieldsExperience &&
      fieldsExperience.length !== 0
    ) {
      fieldsExperience.map((e) => {
        append(e);
      });
    }
  }, [fieldsExperience]);

  function handleOpen(experience, index) {
    setExperience(experience);
    setIndex(index);
    setDialogMessage(questionMessage);
    setShowConfirmButtons(true);
    setShowConfirmDialog(true);
  }
  function handleClose() {
    setShowConfirmDialog(false);
    showConfirmButtons(false)
  }
  function handleConfirm() {
    if (experience.id !== undefined) {
      setLoadingDelete(true);
      api
        .delete(`/api/users/${profile?.id}/experience/${experience.id}`)
        .then(() => {
          setLoadingDelete(false);
          setShowConfirmButtons(false);
          remove(index);
        })
        .catch(() => {
          setLoadingDelete(false);
          setDialogMessage(errorMessage);
          setShowConfirmButtons(false);
        });
    } else {
      remove(index);
      setShowConfirmButtons(false);
      setShowConfirmDialog(false);
    }
  }

  useEffect(() => {
    if (showConfirmButtons !== true) {
      setShowConfirmButtons(false)
      setShowConfirmDialog(false)
    }
  }, [showConfirmButtons])


  return (
    <Box className={classes.column}>
      <Box className={`${classes.column} ${classes.mainForm}`}>
        {fields?.map((experience, index) => (
          <>
            <Box className={classes.deleteBox}>
              <button
                onClick={() => handleOpen(experience, index)}
                type="button"
              >
                <CloseIcon className={classes.deleteIcon} />
              </button>
            </Box>
            <ConfirmModal
              show={showConfirmDialog}
              text={dialogMessage}
              handleClose={handleClose}
              handleConfirm={handleConfirm}
              maxWidth={440}
              loading={loadingDelete}
              showButtons={showConfirmButtons}
            />
            <Box className={classes.row}>
              <Controller
                defaultValue={experience?.title}
                name={`experiences.${index}.title`}
                control={control}
                render={({ field }) => (
                  <ReactHookFormTextField
                    name={`experiences.${index}.title`}
                    defaultValue={experience?.title}
                    label={i18n.t('Role')}
                    {...field}
                  />
                )}
              />

              <Controller
                defaultValue={experience?.company}
                name={`experiences.${index}.company`}
                control={control}
                render={({ field }) => (
                  <ReactHookFormTextField
                    name={`experiences.${index}.company`}
                    defaultValue={experience?.company}
                    label={i18n.t('Company')}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box className={classes.row}>
              <Box className={classes.column}>
                <Text variant="label" color="gray">
                  {i18n.t('StartDate')}
                </Text>

                <Box className={classes.row}>
                  <ReactHookFormSelect
                    id={`experiences.${index}.start_date.month`}
                    name={`experiences.${index}.start_date.month`}
                    label={i18n.t('Month')}
                    control={control}
                    values={Months}
                    defaultValue={experience?.start_date?.month}
                    variant="outlined"
                    margin="normal"
                  />

                  <ReactHookFormSelect
                    id={`experiences.${index}.start_date.year`}
                    name={`experiences.${index}.start_date.year`}
                    label={i18n.t('Year')}
                    control={control}
                    values={Years}
                    defaultValue={experience?.start_date?.year}
                    variant="outlined"
                    margin="normal"
                  />
                </Box>
              </Box>

              <Box className={classes.column}>
                <Text variant="label" color="gray">
                  {i18n.t('EndDate')}
                </Text>

                <Box className={classes.row}>
                  <ReactHookFormSelect
                    id={`experiences.${index}.end_date.month`}
                    name={`experiences.${index}.end_date.month`}
                    label={i18n.t('Month')}
                    control={control}
                    values={Months}
                    defaultValue={experience?.end_date?.month}
                    variant="outlined"
                    margin="normal"
                  />

                  <ReactHookFormSelect
                    id={`experiences.${index}.end_date.year`}
                    name={`experiences.${index}.end_date.year`}
                    label={i18n.t('Year')}
                    control={control}
                    values={Years}
                    defaultValue={experience?.end_date?.year}
                    variant="outlined"
                    margin="normal"
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.row}>
              <Controller
                control={control}
                defaultValue={experience?.current}
                name={`experiences.${index}.current`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank style={{ fontSize: 20 }} />}
                      checkedIcon={<CheckboxIcon style={{ fontSize: 20 }} />}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />

                    <p style={{ fontSize: 13 }}>{i18n.t('CurrentlyWorking')}</p>
                  </Box>
                )}
              />
            </Box>

            <Box className={classes.row}>
              <Controller
                defaultValue={experience?.description}
                name={`experiences.${index}.description`}
                control={control}
                render={({ field }) => (
                  <ReactHookFormTextField
                    name={`experiences.${index}.description`}
                    label={i18n.t('Description')}
                    defaultValue={experience?.description}
                    {...field}
                  />
                )}
              />
            </Box>
          </>
        ))}
      </Box>
      <Box className={classes.buttonArea}>
        <ButtonSquare
          onClick={() => append({ description: '' })}
          backgroundColor={theme.palette.secondary.main}
          fontSize="14px"
          color={theme.palette.textAndBackground.main}
          letterSpacing="0px"
          width="164px"
          height="37px"
        >
          <Text variant="text" color="textAndBackground" font>
            {i18n.t('AddExperience')}
          </Text>
        </ButtonSquare>
      </Box>
    </Box>
  );
};

export default FormProfessionalExperience;

FormProfessionalExperience.propTypes = {
  fieldsExperience: PropTypes.array
};

FormProfessionalExperience.defaultProps = {
  fieldsExperience: []
};
