import * as yup from 'yup';

export const editCourseFormSchema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  initials: yup.string().nullable(),
  minimum_rate_for_completion: yup
    .string()
    .matches(
      /^([0-9]|10)(\.\d{1,3})?$/,
      'Formato inválido. Use um número entre 0 e 10, opcionalmente seguido por ".y" onde y é um valor entre 0 e 100'
    )
    .test(
      'validacao',
      'Valores inválidos. Certifique-se de que voce digitou um valor entre 0 e 10, opcionalmente seguido por ".y" onde y é um valor entre 0 e 100',
      function(value) {
        if (!value) return true;
        const [x, y] = value.split('.').map(Number);
        return x >= 0 && x <= 10 && ((y >= 0 && y <= 999) || !y);
      }
    ),
  description: yup.string().nullable()
});
