import { makeStyles } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import styled from 'styled-components';

export const useStyles = makeStyles({
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingBottom: 12
  },
  showAllButton: {
    height: '23px',
    alignItems: 'stretch',
    '&:hover': {
      borderBottom: '1px solid #fff'
    }
  }
});

export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 30px;
  > span {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const BadgesList = styled(GridList).attrs({
  cols: 4,
  cellHeight: 'auto'
})``;

export const BadgesItem = styled(GridListTile).attrs({
  cols: 1
})`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 2px;
  box-sizing: border-box;
  border-radius: 100%;
  box-shadow: '0px 3px 6px white';
  background: white;
  width: 50px !important;
  height: 50px !important;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
`;
