import api from '~/services/api';

export const submitExercise = async (exerciseID, exerciseBody) => {
  const { data } = await api.post(`api/exercises/${exerciseID}/results`, exerciseBody)

  return data
}

export const submissionForm = async (formID, lectureID) => {
  const { data } = await api.post(`api/forms/${formID}/lectures/${lectureID}/submissions`)

  return data
}

export const getSubmission = async (formID, lectureID) => {
  try {
    const { data } = await api.get(`api/forms/${formID}/lectures/${lectureID}/my_submission`)
    return data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
  }
}

export const submitForm = async (formBody) => {
  const { data } = await api.post(`api/new_choices`, formBody)

  return data
}

export const finalizeForm = async (submissionID) => {
  const { data } = await api.put(`api/submissions/${submissionID}/finalize`)

  return data
}

export const markAsDone = async lectureID => {
  const { data } = await api.put(`/api/lectures/${lectureID}/mark_as_done`)

  return data
}