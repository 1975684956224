import React from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import AuthTermsModal from '~/components/molecules/AuthTerms';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';

export default function Zoom({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { links } = lecture;
  const zoomLink =
    links && links.length && links[0] && links[0].href ? links[0].href : '#';

  return (
    <div className={classes.root}>
      <AuthTermsModal />
      <div className={classes.buttonArea}>
        <Text variant="mediumText" color="gray">
          Não consegue entrar na chamada? Clique no botão abaixo!
        </Text>
        <ButtonSquare
          backgroundColor={theme.palette.secondary.main}
          width={150}
          padding={5}
        >
          <a
            className={classes.buttonText}
            target="_blank"
            href={zoomLink}
            rel="noreferrer"
          >
            Abrir em nova aba
          </a>
        </ButtonSquare>
      </div>
      <iframe className={classes.meet} src={zoomLink} />
    </div>
  );
}
