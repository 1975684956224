import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileIcon from '@material-ui/icons/InsertDriveFile';

export default function DropIcon() {
  const addStyle = {
    fontSize: '24px', 
    marginBottom: '-10px', 
    backgroundColor: "#4b9cfd", 
    borderRadius: "50%", 
    zIndex: 1,
    padding: "2px",
    color: 'white'
  }

  const fileStyle = {
    fontSize: '42px', 
    marginLeft: '-22px', 
    zIndex: 0,
    color: '#4b9cfd'
  }
  return(
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <AddCircleIcon style={addStyle}/>
      <FileIcon style={fileStyle}/>
    </div>
  );
}
