import React from 'react';

import OwnerTag from '~/components/organisms/AudioPlayer/TopSection/OwnerTag';
import Volume from '~/components/organisms/AudioPlayer/TopSection/Volume';

import { Box } from '@material-ui/core';

export default function TopSection({
  ownerName,
  volume,
  setVolume,
  muted,
  setMuted
}) {
  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
      <OwnerTag ownerName={ownerName} />
      <Volume
        volume={volume}
        setVolume={setVolume}
        muted={muted}
        setMuted={setMuted}
      />
    </Box>
  );
}
