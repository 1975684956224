import api from '~/services/api';

const baseURL = '/api/mirroring';

export const create = async (id, payload, parentID) => {
  const response = await api.post(`${baseURL}/subjects/${parentID}/lectures`, {
    replicable_id: id,
    lecture: {
      ...payload
    }
  });
  return response;
};

export const unlink = async (id) => {
  await api.put(`${baseURL}/lectures/${id}/unlink`);
};

export default { create, unlink };
