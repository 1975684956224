import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  text: {
    margin: 0,
    fontSize: 14,
    color: 'white'
  }
});

export const warningStyle = {
  backgroundColor: '#faf0ce',
  padding: '2px',
  borderRadius: '50%'
};
