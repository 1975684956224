import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    gap: '4px'
  },
  labelText: {
    fontSize: '14px',
    color: theme.palette.activeText.main,
    lineHeight: '24px'
  },
  icon: {
    fontSize: '18px',
    color: theme.palette.activeText.main
  }
}));
