import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  lectureContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px',
    marginBottom: 12,
    borderRadius: '7px',
    height: 152,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  current: {
    cursor: 'auto',
    backgroundColor: theme.palette.accent.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.main
    }
  },
  inactive: {
    backgroundColor: '#B4BDD3',
    '&:hover': {
      backgroundColor: '#B4BDD3'
    }
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '26px'
  },
  doneIcon: {
    fontSize: '24px',
    color: '#fff'
  },
  notCanReadIcons: {
    fontSize: '24px',
    color: '#676D82'
  },
  itemName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: 0,
    fontSize: '16px',
    width: '100%',
    textAlign: 'start'
  }
}));
