import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  ReportProblemOutlined,
  CancelOutlined
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import i18n from '~/I18n';
import { Creators as SpacesCreators } from '~/store/ducks/spaces';

import { useStyles } from './styles';

const AlertsDialog = ({ proceed, handleProceed }) => {
  const { open, type, message } = useSelector((state) => state.spaces);
  const color =
    type === 'success' ? 'green' : type === 'alert' ? 'orange' : 'red';
  const theme = useTheme();
  const classes = useStyles({ color, theme });

  const { handleClose } = SpacesCreators;
  const dispatch = useDispatch();

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <Box className={classes.containerIcon}>
        {type === 'success' && <CheckCircleOutline className={classes.icon} />}
        {type === 'alert' && <ReportProblemOutlined className={classes.icon} />}
        {type === 'error' && <CancelOutlined className={classes.icon} />}
      </Box>
      <Box className={classes.containerTitle}>
        {type === 'success' && (
          <Typography className={classes.title}>{i18n.t('success')}</Typography>
        )}
        {type === 'error' && (
          <Typography className={classes.title}>{i18n.t('error')}!</Typography>
        )}
        {type === 'alert' && (
          <Typography className={classes.title}>{i18n.t('alert')}</Typography>
        )}
      </Box>
      <DialogContent className={classes.content}>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>
      <Box className={classes.containerActions}>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(handleClose());
            }}
            className={classes.closeButton}
            disabled={proceed}
          >
            {i18n.t('close')}
          </Button>
          {type === 'alert' && (
            <Button
              onClick={handleProceed}
              className={classes.confirmButton}
              disabled={proceed}
            >
              {i18n.t('continue')}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default memo(AlertsDialog);

AlertsDialog.propTypes = {
  proceed: PropTypes.bool,
  open: PropTypes.bool,
  handleProceed: PropTypes.func
};

AlertsDialog.defaultProps = {
  proceed: false,
  open: false,
  handleProceed: () => {}
};
