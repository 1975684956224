import * as yup from 'yup';

export const newStatusSchema = yup.object().shape({
  description: yup
    .string()
    .required('Por favor, adicione um texto para enviar o comentário')
    .when(['attachment', 'requestForHelp'], {
      is: (attachment, requestForHelp) => attachment || requestForHelp,
      then: yup
        .string()
        .required('Por favor, adicione um texto para enviar o comentário')
    })
    .when(['$request_attachment'], {
      is: (request_attachment) => request_attachment,
      then: yup
        .string()
        .test(
          'attachment-required',
          'Por favor, adicione um anexo. Esta ação é obrigatória.',
          function() {
            const { attachment } = this.parent;
            return attachment !== null;
          }
        )
    }),
  attachment: yup.mixed().nullable(),
  requestForHelp: yup.boolean().nullable()
});
