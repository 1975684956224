import { socket } from '~/socket';

export const lastMessageOn = (senderId, callback) => {
  socket.on(`last-message/${senderId}`, (message) => {
    callback(message);
  });
};
export const lastMessageEmit = (senderId, contactId) => {
  socket.emit(`last-message`, senderId, contactId);
};
export const lastMessageOff = (senderId) => {
  socket.on(`last-message/${senderId}`);
};

export const unreadMessageForContactOn = (senderId, contactId, callback) => {
  socket.on(
    `unread-message-for-contact/${senderId}-${contactId}`,
    (unreadMessagesCount) => {
      callback(unreadMessagesCount);
    }
  );
};
export const unreadMessageForContactEmit = (senderId, contactId) => {
  socket.emit(`unread-message-for-contact`, senderId, contactId);
};

export const unreadMessageForContactOff = (senderId) => {
  socket.off(`unread-message-for-contact/${senderId}`);
};

// Message from chat
export const messageFromChatOn = (senderId, callback) => {
  socket.on(`get-message-chat/${senderId}`, (messages) => {
    callback(messages);
  });
};

export const messageFromChatEmit = (senderId, contactId, offset) => {
  socket.emit(`get-message-chat`, senderId, contactId, offset);
};

export const messageFromChatOff = (senderId) => {
  socket.off(`get-message-chat/${senderId}`);
};

// send message
export const sendMessageEmit = (senderId, contact, message) => {
  socket.emit('send-message', {
    contact: contact,
    currentUser: { id: senderId },
    body: message
  });
};

export const messageFromChatContactOn = (senderId, contactId, callback) => {
  socket.on(`${senderId}-${contactId}`, (message) => {
    callback(message);
  });
};

export const messageFromChatContactOff = (senderId, contactId) => {
  socket.off(`${senderId}-${contactId}`);
};

// read a message
export const readMessageFromChatEmit = (messageId, contactId) => {
  socket.emit('read-a-message-from-chat', {
    messageId: messageId,
    senderId: contactId
  });
};

export const readMessageFromChatOn = (senderId, contactId, callback) => {
  socket.on(`${senderId}-${contactId}`, (message) => {
    callback(message);
  });
};

export const readMessageFromChatOff = (senderId, contactId) => {
  socket.off(`${senderId}-${contactId}`);
};

// unread message count
export const unreadMessageFromChatCountEmit = (senderId) => {
  socket.emit(`unread-messages-count`, senderId);
};

export const unreadMessageFromChatCountOn = (senderId, callback) => {
  socket.on(`unread-messages-count/${senderId}`, (number) => {
    callback(number);
  });
};

export const unreadMessageFromChatCountOff = (senderId, contactId) => {
  socket.off(`${senderId}-${contactId}`);
};
