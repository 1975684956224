import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const Content = styled.div`
  display: ${(props) => (props.hide ? 'flex' : 'none')};
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-itens: 'center';
  justify-content: flex-end;
`;

export const CountLeftCaracters = styled.span`
  color: #707070;
  font-size: 12px;
  padding-bottom: 5px;
`;

export const SendButton = styled.button`
  display: flex;
  color: #ffffff;
  font-size: 16px;
  margin-left: 1rem;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.secondary.main
      : props.theme.palette.primary.main};
  width: 80px;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  align-content: center;
`;

export const CancelButton = styled.button`
  display: flex;
  width: 80px;
  font-size: 16px;
  border-radius: 4px;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: ${(props) => props.theme.palette.secondary.main};
  border: ${(props) => `0.5px solid ${props.theme.palette.secondary.main}`};
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  border-radius: 4px;
  align-items: center;
`;

export const CheckboxLabel = styled(Typography)`
  font-size: 12px;
  color: #707070;
`;
