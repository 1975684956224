import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  root: {
    marginBottom: '30px'
  },
  divider: { 
    backgroundColor: `${theme.palette.primary.main}`, 
    marginBottom: '15px',
  },
  actions: {
    marginTop: '20px'
  }
}));