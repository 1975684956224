import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  styleTableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F7'
    }
  },
  bodySmallCell: {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.activeText.main
  },
  bodyMediumCell: {
    width: '250px',
    maxWidth: '250px',
    minWidth: '250px',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.activeText.main
  },
  actionButtonsBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '8px'
  },
  actionButton: {
    padding: '4px',
    minWidth: 0,
    borderRadius: '4px',
    color: theme.palette.activeText.main
  }
}));
