import React from 'react';
import * as S from './style';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import { dynamicTime, formatTime } from '~/utils/dates';

export const LectureReportCard = ({ LectureReportCardData }) => {
  const formatGrade = (LectureReportCardData) => {
    const format = LectureReportCardData / 1;
    return format.toFixed(2);
  };

  return (
    <S.Container>
      <S.CardsContainer>
        <Text style={{ color: 'black', fontSize: '15px' }}>
          {i18n.t('HowManyDidAlready')}
        </Text>
        <Text color={'secondary'} style={{ fontSize: '30px' }}>
          {LectureReportCardData?.results_count}
        </Text>
        <Text
          style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}
        >
          {i18n.t('People')}
        </Text>
        <Text style={{ color: 'black', fontSize: '15px' }}>
          {i18n.t('OverallGrade')}
        </Text>
        <Text color={'secondary'} style={{ fontSize: '30px' }}>
          {formatGrade(LectureReportCardData?.average_grade)}
        </Text>
        <Text style={{ color: 'black', fontSize: '15px' }}>
          {i18n.t('OverallTime')}
        </Text>
        <Text color={'secondary'} style={{ fontSize: '30px' }}>
          {formatTime(LectureReportCardData?.average_duration)}
        </Text>
        {dynamicTime(LectureReportCardData?.average_duration)}
      </S.CardsContainer>
    </S.Container>
  );
};

export default LectureReportCard;
