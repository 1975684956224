import React from 'react';
import Text  from '~/components/atoms/Text';

import { Button, Grid, useTheme } from '@material-ui/core';

export default function ActionButtons({ handleCancel, handleAddAlternative, editing }){
  const theme = useTheme()
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button style={{ textTransform: 'none', border: `1px solid ${theme.palette.primary.main}` }} 
                onClick={handleCancel} 
                width={120}
                height={25}
                >
          <Text color="primary" variant={"smallText"}> { editing ? "Cancelar Edição" : "Cancelar Alternativa" } </Text> 
        </Button>
      </Grid>
      <Grid item>
        <Button style={{ textTransform: 'none', backgroundColor: `${theme.palette.primary.main}` }}
                onClick={handleAddAlternative}
                width={120}
                height={25}
                >
          <Text color="white" variant={"smallText"}> { editing ? "Alterar Alternativa" : "Adicionar Alternativa" } </Text>
        </Button>
      </Grid>
    </Grid>
  )
}