import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function AvatarProfile({ size, onClick, disabled, children }) {
  return (
    <Container size={size} onClick={onClick} disabled={disabled}>
      {children}
    </Container>
  );
}

AvatarProfile.propTypes = {
  size: PropTypes.number,
  children: PropTypes.shape(),
  onClick: PropTypes.func,
};

AvatarProfile.defaultProps = {
  size: 90,
  children: null,
  onClick: null,
};
