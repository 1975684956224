import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  downloadButton: {
    width: '100%', 
    height: '100%', 
    backgroundColor: theme.palette.primary.main, 
    minWidth: 0,
    lineHeight: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main, 
    },
  },
}));
