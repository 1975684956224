import React, { useState } from 'react';

import i18n from '~/I18n';

import { submitExercise, markAsDone } from './fetch';
import FormAlert from './FormAlert';
import FormButton from './FormButton';
import Question from './Question';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export default function ExerciseForm({
  lecture,
  result,
  setResult,
  closeExerciseForm
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showAlert, setShowAlert] = useState(false);
  const [answers, setAnswers] = useState({});
  const startTime = new Date();

  const {
    lectureable: { id: exerciseID, questions },
    id: lectureID
  } = lecture;

  const answerQuestion = (question, alternative) => {
    setAnswers({ ...answers, [question]: alternative });
  };

  const formatAnswers = (answers) => {
    return Object.keys(answers).map((questionID) => ({
      question_id: Number(questionID),
      alternative_id: answers[questionID]
    }));
  };

  const handleSubmitExercise = () => {
    const currentTime = new Date();
    if (Object.keys(answers).length < questions.length) setShowAlert(true);
    else {
      setShowAlert(false);
      const answersFormatted = formatAnswers(answers);
      const body = {
        choices: { answers: answersFormatted },
        lecture_id: lectureID,
        start_time: startTime.toISOString(),
        finalize_time: currentTime.toISOString(),
        state: 'finalized'
      };
      submitExercise(exerciseID, body)
        .then((fetchedResult) => {
          setResult(fetchedResult);
          closeExerciseForm();
          markAsDone(lectureID);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <form
      className={classes.root}
      style={{
        paddingBottom: '50px',
        'paddingBottom(max-Width:600px': { paddingBottom: '50px' }
      }}
    >
      <Box className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <Question
            key={index}
            question={question}
            questionIndex={index}
            answerQuestion={answerQuestion}
            curAnswer={answers[question.id]}
            submitted={result ? true : false}
            result={result ? result.choices[index] : null}
          />
        ))}
      </Box>

      {showAlert && <FormAlert setShowAlert={setShowAlert} />}

      {result ? (
        <FormButton handleClick={closeExerciseForm} text={i18n.t('GoBack')} />
      ) : (
        <FormButton handleClick={handleSubmitExercise} text={i18n.t('Send')} />
      )}
    </form>
  );
}

ExerciseForm.propTypes = {
  lecture: PropTypes.shape(),
  result: PropTypes.shape(),
  setResult: PropTypes.func,
  closeExerciseForm: PropTypes.func
};

ExerciseForm.defaultProps = {
  lecture: {},
  result: {},
  setResult: async () => {},
  closeExerciseForm: async () => {}
};
