import { Box, Switch } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import DragDropFileInput from '~/components/atoms/DragDropFileInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import { TextPrimary } from '~/utils/forms';

const fileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];
export default function Presentation() {
  const { control } = useFormContext();

  return (
    <>
      <Field
        name="lectureable.presentation.file"
        control={control}
        Component={DragDropFileInput}
        permittedFileTypes={fileTypes}
        fileTypesDescription={
          <div>
            Arquivos apenas em formato .pdf e pptx <br />
            Tamanho máximo de arquivos: <strong>50 MB</strong>.
          </div>
        }
      />
      <Box style={{ marginTop: '20px' }}>
        <Field
          label={TextPrimary('Permitir download da mídia')}
          labelPlacement={'end'}
          control={control}
          name="lectureable.presentation.downloadable"
          size="small"
          Component={Switch}
        />
      </Box>
    </>
  );
}
