import { educationableTemplates, educationableTypes } from '~/constants/education';
import { experienceTemplate } from '~/constants/experience';

const validate = values => {
    const errors = {};

    // check educations errors
    if(values.educations && values.educations.length) {
        let allEducationsErrors = [];

        values.educations.forEach((education, index) => {
            let educationErrors = {};

            if(!education || !education.educationable_type || education.educationable_type === "placeholder") {
                educationErrors.educationable_type = "Você precisa preencher este campo!";
                allEducationsErrors[index] = educationErrors;
            }

            if(education && education.educationable_type && education.educationable_type !== "placeholder") {
                const eduType = educationableTypes.find(
                    ed => ed.name === education.educationable_type
                );

                if(eduType) {
                    educationErrors.educationable = {};
                    Object.keys(educationableTemplates[eduType.id]).forEach(key => {
                        if((!education.educationable || !education.educationable[key] || education.educationable[key] === "placeholder") && key !== 'description') {
                            if(!educationErrors.educationable) educationErrors.educationable = {};
                            educationErrors.educationable[key] = "Você precisa preencher este campo!";
                            allEducationsErrors[index] = educationErrors;
                        }
                    });
                }
            }
        });

        if(allEducationsErrors.length) errors.educations = allEducationsErrors;
    }

    // check experiences errors
    if(values.experiences && values.experiences.length) {
        let allExperiencesErrors = [];

        values.experiences.forEach((experience, index) => {
            let experienceErrors = {};

            Object.keys(experienceTemplate).forEach(key => {
                if(key === 'end_date' || key === 'start_date') {
                    if(!experience || !experience[key] || !experience[key].month || experience[key].month === "placeholder") {
                        if(!experience || (experience && key === 'end_date' && !experience.current) || (experience && key === 'start_date')) {
                            if(!experienceErrors[key]) experienceErrors[key] = {};
                            experienceErrors[key].month = "Você precisa preencher este campo!";
                            allExperiencesErrors[index] = experienceErrors;
                        }
                    }
                    if(!experience || !experience[key] || !experience[key].year || experience[key].year === "placeholder") {
                        if(!experience || (experience && key === 'end_date' && !experience.current) || (experience && key === 'start_date')) {
                            if(!experienceErrors[key]) experienceErrors[key] = {};
                            experienceErrors[key].year = "Você precisa preencher este campo!";
                            allExperiencesErrors[index] = experienceErrors;
                        }
                    }
                } else if(!experience || !experience[key]) {
                    if(key !== 'current' && key !== 'description') {
                        experienceErrors[key] = "Você precisa preencher este campo!";
                        allExperiencesErrors[index] = experienceErrors;
                    }
                }
            });
        });

        if(allExperiencesErrors.length) errors.experiences = allExperiencesErrors;
    }
    
    return errors;
}

const validateName = (name) => {
    const firstName = name === "" ? "" : name.substr(0,name.indexOf(' '));
    const lastName = name === "" ? "" : name.substr(name.indexOf(' ')+1);

    if(name === "") {
        return "Este campo precisa ser preenchido.";
    } 
    if(name.indexOf(' ') === -1 || lastName === "") {
        return "É necessário ter primeiro nome e sobrenome.";
    } 
    if(firstName === "") {
        return "O primeiro nome não pode começar nem terminar com um espaço.";
    }
    if(lastName[0] === " " || lastName[lastName.length-1] === "") {
        return "O sobrenome não pode começar nem terminar com um espaço.";
    } 
    if(firstName.length > 25) {
        return "O primeiro nome não deve ter mais que 25 caracteres";
    } 
    if(lastName.length > 25) {
        return "O sobrenome não deve ter mais que 25 caracteres";
    }

    return "";
}

const isLetter = (char) => {
    return (char >= 'a' && char <= 'z') || (char >= 'A' && char <= "Z");
}

const isNumber = (char) => {
    return (char >= '0' && char <= '9');
}

const validateLogin = (login) => {
    if(login === "") {
        return "Este campo precisa ser preenchido";
    }
    if(login.length < 5) {
        return "O login precisa ter pelo menos 5 caracteres";
    }
    if(login.length > 512) {
        return "O login não deve ter mais que 512 caracteres";
    }

    // check characters
    let hasLetter = false;

    for(let char of login) {
        if(!(isLetter(char) || isNumber(char) || char === '-' || char === '_')) {
            return "O login só deve conter letras, números, - ou _.";
        } 
        if(isLetter(char)) {
            hasLetter = true;
        }
    }

    if(!hasLetter) return "O login deve conter ao menos uma letra.";

    return "";
}

const validateNameAndLogin = (name, login) => {
    const validation =  {};

    const nameError = validateName(name);
    const loginError = validateLogin(login);

    if(nameError !== "") validation.nameError = nameError;
    if(loginError !== "") validation.loginError = loginError;

    return validation;
}

export { validate, validateNameAndLogin };