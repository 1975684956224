import { socket } from '~/socket';

export const getContactOn = (userId, callback) => {
  socket.on(`get-contact/${userId}`, (contact) => {
    callback(contact);
  });
};
export const getContactEmit = (userId, contactId) => {
  socket.emit(`get-contact`, userId, contactId);
};

export const getContactOff = (userId) => {
  socket.on(`get-contact/${userId}`);
};

// search
export const searchContactOn = (userId, callback) => {
  socket.on(`search-contact/${userId}`, (contact) => {
    callback(contact);
  });
};
export const searchContactEmit = (userId, q) => {
  socket.emit(`search-contact`, userId, q);
};

export const searchContactOff = (userId) => {
  socket.on(`search-contact/${userId}`);
};

// search
export const getLastMessageOn = (userId, contactId, callback) => {
  socket.on(`last-message/${userId}-${contactId}`, (message) => {
    callback(message);
  });
};

export const getLastMessageEmit = (userId, contactId) => {
  socket.emit(`last-message`, userId, contactId);
};

export const getLastMessageOff = (userId) => {
  socket.on(`last-message/${userId}`);
};
