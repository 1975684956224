import React from 'react'
import Main from '../Main'

export default function LiveStreamMiniCard({ size, ...rest }){
  return <Main  color="#ef6264" 
                backgroundColor="#fae0e0"
                size={size}
                text="Transmissão ao vivo"
                { ...rest }
                />
}