import React from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const heightRect = 15;

const Shimmer = () => {
  return (
    <Box>
      <Skeleton
        variant="rect"
        width="90%"
        height={heightRect}
        style={{ marginBottom: 5 }}
      />
      <Box display="flex" flexDirection="row" width={1}>
        <Box width={80}>
          <Skeleton variant="circle" width={60} height={60} />
        </Box>
        <Box display="flex" flexDirection="column" width={1}>
          <Skeleton
            variant="rect"
            height={heightRect}
            width="70%"
            style={{ marginBottom: 5 }}
          />
          <Skeleton
            variant="rect"
            height={10}
            width="20%"
            style={{ marginBottom: 5 }}
          />
          <Skeleton
            variant="rect"
            height={heightRect}
            width="80%"
            style={{ marginBottom: 5 }}
          />
          <Skeleton
            variant="rect"
            height={heightRect}
            width="80%"
            style={{ marginBottom: 5 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Shimmer;
