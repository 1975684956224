import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import i18n from '~/I18n';
import api from '~/services/api';
import history from '~/services/history';

import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
// import { Container } from './styles';

export default function Subject() {
  const [subject, setSubject] = useState(null);
  const [lectures, setLectures] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();

  async function hanleGetLectures() {
    try {
      const response = await api.get(`/api/subjects/${id}/lectures`);
      setLectures(response.data);
    } catch (error) {}
  }

  useEffect(() => {
    async function handleGetSubject() {
      try {
        const response = await api.get(`/api/subjects/${id}/enrollments`);
        setSubject(response.data);
      } catch (err) {}
    }
    handleGetSubject();
    hanleGetLectures();
  }, [id]);

  async function handleEditSubject() {
    const newSubjectEdit = {
      subject: {
        name: `Novo ${i18n.t('StructuresName.Subjects.SingularLower')} editado`,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit'
      }
    };
    try {
      const response = await api.put(`/api/subjects/${id}`, newSubjectEdit);
    } catch (error) {}
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleDeleteSubject() {
    api
      .delete(`/api/subjects/${id}`)
      .then(() => {
        history.goBack();
      })
      .catch((error) => {});
  }

  return (
    <div>
      {subject && <h1>{subject.name}</h1>}
      <Button onClick={handleEditSubject}>editar subject</Button>
      <Button onClick={handleClickOpen}>Remover</Button>
      <div>
        <h2>{`${i18n.t('StructuresName.Lectures.PluralLower')}`}</h2>
        <List>
          {lectures &&
            lectures.map((lecture) => (
              <ListItem
                key={lecture.id}
                button
                component={Link}
                to={`/dashboard/lecture/${lecture.id}`}
              >
                <ListItemText primary={lecture.name} />
              </ListItem>
            ))}
        </List>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remover {`${i18n.t('StructuresName.Subjects.SingularLower')}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Você está prestes a remover o ${i18n.t('StructuresName.Subjects.SingularLower')} ${subject &&
              subject.name}, deseja realmente remover este ${i18n.t('StructuresName.Subjects.SingularLower')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {i18n.t('Cancel')}
          </Button>
          <Button onClick={handleDeleteSubject} color="primary" autoFocus>
            {i18n.t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
