import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( theme => ({
  root: {
    color: 'red'
  },
  alternatives: {
    backgroundColor: '#CCC',
    padding: '15px',
    borderRadius: '5px',
  },
  divider: { 
    backgroundColor: `${theme.palette.primary.main}`, 
    marginBottom: '15px',
  },
  toggle: {
    cursor: 'pointer',
  }
}));