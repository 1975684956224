import React from 'react'
import Main from '../Main'

export default function ExerciseMiniCard({ size, ...rest }){
  return <Main  color="#328624" 
                backgroundColor="#cceed4"
                size={size}
                text="Exercício"
                { ...rest }
                />
}