import React from 'react';

import { ShimmerText } from './styles';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';

export default function PageLoading() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sidebar} />
      <Box className={classes.infos}>
        <Box className={classes.infosHeader}>
          <ShimmerText height={25} width="40%" />
          <ShimmerText height={50} width="60%" />
          <ShimmerText height={20} width="40%" />
        </Box>
      </Box>
    </>
  );
}
