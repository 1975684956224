import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled(({ color, size, disabled, ...otherProps }) => (
  <Button
    {...otherProps}
    disabled={disabled}
    style={{
      width: size,
      height: size,
      minWidth: size,
      borderRadius: size / 2,
      padding: 0
    }}
  />
))``;
