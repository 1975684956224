import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    borderBottom: '1px solid #ccc',
    marginBottom: 10,
    marginTop: 10,
    border: '0.5px solid #A8B1CE',
    borderRadius: '12px',
    padding: '5px'
  },
  imagePreview: {
    width: 64,
    height: 64,
    padding: 4,
    borderRadius: '4px',
    marginRight: '10px',
    objectFit: 'cover'
  },
  imageName: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#676D82'
  },
  imageSize: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A8B1CE'
  },
  iconButton: {
    width: '20px',
    height: '18px',
    color: '#A8B1CE'
  },
  cropIcon: {
    width: '20px',
    height: '18px'
  },
  deleteIcon: {
    width: '20px',
    height: '18px'
  }
});
