import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const LECTURE_URL = 'lectures/';

export const hasManageAccessToLecture = async (lectureID) =>
  await hasManageAccessTo(`${LECTURE_URL}${lectureID}`);
export const hasReadAccessToLecture = async (lectureID) =>
  await hasReadAccessTo(`${LECTURE_URL}${lectureID}`);
export const hasUpdateAccessToLecture = async (lectureID) =>
  await hasCustomAccessTo(`${LECTURE_URL}${lectureID}`, 'update');
export const hasDownloadAccessToLecture = async (lectureID) =>
  await hasCustomAccessTo(`${LECTURE_URL}${lectureID}`, 'download');
