import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '340px',
    minWidth: '340px',
    padding: '0 20px',
    height: '100vh',
    overflowY: 'auto',
    paddingBottom: 30,
    backgroundColor: (props) => props.color,
    '@media (max-width: 1630px)': {
      width: '260px',
      minWidth: '260px',
      padding: '0 20px',
      paddingBottom: 30
    },
    '@media (max-width: 1024px)': {
      width: '83px',
      minWidth: '83px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 0',
      paddingBottom: 30
    },

    '@media (max-width: 450px)': {
      display: 'none'
    },
  }
});
