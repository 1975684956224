import React from 'react';

import { Avatar, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import styled from 'styled-components';

import settings from '~settings';

export const useStyles = makeStyles((theme) => ({
  editAvatarHover: {
    '& :hover': {
      cursor: 'pointer'
    }
  },
  modal: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  modalContent: {
    padding: '24px',
    width: '41%',
    height: '87%',
    background: 'white',
    // borderBottomLeftRadius: '10px',
    // borderBottomRightRadius: '10px',
    // border: '3px solid #fff',
    borderRadius: 8,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      width: '90%',
      height: '100%'
    }
  },
  modalTitle: {
    color: theme.palette.primary.main,
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  cropperContainer: {
    width: '100%',
    height: '800px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressContainer: {
    marginBottom: '10px'
  },
  buttonsContainer: {
    width: '100%',
    height: '50px',
    // borderTop: '1px solid #4563f8',
    position: 'relative',
    bottom: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      marginTop: '10px'
    }
  },
  button: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '500'
  },
  cropIcon: {
    fontSize: '32px',
    marginRight: '10px'
  },
  closeIcon: {
    fontSize: '32px'
  }
}));

export const cropperStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  screen: {
    position: 'relative',
    top: 15,
    left: 0,
    right: 0,
    bottom: '40px',
    width: '100%',
    height: '80%',
    borderRadius: 20
  },
  controls: {
    position: 'relative',
    top: 20,
    bottom: 0,
    // transform: 'translateX(50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: 'red',
    width: '100%',
    height: '40px'
  },
  slider: {
    padding: '22px 0px',
    borderRadius: '20px'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
});

export const EditAvatar = styled(
  ({ color, size, profile, handleProfilePhotoChange, ...otherProps }) => (
    <Avatar {...otherProps} style={{ width: size, height: size }}>
      <img
        src={
          profile && profile.thumbnails && profile.thumbnails.length > 5
            ? `${settings.baseUrl}${profile.thumbnails[5].href}`
            : ''
        }
        alt={profile ? profile.first_name : ''}
        // Esse style é uma cópia do style (.MuiAvatar-img) do material Ui aplicado à src
        style={{
          color: 'transparent',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          textAlign: 'center',
          textIndent: '10000px',
          position: 'relative'
        }}
      />
      <InputLabel
        htmlFor="upload-photo"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(255, 255, 255, 0.4)',
          position: 'absolute',
          zIndex: 5
        }}
      >
        <input
          id="upload-photo"
          type="file"
          accept="image/x-png, image/jpeg, image/jpg"
          style={{
            display: 'none'
          }}
          onChange={(e) => handleProfilePhotoChange(e)}
          onClick={(e) => (e.target.value = null)}
        />
        <AddAPhotoIcon
          style={{
            width: 35,
            height: 35,
            position: 'absolute',
            color: 'white'
          }}
        />
      </InputLabel>
    </Avatar>
  )
)``;
