import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getFollowingRequest: ['userId', 'page'],
  getFollowingSuccess: ['following'],
  getFollowingFailure: [],
  putFollowingReset: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  following: [],
  page: 1,
  loading: false,
  noMoreStatuses: false
};

const getFollowingRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getFollowingSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.page += 1;

    if(!action.following || !action.following.length) draft.noMoreStatuses = true;
    else {
      const newFollowing = action.following.filter(item => item.type !== 'CompoundLog');
      draft.following = draft.following.concat(newFollowing);
    }
  });

const getFollowingFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
    draft.noMoreStatuses = true;
  });

const putFollowingReset = (state = INITIAL_STATE) => 
  produce(state, (draft) => {
    draft.following = [];
    draft.page = 1;
    draft.loading = false;
    draft.noMoreStatuses = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_FOLLOWING_REQUEST]: getFollowingRequest,
  [Types.GET_FOLLOWING_SUCCESS]: getFollowingSuccess,
  [Types.GET_FOLLOWING_FAILURE]: getFollowingFailure,
  [Types.PUT_FOLLOWING_RESET]: putFollowingReset
});
