import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function OwnerTag({ ownerName }) {
  const theme = useTheme();

  return (
    <Box style={{ display: 'flex' }}>
      <Typography
        variant={'h3'}
        style={{
          fontSize: '1.5rem',
          color: theme.palette.activeText.main
        }}
      >
        por &nbsp;
      </Typography>

      <Typography
        variant={'h3'}
        style={{
          color: theme.palette.accent.main,
          fontSize: '1.5rem'
        }}
      >
        {`${ownerName}`}
      </Typography>
    </Box>
  );
}
