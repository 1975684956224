import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    borderRadius: '5px',
    padding: '10px'
  },
  title: {
    marginBottom: '5px',
    fontSize: '20px',
    color: theme.palette.primary.main
  }
}));
