import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormButtons from './FormButtons';
import { useStyles } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

export default function GenericFormWrapper({
  defaultValues,
  resolver,
  onSubmit,
  submitButtonTitle,
  handleCancel,
  handleExclude,
  excludeTitle,
  isSubmitting,
  Form
}) {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: resolver ? yupResolver(resolver) : null
  });

  const handlePropagation = (event) => {
    if (typeof event.preventDefault === 'function') event.preventDefault();
    if (typeof event.stopPropagation === 'function') event.stopPropagation();

    methods.handleSubmit(onSubmit)(event);
  };

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handlePropagation} className={classes.container}>
        <Form />
        <FormButtons
          submitButtonTitle={submitButtonTitle}
          handleCancel={handleCancel}
          handleExclude={handleExclude}
          excludeTitle={excludeTitle}
          isSubmitting={isSubmitting}
          nextButtonType="submit"
        />
      </form>
    </FormProvider>
  );
}

GenericFormWrapper.propTypes = {
  onSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  submitButtonTitle: PropTypes.string
};

GenericFormWrapper.defaultProps = {
  onSubmit: null,
  handleCancel: null,
  submitButtonTitle: ''
};
