import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import InterestTag from '~/components/atoms/InterestTag';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import i18n from '~/I18n';

/* lembrar de ajeitar responsividade da tag no modo edit */

export default function Interests({ interests }) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {interests && interests.length
                ? (
                    <Box className={classes.interestsList}>
                        {interests.map((interest, index) => (
                            <InterestTag key={index} name={interest.name} />
                        ))}
                    </Box>
                ) : (
                    <>
                        <Box className={classes.noInterestsLg} >
                            <Text variant='largeText' color='gray'>
                                {i18n.t('NoInterestAreas')}
                            </Text>
                        </Box>
                        <Box className={classes.noInterestsSm}>
                            <Text variant='text' color='gray'>
                                {i18n.t('NoInterestAreas')}
                            </Text>
                        </Box>
                    </>
                )}
        </Box>
    );
}

Interests.propTypes = {
    interests: PropTypes.oneOf(
        PropTypes.shape(),
        PropTypes.array
    )
}

Interests.defaultProps = {
    interests: null
}