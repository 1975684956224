import React from 'react';

import ChatEmptyImg from '~/assets/images/chat-empty.png';
import settings from '~settings';

import * as S from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const WelcomeToChatMessage = ({ subtitle, width }) => {
  const classes = S.useStyles({ width });

  return (
    <Box className={classes.container}>
      <S.Image src={ChatEmptyImg} />
      <S.Title>
        Bem vindo(a) ao chat do <strong>{settings.name}</strong>
      </S.Title>
      <S.SubTitle>{subtitle}</S.SubTitle>
    </Box>
  );
};

WelcomeToChatMessage.propTypes = {
  subtitle: PropTypes.string,
  width: PropTypes.string
};

WelcomeToChatMessage.defaultProps = {
  subtitle: 'Inicie uma nova conversa.',
  width: '100%'
};

export default WelcomeToChatMessage;
