import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function BioCurriculum({ children, title }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Text variant="header" color="primary">
          {title}
        </Text>
      </Box>
      {children}
    </Box>
  );
}

BioCurriculum.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.array
  ]),
};

BioCurriculum.defaultProps = {
  title: '',
  children: null,
};
