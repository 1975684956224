import React, { useEffect, useState } from 'react';

import PaginatableSelect from '~/components-refactor/atoms/PaginatableSelect';
import { useReplicateContext } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext.js';
import { useReplication } from '~/hooks/useReplication';
import i18n from '~/I18n';

import EditSpaceForm from './EditSpaceForm';
import { useSnackbar } from 'notistack';

export default function SpaceStep() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    listService,
    replicationSteps,
    setReplicationSteps,
    mirrorType
  } = useReplicateContext();

  const [spaceListData, setSpaceListData] = useState([]);
  const [mirrorPayload, setMirrorPayload] = useState({});

  const { fetchStructureList, isFetching } = useReplication();

  const handleSuccessFetchList = (data) => {
    const spaceData = data.map((item) => ({
      ...item.space,
      value: item.space.id
    }));
    setSpaceListData((prev) => [...prev, ...spaceData]);
  };

  const handleFailureFetchList = () => {
    enqueueSnackbar(
      `Ocorreu um erro ao carregar os dados das ${i18n.t(
        `StructuresName.Space.PluralLower`
      )}`,
      {
        variant: 'error'
      }
    );
  };

  useEffect(() => {
    if (replicationSteps[2].key)
      fetchStructureList(
        replicationSteps[2].key,
        mirrorType === 'space' ? listService.getSelf : listService.getSpaces,
        handleSuccessFetchList,
        handleFailureFetchList
      );
  }, [replicationSteps[2].key]);

  const renderContent = () => {
    return (
      <>
        <PaginatableSelect
          label={
            mirrorType === 'space'
              ? `Qual ${i18n.t(
                  `StructuresName.Space.SingularLower`
                )} deseja espelhar?`
              : `Selecione de qual ${i18n.t(
                  `StructuresName.Space.SingularLower`
                )} você deseja espelhar`
          }
          placeholder={`Selecionar uma ${i18n.t(
            `StructuresName.Space.SingularUpper`
          )}`}
          curValue={replicationSteps[3].key}
          data={spaceListData}
          fetchMoreItems={() =>
            fetchStructureList(
              replicationSteps[2].key,
              mirrorType === 'space'
                ? listService.getSelf
                : listService.getSpaces,
              handleSuccessFetchList,
              handleFailureFetchList
            )
          }
          isFetching={isFetching}
          handleChange={(e) => {
            const space = spaceListData.find(
              (el) => e.target.value === el.value
            );
            setReplicationSteps((prev) => {
              let temp = prev;
              prev[3].key = space.value;
              return [...temp];
            });
            setMirrorPayload(space);
          }}
        />
        {replicationSteps[3].key && mirrorType === 'space' && (
          <EditSpaceForm mirrorPayload={mirrorPayload} />
        )}
      </>
    );
  };

  return renderContent();
}
