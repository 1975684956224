import base from './base';
const baseUrl = 'https://digital.estartec.net';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjvs7sskd';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  AppKey: 'Kpq4RUxOrQPWZ5fLy7DR5E4GirDaaUquHe9egxXtdzU',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://digital.estartec.net:44443',
  clarityKey
};
