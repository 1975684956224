import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import i18n from '~/I18n';

import * as S from './styles';
import { Box, Checkbox } from '@material-ui/core';

export default function Remember() {
  const classes = S.useStyles();
  const history = useHistory();

  const { setValue, watch } = useFormContext();

  const handleChange = (event) => setValue('remember', event.target.checked);

  return (
    <S.WrapperRemember>
      <Box className={classes.rememberContainer}>
        <S.RememberRecoverDiv>
          <S.RememberDiv>
            <Checkbox
              className={classes.checkbox}
              checked={watch('remember')}
              onChange={handleChange}
            />
            <S.Label>{i18n.t('Login.RememberMeCheck')}</S.Label>
          </S.RememberDiv>

          <S.RecoverPasswordButton
            type="button"
            onClick={() => history.push('/recover_password')}
          >
            {' '}
            {i18n.t('ForgotPassword')}{' '}
          </S.RecoverPasswordButton>
        </S.RememberRecoverDiv>
      </Box>
    </S.WrapperRemember>
  );
}
