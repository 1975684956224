export default function (values) {
  const errors = {};
  const requiredFields = ['email', 'captcharesponse'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
    if (
      field === 'email' &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field])
    ) {
      errors[field] = 'Email inválido';
    }
    if (field === 'captcharesponse' && !values[field]) {
      errors[field] = 'Recaptcha inválido';
    }
  });

  return errors;
}
