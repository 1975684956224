import api from '~/services/api';

const baseURL = '/api/environments';

const getSimplifiedSubjectsReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/simplified_report`, {
    responseType: 'blob'
  });
  return data;
};

const getPerformanceReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_performance_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getConclusionReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/conclusion_report`, {
    responseType: 'blob'
  });
  return data;
};

const getInformationListReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/information_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersRolesReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_roles_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUserFilterReport = async (structureID, usersID) => {
  const { data } = await api.get(
    `${baseURL}/${structureID}/user_filter_report`,
    {
      responseType: 'blob',
      params: { users: usersID }
    }
  );
  return data;
};

export {
  getSimplifiedSubjectsReport,
  getPerformanceReport,
  getUsersReport,
  getConclusionReport,
  getInformationListReport,
  getUsersRolesReport,
  getUserFilterReport
};
