import React, { useEffect, useState } from 'react';

import PaginatableSelect from '~/components-refactor/atoms/PaginatableSelect';
import { useReplicateContext } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext.js';
import { useReplication } from '~/hooks/useReplication';
import i18n from '~/I18n';

import EditSubjectForm from './EditSubjectForm';
import { useSnackbar } from 'notistack';

export default function SubjectStep() {
  const {
    replicationSteps,
    setReplicationSteps,
    listService,
    mirrorType
  } = useReplicateContext();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchStructureList, isFetching } = useReplication();
  const [subjectListData, setSubjectListData] = useState([]);
  const [mirrorPayload, setMirrorPayload] = useState({});

  const handleSuccessFetchList = (data) => {
    const spaceData = data.map((item) => ({
      ...item.subject,
      value: item.subject.id
    }));
    setSubjectListData((prev) => [...prev, ...spaceData]);
  };

  const handleFailureFetchList = () => {
    enqueueSnackbar(
      `Ocorreu um erro ao carregar os dados das ${i18n.t(
        `StructuresName.Space.PluralLower`
      )}`,
      {
        variant: 'error'
      }
    );
  };

  useEffect(() => {
    if (replicationSteps[3].key)
      fetchStructureList(
        replicationSteps[3].key,
        mirrorType === 'subject'
          ? listService.getSelf
          : listService.getSubjects,
        handleSuccessFetchList,
        handleFailureFetchList
      );
  }, [replicationSteps[3].key]);

  const renderContent = () => {
    return (
      <>
        <PaginatableSelect
          label={`Qual ${i18n.t(
            `StructuresName.Subjects.SingularLower`
          )} deseja espelhar?`}
          placeholder={`Selecionar um ${i18n.t(
            `StructuresName.Subjects.SingularLower`
          )}`}
          curValue={replicationSteps[4].key}
          data={subjectListData}
          fetchMoreItems={() =>
            fetchStructureList(
              replicationSteps[3].key,
              mirrorType === 'subject'
                ? listService.getSelf
                : listService.getSubjects,
              handleSuccessFetchList,
              handleFailureFetchList
            )
          }
          isFetching={isFetching}
          handleChange={(e) => {
            const subject = subjectListData.find(
              (el) => e.target.value === el.value
            );
            setReplicationSteps((prev) => {
              let temp = prev;
              prev[4].key = subject.value;
              return [...temp];
            });
            setMirrorPayload(subject);
          }}
        />
        {replicationSteps[4].key && mirrorType === 'subject' && (
          <EditSubjectForm mirrorPayload={mirrorPayload} />
        )}
      </>
    );
  };

  return renderContent();
}
