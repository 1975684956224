import React, { useState } from 'react';

import InputFieldAdornment from '~/components/atoms/InputFieldAdornment';
import { Capitaliza } from '~/utils/auxiliaryFunctions';

import * as S from './styles';
import { InputBase, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const InputField = ({
  type,
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  const classes = S.useStyles();
  const [values, setValues] = useState({
    showPassword: type === 'text' ? true : false
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        {label && <S.Label>{Capitaliza(label)}</S.Label>}
        <S.Gap />
        {touched && !!error && <S.Error>{error}</S.Error>}
      </Box>
      <InputBase
        className={classes.input}
        variant="outlined"
        type={values.showPassword ? 'text' : 'password'}
        {...input}
        {...custom}
        inputProps={{
          className: classes.inputField
        }}
        endAdornment={
          type == 'password' && (
            <InputFieldAdornment setValues={setValues} values={values} />
          )
        }
      />
    </Box>
  );
};

export default InputField;

InputField.propTypes = {
  input: PropTypes.instanceOf(InputBase),
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};
InputField.defaultProps = {
  type: '',
  input: {},
  label: '',
  meta: {
    touched: false,
    error: ''
  }
};
