import React from 'react';

import Avatar from '~/components/atoms/Avatar';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

export default function MessagesContact ({ profile }) {
  const classes = useStyles();
  console.log('----------- [ profile ] -----------');
  console.log(profile);
  console.log('----------- [ profile ] -----------');
  return (
    <Box className={classes.container}>
      <Avatar disabled size={50} profile={profile} />
      <Box className={classes.textArea}>
        {profile?.name ? (
          <Text
            fontWeight="bold"
            letterSpacing=".6px"
            variant="largeText"
            color="gray"
          >
            {profile?.name}
          </Text>
        ) : (
          <Skeleton variant="rectangular" width={210} height={18} />
        )}
        {profile?.contact_attributes?.login ? (
          <Text variant="text" color="gray">
            @{profile?.contact_attributes?.login}
          </Text>
        ) : (
          <Skeleton
            variant="rectangular"
            width={110}
            height={16}
            style={{ marginTop: '1px' }}
          />
        )}
      </Box>
    </Box>
  );
}

MessagesContact.propTypes = {
  profile: PropTypes.shape()
};

MessagesContact.defaultProps = {
  profile: {}
};
