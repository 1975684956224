import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

const NoContent = () => {

  return (
    <Text
      color="primary"
      variant="lg"
      style={{ letterSpacing: 1, fontWeight: 500 }}
    >
      {i18n.t('NoCoursesForThisEnvironment')}
    </Text>
  );
};

export default NoContent;
