import api from '~/services/api';

export const getSubject = async (subjectID) => {
  const { data } = await api.get(`/api/subjects/${subjectID}`);

  return data;
};

export const updateSubject = async (subjectID, body) => {
  return await api.put(`/api/subjects/${subjectID}`, body);
};

export const deleteSubject = async (subjectID) => {
  return await api.delete(`/api/subjects/${subjectID}`);
};
