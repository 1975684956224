import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  membersSection: {
    display: 'grid',
    width: '100%',
    gap: '16px',
    overflow: 'scroll',
    padding: '16px 5px 5px 5px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    [theme.breakpoints.only('md', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  }
}));

export const FeedbackMessage = ({ message, color }) => {
  return (
    <p
      style={{
        width: '100%',
        marginLeft: '16px',
        color: color,
        fontSize: '16px'
      }}
    >
      {message}
    </p>
  );
};

FeedbackMessage.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};
