import React from 'react';

import ShareContentSpace from '~/components/organisms/ShareContents/ShareContentSpaces';

import { useSpacePageContext } from '../contexts/SpacePageContext';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const SharingModal = ({ open, setOpen }) => {
  const {
    space: { id }
  } = useSpacePageContext();

  const classes = makeStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })();

  return (
    <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
      <div>
        <ShareContentSpace spaceID={id} />
      </div>
    </Modal>
  );
};

SharingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default SharingModal;
