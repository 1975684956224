export const experienceTemplate = {
    title: '',
    company: '',
    start_date: {
        month: '',
        year: '',
    },
    end_date: {
        month: '',
        year: ''
    },
    current: false,
    description: ''
}