import {
  hasCustomAccessTo,
  hasManageAccessTo,
  hasReadAccessTo
} from './accessControl';

const COURSE_URL = 'courses/';

export const hasManageAccessToCourse = async (courseID) =>
  await hasManageAccessTo(`${COURSE_URL}${courseID}`);
export const hasReadAccessToCourse = async (courseID) =>
  await hasReadAccessTo(`${COURSE_URL}${courseID}`);
export const hasUpdateAccessToCourse = async (courseID) =>
  await hasCustomAccessTo(`${COURSE_URL}${courseID}`, 'update');
