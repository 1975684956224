import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { LectureCardIcon } from '~/components/atoms/LectureCardIcon';

import { useStyles } from './styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

export const LectureCard = ({ lecture, lastLecture }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      {lecture && (
        <Link
          draggable={false}
          className={classes.root}
          to={'/dashboard/lecture/' + lecture.id}
          style={{
            backgroundColor: lastLecture
              ? '#676D82'
              : theme.palette.primary.main
          }}
        >
          <Box height={'19px'}>
            {lecture.user_completion && (
              <CheckIcon
                style={{
                  fontSize: '16px',
                  color: '#fff'
                }}
              />
            )}
          </Box>
          <Box className={classes.iconContent}>
            <LectureCardIcon lecture={lecture} iconSize={64} />
          </Box>
          <Typography className={classes.lectureName}>
            {lecture.name}
          </Typography>
        </Link>
      )}
    </>
  );
};

LectureCard.propTypes = {
  lecture: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    user_completion: PropTypes.bool
  }).isRequired,
  lastLecture: PropTypes.bool
};
