import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '3px',
  },
  mainArea: {
    height: '88px',
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    padding: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white'
  },
  interestsList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    height: '27px',
    fontSize: '14px',
    margin: '5px',
    padding: 0,
    flexGrow: 1,
    '&::placeholder': {
      color: theme.palette.textActiveElement.main,
      opacity: 0.75
    },
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  tag: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.textAndBackground.main,
    lineHeight: '27px',
    borderRadius: '5px',
    minHeight: '27px'
  },
  editTag: {
    display: 'inline-block',
    paddingLeft: '20px',
    margin: '5px',
    '&:hover button': {
      opacity: 1,
      marginRight: '12px'
    },
    '&:hover': {
      paddingLeft: '13px'
    }
  },
  tagName: {
    fontSize: '14px',
    textTransform: 'capitalize',
    '@media (max-width: 450px)': {
      fontSize: 12
    }
  },
  tagDelete: {
    margin: '0 5px',
    opacity: 0
  },
}));
