import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getCoursesByEnvironmentId: ['page', 'environmentId'],
  getCoursesByEnvironmentIdSuccess: ['courses'],
  getCoursesByEnvironmentIdFailure: [],
  postTransferUsers: [
    'courseSourceId',
    'courseDestinationId',
    'usersToTransfer'
  ],
  postTransferUsersSuccess: ['result'],
  postTransferUsersFailure: [],
  clearCourses: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  courses: [],
  page: 1,
  coursesRequestLoading: false,
  transferredUsers: [],
  usersNotTransferred: [],
  transferdUsersLoading: false
};

const getCoursesByEnvironmentId = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.coursesRequestLoading = true;
  });
};

const getCoursesByEnvironmentIdSuccess = (
  state = INITIAL_STATE,
  { courses }
) => {
  return produce(state, (draft) => {
    draft.coursesRequestLoading = false;
    draft.courses = courses;
  });
};

const getCoursesByEnvironmentIdFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.coursesRequestLoading = false;
  });

const postTransferUsers = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.transferdUsersLoading = true;
  });
};

const postTransferUsersSuccess = (state = INITIAL_STATE, { result }) => {
  return produce(state, (draft) => {
    draft.transferdUsersLoading = false;
    draft.transferredUsers = result.users_transferred;
    draft.usersNotTransferred = result.users_not_transferred;
  });
};

const postTransferUsersFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.transferdUsersLoading = false;
  });

const clearCourses = (state = INITIAL_STATE) => {
  return produce(state, (draft) => {
    draft.courses = [];
    draft.page = 1;
    draft.coursesRequestLoading = false;
    draft.transferredUsers = [];
    draft.usersNotTransferred = [];
  });
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_COURSES_BY_ENVIRONMENT_ID]: getCoursesByEnvironmentId,
  [Types.GET_COURSES_BY_ENVIRONMENT_ID_SUCCESS]: getCoursesByEnvironmentIdSuccess,
  [Types.GET_COURSES_BY_ENVIRONMENT_ID_FAILURE]: getCoursesByEnvironmentIdFailure,
  [Types.POST_TRANSFER_USERS]: postTransferUsers,
  [Types.POST_TRANSFER_USERS_SUCCESS]: postTransferUsersSuccess,
  [Types.POST_TRANSFER_USERS_FAILURE]: postTransferUsersFailure,
  [Types.CLEAR_COURSES]: clearCourses
});
