import React from 'react';
import { MdFolderCopy } from 'react-icons/md';

import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

export default function MirroredFlag({ type }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const text =
    type !== 'course' && type !== 'environment' ? 'ESPELHADA' : 'ESPELHADO';

  return (
    <Box className={classes.container} style={{ backgroundColor: '#fff' }}>
      <MdFolderCopy className={classes.icon} color="primary" />
      <Text
        color="primary"
        className={classes.text}
        style={{ fontWeight: 600 }}
      >
        {text}
      </Text>
    </Box>
  );
}
