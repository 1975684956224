import React from 'react';

import {
  ListItemText,
  MenuItem,
  Typography,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Item({ icon: Icon, text, action, color, children }) {
  const theme = useTheme();
  const defaultColor = theme.palette.activeText.main;
  const IconHandling = () => {
    if (!Icon) {
      return <div style={{ width: 20 }}></div>;
    }
    return <Icon style={{ fontSize: 20, color: color || defaultColor }} />;
  };

  return (
    <MenuItem onClick={action}>
      <IconHandling />
      <ListItemText style={{ marginLeft: 10 }}>
        <Typography style={{ fontSize: 14, color: color || defaultColor }}>
          {text}
        </Typography>
        {children}
      </ListItemText>
    </MenuItem>
  );
}

Item.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  color: PropTypes.string,
  children: PropTypes.node
};

Item.defaultProps = {
  icon: null,
  children: null
};
