import React from 'react'
import Main from '../Main'

export default function QuestionaryMiniCard({ size, ...rest }){
  return <Main  color="#1D3A85" 
                backgroundColor="#CBDCFD"
                size={size}
                text="Questionário"
                { ...rest }
                />
}