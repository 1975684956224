import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    largeList: {
        padding: 0,
        '@media (max-width: 450px)': {
            display: 'none'
        }
    },
    smallList: {
        display: 'none',
        '@media (max-width: 450px)': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, auto)'
        }
    },
    item: {
        padding: '0 0 8px 0',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        fill: theme.palette.activeText.main,
        opacity: 0.2,
        width: 12,
        height: 12,
        marginRight: 10
    },
    noLanguagesLg: {
        '@media (max-width: 450px)': {
            display: 'none'
        }
    },
    noLanguagesSm: {
        display: 'none',
        '@media (max-width: 450px)': {
            display: 'block'
        }
    }
}));