import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFrontHand } from 'react-icons/md';

import { DisplayAttachmentFile } from '../DisplayAttachmentFile';
import { useStyles } from './styles';
import { newStatusSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  InputBase,
  Typography,
  useTheme
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ErrorIcon from '@material-ui/icons/Error';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

const acceptedTypeFiles = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'doc',
  'docx',
  'txt',
  'xlsx'
];

const postSettings = {
  maxCaracteres: 1600,
  sendButtonText: 'Enviar',
  inputPlaceholder: 'Compartilhe suas ideias'
};
const answerSettings = {
  maxCaracteres: 800,
  sendButtonText: 'Responder',
  inputPlaceholder: 'Responder comentário'
};

export const StatusInput = ({
  request_attachment,
  submit,
  cancelButtonCallback,
  isAnswer
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const statusInputSettings = isAnswer ? answerSettings : postSettings;
  const uniqueIdInputFile = useMemo(() => uniqid(), []);
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(newStatusSchema),
    context: {
      request_attachment: request_attachment
    },
    defaultValues: {
      description: '',
      attachment: null,
      requestForHelp: false
    }
  });
  const { register, handleSubmit, watch, setValue, formState, reset } = methods;
  const { description, attachment, requestForHelp } = watch();

  const onSubmit = async (data) => {
    if (loading) return;
    try {
      setLoading(true);
      var formData = new FormData();
      if (!isAnswer) {
        const statusType = data.requestForHelp ? 'Help' : 'Activity';
        formData.append('status[type]', statusType);
      }
      if (data.attachment) formData.append('status[files][]', data.attachment);
      formData.append('status[text]', data.description);

      await submit(formData);
      reset();
      if (fileInputRef.current) fileInputRef.current.value = '';
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível processar sua solicitação, por favor tente novamente, se o erro persistir entre em contato com a equipe de suporte.',
        {
          variant: 'error'
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = () => {
    setValue('attachment', null, {
      shouldDirty: true
    });
    fileInputRef.current.value = '';
  };

  const handleCancel = () => {
    cancelButtonCallback();
    reset();
  };

  const isFileTypeValid = (file) => {
    if (
      acceptedTypeFiles.indexOf(
        file.name.split('.')[file.name?.split('.').length - 1]
      ) > -1
    ) {
      return true;
    } else {
      enqueueSnackbar('Formato de arquivo não permitido.', {
        variant: 'error'
      });
      return false;
    }
  };

  const isFileSizeValid = (file) => {
    if (file.size < 20000000) {
      return true;
    } else {
      enqueueSnackbar('O tamanho do arquivo não pode ser maior que 20 MB.', {
        variant: 'error'
      });
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <InputBase
        {...register('description')}
        maxRows={4}
        inputProps={{
          maxLength: statusInputSettings.maxCaracteres,
          className: classes.input
        }}
        fullWidth
        placeholder={statusInputSettings.inputPlaceholder}
        multiline
        className={classes.inputField}
      />
      {formState.errors.description?.message && (
        <FormHelperText className={classes.helperText}>
          <ErrorIcon className={classes.errorIcon} />
          {formState.errors.description.message}
        </FormHelperText>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {!isAnswer && (
            <>
              <input
                type="file"
                id={uniqueIdInputFile}
                accept=".png, .jpg, .jpeg, .gif, .pdf, .ppt, .pptx, .xls, .doc, .docx, .txt, .xlsx"
                {...register('attachment')}
                style={{ display: 'none' }}
                onChange={(event) => {
                  if (
                    isFileTypeValid(event.target.files[0]) &&
                    isFileSizeValid(event.target.files[0])
                  )
                    setValue('attachment', event.target.files[0], {
                      shouldDirty: true
                    });
                }}
                ref={fileInputRef}
              />
              <label htmlFor={uniqueIdInputFile}>
                <AttachmentIcon className={classes.inputFileIcon} />
              </label>
            </>
          )}
        </Box>
        <Typography
          style={{
            fontSize: '12px',
            color: theme.palette.activeText.main
          }}
        >{`${description.length}/${statusInputSettings.maxCaracteres} caracteres restantes`}</Typography>
      </Box>
      {attachment && (
        <DisplayAttachmentFile attachment={attachment} onDelete={deleteFile} />
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {!isAnswer && (
            <Checkbox
              {...register('requestForHelp')}
              checked={requestForHelp}
              icon={<MdFrontHand style={{ fontSize: '18px' }} />}
              checkedIcon={
                <MdFrontHand
                  style={{
                    fontSize: '18px',
                    color: theme.palette.primary.main
                  }}
                />
              }
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
          <Button
            className={classes.cancelButton}
            disableRipple
            disabled={!isAnswer && (!formState.isDirty || loading)}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disabled={!formState.isDirty || loading}
            className={classes.confirmButton}
            type="submit"
          >
            {loading ? (
              <CircularProgress size={24} color="grey" />
            ) : (
              statusInputSettings.sendButtonText
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

StatusInput.propTypes = {
  request_attachment: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  cancelButtonCallback: PropTypes.func,
  isAnswer: PropTypes.bool
};
StatusInput.defaultProps = {
  request_attachment: false,
  submit: async () => {},
  cancelButtonCallback: () => {},
  isAnswer: false
};
