import { makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import media from 'styled-media-query';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: (props) => props.width,
      '& strong': {
        fontWeight: '500',
        color: theme.palette.primary.main
      }
    }
  };
});

export const Image = styled.img`
  height: auto;
  width: 90%;
`;

export const Title = styled(Typography)`
  font-size: 2.2rem;
  ${media.lessThan('700px')`
    font-size: 2rem;
  `}
`;
export const SubTitle = styled(Typography)`
  font-size: 2rem;
  ${media.lessThan('700px')`
    font-size: 1.8rem;
  `}
`;
