import React, { useEffect, useState, useContext } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Show from '~/components/atoms/Show';
import Text from '~/components/atoms/Text';
import LaPlaceContext from '~/components/contexts/LaPlaceContext';
import i18n from '~/I18n';
import api from '~/services/api';

import { useStyles } from './styles';
import {
  Backdrop,
  Fade,
  FormControl,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PropTypes from 'prop-types';

function LaplaceSelectExerciseListModal({ open, handleClose }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { handleSucessImportLecture, subjectID } = useContext(LaPlaceContext);

  const [exerciseList, setExerciseList] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get('api/laplace/lists')
      .then(({ data }) => {
        setExerciseList(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  const handleImportSucess = () => {
    setExerciseList([]);
    setSelected(-1);
    handleSucessImportLecture();
    handleClose();
  };

  const secondaryText = (text) => (
    <Text color="gray" variant="lg" style={{ fontWeight: 400 }}>
      {text}
    </Text>
  );

  const handleImport = () => {
    const body = exerciseList[selected];
    api
      .post('api/laplace/exercises?subject_id=' + subjectID, body)
      .then(handleImportSucess)
      .catch(console.log);
  };

  function Buttons() {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonSquare onClick={handleClose} className={classes.button}>
          <Typography className={classes.cancelButton}>
            {i18n.t('Cancel')}
          </Typography>
        </ButtonSquare>
        <ButtonSquare
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={handleImport}
        >
          <Typography className={classes.importButton}>
            {i18n.t('SelectExerciseList.Import')}
          </Typography>
        </ButtonSquare>
      </div>
    );
  }

  function ExerciseList() {
    return (
      <FormControl component="fieldset" style={{ width: '100%', gap: '16px' }}>
        <RadioGroup
          aria-label="list"
          name="list"
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {exerciseList.map((list, index) => (
            <FormControlLabel
              key={list.id}
              value={index.toString()}
              control={<Radio color="primary" />}
              label={secondaryText(`${list.name} - ${list.model.name}`)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  function Skeleton() {
    return <span>{secondaryText('Carregando...')}</span>;
  }

  function Title() {
    return (
      <>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ImportExportIcon style={{ fontSize: '24px' }} />
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 500 }}
            >
              {i18n.t('SelectExerciseList.Title')}
            </Text>
          </div>
          <ButtonSquare onClick={handleClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </ButtonSquare>
        </div>
        {secondaryText(i18n.t('SelectExerciseList.Description'))}
      </>
    );
  }

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      // onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Title />
          <Show condition={!loading} fallback={<Skeleton />}>
            <ExerciseList />
          </Show>
          <Buttons />
        </Paper>
      </Fade>
    </Modal>
  );
}

LaplaceSelectExerciseListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default LaplaceSelectExerciseListModal;
