export const eventEducationRole = [
  { id: 'participant', name: 'Participante' },
  { id: 'speaker', name: 'Palestrante' },
  { id: 'organizer', name: 'Organizador' }
];

export const educationableTypes = [
  { id: 'HigherEducation', name: 'Ensino Superior' },
  { id: 'HighSchool', name: 'Ensino Médio' },
  { id: 'EventEducation', name: 'Evento' },
  { id: 'ComplementaryCourse', name: 'Curso Complementar' }
];

export const higherEducationKind = [
  { id: 'technical', name: 'Técnico' },
  { id: 'degree', name: 'Licenciatura' },
  { id: 'bachelorship', name: 'Bacharelado' },
  { id: 'pos_lato_sensu', name: 'Pós "Lato Sensu / MBA"' },
  { id: 'pos_stricto_sensu', name: 'Pós "Stricto Sensu"' },
  { id: 'doctorate', name: 'Doutorado' },
  { id: 'phd', name: 'PhD' }
];

export const educationableTemplates = {
  HigherEducation: {
    course: '',
    description: '',
    end_year: '',
    institution: '',
    kind: 'technical',
    start_year: '',
    research_area: ''
  },
  HighSchool: {
    institution: '',
    description: '',
    end_year: ''
  },
  EventEducation: {
    name: '',
    role: 'participant',
    year: ''
  },
  ComplementaryCourse: {
    course: '',
    description: '',
    institution: '',
    workload: '',
    year: ''
  }
};
