import api from '~/services/api';

const baseURL = '/api/subjects';

const getLecturesReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/lectures_report`, {
    responseType: 'blob'
  });
  return data;
};

export { getLecturesReport };
