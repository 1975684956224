import api from '~/services/api';

const baseURL = '/api/enrollment_links';

export const update = async (enrollmentLinkID, body) => {
  const { data } = await api.put(`${baseURL}/${enrollmentLinkID}`, {
    enrollment_link: {
      ...body
    }
  });

  return data;
};

export const remove = async (enrollmentLinkID) => {
  const { data } = await api.delete(`${baseURL}/${enrollmentLinkID}`);

  return data;
};

// Exported as an object containing both functions
export default {
  update,
  remove
};
