import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  educationContainer: {
    display: 'flex',
    marginBottom: '3rem'
  },
  educationInfoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  schoolIcon: {
    width: 53,
    height: 53,
    marginRight: 20,
    color: theme.palette.textActiveElement.main
  }
}));
