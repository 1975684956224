import React from 'react';

import { useTheme, CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';

export default function FormButtons({
  selectedTab,
  previousTab,
  tabAmount,
  nextTab,
  nextButtonType,
  isSubmitting,
  handleCancel,
  submitButtonTitle,
  handleExclude,
  excludeTitle,
  toSave
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const rightButtonClass = handleExclude
    ? classes.rightButtons
    : classes.centerRightButtons;

  if (isSubmitting) {
    return (
      <div className={classes.buttons} style={{ justifyContent: 'center' }}>
        <CircularProgress className={classes.loader} />
      </div>
    );
  }

  return (
    <div className={classes.buttons}>
      {handleExclude && (
        <ButtonSquare
          type="button"
          width={150}
          height={50}
          border={`1px solid #CB410B`}
          backgroundColor="#CB410B"
          onClick={handleExclude}
          className={classes.leftButton} // Novo estilo para alinhar à esquerda
        >
          <Text color="white" variant="largeText">
            {excludeTitle}
          </Text>
        </ButtonSquare>
      )}

      {selectedTab > 0 && (
        <ButtonSquare
          type="button"
          width={150}
          height={50}
          border={`1px solid ${theme.palette.secondary.main}`}
          backgroundColor="white"
          onClick={previousTab}
        >
          <ArrowBackIosIcon className={classes.arrowBack} />
          <Text color="secondary" variant="largeText">
            Voltar
          </Text>
        </ButtonSquare>
      )}
      <div className={rightButtonClass}>
        <ButtonSquare
          type="button"
          width={150}
          height={50}
          border={`1px solid ${theme.palette.secondary.main}`}
          backgroundColor="white"
          onClick={handleCancel}
        >
          <Text color="secondary" variant="largeText">
            Cancelar
          </Text>
        </ButtonSquare>
        <ButtonSquare
          type={nextButtonType}
          width={150}
          height={50}
          backgroundColor={theme.palette.secondary.main}
          onClick={nextButtonType === 'submit' ? null : nextTab}
          disabled={isSubmitting}
        >
          <>
            <Text color="white" variant="largeText">
              {submitButtonTitle}
            </Text>
            {/* <SaveIcon className={classes.submitIcon} /> */}
          </>
        </ButtonSquare>
      </div>
    </div>
  );
}

FormButtons.propTypes = {
  selectedTab: PropTypes.number,
  previousTab: PropTypes.func,
  tabAmount: PropTypes.number,
  nextTab: PropTypes.func,
  nextButtonType: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleCancel: PropTypes.func,
  submitButtonTitle: PropTypes.string,
  toSave: PropTypes.bool
};

FormButtons.defaultProps = {
  selectedTab: 0,
  previousTab: null,
  tabAmount: 0,
  nextTab: null,
  nextButtonType: 'submit',
  isSubmitting: false,
  handleCancel: null,
  submitButtonTitle: '',
  toSave: false
};
