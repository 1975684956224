import Img from '~/assets/images/woman-login.png';

import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  recaptcha: {
    marginTop: '2rem',
    transform: 'scale(1)',

    '@media (max-width: 1050px)': {
      transform: 'scale(0.9)'
    },

    '@media (max-width: 600px)': {
      transform: 'scale(1)'
    },

    '@media (max-width: 450px)': {
      transform: 'scale(0.9)'
    },

    '@media (max-width: 390px)': {
      transform: 'scale(0.8)'
    },

    '@media (max-width: 350px)': {
      transform: 'scale(0.7)'
    }
  },

  helpLoginContainer: {
    display: 'flex',
    alignItens: 'center',
    justifyContent: 'center'
  },

  helpLoginText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    marginTop: '100px',
    marginLeft: '50px',
    fontSize: '1.2rem',
    cursor: 'pointer'
  },

  alertLine: {
    position: 'absolute',
    top: -10,
    left: -50,
    width: 'calc(100% + 100px)',
    height: 2,
    backgroundColor: theme.palette.error.main
  },

  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    position: 'relative',
    color: theme.palette.error.main
  },

  span: {
    color: theme.palette.error.main,
    fontSize: '1.5em'
  },
  rememberContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%'
  },
  contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    // paddingLeft: '10rem',
    // paddingRight: '5rem',
    padding: '10rem',
    height: '100vh',
    backgroundImage:
      'linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0, 1))'
  },
  contentRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0px',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  },
  containerLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      paddingTop: '5px'
    }
  },
  containerTitleLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 3
  },
  titleLabel: {
    fontSize: '6rem',
    color: 'white',
    fontWeight: '500',
    marginTop: '45%'
  },
  descriptionContainer: {
    display: 'flex',
    marginTop: '2%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '1.8rem',
    zIndex: 3
  },
  descriptionText: {
    fontSize: '2.2rem',
    color: 'white',
    fontWeight: '500'
  },
  itens: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-7%',
    '@media (max-width: 600px)': {
      position: 'absolute',
      bottom: 0
    }
  },
  logoContainer: {},
  logo: {},
  logoLabel: {}
}));

export const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  background-color: red;
`;

export const LogoLabel = styled.label`
  margin-top: 35px;
  font-size: 2rem;
  color: #ffffff;
`;

export const Image = styled.img.attrs({
  src: Img
})`
  width: 68%;
  height: auto;
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 2rem;
  background-color: #449dfc;
  border: 0;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.6rem;
`;

export const RecoverPasswordButton = styled.button`
  background-color: Transparent;
  border: 0;
  font-size: 1.2rem;
  color: #fff;
  opacity: 1;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60rem;
`;

export const RememberRecoverDiv = styled.div`
  display: flex;
  flex: 1;
  margin-top: 2rem;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

export const RememberDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 1.2rem;
  color: #ffffff;
  margin-left: 1rem;
`;

export const Checkbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  width: 1.7rem;
  height: 1.7rem;
  opacity: 1;
`;
export const TextInput = styled.input.attrs(() => ({
  type: 'text'
}))`
  opacity: 1;
`;

export const LeftImageBackground = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
