import React from 'react';

import Main from '../Main';
import i18n from '~/I18n';

export default function CloneMiniCard({ size, ...rest }) {
  return (
    <Main
      color="#4e1460"
      backgroundColor="#bd94cc"
      size={size}
      text={`Copiar ${i18n.t('StructuresName.Lectures.SingularLower')} existente`}
      {...rest}
    />
  );
}
