import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { editSpaceFormSchema } from '~/components-refactor/molecules/ReplicatedSteps/SpaceStep/EditSpaceForm/validation';
import { CreateReplicatedStructure } from '~/components-refactor/organisms/CreateReplicatedStructure';
import { ReplicatedStructureProvider } from '~/components-refactor/organisms/CreateReplicatedStructure/ReplicateStructureContext';
import i18n from '~/I18n';
import spacesListService from '~/services/mirroring/mirroring_list/spaces';
import spaceMirrorService from '~/services/mirroring/space';

export default function ReplicateSpace() {
  const { courseID } = useParams();

  console.log(courseID);

  return (
    <ReplicatedStructureProvider
      parentID={courseID}
      mirrorType="space"
      listService={spacesListService}
      mirrorService={spaceMirrorService}
    >
      <CreateReplicatedStructure
        structureName={i18n.t(`StructuresName.Space.SingularUpper`)}
        stepsCount={5}
        resolver={editSpaceFormSchema}
      />
    </ReplicatedStructureProvider>
  );
}
