import React from 'react';

import anchorme from 'anchorme';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const FormattedText = ({ text }) => {
  return (
    <span>
      {parse(
        anchorme({
          input: text,
          options: {
            attributes: {
              target: '_blank'
            }
          }
        })
      )}
    </span>
  );
};

FormattedText.propTypes = {
  text: PropTypes.string
};

export default FormattedText;
