import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import Text from '~/components/atoms/Text';
import GoogleMeet from '~/pages/Lecture/VideoLecture/GoogleMeet';
import settings from '~settings';

import { ReactComponent as HourglassIcon } from '../../../assets/icons/hourglass.svg';
import AudioLecture from '../AudioLecture';
import { useStyles } from './styles';
import VDONinja from './VDONinja';
import Zoom from './Zoom';
import { Box, useTheme } from '@material-ui/core';
import Plyr from 'plyr';
import PropTypes from 'prop-types';

function VideoLecture({ lecture }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const {
    resource_type: resourceType,
    custom_player: customPlayer,
    stream_key: streamKey,
    stream_ended: streamEnded
  } = lecture.lectureable || {};

  const [errorLoadingVideo, setErrorLoadingVideo] = useState(false);

  // mock variable
  const isLivestreamOwner = !!streamKey;

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Plyr('#player');
  });

  const videoLink = lecture?.lectureable?.links[0]?.href;

  if (!videoLink || errorLoadingVideo) {
    return (
      <Box
        width="100%"
        height="600px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <HourglassIcon fill={theme.palette.primary.main} />
        <Text
          variant="largeText"
          color="gray"
          textAlign="center"
          style={{
            maxWidth: '800px',
            marginTop: '64px'
          }}
        >
          O vídeo está sendo preparado para você. Por favor, aguarde!
        </Text>
        <Text
          variant="largeText"
          color="gray"
          textAlign="center"
          style={{
            maxWidth: '800px'
          }}
        >
          Se o problema persistir por mais de 2 horas, entre em contato com
          nossa <Link to="/help">equipe de suporte.</Link>
        </Text>
      </Box>
    );
  }

  const LiveInfo = () =>
    resourceType === 'youtube_livestream' &&
    isLivestreamOwner &&
    !streamEnded && (
      <div className={classes.streamInfo}>
        <div className={classes.liveText}>
          <Text variant="largeText" color="gray">
            Sua chave da stream: {streamKey}
          </Text>
        </div>
        <div className={classes.liveText}>
          <Text variant="mediumText" color="gray">
            Utilize essa chave para iniciar a transmissão no seu programa de
            streaming. Recomendamos o software gratuito{' '}
            <a
              target="_blank"
              href="https://obsproject.com/"
              className={classes.link}
              rel="noreferrer"
            >
              OBS
            </a>
            .
          </Text>
        </div>
        <div className={classes.liveText}>
          <a
            className={classes.link}
            target="_blank"
            href={`${settings.baseUrl}/static/Tutorial_Livestream_Redu_OBS.pdf`}
            rel="noreferrer"
          >
            Clique aqui para ver as instruções de como realizar sua primeira
            transmissão.
          </a>
        </div>
      </div>
    );

  const CustomPlayer = ({ provider }) => {
    return (
      <Box className={classes.plyr}>
        <div
          id="player"
          data-plyr-provider={provider}
          data-plyr-embed-id={videoLink}
        />
        <LiveInfo />
      </Box>
    );
  };

  const RegularVideo = () => (
    <div>
      <ReactPlayer
        url={videoLink}
        controls
        width="100%"
        className={classes.player}
        onError={() => setErrorLoadingVideo(true)}
      />
      <LiveInfo />
    </div>
  );

  const VideoComponent = () => {
    if (
      customPlayer &&
      (resourceType === 'youtube' || resourceType === 'youtube_livestream')
    )
      return <CustomPlayer provider={'youtube'} />;

    if (resourceType === 'call') return <VDONinja lecture={lecture} />;
    if (resourceType === 'zoom') return <Zoom lecture={lecture} />;
    if (resourceType === 'google_meet')
      return (
        <GoogleMeet
          event={lecture?.lectureable?.event}
          expired={
            lecture.lectureable?.meet_event_expired ||
            lecture.lectureable?.event?.expired
          }
        />
      );

    if (resourceType === 'audio') return <AudioLecture lecture={lecture} />;
    return <RegularVideo />;
  };

  return <VideoComponent />;
}

VideoLecture.propTypes = {
  lecture: PropTypes.shape(),
  provider: PropTypes.string
};

VideoLecture.defaultProps = {
  lecture: null,
  provider: ''
};

export default React.memo(VideoLecture);
