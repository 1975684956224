import api from '~/services/api';
import settings from '~settings';

import axios from 'axios';

const SebraeKey = settings.sebraeKey;

const ReduSebrae = axios.create({
  baseURL: api.defaults.baseURL + '/api/sebrae',
  headers: { ...api.defaults.headers, SebraeKey }
});

ReduSebrae.interceptors.request.use(async (config) => {
  return config;
});

export default ReduSebrae;
