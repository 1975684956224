import api from '~/services/api';

export const submitExercise = async (exerciseID, exerciseBody) => {
  const { data } = await api.post(`api/exercises/${exerciseID}/results`, exerciseBody)

  return data
}

export const markAsDone = async lectureID => {
  const { data } = await api.put(`/api/lectures/${lectureID}/mark_as_done`)

  return data
}