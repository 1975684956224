import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    flexDirection: 'column',
    textAlign: 'center',
    fontStyle: 'normal',
    gap: 18
  },
  largeText: {
    color: theme?.palette.primary.main,
    fontSize: 24,
    lineHeight: '32px'
  },
  text: {
    color: '#676D82',
    fontSize: 14,
    lineHeight: '16px'
  },
  link: {
    color: theme?.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
    cursor: 'pointer'
  }
}));
