import api from '~/services/api';

const baseURL = 'api/spaces';

const getSimplifiedSubjectsReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/simplified_report`, {
    responseType: 'blob'
  });
  return data;
};

const getExerciseAverageReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/average_grades_report`, {
    responseType: 'blob'
  });
  return data;
};

const getExerciseGradesCountReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/grades_report`, {
    responseType: 'blob'
  });
  return data;
};

const getFormConclusionReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/form_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersListReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/users_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getInformationListReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/information_list_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUsersRegisteredReport = async (ID) => {
  const { data } = await api.get(`${baseURL}/${ID}/user_registered_report`, {
    responseType: 'blob'
  });
  return data;
};

const getUserFilterReport = async (structureID, usersID) => {
  const { data } = await api.get(
    `${baseURL}/${structureID}/user_filter_report`,
    {
      responseType: 'blob',
      params: { users: usersID }
    }
  );
  return data;
};

export {
  getExerciseAverageReport,
  getExerciseGradesCountReport,
  getFormConclusionReport,
  getSimplifiedSubjectsReport,
  getUsersListReport,
  getInformationListReport,
  getUsersRegisteredReport,
  getUserFilterReport
};
