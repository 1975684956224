import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  content: {
    padding: 0,
    width: 550,
    '@media (max-width: 800px)': {
      width: 350,
    },
    '@media (max-width: 600px)': {
      width: 250,
    },
  }
});
