import React from 'react';

import DownloadReportButton from '~/components/molecules/DownloadReportButton';
import { getUserPerformanceReport } from '~/services/reports/courses';

import { useStyles } from './styles';
import PropTypes from 'prop-types';

export default function UserPerformanceReport({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadReportButton
        getReport={getUserPerformanceReport}
        baseName="relatório_de_desempenho_dos_alunos_nos_exercícios_"
        id={id}
      />
    </div>
  );
}

UserPerformanceReport.propTypes = {
  id: PropTypes.string
};

UserPerformanceReport.defaultProps = {
  id: ''
};
