import React from 'react'
import Main from '../Main'

export default function DocumentMiniCard({ size, ...rest }){
  return <Main  color="#3143e4" 
                backgroundColor="#e0e3ff"
                size={size}
                text="Documento"
                { ...rest }
                />
}