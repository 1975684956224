import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  option: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    textAlign: 'left'
  },
  input: {
    fontSize: '18px'
  }
}));
