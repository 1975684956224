import React, { useEffect, useState } from 'react';

import i18n from '~/I18n';

import { submissionForm, submitForm, finalizeForm, markAsDone } from './fetch';
import FormAlert from './FormAlert';
import FormButton from './FormButton';
import Question from './Question';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import { Box } from '@material-ui/core';

export default function ExerciseForm({
  lecture,
  result,
  setResult,
  closeExerciseForm
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showAlert, setShowAlert] = useState(false);
  const [answers, setAnswers] = useState({});
  const [submissionID, setSubmissionID] = useState(-1);

  const { type, lectureable_id: exerciseID, id: lectureID } = lecture;

  const questions = lecture.lectureable.any_choice_questions;

  const answerQuestion = (question, alternative) => {
    setAnswers({ ...answers, [question]: alternative });
  };

  useEffect(() => {
    if (result) {
      setSubmissionID(result.id);
    } else {
      submissionForm(lecture.lectureable.id, lecture.id).then((submission) =>
        setSubmissionID(submission.id)
      );
    }
  }, [result]);

  const formatAnswers = (answers) => {
    return Object.keys(answers).map((questionID) => {
      return {
        new_question_id: Number(questionID),
        option_id: answers[questionID],
        submission_id: submissionID
      };
    });
  };

  const handleSubmitExercise = () => {
    if (Object.keys(answers).length < questions.length) setShowAlert(true);
    else {
      setShowAlert(false);
      const answersFormatted = formatAnswers(answers);
      const body = {
        user: {
          new_choices_attributes: answersFormatted
        }
      };
      submitForm(body)
        .then(() => {
          finalizeForm(submissionID).then((submission) => {
            setResult(submission);
            markAsDone(lectureID);
            closeExerciseForm();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form
      className={classes.root}
      style={{
        paddingBottom: '50px',
        'paddingBottom(max-Width:600px': { paddingBottom: '50px' }
      }}
    >
      <Box className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <Question
            key={index}
            question={question}
            questionIndex={index}
            answerQuestion={answerQuestion}
            curAnswer={answers[question.id]}
            submitted={result && result.state === 'finalized' ? true : false}
            result={result ? result.new_choices[index] : null}
          />
        ))}
      </Box>

      {showAlert && <FormAlert setShowAlert={setShowAlert} />}

      {submissionID !== -1 && result?.state === 'finalized' ? (
        <FormButton handleClick={closeExerciseForm} text={i18n.t('GoBack')} />
      ) : (
        <FormButton handleClick={handleSubmitExercise} text={i18n.t('Send')} />
      )}
    </form>
  );
}
