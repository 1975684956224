import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    height: '100vh',
    minHeight: '100vh',
    width: '100vw',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },

    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },

    '& ::-webkit-scrollbar-thumb': {
      background: (props) => props.theme.palette.secondary.main,
      bordeRadius: '2px'
    }
  },
  content: {
    display: 'flex',
    width: '100vw',
    flexDirection: 'column',
    overflowY: 'hidden',
    flexGrow: 1,
    paddingTop: 90
  }
});
