import React from 'react';
import { useForm } from 'react-hook-form';

import { sendMessageEmit } from '~/socket/message-events';

import { useStyles } from './styles';
import { Box, useTheme, IconButton, Input } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';

export const ConversationMessagesFooter = ({ senderId, contact }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { register, handleSubmit, resetField } = useForm();

  const onSubmit = ({ message }) => {
    sendMessageEmit(senderId, contact, message);
    resetField('message');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.wrapper}>
        <Input
          placeholder="Aa"
          className={classes.input}
          {...register('message', { required: true })}
          disableUnderline
          inputProps={{
            className: classes.inputProps
          }}
        />
        <IconButton
          type="submit"
          aria-label="enviar"
          className={classes.iconButton}
        >
          <SendIcon className={classes.sendIcon} />
        </IconButton>
      </Box>
    </form>
  );
};

ConversationMessagesFooter.propTypes = {
  senderId: PropTypes.number,
  contact: PropTypes.any
};

ConversationMessagesFooter.defaultProps = {
  senderId: null,
  contact: null
};
