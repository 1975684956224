import React, { useState } from 'react';
import Text                from '~/components/atoms/Text'
import LectureDependency   from './LectureDependency';
import Scheduleable        from './Scheduleable'

import { Collapse, Grid }         from '@material-ui/core'
import { IconWrapper }            from '~/utils/forms';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { useStyles } from './styles'

export default function AdvancedOptions() {
  const classes = useStyles()
  const [show, setShow] = useState(false);
  const textOptions = show ? 'Ocultar opções avançadas' : 'Mostrar opções avançadas';

  const handleClick = () =>{
    setShow(show => !show)
  }

  const renderIcon = () => {
    return show ? 
    <IconWrapper customColor="#767677" fontSize={'25px'} Icon={ExpandLess}/> 
    : 
    <IconWrapper customColor="#767677" fontSize={'25px'} Icon={ExpandMore}/>
  }

  return (
    <div>

      <div className={`${classes.field} ${classes.click}`}>
        <Grid container alignItems='center' onClick={handleClick}>
          
          <Grid item> {renderIcon()} </Grid>
          
          <Grid item> <Text className={classes.hideText}>{textOptions}</Text> </Grid>
        
        </Grid>
      </div>
      
      <Collapse in={show}>
        <div className={classes.field}>
          <Scheduleable/>
        </div>
        <div className={classes.field}>
          <LectureDependency/>
        </div>
      </Collapse>

    </div>
  )
}
