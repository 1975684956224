import React from 'react';
import { MdFolderCopy } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Text from '~/components/atoms/Text';
// import CreateReplica from '~/components/organisms/CreateMirroredEnvironment/CreateReplica';
import PopUpMenuRefactored from '~/components/molecules/PopUpMenuRefactored';
import PopUpMenuItem from '~/components/molecules/PopUpMenuRefactored/PopUpMenuItem';
import history from '~/services/history';

import { Box, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';

export default React.forwardRef(function StructureCreationPopUp(
  { text, structureName, redirectLink, structure, forwardedRef },
  ref
) {
  const theme = useTheme();
  const { id, courseId } = useParams();
  const location = useLocation();

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const setMirroredPath = () => {
    if (id && location.pathname.includes('/spaces'))
      return `/dashboard/spaces/${id}/new_mirror_subject`;
    else if (courseId) return `/dashboard/courses/${courseId}/new_mirror_space`;
    else if (id) return `/dashboard/environments/${id}/new_mirror_course`;
    else return '/dashboard/environments/new_mirror_environment';
  };

  const path = setMirroredPath();

  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
  };

  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left'
  };

  const render = ({ onClick }) => (
    <Box
      display="flex"
      alignItems="center"
      style={{
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={onClick}
    >
      <Text
        variant="text"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '5px',
          color: theme.palette.activeText.main
        }}
      >
        {text}
      </Text>
      <ArrowRightRoundedIcon
        style={{ fontSize: 25, color: theme.palette.activeText.main }}
      />
    </Box>
  );

  return (
    <>
      <Box onClick={handleClick}>
        <PopUpMenuRefactored
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          forwardRef={forwardedRef}
          Component={render}
        >
          <PopUpMenuItem
            text={`Criar ${structureName}`}
            icon={AddIcon}
            action={() => history.push(redirectLink)}
          />
          <PopUpMenuItem
            text={`Espelhar ${structureName}`}
            icon={MdFolderCopy}
            action={() => {
              history.push(path);
            }}
          />
        </PopUpMenuRefactored>
      </Box>
    </>
  );
});
