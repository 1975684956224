import React from 'react'
import Main from '../Main'

export default function MultimediaMiniCard({ size, ...rest }){
  return <Main  color="#cea309" 
                backgroundColor="#fdf5cf"
                size={size}
                text="Multimídia"
                { ...rest }
                />
}