import React from 'react';

import CustomStepIcon from '~/components/atoms/CustomStepIcon';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { Step, StepLabel, Stepper, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export const StepsDisplay = ({ activeStep, steps }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Stepper activeStep={activeStep} style={{ padding: 0, width: '100%' }}>
      {steps.map((step, index) => {
        return (
          <Step key={index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              className={classes.stepLabel}
            >
              <Text
                className={classes.text}
                style={{
                  color:
                    index === activeStep ? theme.palette.primary.main : 'grey'
                }}
              >
                {step.name}
              </Text>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

StepsDisplay.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
};
