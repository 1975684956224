import * as yup from 'yup';

const editMemberValidation = {
  login: yup.string().required('Campo obrigatório'),
  first_name: yup.string().required('Campo obrigatório'),
  last_name: yup.string().required('Campo obrigatório'),
  password: yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
  email: yup
    .string()
    .required('Campo obrigatório')
    .email('Email inválido')
    // eslint-disable-next-line no-useless-escape
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, { message: 'Email inválido' })
};

export const editMemberScheme = yup.object(editMemberValidation);
