import React from 'react';

import ContactUs from '~/pages/Login/RightContent/ContactUs';
import settings from '~settings';

import * as S from './styles';
import { Grid, Hidden, Box, Typography, useTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export default function GridInitialPages({
  form,
  title,
  subtitle,
  imageBg,
  rememberComponent,
  logoLabel,
  fontSizeTitle,
  widthLeftImage,
  environmentImage
}) {
  const { light } = useTheme();
  const theme = useTheme();
  const classes = S.useStyles();

  return (
    <ThemeProvider theme={light}>
      <Grid container style={{ minWidth: 350, height: '100vh' }}>
        <Hidden smDown>
          <Grid item xs={false} sm={6} md={7} lg={7} xl={6}>
            <Box className={classes.contentLeft}>
              <Box className={classes.containerTitleLabel}>
                <Typography
                  className={classes.titleLabel}
                  style={{ fontSize: fontSizeTitle }}
                >
                  {title}
                </Typography>
              </Box>
              <Box className={classes.descriptionContainer}>
                <Typography className={classes.descriptionText}>
                  {subtitle}
                </Typography>
              </Box>
              <Box
                style={{
                  flex: 1,
                  height: theme.size.imageBackgroundLogin.height,
                  display: 'flex',
                  position: 'absolute',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: theme.size.imageBackgroundLogin.width,
                  zIndex: -1
                }}
              >
                <S.LeftImageBackground src={imageBg} alt="imagem de fundo" />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={6}>
          <Box className={classes.contentRight}>
            <Box className={classes.containerLogo}>
              {environmentImage ? (
                <img
                  src={`${settings.baseUrl}${environmentImage}`}
                  alt={logoLabel}
                />
              ) : (
                <img
                  src={`${theme.logo}`}
                  alt={logoLabel}
                  style={{ width: theme.size.loginLogo.width }}
                />
              )}
              <S.LogoLabel>{logoLabel}</S.LogoLabel>
            </Box>
            {form()}
            {rememberComponent && rememberComponent()}
            <ContactUs />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

GridInitialPages.propTypes = {
  form: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageBg: PropTypes.string,
  rememberComponent: PropTypes.bool,
  logoLabel: PropTypes.string,
  fontSizeTitle: PropTypes.number,
  widthLeftImage: PropTypes.string
};

GridInitialPages.defaultProps = {
  form: null,
  title: '',
  subtitle: '',
  imageBg: '',
  rememberComponent: false,
  logoLabel: '',
  fontSizeTitle: 28,
  widthLeftImage: '50%'
};
