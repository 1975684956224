import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  headerSmallCell: {
    width: '150px',
    maxWidth: '150px',
    minWidth: '150px',
    fontSize: '16px',
    fontWeight: '500',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main
  },
  headerMediumCell: {
    width: '250px',
    maxWidth: '250px',
    minWidth: '250px',
    fontSize: '16px',
    fontWeight: '500',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main
  }
}));
