import api from '~/services/api';

export const createCourse = async (environmentID, body) => {
  const { data } = await api.post(
    `/api/environments/${environmentID}/courses`,
    body
  );

  return data;
};

export const deleteLimit = async (limitID) => {
  const { data } = await api.delete(`api/enrollment_limits/${limitID}`);
  return data;
};
