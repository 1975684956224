import { useState } from 'react';

export function useReplication() {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const fetchStructureList = async (
    parentID,
    fetchFunction,
    handleSuccess,
    handleFailure
  ) => {
    if (isFetching || !hasMore) return;
    try {
      setIsFetching(true);
      const fetchFunctionParams = parentID ? [parentID, page] : [page];
      const response = await fetchFunction(...fetchFunctionParams);
      if (response.length >= 10) {
        setPage((prev) => prev + 1);
      } else {
        setHasMore(false);
      }
      handleSuccess(response);
    } catch (e) {
      handleFailure(e);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    fetchStructureList,
    isFetching
  };
}
