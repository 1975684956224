import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  textHeader: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  exitButton: {
    fontSize: 28,
    cursor: 'pointer',
    color: '#676d82'
  },

  topButtons: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #A8B1CE',
    color: theme.palette.primary.main,
    marginBottom: '32px'
  },

  topButtonSelected: {
    color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },

  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '8px'
  },
  cancelButton: {
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    borderRadius: '4px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#F5F5F5',
      filter: 'saturate(180%)'
    },
    '&:active': {
      color: theme.palette.primary.main,
      backgroundColor: '#E1E1E5',
      filter: 'saturate(180%)'
    }
  },
  importButton: {
    width: '243px',
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600
  },
  reportButton: {
    // width: '243px',
    display: 'flex',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    }
  },
  importButtonAdd: {
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      filter: 'saturate(180%)'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  },

  importButtonRemove: {
    background: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      filter: 'saturate(180%)'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: '#fff'
    }
  },

  inputFile: {
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: '16px',
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    cursor: 'pointer'
  },
  userLimitReachedWarning: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    borderRadius: 4,
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 32
  }
}));
