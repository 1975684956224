import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DateTimePickerInput from '~/components/atoms/DateTimePickerInput';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';

import { CustomSwitch } from '../CustomSwitch';
import { LabelText } from '../LabelText';
import { useStyles } from './styles';
import { Box, Collapse, Grid, Typography } from '@material-ui/core';

function BlockOptions() {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();
  const [show, setshow] = useState(
    Boolean(watch('id') && watch('expiration_date'))
  );

  const handleChange = () => {
    setshow((prev) => {
      if (!prev) {
        setValue('expiration_date', new Date());
      } else {
        setValue('expiration_date', null);
      }

      return !prev;
    });
  };

  return (
    <Grid container style={{ gap: '8px' }}>
      <Grid item xs={12}>
        <Box className={classes.fieldBox}>
          <LabelText>{`Configurar bloqueio de ${i18n.t(
            'StructuresName.Lectures.SingularUpper'
          )}`}</LabelText>
          <CustomSwitch checked={show} onChange={handleChange} size="small" />
        </Box>
      </Grid>

      <Collapse in={show}>
        <Grid item xs={12}>
          <Field
            label={<LabelText>Bloquear a partir de</LabelText>}
            name="expiration_date"
            control={control}
            Component={DateTimePickerInput}
          />
        </Grid>
      </Collapse>

      <Typography className={classes.helpText}>
        Ative essa opção para definir o tempo que a mídia ficará disponível
      </Typography>
    </Grid>
  );
}

export default BlockOptions;
