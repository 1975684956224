import React, { useRef } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import settings from '~settings';
import themes from '~themes';

import { CloseSnackbarButton } from './components/atoms/CloseSnackbarButton';
import FloatButtons from './components/molecules/FloatButtons';
import VLibras from './components/VLibras/index';
import { CustomSnackbarProvider } from './contexts/customSnackBar';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import { useStyles } from './styles/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import './I18n';

export default function App() {
  document.title = themes.title;
  clarity.init(settings.clarityKey);
  const notistackRef = useRef();
  const classes = useStyles();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          <GlobalStyle />
          <ThemeProvider theme={themes}>
            <CustomSnackbarProvider>
              <SnackbarProvider
                ref={notistackRef}
                maxSnack={5}
                style={{
                  fontSize: 14,
                  marginBottom: 2
                }}
                classes={{
                  containerRoot: classes.customSnackbar
                }}
                autoHideDuration={7000}
                action={(key) => (
                  <CloseSnackbarButton
                    snackbarProviderRef={notistackRef}
                    snackbarKey={key}
                  />
                )}
              >
                <VLibras />
                <Routes />
                {/* <FloatButtons /> */}
              </SnackbarProvider>
            </CustomSnackbarProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}
