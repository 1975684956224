import api from '~/services/api';

const baseURL = '/api/getpdf';

const getPdfData = async (ID) => {
  const { data } = await api.get(`${baseURL}?document_id=${ID}`, {
    responseType: 'blob'
  });
  return data;
};

export { getPdfData };
