import React from 'react';
import { useDispatch } from 'react-redux';

import api from '~/services/api';
import { Creators as AuthActions } from '~/store/ducks/auth';

import { useStyles } from './styles';
import { Button, Box } from '@material-ui/core';

export default function PrivacyTermsButtons({
  checked,
  open,
  setOpen,
  profileID
}) {
  const { signOut } = AuthActions;
  const classes = useStyles();
  const dispatch = useDispatch();

  const privacy_and_tos = true;

  const sendConfirmTermsPrivacyData = async () => {
    await api
      .put(`api/users/${profileID}`, { user: { privacy_and_tos } })
      .then(setOpen(!open))
      .catch(console.log());
  };

  return (
    <Box className={classes.container}>
      <Button
        className={classes.cancelButton}
        onClick={() => {
          dispatch(signOut());
        }}
      >
        Cancelar
      </Button>
      <Button
        className={classes.confirmButton}
        disabled={checked === false ? true : false}
        onClick={sendConfirmTermsPrivacyData}
      >
        Confirmar
      </Button>
    </Box>
  );
}
