import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LeftDrawer from '~/components/Badges/Drawer/LeftDrawer';
import AppBar from '~/components/molecules/AppBar';
import { Creators as AuthActions } from '~/store/ducks/auth';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const AppbarPages = ({ children }) => {
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { signOut } = AuthActions;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  function toggleDrawer() {
    setOpen(!open);
  }

  useEffect(() => {
    if (!profile) dispatch(signOut());
  }, [profile]);

  return (
    <Box className={classes.root}>
      <AppBar profile={profile} setOpen={toggleDrawer} />
      <LeftDrawer open={open} setOpen={toggleDrawer} />
      <Box className={classes.outerContainer}>{children}</Box>
    </Box>
  );
};

export default AppbarPages;

AppbarPages.propTypes = {
  children: PropTypes.any
};

AppbarPages.defaultProps = {
  children: null
};
