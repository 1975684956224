import React, { useEffect, useState } from 'react';
import Text from '~/components/atoms/Text';

import { Grid }           from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useSelector }    from 'react-redux';
import i18n from '~/I18n';

import FirstIntegration                                from './FirstIntegration';
import IntegrationOptions                              from './IntegrationOptions';
import { getAuthorizationLink, getUserAuthorizations } from './fetch';

import settings from '~settings';

export default function IntegrationUnDone() {

  const { profile } = useSelector( state => state.user )
  const { watch }   = useFormContext()
  
  const [authorizations, setAuthorizations]       = useState([])
  const [authorizationLink, setAuthorizationLink] = useState('#')

  useEffect(() => {
    const url = new URL(window.location.href);
    const authorizationCallbackPage = url.origin+`/app/pagseguro-redirect/${watch("id")}`
    getAuthorizationLink(watch("id"), authorizationCallbackPage).then( res => setAuthorizationLink(res.data.authorization_link) ).catch(console.log)
  }, [])

  useEffect(() => {
    if (profile) {
      getUserAuthorizations(profile.id).then(res => setAuthorizations( res.data )).catch(console.log)
    }
  }, [profile])

  return (
    <Grid container justifyContent='center'>

      <Grid item xs={12}>
        
        { authorizations.length == 0 ? 
          <FirstIntegration authorizationLink={authorizationLink} /> 
            : 
          <IntegrationOptions authorizationLink={authorizationLink} authorizations={authorizations} />
        }
      </Grid>

      <Grid item xs={12}>
        <a
          target="_blank"
          href={`${settings.baseUrl}/static/tutorial_integracao_pagseguro.pdf`}
          rel="noreferrer"
        >
          <Text variant="mediumText" color="accent">
            Clique aqui e saiba como integrar o {`${i18n.t('StructuresName.Environment.SingularLower')}`} com o Pagseguro.
          </Text>
        </a>
      </Grid>

    </Grid>
  );
}