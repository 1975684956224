import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  padding: 0 24px;
  background-color: white;
  ::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: 1630px) {
    padding: 0 2em;
  }

  @media (max-width: 450px) {
    padding: 0 1em;
    margin-top: 76px;
    height: calc(100vh - 76px);
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.scollbarThumbCOlor};
    border-radius: 2px;
  }
`;
