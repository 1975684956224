import ReduSebrae from '~/services/ReduSebrae';

export const create = async (userID, courseID, role) => {
  const payload = {
    user_course_association: {
      user_id: userID,
      course_id: courseID,
      role
    }
  };
  const { data } = await ReduSebrae.post(`/user_course_associations`, payload);

  return data;
};

export default { create };
