import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    descriptionContainer: {
        marginBottom: 50
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop:'30px',
        "& button": {
            "&:hover": {
                backgroundColor: props => `${props.theme.palette.secondary.main}E6`
            }
        },
        '@media(max-width:600px)': {
            marginTop:'30px'
        }
    },
});