import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    margin: '7px 0',
    display: 'flex',
    padding: '2px 0',
    borderRadius: 5,
    border: (props) =>
      props.greenOutline
        ? `1px solid ${props.theme.palette.success.main}`
        : props.redOutline
        ? `1px solid ${props.theme.palette.error.main}`
        : 'none',
    backgroundColor: (props) =>
      props.greenFilled
        ? `${props.theme.palette.success.main}33`
        : 'transparent'
  },
  imgData: {
    fontSize: '12px',
    '& img': {
      width: '250px'
    }
  },
  radio: {
    width: 14,
    height: 14,
    marginTop: 2,
    marginRight: 15
  },
  icon: {
    width: 14,
    height: 14
  },
  checked: {
    color: (props) =>
      props.greenOutline || props.greenFilled
        ? props.theme.palette.success.main
        : props.redOutline
        ? props.theme.palette.textActiveElement.main
        : 'none'
  },
  alternatives: {
    maxWidth: 500,
    maxHeight: 400
  }
});
