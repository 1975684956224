import React from 'react';
import { Link } from 'react-router-dom';

import { Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import ButtonCircle from '~/components/atoms/ButtonCircle';
import { SearchMenuIcon } from '~/components/atoms/Icons';
import settings from '~settings';

import { ContainerToolbar, useStyles, MyToolbar, Logo } from './styles';

export default function ToolbarLeft({ setOpen, toggleTheme }) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <ContainerToolbar>
      <ButtonCircle size={44} onClick={setOpen}>
        <SearchMenuIcon color="white" />
      </ButtonCircle>
      <Box className={classes.ToolBarLogo}>
        <Box className={classes.toolbar}>
          <MyToolbar>
            <Link to="/dashboard">
              <Logo src={theme.logo} width={theme.size.appBarLogo.width} />
            </Link>
          </MyToolbar>
        </Box>
      </Box>
    </ContainerToolbar>
  );
}

ToolbarLeft.propTypes = {
  setOpen: PropTypes.func,
  toggleTheme: PropTypes.func
};

ToolbarLeft.defaultProps = {
  setOpen: null,
  toggleTheme: null
};
