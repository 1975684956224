import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Text from '~/components/atoms/Text';
import AcademicEducation from '~/components/molecules/AcademicEducation';
import Interests from '~/components/molecules/Interests';
import Languages from '~/components/molecules/Languages';
import ProfessionalExperiences from '~/components/molecules/ProfessionalExperiences';
import Section from '~/components/templates/Section';
import { Months } from '~/constants/months';
import i18n from '~/I18n';

import { useStyles, TextNoExistData } from './styles';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

export default function BioCurriculum({ profile }) {
  const classes = useStyles();
  const loggedInProfile = useSelector((state) => state.user.profile);
  const interests = profile ? profile.interested_areas : null;
  const languages = profile ? profile.languages : null;

  const birthday = useMemo(() => {
    const data = profile?.birthday?.split('-');
    if (data) {
      const month = Months[parseInt(data[1]) - 1];

      return { day: data[2], month: month, year: data[0] };
    }
  }, [profile]);

  return (
    <Section className={classes.root} title={i18n.t('BioCurriculum')}>
      {profile ? (
        <>
          <Box className={classes.subtitleContainer}>
            <Text variant="lg" color="primary">
              Descrição
            </Text>
            {profile?.description !== '' ? (
              <Text variant="text" color="gray">
                {profile.description}
              </Text>
            ) : (
              <Text variant="largeText" color="gray">
                {i18n.t('NoDescription')}
              </Text>
            )}
          </Box>

          {profile.admin && profile?.id == loggedInProfile?.id && (
            <Box style={{ marginTop: 24 }}>
              <Box className={classes.subtitleContainer}>
                <Text variant="lg" color="primary">
                  Telefone
                </Text>
                {profile?.mobile !== '' ? (
                  <Text style={{ marginTop: 6 }} variant="text" color="gray">
                    {profile?.mobile}
                  </Text>
                ) : (
                  <Text variant="largeText" color="gray">
                    {i18n.t('NoMobileNumber')}
                  </Text>
                )}
              </Box>

              {profile?.gender && (
                <Box className={classes.subtitleContainer}>
                  <Text variant="lg" color="primary">
                    Gênero
                  </Text>
                  <Text style={{ marginTop: 6 }} variant="text" color="gray">
                    {profile?.gender}
                  </Text>
                </Box>
              )}

              {profile?.birthday && (
                <Box className={classes.subtitleContainer}>
                  <Text variant="lg" color="primary">
                    Data de Nascimento
                  </Text>
                  <Text style={{ marginTop: 6 }} variant="text" color="gray">
                    {`${birthday?.day}/${birthday?.month}/${birthday?.year}`}
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          <Skeleton
            variant="rect"
            width="100%"
            height={15}
            style={{ marginTop: 15 }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={15}
            style={{ marginTop: 15 }}
          />
        </>
      )}

      <Box className={classes.subsection}>
        <Box className={classes.subtitleContainer}>
          <Text variant="lg" color="primary">
            {i18n.t('Education')}
          </Text>
        </Box>
        {profile ? (
          <>
            {profile.educations && profile.educations.length > 0 ? (
              profile.educations.map((education, idx) => (
                <AcademicEducation
                  key={idx}
                  educationable={education.educationable}
                />
              ))
            ) : (
              <TextNoExistData text={i18n.t('noEducations')} />
            )}
          </>
        ) : (
          <>
            {[1, 2].map((item) => (
              <Box key={item} className={classes.educationsSkeletonContainer}>
                <Skeleton
                  variant="rect"
                  width="60px"
                  height={50}
                  style={{ marginRight: 20 }}
                />
                <Skeleton variant="rect" height={50} style={{ flex: 1 }} />
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box className={classes.subsection}>
        <Box className={classes.subtitleContainer}>
          <Text variant="lg" color="primary">
            {i18n.t('Experience')}
          </Text>
        </Box>
        {profile ? (
          <ProfessionalExperiences experiences={profile.experiences} />
        ) : (
          <>
            <Box className={classes.experiencesSkeletonContainer}>
              <Skeleton
                variant="rect"
                width="60px"
                height={50}
                style={{ marginRight: 20 }}
              />
              <Skeleton variant="rect" height={50} style={{ flex: 1 }} />
            </Box>
            <Skeleton
              variant="rect"
              width="100%"
              height={15}
              style={{ marginTop: 15 }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={15}
              style={{ marginTop: 15 }}
            />
          </>
        )}
      </Box>
      <Box className={classes.subsection}>
        <Box className={classes.subtitleContainer}>
          <Text variant="lg" color="primary">
            {i18n.t('Languages')}
          </Text>
          {profile ? (
            <Languages languages={languages} />
          ) : (
            <>
              <Skeleton
                variant="rect"
                width="100%"
                height={15}
                style={{ marginTop: 15 }}
              />
              <Skeleton
                variant="rect"
                width="100%"
                height={15}
                style={{ marginTop: 15 }}
              />
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.subsection}>
        <Box className={classes.subtitleContainer}>
          <Text variant="lg" color="primary">
            {i18n.t('InterestAreas')}
          </Text>
          {profile ? (
            <Interests interests={interests} />
          ) : (
            <>
              <Skeleton
                variant="rect"
                width="100%"
                height={15}
                style={{ marginTop: 15 }}
              />
              <Skeleton
                variant="rect"
                width="100%"
                height={15}
                style={{ marginTop: 15 }}
              />
            </>
          )}
        </Box>
      </Box>
    </Section>
  );
}

BioCurriculum.propTypes = {
  profile: PropTypes.shape()
};

BioCurriculum.defaultProps = {
  profile: {}
};
