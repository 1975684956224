import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Avatar, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';

export const ActionButton = styled(
  ({ textColor, color, disabled, ...otherProps }) => (
    <Button
      {...otherProps}
      variant="outlined"
      disabled={disabled}
      style={{
        color: textColor,
        fontSize: 12,
        height: 30,
        borderRadius: 15,
        backgroundColor: color,
        borderColor: textColor,
        padding: 0,
        textTransform: 'capitalize',
        flex: 1
      }}
    />
  )
)``;

export const ContactName = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 16,
      color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContactSocialNetwork = styled(({ color, ...otherProps }) => (
  <Typography
    {...otherProps}
    style={{
      fontSize: 12,
      color,
      margin: 0,
      padding: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }}
  />
))``;

export const ContainerInfoContact = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    justifyContent="flex-start"
    flexDirection="column"
    alignItems="flex-start"
    style={{
      marginRight: 10,
      flex: 3,
      width: '100%',
      overflow: 'hidden'
    }}
  />
))``;

export const Container = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    component="div"
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
    alignItems="center"
    style={{
      gap: '8px',
      margin: 5,
      height: 70
    }}
  />
))``;
