import React from 'react';
import { useFormContext } from 'react-hook-form';

import EnrollmentLinkToggle from '~/components/molecules/EnrollmentLinkToggle';
import i18n from '~/I18n';
import { createEnrollmentLink, getEnrollmentLink } from '~/services/course';

import CertificateInfo from './CertificateInfo';
import EnrollmentLimit from './EnrollmentLimit';
import MainInfo from './MainInfo';
import NotificationInfo from './NotificationInfo';
import PagseguroInfo from './PagseguroInfo';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';

export default function CourseForm() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { watch } = useFormContext();

  const tooltipEnrollmentLinkMessage = `Se este campo for preenchido, apenas membros de posse
  dessa chave conseguirão se inscrever no
  ${i18n.t(`StructuresName.Course.SingularUpper`)}
  através do link.`;

  return (
    <div className={classes.content}>
      <MainInfo />
      {watch('id') && (
        <div style={{ marginBottom: '10px' }}>
          <EnrollmentLinkToggle
            structureID={watch('id')}
            tooltipMessage={tooltipEnrollmentLinkMessage}
            getEnrollmentLink={getEnrollmentLink}
            createEnrollmentLink={createEnrollmentLink}
          />
        </div>
      )}
      <div style={{ marginBottom: '10px' }}>
        <EnrollmentLimit />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <CertificateInfo />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <PagseguroInfo />
      </div>
      {/* <NotificationInfo /> */}
    </div>
  );
}
