// React imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components imports
import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import AlertsDialog from '~/components/molecules/AlertsDialog';
import Section from '~/components/templates/Section';
import Notification from './Notification';
import Email from './Email';
import Password from './Password';

// Service and actions imports
import api from '~/services/api';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { Creators as SpacesCreators } from '~/store/ducks/spaces';

// Material-UI imports and icons
import { Box, useTheme } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

// Styles imports
import { useStyles } from './styles';

export default function AccountEdit() {
  // Hooks for theme and styles
  const theme = useTheme();
  const classes = useStyles({ theme });

  // Redux selectors and dispatch hook
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  
  // State hooks for component
  const [proceed, setProceed] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  // Action creators
  const { handleOpen, handleClose } = SpacesCreators;
  const { signOut } = AuthActions;

  // Handlers
  const handleResetActiveSection = () => {
    setActiveSection('');
  };

  // Delete user account
  async function handleDelete() {
    setProceed(true);
    try {
      await api.delete(`/api/users/${profile?.id}`);
      setProceed(false);
      dispatch(handleClose());
      dispatch(signOut());
    } catch (error) {
      setProceed(false);
      dispatch(handleClose());
    }
  }

  // Inline styles
  const styleInput = {
    fontSize: 12,
    lineHeight: 1.3,
    padding: 12,
    marginTop: 8,
    width: '100%',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main}`,
    '&::placeholder': {
      color: theme.palette.textActiveElement.main,
      opacity: 0.75
    }
  };

  const styleSaveButton = {
    cursor: 'pointer',
    padding: '10px 20px',
    margin: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&::placeholder': {
      color: theme.palette.textActiveElement.main,
      opacity: 0.75,
    }
  };

  const styleCancelButton = {
    ...styleSaveButton,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  };

  // Determine section title based on active section
  const getSectionTitle = () => {
    switch (activeSection) {
      case 'notifications':
        return "Recebimento de Notificações";
      case 'email':
        return "Alterar E-mail";
      case 'password':
        return "Alterar Senha";
      default:
        return "Configuração da Conta";
    }
  };

  // Render appropriate section based on active section
  const renderSection = () => {
    switch (activeSection) {
      case 'notifications':
        return <Notification 
                profile={profile}
                styleSaveButton={styleSaveButton}
                styleCancelButton={styleCancelButton}
                resetActiveSection={handleResetActiveSection}
              />;
      case 'email':
        return <Email 
                profile={profile}
                styleInput={styleInput}
                styleSaveButton={styleSaveButton}
                styleCancelButton={styleCancelButton}
                resetActiveSection={handleResetActiveSection}
              />;
      case 'password':
        return <Password 
                profile={profile}
                styleInput={styleInput}
                styleSaveButton={styleSaveButton}
                styleCancelButton={styleCancelButton}
                resetActiveSection={handleResetActiveSection}
              />;
      default:
        // Default account configuration section
        return (
          <Box>
            <AlertsDialog handleProceed={handleDelete} proceed={proceed} />
            {/* Configuration options */}
            <Box>
              <Text 
                variant="largeText" 
                color="gray"
                style={{marginBottom: '16px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                onClick={() => setActiveSection('notifications')}
              >
                <NotificationsIcon fontSize="large"/>
                Recebimento de Notificações
              </Text>
              <Text
                variant="largeText"
                color="gray"
                style={{marginBottom: '16px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                onClick={() => setActiveSection('email')}
              >
                <EmailIcon fontSize="large"/>
                Alterar E-mail
              </Text>
              <Text 
                variant="largeText" 
                color="gray"
                style={{marginBottom: '16px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                onClick={() => setActiveSection('password')}
              >
                <VpnKeyIcon fontSize="large"/>
                Alterar Senha
              </Text>
            </Box>
            {/* Delete account button */}
            <Box className={classes.buttonArea}>
              <ButtonSquare
                type="button"
                onClick={() =>
                  dispatch(
                    handleOpen(
                      'Tem certeza que deseja excluir sua conta?',
                      'alert',
                      profile
                    )
                  )
                }
                height={50}
                width={100}
                letterSpacing={0}
                fontSize="16px"
                border={`1px solid ${theme.palette.primary.main}`}
              >
                <Text variant="button" style={{color: theme.palette.primary.main}}>
                  Excluir Conta
                </Text>
              </ButtonSquare>
            </Box>
          </Box>
        );
    }
  };

  // Render the main component
  return <Section title={getSectionTitle()}>{renderSection()}</Section>;
}
