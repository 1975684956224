import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, Button } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';


export const useStyles = makeStyles({
  welcomeContainer: {
    width: '100%',
    background: '#EAEAEA',
    borderRadius: '10px',
    display: 'flex',
    marginTop: '30px',
    padding: '10px',
    position: 'relative',
    '@media (max-width: 450px)': {
      width: '98%'
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1em',
    marginLeft: '3em'
  },
  welcomeText: {
    display: 'flex',
    width: '100%'
  },
  imageContainer: {
    height: '100%',
    width: '40%',
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    position: 'absolute',
    top: '-5px',
    right: '-5px'
  },
  textInfoDesktop: {
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  textInfoTabletMobile: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  }
});

// Não está sendo usado (apagar depois)
export const Container = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    display="flex"
    alignItems="flex-start"
    justifyContent="flex-end"
    style={{
      width: '100%',
      padding: '10px 0px'
    }}
  />
))``;

// Não está sendo usado (apagar depois)
export const ContainerItems = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    display="flex"
    alignItems="flex-start"
    justifyContent="flex-end"
    style={{
      width: '100%',
      paddingTop: 20,
      paddingRight: 25
    }}
  />
))`
  background: -webkit-linear-gradient(#fff 40px, rgba(0, 0, 0, 0) 40px),
    -webkit-linear-gradient(180deg, #fff 40px, #eaeaea 40px, #eaeaea 100%);
`;

// Não está sendo usado (apagar depois)
export const ContainerImage = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    display="flex"
    alignItems="flex-start"
    justifyContent="flex-end"
  />
))``;

// Não está sendo usado (apagar depois)
export const ContainerInfo = styled(({ ...otherProps }) => (
  <Box
    {...otherProps}
    style={{
      width: '60%',
      // margin: 20,
      marginLeft: 52,
      marginTop: 28,
      borderRadius: 10,
      paddingBottom: 50,
      paddingTop: 20
    }}
  />
))``;

export const Title = styled(({ highlight, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Typography
      {...otherProps}
      style={{
        fontSize: 30,
        color: theme.palette.primary.main,
        marginBottom: 20
      }}
    />
  );
})``;

export const TextInfo = styled(({ highlight, ...otherProps }) => {
  const theme = useTheme();
  return (
    <>
      <Box className={useStyles().textInfoDesktop}>
        <Typography
          {...otherProps}
          style={{
            fontSize: 20,
            color: highlight
              ? theme.palette.primary.main
              : theme.palette.activeText.main
          }}
        />
      </Box>
      <Box className={useStyles().textInfoTabletMobile}>
        <Typography
          {...otherProps}
          style={{
            fontSize: 16,
            color: highlight
              ? theme.palette.primary.main
              : theme.palette.activeText.main
          }}
        />
      </Box>
    </>
  );
})``;

export const TextHighlighted = styled(({ highlight, ...otherProps }) => {
  const theme = useTheme();
  return (
    <span
      {...otherProps}
      style={{
        fontSize: 20,
        color: theme.palette.primary.main
      }}
    />
  );
})``;

export const CloseButton = styled(({ highlight, ...otherProps }) => {
  const theme = useTheme();
  return (
    <Button
      {...otherProps}
      style={{
        fontSize: 20,
        backgroundColor: theme.palette.primary.main,
        minWidth: 30,
        width: 30,
        height: 30,
        alignSelf: 'flex-start',
        borderRadius: 15
      }}
    />
  );
})``;

export const CloseIcon = styled(({ highlight, ...otherProps }) => (
  <FontAwesomeIcon icon={faTimes} color="white" style={{ fontSize: 18 }} />
))``;
