import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '12px',
    margin: '24px 0 12px 0'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  }
});
