import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import { ConversationMessages } from '~/components/molecules/ConversationMessages';
import { WelcomeChatPageTemplate } from '~/components/templates/WelcomeChatPageTemplate';

// import Route from './Route';

export default function MessageRoutes() {
  let { path } = useRouteMatch();
  const { profile } = useSelector((state) => state.user);

  return (
    <Switch>
      <Route
        exact
        path="/messages"
        component={() => <WelcomeChatPageTemplate />}
        isPrivate
      />
      <Route
        path={`${path}/:contact_id`}
        component={() => <ConversationMessages profile={profile} />}
        isPrivate
      />
    </Switch>
  );
}
