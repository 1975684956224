export default {
  versionName: 'AAA',
  versionNumber: '0.0.1',
  name: 'Saberes',
  client: 'saberes',
  websocketSecret: 'OUBtCXoayoc2uptN5tvuCCJvej',
  websocketKey: 'DEu3vO#gPJGMYT@US@YxWNJFAzLUy^BUZLAdu8Bxa6DFrLWR',
  websocketUrl: 'wss://saberes.edicoesipdh.com.br:44443',
  appstore: 'https://apps.apple.com/us/app/saberes/id6446143878',
  playstore:
    'https://play.google.com/store/apps/details?id=com.viitra.saberes&pli=1',
  googleAuthClientId:
    '590034191650-rmrfddb4s0bjpij8nd40p78ljo7midqj.apps.googleusercontent.com',
  googleAuthScopes: [
    'https://www.googleapis.com/auth/calendar.app.created'
  ].join(' '),
  helpCenterUrl: 'https://ajuda.redu.digital'
};
