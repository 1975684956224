import React from 'react';
import { useFormContext } from 'react-hook-form';

import Input from '~/components/atoms/TextInput';
import EnrollmentLinkToggle from '~/components/molecules/EnrollmentLinkToggle';
import Field from '~/components/organisms/GenericFormWrapper/Field';
import i18n from '~/I18n';
import { TextPrimary } from '~/utils/forms';
import { createEnrollmentLink, getEnrollmentLink } from '~/services/Space';

import Keywords from './Keywords';
import Image from './Image';
import MuralAccessManagement from './MuralAccessManagement';
import { useStyles } from './styles';
import SubjectDependency from './SubjectDependency';
import { Grid } from '@material-ui/core';

export default function SpaceForm() {
  const classes = useStyles();
  const { watch } = useFormContext();

  const tooltipEnrollmentLinkMessage = `Se este campo for preenchido, apenas membros de posse
  dessa chave conseguirão se inscrever na
  ${i18n.t(`StructuresName.Space.SingularUpper`)}
  através do link.`;

  return (
    <div className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Nome *')}
              name="name"
              Component={Input}
              placeholder={`Digite o nome da ${i18n.t(
                'StructuresName.Space.SingularLower'
              )}`}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.field}>
            <Field
              label={TextPrimary('Sigla')}
              name="initials"
              Component={Input}
              placeholder="Sigla..."
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} className={classes.field}>
          <Field
            label={TextPrimary('Turno')}
            name="turn"
            Component={Input}
            placeholder="Turno..."
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} className={classes.field}>
          <Field
            label={TextPrimary('Descrição')}
            name="description"
            Component={Input}
            placeholder={`Informações da ${i18n.t(
              'StructuresName.Space.SingularUpper'
            )}...`}
            multiline
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} className={classes.field}>
          <Image />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} className={classes.field}>
          <Keywords />
        </Grid>
      </Grid>

      {watch('id') && (
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.field}>
            <EnrollmentLinkToggle
              structureID={watch('id')}
              tooltipMessage={tooltipEnrollmentLinkMessage}
              getEnrollmentLink={getEnrollmentLink}
              createEnrollmentLink={createEnrollmentLink}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} className={classes.field}>
          <MuralAccessManagement />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} className={classes.field}>
          <SubjectDependency />
        </Grid>
      </Grid>
    </div>
  );
}
