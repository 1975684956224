import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    padding: 0,
    overflowY: 'auto',
    '& ::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  }
});
