import React from 'react';

import { useStyles } from './style';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Forward10RoundedIcon from '@material-ui/icons/Forward10Rounded';
import Replay10RoundedIcon from '@material-ui/icons/Replay10Rounded';

export default function Controls({
  progress,
  duration,
  playbackRate,
  setPlaybackRate,
  seekTo
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  // 1x -> 1.25x -> 1.5x -> 1.75x -> 2x -> 1x
  const nextSpeed = (prev) => Math.min(Math.max((prev + 0.25) % 2.25, 1), 2);
  const cycleSpeed = () => setPlaybackRate((prev) => nextSpeed(prev));

  const forward10 = () => seekTo(progress + 10);
  const backward10 = () => seekTo(progress - 10);

  function sec2min(sec) {
    const prependZero = (num) => ('0' + num).slice(-2);
    const min = Math.trunc(sec / 60);
    const secRemain = sec % 60;
    return `${prependZero(min)}:${prependZero(secRemain)}`;
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.progress}>{sec2min(progress)}</Typography>

      <Box className={classes.container}>
        <button className={classes.controlIcons} onClick={backward10}>
          <Replay10RoundedIcon />
        </button>

        <button
          className={classes.playBackRate}
          onClick={cycleSpeed}
        >{`${playbackRate}X`}</button>

        <button className={classes.controlIcons} onClick={forward10}>
          <Forward10RoundedIcon />
        </button>
      </Box>

      <Typography className={classes.progress}>{sec2min(duration)}</Typography>
    </Box>
  );
}
