import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  searchMenuPaper: {
    borderRadius: '4px',
    position: 'absolute',
    backgroundColor: '#32394E',
    top: '40px'
  },
  searchMenuContent: {
    display: 'flex',
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: '#32394E'
  },
  searchInputEndAdornment: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '12px',
    fontWeight: 600
  },
  personIconsStyle: {
    height: '24px',
    width: '24px'
  },
  inputSearch: {
    marginRight: '8px',
    backgroundColor: '#32394E',
    color: theme.palette.textAndBackground.main,
    fontSize: '14px',
    border: '1px solid  #676D82',
    padding: '8px 8px',
    borderRadius: '4px'
  }
}));
