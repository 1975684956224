import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import UserCourseAssociationService from '~/services/ReduSebrae/UserCourseAssociation';
import { Creators as UserActions } from '~/store/ducks/user';

export default function SebraeHandling () {
  const token = new URLSearchParams(useLocation().search).get('t');

  const [path, setPath] = useState('/dashboard?se=true');
  const [loading, setLoading] = useState(true);

  const { profile } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { getProfileRequest } = UserActions;

  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);
  useEffect(() => {
    if (profile) {
      UserCourseAssociationService.create(profile.id, token, 'member')
        .then((UCA) => {
          setPath(
            `/dashboard/environments/${UCA?.course?.environment_id}/courses/${UCA?.course_id}`
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [profile]);

  return loading ? <div>carregando</div> : <Redirect to={path} />;
}
