import api from '~/services/api';

import { getEnvironments as baseGetEnvironments } from '..';

const baseURL = '/api/mirroring_list/courses';

export const getEnvironments = async (page) => {
  const environmentsList = await baseGetEnvironments(baseURL, page);

  return environmentsList;
};

export const getSelf = async (environmentID, page) => {
  const { data } = await api.get(
    `${baseURL}?environment_id=${environmentID}&page=${page}`
  );

  return data;
};

export default {
  getEnvironments,
  getSelf
};
