import { all, put, call, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { Types, Creators as EnvironmentsActions } from './index';

export function* getEnvironments(action) {
  const {
    getEnvironmentsSuccess,
    getEnvironmentsFailure,
  } = EnvironmentsActions;
  // const { page } = action;
  try {
    const response = yield call(
      api.get,
      'api/environments'
      // {params: { page },}
    );
    yield put(getEnvironmentsSuccess(response.data));
  } catch (error) {
    yield put(getEnvironmentsFailure());
  }
}

export default all([
  takeLatest(Types.GET_ENVIRONMENTS_REQUEST, getEnvironments),
]);
