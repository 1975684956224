import React from 'react';
import { BsStars } from 'react-icons/bs';

import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';

export const ForumDescription = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox}>
        <BsStars className={classes.titleIcon} />
        <Typography className={classes.titleText}>Dica de uso</Typography>
      </Box>
      <Typography className={classes.description}>
        Utilize o campo abaixo para desenvolver seu tópico de forma detalhada e
        abrangente. Utilize os recursos do campo para criar perguntas, adicionar
        imagens e links, além de incorporar páginas através de{' '}
        <strong>iFrames.</strong>
      </Typography>
    </Box>
  );
};
