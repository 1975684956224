import React from 'react';

import LecturesReport from '~/components/molecules/LecturesReport';
import ReportRow from '~/components/molecules/ReportRow';
import { ReportTableAccordion } from '~/components/molecules/ReportTableAccordion';
import { getSubjects } from '~/services/environment';

import InformationListReport from './InformationListReport';
import PerformanceReport from './PerformanceReport';
import SimplifiedSubjectsReport from './SimplifiedSubjectsReport';
import { useStyles } from './styles';
import UsersReport from './UsersReport';
import UsersRolesReport from './UsersRolesReport';
import PropTypes from 'prop-types';

export default function EnvironmentReports({ id }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReportTableAccordion
        title="Relatórios de conclusão de mídias"
        report={LecturesReport}
        id={id}
        fetchFunction={getSubjects}
      />
      <ReportRow
        title="Relatório de alunos do Ambiente"
        report={UsersReport}
        id={id}
      />
      <ReportRow
        title="Relatório das estruturas do Ambiente"
        report={InformationListReport}
        id={id}
      />
      <ReportRow
        title={`Relatório de papéis dos usuários no Ambiente`}
        report={UsersRolesReport}
        id={id}
      />
      <ReportRow
        title="Relatório simplificado de conclusão de módulos"
        report={SimplifiedSubjectsReport}
        id={id}
      />
      <ReportRow
        title="Relatório de desempenho do aluno nos exercícios"
        report={PerformanceReport}
        id={id}
      />
    </div>
  );
}

EnvironmentReports.propTypes = {
  id: PropTypes.string
};

EnvironmentReports.defaultProps = {
  id: ''
};
