import React, { useState, useEffect } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import { useStyles } from './styles';
import { Backdrop, Fade, Modal, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

function QuestionBankModal({ open, setOpen }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [laplaceLink, setLaplaceLink] = useState('');
  const [inovaulaLink, setInovaulaLink] = useState('');
  const [modalVariant, setModalVariant] = useState('LaplaceModal');

  useEffect(() => {
    if (open) {
      api
        .get('/api/laplace')
        .then(({ data }) => {
          setModalVariant('LaplaceModal');
          setLaplaceLink(data);
        })
        .catch(console.log);

      api
        .get('/api/inovaula')
        .then(({ data }) => {
          setModalVariant('InovaulaModal');
          setInovaulaLink(data);
        })
        .catch(console.log);
    }
  }, [open]);

  function gotoLaplace() {
    window.open(laplaceLink, '_blank');
    setOpen(false);
  }

  function goToInovaula() {
    window.open(inovaulaLink, '_blank');
    setOpen(false);
  }

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 450 }}
            >
              {i18n.t(`${modalVariant}.Title`)}
            </Text>
            <ButtonSquare onClick={() => setOpen(false)}>
              <CloseIcon style={{ fontSize: '32px' }} />
            </ButtonSquare>
          </div>
          <Text
            color="gray"
            variant="lg"
            style={{ fontWeight: 500, marginTop: '24px' }}
          >
            {i18n.t(`${modalVariant}.Description`)}
          </Text>
          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step1`)}
          </Text>
          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step1Description`)}
          </Text>

          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step2`)}
          </Text>
          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step2Description`)}
          </Text>

          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step3`)}
          </Text>
          <Text color="gray" variant="lg" style={{ marginTop: '24px' }}>
            {i18n.t(`${modalVariant}.Step3Description`)}
          </Text>
          <ButtonSquare
            style={{ marginLeft: 'auto', textTransform: 'none' }}
            backgroundColor={theme.palette.primary.main}
            padding="8px 24px"
            disabled={!laplaceLink && !inovaulaLink}
            onClick={
              laplaceLink ? gotoLaplace : inovaulaLink ? goToInovaula : () => {}
            }
          >
            <Text color="white" variant="lg">
              {i18n.t(`${modalVariant}.GoToLaplace`)}
            </Text>
          </ButtonSquare>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default QuestionBankModal;
