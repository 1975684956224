import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '865px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginBottom: '8px',
    '& > *': {
      marginBottom: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      gap: '15px'
    }
  }
}));
