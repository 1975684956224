import React from 'react';

import { useStyles } from './styles';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

export default function TableHeaderWrapper() {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerSmallCell} align="center">
          ID
        </TableCell>
        <TableCell className={classes.headerMediumCell}>Espelhado</TableCell>
        <TableCell className={classes.headerSmallCell} align="center">
          ID
        </TableCell>
        <TableCell className={classes.headerMediumCell}>Original</TableCell>
        <TableCell className={classes.headerSmallCell} align="center">
          Tipo
        </TableCell>
        <TableCell className={classes.headerSmallCell} align="center">
          Data de criação
        </TableCell>
        <TableCell className={classes.headerSmallCell} align="center">
          Status
        </TableCell>
        <TableCell className={classes.headerSmallCell} align="center">
          Ações
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
