import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  getEnvironmentsRequest: ['page'],
  getEnvironmentsSuccess: ['environments'],
  getEnvironmentsFailure: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  environments: null,
  page: 1,
  loading: false,
};

const getEnvironmentsRequest = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = true;
  });

const getEnvironmentsSuccess = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    draft.loading = false;
    // draft.page += 1;
    // draft.environments = draft.environments.concat(action.environments);
    draft.environments = action.environments;
  });

const getEnvironmentsFailure = (state = INITIAL_STATE) =>
  produce(state, (draft) => {
    draft.loading = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.GET_ENVIRONMENTS_REQUEST]: getEnvironmentsRequest,
  [Types.GET_ENVIRONMENTS_SUCCESS]: getEnvironmentsSuccess,
  [Types.GET_ENVIRONMENTS_FAILURE]: getEnvironmentsFailure,
});
