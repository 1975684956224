import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EmptyFeedbackMural } from '~/assets/images/writing-a-letter-pana.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import MuralTemplate from '~/components/templates/Mural';
import { Creators as MyWallActions } from '~/store/ducks/my-wall';

import PropTypes from 'prop-types';

const Mural = ({ target, userId }) => {
  const { profile } = useSelector((state) => state.user);
  const { pageMyWall, statusesMyWall, hasMoreStatuses, loading } = useSelector(
    (state) => state.mywall
  );
  const { getMyStatusesRequest, postMyStatusesRequest } = MyWallActions;
  const dispatch = useDispatch();

  const getStatuses = () => {
    const curUserID = userId || profile.id;
    if (profile) {
      dispatch(getMyStatusesRequest(curUserID, pageMyWall));
    }
  };
  const postStatuses = (status, login) => {
    dispatch(postMyStatusesRequest(status, login));
  };

  useEffect(() => {
    getStatuses();
  }, [profile]);

  return (
    <MuralTemplate
      loading={loading}
      statuses={statusesMyWall}
      hasMore={hasMoreStatuses}
      target={target}
      getStatuses={getStatuses}
      postStatuses={postStatuses}
      showTextarea={!userId}
      isDashboard
      emptyFeedbackComponent={
        <GenericFeedbackLayout
          ImageIcon={EmptyFeedbackMural}
          title="Faça sua primeira publicação"
          description="Compartilhe duas ideias com suas conexões"
        />
      }
    />
  );
};

Mural.propTypes = {
  target: PropTypes.string
};

Mural.defaultProps = {
  target: ''
};

export default Mural;
