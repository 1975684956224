import React from 'react';

import { useInfiniteScroll } from '~/hooks/useInfiniteScroll';

import Row from './Row';
import RowText from './Row/RowText';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function LecturesReport({ id, fetchFunction }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { elements: subjects, anchorRef } = useInfiniteScroll({
    fetchFunction: fetchFunction,
    id
  });

  function TitleRow() {
    return (
      <div className={`${classes.row} ${classes.titleRow}`}>
        <RowText style={{ color: 'white' }} variant={'h6'}>
          ID
        </RowText>
        <RowText style={{ color: 'white' }} variant={'h6'}>
          Nome
        </RowText>
        <RowText style={{ color: 'white' }} variant={'h6'}>
          Ações
        </RowText>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <TitleRow />
      {subjects.map((subject, id) => (
        <Row key={subject.id} subject={subject} id={id} />
      ))}
      <div style={{ height: '8px' }} ref={anchorRef}></div>
    </div>
  );
}

LecturesReport.propTypes = {
  id: PropTypes.string,
  fetchFunction: PropTypes.func
};

LecturesReport.defaultProps = {
  id: ''
};
