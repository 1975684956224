import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  infosHeader: {
    marginTop: '34px',
    marginBottom: '5px',
    maxWidth: '100%',
    '& p': {
      maxWidth: '100%'
    }
  },
  infoIcon: {
    color: theme.palette.accent.main,
    marginRight: '8px'
  },
  ambientInformationText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
