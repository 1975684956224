import React, { useEffect, useRef, useState } from 'react';

import i18n from '~/I18n';
import { getSummaryLectures } from '~/services/Lecture';

import { HorizontalScrollerWrapper } from './HorizontalScrollerWrapper';
import { LectureCard } from './LectureCard';
import { Loading } from './Loading';
import { useStyles } from './styles';
import { Title } from './Title';
import { Welcome } from './Welcome';
import { Box, Divider, useTheme } from '@material-ui/core';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import RestoreIcon from '@material-ui/icons/Restore';
import { useSnackbar } from 'notistack';

export const LecturesSummary = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [summaryLectures, setSummaryLectures] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const getLecturesSummary = () => {
    getSummaryLectures()
      .then((lectures) => {
        setSummaryLectures(lectures);
      })
      .catch(() => {
        enqueueSnackbar(
          `Não foi possível carregar as ultimas ${i18n.t(
            `StructuresName.Lectures.PluralLower`
          )}`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    getLecturesSummary();
  }, []);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {summaryLectures.length > 0 ? (
            <HorizontalScrollerWrapper>
              <Box className={classes.lastLectureContainer}>
                <Title
                  Icon={RestoreIcon}
                  text={`Ultima ${i18n.t(
                    `StructuresName.Lectures.SingularLower`
                  )} acessada`}
                  color={theme.palette.activeText.main}
                />
                <LectureCard lecture={summaryLectures[0]} lastLecture />
              </Box>
              {summaryLectures.length > 1 && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Box className={classes.otherLecturesContainer}>
                    <Title
                      Icon={PlayCircleOutlineRoundedIcon}
                      text={`Próximas ${i18n.t(
                        `StructuresName.Lectures.PluralLower`
                      )}`}
                      color={theme.palette.primary.main}
                    />
                    <Box style={{ display: 'flex', gap: '16px' }}>
                      {summaryLectures.slice(1, 6).map((item, index) => (
                        <LectureCard lecture={item} key={index} />
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </HorizontalScrollerWrapper>
          ) : (
            <Welcome />
          )}
        </>
      )}
    </>
  );
};
