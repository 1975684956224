import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function PaginatableSelect({
  label,
  placeholder,
  handleChange,
  curValue,
  data,
  fetchMoreItems,
  isFetching
}) {
  const classes = useStyles();

  const [localValue, setLocalValue] = useState(curValue);

  useEffect(() => {
    setLocalValue(curValue);
  }, [curValue, data]);

  const handleInfinitScroll = (event) => {
    const bottom =
      event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight <
      50;
    if (bottom && !isFetching) {
      fetchMoreItems();
    }
  };

  return (
    <Box className={classes.content}>
      <InputLabel id="structureSelect" className={classes.label}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        variant="outlined"
        labelId="structureSelect"
        value={localValue || ''}
        displayEmpty
        onScroll={handleInfinitScroll}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          },
          getContentAnchorEl: null
        }}
        renderValue={(value) => {
          const selectedStructure = data.find((item) => item.value === value);
          const curPlaceholder =
            isFetching && data.length === 0 ? (
              <CircularProgress size={24} />
            ) : (
              placeholder
            );
          return selectedStructure ? selectedStructure.name : curPlaceholder;
        }}
        classes={{ outlined: classes.outlinedSelect }}
      >
        {data.map((structureItem) => (
          <MenuItem
            key={structureItem.value}
            value={structureItem.value}
            className={classes.selectItem}
          >
            {structureItem.name}
          </MenuItem>
        ))}
        {isFetching && (
          <MenuItem
            disabled
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress size={24} />
          </MenuItem>
        )}
        {!isFetching && data.length === 0 && (
          <MenuItem
            disabled
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            Nenhum conteúdo encontrado.
          </MenuItem>
        )}
      </Select>
    </Box>
  );
}

PaginatableSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.node,
  handleChange: PropTypes.func.isRequired,
  curValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  fetchMoreItems: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired
};
