// React-related imports
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Components and utils imports
import Text from '~/components/atoms/Text';
import api from '~/services/api';
import i18n from '~/I18n';

// Validation imports
import * as Yup from 'yup';
import { EmailValidation } from '~/utils/EditProfileValidation/yup';

// Action creators imports
import { Creators as UserActions } from '~/store/ducks/user';

function EmailSettings({
  profile,
  styleInput,
  styleSaveButton,
  styleCancelButton,
  resetActiveSection
}) {
  // Utilizing Material-UI theme for styles
  const theme = useTheme();

  // State for handling email data
  const [emailData, setEmailData] = useState({ email: '', confirmEmail: '' });

  // State for handling validation errors
  const [errors, setErrors] = useState([]);

  // Hooks for dispatching actions and showing snackbars
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Cancel button handler
  const handleCancel = () => {
    resetActiveSection();
  };

  // Save button handler
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      // Validating email data before sending the request
      await EmailValidation.validate(emailData, { abortEarly: false });
      setErrors([]);

      // API request to update email
      await api.put(`/api/users/${profile?.id}`, {
        user: {
          email: emailData.email
        }
      });

      // Dispatching the updated profile data to the store
      const updatedProfile = { ...profile, email: emailData.email };
      dispatch(UserActions.putProfileUpdate(updatedProfile));

      // Showing success message
      enqueueSnackbar('Email foi atualizado com sucesso!', {
        variant: 'success'
      });
      handleCancel();
    } catch (error) {
      // Handling validation and API errors
      if (error instanceof Yup.ValidationError) {
        setErrors(error.inner.map((err) => err.message));
      } else {
        enqueueSnackbar('Erro ao atualizar o Email', { variant: 'error' });
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleSave}>
        {/* Displaying current email */}
        <Text variant="label" color={'gray'}>
          Email Atual
        </Text>
        <Text
          variant="label"
          style={{ color: theme.palette.primary.main, fontWeight: 'bolder' }}
        >
          {profile?.email || 'N/A'}
        </Text>

        {/* Input for new email */}
        <Text variant="label" color={'gray'}>
          Novo E-mail
        </Text>
        <input
          style={styleInput}
          placeholder="Email"
          onChange={(e) =>
            setEmailData({ ...emailData, email: e.target.value })
          }
        />

        {/* Input for confirming new email */}
        <Text variant="label" color={'gray'}>
          Confirmar E-mail
        </Text>
        <input
          style={styleInput}
          placeholder="Confirmar Email"
          onChange={(e) =>
            setEmailData({ ...emailData, confirmEmail: e.target.value })
          }
        />

        {/* Displaying validation errors */}
        {errors &&
          errors.map((error, index) => (
            <p key={index} style={{ color: 'red', fontSize: 12 }}>
              {error}
            </p>
          ))}

        {/* Buttons for cancel and save actions */}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            style={styleCancelButton}
            onClick={handleCancel}
            type="button"
          >
            {i18n.t('Cancel')}
          </button>
          <button style={styleSaveButton} type="submit">
            Alterar E-mail
          </button>
        </div>
      </form>
    </Box>
  );
}

export default EmailSettings;
