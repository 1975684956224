import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles({
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingBottom: 12
  },
  showAllButton: {
    '&:hover': {
      borderBottom: '1px solid #fff'
    }
  }
});

export const ContainerButton = styled(({ ...otherProps }) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    {...otherProps}
    style={{ padding: '10px 0' }}
  />
))`
  ${(props) => props.lastChild && 'padding-bottom: 200px'}
`;
