import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '6rem',
    alignItems: 'end'
  },

  progress: {
    color: theme.palette.activeText.main,
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },

  controlIcons: {
    transform: 'scale(1.8)',
    color: theme.palette.activeText.main
  },

  playBackRate: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    width: '10rem',
    color: theme.palette.activeText.main
  }
}));
