
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ObjectsContainer from './ObjectsContainer'

export default function DragDropList({ objects, setObjects, Container, ObjectComponent, itemType, ...rest }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <ObjectsContainer objects={objects} 
                        setObjects={setObjects}  
                        Container={Container}
                        ObjectComponent={ObjectComponent}
                        itemType={itemType}
                        {...rest}
                      />
    </DndProvider>
  )
}