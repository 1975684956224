import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 'min-content',
    maxHeight: 'min-content'
  },
  image: {
    borderRadius: '8px',
    objectFit: 'contain',
    maxHeight: '600px'
  },
  openFullScreenImageButton: {
    position: 'absolute',
    alignSelf: 'end',
    top: 16,
    right: 16,
    borderRadius: '50%',
    backgroundColor: '#F2F2F7'
  },
  openFullScreenImageButtonIcon: {
    fontSize: '24px',
    color: theme.palette.activeText.main
  }
}));
