import auth from './auth/saga';
import courses from './courses/saga';
import environments from './environments/saga';
import following from './following/saga';
import mywall from './my-wall/saga';
import overview from './overview/saga';
import transferMembers from './transferMembers/saga';
import user from './user/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga () {
  return yield all([
    auth,
    user,
    overview,
    environments,
    following,
    mywall,
    courses,
    transferMembers
  ]);
}
