/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import CourseCard from '~/components/molecules/CourseCard';
import api from '~/services/api';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';

const Courses = ({ courses, environmentID }) => {
  const classes = useStyles();
  const { watch } = useFormContext();

  const [coursesList, setCoursesList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreCourses, setHasMoreCourses] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const { id } = useParams();

  const getMoreCourses = async () => {
    setIsFetching(true);
    try {
      const { data } = await api.get(
        `api/environments/${id}/courses?order=${watch('ord')}&sort=${watch(
          'direction'
        )}&name_like=${watch('name_like')}&page=${page + 1}`
      );

      setCoursesList((courses) => [...courses, ...data]);

      if (data.length < 10) {
        setHasMoreCourses(false);
      }

      setPage((page) => page + 1);
      setIsFetching(false);
      // eslint-disable-next-line no-empty
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setHasMoreCourses(false);
      }
      setIsFetching(false);
    }
  };

  const getMoreItems = (e) => {
    if (isFetching || !hasMoreCourses) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 180;

    if (bottom) {
      getMoreCourses();
    }
  };

  useEffect(() => {
    if (courses) {
      setCoursesList(courses);
      setHasMoreCourses(courses.length >= 10);
    }
  }, [courses]);

  return (
    <Box className={classes.root}>
      <Box className={classes.content} onScroll={getMoreItems}>
        {coursesList.map((course, index) => {
          return (
            <CourseCard
              instance={course}
              instanceType="course"
              key={index}
              link={`/dashboard/environments/${environmentID}/courses/${course.id}`}
              courseID={course.id}
              environmentId={environmentID}
              editLink={`/dashboard/environments/${environmentID}/courses/${course.id}/edit`}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Courses;
