import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import settings from '~settings';

import TextBox from '../TextBox';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

const UsageTerms = ({ isModal }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const client = settings.name;
  const platformName =
    client === 'AVAMEC Interativo' ? 'AVAMEC Interativo' : 'Redu';
  const clientMaintainer =
    client === 'AVAMEC Interativo'
      ? 'UFPE - Universidade Federal de Pernambuco'
      : 'Viitra';
  let count = 0;

  return (
    <Box className={isModal ? classes.rootModal : classes.root}>
      <Box className={isModal ? classes.titleModal : classes.title}>
        <Text
          style={{
            color: theme.palette.activeText.main,
            fontSize: 20,
            fontWeight: '700'
          }}
          variant="ppTitle"
        >
          {i18n.t('TermsOfUse')}
        </Text>
      </Box>
      <Box className={isModal ? classes.contentModal : classes.content}>
        <Box className={classes.subtitle}>
          <Text
            variant="lg"
            style={{
              fontWeight: '500',
              color: theme.palette.activeText.main
            }}
          >
            Preâmbulo
          </Text>
        </Box>
        <TextBox>
          Este termo de uso da <strong>{platformName}</strong>, plataforma
          tecnológica de iniciativa e mantida{' '}
          {client == 'AVAMEC Interativo'
            ? 'UFPE - Universidade Federal de Pernambuco, CNPJ sob o nº 24.134.488/0001-08, doravante mantenedora, com sede à Av. Prof. Moraes Rego, 1235 - Cidade Universitária, Recife - PE, 50670-901'
            : 'VIITRA INOVAÇÕES TECNOLÓGICAS LTDA, CNPJ/MF sob o nº 18.217.632.0001-77, doravante mantenedora, com sede à Rua Acadêmico Hélio Ramos, 60, sala 13, bairro da Várzea, Recife/PE, CEP 50.740-533'}
          , se trata de documento essencial, cuja aceitação é requisito
          fundamental para os seus <strong>usuários</strong>, sendo importante
          que os mesmos o leiam e o compreendam integralmente, tendo como
          documentos indissociáveis a este instrumento os listados e
          disponibilizados no item 7. Dessa forma, o uso da plataforma{' '}
          {platformName} implica o reconhecimento e a plena aceitação dos
          presentes termos de uso.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Mantenedora da plataforma {platformName}
          </Text>
        </Box>
        <TextBox>
          {client == 'AVAMEC Interativo'
            ? 'UFPE - Universidade Federal de Pernambuco, CNPJ sob o nº 24.134.488/0001-08, com sede à Av. Prof. Moraes Rego, 1235 - Cidade Universitária, Recife - PE, 50670-901, sociedade responsável pela titularidade da Plataforma AVAMEC Interativo.'
            : 'VIITRA INOVAÇÕES TECNOLÓGICAS LTDA, CNPJ/MF sob o nº 18.217.632.0001-77, com sede à Rua Acadêmico Hélio Ramos, 60, sala 13, bairro da Várzea, Recife/PE, CEP 50.740-533, sociedade responsável pela titularidade da Plataforma Redu.'}
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Conceito
          </Text>
        </Box>
        <TextBox>
          A plataforma {platformName} tem o objetivo de tornar o ambiente
          educacional mais prático e interativo, de modo a viabilizar interação
          mútua pelos integrantes do ecossistema de ensino, possibilitando o
          compartilhamento de conhecimento, sendo possível a escolha do{' '}
          <strong>usuário</strong> com relação a gravação de aulas, apresentação
          de materiais didáticos, realização de comentários, dúvidas e/ou
          registro de sugestões.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Usuários
          </Text>
        </Box>
        <TextBox>
          A plataforma possui <strong>usuários</strong> que realizam ações nos
          termos do manual de uso, quais sejam: Aluno, Professor, Tutor,
          Administrador de Ambiente e Administrador de Plataforma, cujos
          detalhamentos podem ser vistos e consultados no arquivo integrante
          destes termos. Consideram-se <strong>usuários</strong> os clientes
          pessoas jurídicas da{' '}
          {client == 'AVAMEC Interativo' ? 'UFPE' : 'Viitra'} que utilizam a
          plataforma {platformName} da forma disponível em{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>
          , em formato de whitelabel e/ou por implantação personalizada, bem
          como os <strong>usuários finais</strong> da plataforma {platformName},
          os quais geralmente são vinculados diretamente ao{' '}
          <strong>usuário cliente</strong> da {clientMaintainer} (a exemplo de
          Aluno, Professor, Tutor, Administrador de Ambiente e Administrador de
          Plataforma), estando ambos condicionados a anuência e cumprimento do
          presente instrumento, no que lhe couber.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Conteúdo e/ou material
          </Text>
        </Box>
        <TextBox>
          Material educacional em que o seu <strong>usuário autor</strong> se
          responsabiliza, exclusivamente, civil e criminalmente pelo cumprimento
          da Lei de Direitos Autorais (Lei nº 9.610/1998), excetuando o fato de
          ser licença livre, bem como das regras da ABNT em caso de referências
          de autoria, sendo a plataforma {platformName} exclusivamente um
          instrumento tecnológico que viabiliza aproximação entre{' '}
          <strong>usuários</strong> que querem compartilhar conhecimento, não
          tendo a plataforma nenhuma interferência sobre o conteúdo e/ou
          material.
        </TextBox>
        {client === 'AVAMEC Interativo' && (
          <>
            <Box className={classes.subtitle}>
              <Text
                style={{
                  color: theme.palette.activeText.main,
                  fontWeight: '500'
                }}
                variant="lg"
              >
                {`${++count}`}. Uso de Imagens de Pessoas Menores de Idade
              </Text>
            </Box>
            <TextBox>
              Ao utilizar a plataforma AVAMEC Interativo e optar por
              compartilhar imagens que mostrem explicitamente o rosto de uma
              criança ou que possam identificá-la de qualquer maneira, você (o
              usuário) concorda em obter e manter em seu poder uma autorização
              prévia dos pais ou responsáveis legais do menor. É de sua total
              responsabilidade assegurar que tal autorização esteja de acordo
              com as leis vigentes e que possa ser apresentada quando
              solicitada. A falta de comprovação de tal autorização pode
              resultar na remoção das imagens e na aplicação de sanções
              pertinentes de acordo com os termos de uso e legislação aplicável.
            </TextBox>
          </>
        )}
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Recursos
          </Text>
        </Box>
        <TextBox>
          Os recursos tecnológicos da plataforma {platformName} permitem a
          exibição de conteúdo de autoria de seus <strong>usuários</strong>,
          trata-se de um espaço que viabiliza o compartilhamento de informações
          educacionais, sendo certo que qualquer <strong>usuário</strong> que
          publicar conteúdo é informado sobre a aceitação destes Termos de Uso,
          sendo sua participação e acesso na plataforma {platformName} um
          reconhecimento tácito da aceitação dos mesmos.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Reconhecimento
          </Text>
        </Box>
        <TextBox>
          O <strong>usuário</strong> reconhece que todas as contribuições são
          bem-vindas, desde que respeitadas as condições descritas nestes Termos
          de Uso. Ao visitar, ler, ou interagir com os recursos disponibilizados
          pela {platformName}, você está aceitando todas as condições
          mencionadas nestes Termos de Uso, com destaque para as seguintes
          condições gerais:
        </TextBox>
        <TextBox>
          a) O reconhecimento de que não há presunção de anonimato, e de que o
          conteúdo postado é de sua inteira responsabilidade, não podendo a
          mantenedora ser responsabilizada por quaisquer fatos decorrentes da
          postagem desse conteúdo, sendo facultado à mantenedora, ao seu livre
          convencimento e critério, tomar iniciativas previstas na Lei
          12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          b) O reconhecimento de que as postagens devem obedecer ao escopo e ao
          objetivo da {platformName}, mantendo-se dentro do assunto em que estão
          inseridas, acatando as definições adotadas pela {platformName} e sem
          induzir outros <strong>usuários</strong> a atitudes tecnicamente
          incorretas, e sem conter ofensas, vocabulário ofensivo ou
          desrespeitoso a terceiros, incluindo calúnias, injúrias e difamações,
          atitudes de preconceito, sendo facultado à mantenedora, ao seu livre
          convencimento e critério, tomar iniciativas previstas na Lei
          12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          c) O reconhecimento de que a permanência de conteúdos no site deve ser
          encarada como um privilégio e que, em consequência disso, os{' '}
          <strong>usuários</strong> do site tomarão as providências necessárias
          para garantir as condições destes Termos de Uso, sempre que solicitado
          por alguma parte ofendida, ou que seja verificada a ocorrência de
          violação, a critério da administração do site, sendo facultado à
          mantenedora, ao seu livre convencimento e critério, tomar iniciativas
          previstas na Lei 12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          d) O reconhecimento de que a licença livre adotada em seu conteúdo
          será escolhida no momento da publicação e que ela será mencionada
          explicitamente na página de publicação do conteúdo, sendo facultado à
          mantenedora, ao seu livre convencimento e critério, tomar iniciativas
          previstas na Lei 12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          e) O reconhecimento de que o conteúdo deve estar conforme a legislação
          em vigor, sem material que possa ser considerado ilegal ou que incite
          ou favoreça práticas em desacordo com a legislação e que toda
          informação registrada usando os recursos do site é considerada como
          pública, sem nenhum tipo de confidencialidade.
        </TextBox>
        <TextBox>
          f) Para se identificar como autor, poderá o criador do conteúdo
          enviado à {platformName} usar seu nome civil, completo ou abreviado,
          até por suas iniciais, pseudônimo ou qualquer outro sinal convencional
          (Lei 9.610/1998, Art. 12), não sendo permitido conteúdos de autor não
          identificado. Embora não seja possível tecnicamente garantir que os
          pseudônimos e endereços de e-mail fornecidos sejam sempre corretos e
          identificáveis, a mantenedora solicita seu correto preenchimento em
          todas as ocasiões, e identifica o autor através do registro (e
          possível exibição) do endereço de origem e do horário de cada
          postagem.
        </TextBox>
        <TextBox>
          g) A remoção ou edição de conteúdos por iniciativa de seu próprio
          autor é facultada conforme as opções disponíveis na ferramenta de
          gestão de conteúdo, se houver. Outros casos de edição ou alteração
          podem ser solicitados pelo autor do conteúdo, de forma fundamentada,
          via formulário de contato. O atendimento, nestes casos, estará sujeito
          a análise sob aspectos técnicos e da manutenção da qualidade das
          discussões.
        </TextBox>
        <TextBox>
          h) Tentativas de abuso do sistema de moderação, ou de abuso
          sistemático e repetitivo destes Termos de Uso, incluindo a reinclusão
          de material que já tenha sido previamente moderado, serão prevenidas
          ou corrigidas por intermédio dos recursos técnicos que estiverem
          disponíveis, sempre que possível. O protesto contra a moderação pode
          ser encaminhado pelo formulário de contato, mas será considerado
          off-topic nas áreas de discussão, sendo facultado à mantenedora, ao
          seu livre convencimento e critério, tomar iniciativas previstas na Lei
          12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          i) Logins e identificações de <strong>usuário</strong> considerados
          inadequados pela administração da {platformName} (incluindo casos de
          mais de um login para o mesmo <strong>usuário</strong>, ofensas,
          identificações que possam levar os demais <strong>usuários</strong> a
          acreditar que você fala em nome de outra pessoa ou organização) podem
          ser desativados, removidos, ter seus privilégios de acesso reduzidos
          ou ter seu conteúdo tornado inacessível sem aviso, sendo ainda
          facultado à mantenedora, ao seu livre convencimento e critério, tomar
          iniciativas previstas na Lei 12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <TextBox>
          j) O login registrado na {platformName} ou a disponibilização de
          conteúdo no mesmo, não geram nenhum direito de suporte, não cabendo
          qualquer tipo de reparação, compensação ou outra obrigação da{' '}
          {platformName} em caso de cancelamento, suspensão, perda,
          indisponibilidade ou outra situação adversa que afete o acesso, os
          dados ou o conteúdo do <strong>usuário</strong>.
        </TextBox>
        <TextBox>
          k) Também deverão ser seguidas pelos <strong>usuários</strong> as
          seguintes orientações:
        </TextBox>
        <ol className={classes.numberedList}>
          <li>
            <TextBox isListItem>
              Seja cortês e trate os demais <strong>usuários</strong> com
              respeito;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>
              Preencha corretamente seu endereço de e-mail, para que este seja
              vinculado a sua verdadeira titularidade;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>
              Se não tiver certeza de algo que vá afirmar, deixe isso bem claro.
              É plenamente aceitável dizer “Eu acho que…” ou “Sugiro que você
              tente…”, e agir de outra forma não seria ético;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>
              Sinta-se à vontade para sugerir links para outros sites, desde que
              tenham relacionamento direto com o assunto abordado.
            </TextBox>
          </li>
        </ol>
        <TextBox>
          Em caso de dúvidas, use o formulário de contato para solicitar
          esclarecimento, sendo ainda facultado à mantenedora, ao seu livre
          convencimento e critério, tomar iniciativas previstas na Lei
          12.965/2014 (Marco Civil da Internet).
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Documentos indissociáveis destes termos de uso
          </Text>
        </Box>
        <TextBox>
          a) Política de privacidade: Aplica-se à plataforma {platformName} a
          política de privacidade da{' '}
          {client == 'AVAMEC Interativo'
            ? 'UFPE - Universidade Federal de Pernambuco'
            : 'VIITRA INOVAÇÕES TECNOLÓGICAS LTDA'}
          , disponibilizada no link{' '}
          <a
            className={classes.link}
            href={
              client === 'AVAMEC Interativo'
                ? 'https://avamecinterativo.mec.gov.br/app/paginas/privacy'
                : 'https://redu.digital/app/paginas/privacy'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {client === 'AVAMEC Interativo'
              ? 'https://avamecinterativo.mec.gov.br/app/paginas/privacy'
              : 'https://redu.digital/app/paginas/privacy'}
          </a>
        </TextBox>
        {client !== 'AVAMEC Interativo' && (
          <>
            <TextBox>
              b) Manual de uso da plataforma: Para que o{' '}
              <strong>usuário</strong> tenha acesso a mais detalhamentos da
              plataforma {platformName}, recomendamos que leia atentamente o
              documento em referência disponibilizado no link{' '}
              <a
                className={classes.link}
                href="https://ajuda.redu.digital/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {`https://ajuda.redu.digital/`}
              </a>
              .
            </TextBox>
          </>
        )}
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Limitação de responsabilidade
          </Text>
        </Box>
        <TextBox>
          Em nenhuma situação a {platformName} e/ou sua mantenedora serão
          responsáveis por quaisquer danos, prejuízos ou outro efeito, direto ou
          indireto, relacionados ao uso, por parte de seus{' '}
          <strong>usuários</strong>, leitores ou de qualquer outra pessoa, da
          plataforma {platformName}, deste website, de seu conteúdo e/ou de
          qualquer outro website aqui mencionado. Em especial, a {platformName}{' '}
          e/ou sua mantenedora não classificam e não analisam o conteúdo que
          seus <strong>usuários</strong> acrescentam como comentários ou como
          participações nos fóruns e demais áreas do site, mediadas ou não. Todo
          autor de conteúdo exibido na {platformName} retém os seus direitos e
          responsabilidades autorais, nos termos da Lei 9.610/1998, sem prejuízo
          dos termos de licenciamento de livre uso, conforme exposto nestes
          Termos de Uso, no item “Licença de uso do conteúdo”, abaixo, sendo
          ainda facultado à mantenedora, ao seu livre convencimento e critério,
          tomar iniciativas previstas na Lei 12.965/2014 (Marco Civil da
          Internet).
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Licença de uso do conteúdo
          </Text>
        </Box>
        <TextBox>
          Todo <strong>usuário</strong> que é autor de conteúdo exibido na{' '}
          {platformName}
          retém os seus direitos e responsabilidades autorais, nos termos da Lei
          9.610/1998, sem prejuízo dos termos de licenciamento de livre uso,
          expostos a seguir. Exceto quando mencionado explicitamente, ou quando
          se tratar de citação (adequadamente indicada no corpo do texto, por
          intermédio de tipo diferenciado, aspas e/ou margem esquerda adicional)
          de material alheio ou ilustração, nos limites estabelecidos pela Lei
          9.610/98, todo o conteúdo textual original da {platformName} está
          disponível livremente para leitura, uso, redistribuição ou
          modificação, entre outros direitos, conforme definido na licença
          Creative Commons.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Como reportar violações
          </Text>
        </Box>
        <TextBox>
          Se algum conteúdo publicado na plataforma {platformName} violar algum
          direito seu ou a legislação aplicável, entre em contato pelos
          endereços indicados abaixo, mencionando de forma específica e
          detalhada:
        </TextBox>
        <ul className={classes.bulletList}>
          <li>
            <TextBox isListItem>
              o título da página específica em que se encontra a violação;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>
              o endereço (URL) da página específica em que se encontra a
              violação; a natureza da violação;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>
              o trecho específico em que ocorre a violação;
            </TextBox>
          </li>
          <li>
            <TextBox isListItem>a providência desejada; e</TextBox>
          </li>
          <li>
            <TextBox isListItem>
              as suas informações de contato, incluindo e-mail.
            </TextBox>
          </li>
        </ul>
        <TextBox>
          De posse destas informações, a mantenedora da {platformName} analisará
          e resolverá a questão com brevidade. Caso a informação esteja
          incompleta ou com detalhamento insuficiente, a mantenedora entrará em
          contato para solicitar a complementação, possivelmente atrasando a
          providência desejada. São aceitas comunicações de violações e
          solicitações de providências por meio da página{' '}
          <a
            className={classes.link}
            href={
              client === 'AVAMEC Interativo'
                ? 'https://avamecinterativo.mec.gov.br/app/contato'
                : 'https://redu.digital/app/contato'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {client === 'AVAMEC Interativo'
              ? 'https://avamecinterativo.mec.gov.br/app/contato'
              : 'https://redu.digital/app/contato'}
          </a>
          .
        </TextBox>
        <TextBox>
          Comunicações de violações e solicitações de providências enviadas por
          outros canais não serão recebidas. Comunicações de violações e
          solicitações de providências enviadas sem identificação completa do
          requerente e descrição detalhada e justificada da violação não serão
          atendidas. A mantenedora da {platformName} responderá
          preferencialmente ao endereço de e-mail do requerente, indicado na sua
          mensagem enviada pelos canais acima.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Inexistência de vínculo
          </Text>
        </Box>
        <TextBox>
          Estes Termos de Uso não importam na criação de qualquer vínculo
          trabalhista, societário, de parceria ou associativo entre o{' '}
          <strong>usuário-colaborador</strong> e a {platformName} e/ou sua
          mantenedora, sendo excluídas quaisquer presunções de solidariedade
          entre ambos no cumprimento de suas obrigações.
        </TextBox>
        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Da disponibilização da plataforma {platformName} por
            meio de Whitelabel e/ou Implantação Personalizada
          </Text>
        </Box>
        <TextBox>
          Além da disponibilização da plataforma {platformName} por meio de
          acesso ao{' '}
          <a
            className={classes.link}
            href={settings.baseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${settings.baseUrl}`}
          </a>
          , a {clientMaintainer} também disponibiliza aos seus{' '}
          <strong>usuários clientes</strong> a plataforma {platformName} por
          meio de whitelabel e/ou implantação personalizada, com as seguintes
          características:
        </TextBox>
        <TextBox>
          a) Whitelabel: apenas muda a interface do dashboard de acesso à
          plataforma, o qual ficará com elementos identificadores do{' '}
          <strong>usuário cliente</strong>, a exemplo de marca e/ou logotipo,
          dentre outros detalhes.
        </TextBox>
        <TextBox>
          b) Implantação personalizada: a implantação da plataforma Web ocorrerá
          em infraestrutura nas nuvens. Com a implantação da plataforma{' '}
          {platformName} na nuvem, uma cópia da plataforma será disponibilizada
          em endereço requisitado pelo <strong>usuário cliente</strong> da{' '}
          {clientMaintainer}, geralmente contratante da plataforma. Também
          estará disponível um aplicativo para dispositivos móveis com sistemas
          operacionais Android e iOS para download na Google Play e Apple Store.
        </TextBox>
        <TextBox>
          <strong>{count}.1</strong> Os <strong>usuários</strong>, sejam eles{' '}
          <strong>usuários contratantes</strong> da {clientMaintainer}, sejam
          meramente <strong>usuários finais</strong> da plataforma, sendo esses
          últimos geralmente vinculados ao contratante da plataforma{' '}
          {platformName}, estarão vinculados ao cumprimento do presente termo de
          uso.
        </TextBox>
        {client == 'AVAMEC Interativo' && (
          <>
            <Box className={classes.subtitle} style={{ textAlign: 'center' }}>
              <Text
                style={{
                  color: theme.palette.activeText.main,
                  fontWeight: '500'
                }}
                variant="lg"
              >
                COMPROMISSO NACIONAL CRIANÇA ALFABETIZADA – PROPOSTA FORMATIVA
                LEITURA E ESCRITA NA EDUCAÇÃO INFANTIL – LEEI/CNCA
              </Text>
            </Box>
            <Box className={classes.subtitle}>
              <Text
                style={{
                  color: theme.palette.activeText.main,
                  fontWeight: '500'
                }}
                variant="lg"
              >
                {++count}. Termo de Responsabilidade
              </Text>
            </Box>
            <TextBox>
              Este termo de responsabilidade estabelece as responsabilidades de
              formadoras estaduais, de formadoras municipais e de cursistas que
              acessarão e utilizarão conteúdo de livros infantis e contos
              literários protegidos por direitos autorais. Ao assinar este
              documento, as formadoras e cursistas se comprometem a respeitar as
              leis de propriedade intelectual e a usar o conteúdo de forma
              responsável e ética.
            </TextBox>
            <TextBox>
              Além disso, este termo busca estabelecer diretrizes claras sobre o
              uso adequado do conteúdo, evitando fraudes, reproduções não
              autorizadas e outras práticas prejudiciais. Dessa forma, cria-se
              um ambiente seguro e sustentável para compartilhar livros infantis
              nas ações de formação profissional no âmbito do LEEI/CNCA.
            </TextBox>
            <TextBox>
              <strong>{count}.1 Direitos Autorais de Livros Infantis</strong>
            </TextBox>
            <TextBox>
              Os livros infantis e demais livros literários são protegidos por
              leis de direitos autorais, que garantem aos autores e editoras o
              controle sobre a reprodução, distribuição e uso de seus trabalhos
              criativos. Esse arcabouço legal tem o objetivo de incentivar a
              produção de conteúdo de qualidade, assegurando que os criadores
              sejam devidamente recompensados pelo seu esforço.
            </TextBox>
            <TextBox>
              Ao utilizar os livros infantis e os contos disponibilizados na
              plataforma {platformName}, as formadoras e cursistas concordam em
              respeitar esses direitos autorais. Isso significa que{' '}
              <strong>
                {' '}
                o conteúdo não poderá ser copiado, reproduzido, distribuído ou
                modificado.
              </strong>{' '}
              Ao assinar este termo de responsabilidade, a formadora/cursista se
              compromete a utilizar o conteúdo de livros infantis e demais
              livros literários de forma ética e responsável. Isso inclui:
            </TextBox>
            <ul className={classes.bulletList}>
              <li>
                <TextBox isListItem>
                  Projetar o conteúdo dos livros em ambiente fechado, nos quais
                  ocorrerão as atividades do LEEI/CNCA com os fins de formação
                  das cursistas
                </TextBox>
              </li>
              <li>
                <TextBox isListItem>
                  Não fazer cópias não autorizadas do conteúdo
                </TextBox>
              </li>
              <li>
                <TextBox isListItem>
                  Não compartilhar ou distribuir o conteúdo com terceiros
                </TextBox>
              </li>
              <li>
                <TextBox isListItem>
                  Não modificar ou alterar o conteúdo original
                </TextBox>
              </li>
              <li>
                <TextBox isListItem>
                  Respeitar os créditos e informações de autoria dos livros
                </TextBox>
              </li>
            </ul>
            <TextBox>
              A formadora também se compromete a denunciar quaisquer usos
              indevidos ou violações de direitos autorais que venha a
              presenciar.
            </TextBox>
            <TextBox>
              <strong>{count}.2 Uso Adequado do Conteúdo</strong>
            </TextBox>
            <TextBox>
              O conteúdo dos livros infantis e demais livros literários
              disponibilizados na plataforma {platformName} deve ser utilizado
              apenas para fins educacionais, relacionados ao LEEI/CNCA. Isso
              inclui leitura individual, atividades em sala de aula e projetos
              pedagógicos aprovados.
            </TextBox>
            <TextBox>
              <strong>{count}.2.1 Dúvidas e Orientações</strong>
            </TextBox>
            <TextBox>
              Em caso de dúvidas sobre o uso adequado do conteúdo, a
              formadora/cursista deve entrar em contato com a formadora
              estadual.
            </TextBox>
            <TextBox>
              <strong>{count}.3 Penalidades por Violação</strong>
            </TextBox>
            <TextBox>
              O descumprimento deste termo de responsabilidade pode acarretar
              sérias consequências legais e financeiras para a
              formadora/cursista. Algumas das penalidades aplicáveis incluem:
            </TextBox>
            <table className={classes.table}>
              <tr>
                <th>Violação</th>
                <th>Penalidade</th>
              </tr>
              <tr>
                <td>Cópia não autorizada</td>
                <td>Multa e/ou processo judcial</td>
              </tr>
              <tr>
                <td>Distribuição não licenciada</td>
                <td>Multa e /ou suspensão da conta</td>
              </tr>
              <tr>
                <td>Modificação não licenciada</td>
                <td>Retirada do conteúdo e/ou suspensão da conta</td>
              </tr>
            </table>
            <TextBox>
              <strong>
                A formadora/cursista reconhece que a proteção dos direitos
                autorais é fundamental para a sustentabilidade da indústria
                editorial e se compromete a respeitar integralmente as
                disposições deste termo.
              </strong>
            </TextBox>
            <TextBox>
              <strong>{count}.4 Processo de Assinatura</strong>
            </TextBox>
            <TextBox>
              Para acessar e utilizar o conteúdo de livros infantis nesta
              plataforma, a formadora/cursista deve ler atentamente este termo
              de responsabilidade e concordar com todos os seus termos e
              condições.
            </TextBox>
            <TextBox>
              Ao clicar no botão que leu e aceita os termos, a
              formadora/cursista aceita formalmente os termos e se compromete a
              respeitá-los.
            </TextBox>
          </>
        )}

        <Box className={classes.subtitle}>
          <Text
            style={{ color: theme.palette.activeText.main, fontWeight: '500' }}
            variant="lg"
          >
            {`${++count}`}. Disposições Finais
          </Text>
        </Box>
        <TextBox>
          Estas Condições Gerais serão regidas e interpretadas segundo a
          legislação brasileira. Fica eleito o Foro da Comarca de Recife, Estado
          de Pernambuco, para dirimir questões relativas a estas Condições
          Gerais, com renúncia expressa a qualquer outro. Estes termos de uso
          podem ser modificados pela {platformName} e/ou sua mantenedora a
          qualquer tempo e as modificações terão efeito a partir da data de sua
          publicação na plataforma {platformName} mediante a sua comunicação em
          local que seja de fácil identificação para o <strong>usuário</strong>.
        </TextBox>
      </Box>
    </Box>
  );
};

export default UsageTerms;
