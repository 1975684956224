import React from 'react';

import { Divider, Typography } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
export default function FetchMoreButton({ fetchMore }) {
  return (
    <>
      <Divider></Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px'
        }}
      >
        <button type="button" onClick={fetchMore}>
          <Typography color={'primary'}>Carregar mais...</Typography>
        </button>
      </div>
    </>
  );
}
